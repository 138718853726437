import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import NewFeatureSplash from './NewFeatureSplash';
import { getTheme } from '../../state/reducers/AppSettingsReducer';

const mapStateToProps = state => {
    return { 
        theme: getTheme(state)
    };
};

const NewFeatureSplashContainer = translate()(connect(mapStateToProps)(NewFeatureSplash));

export default NewFeatureSplashContainer;

import React, { useState, useEffect, useCallback } from 'react';
import classNames from 'classnames/bind';
import Utils from '../../../services/Utils';
import { ChartSettingsState } from '../../../models/Interfaces';
import TimeSeriesQuery from '../../../models/TimeSeriesQuery';
import { 
    SeriesSettingsTabs, 
    TimeUnit, 
    XAxisShiftState, 
    QueryTypes, 
    CurveStepInterpolationFunctions, 
    InterpolationFunctions } from '../../../../constants/Enums';
import DropdownMenuContainer from '../../DropdownMenu/DropdownMenu.container';
import Icon from '../../Icon/Icon';
import BaseQuery from '../../../models/BaseQuery';
import TooltipableContainer from '../../Tooltipable/Tooltipable.container';
import { usePrevious } from '../../../hooks/usePrevious';
const cx = classNames.bind(require('./SeriesSettings.module.scss'));

interface Props { 
    t: any; 
    theme: string; 
    chartSettings: ChartSettingsState;
    query: any; 
    queries: Array<TimeSeriesQuery>; 
    queriesType: QueryTypes; 
    startAtString: string;
    searchSpan: any; 
    offsetIsPositive: boolean; 
    offsetQuantity: number; 
    offsetUnit: TimeUnit;
    timezone: string; 
    setQueryField: (queryType: QueryTypes, idx: number, field: string, value: any) => void;
    setTSQField: (tsqI: number, field: string, value: any) => void; 
    setAQField: (idx: number, field: string, value: any) => void; 
    setActiveTSQI: (aciveTSQI: number) => void; 
    clearShift: (tsqI: number) => any; 
    setTimeShift: (idx, timeShift) => void; 
    setStartAt: (tsqI: number, startAt: string) => any; 
}

export default function SeriesSettings (props:Props) {

    const steppedInterpolationLabelId = Utils.customID('steppedInterpolationLabel');
    const minMaxShadowsLabelId = Utils.customID('minMaxShadowsLabel');
    const dotsLabelId = Utils.customID('dotsLabel');
    const [tab, setTab] = useState(SeriesSettingsTabs.basic);
    const [xAxisType, setXAxisType] = useState(XAxisShiftState.none);
    const [offsetUnit, setOffsetUnit] = useState(null);
    const [offsetIsPositive, setOffsetIsPositive] = useState(null);
    const [offsetQuantity, setOffsetQuantity] = useState(null);
    const [startAtTargetRef] = useState((React.createRef() as any));
    const [startAt, setStartAt] = useState(null);
    const [renderStartAtDTPBTimeout, setRenderStartAtDTPBTimeout] = useState(null);

    const timeUnitMap = {
        'milliseconds': 'ms',
        'seconds': 's',
        'minutes': 'm',
        'hours': 'h',
        'days': 'd'
    }

    const xAxisIds = {
        xAxisNone: Utils.customID('xAxisNone'),
        xAxisOffset: Utils.customID('xAxisOffset'),
        xAxisStartAt: Utils.customID('yAxisStartAt')
    };

    const setOffsetTypeAndClearShift = (offsetType: XAxisShiftState) => {
        setXAxisType(offsetType);
        if (offsetType === XAxisShiftState.none) {
            props.clearShift(props.chartSettings.activeTSQI);
        }
    }

    const constructTimeShift = useCallback((isPositive: boolean, quantity: number, unit: string) => {
        return `${isPositive ? '' : '-'}${quantity}${timeUnitMap[unit]}`;
    }, [timeUnitMap]);

    const setOffset = useCallback((offsetType: XAxisShiftState, startAtDateString: string = null) => {
        if (offsetType === XAxisShiftState.none) {
            props.clearShift(props.chartSettings.activeTSQI);
        }
        if (offsetType === XAxisShiftState.offset) {
            let timeShift = constructTimeShift(offsetIsPositive, Number(offsetQuantity), offsetUnit);
            props.setTimeShift(props.chartSettings.activeTSQI, timeShift);
        }
        if (offsetType === XAxisShiftState.startAt) {
            props.setStartAt(props.chartSettings.activeTSQI, startAtDateString);
        }
    }, [props, offsetIsPositive, offsetQuantity, offsetUnit, constructTimeShift]);

    const setQueryField = (property: string, value: any) => {
        props.setQueryField(props.queriesType, props.chartSettings.activeTSQI, property, value)
    }

    const setInterpolationFunction = (stepInterpolation: CurveStepInterpolationFunctions = null) => {
        if (stepInterpolation) {
            setQueryField('interpolationFunction', stepInterpolation);
        } else { // for on-off toggling purpose
            setQueryField('interpolationFunction', props.query.interpolationFunction in CurveStepInterpolationFunctions ? InterpolationFunctions.curveMonotoneX : getDefaultStepInterpolationFunctionToSet()); // instead of InterpolationFunctions.null, set it to monotoneX to make it clear that it is set by user
        }
    }

    const getDefaultStepInterpolationFunctionToSet = () => {
        const interpolationFunction = TimeSeriesQuery.getDefaultInterpolationFunctionForVariable(props.query.variableObject, props.query.dataType, props.query.isRawData, true);
        return interpolationFunction === InterpolationFunctions.none ? CurveStepInterpolationFunctions.curveStep : interpolationFunction;
    }

    const setIncludeEnvelope = () => {
        setQueryField('includeEnvelope', !props.query.includeEnvelope);
    }

    const setIncludeDots = () => {
        setQueryField('includeDots', !props.query.includeDots);
    }

    const getShiftState = (timeShift: string, startAt: string):XAxisShiftState => {
        if (startAt !== null) {
            return XAxisShiftState.startAt;
        } 
        if (timeShift !== '' && 
                Utils.parseShift(timeShift) !== 0) { 
            return XAxisShiftState.offset;
        }
        return XAxisShiftState.none;
    }

    const isOffsetOutOfSync = () => {
        let timeShift = constructTimeShift(offsetIsPositive, offsetQuantity, offsetUnit);        
        let userSetTimeShiftMillis = Utils.tsiClient.utils.parseTimeInput(timeShift);
        let propsTimeShiftMillis = Utils.tsiClient.utils.parseTimeInput(props.query.timeShift);
        let shiftMillisOutOfSync = userSetTimeShiftMillis !== propsTimeShiftMillis;

        let currentShiftType = getShiftState(props.query.timeShift, props.query.startAt);
        let shiftTypeOutOfSync = currentShiftType !== xAxisType;

        return shiftMillisOutOfSync || shiftTypeOutOfSync;
    }

    const getTitleFromQuery = (query: BaseQuery) => {
        return Utils.getTitleFromQuery(query, props.timezone, props.t('settingsModal.startAt'));
    }

    useEffect(() => {
        setXAxisType(getShiftState(props.query.timeShift, props.query.startAt));
    }, [props.query.timeShift, props.query.startAt, props.chartSettings.activeTSQI]);

    const prevXAxisType: any = usePrevious({xAxisType})?.xAxisType;
    const prevTab: any = usePrevious({tab})?.tab;
    useEffect(() => {
        const renderStartAtButton = () => {
            clearTimeout(renderStartAtDTPBTimeout);
            setRenderStartAtDTPBTimeout(setTimeout(() => {
                const targetElement = startAtTargetRef.current;
                if (targetElement) {
                    targetElement.innerHTML = "";
                    let startAtMillis = (new Date(startAt)).valueOf();
                    let startAtButton = new Utils.tsiClient.ux.DateTimeButtonSingle(targetElement);
                    startAtButton.render({theme: props.theme, offset: props.timezone}, 0, Infinity, startAtMillis, (t) => {
                        let dateString = (new Date(t)).toISOString();
                        setStartAt(dateString);
                        setOffset(XAxisShiftState.startAt, dateString);
                    });    
                }
            }));
        }
        if (xAxisType === XAxisShiftState.startAt && (prevXAxisType === undefined || prevXAxisType !== xAxisType || prevTab !== tab)) {
            renderStartAtButton();
        }
    }, [xAxisType, props.theme, props.timezone, renderStartAtDTPBTimeout, setOffset, startAt, startAtTargetRef, prevXAxisType, tab, prevTab]);

    // useEffect calls below are to update state variables when props change

    useEffect(() => {
        setOffsetIsPositive(props.offsetIsPositive);
    }, [props.offsetIsPositive]);

    useEffect(() => {
        setOffsetQuantity(props.offsetQuantity)
    }, [props.offsetQuantity]);

    useEffect(() => {
        setOffsetUnit(props.offsetUnit);
    }, [props.offsetUnit]);

    useEffect(() => {
        setStartAt(props.startAtString);
    }, [props.startAtString]);

    return (<div className={cx('settingsTab', 'seriesTab')}>
        <DropdownMenuContainer 
            dropdownMenuOptions={props.queries.map((q: any, i) => 
                [<span className={cx('seriesColor')} style={{background: q.color}}></span>, getTitleFromQuery(q), (q) => {
                    props.setActiveTSQI(i);
                }]
            )}
            dropdownMenuSide='right'
            containerClassName={cx('seriesSelectionMenuWrapper')}
            menuClassName={cx("seriesSelectionMenu")}
            buttonClassName={cx("seriesSelectionButton")}
            menuItemClassName={cx("seriesSelectionMenuItem")}
            menuButtonLabel={``}
            selectedValue={getTitleFromQuery(props.query)}
        >
            <div className={cx('seriesSelectionButtonContent')}>
                <span className={cx('seriesColor')} style={{background: props.query.color}}></span>
                <span className={cx('seriesName')}>{getTitleFromQuery(props.query)}</span>
                <Icon id='chevron' className={cx('chevron')}></Icon>
            </div>
        </DropdownMenuContainer>
           
        <div className={cx('settingsModalContent')}>
            {(props.queriesType === QueryTypes.TimeSeries) &&
            <div role="tablist" className={cx('settingsTabNav')}>
                <button role="tab" aria-selected={tab === SeriesSettingsTabs.basic} className={`${cx('tabTitle')} ${tab === SeriesSettingsTabs.basic ? cx('selected') : ''}`} 
                    onClick={() => setTab(SeriesSettingsTabs.basic)}>{props.t('settingsModal.basic')}</button>
                <button role="tab" aria-selected={tab === SeriesSettingsTabs.advanced} className={`${cx('tabTitle')} ${tab === SeriesSettingsTabs.advanced ? cx('selected') : ''}`} 
                    onClick={() => setTab(SeriesSettingsTabs.advanced)}>{props.t('settingsModal.advanced')}</button>
            </div>}
            {(tab === SeriesSettingsTabs.basic) && 
            <div className={cx("basic")}>
                <div className={cx("tsqSettingsFields")}>
                    <div className={cx("settingsField")}>
                        <div className={cx("settingsFieldLabel")} id={steppedInterpolationLabelId}>{props.t('well.steppedInterpolation')}</div>
                        <button className={cx('settingsFieldToggle')} role='checkbox' aria-labelledby={steppedInterpolationLabelId} aria-checked={props.query.interpolationFunction in CurveStepInterpolationFunctions} onClick={() => { 
                            setInterpolationFunction();
                        }}>
                            <div className={cx('toggleSliderWrapper', {off: !(props.query.interpolationFunction in CurveStepInterpolationFunctions)})}><div className={cx('toggleButton')}></div></div>
                        </button>
                    </div>
                    <div className={cx("settingsField")}>
                        <div className={cx("settingsFieldLabel")} id={minMaxShadowsLabelId}>{props.t('well.minMaxShadows')}</div>
                        {props.query.isRawData ?
                            <TooltipableContainer position={'left'} tooltip={props.t('settingsModal.minMaxShadowsWarningForRawData')}>
                                <button className={cx('settingsFieldToggle')} role='checkbox' aria-labelledby={minMaxShadowsLabelId} aria-checked={props.query.includeEnvelope} 
                                        onClick={() => {setIncludeEnvelope();}} >
                                    <div className={cx('toggleSliderWrapper', {off: !props.query.includeEnvelope})}><div className={cx('toggleButton')}></div></div>
                                </button>
                            </TooltipableContainer>
                        : <button className={cx('settingsFieldToggle')} role='checkbox' aria-labelledby={minMaxShadowsLabelId} aria-checked={props.query.includeEnvelope} 
                                onClick={() => {setIncludeEnvelope();}}>
                            <div className={cx('toggleSliderWrapper', {off: !props.query.includeEnvelope})}><div className={cx('toggleButton')}></div></div>
                        </button>
                        }
                    </div>
                    <div className={cx("settingsField")}>
                        <div className={cx("settingsFieldLabel")} id={dotsLabelId}>{props.t('well.dots')}</div>
                        <button className={cx('settingsFieldToggle')} role='checkbox' aria-checked={props.query.includeDots} aria-labelledby={dotsLabelId} onClick={() => { 
                            setIncludeDots();
                        }}>
                            <div className={cx('toggleSliderWrapper', {off: !props.query.includeDots})}><div className={cx('toggleButton')}></div></div>
                        </button>
                    </div>
                </div>
            </div>}
            {(tab === SeriesSettingsTabs.advanced) && 
            <div className={cx('advanced')}>
                <div className={cx("tsqSettingsFields")}>
                    <div className={cx('xAxisShift')}>
                        <h2 className={cx('tsqSettingsFieldTitle')}>
                            {props.t('settingsModal.xAxisShift')}
                        </h2>
                        <div className={cx('radioButtonContainer')} role="radiogroup"> 
                            <div className={cx('_base-radioButton', 'xAxisRadio')}>
                                <input type="radio" aria-checked={xAxisType === XAxisShiftState.none} checked={xAxisType === XAxisShiftState.none} 
                                    id={xAxisIds.xAxisNone} onChange={() => setOffsetTypeAndClearShift(XAxisShiftState.none)}/>
                                <label htmlFor={xAxisIds.xAxisNone}>{props.t('settingsModal.none')}</label>
                            </div>
                            <div className={cx('_base-radioButton', 'xAxisRadio')}>
                                <input type="radio" aria-checked={xAxisType === XAxisShiftState.offset} checked={xAxisType === XAxisShiftState.offset} 
                                    id={xAxisIds.xAxisOffset} onChange={() => setOffsetTypeAndClearShift(XAxisShiftState.offset)}/>
                                <label htmlFor={xAxisIds.xAxisOffset}>{props.t('settingsModal.offset')}</label>
                            </div>
                            <div className={cx('_base-radioButton', 'xAxisRadio')}>
                                <input type="radio" aria-checked={xAxisType === XAxisShiftState.startAt} checked={xAxisType === XAxisShiftState.startAt} 
                                    id={xAxisIds.xAxisStartAt} onChange={() => {setOffsetTypeAndClearShift(XAxisShiftState.startAt)}}/>
                                <label htmlFor={xAxisIds.xAxisStartAt}>{props.t('settingsModal.startAt')}</label>
                            </div>
                        </div>
                        {xAxisType === XAxisShiftState.offset && <div className={cx('xAxisOffsetContainer')}>
                            <div className={cx('offsetContainer')}>
                                <div className={cx('plusMinusContainer')} role="radiogroup"> 
                                    <label className={cx('plusButton')} aria-label={props.t('settingsModal.shiftPositive')}><input type="radio" checked={offsetIsPositive} onChange={() => setOffsetIsPositive(true)}/><span>+</span></label> 
                                    <label className={cx('minusButton')} aria-label={props.t('settingsModal.shiftNegative')}><input type="radio" checked={!offsetIsPositive} onChange={() => setOffsetIsPositive(false)}/><span>-</span></label> 
                                </div>
                                <input type="number" min="0" className={cx('offsetNumber')} value={offsetQuantity} onChange={(e) => setOffsetQuantity(e.target.value)}></input>
                                <select className={cx('unitSelect')} value={offsetUnit} onChange={(e) => setOffsetUnit(e.target.value)}>
                                    {Object.keys(timeUnitMap).map((unit, unitI) => 
                                        <option key={unitI} value={unit}>{unit}</option>
                                    )}
                                </select>
                                <button onClick={() => setOffset(XAxisShiftState.offset)} disabled={!isOffsetOutOfSync()} className={cx('applyButton')} aria-label={props.t('settingsModal.applyTimeShift')}></button>
                            </div>
                        </div>}
                        {xAxisType === XAxisShiftState.startAt && <div className={cx('xAxisStartAtContainer')} ref={startAtTargetRef}>
                        </div>}
                    </div>
                </div>
            </div>}
        </div>
    </div>
    );
}
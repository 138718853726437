import { from } from 'rxjs/observable/from';
import AuthService from '../services/AuthService';
const AuthenticationContext = require('adal-angular');
const jwt = require('jsonwebtoken');

export default class AdalAuthService {

    private authContext;
    private getTokenCalls: any = [];
    private gettingToken: boolean = false;
    
    constructor(authContextConfig){
        this.authContext = new AuthenticationContext(authContextConfig);
    }

    public login = (continuation) => {
        let user;
        if (this.authContext.isCallback(window.location.hash)) {
            
            // Handle redirect after token requests
            this.authContext.handleWindowCallback();
            let err = this.authContext.getLoginError();
            if (err) {
                console.log(err);
            }
        
        } else {
            user = this.authContext.getCachedUser();
            if (user) {
                let userName = user.profile.name ? user.profile.name : (`${user.profile.given_name} ${user.profile.family_name}`);
                continuation(userName, user.userName);
            } else {
                console.log('Not signed in.');
                this.authContext.login();
            }
        }
    }

    public logout = () => {
        this.authContext.logOut();
    }

    private shiftAndExecuteGetTokenCall = () => {
        let call = this.getTokenCalls.shift();
        if (call) {
            call.call();
        }
    }

    private createGetTokenCall = (scope: string, resolve, reject, tenantBased: boolean) => {
        
        let tokenContinuation = (error, token) => {
            this.gettingToken = false;
            this.shiftAndExecuteGetTokenCall();
            if (!error) {
                resolve(token);
            }
            else {
                // meet with Neha to discuss specific flow in this case, popup has the following issue presently...
                // Refused to display 'https://login.microsoftonline.com/common/oauth2/authorize?response_type=id_token&client_id=120d688d-1518-4cf7-bd38-182f158850b6&redirect_uri=https%3A%2F%2Finsights-local.timeseries.azure.com%2Fbeta&state=a74becd7-2080-4275-ae40-f4ca8d7a1a33&client-request-id=1ff1dfd2-b76d-434b-bbd1-7b8727dc86d9&x-client-SKU=Js&x-client-Ver=1.0.17&nonce=cbb144d1-7598-4f9c-9119-c356331f52f7' in a frame because it set 'X-Frame-Options' to 'deny'.
                this.authContext.acquireTokenPopup(scope, null, null, 
                    (error, token) => { 
                        if (!error) {
                            resolve(token); 
                        }
                        else {
                            reject(error);
                        }
                });
            }
        };

        // if tenant id matches or not tenant based, acquireToken, else _renewToken
        let availableToken =  this.authContext.getCachedToken(scope);
        if (AuthService.tenantId !== 'common' && tenantBased && availableToken && jwt.decode(availableToken).tid !== AuthService.tenantId) {
            return () => {
                this.gettingToken = true;
                this.authContext.config.tenant = AuthService.tenantId;
                this.authContext._requestType = 'RENEW_TOKEN';
                this.authContext._renewToken(scope, tokenContinuation, 'token');
            };
        }
        else {
            return () => {
                this.gettingToken = true;
                if (tenantBased) {
                    this.authContext.config.tenant = AuthService.tenantId;
                }
                this.authContext.acquireToken(scope, tokenContinuation);
            };
        }
    }

    private getGenericTokenPromiseCallback = (scope: string, isTenantBased: boolean = false) => {
        return (resolve, reject) => {
            let getTokenCall = this.createGetTokenCall(scope, resolve, reject, isTenantBased);
            this.getTokenCalls.push(getTokenCall);
            if (!this.gettingToken) {
                this.shiftAndExecuteGetTokenCall();
            }
        };
    }

    public getTsiTokenPromise = (clientId) => new Promise(this.getGenericTokenPromiseCallback(clientId, true));

    public getManagementTokenPromise = (management) => new Promise(this.getGenericTokenPromiseCallback(management));

    public getTsiToken = (clientId) => {
        return from(this.getTsiTokenPromise(clientId));
    }

    public getManagementToken = (management) => {
        return from(this.getManagementTokenPromise(management));
    }

    public getGraphToken = (graph) => {
        return from(new Promise(this.getGenericTokenPromiseCallback(graph, true)));
    }

}
import * as React from 'react';
import classNames from 'classnames/bind';
import Icon from '../../Icon/Icon';
import { SortableTableColumn } from '../../SortableTable/SortableTable';
import SortableTableContainer from '../../SortableTable/SortableTable.container';
import Utils from '../../../services/Utils';
const cx = classNames.bind(require('../TsmEntities.module.scss'));

const InstancesColumns: Array<SortableTableColumn> =  [
    { key: 'timeSeriesId', i18nKey: 'tsm.instance.id', isSortable: true},
    { key: 'name', i18nKey: 'tsm.name', isSortable: true },
    { key: 'description', i18nKey: 'tsm.description', isSortable: false },
    { key: 'typeName', i18nKey: 'tsm.type.title', isSortable: true },
    { key: 'hierarchies', i18nKey: 'tsm.hierarchies', isSortable: false },
    { key: 'instanceFields', i18nKey: 'tsm.instanceFields', isSortable: false },
    { key: 'actions', i18nKey: 'tsm.actions', isSortable: false, style: { width: '164px' } }
];

const InstanceFieldsColumns: Array<SortableTableColumn> =  [
    { key: 'instanceFieldName', i18nKey: 'tsm.instance.fieldName', isSortable: true},
    { key: 'instanceFieldValue', i18nKey: 'tsm.instance.fieldValue', isSortable: true},
    { key: 'actions', i18nKey: 'tsm.actions', isSortable: false}
];

/*
    'entity' refers to instance
    'subEntity' refers to instance field in a instance
*/
export const InstancesTable = ({entities, types, hierarchies, selectedEntityIndex, selectedSubEntityIndex, expandedEntityIndices, handlers: {onClickRow, onClickSort, onEditEntity, onShowEntityJSON, onConfirmDeletion, onAddSubEntity, onEditSubEntity, onClickSubEntitySort, onConfirmDeletionSubEntity}, sort: {sOrder, sColumnKey, sOrderSubEntity, sColumnKeySubEntity, sParentIndex}, t, theme, isUserContributor, progressMessageFieldID}) => 
    <SortableTableContainer
        columns={InstancesColumns}  
        rows={entities} 
        handleOnSort={onClickSort} 
        canExpandRows={true}
        sortOrder={sOrder}
        sortColumnIndex={InstancesColumns.findIndex(column => column.key === sColumnKey)}
        selectedRowIndex={selectedEntityIndex}
        handleOnClickRow={onClickRow}
        expandedRowIndices={expandedEntityIndices}
        renderRow={(instance, index, expanded, selected, onClick) => 
            <InstancesTableRow 
                key={`instancestablerow-${index}`} 
                t={t}
                instance={instance}
                types={types}
                hierarchies={hierarchies}
                rowIndex={index} 
                expanded={expanded}
                selected={selected}
                handlers={{onClick}}
                actionButton={createActionButtonsForInstance({t, theme, instanceTimeSeriesIdKey: instance.timeSeriesIdKey, index, handlers: {onEditEntity, onShowEntityJSON, onConfirmDeletion}, isUserContributor, progressMessageFieldID})} />
        }
        renderExpandedRowContents={(instance, idx) => <InstanceDetails 
                                                            t={t} 
                                                            theme={theme} 
                                                            instance={instance} 
                                                            instanceIdx={idx} 
                                                            selectedInstanceFieldIndex={selectedSubEntityIndex} 
                                                            handlers={{onClickSubEntitySort, onAddSubEntity, onEditSubEntity, onConfirmDeletionSubEntity}} 
                                                            sort={{sOrder: sOrderSubEntity, sColumnKey: sColumnKeySubEntity, sOrderVisible: idx === sParentIndex}} 
                                                            isUserContributor={isUserContributor} 
                                                            progressMessageFieldID={progressMessageFieldID}/>}/> ;

const InstancesTableRow = ({t, instance, types, hierarchies, rowIndex, expanded, selected, handlers: {onClick}, actionButton}) =>
    <tr tabIndex={0}
        className={cx('row', 'bodyRow', {bodyRowExpanded: expanded}, { bodyRowSelected: selected })} 
        onClick={() => onClick(rowIndex)} onKeyDown={(e) => {if (!Utils.isKeyDownAndNotEnter(e)) {onClick(rowIndex)}}}>
        <td className={cx('expand', 'table-cell')}>
            <div className={cx('cell-wrapper')}>
                <button className={cx('inline')}
                    title={t('expandCollapse')} 
                    aria-label={t('expandCollapse')} 
                    aria-controls={instance.timeSeriesIdKey + "_details"}
                    aria-expanded={expanded}> 
                    <Icon 
                        id={cx('caret-down')} 
                        className={cx('icon12', 'expandIcon', { rotated: expanded })} />
                </button>
            </div>
        </td>
        <InstancesTableCell sortable={true}>
            <span title={Utils.getTimeSeriesIdString(instance)}>
                {Utils.getFormattedHtml(instance.highlightedTimeSeriesId ? Utils.getHighlightedTimeSeriesIdToDisplay(instance) : Utils.getTimeSeriesIdToDisplay(instance), {monoClassName: cx('_base_mono')})}
            </span>
        </InstancesTableCell>
        <InstancesTableCell sortable={true}>{instance.name ? Utils.getFormattedHtml(instance.name) : "-"}</InstancesTableCell>
        <InstancesTableCell sortable={false}>{instance.description ? Utils.getFormattedHtml(instance.description) : "-"}</InstancesTableCell>
        <InstancesTableCell sortable={true}>{Utils.getFormattedHtml(instance.typeId ? types[types.findIndex(t => t.id === instance.typeId)].name : (instance.typeName ? instance.typeName : instance.type))}</InstancesTableCell>
        <InstancesTableCell sortable={false}>
            {instance.hierarchyIds && instance.hierarchyIds.length > 0 ? 
                instance.hierarchyIds.map((hId, idx) => {
                    let index = hierarchies.findIndex(x => x.id === hId);
                    if (index !== -1) {
                        return Utils.getFormattedHtml(hierarchies[index].name + ((idx !== instance.hierarchyIds.length - 1) ? ", " : ""));
                    }
                    return null;
                })
            :
                "-"
            }
        </InstancesTableCell>
        <InstancesTableCell sortable={false}>
            {instance.instanceFields && Object.keys(instance.instanceFields).length > 0 ? 
                [Object.keys(instance.instanceFields).map((iF, idx) => {
                    if (iF.indexOf("<hit>") !== -1 || instance.instanceFields[iF].indexOf("<hit>") !== -1) {
                        return Utils.getFormattedHtml(iF + ': ' + instance.instanceFields[iF] + ((idx !== Object.keys(instance.instanceFields).length - 1) ? ", " : ""));
                    }
                    return null;
                }),
                Object.keys(instance.instanceFields).map((iF, idx) => {
                    if (iF.indexOf("<hit>") === -1 && instance.instanceFields[iF].indexOf("<hit>") === -1) {
                        return Utils.getFormattedHtml(iF + ': ' + instance.instanceFields[iF] + ((idx !== Object.keys(instance.instanceFields).length - 1) ? ", " : ""));
                    }
                    return null;
                })]
            : "-"}
        </InstancesTableCell>

        <td className={cx('table-cell', 'actions')}>
            <div className={cx('cell-wrapper')}>
                {actionButton}
            </div>
        </td>
    </tr>;

const InstancesTableCell = ({sortable, children}) => 
    <td className={cx('table-cell', {sortableCell: sortable})}>
        <div className={cx('cell-wrapper')}>
            <span>{children}</span>
        </div>
    </td>;

const createActionButtonsForInstance = ({t, theme, instanceTimeSeriesIdKey, index, handlers: {onEditEntity, onShowEntityJSON, onConfirmDeletion}, isUserContributor, progressMessageFieldID}) => 
    isUserContributor ? 
        [<button key={`edit-${index}`} className={cx('action-button', 'inline')} onClick={onEditEntity(instanceTimeSeriesIdKey)} onKeyDown={onEditEntity(instanceTimeSeriesIdKey)} 
                aria-label={t('tsm.instance.edit')} aria-describedby={progressMessageFieldID} title={t('tsm.instance.edit')}>
            <Icon id={'iconEdit'} className={cx('icon16', theme)}/>
        </button>,
        <button key={`show-${index}`} className={cx('action-button', 'inline')} onClick={onShowEntityJSON(index)} onKeyDown={onShowEntityJSON(index)} 
                aria-label={t('tsm.viewJson')} title={t('tsm.viewJson')}>
            <Icon id={"iconDocument"} className={cx('icon16')}/>
        </button>,
        <button key={`delete-${index}`} className={cx('action-button', 'inline', 'delete')} onClick={e => onConfirmDeletion(false, e, instanceTimeSeriesIdKey)} 
                onKeyDown={e => onConfirmDeletion(false, e, instanceTimeSeriesIdKey)} 
                aria-describedby={progressMessageFieldID}
                aria-label={t('tsm.instance.delete')} title={t('tsm.instance.delete')}>
            <Icon id={'iconDelete'} className={cx('icon16', theme)}/>
        </button>]
    : '';


const InstanceDetails = ({t, theme, instance, instanceIdx, selectedInstanceFieldIndex, handlers: {onClickSubEntitySort, onAddSubEntity, onEditSubEntity, onConfirmDeletionSubEntity}, sort: {sOrder, sColumnKey, sOrderVisible}, isUserContributor, progressMessageFieldID}) =>  
    <>
        {(instance.instanceFields && Object.keys(instance.instanceFields).length !== 0) &&
        <div className={cx('expanded-row-table-container')}>
            <SortableTableContainer
                columns={InstanceFieldsColumns}  
                rows={Object.keys(instance.instanceFields).map(iFName => {return {fieldName: iFName, fieldValue: instance.instanceFields[iFName]}})}
                handleOnSort={(fieldName) => onClickSubEntitySort(fieldName, instanceIdx)}
                canExpandRows={false}
                sortOrder={sOrder}
                sortColumnIndex={InstanceFieldsColumns.findIndex(column => column.key === sColumnKey)}
                ariaLabel={t('tsm.instanceFields')}
                selectedRowIndex={selectedInstanceFieldIndex}
                isSortVisible={sOrderVisible}
                renderRow={(instanceField, index, expanded, selected) => 
                    <InstanceFieldsTableRow 
                        key={`instanceFieldsTableRow-${index}`}
                        instanceField={instanceField}
                        selected={selected}
                        actionButton={createActionButtonsForInstanceField({t, theme, instanceFieldName: instanceField.fieldName, instanceTimeSeriesIdKey: instance.timeSeriesIdKey, index, handlers: {onEditSubEntity, onConfirmDeletionSubEntity}, isUserContributor, progressMessageFieldID})} />
                }/> 
        </div>}
        {isUserContributor &&
        <div className={cx('sub-button')}>
            <button className={cx('action-button')} onClick={onAddSubEntity(instance.timeSeriesIdKey)} aria-label={t('tsm.instance.addInstanceField')}>
                <Icon id={'iconAddBlue'} className={cx('icon16')}/>
                <span>{t('tsm.instance.addInstanceField')}</span>
            </button>
        </div>}
    </>
const InstanceFieldsTableRow = ({instanceField, selected, actionButton}) =>
    <tr className={cx('row', {bodyRowSelected: selected})}>
        <InstancesTableCell sortable={true}>{Utils.getFormattedHtml(instanceField.fieldName)}</InstancesTableCell>
        <InstancesTableCell sortable={true}>{Utils.getFormattedHtml(instanceField.fieldValue)}</InstancesTableCell>

        <td className={cx('table-cell', 'actions')}>
            <div className={cx('cell-wrapper')}>
                {actionButton}
            </div>
        </td>
    </tr>;

const createActionButtonsForInstanceField = ({t, theme, instanceFieldName, instanceTimeSeriesIdKey, index, handlers: {onEditSubEntity, onConfirmDeletionSubEntity}, isUserContributor, progressMessageFieldID}) => 
    isUserContributor ? 
        <>
            <button className={cx('action-button')} onClick={onEditSubEntity(instanceTimeSeriesIdKey, index)} 
                    aria-label={t('tsm.instance.instanceField') + (index + 1) + instanceFieldName + " " + t('tsm.edit')}
                    aria-describedby={progressMessageFieldID} 
                    title={t('tsm.instance.editInstanceField')}><Icon id={'iconEdit'} className={cx('icon16', theme)}/>
            </button>
            <button className={cx('action-button', 'delete')} onClick={e => onConfirmDeletionSubEntity(false, e, instanceTimeSeriesIdKey, index)} 
                    aria-label={t('tsm.instance.instanceField') + (index + 1) + instanceFieldName + " " + t('tsm.delete')}
                    aria-describedby={progressMessageFieldID} 
                    title={t('tsm.instance.deleteInstanceField')}><Icon id={'iconDelete'} className={cx('icon16', theme)}/>
            </button>
        </>
    : '';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import AvailabilityChart from './AvailabilityChart';
import {SET_SEARCHSPAN, TOGGLE_COMPACT_AVAILABILITY, SET_TIMEZONE, GET_QUERYRESULTS, SET_MAINTAIN_INTERVAL, STOP_AVAILABILITY_LONG_POLL, START_AVAILABILITY_LONG_POLL, ZOOM, APPLY_SEARCH_SPAN, TOGGLE_FORCE_COLD } from '../../../constants/ActionTypes';
import { getTheme, getSelectedEnvironmentFqdn } from '../../state/reducers/AppSettingsReducer';
import { getAvailabilityDistribution, getAvailabilityDistributionIsLoading, getSearchSpan, getAvailabilityIsCompact, getTimezone, getActiveQueries, getAvailabilityRerenderTrigger, getValidBucketSizes, getWarmStoreRange, getEnvironmentHasWarmStore, getIsSearchSpanRelative, getForceCold } from '../../state/reducers/AnalyticsReducer';

const mapStateToProps = state => {
    return { 
        availabilityDistribution: getAvailabilityDistribution(state),
        isLoading: getAvailabilityDistributionIsLoading(state),
        theme: getTheme(state),
        searchSpan: getSearchSpan(state),
        queries: getActiveQueries(state),        
        compact: getAvailabilityIsCompact(state),
        timezone: getTimezone(state),
        environmentFqdn: getSelectedEnvironmentFqdn(state),
        availabilityRerenderTrigger: getAvailabilityRerenderTrigger(state),
        validBucketSizes: getValidBucketSizes(state),
        warmStoreRange: getWarmStoreRange(state),
        environmentHasWarmStore: getEnvironmentHasWarmStore(state),
        isSearchSpanRelative: getIsSearchSpanRelative(state),
        forceCold: getForceCold(state)
    };
};

const mapDispatchToProps = dispatch => ({ 
    setSearchSpan: (from, to, bucketSize, validBucketSizes, isSearchSpanRelative) => dispatch({type: SET_SEARCHSPAN, payload: {from, to, bucketSize, validBucketSizes, isSearchSpanRelative}}),
    toggleCompact: () => dispatch({type: TOGGLE_COMPACT_AVAILABILITY}),
    toggleForceCold: () => dispatch({type: TOGGLE_FORCE_COLD}),
    setTimezone: (timezone) => dispatch({type: SET_TIMEZONE, payload: {timezone: timezone}}),
    getQueryResults: () => dispatch({type: GET_QUERYRESULTS, payload: {unsetUnzoomStack: true}}),
    setMaintainedIntervalSize: (intervalSize = null) => dispatch({type: SET_MAINTAIN_INTERVAL, payload: {maintainedIntervalSize: intervalSize}}),
    stopAvailabilityLongPoll: () => dispatch({type: STOP_AVAILABILITY_LONG_POLL}),
    startAvailabilityLongPoll: () => dispatch({type: START_AVAILABILITY_LONG_POLL}),
    zoom: (fromMillis, toMillis, direction = null) => {
        dispatch({type: ZOOM, payload: {from: fromMillis, to: toMillis, direction: direction}})
        dispatch({type: APPLY_SEARCH_SPAN});
    },
});

const AvailabilityChartContainer = translate()(connect(mapStateToProps, mapDispatchToProps)(AvailabilityChart));

export default AvailabilityChartContainer;

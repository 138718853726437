import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import AnalyticsWorkspace from './AnalyticsWorkspace';
import { getTheme, getSelectedEnvironmentIsLts } from '../../state/reducers/AppSettingsReducer';
import { getActiveQueries, getIsLoadingEvents, getChartType, getLoadingAggregatesProgress, getModalOptions, getPreviousSessionState, getBrushedRegionStatisticsState, getIsLoadingAggregates, getChartSettingsState} from '../../state/reducers/AnalyticsReducer';
import { CLOSE_MODAL, RESTORE_PREVIOUS_SESSION, UPDATE_JUMBOTRON_STATE } from '../../../constants/ActionTypes';
import Utils from '../../services/Utils';
import { CollapseMode } from '../../../constants/Enums';

const mapStateToProps = state => {
    return { 
        events: state.analytics.events,
        theme: getTheme(state),
        isLoadingAggregates: getIsLoadingAggregates(state),
        isLoadingEvents: getIsLoadingEvents(state),
        queries: getActiveQueries(state),
        environmentIsLts: getSelectedEnvironmentIsLts(state),
        chartType: getChartType(state),
        loadingAggregatesProgress: getLoadingAggregatesProgress(state),
        modalOptions: getModalOptions(state),
        previousSessionState: getPreviousSessionState(state),
        brushedRegionStatisticsState: getBrushedRegionStatisticsState(state),
        chartSettingsState: getChartSettingsState(state)
    };
};

const mapDispatchToProps = dispatch => ({ 
    closeModal: () => {dispatch({type: CLOSE_MODAL})},
    restorePreviousSession: () => {dispatch({type: RESTORE_PREVIOUS_SESSION})},
    toggleJumboOff: () => {
        dispatch({type: UPDATE_JUMBOTRON_STATE, payload: {target: CollapseMode.collapse}});
        Utils.triggerResize(500);
    }
});

const AnalyticsWorkspaceContainer = translate()(connect(mapStateToProps, mapDispatchToProps)(AnalyticsWorkspace));

export default AnalyticsWorkspaceContainer;

import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { getTheme } from '../../../state/reducers/AppSettingsReducer';
import SwimlaneSettings from './SwimlaneSettings';
import { 
    SET_SWIMLANE_FIELD, 
    ADD_SWIMLANE_HORIZONTAL_MARKER,
    UPDATE_SWIMLANE_HORIZONTAL_MARKER,
    REMOVE_SWIMLANE_HORIZONTAL_MARKER
} from '../../../../constants/ActionTypes';
import { 
    getChartSettingsState, 
    getTimeSeriesQueries, 
    getAggregatesQueries,
    getSwimLaneOptions, 
    getLinechartStackState,
    getActiveVisualizationReference } from '../../../state/reducers/AnalyticsReducer';
import { QueryTypes, YAxisState } from '../../../../constants/Enums';
import TimeSeriesQuery from '../../../models/TimeSeriesQuery';
import AggregatesQuery from '../../../models/AggregatesQuery';

const mapStateToProps = state => {
    const timeSeriesQueries: Array<TimeSeriesQuery> = getTimeSeriesQueries(state); 
    const aggregateQueries: Array<AggregatesQuery> = getAggregatesQueries(state);

    return { 
        theme: getTheme(state),
        chartSettings: getChartSettingsState(state),
        queries: timeSeriesQueries.length ? timeSeriesQueries : aggregateQueries,
        queriesType: timeSeriesQueries?.length ? QueryTypes.TimeSeries : QueryTypes.Aggregates,
        activeVisualizationRef: getActiveVisualizationReference(state),
        laneOptions: getSwimLaneOptions(state),
        selectedLane: getChartSettingsState(state)?.activeSwimlane,
        linechartStackState: getLinechartStackState(state),
    };
};

const mapDispatchToProps = dispatch => ({ 
    setSwimlaneField: (swimlane: string, field: string, value: any) => {
        dispatch({type: SET_SWIMLANE_FIELD, payload: {swimlane: swimlane, field: field, value: value}});        
    },
    addHorizontalMarker: (swimlane: string) => {
        dispatch({
            type: ADD_SWIMLANE_HORIZONTAL_MARKER,
            payload: { swimlane, value: '', color: '' }
        });
    },
    updateHorizontalMarker: (swimlane: string, markerIndex: number, value: number | string, color: string) => {
        dispatch({
            type: UPDATE_SWIMLANE_HORIZONTAL_MARKER,
            payload: { swimlane, markerIndex, value, color: color || '#008272' }
        });
    },
    removeHorizontalMarker: (swimlane: string, markerIndex: number) => {
        dispatch({
            type: REMOVE_SWIMLANE_HORIZONTAL_MARKER,
            payload: { swimlane, markerIndex }
        });
    },
    setYAxisState: (swimlane: string, state: YAxisState) => {
        dispatch({type: SET_SWIMLANE_FIELD, payload: {swimlane, field: 'yAxisExtentState', value: state}})
        // If setting back to auto, clear custom yExtent
        if(state === YAxisState.auto){
            dispatch({type: SET_SWIMLANE_FIELD, payload: {swimlane, field: 'yExtent', value: null}})
        }
    },
    setYAxisCustomExtents: (swimlane: string, min: Number, max: Number) => {
        if(max > min){
            dispatch({type: SET_SWIMLANE_FIELD, payload: {swimlane, field: 'yExtent', value: [min,max]}})
        }
    }
});

const SwimlaneSettingsContainer = translate()(connect(mapStateToProps, mapDispatchToProps)(SwimlaneSettings));

export default SwimlaneSettingsContainer;

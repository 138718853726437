import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import TsmEntities from './TsmEntities';
import {ADD_ENTITY, PUT_TYPE, PUT_HIERARCHY, PUT_INSTANCES, BULK_UPLOAD, SEARCH_INSTANCES, CLOSE_TSM_MODAL, CLEAR_PROGRESS, 
        SELECT_ENTITY_TYPE, GET_MODEL_TYPES, GET_MODEL_HIERARCHIES, SET_TSM_ENTITY_SORT, SET_TSM_SUBENTITY_SORT, SELECT_ENTITY, 
        EDIT_ENTITY, EDIT_SUBENTITY, SELECT_SUB_ENTITY, ADD_SUBENTITY, DOWNLOAD_ENTITIES} from '../../../constants/ActionTypes.js';
import { getSelectedEntities, getTypes, getSelectedEntityType, getSelectedEntity, isTsmModalVisible, getInstancesContinuationToken, getSearchText, 
        getEntityTypeLoadingStatus, getHierarchies, getProgress, getErrorMessage, getRequestMethod, getErrorCode, getTSMEntitySortField, getTSMEntitySortOrder, 
        getSelectedEntityId, getTSMSubEntitySortField, getTSMSubEntitySortOrder, getTSMSubEntitySortParentIdx, getSelectedSubEntityIdx, getDownloadStatus } from '../../state/reducers/ModelReducer';
import { getTheme, getSelectedEnvironment, getIsUserContributor } from '../../state/reducers/AppSettingsReducer';
import TelemetryService from '../../services/TelemetryService'; 
import { TSMEntityKinds } from '../../../constants/Enums';

const mapStateToProps = state => {
    return { 
        theme: getTheme(state),
        selectedEntityType: getSelectedEntityType(state),
        selectedEntity: getSelectedEntity(state),
        selectedEntityId: getSelectedEntityId(state),
        selectedSubEntiyIdx: getSelectedSubEntityIdx(state),
        selectedEntities: getSelectedEntities(state),
        types: getTypes(state),
        hierarchies: getHierarchies(state),
        isTsmModalVisible: isTsmModalVisible(state),
        environment: getSelectedEnvironment(state),
        instancesContinuationToken: getInstancesContinuationToken(state),
        searchText: getSearchText(state),
        isLoadingEntityType: getEntityTypeLoadingStatus(state),
        progress: getProgress(state),
        errorCode: getErrorCode(state),
        errorMessage: getErrorMessage(state),
        requestMethod: getRequestMethod(state),
        tsmEntitySortField: getTSMEntitySortField(state),
        tsmEntitySortOrder: getTSMEntitySortOrder(state),
        tsmSubEntitySortField: getTSMSubEntitySortField(state),
        tsmSubEntitySortOrder: getTSMSubEntitySortOrder(state),
        tsmSubEntitySortParentIdx: getTSMSubEntitySortParentIdx(state),
        downloadStatus: getDownloadStatus(state),
        isUserContributor: getIsUserContributor(state)
    };
};

const mapDispatchToProps = dispatch => ({
    closeEntityDrawer: () => dispatch({type: CLOSE_TSM_MODAL}),
    addEntity: () => dispatch({type: ADD_ENTITY}),
    editEntity: (entityId) => dispatch({type: EDIT_ENTITY, payload: {entityId: entityId}}),
    bulkUpload: () => dispatch({type: BULK_UPLOAD}),
    selectEntity: (entityId) => dispatch({type: SELECT_ENTITY, payload: {entityId: entityId}}),
    selectSubEntity: (subEntityIdx) => dispatch({type: SELECT_SUB_ENTITY, payload: {subEntityIdx: subEntityIdx}}),
    editSubEntity: (entityId, subEntityIdx) => dispatch({type: EDIT_SUBENTITY, payload: {entityId: entityId, subEntityIdx: subEntityIdx}}),
    addSubEntity: (entityId) => dispatch({type: ADD_SUBENTITY, payload: {entityId: entityId}}),
    putType: (type, method : string = "") => {
        dispatch({type: PUT_TYPE, payload: {entity: type, method: method}});
        TelemetryService.logUserAction('deleteType');
    },
    putHierarchy: (hierarchy, method : string = "") => {
        dispatch({type: PUT_HIERARCHY, payload: {entity: hierarchy, method: method}});
        TelemetryService.logUserAction('deleteHierarchy');
    },
    putInstances: (instances, method : string = "", searchText) => {
        dispatch({type: PUT_INSTANCES, payload: {instances: instances, method: method, searchText: searchText}});
        TelemetryService.logUserAction('deleteInstance');
    },
    searchInstances: (search) => {
        dispatch({type: SEARCH_INSTANCES, payload: search});
        TelemetryService.logUserAction('searchInstance', {searchText: search.searchText});
    },
    clearProgress: () => dispatch({type: CLEAR_PROGRESS}),
    selectEntityType: (entityType) => {
        dispatch({type: SELECT_ENTITY_TYPE, payload: {selectedEntityType: entityType}});
        if (entityType === TSMEntityKinds.Types) {
            dispatch({type: GET_MODEL_TYPES});
        } else if (entityType === TSMEntityKinds.Hierarchies) {
            dispatch({type: GET_MODEL_HIERARCHIES});
        } else {
            dispatch({type: GET_MODEL_TYPES, payload: {isForSearch: true}});
            dispatch({type: GET_MODEL_HIERARCHIES, payload: {isForSearch: true}});
            dispatch({type: SEARCH_INSTANCES, payload: {searchText: '', continuationToken: null}});
        }
    },
    setTSMEntitySort: (field, sortOrder) => dispatch({type: SET_TSM_ENTITY_SORT, payload: {sortField: field, sortOrder: sortOrder}}),
    setTSMSubEntitySort: (entityIdx, field, sortOrder) => dispatch({type: SET_TSM_SUBENTITY_SORT, payload: {entityIdx: entityIdx, sortField: field, sortOrder: sortOrder}}),
    downloadEntities: (entityKind) => dispatch({type: DOWNLOAD_ENTITIES, payload: {kind: entityKind}})
});
const TsmEntitiesContainer = translate()(connect(mapStateToProps, mapDispatchToProps)(TsmEntities));

export default TsmEntitiesContainer;
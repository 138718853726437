import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import Well from './Well';
import {SHOW_HIDE_TIMESERIESQUERY, REMOVE_TIMESERIESQUERY, REMOVE_ALL_TIMESERIESQUERIES, SET_TSQ_SORT, GET_QUERYRESULTS, 
        STICKY_TIMESERIESQUERY, UNSTICKY_TIMESERIESQUERIES,  SET_MODAL_OPTIONS, CLOSE_MODAL, 
        SERIALIZE_STATE_TO_LOCAL_STORAGE, SET_QUERY_FIELD, SET_TSID_FOR_LOOKUP, 
        SWAP_IN_TSQ, DUPLICATE_TIMESERIESQUERY, OPEN_FEEDBACK_FORM, SET_CHART_SETTINGS_STATE, UPDATE_JUMBOTRON_STATE,
        SET_MARKERS, RESET_SWIMLANE_OPTIONS, REFRESH_PREVIOUS_SESSION_STATE, CLEAR_SAVED_QUERY_NAME} 
    from '../../../constants/ActionTypes';
import { getTimeSeriesQueries, getTSQFields, getTSQSortField, getTSQSortDescending, getIsLoadingSavedTsq, 
         getValueElementFocusTrigger, getValueElementUnfocusTrigger, getValueElementStickyTrigger, getStickiedTSQ, 
         getScatterMeasures, getIsLoadingAggregates, getMetadata, getInstancesArray, getTimezone, getJumbotronState} 
    from '../../state/reducers/AnalyticsReducer';
import { getTheme, getSelectedEnvironmentId } from '../../state/reducers/AppSettingsReducer';
import { QueryTypes, ChartSettingsTypes, CollapsableComponents } from '../../../constants/Enums';
import TimeSeriesQuery from '../../models/TimeSeriesQuery';
import { getTypes } from '../../state/reducers/ModelReducer';
import { ChartSettingsState } from '../../models/Interfaces';

const mapStateToProps = state => {
    return { 
        timeSeriesQueries: getTimeSeriesQueries(state),
        tsqFields: getTSQFields(state),
        envId: getSelectedEnvironmentId(state),
        theme: getTheme(state),
        tsqSortField: getTSQSortField(state),
        tsqSortDescending: getTSQSortDescending(state),
        isLoadingSavedTsq: getIsLoadingSavedTsq(state),
        focusTrigger: getValueElementFocusTrigger(state),
        unfocusTrigger: getValueElementUnfocusTrigger(state),
        stickyTrigger: getValueElementStickyTrigger(state),
        stickiedTSQ: getStickiedTSQ(state),
        scatterMeasures: getScatterMeasures(state),
        isLoadingAggregates: getIsLoadingAggregates(state),
        types: getTypes(state),
        metadata: getMetadata(state),
        instances: getInstancesArray(state),
        timezone: getTimezone(state), 
        jumbotronState: getJumbotronState(state)
    };
};

const mapDispatchToProps = dispatch => ({ 
    setTSQColor: (idx, colorValue) => {
        dispatch({type: SET_QUERY_FIELD, payload: {type: QueryTypes.TimeSeries, idx: idx, field: 'color', value: colorValue}});
        dispatch({type: SERIALIZE_STATE_TO_LOCAL_STORAGE});
    },
    removeTimeSeriesQuery: (idx) => {
        dispatch({type: REMOVE_TIMESERIESQUERY, payload: idx});
        dispatch({type: SERIALIZE_STATE_TO_LOCAL_STORAGE});
    },
    removeAllTimeSeriesQueries: () => {
        dispatch({type: REMOVE_ALL_TIMESERIESQUERIES});
    },
    clearLineChartMarkers: () => {
        dispatch({type: SET_MARKERS, payload: []});
    },
    resetSwimlaneOptions: () => {
        dispatch({type: RESET_SWIMLANE_OPTIONS });
    },
    showHideTimeSeriesQuery: (idx) => dispatch({type: SHOW_HIDE_TIMESERIESQUERY, payload: idx}),
    setTSQSort: (field, isDescending) => dispatch({type: SET_TSQ_SORT, payload: {sortField: field, isDescending: isDescending}}),
    setTimeShift: (idx, timeShift) => {
        dispatch({type: SET_QUERY_FIELD, payload: {type: QueryTypes.TimeSeries, idx: idx, field: 'timeShift', value: timeShift}});
        dispatch({type: GET_QUERYRESULTS});
    },
    stickyTSQ: (aggKey) => { dispatch({type: STICKY_TIMESERIESQUERY, payload: aggKey}); },
    unstickyTSQ: () => { dispatch({type: UNSTICKY_TIMESERIESQUERIES})},
    setModalOptions: (tsqI, focusModalX, focusModalY, modalType, fromWell, caretDirection, sourceElement: any = null) => {
        let options = {tsqI, focusModalX, focusModalY, modalType, fromWell, caretDirection, sourceElement};
        dispatch({type: SET_MODAL_OPTIONS, payload: options})
    },
    closeModal: () => {dispatch({type: CLOSE_MODAL})},
    setTSQField: (idx, field, value) => {dispatch({type: SET_QUERY_FIELD, payload: {type: QueryTypes.TimeSeries, idx: idx, field: field, value: value}})},
    getQueryResults: () => dispatch({type: GET_QUERYRESULTS}),
    setSwimLane: (idx, swimLane) => {
        dispatch({type: SET_QUERY_FIELD, payload: {type: QueryTypes.TimeSeries, idx: idx, field: 'swimLane', value: swimLane}});
        dispatch({type: SET_TSQ_SORT, payload: {sortField: null, isDescending: true}});
    },
    setTSIDforLookup: (tsid) => {
        dispatch({type: SET_TSID_FOR_LOOKUP, payload: tsid});
    },
    duplicateTimeSeries: (idx: number, tsq: TimeSeriesQuery) => {
        dispatch({type: DUPLICATE_TIMESERIESQUERY, payload: tsq});
        dispatch({type: GET_QUERYRESULTS});
    },
    setTSQVariable: (idx: number, tsq: any, variable: any) => {
        let updatedTsq = TimeSeriesQuery.constructFromInstanceTypeVariable(variable.type, tsq.instanceObject, variable.name, tsq.color, {swimLane: tsq.swimLane});
        dispatch({type: SWAP_IN_TSQ, payload: {idx: idx, tsq: updatedTsq}})
        dispatch({type: GET_QUERYRESULTS});
    },
    openFeedbackForm: (issue: string, requestId: string, responseText: string): void => dispatch({ 
        type: OPEN_FEEDBACK_FORM,
        payload: { issue, requestId, responseText }
    }),
    setChartSettingsToSeries: (series: TimeSeriesQuery, tsqI: number): void => {
        let chartSettings: ChartSettingsState = {
            isOpen: true,
            type: ChartSettingsTypes.Series,
            activeTSQ: series,
            activeTSQI: tsqI
        }
        dispatch({
            type: SET_CHART_SETTINGS_STATE,
            payload: chartSettings
        });
    },
    updateCollapseState: (isCollapsed: boolean) => {
        dispatch({type: UPDATE_JUMBOTRON_STATE, payload: {target: CollapsableComponents.well, isCollapsed}})
    },
    refreshPreviousSessionState: () => {
        dispatch({type: REFRESH_PREVIOUS_SESSION_STATE});
    },
    clearSavedQueryName: () => {
        dispatch({type: CLEAR_SAVED_QUERY_NAME});
    }
});

const WellContainer = translate()(connect(mapStateToProps, mapDispatchToProps)(Well));

export default WellContainer;

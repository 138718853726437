/**
 * Represents a instance field in an instance.
 * @constructor
 * @param {string} name - The name of the instance field.
 * @param {string} value - The value of the instance field.
 * @param {{}} [errors] - Errors in the instance field.
 */
export class InstanceField {
    public name: string;
    public value: string;
    public errors?: {};

    constructor() {
        this.name = "";
        this.value = "";
    }

    static fromObject (obj: {name, value?}) {
        let iF = new InstanceField();
        iF.name = obj.name;
        if (obj.value) {
            iF.value = obj.value;
        }
        return iF;
    }

    public updateErrors() {
        if (!this.name) {
            if (!this.errors) {
                this.errors = {};
            }
            this.errors["name"] = true;
        } else {
            if (this.errors && this.errors["name"]) {
                delete this.errors["name"];
            }
        }
    }

    public errorCount() {
        let errorCount = 0;
        if (this.errors) {
            errorCount += Object.keys(this.errors).length;
        }

        return errorCount;
    }

    public addError(errorEnum) {
        if (!this.errors) {
            this.errors = {};
        }
        this.errors[errorEnum] = true;
    }

    public removeError(errorEnum) {
        if (this.errors) {
            delete this.errors[errorEnum];
        }
    }
}
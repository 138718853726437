import { Variable, VariableAggregationOperations_Numeric, VariableAggregationOperations_Aggregate } from "./Variable";
import { VariableCategory } from "./VariableCategory";
import { TSMCategoricalValueKind } from "../../constants/Enums";

/**
 * Represents a type.
 * @constructor
 * @param {string} [id] - ID of the type.
 * @param {string} name - The name of the type.
 * @param {string} description - The description of the type.
 * @param {Variable[]} [variables] - Variables defined for the type.
 * @param {{}} [errors] - Errors in the type, see TypeEntityErrors enums for details.
 */
export default class TypeEntity {
    public id?: string;
    public name: string;
    public description?: string;
    public variables: Variable[];
    public errors?: {};

    constructor() {
        this.name = "";
        this.variables = [];
    }

    static fromObject = (obj: any, valueOptions: any) => {
        let te = new TypeEntity();
        te.id = obj.id;
        te.name = obj.name;
        te.description = obj.description;
        
        let variables: Variable[] = [];
        if (obj.variables) {    /** TODO: This check is temporary, serer side will already not allow a type existing without a variable */
            Object.keys(obj.variables).forEach(e => {
                let variable = new Variable(valueOptions);
                variable.name = e;
                variable.kind = obj.variables[e]['kind'];
                variable.value = obj.variables[e]['value'];
                variable.isValueFreeform = !(variable.value && valueOptions.indexOf(variable.value.tsx) !== -1);
                variable.filter = obj.variables[e]['filter'];
                variable.interpolation = obj.variables[e]['interpolation'];
                variable.aggregation = obj.variables[e]['aggregation'];
                if (obj.variables[e]['defaultCategory']) {
                    variable.defaultCategory = VariableCategory.fromObject(obj.variables[e]['defaultCategory']);
                }
                if (obj.variables[e]['categories']) {
                    variable.categories = obj.variables[e]['categories'].map(c => VariableCategory.fromObject(c));
                    if (typeof obj.variables[e]['categories'][0]['values'][0] === "string") 
                        variable.categoryValueKind = TSMCategoricalValueKind.String;
                    else
                        variable.categoryValueKind = TSMCategoricalValueKind.Integer; //TODO: or long?
                }
                variable.isAggregationOperationFreeform = !(variable.aggregation && VariableAggregationOperations_Numeric.concat(VariableAggregationOperations_Aggregate).indexOf(variable.aggregation.tsx) !== -1);
                variables.push(variable);
            });
    
            te.variables = variables;
        } 
        return te;
    }

    public convertToJSON() {
        let data = {};
        data["put"] = [];

        let type = {};
        if (this.id) {
            type["id"] = this.id;
        }
        type["name"] = this.name;
        if (this.description) {
            type["description"] = this.description;
        }

        let variables = {};
        this.variables.forEach(v => {
            let variable = {};
            variable["kind"] = v.kind;
            variable["value"] = v.value;
            variable["filter"] = v.filter ? v.filter : null;
            variable["interpolation"] = v.interpolation && v.interpolation.kind ? v.interpolation : null;
            variable["aggregation"] = v.aggregation;
            variable["categories"] = v.categories;
            variable["defaultCategory"] = v.defaultCategory;
            if (v["categories"]) {
                variable["categories"].forEach((c) => {
                    c.values.forEach((value, idx) => {
                        if(value === "null" || value === null)
                            c.values[idx] = null;
                        else if (v["categoryValueKind"] === TSMCategoricalValueKind.Integer)
                            c.values[idx] = Number(value);
                        else 
                            c.values[idx] = value.toString();
                    });
                });
            }
            
            variables[v.name] = variable;
        });
        type["variables"] = variables;

        data["put"].push(type);
        
        return JSON.stringify(data, null, 2);
    }

    public addVariable(obj, valueOptions: any) {
        let variable;
        if (obj) {
            variable = Variable.fromObject(obj, valueOptions);
        } else {
            variable = new Variable (valueOptions);
        }
        if (!this.variables) {
            this.variables = [];
        }

        this.variables.push(variable);
    }

    public hasErrors() {
        return this.errorCount() > 0;
    }

    public updateErrors() {
        if (this.name === "") {
            if (!this.errors) {
                this.errors = {};
            }
            this.errors["name"] = true;
        } else {
            if (this.errors && this.errors["name"]) {
                delete this.errors["name"];
            }
        }

        if (!this.variables || (this.variables && this.variables.length === 0)) {
            if (!this.errors) {
                this.errors = {};
            }
            this.errors["variables"] = true;
        } else {
            if (this.errors && this.errors["variables"]) {
                delete this.errors["variables"];
            }
            this.variables.forEach((a, idx) => {
                a.updateErrors();
            });

            let uniqueName = true;
            this.variables.forEach((a, idx) => {
                this.variables.forEach((a2, idx2) => {
                    if (idx !== idx2) {
                        if (a.name && a.name === a2.name) {
                            a.addError("name_unique");
                            uniqueName = false;
                        }    
                    }
                });
                if (uniqueName) {
                    a.removeError("name_unique");
                }
            });
        }
    }

    public errorCount() {
        let errorCount = 0;
        if (this.errors) {
            errorCount += Object.keys(this.errors).length;
        }

        if (this.variables) {
            this.variables.forEach((a) => {
                errorCount += a.errorCount();
            });
        }

        return errorCount;
    }

    public addError(errorEnum) {
        if (!this.errors) {
            this.errors = {};
        }
        this.errors[errorEnum] = true;
    }
}
import { translate } from "react-i18next"
import { connect } from "react-redux"
import { CLEAR_SHORTLINK, GET_SHORTLINK, SET_AUTO_REFRESH, SET_CHART_SETTINGS_STATE, SET_ISSAVEOPENSHAREVISIBLE } from "../../../constants/ActionTypes"
import { ChartSettingsTypes } from "../../../constants/Enums"
import { getAutoRefreshCycleMillis, getAutoRefreshTimeSpanMillis, getAvailabilityDistributionIsLoading, getEnvironmentHasWarmStore, getIsAutoRefreshEnabled, getIsSaveOpenShareVisible, getWarmStoreRange } from "../../state/reducers/AnalyticsReducer"
import { getSelectedEnvironmentIsLts, getTheme } from "../../state/reducers/AppSettingsReducer"
import AnalyticsCommandBar from "./AnalyticsCommandBar"

const mapStateToProps = state => {
    return {
        theme: getTheme(state),
        isSaveOpenShareVisible: getIsSaveOpenShareVisible(state),
        isAvailabilityDistributionIsLoading: getAvailabilityDistributionIsLoading(state),
        isPaygEnvWithoutWarmStore: getSelectedEnvironmentIsLts(state) && (getWarmStoreRange(state) === null || !getEnvironmentHasWarmStore(state)),
        autoRefreshCycleMillis: getAutoRefreshCycleMillis(state),
        autoRefreshTimeSpanMillis: getAutoRefreshTimeSpanMillis(state),
        isAutoRefreshEnabled: getIsAutoRefreshEnabled(state),
        environmentIsLts: getSelectedEnvironmentIsLts(state),
        warmStoreRange: getWarmStoreRange(state),
        environmentHasWarmStore: getEnvironmentHasWarmStore(state),
    }
}

const mapDispatchToProps = dispatch => ({
    setIsSaveOpenShareVisible: visible => {
        dispatch({type: SET_ISSAVEOPENSHAREVISIBLE, payload: visible})
    },
    getShortlink: () => {
        dispatch({type: CLEAR_SHORTLINK});
        dispatch({type: GET_SHORTLINK});
    },
    clearShortlink: () => {
        dispatch({type: CLEAR_SHORTLINK});
    },
    setChartSettingsToAutoRefresh: () => {
        dispatch({type: SET_CHART_SETTINGS_STATE, payload: {
            isOpen: true,
            type: ChartSettingsTypes.AutoRefresh
        }})
    },
    cycleAutoRefresh: (isRefreshEnabled) => {
        if(isRefreshEnabled){
            dispatch({type: SET_AUTO_REFRESH, payload: false});
            dispatch({type: SET_AUTO_REFRESH, payload: true});
        }
    }
})

export default translate()(connect(mapStateToProps, mapDispatchToProps)(AnalyticsCommandBar))
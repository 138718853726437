import update from 'immutability-helper';
import { 
  GET_ALL_JOBS_FULFILLED, 
  GET_ALL_JOBS, 
  UPDATE_SORT_ORDER, 
  CREATE_JOB_FULFILLED, 
  MODIFY_JOB_FULFILLED,
  GET_JOB_LOGS,
  GET_JOB_LOGS_FULFILLED
} from '../../../constants/ActionTypes';
import { JobInfo, JobsPageStatus } from '../../models/JobsModels';
import { SortOrder } from '../../components/SortableTable/SortableTable';
import { JobsColumns } from '../../pages/Jobs/Jobs';

class JobsState {
  status: JobsPageStatus;
  jobsList: Array<JobInfo>;
  sortOrder: SortOrder;
  sortedColumnIndex: number;
  callFailedWith500: boolean;
  jobLogs: Array<any>;
  jobLogsErrorMessage: string;

  constructor() {
    this.status = JobsPageStatus.Loading;
    this.jobsList = [];

    // By default sort by job creation time starting from most recent.
    this.sortOrder = SortOrder.Descending;
    this.sortedColumnIndex = JobsColumns.findIndex(col => col.key === 'created');

    this.callFailedWith500 = false;
    this.jobLogs = null;
    this.jobLogsErrorMessage = null;
  }
}

function jobsReducer(state = new JobsState(), action) {
  let newStatus: JobsPageStatus, newJobsList: Array<JobInfo>;
  switch(action.type) {
    case GET_ALL_JOBS:
      let isRefresh = action.payload && action.payload.isRefresh;
      return update(state, {
        status: { $set: isRefresh ? JobsPageStatus.Refreshing : JobsPageStatus.Loading }
      });

    case GET_ALL_JOBS_FULFILLED:
      let newCallFailedWith500 = false;
      if(action.payload.error === null) {
        newStatus = JobsPageStatus.Loaded;
      } else {
        newStatus = JobsPageStatus.Error;
        newCallFailedWith500 = action.payload.error.status === 500;
      }
      
      return update(state, {
        status: { $set: newStatus },
        jobsList: { $set: action.payload.jobsList },
        callFailedWith500: { $set: newCallFailedWith500 }
      });      

    case MODIFY_JOB_FULFILLED:
      let updatedJob = action.payload || {};
      let updatedJobIndex = state.jobsList.findIndex(job => job.name === updatedJob.name);

      if(updatedJobIndex === -1) {
        return state;
      }

      let updatedJobsList = [...state.jobsList];
      updatedJobsList.splice(updatedJobIndex, 1, updatedJob);

      return update(state, {
        jobsList: { $set: updatedJobsList }
      })

    case CREATE_JOB_FULFILLED:
      newJobsList = [...state.jobsList];

      // If it's a child job, add it to the parent otherwise, add it to the top level
      if(action.payload.parentJobName) {
        let parentJob = newJobsList.find(job => job.name === action.payload.parentJobName);
        parentJob.appendChild(action.payload);
      } else {
        newJobsList.unshift(action.payload);
      }

      return update(state, {
        jobsList: { $set: newJobsList }
      });

    case UPDATE_SORT_ORDER:
      return update(state, {
        sortOrder: { $set: action.payload.sortOrder },
        sortedColumnIndex: { $set: action.payload.sortedColumnIndex }
      });

    case GET_JOB_LOGS:
      // We set logs to null so that the log viewer displays a 'loading' message. Without this,
      // it's not clear that the button press did anything if the contents of the logs remain
      // the same after fetching.
      return update(state, {
        jobLogs: { $set: null }
      });

    case GET_JOB_LOGS_FULFILLED:
      return update(state, {
        jobLogs: { $set: action.payload.logs },
        jobLogsErrorMessage: { $set: action.payload.errorInfo ? 'jobs.errorGettingLogs' : null }
      });

    default: return state;
  }
}

export const getJobs = state => state.jobs;
export const getJobsList = state => getJobs(state).jobsList;
export const getJobsPageStatus = state => getJobs(state).status;
export const getJobsSortOrder = state => getJobs(state).sortOrder;
export const getJobsSortedColumIndex = state => getJobs(state).sortedColumnIndex;
export const getCallFailedWith500 = state => getJobs(state).callFailedWith500;
export const getJobLogs = state => getJobs(state).jobLogs;
export const getJobLogsErrorMessage = state => getJobs(state).jobLogsErrorMessage;

export default jobsReducer;
import { from } from 'rxjs/observable/from';
import { i18nInstance } from '../../i18n';
import AuthService from '../services/AuthService';
import NotificationService from '../services/NotificationService';
import TelemetryService from '../services/TelemetryService';
const Msal = require('msal');

export default class MsalAuthService {

    private getTokenCalls: any = [];
    private gettingToken: boolean = false;
    private authContext; 
    private executeGetTokenSequentially = true;

    constructor(authContextConfig){
        this.authContext = new Msal.UserAgentApplication(authContextConfig);
        this.authContext.handleRedirectCallback(() => {});
    }

    public login = (continuation) => {
        if (window.self === window.top) { 
            let user = this.authContext.getAccount();
            if (user) {
                continuation(user.name, user.userName);
            }
            else {

                // in the event that loginRedirect does not actually redirect me because of an existing adal token, attempt the continuation
                this.authContext.handleRedirectCallback((error, response) => {
                    console.log('Authentication: loginRedirect without redirect flow');
                    console.log(error);
                    console.log(response);
                    let user = this.authContext.getAccount();
                    if (user) {
                        continuation(user.name, user.userName);
                    }
                });

                this.authContext.loginRedirect({});  
            }
        }
    }

    public logout = () => {
        this.authContext.logout();
    }

    private shiftAndExecuteGetTokenCall = () => {
        let call = this.getTokenCalls.shift();
        if (call) {
            call.call();
        }
    }

    private createGetTokenCall = (scope: any, resolve, reject, allowParallelGetTokenAfterComplete) => {
        let resolveToken = ({accessToken}) => {
            if(allowParallelGetTokenAfterComplete){
                this.executeGetTokenSequentially = false;
            }
            this.gettingToken = false;
            resolve(accessToken);
            this.shiftAndExecuteGetTokenCall();
        };

        return () => {
                this.gettingToken = true;
                this.authContext.acquireTokenSilent(scope)
                .then(resolveToken)
                .catch(error => {
                    if(this.requiresInteraction(error.errorCode)){
                        AuthService.onAtpCall();
                        this.authContext.acquireTokenPopup(scope).then(resolveToken).catch(error => {
                            console.error(error); 
                            resolveToken(error);
                        })
                    }
                    else{
                        console.error(error); 
                        NotificationService.showErrorNotification(i18nInstance.t('errorRetrievingToken'), i18nInstance.t('errorRetrievingTokenMessage', {error: JSON.stringify(error).split(':').join(';')}), null);
                        TelemetryService.logUserAction('errorRetrievingToken', {error: error});
                        resolveToken(error);
                    }
                });
            }
    }

    private getGenericTokenPromiseCallback = (scope: any, allowParallelGetTokenAfterComplete = false) => {
        if(AuthService.tenantId !== `${AuthService.getConstantValue('login')}organizations` && !scope.authority){
            scope.authority = `${AuthService.getConstantValue('login')}${AuthService.tenantId}`;
        }
        return (resolve, reject) => {
            let getTokenCall = this.createGetTokenCall(scope, resolve, reject, allowParallelGetTokenAfterComplete);
            this.getTokenCalls.push(getTokenCall);
            if (!this.gettingToken || !this.executeGetTokenSequentially) {
                this.shiftAndExecuteGetTokenCall();
            }
        };
    }

    public getTsiTokenPromise = (clientId) => new Promise(this.getGenericTokenPromiseCallback({scopes: [clientId + '/.default']}));

    public getManagementTokenPromise = (management) => new Promise(this.getGenericTokenPromiseCallback({
        scopes: [management + '/.default']}));

    public getTsiToken = (clientId) => {
        return from(this.getTsiTokenPromise(clientId));
    }

    public getManagementToken = (management) => {
        return from(new Promise(this.getGenericTokenPromiseCallback({
            scopes: [management + '/.default'], 
            authority: `${AuthService.getConstantValue('login')}organizations`})));
    }

    public getGraphToken = (graph) => {
        return from(new Promise(this.getGenericTokenPromiseCallback({scopes: [graph + '/.default']})));
    }

    public requiresInteraction(errorMessage) {
        if (!errorMessage || !errorMessage.length) {
            return false;
        }
        return errorMessage.indexOf("consent_required") !== -1 ||
            errorMessage.indexOf("interaction_required") !== -1 ||
            errorMessage.indexOf("login_required") !== -1 ;
    }

}
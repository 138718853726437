import update from 'immutability-helper';
import { Action } from "../../constants/Interfaces";
import { PUT_REFERENCE_DATA_SET, PUT_REFERENCE_DATA_SET_ITEMS, PUT_REFERENCE_DATA_SET_ITEMS_FULFILLED, 
    PUT_REFERENCE_DATA_SET_FULFILLED, 
    SET_BULK_UPLOAD_REFERENCE_DATA_ITEMS_PROGRESS} from "../../constants/ActionTypes";
import { ErrorCodes, RequestStatus } from '../../constants/Enums';
import Utils from '../services/Utils';

export const defaultReferenceDataModalState = {
    fileUploadStatus: null, 
    fileUploadMessage: null, 
    itemsJSON: null,
    isStep1Finished: false, // creation of dataset
    isStep2Finished: false, // uploading items to the dataset
    progress: RequestStatus.Idle,
    errorCode: null,
    errorMessage: null,
    bulkUploadProgress: 0
}

export const referenceDataModalReducer = (state, action: Action) => {
    const payload = action.payload;
    if (typeof state === 'undefined') {
        return defaultReferenceDataModalState;
    }

    switch (action.type) {
        case PUT_REFERENCE_DATA_SET:
        case PUT_REFERENCE_DATA_SET_ITEMS:
            return update(state, {progress: {$set: RequestStatus.Publishing}, errorCode: {$set: null}, errorMessage: {$set: null}, bulkUploadProgress: {$set: 0}});
        case PUT_REFERENCE_DATA_SET_FULFILLED:
            if (payload.error) { // error from catch block
                return update(state, {progress: {$set: RequestStatus.Error}, errorCode: {$set: Utils.getErrorCode(payload.error)}, errorMessage: {$set: Utils.getErrorMessage(payload.error)}});
            } else {
                return update(state, {isStep1Finished: {$set: true}, progress: {$set: RequestStatus.Successful}, errorCode: {$set: null}, errorMessage: {$set: null}});
            }
        case PUT_REFERENCE_DATA_SET_ITEMS_FULFILLED:
            if (payload.error) { // error from catch block
                return update(state, {progress: {$set: RequestStatus.Error}, errorCode: {$set: Utils.getErrorCode(payload.error)}, errorMessage: {$set: Utils.getErrorMessage(payload.error)}});
            } else {
                const result = payload.put[0]; // check the manually created response from batch call in sdk
                if (result?.error) {
                    if (result.error.code === ErrorCodes.PartialSuccess) { /** Need to check for a specific code here as it is manually created for bulk upload*/
                        return update(state, {isStep2Finished: {$set: true}, progress: {$set: RequestStatus.Partial_Success}, errorCode: {$set: Utils.getErrorCode(result.error)}, errorMessage: {$set: Utils.getErrorMessage(result.error)}});
                    } else {
                        return update(state, {isStep2Finished: {$set: true}, progress: {$set: RequestStatus.Unsuccessful}, errorCode: {$set: Utils.getErrorCode(result.error)}, errorMessage: {$set: Utils.getErrorMessage(result.error)}});
                    }
                } else {
                    return update(state, {isStep2Finished: {$set: true}, progress: {$set: RequestStatus.Successful}, errorCode: {$set: null}, errorMessage: {$set: null}});
                }
            }
        case SET_BULK_UPLOAD_REFERENCE_DATA_ITEMS_PROGRESS:
            return update(state, {bulkUploadProgress: {$set: action.payload}});
        default:
            return state;
    }
};
import { Observable } from 'rxjs';
import AdalAuthService from '../models/AdalAuthService';
import MsalAuthService from '../models/MsalAuthService';
import TelemetryService from './TelemetryService';
import Utils from './Utils';

export default class AuthService {

    static isMooncake = window.location.hostname.indexOf('.cn') !== -1;
    static isDogfood = Utils.getUrlParam('repo') === 'dogfood';
    static isDev1 = Utils.getUrlParam('repo') === 'dev1';
    static onAtpCall = () => {TelemetryService.logUserAction('atpCall')};

    static environmentToConstantMapping = {
        prod: {
            login: 'https://login.microsoftonline.com/',
            clientId: '120d688d-1518-4cf7-bd38-182f158850b6',
            tsiApi: 'https://api.timeseries.azure.com/',
            management: 'https://management.core.windows.net/',
            graph: 'https://graph.microsoft.com/',
            azureManagement: 'https://management.azure.com/'
        },
        mooncake: {
            login: 'https://login.chinacloudapi.cn/',
            clientId: '120d688d-1518-4cf7-bd38-182f158850b6',
            tsiApi: 'https://api.timeseries.azure.cn/',
            management: 'https://management.core.chinacloudapi.cn/',
            graph: 'https://microsoftgraph.chinacloudapi.cn',
            azureManagement: 'https://manage.windowsazure.cn/'
        },
        dogfood: {
            login: 'https://login.windows-ppe.net/',
            clientId: '120d688d-1518-4cf7-bd38-182f158850b6',
            tsiApi: 'https://api.timeseries.azure.com/',
            management: 'https://api-dogfood.resources.windows-int.net/',
            graph: 'https://graph.microsoft-ppe.com/',
            azureManagement: 'https://management.azure.com/'
        },
        dev1: {
            login: 'https://login.microsoftonline.com/',
            clientId: 'b9c64278-dd8f-41c6-a5af-d0b83837e5fb',
            tsiApi: 'https://api.timeseries.azure.com/',
            management: 'https://management.core.windows.net/',
            graph: 'https://graph.microsoft.com/',
            azureManagement: 'https://management.azure.com/'
        }
    }

    static getConstantValue = key => {
        if(AuthService.isDogfood){
            return AuthService.environmentToConstantMapping.dogfood[key];
        }
        else if(AuthService.isDev1){
            return AuthService.environmentToConstantMapping.dev1[key];
        }
        else if(AuthService.isMooncake){
            return AuthService.environmentToConstantMapping.mooncake[key];
        }
        else{
            return AuthService.environmentToConstantMapping.prod[key];
        }
    }

    static onError: any;
    static getTokenCalls: any = [];
    static gettingToken: boolean = false;
    static tenantId: string = AuthService.isMooncake ? 'common' : `${AuthService.getConstantValue('login')}organizations`;

    static authServiceImplementation = AuthService.isMooncake ? 
                                            new AdalAuthService({
                                                clientId: '120d688d-1518-4cf7-bd38-182f158850b6',
                                                tenant: 'common',
                                                postLogoutRedirectUri: `https://${window.location.hostname}`,
                                                cacheLocation: 'localStorage',
                                                loadFrameTimeout: 30000,
                                                instance: AuthService.getConstantValue('login')
                                            }) : 
                                            new MsalAuthService({
                                                auth: {
                                                    clientId: AuthService.getConstantValue('clientId'),
                                                    authority: `${AuthService.getConstantValue('login')}organizations`,
                                                    redirectUri: window.location.protocol + '//' + window.location.hostname,
                                                    navigateToLoginRequestUrl: true
                                                },
                                                cache: {
                                                    cacheLocation: 'localStorage',
                                                    storeAuthStateInCookie: true
                                                }
                                            })
    ;

    static login = (continuation, onAtpCall = () => {}) => {
        AuthService.authServiceImplementation.login(continuation);
        AuthService.onAtpCall = onAtpCall;
    }

    static logout = () => {
        AuthService.authServiceImplementation.logout();
    }

    static getTsiTokenPromise = () => AuthService.authServiceImplementation.getTsiTokenPromise(AuthService.getConstantValue('clientId'));

    static getManagementTokenPromise = () => AuthService.authServiceImplementation.getManagementTokenPromise(AuthService.getConstantValue('management'));

    static getTsiToken: () => Observable<string> = () => AuthService.authServiceImplementation.getTsiToken(AuthService.getConstantValue('clientId')) as Observable<string>;

    static getManagementToken = () => AuthService.authServiceImplementation.getManagementToken(AuthService.getConstantValue('management'));

    static getGraphToken = () => AuthService.authServiceImplementation.getGraphToken(AuthService.getConstantValue('graph'));
}
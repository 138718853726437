import * as React from 'react';
import cx from './Tooltipable.module.scss';

interface Props { theme: string; tooltip: string; className?: string; tooltipClassName?: string; position?: string;} 
interface State { isTooltipShown: boolean; }

export default class Tooltipable extends React.Component<Props, State> {

    static defaultProps = {className: '', tooltipClassName: '', position: 'bottom'};
    private nodeRef;

    constructor(props: Props) {
        super(props);
        this.nodeRef = React.createRef();
        this.state = {isTooltipShown: false};
    }

    showTooltip = () => {
        this.setState({isTooltipShown: true});
    }

    hideTooltip = () => {
        this.setState({isTooltipShown: false});
    }

    componentDidMount() {
        this.nodeRef.current.addEventListener('mouseenter', this.showTooltip);
        this.nodeRef.current.addEventListener('mouseleave', this.hideTooltip);
    }

    componentWillUnmount() {
        this.nodeRef.current.removeEventListener('mouseenter', this.showTooltip);
        this.nodeRef.current.removeEventListener('mouseleave', this.hideTooltip);
    }

    render() {
        return <div className={`${cx['wrapper']} ${this.props.className}`}>
                    <div ref={this.nodeRef} aria-label={this.props.tooltip} tabIndex={0} className={cx['hoverTarget']}>
                        {this.props.children}
                        <div className={`${cx['tooltipWrapper']} ${this.props.tooltipClassName} ${cx[this.props.position]} ${this.state.isTooltipShown ? cx['shown'] : ''}`}>
                            <div className={cx['tooltipContent']}>{this.props.tooltip}</div>
                            <div className={cx['tooltipCaret']}></div>
                        </div>  
                    </div>
                </div>
    }
}
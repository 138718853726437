import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { getTheme, getSelectedEnvironmentResourceId, getSelectedEnvironmentFqdn } from '../../state/reducers/AppSettingsReducer';
import { ReferenceData } from './ReferenceData';
import { getIsUserContributor } from '../../state/reducers/AppSettingsReducer';

const mapStateToProps = state => {
    return { 
        theme: getTheme(state),
        selectedEnvironmentResourceId: getSelectedEnvironmentResourceId(state),
        environmentFqdn: getSelectedEnvironmentFqdn(state),
        isUserContributor: getIsUserContributor(state)
    };
};

const ReferenceDataContainer = translate()(connect(mapStateToProps)(ReferenceData));

export default ReferenceDataContainer;
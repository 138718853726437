import * as React from 'react';
import classNames from 'classnames/bind';
import ModalContainer from '../Modal/Modal.container';
import { ChartTypes, ChartDataTypes } from '../../../constants/Enums';
import Utils from '../../services/Utils';


const cx = classNames.bind(require('./AdvancedExploration.module.scss'));

interface Props { t: any; timeSeriesQueries: Array<any>; theme: string; 
    setScatterMeasures: any, setChartType: any, closeModal: any; focusModalX: number; timezone: string;
    focusModalY: number; suggestedScatterMeasures: any; modalOptions: any; modalTimeSeriesQuery: any; 
    getQueryResults: any;}

interface State { scatterMeasures: any;}

class AdvancedExploration extends React.Component<Props, State> {

    public static advancedExploreDimensions = {width: 320, height: 300};

    constructor(props: Props) {
        super(props);

        let scatterMeasures = this.props.suggestedScatterMeasures;

        this.state = { 
            scatterMeasures
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.suggestedScatterMeasures !== this.state.scatterMeasures) {
          this.setState({ scatterMeasures: nextProps.suggestedScatterMeasures });
        }
    }

    private closeModal = () =>{
        this.props.closeModal();
    }

    private getSourceElement = () => {
        return (this.props.modalOptions && this.props.modalOptions.sourceElement) ? this.props.modalOptions.sourceElement : null; 
    }

    render() {
        let tsq = this.props.modalTimeSeriesQuery;
        let modalTitle = this.props.t('commanding.scatterplot');
        if (!tsq) {
            return '';
        }
        return <ModalContainer onClose={() => { this.closeModal() } } 
                sourceElement={this.getSourceElement()}
                title={modalTitle} className={cx('focusModal')} titleClassName={cx('focusModalTitle')} wrapperClassName={cx('focusModalWrapper', 'scatterPlotOnly')} contentClassName={cx('focusModalContent')} 
                pageX={this.props.focusModalX} pageY={this.props.focusModalY} titleIconId={'scatterplot-' + this.props.theme}
                caret={this.props.modalOptions.caretDirection} modalDimensions={AdvancedExploration.advancedExploreDimensions}>

                <ScatterPlotModal 
                    {...this.props} 
                    {...this.state} 
                    updateScatterMeasures={this.updateScatterMeasures.bind(this)}
                    checkDuplicateMeasure={this.checkDuplicateMeasure.bind(this)}
                    createScatterPlot={this.createScatterPlot.bind(this)}
                />
                
        </ModalContainer>;
    }

    checkDuplicateMeasure = () => {
        return this.state.scatterMeasures.X_MEASURE === this.state.scatterMeasures.Y_MEASURE ||
            this.state.scatterMeasures.X_MEASURE === this.state.scatterMeasures.R_MEASURE ||
            this.state.scatterMeasures.R_MEASURE === this.state.scatterMeasures.Y_MEASURE;
    }

    createScatterPlot = () => {
        let measures = this.state.scatterMeasures;
        
        if(measures.R_MEASURE === -1)
            measures.R_MEASURE = null;

        // Update measure to store query ID in place of index
        Object.keys(measures).filter((key) => measures[key] != null).forEach((key) => {
            measures[key] = this.props.timeSeriesQueries[measures[key]].queryID;
        });

        // Update store measures for scatter
        this.props.setScatterMeasures(measures);

        // Render scatter plot, use set timeout to ensure hidden queries are appropriately shown
        setTimeout(() => {
            this.props.setChartType(ChartTypes.Scatterplot);
            this.props.getQueryResults();
        });

        // Close scatter modal and reset measures
        this.props.closeModal();
        this.setState({
            scatterMeasures: {
                X_MEASURE: 0,
                Y_MEASURE: 1,
                R_MEASURE: -1
            }
        });
   }

    updateScatterMeasures = (queryIdx: number, measure: string) => {
        let newScatterMeasures = this.state.scatterMeasures;
        newScatterMeasures[measure] = queryIdx;
        this.setState({scatterMeasures: newScatterMeasures});
    }
}

export default AdvancedExploration;

const ScatterPlotModal = (props) => {
    let measureOptions = () => {
        return props.timeSeriesQueries.map((query, idx) => {
            let fullValue = getTitleFromIdx(idx);
            let label = fullValue.length > 42 ? fullValue.substring(0, 40) + "..." : fullValue;
            return (
                <option disabled={query.dataType !== ChartDataTypes.Numeric} key={idx} value={idx} label={label} title={fullValue}>
                </option>
            );
        });
    }

    let getTitleFromIdx = (idx) => {
        if(idx === -1) return "None";
        let query = props.timeSeriesQueries[idx];
        return Utils.getTitleFromQuery(query, props.timezone, props.t('settingsModal.startAt'));
    }

    let xMeasureLabelId = Utils.customID('xMeasureLabel');
    let yMeasureLabelId = Utils.customID('yMeasureLabel');
    let rMeasureLabelId = Utils.customID('rMeasureLabel');
    
    return (
        <div className={cx('compareModalWrapper')}>
            <div className={cx('compareModalContent')}>
                <div className={cx('selectLabelWrapper')}>
                    <div className={cx('colorCircle')} style={{background: props.timeSeriesQueries[props.scatterMeasures.X_MEASURE].color}}></div>
                    <div className={cx('selectLabel')} id={xMeasureLabelId}>{props.t('well.xMeasure')}</div>
                </div>
                    <select
                        aria-labelledby={xMeasureLabelId}
                        title={getTitleFromIdx(props.scatterMeasures.X_MEASURE)} 
                        onChange={(e) => props.updateScatterMeasures(parseInt(e.target.value), "X_MEASURE")}
                        value={(props.scatterMeasures.X_MEASURE)}
                    >
                        {measureOptions()}
                    </select>
                <div className={cx('selectLabelWrapper')}>
                    <div className={cx('colorCircle')} style={{background: props.timeSeriesQueries[props.scatterMeasures.Y_MEASURE].color}}></div>
                    <div className={cx('selectLabel')} id={yMeasureLabelId}>{props.t('well.yMeasure')}</div>
                </div>
                    <select 
                        aria-labelledby={yMeasureLabelId}
                        title={getTitleFromIdx(props.scatterMeasures.Y_MEASURE)}
                        onChange={(e) => props.updateScatterMeasures(parseInt(e.target.value), "Y_MEASURE")}
                        value={(props.scatterMeasures.Y_MEASURE)}
                    >
                        {measureOptions()}
                    </select>
                <div className={cx('selectLabelWrapper')}>
                    {props.scatterMeasures.R_MEASURE !== -1 && <div className={cx('colorCircle')} style={{background: props.timeSeriesQueries[props.scatterMeasures.R_MEASURE].color}}></div>}
                    <div className={cx('selectLabel')} id={rMeasureLabelId}>{props.t('well.rMeasure')}</div>
                </div>
                    <select 
                        aria-labelledby={rMeasureLabelId}
                        title={getTitleFromIdx(props.scatterMeasures.R_MEASURE)}
                        onChange={(e) => props.updateScatterMeasures(parseInt(e.target.value), "R_MEASURE")}
                        value={(props.scatterMeasures.R_MEASURE)}
                    >
                        <option value={-1}>None</option>
                        {measureOptions()}
                    </select>
            </div>
            <div className={cx('applyContainer')}>
                {props.checkDuplicateMeasure() && 
                <div className={cx('overwriteWarningSP')}>
                    <div className={cx('overwriteWarningText')}>
                        {props.t('well.invalidScatterMeasures')}
                    </div>
                </div>}
                <button className={cx('saveButton')} onClick={() => {
                    props.createScatterPlot();
                    if (props.modalOptions && props.modalOptions.sourceElement) {
                        props.modalOptions.sourceElement.focus();
                    }
                }}
                >{props.t('well.apply')}</button>
            </div>
        </div>
    );
};
import * as React from 'react';
import { GetAvailabilityReason } from '../../../../constants/Enums';

interface Props { theme: string; t: any; autoRefreshCycleMillis: number; isAutoRefreshEnabled: boolean; 
                setAutoRefresh: any; getAvailability: any; isAvailabilityDistributionIsLoading: boolean; 
                isLongPollingAvailability: boolean; isLoadingAggregates: boolean; getModel: any; } 
                
interface State {isLongPollingForAutoRefresh: boolean;}

export default class RefreshCharts extends React.Component<Props, State> {
    private autoRefresherFunc = null;
    private availabilityLongPollInterval = 30000;
    private firstFireAutoRefreshTimeout;

    constructor(props: Props) {
        super(props);

        this.state = {isLongPollingForAutoRefresh: false};
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if ((this.props.isLongPollingAvailability !== nextProps.isLongPollingAvailability)
            || (this.props.isAutoRefreshEnabled !== nextProps.isAutoRefreshEnabled)) {
            if(nextProps.isLongPollingAvailability)
                this.startAvailabilityLongPoll(nextProps.isAutoRefreshEnabled);
            else
                this.stopAvailabilityLongPoll();
        }
    }

    componentDidMount() {
        if (this.props.isAutoRefreshEnabled || this.props.isLongPollingAvailability) {
            this.startAvailabilityLongPoll(this.props.isAutoRefreshEnabled);
        }
        document.addEventListener("visibilitychange", this.onVisibilityChange.bind(this));
    }

    componentWillUnmount() {
        document.removeEventListener("visibilitychange", this.onVisibilityChange);
        this.stopAvailabilityLongPoll(true);
    }

    onVisibilityChange(){
        if(document.hidden){
            this.stopAvailabilityLongPoll();
        }
        else{
            this.refreshAvailabilityAndLineCharts(false, false);
            this.startAvailabilityLongPoll(this.props.isAutoRefreshEnabled);
        }
    }

    refreshAvailabilityAndLineCharts = (isManual, isFirstFireForAutoRefresh = false) => {
        if (this.props.isAvailabilityDistributionIsLoading || this.props.isLoadingAggregates) {return;}
        if (isManual) {
            if(this.props.isAutoRefreshEnabled){
                this.stopAvailabilityLongPoll();
                this.startAvailabilityLongPoll(true);
            }
            else{
                this.props.getAvailability({getAvailabilityReason: GetAvailabilityReason.ManualRefresh});
            }
            this.props.getModel();
        } else if(this.props.isAutoRefreshEnabled || isFirstFireForAutoRefresh) {
            if(isFirstFireForAutoRefresh){
                // using ManualRefresh ensures this will fire even if it is against cold
                this.props.getAvailability({getAvailabilityReason: GetAvailabilityReason.ManualRefresh});
            }
            else{
                this.props.getAvailability({getAvailabilityReason: GetAvailabilityReason.AutoRefresh});
            }
        }
        else {
            this.props.getAvailability({getAvailabilityReason: GetAvailabilityReason.LongPoll});
        }
    }

    startAvailabilityLongPoll = (isForAutoRefresh) => {
        this.stopAvailabilityLongPoll();
        if(isForAutoRefresh){
            // allow state to be fully set from shortlink or saved query
            // so that isLoadingAggregates is true and we don't double fire
            this.firstFireAutoRefreshTimeout = setTimeout(() => {
                this.refreshAvailabilityAndLineCharts(false, true);
                this.setState({isLongPollingForAutoRefresh: true})
            });
        }
        this.autoRefresherFunc = setInterval(() => this.refreshAvailabilityAndLineCharts(false), isForAutoRefresh ? this.props.autoRefreshCycleMillis : this.availabilityLongPollInterval);
    }

    stopAvailabilityLongPoll = (fromComponentWillUnmount = false) => {
        if (this.autoRefresherFunc !== null) {
            clearInterval(this.autoRefresherFunc);
        }
        if(this.firstFireAutoRefreshTimeout !== null){
            clearTimeout(this.firstFireAutoRefreshTimeout);
        }
        this.autoRefresherFunc = null;
        if(!fromComponentWillUnmount){
            this.setState({isLongPollingForAutoRefresh: false}); // Don't set state in componentWillUnmount
        }
    }

    handleAutoRefreshChange = () => {
        this.stopAvailabilityLongPoll();
        this.props.setAutoRefresh(!this.props.isAutoRefreshEnabled);
    }

    render() {
        return <div></div>;
    }
}
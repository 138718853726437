import * as React from 'react';
import { TranslationFunction } from 'react-i18next';
import { Route, Switch, NavLink } from 'react-router-dom';
import classNames from 'classnames/bind';
import { Navigation } from '@microsoft/azure-iot-ux-fluent-controls/lib/components/Navigation';
import { Masthead } from '@microsoft/azure-iot-ux-fluent-controls/lib/components/Masthead';
import { Shell } from '@microsoft/azure-iot-ux-fluent-controls/lib/components/Shell';
import { I18n, i18nInstance } from '../../../i18n';
import Icon from '../../components/Icon/Icon';
import AuthService from '../../services/AuthService';
import AnalyticsContainer from '../Analytics/Analytics.container';
import ModelContainer from '../Model/Model.container';
import JobsContainer from '../Jobs/Jobs.container';
import Placeholder from '../../components/Placeholder/Placeholder';
import Utils from '../../services/Utils';
import FeedbackContainer from '../../components/Feedback/Feedback.container';
import TelemetryService from '../../services/TelemetryService';
import DemoContainer from '../../components/Demo/Demo.container';
import 'tsiclient/tsiclient.css';
import { AppPages } from '../../../constants/Enums';
import AppNotificationAreaContainer from '../../components/AppNotificationArea/AppNotificationArea.container';
import AppNotificationHistoryContainer from '../../components/AppNotificationHistory/AppNotificationHistory.container';
import AppSettingsContainer from '../../components/AppSettings/AppSettingsContainer';
import TsxMigrationContainer from '../../components/TsxMigration/TsxMigration.container';
import ModalContainer from '../../components/Modal/Modal.container';
import WarningBannerContainer from '../../components/WarningBanner/WarningBanner.container';
import { Trans } from 'react-i18next';
const cx = classNames.bind(require('./App.module.scss'));

interface Properties {
    theme: string;
    t: any;
    tenants: Array<any>;
    setTenantId: any;
    tenantId: string;
    isDoneGettingTenants: boolean;
    setActivePage: any;
    getTenants: any;
    isMigrationModalVisible: boolean;
    openFeedbackForm: any;
}

interface State {
  isNavExpanded: boolean;
  user: any;
  isUserMenuExpanded: boolean;
  isLoggedIn: boolean;
  authErrorTitle: string;
  authErrorContent: string;
  isNotSupported: boolean;
  isPopupHelperVisible: boolean;
  hasError: boolean;
  error: any;
}

export class App extends React.Component<Properties, State>  {

  private pageLoaded = false;

  constructor(props: Properties) {
      super(props);
      this.state = {
        isNavExpanded: false,
        isUserMenuExpanded: false,
        user: {
            displayName: 'John Smith',
            email: 'jsmith@example.com'
        },
        isLoggedIn: false,
        authErrorTitle: null,
        authErrorContent: null,
        isNotSupported: Utils.isIeOrMobile(),
        isPopupHelperVisible: false,
        hasError: false,
        error: null
      };
  }

  

  static getDerivedStateFromError(error){
    let errorString = Utils.truncateWithEllipsis(error?.message + '\n' + error?.stack, 1000);
    TelemetryService.logUserAction('somethingWentWrong', {error: errorString});
    return { hasError: true, error: errorString};
  }

  componentDidMount() { 
    if(!this.state.isNotSupported){
      AuthService.login((displayName, email) => {
          this.setState({
            isNavExpanded: this.state.isNavExpanded,
            user: {displayName: displayName, email: email},
            isLoggedIn: true,
          });
          this.props.getTenants();
          this.pageLoaded = true;
        },
        () => {
          this.handleShowHidePopupHelper(true);
          TelemetryService.logUserAction('atpFlow');
        }
      );
    }
  }

  setPage = (page) => {
    this.props.setActivePage(page);
  }
  
  handleSelectPage(navItem) {
    const { key, to } = navItem;

    switch(key) {
      case 'model': this.setPage(AppPages.Model); break;
      case 'jobs': this.setPage(AppPages.Jobs); break;
      default: this.setPage(AppPages.Analytics); break;
    }

    TelemetryService.logUserAction('changeRoute', {route: to});
  }

  render() {
    if(this.state.hasError){
      return (
        <I18n>{loc =>
          <Shell theme={this.props.theme} isRtl={isRtl()}>
            <SomethingWentWrongModal t={this.props.t} error={this.state.error} openFeedbackForm={this.props.openFeedbackForm}/>
          </Shell>
        }</I18n>
      )
    }
    return (
      <I18n>{loc =>
        <Shell theme={this.props.theme} isRtl={isRtl()}>
            <WarningBannerContainer hideBannerSettingKey='deprecationBanner'>
              {this.props.t('deprecation.mainBanner')}{" "}
              <Trans i18nKey='deprecation.moreInfo'>
                For more information visit <a tabIndex={1} target="_blank" rel="noopener noreferrer" href="https://aka.ms/tsi2adx">our documentation</a>.
              </Trans>
            </WarningBannerContainer>
            {this.state.isNotSupported && <BrowserNotSupportedModal t={this.props.t} />}
            {this.state.isPopupHelperVisible && <PopupHelperModal props={this.props} parent={this} />}
            <div className={cx('app', {navNotExpanded: !this.state.isNavExpanded})} onClick={this.handleViewCollapse}>
                <AppNotificationHistoryContainer />
                <AppSettingsContainer />
                <header>
                  {this.renderMasthead(loc)}
                </header>
                {this.renderNav(loc)}
                {Utils.isDemo ? <DemoContainer /> : ''}
                <MastheadNavMenu props={{isUserMenuExpanded: this.state.isUserMenuExpanded, ...this.props}}/>
                <main>
                  <AppNotificationAreaContainer />
                  {this.state.isLoggedIn && !this.state.isNotSupported ? 
                      (<div className={cx('page')}>
                          {this.props.isMigrationModalVisible && <TsxMigrationContainer/>}
                          <div className={cx('rhs')}>
                              {this.props.isDoneGettingTenants && 
                                <Switch>
                                    <Route exact path={`${Utils.isSampleEnvironments() ? Utils.sampleRoute : ''}/`} render={() => <AnalyticsContainer isInitialPageLoad={!this.pageLoaded} openUserSettings={this.handleUserMenuToggle} />}/>
                                    <Route exact path={`${Utils.isSampleEnvironments() ? Utils.sampleRoute : ''}/model`} render={() => <ModelContainer openUserSettings={this.handleUserMenuToggle} />}/> 
                                    <Route exact path={`${Utils.isSampleEnvironments() ? Utils.sampleRoute : ''}/2da92df7c05c`} render={() => <JobsContainer openUserSettings={this.handleUserMenuToggle} />}/> 
                                </Switch>
                              }
                          </div>
                      </div>)
                      : <Placeholder visible={true} className={cx('page')}><div>{this.props.t('loggingIn')}</div></Placeholder>
                  }
                </main>
            </div>
        </Shell>
      }</I18n>
    );
  }

  renderNav(loc: TranslationFunction) {
    const items = [
      {
        key: 'home',
        to: `${Utils.isSampleEnvironments() ? Utils.sampleRoute : ''}`,
        icon: 'analytics',
        label: loc('navigation.home'),
        title: loc('navigation.home')
      },
      {
        key: 'model',
        to: `${Utils.isSampleEnvironments() ? Utils.sampleRoute : ''}/model`,
        icon: 'model',
        label: loc('navigation.model'),
        title: loc('navigation.model')
      },
      // Navigation to jobs via the UI is disabled until the feature comes out of private preview.
      // {
      //   key: 'jobs',
      //   to: `${Utils.isSampleEnvironments() ? Utils.sampleRoute : ''}/jobs`,
      //   icon: 'jobs',
      //   label: loc('navigation.jobs'),
      //   title: loc('navigation.jobs')
      // }
    ];

    return (
        <Navigation 
          isExpanded={this.state.isNavExpanded} 
          onClick={this.handleGlobalNavToggle} 
          attr={{
            navButton: {
              title: this.state.isNavExpanded ? 'Collapse side navigation' : 'Expand side navigation'
            }
          }}
        >
          {items.map(navItem => (
              <NavLink 
                exact={true} 
                onClick={() => this.handleSelectPage(navItem)} 
                activeClassName={cx('activeLink')} 
                to={navItem.to} 
                className={cx('link-container')} 
                key={navItem.key} 
                title={navItem.title} 
                aria-label={navItem.title}>
                  <div className={cx('link-thumbnail')}>
                    <Icon id={navItem.icon} className={cx(navItem.key === 'model' ? 'icon16' : 'icon14', this.props.theme)} />
                  </div>
                  <div className={cx('link-label', 'inline-text-overflow')}>{navItem.label}</div>
              </NavLink>
          ))}
        </Navigation>
      );
  }

  renderMasthead(loc: TranslationFunction) {
    return <Masthead
      branding={<div><Icon id='logo' className={cx('tsiLogo')} /><span style={{position: 'absolute', left: '56px', fontSize: '16px'}}>{loc('masthead')}</span></div>}
      user={{
        displayName: this.state.user.displayName,
        email: this.state.user.email,
        menuExpanded: this.state.isUserMenuExpanded,
        onMenuClick: this.handleUserMenuToggle,
        menuItems: []
      }}
      attr={{
        userMenuAriaLabel: 'User Menu',
        mobileMenuAriaLabel: 'Application Menu'
      }}
    >
    </Masthead>
    ;
  }

  handleGlobalNavToggle = (e: React.MouseEvent<any>) => {
      e.stopPropagation();
      this.setState({
          isNavExpanded: !this.state.isNavExpanded
      });
  }

  handleViewCollapse = (e: React.MouseEvent<any>) => {
      e.stopPropagation();
      if (this.state.isNavExpanded) {
        this.setState({
          isNavExpanded: false
        }); 
      }
  }
  
  handleUserMenuToggle = () => {
    this.setState({
      isUserMenuExpanded: !this.state.isUserMenuExpanded
    });
  }

  handleShowHidePopupHelper = (showPopupHelper: boolean) => {
    this.setState({isPopupHelperVisible: showPopupHelper});
  }
}

export default App;

const MastheadNavMenu = ({props}) => {
    return (<div className={cx('customUserMenu', {visible: props.isUserMenuExpanded}, {'slideIn': props.isUserMenuExpanded}, {'slideOut': !props.isUserMenuExpanded})}>
          <div className={cx('menuElement')}>
            <div className={cx('menuElementSelectContainer')}>
              <div>Directories</div>
            <select value={props.tenantId} onChange={(event) => props.setTenantId(event.target.value)}>
                {props.tenants.map((t, i) => <option key={i} value={t.tenantId}>{t.displayName ? t.displayName : t.tenantId}</option>)}
            </select>
            </div>
          </div>
          <div className={cx('menuElement')}>
            <button className={cx('menuElementButton')} onClick={() => window.open(`https://${window.location.hostname}/demo`)}>View Demo Environment</button>
          </div>
          <div className={cx('menuElement')}>
            <button className={cx('menuElementButton')} onClick={() => AuthService.logout()}>Logout</button>
          </div>
          <div className={cx('menuElement', 'tou')}>
                <a target="_blank" rel="noopener noreferrer" href={`https://${window.location.hostname}/TermsOfUse.PDF`}>Terms of Use</a>
                <a target="_blank" rel="noopener noreferrer" href="http://www.microsoft.com/privacystatement/OnlineServices/Default.aspx">Privacy and Cookies</a>
          </div>
  </div>);
};

const BrowserNotSupportedModal = ({t}) => {
    Utils.fadeOutLoadingSplash();
    return <div className={cx('fiveheadSplashWrapper', 'notSupported')}>
        <div className={cx('content')}>
            <div className={cx('fivehead')}>
                <Icon id='logo' className={cx('logo')}></Icon>
            </div>
            <div className={cx('text')}>
                <div className={cx('title')}>
                    {t('browserNotSupportedText')}
                </div>
                {t('supportedBrowsers')}
            </div>
        </div>
    </div>;
};

const SomethingWentWrongModal = ({t, error, openFeedbackForm}) => {
    Utils.fadeOutLoadingSplash();

    return <div className={cx('fiveheadSplashWrapper', 'notSupported')}>
        <div className={cx('content')}>
            <div className={cx('fivehead')}>
                <Icon id='logo' className={cx('logo')}></Icon>
            </div>
            <div className={cx('text')}>
                <div className={cx('title')}>
                  {t('somethingWentWrong')}
                </div>
                <div className={cx('description')}>
                  {t('somethingWentWrongDescription')}
                </div>
                <div className={cx('letUsKnowWrapper')}>
                    <button className={cx('_base-primary-button')} onClick={() => openFeedbackForm(t('somethingWentWrong'), null, error)}>{t('well.letUsKnowUpper')}</button>
                </div>
            </div>
        </div>
        <FeedbackContainer fullHeightNoToggle={true}/>
    </div>;
}

const PopupHelperModal = ({props, parent}) => {
  return <ModalContainer hasCloseButton={false} isOffModalAutoClosingEnabled={true} title={props.t('enablePopups')} pageX={0} pageY={0} modalDimensions={{width: 320, height: 0}} caret={'top'} contentPositionContainerClassName={cx('popupHelperContent')} onClose={() => parent.handleShowHidePopupHelper(false)} className={cx('popupHelperWrapper')}>
          <div className={cx('popupHelperContentText')}>
            {props.t('enablePopupsContent')}
            <button className={cx('_base-primary-button')} onClick={() => parent.handleShowHidePopupHelper(false)}>
              {props.t('gotIt')}
            </button>
          </div>
        </ModalContainer>
}

function isRtl() {
  return i18nInstance.dir() as any === 'rtl';
}
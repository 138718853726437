import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import TsmSplash from './TsmSplash';
import { getTheme } from '../../../../state/reducers/AppSettingsReducer';

const mapStateToProps = state => {
    return { 
        theme: getTheme(state)
    };
};

const TsmSplashContainer = translate()(connect(mapStateToProps)(TsmSplash));

export default TsmSplashContainer;

import { DirectionalHint, IButtonProps, ICalloutContentStyles, IContextualMenuStyles,
    CommandBar, registerIcons, unregisterIcons, setIconOptions } from '@fluentui/react';
import React, { useEffect } from 'react';
import classNames from 'classnames/bind';

const cx = classNames.bind(require('./FluentCommandBar.module.scss'));

// Suppress icon warnings on re-register.
setIconOptions({
    disableWarnings: true
});

interface FluentCommandBarProps {
    leftItems: any;
    rightItems?: any;
    theme: any;
    t: any;
    icons: any;
    height?: string;
    shiftOnReduce?: boolean;
    onDataGrown?: (item: any) => any;
}

/*  
    CommandBarUtilities wraps static utility methods for command bar styling.
    These methods are used to style properties of the command bar
    overflow, leftItems, and rightItems which must be styled via 
    prop names. 
*/
export class CommandBarUtilities{
    static getThemeBackground = (theme) => theme === 'light' ? '#ffffff': '#0d0e0f';
    
    // Styles for callout menus
    static getCalloutStyles: (t: string, o?: boolean) => Partial<ICalloutContentStyles> = (theme, offset = true) => ({
        beak: { 
            background: CommandBarUtilities.getThemeBackground(theme),
            bottom: '-6px !important'
        },
        label: { fontSize: 12 },
        beakCurtain: { 
            background: CommandBarUtilities.getThemeBackground(theme) ,
            borderRadius: '2px'
        },
        calloutMain: { 
            backgroundColor: CommandBarUtilities.getThemeBackground(theme), 
            borderRadius: '2px',
            top: offset ? '1px' : '0px',
        },
        container: {
            borderRadius: '2px'
        },
        root: {
            backgroundColor: CommandBarUtilities.getThemeBackground(theme), 
            borderRadius: '2px',
        },
    })

    // For passing the styles through to the context menus
    static getMenuStyles: (theme, width?: string, offset?: boolean) => Partial<IContextualMenuStyles> = (theme, minWidth = '100px', offset = true) => ({
        subComponentStyles: { 
            menuItem: {},
            callout: CommandBarUtilities.getCalloutStyles(theme, offset),
        },
        root: {
            display: 'flex',
            minWidth,
            maxWidth: '300px',
        }
    });

    // Adds [theme-light | theme-dark] to ms-Layer--fixed floating layer
    static themifyFloatingLayer = (theme) => {
        let fixedLayers: any = document.getElementsByClassName('ms-Layer--fixed');
        fixedLayers = [].slice.call(fixedLayers);
        for(let div of fixedLayers){
            div.classList.remove('theme-light', 'theme-dark')
            div.classList.add(theme === 'light' ? 'theme-light' : 'theme-dark');
        }
    }

    // Exposes FluentCommandBar scss module to external classes
    static style(...classNames: string[]){
        return cx(...classNames)
    };
}

// Functional component which renders Fluent UI Command Bar
export const FluentCommandBar: React.FunctionComponent<FluentCommandBarProps> = ({height = '36px', shiftOnReduce = false, onDataGrown = null, ...props}) => {
    useEffect(() => {
        registerIcons(props.icons) // Custom icons must be passed in as props and registered here
        return () => unregisterIcons(props.icons); // unregister as cleanup
    }, [props.icons]);
    
    // Props of command bar overflow (ellipsis) menu
    const getOverflowProps: () => IButtonProps = () => ({
        ariaLabel: props.t('commanding.moreCommands'),
        iconProps: {iconName: props.theme === 'light' ? 'ellipsisLight' : 'ellipsisDark'},
        className: cx('commandItem', 'overflowButton'),
        menuProps: {
            className: cx('dropdownMenu','moreItemsCallout'),
            calloutProps: {
                styles: CommandBarUtilities.getCalloutStyles(props.theme, false),
            },
            onMenuOpened: () => CommandBarUtilities.themifyFloatingLayer(props.theme),
            items: [], // CommandBar will determine items rendered in overflow
            isBeakVisible: true,
            beakWidth: 20,
            gapSpace: 10,
            directionalHint: DirectionalHint.bottomCenter,
        },
    });

    return <CommandBar
            overflowButtonProps={getOverflowProps()}
            items={props.leftItems}
            farItems={props.rightItems}
            ariaLabel={props.t('commanding.arrowKeys')}
            className={cx('commandBar')}
            shiftOnReduce={shiftOnReduce} // If true, will shift items into overflow from start of list
            styles={{
                root: {
                    height: height,
                    padding: 'unset',
                    backgroundColor: 'unset',
                },
            }}
            onDataGrown={onDataGrown} // if truthy, will pass item grown to custom callback
        />
}
import Utils from "../services/Utils";
import BaseQuery from "./BaseQuery";
import { t } from "i18next";

export default class AggregatesQuery extends BaseQuery {
    
    private aggregateExpression;
    public color: string;

    constructor(predicateObject: any, measureObject: any, measureTypes: Array<string>, searchSpan: any, 
        splitByObject: any = null, color: string = null, alias: string = '') {
            super();
            this.aggregateExpression = new Utils.tsiClient.ux.AggregateExpression(predicateObject, measureObject, measureTypes, searchSpan, splitByObject, color, alias);
            for (let prop in this.aggregateExpression) {
                this[prop] = this.aggregateExpression[prop];
            }
    }

    static fromObject = (obj: any) => {
        let ts = new AggregatesQuery(obj.predicate, obj.measureObject.input, obj.measureTypes, obj.searchSpan, obj.splitByObject, obj.color, obj.alias);
        ts.error = obj.error;
        return ts;
    }

    static toObject(aq: any) {
        // Perform some clean up and return
        delete aq.data;
        delete aq.contextMenu;

        return aq
    }

    static constructPredicateFromInput = (where) => {
        return {predicateString: where};
    }

    static constructMeasureArrayFromInput = (measure) => {
        return measure === 'Event Count' ? ['count'] : ['avg', 'min', 'max'];
    }

    static constructMeasureObjectFromInput = (measure) => {
        return measure === 'Event Count' ? {count: {}} : {input: {property: measure, type: 'double'}};
    }

    static constructSplitByObjectFromInput = (splitBy) => {
        return splitBy && splitBy !== '(none)' ? {property: splitBy, type: 'string'} : null;
    }

    static defaultAggregatesQuery = (name = null) => {
        let predicate = AggregatesQuery.constructPredicateFromInput('');
        let measureArray = AggregatesQuery.constructMeasureArrayFromInput('Event Count');
        let measureObject = AggregatesQuery.constructMeasureObjectFromInput('Event Count');
        let queryName = name ? name : `${t('query')} 1`;
        let aQ = new AggregatesQuery(predicate, measureObject, measureArray, {}, null, Utils.getColor(''), queryName);
        return aQ;
    }
}
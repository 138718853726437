import * as React from 'react';
import classNames from 'classnames/bind';
import Icon from '../Icon/Icon';
const cx = classNames.bind(require('./SortableTable.module.scss'));

export interface SortableTableColumn {
  key: string, 
  i18nKey?: string,
  isSortable: boolean,
  isDateOrNumber?: boolean, 
  style?: any,
  iconList?: Array<string>
};

export enum SortOrder { 
  Unsorted, 
  Ascending, 
  Descending 
};

interface Props { 
  t: any, 
  theme: string, 
  rows: Array<any>, 
  columns: Array<any>, 
  renderRow: Function,
  handleOnClickRow?: Function,
  canExpandRows: boolean, 
  expandedRowIndices?: Array<number>,
  renderExpandedRowContents?: Function,
  handleOnSort?: Function,
  sortOrder?: SortOrder,
  sortColumnIndex?: number,
  ariaLabel?: string,
  selectedRowIndex?: number,
  isSortVisible?: boolean
}

class SortableTable extends React.Component<Props> {
  static defaultProps = {isSortVisible: true}

  render() {
    const { 
      t, 
      theme,
      rows, 
      columns, 
      renderRow, 
      handleOnClickRow,
      canExpandRows, 
      handleOnSort, 
      sortOrder,
      sortColumnIndex,
      ariaLabel,
      selectedRowIndex,
      isSortVisible
    } = this.props;

    const expandedRowIndices = this.props.expandedRowIndices || [];
    const renderExpandedRowContents = this.props.renderExpandedRowContents || function() { };

    let header = columns.map((column: SortableTableColumn, index: number) => {
      let clickHandler = column.isSortable ? () => handleOnSort(column.key) : null;
      let direction = sortOrder === SortOrder.Ascending ? 'Down' : 'Up';
      let headerName = column.i18nKey ? t(column.i18nKey) : column.key;

      return <th key={`column-${column.key}`} className={cx('tableHeader')} style={ column.style || null } >
        <div className={cx('headerCell', { sortableColumn: column.isSortable })}>
          {column.isSortable ?  
            <button onClick={clickHandler} 
                    aria-label={t('sortBy') + " " + headerName + " " + 
                                (sortColumnIndex === index ? t('sorted') + (direction === 'Down' ? t('ascending') : t('descending')) : '')}>
                <span className={cx('headerName')}>{headerName}</span>
                {isSortVisible && sortColumnIndex === index && 
                  <div className={cx('sortIcon')} style={ headerName.length > 0 ? {} : {paddingRight: '8px' } }>
                    <Icon id={cx(`iconSort${direction}-${theme}`)} className={cx('icon16')}/>
                  </div> 
                }
                {column.iconList?.filter(i => i).map(icon => 
                  <Icon key={index + '_' + icon} title={icon} id={icon} className={cx('icon16')}/>
                )}
            </button>
            :
            <>
              <span className={cx('headerName')} aria-hidden={!headerName}>{headerName}</span>
              {column.iconList?.filter(i => i).map((icon, idx) => 
                <Icon title={icon} key={`headerColumn-${idx}`} id={icon} className={cx('icon16')}/>
              )}
            </>
          }
        </div>
      </th>;
    });

    if(canExpandRows) {
      header.unshift(<th key={`column-expand`} className={cx('tableHeader')} style={ { width: '50px' } }></th>);
    }

    let tableBody = [];
    for(let i = 0; i < rows.length; i++) {
      let isExpanded = canExpandRows && expandedRowIndices.indexOf(i) !== -1;
      let isSelected = selectedRowIndex === i;
      tableBody.push(renderRow(rows[i], i, isExpanded, isSelected, handleOnClickRow));

      if(isExpanded) {
        tableBody.push(<tr key={`row-${i}-details-slideout`} className={cx('detailsSlideout')}>
          <td colSpan={columns.length + (canExpandRows ? 1 : 0)}>
            {renderExpandedRowContents(rows[i], i)}
          </td>
        </tr>);
      }
    }

    return <table className={cx('table')} aria-label={ariaLabel}>
      <thead><tr className={cx('headerRow')}>{ header }</tr></thead>
      <tbody>{ tableBody }</tbody>
    </table>;
  }
}

export default SortableTable;
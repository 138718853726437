import * as React from 'react';
import update from 'immutability-helper';
import classNames from 'classnames/bind';
import Utils from '../../services/Utils';
import AggregatesQuery from '../../models/AggregatesQuery';
import DropdownMenuContainer from '../DropdownMenu/DropdownMenu.container';
import Icon from '../Icon/Icon';
import { MetadataPropertyTypes, KeyCodes, CurveStepInterpolationFunctions, InterpolationFunctions } from '../../../constants/Enums';
import ColorPickerContainer from '../ColorPicker/ColorPicker.container';
const cx = classNames.bind(require('./AdHocQueryBuilder.module.scss'));

interface Props { theme: string; aggregatesQueries: Array<AggregatesQuery>; addAggregatesQuery: any; 
                getQueryResults: any; removeAggregatesQuery: any; metadata: any; t: any; setAQField: any;}

interface State {aliasArray: Array<string>}

export default class AdHocQueryBuilder extends React.Component<Props, State> {
    private activeColorPicker: any;

    constructor(props: Props) {
        super(props);
        this.state = {aliasArray: this.props.aggregatesQueries.map(aQ => aQ['alias'])};
    }

    componentDidUpdate(prevProps) {
        if (this.props.aggregatesQueries.length !== prevProps.aggregatesQueries.length) {
            this.setState(update(this.state, {aliasArray: {$set: this.props.aggregatesQueries.map(aQ => aQ['alias'])}}));
        }
    }

    handleScroll = (e) => {
        if(this.activeColorPicker && e.target !== this.activeColorPicker.getColorPickerElem().select('.tsi-colorGrid').node()){
            this.activeColorPicker.hideColorGrid();
        }
    }

    onColorPickerClicked = (e, colorPicker) => {
        if(this.activeColorPicker){
            this.activeColorPicker.hideColorGrid();
        }
        this.activeColorPicker = colorPicker;
    }

    setField = (index, property, value) => {
        switch(property) {
            case "where": 
                this.props.setAQField(index, 'predicate', AggregatesQuery.constructPredicateFromInput(value));
                break;
            case "measure":
                this.props.setAQField(index, 'measureObject', AggregatesQuery.constructMeasureObjectFromInput(value));
                this.props.setAQField(index, 'measureTypes', AggregatesQuery.constructMeasureArrayFromInput(value));
                this.props.getQueryResults();
                break;
            case "splitBy":
                this.props.setAQField(index, 'splitByObject', AggregatesQuery.constructSplitByObjectFromInput(value));
                this.props.getQueryResults();
                break;
            case "color":
                this.props.setAQField(index, property, value);
                break;
            default: 
                this.props.setAQField(index, property, value);
                break;
        }
    }

    addAggregateQuery = () => {
        let queryName = `${this.props.t('query')} ${this.props.aggregatesQueries.length + 1}`;
        let defaultAQ = AggregatesQuery.defaultAggregatesQuery(queryName);
        this.props.addAggregatesQuery(defaultAQ);
        this.props.getQueryResults();
        this.setAliasArray(this.props.aggregatesQueries.length, queryName);
    }

    setAliasArray = (idx, value) => {
        this.setState(update(this.state, {aliasArray: {[idx]: {$set: value}}}));
    }

    removeFromAliasArray = (idx) => {
        this.setState(update(this.state, {aliasArray: {$splice: [[idx, 1]]}}));
    }

    removeQuery = (t, i) => {
        delete Utils.usedColors[t.color];
        this.props.removeAggregatesQuery(i);
        this.removeFromAliasArray(i);
    }

    render() {
        let measures = this.props.metadata.filter(m => m.type === MetadataPropertyTypes.Double).map(m => m.name).sort();
        measures.unshift('Event Count');
        let splitBys = this.props.metadata.filter(m => m.type === MetadataPropertyTypes.String).map(m => m.name).sort();
        splitBys.unshift('(none)');
        return <div className={cx('theme-' + this.props.theme, 'wrapper')}>
                    <button className={cx('addButton', {primary: this.props.aggregatesQueries.length === 0})} onClick={() => this.addAggregateQuery()}>{this.props.t('adHocQueryBuilder.addQuery')}</button>
                    {this.props.aggregatesQueries.length > 0 && 
                        <div className={cx('queries')} onScroll={this.handleScroll}>
                            {this.props.aggregatesQueries.map((aQ: any, i) => {
                                return <QueryBlock 
                                            key={`aQ-${i}`}
                                            aQ={aQ} 
                                            aQIndex={i} 
                                            t={this.props.t} 
                                            theme={this.props.theme} 
                                            setField={this.setField}
                                            setAliasArray={this.setAliasArray}
                                            alias={this.state.aliasArray[i] !== undefined ? this.state.aliasArray[i] : aQ.alias}
                                            measures={measures}
                                            splitBys={splitBys}
                                            onRemove={this.removeQuery}
                                            onColorPickerClicked={this.onColorPickerClicked}
                                            getQueryResults={this.props.getQueryResults}/>
                            })} 
                        </div>
                    }
                </div>
    }
}

const QueryBlock = ({aQ, aQIndex, t, theme, setField, setAliasArray, alias, measures, splitBys, onRemove, onColorPickerClicked, getQueryResults}) => {
    let where = aQ.predicate ? aQ.predicate.predicateString : '';
    let measure =  aQ.measureObject.count ? 'Event Count' : aQ.measureObject.input.property;
    let splitBy = aQ.splitByObject ? aQ.splitByObject.property : '(none)';

    return <div className={cx('buildArea')}>
        <div className={cx('buildAreaTop')}>
            <ColorPickerContainer 
                className={cx('colorPicker')}
                defaultColor={aQ.color}
                isColorValueHidden={true}
                numberOfColors={24}
                colors={Utils.defaultColors}
                onSelect={(color) => {
                    setField(aQIndex, 'color', color);
                    delete Utils.usedColors[aQ.color];
                    Utils.usedColors[color] = true;
                }}
                onClick={(e, colorPicker) => {
                    onColorPickerClicked(e, colorPicker);
                    let targetElement = colorPicker.getColorPickerElem().node();
                    setTimeout(()=>{
                        targetElement.querySelector('.tsi-colorGrid').style.marginTop = -(
                            + targetElement.querySelector('.tsi-colorPickerButton').clientHeight 
                            + document.getElementsByClassName(cx('queries'))[0].scrollTop) +'px';
                    }); 
                }}>
            </ColorPickerContainer>
            <input type='text' 
                value={alias} 
                title={t('adHocQueryBuilder.pressEnter')} 
                onChange={(e) => setAliasArray(aQIndex, e.target.value)} 
                onKeyDown={(e) => {if(e.keyCode === KeyCodes.Enter) {setField(aQIndex, 'alias', (e.target as HTMLInputElement).value); getQueryResults()}}}
                onBlur={(e) => {setField(aQIndex, 'alias', (e.target as HTMLInputElement).value);}} 
                className={cx('queryAlias')}/>
            <button className={cx('removeButton')} onClick={() => onRemove(aQ, aQIndex)} title={t('remove')} aria-label={t('remove')}>
                <Icon id={'iconClose-' + theme} className={cx('icon16')}/>       
            </button>
        </div>

        <div className={cx('buildAreaContent')}>
            <div className={cx('contentTop')}>
                <div className={cx('menu')}>
                    {aQ.measureTypes[0] === 'avg' ?
                        <DropdownMenuContainer containerClassName={cx('menuContainerClassName')} dropdownMenuOptions={[
                            [(<Icon id={'iconGear-' + theme} className={cx('wellSwap', 'icon16')} />), 
                                aQ.interpolationFunction === CurveStepInterpolationFunctions.curveStep ? t('well.disableSteppedInterpolation') : t('well.enableSteppedInterpolation'), 
                                () => { 
                                    aQ.interpolationFunction === CurveStepInterpolationFunctions.curveStep ? setField(aQIndex, 'interpolationFunction', InterpolationFunctions.none) : setField(aQIndex, 'interpolationFunction', CurveStepInterpolationFunctions.curveStep)
                                }
                            ], 
                            [(<Icon id={'iconGear-' + theme} className={cx('wellSwap', 'icon16')} />), 
                                aQ.includeEnvelope ? t('well.disableMinMaxShadows') : t('well.enableMinMaxShadows'), 
                                () => {
                                    aQ.includeEnvelope ? setField(aQIndex, 'includeEnvelope', false) : setField(aQIndex, 'includeEnvelope', true)
                                }
                            ],
                            [(<Icon id={'iconGear-' + theme} className={cx('wellSwap', 'icon16')} />), 
                                aQ.includeDots ? t('well.disableDots') : t('well.enableDots'), 
                                () => {
                                    aQ.includeDots ? setField(aQIndex, 'includeDots', false) : setField(aQIndex, 'includeDots', true)
                                }
                            ]
                        ]} dropdownMenuSide='left' menuButtonLabel={t('adHocQueryBuilder.queryProperties')}/>
                        :
                        <DropdownMenuContainer containerClassName={cx('menuContainerClassName')} dropdownMenuOptions={[
                            [(<Icon id={'iconGear-' + theme} className={cx('wellSwap', 'icon16')} />), 
                                aQ.interpolationFunction === CurveStepInterpolationFunctions.curveStep ? t('well.disableSteppedInterpolation') : t('well.enableSteppedInterpolation'), 
                                () => { 
                                    aQ.interpolationFunction === CurveStepInterpolationFunctions.curveStep ? setField(aQIndex, 'interpolationFunction', InterpolationFunctions.none) : setField(aQIndex, 'interpolationFunction', CurveStepInterpolationFunctions.curveStep)
                                }
                            ],
                            [(<Icon id={'iconGear-' + theme} className={cx('wellSwap', 'icon16')} />), 
                                aQ.includeDots ? t('well.disableDots') : t('well.enableDots'), 
                                () => {
                                    aQ.includeDots ? setField(aQIndex, 'includeDots', false) : setField(aQIndex, 'includeDots', true)
                                }
                            ]
                        ]} dropdownMenuSide='left' menuButtonLabel={t('adHocQueryBuilder.queryProperties')}/>
                    }
                </div>
            </div>

            <div className={cx('contentBottom')}>
                <div className={cx('block')}>
                    <div className={cx('title')}>{t('adHocQueryBuilder.where')}</div>
                    <textarea title={t('adHocQueryBuilder.pressEnter')} 
                        placeholder={measures[1] ? `e.g. [${measures[1]}] > 5` : ''} 
                        value={where} 
                        onChange={(e) => setField(aQIndex,'where', e.target.value)} 
                        onKeyDown={(e) => {if(e.keyCode === KeyCodes.Enter) {e.preventDefault(); getQueryResults()}}} 
                        aria-label={t('adHocQueryBuilder.where')}/>
                </div>
                <div className={cx('block')}>
                    <div className={cx('title')}>{t('adHocQueryBuilder.measure')}</div>
                    <select value={measure} onChange={(e) => setField(aQIndex,'measure', e.target.value)} aria-label={t('adHocQueryBuilder.measure')}>
                        {measures.map((m, i) => <option key={i} value={m}>{m}</option>)}
                    </select>
                </div>
                <div className={cx('block')}>
                    <div className={cx('title')}>{t('adHocQueryBuilder.splitBy')}</div>
                    <select value={splitBy} onChange={(e) => setField(aQIndex, 'splitBy', e.target.value)} aria-label={t('adHocQueryBuilder.splitBy')}>
                        {splitBys.map((sb, i) => <option key={i} value={sb}>{sb}</option>)}
                    </select>
                </div>
            </div>
        </div>
    </div>
}

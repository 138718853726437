import React from "react";
import { CommandBarUtilities } from "../application/components/FluentCommandBar/FluentCommandBar";
import Icon from "../application/components/Icon/Icon";
import Utils from "../application/services/Utils";
import { ChartTypes } from "./Enums";
import '../resources/styles/global.scss';

export const GeneralIcons = {
    icons: {
        'addLight': <Icon id="iconAdd-light" className="tsi-icon16" />,
        'addDark': <Icon id="iconAdd-dark" className="tsi-icon16" />,
        'closeLight': <Icon id="iconClose-light" className="tsi-icon16" />,
        'closeDark': <Icon id="iconClose-dark" className="tsi-icon16" />,
    }
};

const commonChartIcons = {
    'chevronDown': <Icon id='chevron' className={CommandBarUtilities.style('fluentCommandBarIcon','chevronDown')}></Icon>,
    'chevronRight': <Icon id='chevron' className={CommandBarUtilities.style('fluentCommandBarIcon','subMenuChevron')}/>,
    'ellipsisLight': <Icon id='iconEllipsis-light' className={CommandBarUtilities.style('overflowIcon')}/>,
    'ellipsisDark': <Icon id='iconEllipsis-dark' className={CommandBarUtilities.style('overflowIcon')}/>,
}

export const chartCommandingIcons = {
    icons: {
        ...commonChartIcons,
        [ChartTypes.Linechart]: <Icon id={'linechart'} className={CommandBarUtilities.style('fluentCommandBarIcon')}/>,
        [ChartTypes.Scatterplot + 'light']: <Icon id={'scatterplot-light'} className={CommandBarUtilities.style('fluentCommandBarIcon')}/>,
        [ChartTypes.Scatterplot + 'dark']: <Icon id={'scatterplot-dark'} className={CommandBarUtilities.style('fluentCommandBarIcon')}/>,
        [ChartTypes.Heatmap]: <Icon id={'heatmap'} className={CommandBarUtilities.style('fluentCommandBarIcon')}/>,
        'zoom': <Icon id={'expandIconlight'} className={CommandBarUtilities.style('fluentCommandBarIcon', 'zoomControlIcon')} />,
        [Utils.getStackStates().Stacked]: <Icon key={0} id={'stack'} className={CommandBarUtilities.style('fluentCommandBarIcon')}/>,
        [Utils.getStackStates().Shared]: <Icon key={0} id={'shared'} className={CommandBarUtilities.style('fluentCommandBarIcon')}/>,
        [Utils.getStackStates().Overlap]: <Icon key={0} id={'overlap'} className={CommandBarUtilities.style('fluentCommandBarIcon')}/>,
        'expand': <Icon id={'expandChart'} className={CommandBarUtilities.style('fluentCommandBarIcon')} />,
        'collapse': <Icon id={'collapseChart'} className={CommandBarUtilities.style('fluentCommandBarIcon')} />,
        'Download': <Icon id={'download'} className={CommandBarUtilities.style('fluentCommandBarIcon')}/>,
        'pbiLight': <Icon id={'pbi'} className={CommandBarUtilities.style('fluentCommandBarIcon')}/>,
        'pbiDark': <Icon id={'pbi'} className={CommandBarUtilities.style('fluentCommandBarIcon', 'invert')}/>,
        'gridThumbnail': <Icon id={'gridThumbnail'} className={CommandBarUtilities.style('fluentCommandBarIcon', 'gridThumbnail')}/>,
        'rawEvents': <Icon id={'rawEvents'} className={CommandBarUtilities.style('fluentCommandBarIcon', 'rawEvents')}/>,
        'zoomOutLight': <Icon id={'zoomOutlight'} className={CommandBarUtilities.style('zoomIcon')}/>,
        'zoomOutDark': <Icon id={'zoomOutdark'} className={CommandBarUtilities.style('zoomIcon')}/>,
        'zoomInLight': <Icon id={'zoomInlight'} className={CommandBarUtilities.style('zoomIcon')}/>,
        'zoomInDark': <Icon id={'zoomIndark'} className={CommandBarUtilities.style('zoomIcon')}/>,
        'leftChevron': <Icon id='chevron' className={CommandBarUtilities.style('zoomIcon', 'leftChevron')}/>,
        'rightChevron': <Icon id='chevron' className={CommandBarUtilities.style('zoomIcon', 'rightChevron')}/>,
        'undo': <Icon className={CommandBarUtilities.style('fluentCommandBarIcon')} id={'undo'}></Icon>,
        'marker': <Icon id={'marker'} className={CommandBarUtilities.style('fluentCommandBarIcon')}/>,
        'gearLight': <Icon id='iconGear-light' className={CommandBarUtilities.style('fluentCommandBarIcon')}/>,
        'gearDark': <Icon id='iconGear-dark' className={CommandBarUtilities.style('fluentCommandBarIcon')}/>,
    }
}

export const analyticsCommandingIcons = {
    icons: {
        ...commonChartIcons,
        'openFolderLight': <Icon id={'openFolder-light'} className={CommandBarUtilities.style('fluentCommandBarIcon')}/>,
        'openFolderDark': <Icon id={'openFolder-dark'} className={CommandBarUtilities.style('fluentCommandBarIcon')}/>,
        'iconSaveLight': <Icon id={'iconSave-light'} className={CommandBarUtilities.style('fluentCommandBarIcon', 'small')}/>,
        'iconSaveDark': <Icon id={'iconSave-dark'} className={CommandBarUtilities.style('fluentCommandBarIcon', 'small')}/>,
        'linkLight': <Icon id={'link-light'} className={CommandBarUtilities.style('fluentCommandBarIcon')}/>,
        'linkDark': <Icon id={'link-dark'} className={CommandBarUtilities.style('fluentCommandBarIcon')}/>,
        'refreshLight': <Icon id={'refresh-light'} className={CommandBarUtilities.style('fluentCommandBarIcon', 'small')}/>,
        'refreshLightRotating': <Icon id={'refresh-light'} className={CommandBarUtilities.style('fluentCommandBarIcon', 'rotating', 'small')}/>,
        'refreshDark': <Icon id={'refresh-dark'} className={CommandBarUtilities.style('fluentCommandBarIcon', 'small')}/>,
        'refreshDarkRotating': <Icon id={'refresh-dark'} className={CommandBarUtilities.style('fluentCommandBarIcon', 'rotating', 'small')}/>,
        'gearLight': <Icon id='iconGear-light' className={CommandBarUtilities.style('fluentCommandBarIcon')}/>,
        'gearDark': <Icon id='iconGear-dark' className={CommandBarUtilities.style('fluentCommandBarIcon')}/>,
    }
}
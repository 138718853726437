import * as React from 'react';
import classNames from 'classnames/bind';
import Icon from '../Icon/Icon';
import SidePane from '../Modal/SidePane/SidePane';
import InfoBanner from '../InfoBanner/InfoBanner';
import Utils from '../../services/Utils';
import TelemetryService from '../../services/TelemetryService';
const cx = classNames.bind(require('./Feedback.module.scss'));

interface Props {
    t: any;
    theme: string;
    isFormOpen: boolean;
    issue: string;
    requestId: string;
    responseText: string;
    openForm: () => {};
    closeForm: () => {};
    closeAndClearForm: () => {};
    fullHeightNoToggle?: boolean;
}

interface State {
    feedbackText: string;
    feedbackSubmitted: boolean;
    userConsentsToEmail: boolean;
}

export default class Feedback extends React.Component<Props, State> {

    static defaultProps = {
        fullHeightNoToggle: false
    };

    private closeFormTimeout: number;

    constructor(props: Props) {
        super(props);
        this.state = {
            feedbackText: '',
            feedbackSubmitted: false,
            userConsentsToEmail: false
        };
    }

    submitFeedback = () => {
        if (!this.state.feedbackSubmitted) {
            if (this.state.feedbackText.length) {
                let feedbackObj: any = { 
                    feedback: this.state.feedbackText,
                    userConsentsToEmail: this.state.userConsentsToEmail,
                    sessionId: Utils.sessionId
                };
                if(this.props.issue || this.props.requestId) {
                    feedbackObj.issue = this.props.issue;
                    feedbackObj.requestId = this.props.requestId;
                    feedbackObj.responseText = this.props.responseText;
                }

                TelemetryService.logUserAction('feedback', feedbackObj);
            }

            this.setState({ 
                feedbackSubmitted: true,
            });

            this.closeFormTimeout = window.setTimeout(() => {
                this.setState({ 
                    feedbackSubmitted: false,
                    feedbackText: '',
                    userConsentsToEmail: false
                 });

                this.props.closeAndClearForm();
            }, 3000);
        }
    }

    componentWillMount() {
        if(this.closeFormTimeout) {
            window.clearTimeout(this.closeFormTimeout);
        }
    }

    handleOnClickToggle = () => {
        if (this.props.isFormOpen) {
            this.props.closeForm();
        } else {
            this.props.openForm();
        }
    }

    handleOnChangeFeedbackText = (event) => {
        this.setState({
            feedbackText: event.target.value
        });
    }

    handleOnChangeUserEmailConsent = (event) => {
        this.setState(prevState => ({
            userConsentsToEmail: !prevState.userConsentsToEmail
        }));
    }

    render() {
        let sessionId = Utils.sessionId;
        let issueStyle: any =  { 
            visibility: this.props.issue ? 'visible' : 'hidden', 
            marginTop: this.props.issue ? '8px' : '0'
        };

        return <div className={cx('wrapper')}>
            {!this.props.fullHeightNoToggle && 
                <button aria-label={this.props.t('openFeedbackForm')} onClick={this.handleOnClickToggle} className={cx('openToggle', {open: this.props.isFormOpen})}>
                    <Icon id="smile" className={cx('icon16')} />
                </button>
            }
            {this.props.isFormOpen &&
                <SidePane title={this.props.t('sendFeedback')} handleOnClose={this.props.closeForm} panePositionClassName={this.props.fullHeightNoToggle ? cx('fullHeightSidePane') : ''}>
                    <div className={cx('content')}>
                        <div>
                            { this.props.t('feedbackThanks') }
                            <InfoBanner onClick={() => { 
                                window.open('https://ms.portal.azure.com/#create/Microsoft.Support', '_blank'); 
                            }}>
                                <span>
                                    { this.props.t('feedbackSupportLinkText') } 
                                    {this.props.t('support') }.
                                </span>
                            </InfoBanner>
                        </div>
                        <div className={cx('textAreaWrapper')}>
                            <div className={cx('issue')} style={ issueStyle }>
                                <span className={cx('label')}>{this.props.t('feedbackIssue')} </span>
                                <span>{ this.props.issue }</span>
                            </div>
                            <textarea className={cx('feedbackTextArea')} placeholder={this.props.t('feedbackPlaceholder')} onChange={this.handleOnChangeFeedbackText}>
                                {this.state.feedbackText || null}
                            </textarea>
                            <div className={cx('feedbackIds')}>
                                { this.props.requestId &&
                                    <div><span className={cx('label')}>{ this.props.t('feedbackRequestId') } </span>
                                    <span>{ this.props.requestId }</span></div> }
                                { sessionId &&
                                    <div><span className={cx('label')}>{ this.props.t('feedbackSessionId') } </span>
                                    <span>{ sessionId }</span></div> }
                                { this.props.responseText &&
                                    <div><span className={cx('label')}>{ this.props.t('feedbackResponseText') } </span>
                                    <span>{ this.props.responseText }</span></div> }
                            </div>
                        </div>
                        {this.state.feedbackSubmitted &&
                            <div className={cx('thanksToast')}>
                                <Icon id={'iconStatusOK-' + this.props.theme} className={cx('icon16')} />
                                {this.props.t('feedbackSubmitThanks')}
                            </div>}
                        <div className={cx('feedbackFooter')}>
                            <div className={cx('userConsent')}>
                                <button role="checkbox"
                                    className={cx('checkbox')}
                                    aria-label= {this.props.t('feedbackUserConsentToEmail')}
                                    aria-checked={this.state.userConsentsToEmail}
                                    onClick={this.handleOnChangeUserEmailConsent}
                                    aria-labelledby="feedback-userConsentToEmail" >
                                    <Icon id="checkbox" className={cx('icon16', 'tsiCb', { tsiCbSelected: this.state.userConsentsToEmail })} />
                                </button>
                                <span id="feedback-userConsentToEmail" onClick={this.handleOnChangeUserEmailConsent}>
                                    { this.props.t('feedbackUserConsentToEmail') }
                                </span>
                            </div>
                            <p>
                                <a target="_blank" rel="noopener noreferrer" href="https://go.microsoft.com/fwlink/?linkid=2128146">{this.props.t('feedbackPrivacyStatement')}</a>
                            </p>
                        </div>
                        <button onClick={this.submitFeedback} className={cx('_base-primary-button')}>{this.props.t('submitFeedback')}</button>
                    </div>
                </SidePane>
            }
        </div>;
    }
}
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import AppSettings from './AppSettings';
import { getTheme, getIsAppSettingsOpen } from '../../state/reducers/AppSettingsReducer';
import { TOGGLE_OPEN_APP_SETTINGS, SET_THEME } from '../../../constants/ActionTypes';

const mapStateToProps = state => {
    return { 
        theme: getTheme(state),
        isAppSettingsOpen: getIsAppSettingsOpen(state)
    };
};

const mapDispatchToProps = dispatch => ({ 
    toggleOpenAppSettings: () => dispatch({type: TOGGLE_OPEN_APP_SETTINGS}),
    setTheme: () => dispatch({type: SET_THEME}),
})

const AppSettingsContainer = translate()(connect(mapStateToProps, mapDispatchToProps)(AppSettings));

export default AppSettingsContainer;

import * as React from 'react';
import classNames from 'classnames/bind';
import Utils from '../../services/Utils';
import update from 'immutability-helper';
import TypeEntity from '../../models/TypeEntity';
import HierarchyEntity from '../../models/HierarchyEntity';
import InstanceEntity from '../../models/InstanceEntity';
import { Variable } from '../../models/Variable';
import { HierarchyLevel } from '../../models/HierarchyLevel';
import { TSMEntityKinds, RequestStatus, TSMModalNumbers, RequestMethods, FileUploadStatus } from '../../../constants/Enums';
import BulkUploadModal from './Implementations/BulkUploadModal';
import TypeModal from './Implementations/TypeModal';
import HierarchyModal from './Implementations/HierarchyModal';
import InstanceModal from './Implementations/InstanceModal';
import TypeVariableModal from './Implementations/TypeVariableModal';
import { InstanceField } from '../../models/InstanceField';
import { bulkUploadOverflowCharacterLimit } from '../../../constants/Constants';
const cx = classNames.bind(require('./TsmModal.module.scss'));

interface Props { theme: string; isTsmModalVisible: boolean; entityType: string; entityToEdit: any; subEntityIdxToEdit: any; 
                    types: any; hierarchies: any; metadata: any; progress: RequestStatus; errorMessage: string; errorCode: string; 
                    requestMethod: string; addType: string; closeTsmModal: any; putType: any; putHierarchy: any; putInstances: any; 
                    clearProgress: any; t: any; validBucketSizes: Array<any>; searchText: string; showMigrationModal: any; environment:any; 
                    bulkUploadInstancesProgress: number; timeSeriesIdProperties: any; isUserContributor: boolean;} 
interface State { modalNumber: any; valueOptions: any; fileUploadStatus: string | null; fileUploadMessage: string; updateOnly: boolean;
    isSubEntityTab: boolean; entity: any; subEntity: any; subEntityCallFromInline: boolean; sortingField: string; sortDirection: string; subEntityIndex: number; isAdvancedOptionsExpanded: boolean;}

export default class TsmModal extends React.Component<Props, State> {
    private entitiesEnd;
    private closeModalTimeout: number;
    private focusTimeout: number;

    constructor(props: Props) {
        super(props);
        let modalNumber = TSMModalNumbers.Type;
        let fileUploadStatus = null;
        let fileUploadMessage = "";
        let updateOnly = true;
        let entity = this.props.entityToEdit;
        let valueOptions = [];
        let isSubEntityTab = false;
        let subEntityIndex = null;
        let subEntity = null;
        this.closeModalTimeout = this.focusTimeout = null;

        if (this.props.entityType === TSMEntityKinds.Types) {
            if (this.props.addType === "bulk") {
                modalNumber = TSMModalNumbers.Type_Bulk;
            } else {
                this.props.metadata.map(md => valueOptions.push(Utils.tsxFromEventSchema(md)));
                if (entity != null) {
                    entity = TypeEntity.fromObject(entity, valueOptions);
                } else {
                    entity = new TypeEntity();
                }
                if (this.props.subEntityIdxToEdit !== null) {
                    modalNumber = TSMModalNumbers.Type_Variable;
                    if (this.props.subEntityIdxToEdit !== -1) {
                        subEntityIndex = this.props.subEntityIdxToEdit;
                        subEntity = Variable.fromObject(Object.assign({}, entity.variables[subEntityIndex]), valueOptions);
                    } else {
                        subEntity = new Variable(valueOptions);
                    }
                } else {
                    modalNumber = TSMModalNumbers.Type;
                }
            }
        } else if (this.props.entityType === TSMEntityKinds.Hierarchies) {
            if (this.props.addType === "bulk") {
                modalNumber = TSMModalNumbers.Hierarchy_Bulk;
            } else {
                if (entity != null) {
                    entity = HierarchyEntity.fromObject(entity);
                } else {
                    entity = new HierarchyEntity();
                }
                if (this.props.subEntityIdxToEdit !== null) {
                    if (this.props.subEntityIdxToEdit !== -1) {
                        subEntityIndex = this.props.subEntityIdxToEdit;
                    } else {
                        let newHierarchyLevel = new HierarchyLevel();
                        subEntityIndex = entity.levels.length;
                        entity.levels.push(newHierarchyLevel);
                    }
                }
                modalNumber = TSMModalNumbers.Hierarchy;
            }
        } else {
            if (this.props.addType === "bulk") {
                modalNumber = TSMModalNumbers.Instance_Bulk;
            } else {
                if (entity != null) {
                    this.props.types.forEach(t => {if (t.name === (entity.type ? Utils.stripHits(entity.type) : Utils.stripHits(entity.typeName))) {entity.typeId = t.id; }});
                    entity = InstanceEntity.fromObject(entity, this.getTsidLength());
                    props.hierarchies.forEach(h => {
                        if (entity.hierarchyIds?.indexOf(h.id) >= 0) {
                            h.source.instanceFieldNames.forEach(field => {
                                if (entity.instanceFields?.filter(iF => iF.name === field).length === 0) {
                                    entity.instanceFields.push(InstanceField.fromObject({name: field}));
                                }
                            });
                        }
                    });
                } else {
                    entity = new InstanceEntity(this.getTsidLength());
                }

                if (this.props.subEntityIdxToEdit !== null) {
                    modalNumber = TSMModalNumbers.Instance_InstanceFields;
                    if (this.props.subEntityIdxToEdit !== -1) {
                        subEntityIndex = this.props.subEntityIdxToEdit;
                    } else {
                        if (!entity.instanceFields) {
                            entity.instanceFields.push(new InstanceField());
                            subEntityIndex = 0;
                        } else {
                            subEntityIndex = Object.keys(entity.instanceFields).length - 1;
                        }
                    }
                    isSubEntityTab = true;
                } else {
                    modalNumber = TSMModalNumbers.Instance;
                }
            }
        }
        this.state = {modalNumber: modalNumber, fileUploadStatus: fileUploadStatus, fileUploadMessage: fileUploadMessage, updateOnly: updateOnly, 
                        isSubEntityTab: isSubEntityTab, entity: entity, subEntity: subEntity, subEntityCallFromInline: subEntity ? true : false, 
                        sortingField: null, sortDirection: 'Down', subEntityIndex: subEntityIndex, isAdvancedOptionsExpanded: false, valueOptions: valueOptions};
    }

    componentWillUnmount() {
        if (this.closeModalTimeout) {
            window.clearTimeout(this.closeModalTimeout);
        }
        if (this.focusTimeout) {
            window.clearTimeout(this.focusTimeout);
        }
    }

    componentWillReceiveProps(nextProps) {
        if ((this.props.progress !== nextProps.progress) && (nextProps.progress === RequestStatus.Successful) && (this.state.modalNumber !== TSMModalNumbers.Type_Variable || this.state.subEntityCallFromInline)) { /** used timeout just to show the Successful message right before closing the drawer */
            this.closeModalTimeout = window.setTimeout(() => {
                this.closeModal(null);
              }, 1500);
        }
    }

    componentDidMount() {
        this.focusTimeout = window.setTimeout(() => {  /** wait for the sliding left animation to be completed, then focus on the selected sub entity (instance field or hierarchy level) to edit when inline edit button clicked on the list */
            if (this.props.subEntityIdxToEdit !== null && this.state.modalNumber === TSMModalNumbers.Instance_InstanceFields) {
                if (this.props.subEntityIdxToEdit === -1) {// meaning inline add new instance field button clicked
                    Utils.tryFocusElement('instanceFieldName', this.state.subEntityIndex, "input");
                } else {
                    Utils.tryFocusElement('instanceFieldValue', this.state.subEntityIndex, "input");
                }
            } else if (this.props.subEntityIdxToEdit !== null && this.state.modalNumber === TSMModalNumbers.Hierarchy) {
                Utils.tryFocusElement(cx('level'), this.state.subEntityIndex, "input");
            }
        }, 500);
    }

    componentDidUpdate(prevProps, prevState){
        if (this.state.modalNumber === TSMModalNumbers.Type_Variable && this.state.subEntity && prevState.subEntity) {
            if ((this.state.subEntity.categories && !prevState.subEntity.categories) || (prevState.subEntity.categories && this.state.subEntity.categories && (prevState.subEntity.categories.length < this.state.subEntity.categories.length))) { // new category is added or variable kind is set to categorical, focus on the newly added empty one
                this.focusTimeout = window.setTimeout(() => {
                    Utils.tryFocusElement(cx('categoryLabel'), this.state.subEntity.categories.length - 1, "input");
                }, 500);
            } else if ((!this.state.subEntity.categories && prevState.subEntity.categories) || (prevState.subEntity.categories && this.state.subEntity.categories && (prevState.subEntity.categories.length > this.state.subEntity.categories.length))) {
                Utils.tryFocusElement(cx('sub-button'), 0, "a");
            }
        }
        else if (this.state.modalNumber === TSMModalNumbers.Hierarchy) {
            if (prevState.entity.levels.length < this.state.entity.levels.length) { // new hierarchy level is added, focus on the newly added empty one
                this.focusTimeout = window.setTimeout(() => {
                    Utils.tryFocusElement(cx('level'), this.state.entity.levels.length - 1, "input");
                }, 500);
            } else if (prevState.entity.levels.length > this.state.entity.levels.length) { // a hierarchy is removed, focus on the add level button
                Utils.tryFocusElement(cx('sub-button'), 0, "a");
            }
        } else if (((this.state.modalNumber === TSMModalNumbers.Instance_InstanceFields) || (this.state.modalNumber === TSMModalNumbers.Instance && this.state.isSubEntityTab)) && prevState.entity.instanceFields !== undefined && this.state.entity.instanceFields !== undefined) {
            if (prevState.entity.instanceFields.length < this.state.entity.instanceFields.length) { // new instance field is added, focus on the newly added empty one
                this.focusTimeout = window.setTimeout(() => {
                    Utils.tryFocusElement(cx('instanceFieldName'), this.state.entity.instanceFields[this.state.entity.instanceFields.length - 1].name, "input");
                }, 500);
            } else if (prevState.entity.instanceFields.length > this.state.entity.instanceFields.length) { // an instance field is removed, focus on the add instance level button
                Utils.tryFocusElement(cx('sub-button'), 0, "a");
            }
        }
    }

    // begin component functions
    closeModal = (e) => {
        if (Utils.isKeyDownAndNotEnter(e)) {return; }
        this.props.closeTsmModal();
    }

    switchToMigrationModal = () => {
        this.props.closeTsmModal();
        this.props.showMigrationModal();
    }

    setIsSubEntityTab = (isSubEntityTab) => {
        this.setState({isSubEntityTab: isSubEntityTab});
    }

    setOrToggleSorting = (field) => {
        if (this.props.entityType === TSMEntityKinds.Hierarchies) {
            let sortedResult = this.state.entity.levels;
            if (this.state.sortingField !== field) {
                sortedResult.sort((a,b) => {
                    return a[field] < b[field] ? -1 : 1;
                });
                this.setState(update(this.state, {entity: {levels: {$set: sortedResult}}, sortingField: {$set: field}, sortDirection: {$set: "Down"}, subEntityIndex: {$set: null}, subEntity: {$set: null}}));
            } else {
                sortedResult.sort((a,b) => {
                    return ((a[field] < b[field]) === (this.state.sortDirection === "Down") ? 1 : -1);
                });
                this.setState(update(this.state, {entity: {levels: {$set: sortedResult}}, sortingField: {$set: field}, sortDirection: {$set: this.state.sortDirection === "Down" ? "Up" : "Down"}, subEntityIndex: {$set: null}, subEntity: {$set: null}}));
            } 
        } else if (this.props.entityType === TSMEntityKinds.Types) {
            let sortedResult = this.state.entity.variables;
            if (this.state.sortingField !== field) {
                sortedResult.sort((a,b) => {
                    return a[field] < b[field] ? -1 : 1;
                });
                this.setState(update(this.state, {entity: {variables: {$set: sortedResult}}, sortingField: {$set: field}, sortDirection: {$set: "Down"}, subEntityIndex: {$set: null}, subEntity: {$set: null}}));
            } else {
                sortedResult.sort((a,b) => {
                    return ((a[field] < b[field]) === (this.state.sortDirection === "Down") ? 1 : -1);
                });
                this.setState(update(this.state, {entity: {variables: {$set: sortedResult}}, sortingField: {$set: field}, sortDirection: {$set: this.state.sortDirection === "Down" ? "Up" : "Down"}, subEntityIndex: {$set: null}, subEntity: {$set: null}}));
            }
        }
    }
    

    setSubEntityIndexToEdit = (idx, subEntity = null) => {
        let returnModalNumber = this.props.entityType === TSMEntityKinds.Types ? TSMModalNumbers.Type : this.props.entityType === TSMEntityKinds.Hierarchies ? TSMModalNumbers.Hierarchy : TSMModalNumbers.Instance;
        let nextModalNumber = this.props.entityType === TSMEntityKinds.Types ? TSMModalNumbers.Type_Variable : this.props.entityType === TSMEntityKinds.Hierarchies ? TSMModalNumbers.Hierarchy : TSMModalNumbers.Instance;
        this.setState({subEntityIndex: idx, subEntity: subEntity, modalNumber: idx !== null ? nextModalNumber : returnModalNumber, isAdvancedOptionsExpanded: false});
        if (this.props.progress !== RequestStatus.Publishing) {
            this.props.clearProgress();
        }
    }

    addEntityProperty = (e) => {
        if (Utils.isKeyDownAndNotEnter(e)) {return; }
        if (this.props.entityType === TSMEntityKinds.Types) {
            let newVariable = new Variable(this.state.valueOptions);
            this.setState(update(this.state, {subEntity: {$set: newVariable}, subEntityIndex: {$set: null}, isAdvancedOptionsExpanded: {$set: false}, modalNumber: {$set: TSMModalNumbers.Type_Variable}}));
        } else if (this.props.entityType === TSMEntityKinds.Hierarchies) {
            let newHierarchyLevel = new HierarchyLevel();
            this.setState(update(this.state, {entity: {levels: {$push: [newHierarchyLevel]}}, subEntityIndex: {$set: this.state.entity.levels.length}}));
        } else {
            if (!this.state.entity.instanceFields) {
                this.setState(update(this.state, {entity : {instanceFields: {$set: [new InstanceField()]}}}));
            } else {
                this.setState(update(this.state, {entity : {instanceFields: {$push: [new InstanceField()]}}}));
            }
        }
    }

    removeEntityProperty = (idx) => (e) => {
        if (Utils.isKeyDownAndNotEnter(e)) {return; }
        if (this.props.entityType === TSMEntityKinds.Types) {
            this.setState(update(this.state, {entity: {variables: {$splice: [[idx, 1]]}}}));
        } else if (this.props.entityType === TSMEntityKinds.Hierarchies) {
            this.setState(update(this.state, {entity: {levels: {$splice: [[idx, 1]]}}}));
        } else {
            this.setState(update(this.state, {entity: {instanceFields: {$splice: [[idx, 1]]}}}));
        }
    }

    scrollToBottom = () => {
        if (this.entitiesEnd) {
            this.entitiesEnd.scrollIntoView({ behavior: "smooth", block: "end"});
        }
    }

    showJSON = (e) => {
        if (Utils.isKeyDownAndNotEnter(e)) {return; }
        if (this.props.progress !== RequestStatus.Publishing) {
            this.props.clearProgress();
        }
        let JSONObject = this.state.entity.convertToJSON();
        let w = window.open("", this.props.t('tsm.viewJson'), "width=500,height=400");
        w.document.write(`<pre></pre>`);
        w.document.getElementsByTagName("pre")[0].innerText = JSONObject;
    }

    applySubEntity = (e) => {
        if (Utils.isKeyDownAndNotEnter(e)) {return; }
        let subEntity = this.state.subEntity;
        if (this.props.entityType === TSMEntityKinds.Types) {
            if (this.state.subEntityIndex !== null){
                if (this.state.subEntityCallFromInline){
                    this.setState(update(this.state, {entity: {variables: {[this.state.subEntityIndex]: {$set: subEntity}}}}), () => {this.createOrEditEntity(e.persist())});
                } else {
                    this.setState(update(this.state, {entity: {variables: {[this.state.subEntityIndex]: {$set: subEntity}}}, modalNumber: {$set: TSMModalNumbers.Type}}));
                }
            } else {
                if (this.state.subEntityCallFromInline){ //clicked on add new variable from cards
                    this.setState(update(this.state, {entity: {variables: {[Object.keys(this.props.entityToEdit.variables).length]: {$set: subEntity}}}}), () => {this.createOrEditEntity(e.persist())});
                } else {
                    this.setState(update(this.state, {entity: {variables: {$push: [subEntity]}}, modalNumber: {$set: TSMModalNumbers.Type}}));
                }
            }
        }
    }

    createOrEditEntity = (e) => {
        if (Utils.isKeyDownAndNotEnter(e)) {return; }
        if (this.props.progress !== RequestStatus.Publishing && this.props.progress !== RequestStatus.Finalizing) {  // not to let double publishing if there is one request already sent
            let JSONObjectString;

            if (this.props.entityType === TSMEntityKinds.Types) {
                if (this.props.addType === "single") {
                    JSONObjectString = this.state.entity.convertToJSON();
                } else {
                    JSONObjectString = JSON.stringify(this.state.entity);
                }
                this.props.putType(JSONObjectString, !!this.props.entityToEdit, this.props.addType !== 'single');
            } else if (this.props.entityType === TSMEntityKinds.Hierarchies) {
                if (this.props.addType === "single") {
                    JSONObjectString = this.state.entity.convertToJSON();
                } else {
                    JSONObjectString = JSON.stringify(this.state.entity);
                }
                this.props.putHierarchy(JSONObjectString, !!this.props.entityToEdit, this.props.addType !== 'single');
            } else {
                if (this.props.addType === "single") {
                    JSONObjectString = this.state.entity.convertToJSON();
                } else {
                    let payload =  Object.assign({}, this.state.entity);
                    if (this.state.updateOnly) {
                        let data = payload.put;
                        payload["update"] = data;
                        delete payload.put;
                    }
                    JSONObjectString = JSON.stringify(payload);
                }
                this.props.putInstances(JSONObjectString, 
                                        this.props.addType === "single" ? RequestMethods.Put : (this.state.updateOnly ? RequestMethods.Update : RequestMethods.Put), 
                                        !!this.props.entityToEdit, 
                                        this.props.addType !== 'single', 
                                        this.props.searchText);
            }
        }
    }

    handleFiles = (file) => {
        let reader = new FileReader();
        let fileType = file.name.split('.').pop().toLowerCase();
        (document.getElementById(cx("JSONText")) as HTMLTextAreaElement).value = "";
        (document.getElementById('myFileName') as HTMLSpanElement).innerHTML = file.name;
        if (this.props.progress !== RequestStatus.Publishing) {
            this.props.clearProgress();
        }

        if (fileType === 'json' || fileType === 'txt') {
            reader.onload = (evt: any) => {
                let text = evt.target.result;
                let obj = null;
                try {
                    obj = JSON.parse(text);
                    if (!obj.put) {
                        this.setState(update(this.state, {fileUploadStatus: {$set: FileUploadStatus.Error}, fileUploadMessage: {$set: this.props.t('tsm.fileNotIncludingPutField')}}));
                    } else {
                        this.setState(update(this.state, {fileUploadStatus: {$set: FileUploadStatus.Successful}, fileUploadMessage: {$set: `${this.props.t('validFile')} (${Object.values(obj.put).length} ${this.props.entityType})`}}));
                    }
                } catch (e) {
                    if (e instanceof SyntaxError) {
                        this.setState(update(this.state, {fileUploadStatus: {$set: FileUploadStatus.Error}, fileUploadMessage: {$set: this.props.t('parseFailed')}}));
                    } else {
                        this.setState(update(this.state, {fileUploadStatus: {$set: FileUploadStatus.Successful}, fileUploadMessage: {$set: this.props.t('validFile')}}));
                    }
                }
                if (text) {
                    (document.getElementById(cx('refDataFileUpload')) as HTMLInputElement).value = null;
                    if (text.length > bulkUploadOverflowCharacterLimit) {
                        (document.getElementById(cx("JSONText")) as HTMLTextAreaElement).value = text.substring(0, bulkUploadOverflowCharacterLimit).concat(`\n ... (${this.props.t('continued')})`);
                    } else {
                        (document.getElementById(cx("JSONText")) as HTMLTextAreaElement).value = text;
                    }
                    
                    this.setState(update(this.state, {entity: {$set: obj}}));
                }
            };
            reader.readAsText(file, "UTF-8");
        } else {
            this.setState(update(this.state, {fileUploadStatus: {$set: FileUploadStatus.Error}, fileUploadMessage: {$set: this.props.t('tsm.invalidFileType')}}));
        }
    }

    getTsidLength = () => {
        return this.props.timeSeriesIdProperties.length;
    }
    // end component functions

    render() {
        let modalToShow = null;
        switch(this.state.modalNumber) {
            case TSMModalNumbers.Type: 
                modalToShow = <TypeModal type={this.state.entity} tsmModalComponent={this}/>;
                break;
            case TSMModalNumbers.Hierarchy: 
                modalToShow = <HierarchyModal hierarchy={this.state.entity} tsmModalComponent={this}/>;
                break;
            case TSMModalNumbers.Instance: case TSMModalNumbers.Instance_InstanceFields:
                modalToShow = <InstanceModal instance={this.state.entity} tsmModalComponent={this}/>;
                break;
            case TSMModalNumbers.Type_Variable: 
                modalToShow = <TypeVariableModal variable={this.state.subEntity} idx={this.state.subEntityIndex} tsmModalComponent={this}/>;
                break;
            case TSMModalNumbers.Type_Bulk: case TSMModalNumbers.Hierarchy_Bulk: case TSMModalNumbers.Instance_Bulk:
                modalToShow = <BulkUploadModal tsmModalComponent={this}/>
                break;
            default: 
        }

        return <div className={cx('wrapper')}>
            {modalToShow}
        </div>;
    }
}
import * as React from 'react';
import classNames from 'classnames/bind';
import Placeholder from '../../components/Placeholder/Placeholder';
const cx = classNames.bind(require('./LogViewer.module.scss'));

export default function LogViewer({ t, logLines, message }) {
    let isLoading = logLines === null && !message;
    return <div className={cx('container')}>
        { isLoading && <Placeholder visible={true}>{ t('loading') }</Placeholder> }
        { logLines !== null && !message && <div className={cx('logLines')}>
            { logLines.map((line: any, i: number) => {
                let styling, content;
                if(typeof(line) === 'object') {
                    styling = line.styling;
                    content = line.content;
                } else {
                    styling = null;
                    content = line;
                }

                return <div className={cx('line')} key={`log-line-${i}`}>
                    <div className={cx('lineNumber')}>{i}</div>
                    <div className={cx('lineContent')} style={ styling }>{content}</div>
                </div> }) }
            </div> }
        { message && <div><Placeholder className={cx('logsMessage')} visible={true}>{ message }</Placeholder></div> }
    </div>;
}
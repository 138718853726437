import * as React from 'react';
import classNames from 'classnames/bind';
import Utils from '../../../services/Utils';
import Icon from '../../Icon/Icon';
import ModalContainer from '../../Modal/Modal.container';
import { ClientDataFormat } from '../../../../constants/Enums';
import { migrateTsqToNewTsx } from '../../../services/MigrationService';
const cx = classNames.bind(require('./PbiModal.module.scss'));

interface Props {
    t: any; 
    theme: string; 
    environmentIsLts: boolean; 
    environmentHasWarmStore: boolean; 
    moreActionsElement: any;
    pbiQueryId: string;
    forceCold: boolean;
    searchSpan: any;
    warmStoreRange: any;
    availabilityDistribution: any;
    timeSeriesQueries: any[];
    selectedEnvironment: any;
    isGlobalSearchSpanRelative: boolean;
    handleClosePbiModal: () => void;
};

interface State { 
    isPbiRaw: boolean; 
    isSearchSpanRelative: boolean; 
    isWarmStore: boolean; 
    wasPbiQueryCopied: boolean };

class PbiModal extends React.Component<Props, State> {
    private pbiQueryCopyTimeout: number;

    constructor(props) {
        super(props);

        this.state = {
            isPbiRaw: false,
            isSearchSpanRelative: false,
            isWarmStore: false,
            wasPbiQueryCopied: false
        };
    }

    componentDidMount() {
        this.setState({ 
            isSearchSpanRelative: this.getCanUseWarmStore() || this.props.isGlobalSearchSpanRelative,
            isWarmStore: this.getCanUseWarmStore() 
        });
    }

    componentWillUnmount() {
        if(this.pbiQueryCopyTimeout) {
            window.clearTimeout(this.pbiQueryCopyTimeout);
        }
    }

    handlePbiRadioChange = (isPbiRaw: boolean) => {
        this.setState({ isPbiRaw });
    }

    handleSearchSpanRelativeRadioChange = (isSearchSpanRelative: boolean) => {
        this.setState({ 
            isSearchSpanRelative
        });
    }

    handleUseWarmStoreChange = (isWarmStore: boolean) => {
        this.setState({ isWarmStore });
    }

    getCanUseWarmStore = () => {
        let { environmentIsLts, 
            environmentHasWarmStore, 
            forceCold, 
            searchSpan,
            warmStoreRange,
            availabilityDistribution } = this.props;
        return environmentIsLts
            && environmentHasWarmStore
            && !forceCold
            && Utils.isQueryRangeInWarmStoreRange([searchSpan.from, searchSpan.to], warmStoreRange, availabilityDistribution.retentionPeriod);
    }

    handleCopyPbiQuery =  () => {
        let copyText: HTMLInputElement = document.getElementById(this.props.pbiQueryId) as HTMLInputElement;
        copyText.select();      
        document.execCommand("copy");
        this.setState({ wasPbiQueryCopied: true });
        this.pbiQueryCopyTimeout = window.setTimeout(() => {
            this.setState({ wasPbiQueryCopied: false });
        }, 3000);
        window.getSelection().removeAllRanges();
    }

    getPbiPayload = (queries): any => {
        let config: any = {};

        config.storeType = this.state.isWarmStore ? 'WarmStore' : 'ColdStore';
        config.isSearchSpanRelative = this.state.isSearchSpanRelative;
        config.clientDataType = ClientDataFormat.RDX_20200713_Q;
        config.environmentFqdn = this.props.selectedEnvironment.environmentFqdn;
        config.queries = queries;

        if(this.state.isPbiRaw && Array.isArray(config.queries)) {
            config.queries.forEach(q => {
                if(q.getEvents) {
                    q.getEvents.take = 250000;
                }
            });
        }

        return config;
    }

    restoreOriginalVariableIfCustom = (tsq, query) => {
        // If the original query had avg, min, and max variables, we need to adjust the inlineVariables
        // and projectedVariables properties of the tsq object to restore the original variable.
        if(query.variableObject && 'avg' in query.variableObject && 'min' in query.variableObject && 'max' in query.variableObject) {            
            // guard against the unlikely case of a malformed tsq
            if(tsq && tsq.aggregateSeries) {
                let avgVariable = query.variableObject['avg'];
                
                return {
                    ...tsq,
                    aggregateSeries: {
                        ...tsq.aggregateSeries,
                        inlineVariables: {
                            [query.variableAlias]: avgVariable
                        },
                        projectedVariables: [query.variableAlias]
                    }
                };
            }
        }

        return tsq;
    }

    generatePBIClipboard = (): string => {
        if(!this.props.environmentIsLts) {
            return '';
        }

        if(this.state.isPbiRaw){
            let queries = this.props.timeSeriesQueries.map(query => {
                let restoredQuery = {
                    ...query,
                    searchSpan: this.props.searchSpan
                };
                let migrated = migrateTsqToNewTsx(restoredQuery.toTsq(false, true)); // toTsq(roundFromTo, getEvents)
                return this.restoreOriginalVariableIfCustom(migrated, query);
            }); 
            let payload = this.getPbiPayload(queries);
            let uniqueIds = {};

            payload.queries = payload.queries.filter(q => {
                if(q.getEvents.timeSeriesId in uniqueIds) { 
                    return false;
                } else {
                    uniqueIds[q.getEvents.timeSeriesId] = "";
                    return true;
                }
            })
            return JSON.stringify(payload);
    
        } else {
            let queries = this.props.timeSeriesQueries.map(query => {
                let restoredQuery = {
                    ...query,
                    searchSpan: this.props.searchSpan
                };
                let migrated = migrateTsqToNewTsx(restoredQuery.toTsq()); // toTsq(roundFromTo = false, getEvents = false)
                return this.restoreOriginalVariableIfCustom(migrated, query);
            });
            return JSON.stringify(this.getPbiPayload(queries));
        }
    }

    render() {
        let { 
            t,
            theme,
            moreActionsElement,
            environmentIsLts,
            environmentHasWarmStore,
            pbiQueryId,
            handleClosePbiModal } = this.props;
        let { isWarmStore, isPbiRaw, isSearchSpanRelative, wasPbiQueryCopied } = this.state;

        let customQueryPromptId = Utils.customID('customQueryPrompt');
        let pbiGuid = Utils.guid();
        const getID = (name: string) => {
            return Utils.customID(name, pbiGuid)
        };

        let canUseWarmStore = this.getCanUseWarmStore();
        let isPbiConnectorDisabled = !environmentIsLts;
        let showQueryWillExpireWarning = canUseWarmStore && isWarmStore && !isSearchSpanRelative;
        let isInvalidQuery = !canUseWarmStore && isWarmStore && !isSearchSpanRelative;
        let isQueryOutOfWarmRange = !canUseWarmStore && isWarmStore && isSearchSpanRelative;
        
        let warningMessageKey = null;
        if(showQueryWillExpireWarning) {
            warningMessageKey = 'pbiQueryWillExpire';
        } else if(isQueryOutOfWarmRange || isInvalidQuery) {
            warningMessageKey = 'pbiOutOfWarmRange';
        }
    
        return <ModalContainer 
            sourceElement={moreActionsElement}
            className={cx('pbiModal')} 
            titleClassName={cx(theme === 'dark' ? 'invertIcon' : '')} 
            contentClassName={cx('pbiModalContent')} 
            titleIconId={'pbi'} 
            onClose={() => handleClosePbiModal()} 
            title={t('commanding.pbiModalTitle')} >
            {   isPbiConnectorDisabled && <div className={cx('pbiBonkPh')}>
                    <Icon id={'iconStatusWarning-' + theme} className={cx('icon16')}/>
                    <span>{t('pbiBonk')}</span>
                    <a className={cx('linkLearnMore')} href={'https://docs.microsoft.com/azure/time-series-insights/how-to-connect-power-bi'} target='_blank' rel="noopener noreferrer">{t('learnPbiIntegration')}</a>
                </div> 
            }
            {   !isPbiConnectorDisabled && <>
                    <div className={cx('pbiModalSubtitle')}>
                        {t('commanding.pbiModalSubtitle')}
                        <a className={cx('linkLearnMore')} href={'https://docs.microsoft.com/azure/time-series-insights/how-to-connect-power-bi'} target='_blank' rel="noopener noreferrer">{t('learnPbiIntegration')}</a>
                    </div>
                    <div className={cx('pbiInfo')}>
                        <span className={cx('messageIcon')}><Icon id='blueInfo' className={cx('icon16')} /></span>
                        <span className={cx('messageText')}>{t('pbiUpdateConnector')}</span>
                    </div>
                </>
            }
            <div>
                <div className={cx('pbiModalFormBlock')}>
                    <div className={cx('radioButtonGroup')}>
                        <div className={cx('pbiModalHeading')} id={getID('dataFormatLabel')}>{this.props.t('commanding.dataFormat')}</div>
                        <div className={cx('radioButtonContainer')} role="radiogroup" aria-labelledby={getID('dataFormatLabel')}>     
                            <div className={cx('_base-radioButton')}>
                                <input type="radio" 
                                    name="dataFormatRadioGroup" 
                                    id={getID('dataFormatValueAggregateInput')} 
                                    tabIndex={isPbiRaw ? -1 : 0} 
                                    aria-checked={!isPbiRaw} 
                                    checked={!isPbiRaw} 
                                    onChange={() => this.handlePbiRadioChange(false)} 
                                    aria-labelledby={getID('dataFormatValueAggregateLabel')} />
                                <label tabIndex={-1} htmlFor={getID('dataFormatValueAggregateInput')} id={getID('dataFormatValueAggregateLabel')} className={cx('checkmark')}>{t('pbiAggregate')}</label>
                            </div>
                            <div className={cx('_base-radioButton')}>
                                <input type="radio"
                                    name="dataFormatRadioGroup" 
                                    id={getID('dataFormatValueRawInput')} 
                                    tabIndex={isPbiRaw ? 0 : -1} 
                                    aria-checked={isPbiRaw} 
                                    checked={isPbiRaw} 
                                    onChange={() => this.handlePbiRadioChange(true)} 
                                    aria-labelledby={getID('dataFormatValueRawLabel')} />
                                <label tabIndex={-1} htmlFor={getID('dataFormatValueRawInput')} id={getID('dataFormatValueRawLabel')} className={cx('checkmark')}>{t('pbiRaw')}</label>
                            </div>
                        </div>
                    </div>
                    <div className={cx('radioButtonGroup')}>
                    <div className={cx('pbiModalHeading')} id={getID('searchSpanRelativeLabel')}>{t('commanding.searchSpanRelative')}</div>
                        <div className={cx('radioButtonContainer')} role="radiogroup" aria-labelledby={getID('searchSpanRelativeLabel')}> 
                            <div className={cx('_base-radioButton')}>
                                <input type="radio" 
                                    name="searchSpanRelativeRadioGroup"
                                    id={getID('searchSpanRelativeFalseInput')} 
                                    tabIndex={isSearchSpanRelative ? -1 : 0} 
                                    aria-checked={!isSearchSpanRelative} 
                                    checked={!isSearchSpanRelative} 
                                    onChange={() => this.handleSearchSpanRelativeRadioChange(false)} 
                                    aria-labelledby={getID('searchSpanRelativeFalseLabel')} />
                                <label tabIndex={-1} htmlFor={getID('searchSpanRelativeFalseInput')} id={getID('searchSpanRelativeFalseLabel')} className={cx('checkmark')}>{t('commanding.absolute')}</label>
                            </div>
                            <div className={cx('_base-radioButton')}>
                                <input type="radio" 
                                    name="searchSpanRelativeRadioGroup"
                                    id={getID('searchSpanRelativeTrueInput')} 
                                    tabIndex={isSearchSpanRelative ? 0 : -1} 
                                    aria-checked={isSearchSpanRelative} 
                                    checked={isSearchSpanRelative} 
                                    onChange={() => this.handleSearchSpanRelativeRadioChange(true)} 
                                    aria-labelledby={getID('searchSpanRelativeTrueLabel')} />
                                <label tabIndex={-1} htmlFor={getID('searchSpanRelativeTrueInput')} id={getID('searchSpanRelativeTrueLabel')} className={cx('checkmark')}>{t('commanding.relative')}</label>
                            </div>
                        </div>
                    </div>
                    { environmentHasWarmStore && <div className={cx('radioButtonGroup')}>
                        <div className={cx('pbiModalHeading')} id={getID('storeTypeLabel')}>{t('commanding.storeType')}</div>
                        <div className={cx('radioButtonContainer')} role="radiogroup" aria-labelledby={getID('storeTypeLabel')}>
                            <div className={cx('_base-radioButton')}>
                                <input type="radio" 
                                    name="useWarmStoreRadioGroup"
                                    id={getID('useWarmStoreFalseInput')} 
                                    tabIndex={isWarmStore ? -1 : 0} 
                                    aria-checked={!isWarmStore} 
                                    checked={!isWarmStore} 
                                    onChange={() => this.handleUseWarmStoreChange(false)} 
                                    aria-labelledby={getID('useWarmStoreFalseLabel')} />
                                <label tabIndex={-1} htmlFor={getID('useWarmStoreFalseInput')} id={getID('useWarmStoreFalseLabel')} className={cx('checkmark')}>{t('pbiColdStore')}</label>
                            </div>
                            <div className={cx('_base-radioButton')}>
                                <input type="radio" 
                                    name="useWarmStoreRadioGroup"
                                    id={getID('useWarmStoreTrueInput')} 
                                    tabIndex={isWarmStore ? 0 : -1} 
                                    aria-checked={isWarmStore} 
                                    checked={isWarmStore} 
                                    onChange={() => this.handleUseWarmStoreChange(true)} 
                                    aria-labelledby={getID('useWarmStoreTrueLabel')} />
                                <label tabIndex={-1} htmlFor={getID('useWarmStoreTrueInput')} id={getID('useWarmStoreTrueLabel')} className={cx('checkmark')}>{t('pbiWarmStore')}</label>
                            </div>
                        </div>
                    </div> }
                    { warningMessageKey !== null && <div className={cx('warningMessage')}>
                        <div className={cx('warningIcon')}><Icon id={'iconStatusWarning-' + theme} className={cx('icon16')} /></div>
                        <span>{t(warningMessageKey)}</span>
                      </div> }
                    
                </div>
    
                <div className={cx('pbiModalFormBlock')}>
                    <div id={customQueryPromptId} className={cx('pbiModalHeading')}>{t('commanding.queryParameters')}</div>
                    <textarea className={cx('linkInput', 'linkTextarea')} 
                        id={pbiQueryId} 
                        aria-labelledby={customQueryPromptId}
                        readOnly 
                        value={this.generatePBIClipboard()} />
                </div>
                <button disabled={isPbiConnectorDisabled} className={cx('copyButton')} onClick={() => this.handleCopyPbiQuery()}>
                    {wasPbiQueryCopied ? t('copied') : (t('copyParameters'))}
                </button>
            </div>
            </ModalContainer>
    }
}

export default PbiModal;
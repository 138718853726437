import { combineReducers } from 'redux';
import appReducer from '../../state/reducers/AppReducer';
import analyticsReducer from '../../state/reducers/AnalyticsReducer';
import appSettingsReducer from '../../state/reducers/AppSettingsReducer';
import modelReducer from '../../state/reducers/ModelReducer';
import telemetryReducer from './TelemetryReducer';
import jobsReducer from './JobsReducer';

const rootReducer =  combineReducers({
    app: appReducer,
    analytics: analyticsReducer, 
    appSettings: appSettingsReducer,
    model: modelReducer,
    telemetry: telemetryReducer,
    jobs: jobsReducer
});

export default rootReducer;
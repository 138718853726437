import classNames from 'classnames/bind';
import React, { useEffect, useContext, useState, useCallback, useRef, useMemo } from 'react';
import { defaultReferenceDataState, getSelectedReferenceDataSetName, referenceDataReducer } from '../../reducers/referenceDataReducer';
import { CLOSE_REFERENCE_DATA_MODAL, DELETE_REFERENCE_DATA_SET, GET_REFERENCE_DATA_SETS, GET_REFERENCE_DATA_SET_ITEMS, OPEN_REFERENCE_DATA_MODAL, SET_SELECTED_REFERENCE_DATA_SET_ID } from '../../../constants/ActionTypes';
import { IReferenceDataSet, ReferenceDataSetItem, ReferenceDataSetKeyProperty } from '../../../constants/Interfaces';
import Placeholder from '../Placeholder/Placeholder';
import SortableTableContainer from '../SortableTable/SortableTable.container';
import Icon from '../Icon/Icon';
import { SortableTableColumn, SortOrder } from '../SortableTable/SortableTable';
import { useReducerAndSaga } from '../../hooks/useReducerAndSaga';
import getReferenceDataSaga from '../../sagas/referenceDataSaga';
import Utils from '../../services/Utils';
import ModalContainer from '../Modal/Modal.container';
import { ErrorCodes, ReferenceDataPrimaryKeyTypes, RequestMethods, RequestStatus } from '../../../constants/Enums';
import { clientHeightFactorTresholdForInfiniteScroll, debounceDelayTimeForSearch, maxReferenceDataSetLimit, referenceDataSetItemsPaginationSize } from '../../../constants/Constants';
import TelemetryService from '../../services/TelemetryService';
import { usePrevious } from '../../hooks/usePrevious';
import { ProgressMessageField } from '../ReferenceDataModal/ReferenceDataModal';
import ReferenceDataModalContainer from '../ReferenceDataModal/ReferenceDataModal.container';
import SearchboxContainer from '../Searchbox/Searchbox.container';
const cx = classNames.bind(require('./ReferenceData.module.scss'));

interface Props { 
    t: any; 
    theme: string; 
    selectedEnvironmentResourceId: string;
    environmentFqdn: string;
    isUserContributor: boolean;
}
interface State { 
    isLoadingDataSets: boolean;
    isLoadingDataSetItems: boolean;
    referenceDataSets: Array<IReferenceDataSet>;
    selectedReferenceDataSetId: string;
    referenceDataSetItems: Array<ReferenceDataSetItem>;
    deleteFinished: boolean;
    progress: RequestStatus;
    errorCode: string;
    errorMessage: string;
    isModalVisible: boolean;
    isModalForNewDataSet: boolean;
}
const AssetsContext = React.createContext(null);

export const ReferenceData = (props: Props) => {
    const initialState: State = defaultReferenceDataState; //state variables from reducer
    const progressMessageFieldID = Utils.customID('progressMessageField');
    const tableContainerRef = useRef(null);
    const searchDebounceTimeout = useRef(null);

    const [state, dispatch] = useReducerAndSaga(referenceDataReducer, initialState, getReferenceDataSaga);

    //additional state variables
    const [isConfirmDeleteModalHidden, setIsConfirmDeleteModalHidden] = useState(true);
    const [sortOrder, setSortOrder] = useState(SortOrder.Ascending);
    const [sortedColumnIndex, setSortedColumnIndex] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [debouncingSearchTrigger, setDebouncingSearchTrigger] = useState(false);
    const {t, theme, selectedEnvironmentResourceId, environmentFqdn, isUserContributor} = props;
    const {isLoadingDataSets, isLoadingDataSetItems, referenceDataSets, selectedReferenceDataSetId, referenceDataSetItems, progress, 
        errorCode, errorMessage, isModalVisible, isModalForNewDataSet} = state;

    //custom component functions
    const canUserEdit = isUserContributor && errorCode !== ErrorCodes.InvalidAuthenticationTokenTenant; // even though a user has a 'contributor' role for an environment, user may not have permission to edit the reference data
    const selectedDataSetName = getSelectedReferenceDataSetName(state);
    
    const onDataSetClick = (dataSetId) => {
        dispatch({type: SET_SELECTED_REFERENCE_DATA_SET_ID, payload: {referenceDataSetId: dataSetId}});
    };

    const deleteDataSet = () => {
        if (progress !== RequestStatus.Publishing && progress !== RequestStatus.Finalizing) {  // not to let double publishing if there is one request already sent
            dispatch({type: DELETE_REFERENCE_DATA_SET, payload: {resourceId: selectedEnvironmentResourceId, dataSetName: selectedDataSetName}});
            TelemetryService.logUserAction('deleteReferenceDataSet');
        }
    };

    const setConfirmationModalVisibility = (isHidden) => {
        setIsConfirmDeleteModalHidden(isHidden);
    };

    const openModal = ({isForNew}) => {
        dispatch({type: OPEN_REFERENCE_DATA_MODAL, payload: {isForNew}});
    };

    const closeModal = () => {
        dispatch({type: CLOSE_REFERENCE_DATA_MODAL});
    };

    const getReferenceDataSets = useCallback((dataSetNameToBeSelected = null) => { // needed to keep useCallback here since it is used in useEffect
        dispatch({type: GET_REFERENCE_DATA_SETS, payload: {resourceId: selectedEnvironmentResourceId, dataSetNameToSetAfter: dataSetNameToBeSelected}});
        TelemetryService.logUserAction('getReferenceDataSets');
    }, [dispatch, selectedEnvironmentResourceId]);

    const getReferenceDataSetItems = useCallback(() => { // needed to keep useCallback here since it is used in useEffect
        dispatch({type: GET_REFERENCE_DATA_SET_ITEMS, payload: {referenceDataSetId: selectedReferenceDataSetId, envFqdn: environmentFqdn, dataSetName: selectedDataSetName}});
        TelemetryService.logUserAction('getReferenceDataItems');
        resetSorting();
        resetPagination();
    }, [dispatch, selectedReferenceDataSetId, environmentFqdn, selectedDataSetName]);

    const dateFormatter = Utils.tsiClient.utils.timeFormat(true, true, 0, true, 0, 'YYYY/MM/DD H:mm [UTC]');
    const formatDate = date => date ? dateFormatter(date) : '-';
    const isKeyColumn = (keys, columnName) => keys.map(k => k.name).includes(columnName);
    const keys: Array<ReferenceDataSetKeyProperty> = referenceDataSets?.find((s: IReferenceDataSet) => s.id === selectedReferenceDataSetId)?.properties?.keyProperties || [];
    
    //if there are reference data items get the columns from the schema which is listed in the first row; if there is no item, get the key properties from reference data set definition
    const columns: Array<SortableTableColumn> = referenceDataSetItems?.[0]?.schema?.properties ?
        referenceDataSetItems[0].schema.properties.map(p =>
            ({key: p.name, 
                isDateOrNumber: p.type === ReferenceDataPrimaryKeyTypes.Double || p.type === ReferenceDataPrimaryKeyTypes.DateTime, 
                isSortable: true, 
                iconList: [isKeyColumn(keys, p.name) ? 'key' : '', Utils.referenceDataItemTypeToIconName(p.type)]}
            ))
        : keys.map(k => 
            ({key: k.name, 
                isDateOrNumber: k.type === ReferenceDataPrimaryKeyTypes.Double || k.type === ReferenceDataPrimaryKeyTypes.DateTime, 
                isSortable: true, 
                iconList: ['key', Utils.referenceDataItemTypeToIconName(k.type)]}));
    
    const handleOnClickSort = (columnKey) => {
        const columnIndex = columns.findIndex(column => column.key === columnKey);
    
        let newOrder = sortedColumnIndex === columnIndex && sortOrder === SortOrder.Ascending
          ? SortOrder.Descending
          : SortOrder.Ascending;
        
        setSortOrder(newOrder);
        setSortedColumnIndex(columnIndex);

        if (tableContainerRef.current.scrollTop > 0) {
            tableContainerRef.current.scrollTop = 0;
            resetPagination();
        }
    };

    const totalPageNumber = useMemo(() => {
        if (!referenceDataSetItems) return 0;

        let total = 0;
        if (searchTerm === '') {
            total = Math.ceil(referenceDataSetItems.length/referenceDataSetItemsPaginationSize);
        } else {
            try { 
                let regex = new RegExp(searchTerm, 'gi');
                total = Math.ceil(referenceDataSetItems.filter(row => row.values.filter(column => String(column).match(regex)).length).length / referenceDataSetItemsPaginationSize);
            } catch (e) {}
        }
        return total;
    },[searchTerm, referenceDataSetItems]);
    
    const prevSearchTerm = usePrevious(searchTerm);
    const prevDebouncingSearchTrigger = usePrevious(debouncingSearchTrigger);
    const visibleRows = useMemo(() => {   
        let rows = referenceDataSetItems?.map(r => r.values);
        if (!rows || rows.length === 0) return [];
        
        //filter
        let regex;
        if (prevDebouncingSearchTrigger !== debouncingSearchTrigger) {// execute delayed search
            try { 
                regex = new RegExp(searchTerm, 'gi');
                rows = searchTerm === ''
                    ? rows
                    : rows.filter(row => row.filter(column => String(column).match(regex)).length);
            } catch(e) {rows = []}
        } else {// wait until debouncing by returning old search result
            try {
                regex = new RegExp(prevSearchTerm, 'gi');
                rows = prevSearchTerm === ''
                    ? rows
                    : rows.filter(row => row.filter(column => String(column).match(regex)).length);
            } catch(e) {rows = []}
        }

        //sort
        let sortFunction;
        if(columns[sortedColumnIndex].isDateOrNumber) {
            sortFunction = sortOrder === SortOrder.Ascending
                ? (a, b) => a[sortedColumnIndex] - b[sortedColumnIndex]
                : (a, b) => b[sortedColumnIndex] - a[sortedColumnIndex];
        } else {
            sortFunction = sortOrder === SortOrder.Ascending
                ? (a, b) => String(a[sortedColumnIndex] || '').localeCompare(String(b[sortedColumnIndex] || ''))
                : (a, b) => String(b[sortedColumnIndex] || '').localeCompare(String(a[sortedColumnIndex] || ''));
        }
    
        rows.sort(sortFunction);
        return rows.slice(0, currentPageNumber * referenceDataSetItemsPaginationSize);
    }, [referenceDataSetItems, columns, currentPageNumber, searchTerm, prevSearchTerm, sortOrder, sortedColumnIndex, prevDebouncingSearchTrigger, debouncingSearchTrigger]);

    const delayFiltering = useCallback(() => {
        clearTimeout(searchDebounceTimeout.current);
        searchDebounceTimeout.current = setTimeout(() => {
            setDebouncingSearchTrigger(!debouncingSearchTrigger);
        }, debounceDelayTimeForSearch)
    }, [debouncingSearchTrigger]);
    
    const handleOnChangeSearchTerm = (event) => {
        setSearchTerm(event.target.value);
        delayFiltering();
        resetPagination();
        tableContainerRef.current.scrollTop = 0;
    }

    const handleOnClearSearchTerm = () => {
        setSearchTerm('');
        delayFiltering();
        resetPagination();
        tableContainerRef.current.scrollTop = 0;
    }

    const resetSorting = () => {
        setSortedColumnIndex(0); 
        setSortOrder(SortOrder.Ascending);
        setSearchTerm('');
    }

    const resetPagination = () => {
        setCurrentPageNumber(1);
    }

    const loadMoreItems = () => {
        setCurrentPageNumber(currentPageNumber + 1);
    }

    const handleScroll = () => {
        const tableContainer = tableContainerRef.current;
        if((tableContainer.scrollHeight - tableContainer.scrollTop) <= tableContainer.clientHeight * clientHeightFactorTresholdForInfiniteScroll) {
            if(currentPageNumber < totalPageNumber) {
                loadMoreItems();
            }
        }
    }

    // side-effects
    useEffect(() => {// when environment changes fetch reference data sets
        if (selectedEnvironmentResourceId) {
            getReferenceDataSets();
            resetSorting();
        }
    }, [selectedEnvironmentResourceId, getReferenceDataSets]);

    const prevEnvironmentFqdn = usePrevious(environmentFqdn); // check to make sure environment is same in case of that when environment changes first let the above effect of retrieving reference data sets complete first
    useEffect(() => {// when selected reference data set id changes fetch items of it
        if (canUserEdit && prevEnvironmentFqdn === environmentFqdn && selectedReferenceDataSetId) {
            getReferenceDataSetItems();
        }
    }, [selectedReferenceDataSetId, prevEnvironmentFqdn, environmentFqdn, canUserEdit, getReferenceDataSetItems]);
    

    return <div className={cx('wrapper', `theme-${theme}`)}>
                <Placeholder visible={isLoadingDataSets}>
                    <div>{t('referenceData.loadingSets')}</div>
                </Placeholder>
                <ProgressMessageField props={{// if user dont have permission to edit modify [progress, method, errorCode] props to show the access error
                    cx, 
                    t, 
                    theme, 
                    progress: !canUserEdit ? RequestStatus.Error : progress, 
                    progressMessageFieldID, 
                    method: !canUserEdit ? RequestMethods.Get : RequestMethods.Delete, 
                    errorCode: !canUserEdit ? ErrorCodes.InvalidAuthenticationTokenTenant : errorCode, 
                    errorMessage}}/>
                <AssetsContext.Provider value={{cx, t, theme}}>
                    {canUserEdit && <DataSetsTabs props={{referenceDataSets, selectedReferenceDataSetId, onDataSetClick, openModal}}/>}
                    {(canUserEdit && referenceDataSets?.length > 0) &&
                        <CommandingField props={{
                            rows: referenceDataSetItems, 
                            selectedReferenceDataSetName: referenceDataSets?.find(s => s.id === selectedReferenceDataSetId)?.name,
                            setIsDeleteConfirmationHidden: setConfirmationModalVisibility,
                            openModal: openModal,
                            searchTerm,
                            handleOnChangeSearchTerm,
                            handleOnClearSearchTerm
                        }}/>
                    }
                    <div className={cx('list')}>
                        <InnerPlaceholder props={{t, isLoadingDataSets, referenceDataSets, isLoadingDataSetItems, referenceDataSetItems, openModal, canUserEdit}}/>
                        {(!isLoadingDataSetItems && referenceDataSetItems) &&
                            <>
                                <div className={cx('table-container')} ref={tableContainerRef} onScroll={handleScroll}>
                                    <DataSetRows props={{
                                        columns, 
                                        rows: visibleRows,
                                        handleOnClickSort,
                                        sortOrder,
                                        sortedColumnIndex,
                                        searchTerm,
                                        formatDate
                                    }}/>
                                </div>
                                {currentPageNumber < totalPageNumber &&
                                    <div className={cx('show-more')}>
                                        <button tabIndex={0} onClick={loadMoreItems} aria-label={t('showMore') + t('referenceData.referenceDataSetItems')}>{t('showMore') + "..."}</button>
                                    </div>
                                }  
                            </> 
                        }
                    </div>
                    {!isConfirmDeleteModalHidden && <ConfirmationBox props={{hideFunc: setConfirmationModalVisibility, deleteFunc: deleteDataSet}}/>}
                </AssetsContext.Provider>
                {isModalVisible && 
                    <ReferenceDataModalContainer
                        isForNewReferenceDataSet={isModalForNewDataSet}
                        selectedDataSetName={selectedDataSetName}
                        closeModal={closeModal}
                        onItemsUploadComplete={getReferenceDataSetItems} 
                        onDataSetUploadComplete={(dataSetNameToBeSelected) => getReferenceDataSets(dataSetNameToBeSelected)}/>
                }
            </div>
}

const InnerPlaceholder = ({props}) => {
    const {t, isLoadingDataSets, referenceDataSets, isLoadingDataSetItems, referenceDataSetItems, openModal, canUserEdit} = props; // even though user don't have permission to edit let them see the learn more splash
    if (!isLoadingDataSets) {
        if (!referenceDataSets || referenceDataSets.length === 0) {
            return <Placeholder className={cx('infoSplash')} visible={true}>
                <Icon id={'boxPlaceholder'} className={cx('lbph')}/>
                {canUserEdit ? 
                    <>
                        <span>
                            {t('referenceData.infoText') + ' '}
                            <a target="_blank" rel="noopener noreferrer" href="https://docs.microsoft.com/en-us/rest/api/time-series-insights/gen1-reference-data-api">{t('learnMore')}</a>
                        </span>
                        <button className={cx('_base-primary-button')}
                            onClick={() => openModal({isForNew: true})}
                            aria-label={t('referenceData.addDataSet')}>
                            {t('referenceData.addDataSet')}
                        </button>
                    </>
                    :
                    <span>
                        {t('referenceData.infoText') + ' ' + t('referenceData.infoTextAskPermission') + ' '}
                        <a target="_blank" rel="noopener noreferrer" href="https://docs.microsoft.com/en-us/rest/api/time-series-insights/gen1-reference-data-api">{t('learnMore')}</a>
                    </span>
                }
            </Placeholder>
        } else if (!isLoadingDataSetItems && referenceDataSetItems?.length === 0) {
            return <Placeholder><span aria-label={t('referenceData.noItems')}>{t('referenceData.noItems')}</span></Placeholder>
        } else if (isLoadingDataSetItems) {
            return <Placeholder><span aria-label={t('referenceData.loadingItems')}>{t('referenceData.loadingItems')}</span></Placeholder>
        }
    }
    return null;
}

const DataSetsTabs = ({props}) => {
    const {cx, t, theme} = useContext(AssetsContext);
    const {referenceDataSets, selectedReferenceDataSetId, onDataSetClick, openModal} = props;
    return <div className={cx('tabs', !referenceDataSets?.length ? 'underline' : '')} role="tablist" aria-label={t('referenceData.referenceDataSets')}>
            {referenceDataSets?.map((dS, idx) => 
                <button key={idx} className={cx('tab', dS.id === selectedReferenceDataSetId ? 'selected' : '')} role="tab"
                    aria-selected={dS.id === selectedReferenceDataSetId}
                    onClick={() => onDataSetClick(dS.id)}
                    aria-label={dS.name}>
                    <span title={dS.name}>{dS.name}</span>
                </button>
            )}
            {referenceDataSets?.length < maxReferenceDataSetLimit &&
                <button key={referenceDataSets?.length} className={cx('tab')} role="tab"
                    aria-selected={false}
                    onClick={() => openModal({isForNew: true})}
                    aria-label={t('referenceData.addDataSet')}>
                    <Icon id={'iconAdd-' + theme} className={cx('icon16')}/>
                    <span title={t('referenceData.addDataSet')}>{t('referenceData.addDataSet')}</span>
                </button>
            }
        </div>
};

const DataSetRows = ({props}) => {
    const {columns, rows, handleOnClickSort, sortOrder, sortedColumnIndex, searchTerm, formatDate} = props;

    return <SortableTableContainer
        columns={columns}  
        rows={rows}
        handleOnSort={handleOnClickSort}
        sortOrder={sortOrder}
        sortColumnIndex={sortedColumnIndex}
        canExpandRows={false}
        renderRow={(item, index) => 
            <ItemsTableRow 
                key={`referenceDataSetItemsTableRow-${index}`}
                item={item} 
                searchTerm={searchTerm}
                formatDate={formatDate}/>
        }/>
}

const ItemsTableRow = ({item, searchTerm, formatDate}) => {
    const {cx} = useContext(AssetsContext);
    return <tr tabIndex={0}
        className={cx('row', 'bodyRow')} >
        {item.map((column, idx) => 
            <td key={idx} className={cx('table-cell')}>
                <div className={cx('cell-wrapper')}>
                    <span>
                        {column instanceof Date ? 
                            formatDate(column)
                            : searchTerm ? Utils.getMarkedHtmlBySearch(column.toString(), searchTerm) : column.toString()
                        }
                    </span>
                </div>
            </td>
        )}
    </tr>;
}

const CommandingField = ({props}) => {
    const {cx, t, theme} = useContext(AssetsContext);
    const {rows, selectedReferenceDataSetName, setIsDeleteConfirmationHidden, openModal, searchTerm, handleOnChangeSearchTerm, handleOnClearSearchTerm} = props;

    const download = () => {
        const keys = rows[0]?.schema?.properties.map(p => p.name); // not referring to primary keys, but keys for key-value pairs in JSON
        Utils.downloadJSON(Utils.createJSONArrayFromKeysAndRows(keys, rows), selectedReferenceDataSetName, false);
    }

    const deleteDataSet = () => {
        setIsDeleteConfirmationHidden(false);
    }

    return (
        <div className={cx('commands')}>
            <div className={cx('main-actions')}>
                <button className={cx('action-button')} onClick={() => openModal({isForNew: false})} aria-label={t('tsm.bulkUpload')}>
                    <Icon id={'iconUpload-' + theme} className={cx('icon16')}/>
                    <span>{t('tsm.bulkUpload')}</span>
                </button>
                <button className={cx('action-button')} onClick={download} aria-label={t('referenceData.downloadJSON')}>
                    <Icon id={'download'} className={cx('downloadIcon', 'icon16')}/>
                    <span>{t(`referenceData.downloadJSON`)}</span>
                </button>
                <button className={cx('action-button')} onClick={deleteDataSet} aria-label={t('referenceData.deleteDataSet')}>
                    <Icon id={'iconDelete'} className={cx('icon16', theme)}/>
                    <span>{t(`referenceData.deleteDataSet`)}</span>
                </button>
            </div>
            <div className={cx('search')}>
                <SearchboxContainer
                    placeholder={t('search')}
                    value={searchTerm}
                    handleOnChange={handleOnChangeSearchTerm}
                    handleOnClear={handleOnClearSearchTerm} />
            </div>
        </div>
    );
};

const ConfirmationBox = ({props}) => {
    const {cx, t} = useContext(AssetsContext);
    const {hideFunc, deleteFunc} = props;
    return (
        <ModalContainer hasCloseButton={false} title={t('tsm.confirmDelete')} onClose={() => hideFunc(true, null)}>
            <div className={cx('confirmationModalContent')}>
                <div className={cx('message')}>{t('tsm.confirmDeleteQuestion')}</div>
                <div className={cx('buttonWrapper')}>
                    <button className={cx('modal-button-secondary')} onClick={e => hideFunc(true, e)} aria-label={t('cancel')}>{t('cancel')}</button>
                    <button className={cx('modal-button-primary')} onClick={e => {hideFunc(true, e); deleteFunc(); }} aria-label={t('tsm.delete')}>{t('tsm.delete')}</button>
                </div>
            </div>
        </ModalContainer>
    );
};



import update from 'immutability-helper';
import { NotificationInfo, NotificationType } from '../../models/AppNotificationModels';
import {
    SHOW_NOTIFICATION,
    SHOW_ERROR_NOTIFICATION,
    HIDE_NOTIFICATION,
    DISMISS_NOTIFICATION,
    MARK_NOTIFICATIONS_AS_SEEN,
    OPEN_FEEDBACK_FORM,
    CLOSE_FEEDBACK_FORM,
    CLOSE_AND_CLEAR_FEEDBACK_FORM,
    SET_MIGRATION_MODAL_VISIBILITY,
    MIGRATE_TSM_VARIABLES,
    MIGRATE_TSM_VARIABLES_FULFILLED,
    MIGRATE_SAVED_QUERIES,
    MIGRATE_SINGLE_SAVED_QUERY_FULFILLED,
    SUMMARIZE_SAVED_QUERY_RESULTS,
    SET_ELEMENTS_THAT_NEED_MIGRATION,
    DOWNLOAD_ENTITIES,
    DOWNLOAD_ENTITIES_FULLFILLED
} from '../../../constants/ActionTypes';
import { TSXMigrationStatus, DownloadStatus } from '../../../constants/Enums';
import Utils from '../../services/Utils';
import { TSXMigrationResponseError } from '../../models/Interfaces';

class FeedbackState {
    isFormOpen: boolean;
    issue?: string;
    requestId?: string;
    responseText?: string;

    constructor() {
        this.isFormOpen = false;
        this.issue = '';
        this.requestId = '';
        this.responseText = '';
    }
}

class AppState {
    notifications: Array<NotificationInfo>;
    notificationHistory: Array<NotificationInfo>;
    unseenNotifications: Array<string>;
    feedback: FeedbackState;
    isMigrationModalVisible: boolean;
    variableTSXMigrationStatus: TSXMigrationStatus;
    savedQueryTSXMigrationStatus: TSXMigrationStatus;
    variableTSXMigrationResultErrors: Array<TSXMigrationResponseError>;
    savedQueryTSXMigrationResultErrors: Array<any>;
    typesThatNeedMigration: Array<any>;
    downloadTypesForMigrationStatus: DownloadStatus;
    savedQueriesThatNeedMigration: Array<any>;
    completedSavedQueryMigrationsCount: number;
    failedMigratedSavedQueries: Array<any>;

    constructor() {
        this.notifications = [];
        this.notificationHistory = [];
        this.unseenNotifications = [];
        this.feedback = new FeedbackState();
        this.isMigrationModalVisible = false;
        this.variableTSXMigrationStatus = this.savedQueryTSXMigrationStatus = TSXMigrationStatus.NotStarted;
        this.variableTSXMigrationResultErrors = this.savedQueryTSXMigrationResultErrors = [];
        this.downloadTypesForMigrationStatus = DownloadStatus.NotStarted;
        this.savedQueriesThatNeedMigration = [];
        this.completedSavedQueryMigrationsCount = 0;
        this.failedMigratedSavedQueries = [];
    }
}

function updateNotificationState(state, newNotification) {
    let notifications = [...state.notifications, newNotification];
    let notificationHistory = [...state.notificationHistory, newNotification];
    let unseenNotifications = [...state.unseenNotifications, newNotification.id];

    return update(state, {
        notifications: { $set: notifications },
        notificationHistory: { $set: notificationHistory },
        unseenNotifications: { $set: unseenNotifications }
    });
}

function resetTSXMigrationState(state) {
    return update(state, {
        variableTSXMigrationStatus: {$set: TSXMigrationStatus.NotStarted},
        savedQueryTSXMigrationStatus: {$set: TSXMigrationStatus.NotStarted},
        variableTSXMigrationResultErrorCodes: {$set: []},
        savedQueryTSXMigrationResultErrorCodes: {$set: []}
    });
}

function appReducer(state = new AppState(), action) {
    let notifications: Array<NotificationInfo>,
        notificationHistory: Array<NotificationInfo>,
        notificationId: string,
        notificationIndex: number,
        notificationHistoryIndex: number,
        unseenNotifications: Array<string>,
        newFeedback: FeedbackState;

    let newNotification;
    switch (action.type) {
        case SHOW_NOTIFICATION:
            newNotification = new NotificationInfo(
                action.payload.titleKey,
                action.payload.messageKey,
                action.payload.type,
                null,
                null,
                action.payload.action);
            let newState = updateNotificationState(state, newNotification);
            return resetTSXMigrationState(newState);

        case SHOW_ERROR_NOTIFICATION:
            newNotification = new NotificationInfo(
                action.payload.titleKey,
                action.payload.messageKey,
                NotificationType.Error,
                action.payload.requestId,
                action.payload.responseText,
                null,
                action.payload.bypasseLocaleOptions);

            return updateNotificationState(state, newNotification);

        case HIDE_NOTIFICATION:
            notificationId = action.payload.notificationId;

            notifications = [...state.notifications];
            notificationIndex = notifications.findIndex(n => n.id === notificationId);

            if (notificationIndex !== -1) {
                notifications.splice(notificationIndex, 1);
            } else {
                return state;
            }

            return update(state, {
                notifications: { $set: notifications },
            });

        case DISMISS_NOTIFICATION:
            notificationId = action.payload.notificationId;

            notifications = [...state.notifications];
            notificationIndex = notifications.findIndex(n => n.id === notificationId);
            notificationHistory = [...state.notificationHistory];
            notificationHistoryIndex = notificationHistory.findIndex(n => n.id === notificationId);

            if (notificationIndex === -1 && notificationHistoryIndex === -1) {
                return state;
            }

            if (notificationIndex !== -1) {
                notifications.splice(notificationIndex, 1);
            }

            if (notificationHistoryIndex !== -1) {
                notificationHistory.splice(notificationHistoryIndex, 1);
            }

            return update(state, {
                notifications: { $set: notifications },
                notificationHistory: { $set: notificationHistory }
            });

        case MARK_NOTIFICATIONS_AS_SEEN:
            unseenNotifications = state.unseenNotifications.filter(id => !action.payload.includes(id));

            return update(state, {
                unseenNotifications: { $set: unseenNotifications }
            });

        case OPEN_FEEDBACK_FORM:
            newFeedback = {
                isFormOpen: true,
                issue: (action.payload && action.payload.issue) || null,
                requestId: (action.payload && action.payload.requestId) || null,
                responseText: (action.payload && action.payload.responseText) || null
            };
            return update(state, {
                feedback: { $set: newFeedback } 
            });

        case CLOSE_FEEDBACK_FORM:
            return update(state, {
                feedback: { $set: { isFormOpen: false } }
            });

        case CLOSE_AND_CLEAR_FEEDBACK_FORM:
            return update(state, {
                feedback: { $set: new FeedbackState() }
            });
        
        case SET_MIGRATION_MODAL_VISIBILITY:
            return update(state, {
                isMigrationModalVisible: {$set: action.payload.open},
                variableTSXMigrationStatus: {$set: state.variableTSXMigrationStatus === TSXMigrationStatus.Error ? TSXMigrationStatus.NotStarted : state.variableTSXMigrationStatus},
                variableTSXMigrationResultErrors: {$set: []},
                savedQueryTSXMigrationStatus: {$set: state.savedQueryTSXMigrationStatus === TSXMigrationStatus.Error ? TSXMigrationStatus.NotStarted : state.savedQueryTSXMigrationStatus},
                savedQueryTSXMigrationResultErrors: {$set: []},
                downloadTypesForMigrationStatus: {$set: DownloadStatus.NotStarted}
            });
        
        case MIGRATE_TSM_VARIABLES:
            return update(state, {
                variableTSXMigrationStatus: {$set: TSXMigrationStatus.Migrating},
                variableTSXMigrationResultErrors: {$set: {}}
            });

        case MIGRATE_TSM_VARIABLES_FULFILLED:
            let payload = action.payload;
            let result = payload.result;
            let migrationStatus = payload.migrationStatus;
            let errors = [];
            let error = {} as TSXMigrationResponseError;
            if(migrationStatus === TSXMigrationStatus.Error) {
                if(result.error) {
                    error.code = Utils.getErrorCode(result.error);
                    error.message = Utils.getErrorMessage(result.error);
                    errors.push(error);
                } else if (result.put) {
                    result.put.forEach((typeResult, idx) => {
                        if(typeResult.error) {
                            error.code = Utils.getErrorCode(typeResult.error);
                            error.message = `In type number ${idx+1} - ${Utils.getErrorMessage(typeResult.error)}`;
                            errors.push(error);
                        }
                    });
                    if (errors.length > 0 && errors.length < result.put.length) {
                        migrationStatus = TSXMigrationStatus.PartialSuccess;
                    }
                }
            } else {
                if(payload.types) {
                    payload.types.forEach(t => {
                        if (t.error) {
                            error.code = Utils.getErrorCode(error);
                            error.message = Utils.getErrorMessage(error);
                            errors.push(error);
                        }
                    });
                    if (errors.length > 0 && errors.length < payload.types.length) {
                        migrationStatus = TSXMigrationStatus.PartialSuccess;
                    }
                }
            }
            return update(state, {
                variableTSXMigrationStatus: {$set: migrationStatus},
                variableTSXMigrationResultErrors: {$set: errors}
            });

        case MIGRATE_SAVED_QUERIES:
            return update(state, {
                savedQueryTSXMigrationStatus: {$set: TSXMigrationStatus.Migrating},
                savedQueryTSXMigrationResultErrors: {$set: []}
            });

        case MIGRATE_SINGLE_SAVED_QUERY_FULFILLED:
            let migrationResult = action.payload;
            let failedMigratedSavedQueries = migrationResult.error === null
                ? state.failedMigratedSavedQueries
                : [...state.failedMigratedSavedQueries, migrationResult];

            return update(state, {
                completedSavedQueryMigrationsCount: { $set: state.completedSavedQueryMigrationsCount + 1 },
                failedMigratedSavedQueries: { $set: failedMigratedSavedQueries }
            });
            
        case SUMMARIZE_SAVED_QUERY_RESULTS:
            return update(state, {
                savedQueryTSXMigrationStatus: { $set: action.payload.migrationStatus },
            });

        case SET_ELEMENTS_THAT_NEED_MIGRATION:
            if(!action.payload) {
                return state;
            }

            const variableTSXMigrationStatus = action.payload.types.length > 0
                ? TSXMigrationStatus.NotStarted 
                : TSXMigrationStatus.NotNeeded;
            const savedQueryTSXMigrationStatus = action.payload.savedQueries.length > 0
                ? TSXMigrationStatus.NotStarted 
                : TSXMigrationStatus.NotNeeded;
            const typesThatNeedMigration = action.payload.types || [];
            const savedQueriesThatNeedMigration = action.payload.savedQueries || [];

            return update(state, {
                variableTSXMigrationStatus: { $set: variableTSXMigrationStatus },
                savedQueryTSXMigrationStatus: { $set: savedQueryTSXMigrationStatus },
                typesThatNeedMigration: { $set: typesThatNeedMigration },
                savedQueriesThatNeedMigration: { $set: savedQueriesThatNeedMigration },
                completedSavedQueryMigrationsCount: { $set: 0 }
            });

        case DOWNLOAD_ENTITIES:
            if(action.payload.isForMigration) {
                return update(state, {downloadTypesForMigrationStatus: {$set: DownloadStatus.Downloading}});
            }
            return state;

        case DOWNLOAD_ENTITIES_FULLFILLED:
            if(action.payload.isForMigration) {
                if(action.payload.error) {
                    let error = {code: 'DownloadTypesFailed', message: Utils.getErrorMessage(action.payload.error)};
                    return update(state, {variableTSXMigrationResultErrors: {$set: [error, ...state.variableTSXMigrationResultErrors]}});
                } else {
                    Utils.downloadJSON(action.payload.json, action.payload.fileName);
                    return update(state, {downloadTypesForMigrationStatus: {$set: DownloadStatus.Finished}});
                }
            }
            return state;

        default: return state;
    }
}

export const getApp = state => state.app;
export const getNotifications = state => getApp(state).notifications;
export const getNotificationHistory = state => getApp(state).notificationHistory;
export const getUnseenNotifications = state => getApp(state).unseenNotifications;

export const getFeedback = state => getApp(state).feedback;
export const getIsFormOpen = state => getFeedback(state).isFormOpen;
export const getFeedbackIssue = state => getFeedback(state).issue;
export const getFeedbackRequestId = state => getFeedback(state).requestId;
export const getFeedbackResponseText = state => getFeedback(state).responseText;

export const getMigrationModalVisible = state => getApp(state).isMigrationModalVisible;
export const getVariableTSXMigrationStatus = state => getApp(state).variableTSXMigrationStatus;
export const getSavedQueryTSXMigrationStatus = state => getApp(state).savedQueryTSXMigrationStatus;
export const getVariableTSXMigrationResultErrors = state => getApp(state).variableTSXMigrationResultErrors;
export const getSavedQueryTSXMigrationResultErrors = state => getApp(state).savedQueryTSXMigrationResultErrors;
export const getTypesThatNeedMigration = state => getApp(state).typesThatNeedMigration;
export const getDownloadTypesForMigrationStatus = state => getApp(state).downloadTypesForMigrationStatus;
export const getSavedQueriesThatNeedMigration = state => getApp(state).savedQueriesThatNeedMigration;
export const getCompletedSavedQueryMigrationsCount = state => getApp(state).completedSavedQueryMigrationsCount;
export const getFailedMigratedSavedQueries = state => getApp(state).failedMigratedSavedQueries;

export default appReducer;
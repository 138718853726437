import { ChartTypes } from "../../constants/Enums";
import TimeSeriesQuery from "../models/TimeSeriesQuery";
import AggregatesQuery from "../models/AggregatesQuery";
import { ObjectMapper } from './ObjectMapper';
import Utils from '../services/Utils';
import { StateParserErrorTranslationKeys } from '../../constants/Enums';
import NotificationService from "./NotificationService";
import { autoRefreshCycleMillis_Default, autoRefreshTimeSpanMillis_Default, defaultSwimlaneOptions } from "../../constants/Constants";

export class AnalyticsState {
    public aggregatesQueries: Array<any>;
    public timeSeriesQueries: Array<any>;
    public timeSeriesQueriesFields;
    public searchSpan;
    public isAutoRefreshEnabled: boolean;
    public isSeriesLabelsEnabled: boolean;
    public shouldSticky: boolean;
    public autoRefreshCycleMillis: number;
    public autoRefreshTimeSpanMillis: number;
    public forceCold: boolean;
    public chartType: ChartTypes;
    public markers: Array<any>;
    public swimLaneOptions: any;
    public scatterMeasures: any;
    public linechartStackState: any;
    public isSearchSpanRelative: boolean;
    public selectedHierarchyId: string;
    public isGetSeriesEnabled: boolean; 

    public unzoomStack: Array<any>;
    public isLoadingSavedTsq: boolean;
    public isLoadingAggregates: boolean;
    public previousSessionState;

    constructor() {
        this.aggregatesQueries = [];
        this.timeSeriesQueries = [];
        this.timeSeriesQueriesFields = {};
        this.searchSpan = {};
        this.isAutoRefreshEnabled = false;
        this.shouldSticky = false;
        this.autoRefreshCycleMillis = autoRefreshCycleMillis_Default;
        this.autoRefreshTimeSpanMillis = autoRefreshTimeSpanMillis_Default;
        this.forceCold = false;
        this.chartType = ChartTypes.Linechart;
        this.markers = [];
        this.swimLaneOptions = {...defaultSwimlaneOptions};
        this.isSeriesLabelsEnabled = false;
        this.scatterMeasures = {};
        this.linechartStackState = Utils.getStackStates().Stacked;
        this.isSearchSpanRelative = false;

        // These properties are not set during parsing, but still need default
        // values to form a 'complete' analytics state object.
        this.unzoomStack = [];
        this.isLoadingSavedTsq = false;
        this.isLoadingAggregates = false;
        this.selectedHierarchyId = "";
        this.isGetSeriesEnabled = true;
    }
}

export interface StateParseResult {
    state: AnalyticsState;
    errors: Array<string>;
}

const AnalyticsStateOverrides = {
    isLoadingSavedTsq: true,
    isLoadingAggregates: true,
    previousSessionState: null
};

export class StateParserService {
    static DefaultNumberOfBuckets = 500;

    static parseT7StyleQuery(source: any, currentState: any): StateParseResult {
        let parser = new ObjectMapper(source);

        parser.map({
            sourcePath: 'analytics.isAutoRefreshEnabled',
            isRequired: false,
            calcDefault: () => false,
            validators: [Validate.isBoolean],
            targetPath: 'isAutoRefreshEnabled'
        }); 

        parser.map({
            sourcePath: 'analytics.isSeriesLabelsEnabled',
            isRequired: false,
            calcDefault: () => false,
            validators: [Validate.isBoolean],
            targetPath: 'isSeriesLabelsEnabled'
        });

        parser.map({
            sourcePath: 'analytics.shouldSticky',
            isRequired: false,
            calcDefault: () => false,
            validators: [Validate.isBoolean],
            targetPath: 'shouldSticky'
        }); 

        parser.map({
            sourcePath: 'analytics.autoRefreshCycleMillis',
            isRequired: false,
            calcDefault: () => autoRefreshCycleMillis_Default,
            validators: [Validate.isNumber],
            targetPath: 'autoRefreshCycleMillis'
        });

        parser.map({
            sourcePath: 'analytics.autoRefreshTimeSpanMillis',
            isRequired: false,
            calcDefault: () => autoRefreshTimeSpanMillis_Default,
            validators: [Validate.isNumber],
            targetPath: 'autoRefreshTimeSpanMillis'
        }); 

        const { availabilityDistribution, autoRefreshTimeSpanMillis } = currentState;
        parser.map({
            sourcePath: 'analytics.searchSpan',
            targetPath: 'searchSpan',
            isRequired: true,
            validators: [Validate.isNonNullObject],
            additionalInputs: [{
                sourcePath: 'analytics.isSearchSpanRelative',
                isRequired: false,
                calcDefault: () => false,
                validators: [Validate.isBoolean]
            }],
            valueMapper: (sourceSearchSpan, additionalInputs, target) => {
                let [ isSearchSpanRelative ] = additionalInputs;
                let { isAutoRefreshEnabled } = target;
                let searchSpan = Object.assign({}, sourceSearchSpan);

                if(isSearchSpanRelative) {
                    let searchSpanLengthMillis = new Date(sourceSearchSpan.to).valueOf() - new Date(sourceSearchSpan.from).valueOf();
                    searchSpan.to = new Date(availabilityDistribution.range.to).valueOf();
                    searchSpan.from = searchSpan.to - searchSpanLengthMillis;
                } else {
                    searchSpan.from = new Date(searchSpan.from).valueOf();
                    searchSpan.to = new Date(searchSpan.to).valueOf();
                }

                if(isAutoRefreshEnabled) {
                    let newAvailabilityDistributionRangeTo = new Date(availabilityDistribution.range.to).valueOf();
                    let newAvailabilityDistributionRangeFrom = new Date(availabilityDistribution.range.from).valueOf();
                    let newIntendedFrom = newAvailabilityDistributionRangeTo - autoRefreshTimeSpanMillis;
                    let newFrom = Math.max(newAvailabilityDistributionRangeFrom, newIntendedFrom);
                    
                    searchSpan = {from: newFrom, to: newAvailabilityDistributionRangeTo};
                    let validBucketSizes = Utils.getValidBucketSizes(newFrom, newAvailabilityDistributionRangeTo);
                    searchSpan.validBucketSizes = validBucketSizes;
                    searchSpan.bucketSize = Utils.getNewBucketSize([], validBucketSizes, '');
                }

                return searchSpan;
            }
        });

        parser.map({
            sourcePath: 'analytics.markers',
            isRequired: false,
            calcDefault: () => [],
            validators: [Array.isArray],
            targetPath: 'markers'
        });

        parser.map({
            sourcePath: 'analytics.chartType',
            isRequired: false,
            calcDefault: () => ChartTypes.Linechart,
            validators: [Validate.isChartType],
            targetPath: 'chartType'
        });

        parser.map({
            sourcePath: 'analytics.scatterMeasures',
            calcDefault: () => {return {}},
            isRequired: false,
            targetPath: 'scatterMeasures'
        });

        parser.map({
            sourcePath: 'analytics.linechartStackState',
            calcDefault: () => Utils.getStackStates().Stacked,
            isRequired: false,
            targetPath: 'linechartStackState'
        });

        parser.map({
            sourcePath: 'analytics.swimLaneOptions',
            calcDefault: () => {return {...defaultSwimlaneOptions}},
            isRequired: false,
            targetPath: 'swimLaneOptions'
        })

        parser.mapArray({
            sourcePath: 'analytics.aggregatesQueries',
            isRequired: false,
            calcDefault: () => [],
            targetPath: 'aggregatesQueries',
            elementMapper: AggregatesQuery.fromObject
        });

        parser.mapArray({
            sourcePath: 'analytics.timeSeriesQueries',
            isRequired: false,
            calcDefault: () => [],
            targetPath: 'timeSeriesQueries',
            elementMapper: element => {
                if (element.tsqExpression === undefined) {
                    element.tsqExpression = {};
                }
                element.tsqExpression.instanceObject = element.instanceObject;
                element.tsqExpression.variableObject = element.variableObject;
                let tsq = TimeSeriesQuery.fromObject(element);

                return tsq;
            }
        });

        parser.map({
            sourcePath: 'analytics.timeSeriesQueriesFields',
            targetPath: 'timeSeriesQueriesFields',
            isRequired: false,
            calcDefault: () => { return {}; },
            validators: [Validate.isNonNullObject]
        });

        parser.map({
            sourcePath: 'analytics.forceCold',
            targetPath: 'forceCold',
            isRequired: false,
            calcDefault: () => false,
            validators: [Validate.isBoolean]
        });

        parser.map({
            sourcePath: 'analytics.selectedHierarchyId',
            isRequired: false,
            calcDefault: () => "",
            targetPath: 'selectedHierarchyId'
        });

        parser.map({
            sourcePath: 'analytics.isGetSeriesEnabled',
            isRequired: false,
            calcDefault: () => {return parser.source.analytics.chartType === ChartTypes.Linechart},
            validators: [Validate.isBoolean],
            targetPath: 'isGetSeriesEnabled'
        });

        return StateParserService.createResultsObject(parser, AnalyticsStateOverrides, StateParserErrorTranslationKeys.parsingSavedQueryFailed);
    }

    static parseT6StyleQuery(source: any, currentState: any): StateParseResult {
        let parser = new ObjectMapper(source);

        parser.map({
            sourcePath: 'relativeMillis',
            isRequired: false,
            calcDefault: () => false,
            targetPath: 'isSearchSpanRelative',
            valueMapper: value => value !== null
        });

        const { availabilityDistribution } = currentState;
        parser.map({
            sourcePath: null,
            isRequired: false,
            additionalInputs: [{
                sourcePath: 'from',
                isRequired: false,
                calcDefault: () => null,
                validators: [Validate.isNumber]
            }, {
                sourcePath: 'to',
                isRequired: false,
                calcDefault: () => null,
                validators: [Validate.isNumber]
            }, {
                sourcePath: 'relativeMillis',
                isRequired: false,
                calcDefault: () => null,
                validators: [Validate.isNumber]
            }],
            validators: [],
            targetPath: 'searchSpan',
            valueMapper: (value, additionalInputs) => {
                const [ from, to, relativeMillis ] = additionalInputs;
                let parsedFromMillis = new Date(from).valueOf();
                let parsedToMillis = new Date(to).valueOf();
                let searchSpan = {};

                if (relativeMillis !== null) {
                    searchSpan['to'] = new Date(availabilityDistribution.range.to).valueOf();
                    searchSpan['from'] = new Date((new Date(searchSpan['to'])).valueOf() - relativeMillis).valueOf();
                }
                else {
                    searchSpan['to'] = parsedToMillis;
                    searchSpan['from'] = parsedFromMillis;
                }

                return searchSpan;
            }
        });

        parser.map({
            sourcePath: null,
            isRequired: false,
            additionalInputs: [{
                sourcePath: 'timeBucketSize',
                isRequired: true,
                validators: [Validate.isNumeric]
            }, {
                sourcePath: 'timeBucketUnit',
                isRequired: true,
                validators: [Validate.isNonEmptyString]
            }],
            validators: [],
            targetPath: 'searchSpan.bucketSize',
            valueMapper: (value, additionalInputs) => {
                const [ timeBucketSize, timeBucketUnit ] = additionalInputs;
                return Utils.getBucketSizeFromSizeAndUnit(timeBucketSize, timeBucketUnit);
            }
        })

        parser.mapArray({
            sourcePath: 'timeSeriesDefinitions',
            isRequired: true,
            validators: [Array.isArray],
            targetPath: 'aggregatesQueries',
            elementMapper: (tsd) => {
                let measureArray: any = ['avg', 'min', 'max'];
                let measureProperty: any = { property: tsd.measureName, type: "Double" }

                if(tsd.measureName === 'Event Count'){
                    measureProperty = AggregatesQuery.constructMeasureObjectFromInput('Event Count');
                    measureArray = AggregatesQuery.constructMeasureArrayFromInput('Event Count');
                }
                return new AggregatesQuery(
                    { predicateString: tsd.predicate }, 
                    measureProperty, 
                    measureArray, 
                    null, 
                    tsd.splitBy ? { property: tsd.splitBy, type: 'String' } : null, 
                    ( 'color' in tsd ? tsd.color : Utils.getColor(tsd.name + "_" + tsd.measureName) ), 
                    tsd.name );
            }
        })
        
        return StateParserService.createResultsObject(parser, AnalyticsStateOverrides, StateParserErrorTranslationKeys.parsingSavedQueryFailed);
    } 

    static parseGen1UrlState(source: any, currentState: any) : StateParseResult {        
        let parser = new ObjectMapper(source);

        parser.map({
            sourcePath: 'relativeMillis',
            isRequired: false,
            calcDefault: () => false,
            targetPath: 'isSearchSpanRelative',
            validators: [Validate.isNumeric],
            valueMapper: value => value !== null
        });

        parser.map({
            sourcePath: 'timezoneOffset',
            isRequired: false,
            calcDefault: () => 'Local',
            targetPath: 'timezone',
            validators: [Validate.isNumeric],
            valueMapper: value => {
                let offset = Number(value) / 1000 / 60; // Convert ms to minute offset
                return offset;
            } 
        });

        const { availabilityDistribution } = currentState;
        parser.map({
            sourcePath: null,
            isRequired: false,
            additionalInputs: [{
                sourcePath: 'from',
                isRequired: false,
                calcDefault: () => null,
                validators: [Validate.isNumeric]
            }, {
                sourcePath: 'to',
                isRequired: false,
                calcDefault: () => null,
                validators: [Validate.isNumeric]
            }, {
                sourcePath: 'relativeMillis',
                isRequired: false,
                calcDefault: () => null,
            }],
            validators: [],
            targetPath: 'searchSpan',
            valueMapper: (_, additionalInputs) => {
                let [ from, to, relativeMillis ] = additionalInputs;
                let parsedFromMillis = new Date(Number(from)).valueOf();
                let parsedToMillis = new Date(Number(to)).valueOf();
                let searchSpan = {};

                if(relativeMillis === null && (from === null || to === null)){ // Default to last 60 minutes if no time parameters given
                    relativeMillis = 3600000;
                }

                if (relativeMillis !== null && typeof(+relativeMillis) === 'number') {
                    searchSpan['to'] = new Date(availabilityDistribution.range.to).valueOf();
                    searchSpan['from'] = new Date((new Date(searchSpan['to'])).valueOf() - (+relativeMillis)).valueOf();
                }
                else {
                    searchSpan['to'] = parsedToMillis;
                    searchSpan['from'] = parsedFromMillis;
                }

                return searchSpan;
            }
        });

        parser.map({
            sourcePath: null,
            isRequired: false,
            additionalInputs: [{
                sourcePath: 'timeBucketSize',
                isRequired: false,
                calcDefault: () => null,
                validators: [Validate.isNumeric]
            }, {
                sourcePath: 'timeBucketUnit',
                isRequired: false,
                calcDefault: () => null,
                validators: [Validate.isNonEmptyString]
            }],
            validators: [],
            targetPath: 'searchSpan.bucketSize',
            valueMapper: (_, additionalInputs) => {
                let [ timeBucketSize, timeBucketUnit ] = additionalInputs;
                if(!timeBucketSize) timeBucketSize = 1;
                if(!timeBucketUnit) timeBucketUnit = 'minute';
                return Utils.getBucketSizeFromSizeAndUnit(timeBucketSize, timeBucketUnit);
            }
        })

        parser.mapArray({
            sourcePath: 'timeSeriesDefinitions',
            isRequired: true,
            validators: [Array.isArray],
            targetPath: 'aggregatesQueries',
            elementMapper: (tsd) => {
                let measureArray: any = ['avg', 'min', 'max'];
                let measureProperty: any = { property: tsd.measureName, type: "Double" }

                if(tsd.measureName === 'Event Count'){
                    measureProperty =  AggregatesQuery.constructMeasureObjectFromInput('Event Count');
                    measureArray = AggregatesQuery.constructMeasureArrayFromInput('Event Count');
                }

                return new AggregatesQuery(
                    { predicateString: tsd.predicate }, 
                    measureProperty, 
                    measureArray, 
                    null, 
                    tsd.splitBy ? { property: tsd.splitBy, type: 'String' } : null, 
                    ( 'color' in tsd ? tsd.color : Utils.getColor(tsd.name + "_" + tsd.measureName) ), 
                    tsd.name );
            }
        })

        parser.map({
            sourcePath: 'multiChartStack',
            isRequired: false,
            calcDefault: () => true,
            targetPath: 'linechartStackState',
            validators: [Validate.isNonEmptyString],
            valueMapper: value => {
                return value === 'true' ? Utils.getStackStates().Stacked : Utils.getStackStates().Overlap
            }
        });

        parser.map({
            sourcePath: null,
            isRequired: false,
            additionalInputs: [{
                sourcePath: 'multiChartStack',
                isRequired: false,
                calcDefault: () => true,
                validators: [Validate.isNonEmptyString]
            }, {
                sourcePath: 'multiChartSameScale',
                isRequired: false,
                calcDefault: () => false,
                validators: [Validate.isNonEmptyString]
            }],
            targetPath: 'linechartStackState',
            valueMapper: (_, additionalInputs) => {
                let [ multiChartStack, multiChartSameScale ] = additionalInputs;
                if(multiChartStack === 'false' && multiChartSameScale === 'true'){
                    return Utils.getStackStates().Shared;
                } else if(multiChartStack === 'false'){
                    return Utils.getStackStates().Overlap;
                } else{
                    return Utils.getStackStates().Stacked;
                }
            }
        });

        return StateParserService.createResultsObject(parser, AnalyticsStateOverrides, StateParserErrorTranslationKeys.parsingUrlFailed);
    }

    static parseUrlState(source: any, currentState: any): StateParseResult {
        let parser = new ObjectMapper(source);

        parser.map({
            sourcePath: 'relativeMillis',
            isRequired: false,
            calcDefault: () => false,
            targetPath: 'isSearchSpanRelative',
            validators: [Validate.isNumber],
            valueMapper: value => value !== null
        });

        const { availabilityDistribution } = currentState;
        parser.map({
            sourcePath: null,
            isRequired: false,
            additionalInputs: [{
                sourcePath: 'searchSpan.from',
                isRequired: false,
                calcDefault: () => null,
                validators: [Validate.isNonEmptyString]
            }, {
                sourcePath: 'searchSpan.to',
                isRequired: false,
                calcDefault: () => null,
                validators: [Validate.isNonEmptyString]
            }, {
                sourcePath: 'relativeMillis',
                isRequired: false,
                calcDefault: () => null,
            }],
            validators: [],
            targetPath: 'searchSpan',
            valueMapper: (_, additionalInputs) => {
                let [ from, to, relativeMillis ] = additionalInputs;
                let searchSpan = {};

                if(relativeMillis === null && (from === null || to === null)){ // Default to last 60 minutes if no time parameters given
                    relativeMillis = 3600000;
                }
                
                if (relativeMillis !== null && typeof(relativeMillis) === 'number') {
                    searchSpan['to'] = new Date(availabilityDistribution.range.to);
                    searchSpan['from'] = new Date((new Date(searchSpan['to'])).valueOf() - relativeMillis);
                }
                else {
                    searchSpan['to'] = new Date(to);
                    searchSpan['from'] = new Date(from);
                }

                return searchSpan;
            }
        });

        parser.map({
            sourcePath: 'searchSpan.bucketSize',
            isRequired: false,
            calcDefault: target => {
                const { from, to } = target.searchSpan;
                return Utils.getDimensionAndIntegerForRangeAndBuckets(from.valueOf(), to.valueOf(), StateParserService.DefaultNumberOfBuckets)
            },
            validators: [Validate.isNonEmptyString],
            targetPath: 'searchSpan.bucketSize'
        });

        parser.map({
            sourcePath: 'chartType',
            isRequired: false,
            calcDefault: () => ChartTypes.Linechart,
            validators: [Validate.isChartType],
            targetPath: 'chartType'
        });

        parser.mapArray({
            sourcePath: 'timeSeries',
            targetPath: 'timeSeriesQueries',
            isRequired: true,
            validators: [Validate.isNonEmptyArray],
            additionalInputs: [
                {
                    sourcePath: 'variableName',
                    isRequired: true,
                    validators: [Validate.isNonEmptyString]
                },
                {
                    sourcePath: 'id',
                    isRequired: true,
                    validators: [Validate.isNonEmptyArray]
                },
                {
                    sourcePath: 'swimLane',
                    isRequired: false,
                    validators: [Validate.isNumber],
                    calcDefault: () => null // Real default value is based on index of the array element, which we can't access here.
                },
                {
                    sourcePath: 'interpolationFunction',
                    isRequired: false,
                    validators: [Validate.isString],
                    calcDefault: () => ''
                },
                {
                    sourcePath: 'startAt',
                    isRequired: false,
                    validators: [Validate.isNonEmptyString],
                    calcDefault: () => null
                }
            ],
            elementMapper: (series: any, additionalInputs: any, target: Record<string, any>, index: number) => {
                const { from, to, bucketSize } = target.searchSpan;
                let [ variableName, id, swimLane, interpolationFunction, startAt ] = additionalInputs;

                // Check if input is invalid or out of bounds. If swim lane number is not specified, add each tsq
                // to a separate lane, wrapping around if there are more than the max number of swim lanes.
                if(swimLane === null) {
                    swimLane = (index % Utils.wellSwimLanes.length) + 1; // swim lane values are base 1
                } 
                else if(!Number.isInteger(swimLane) || swimLane < 1 || swimLane > Utils.wellSwimLanes.length) {
                    swimLane = 1;
                } 

                let dataOptions: any = {
                    swimLane, 
                    interpolationFunction
                }

                if(!isNaN(Date.parse(startAt))){
                   dataOptions.startAt = startAt;
                }

                let tsq = new TimeSeriesQuery({ timeSeriesId: id }, series.variables, { from, to, bucketSize }, Utils.getColor(id[0] + "_" + variableName), id[0], dataOptions);
                tsq.additionalFields['Variable'] = variableName;

                return tsq;
            }
        });

        return StateParserService.createResultsObject(parser, AnalyticsStateOverrides, StateParserErrorTranslationKeys.parsingUrlFailed);
    }

    static serializeT7AnalyticsState(state: any): StateParseResult {
        let parser = new ObjectMapper(state);

        parser.mapArray({
            sourcePath: 'analytics.aggregatesQueries',
            isRequired: true,
            targetPath: 'aggregatesQueries',
            elementMapper: AggregatesQuery.toObject
        });

        parser.mapArray({
            sourcePath: 'analytics.timeSeriesQueries',
            isRequired: true,
            targetPath: 'timeSeriesQueries',
            elementMapper: TimeSeriesQuery.toObject
        });

        parser.map({
            sourcePath: 'analytics.timeSeriesQueriesFields',
            isRequired: true,
            targetPath: 'timeSeriesQueriesFields'
        });

        parser.map({
            sourcePath: 'analytics.searchSpan',
            isRequired: true,
            targetPath: 'searchSpan'
        });

        parser.map({
            sourcePath: 'analytics.isAutoRefreshEnabled',
            isRequired: true,
            targetPath: 'isAutoRefreshEnabled'
        });

        parser.map({
            sourcePath: 'analytics.isSeriesLabelsEnabled',
            isRequired: true,
            targetPath: 'isSeriesLabelsEnabled'
        });

        parser.map({
            sourcePath: 'analytics.shouldSticky',
            isRequired: false,
            targetPath: 'shouldSticky',
            calcDefault: () => false
        });

        parser.map({
            sourcePath: 'analytics.autoRefreshCycleMillis',
            isRequired: false,
            calcDefault: () => autoRefreshCycleMillis_Default,
            targetPath: 'autoRefreshCycleMillis'
        });

        parser.map({
            sourcePath: 'analytics.autoRefreshTimeSpanMillis',
            isRequired: false,
            calcDefault: () => autoRefreshTimeSpanMillis_Default,
            targetPath: 'autoRefreshTimeSpanMillis'
        }); 

        parser.map({
            sourcePath: 'analytics.forceCold',
            isRequired: true,
            targetPath: 'forceCold'
        });

        parser.map({
            sourcePath: 'analytics.isSearchSpanRelative',
            isRequired: true,
            targetPath: 'isSearchSpanRelative'
        });

        parser.map({
            sourcePath: 'analytics.chartType',
            isRequired: true,
            targetPath: 'chartType'
        });

        parser.map({
            sourcePath: 'analytics.scatterMeasures',
            isRequired: false,
            targetPath: 'scatterMeasures',
            calcDefault: () => {return {}}
        });

        parser.map({
            sourcePath: 'analytics.linechartStackState',
            isRequired: false,
            targetPath: 'linechartStackState',
            calcDefault: () => Utils.getStackStates().Stacked
        });

        parser.map({
            sourcePath: 'analytics.swimLaneOptions',
            calcDefault: () => {return {...defaultSwimlaneOptions}},
            isRequired: false,
            targetPath: 'swimLaneOptions'
        });

        parser.map({
            sourcePath: 'analytics.markers',
            isRequired: true,
            targetPath: 'markers'
        });

        parser.map({
            sourcePath: 'analytics.selectedHierarchyId',
            isRequired: true,
            targetPath: 'selectedHierarchyId'
        });

        parser.map({
            sourcePath: 'analytics.isGetSeriesEnabled',
            isRequired: true,
            targetPath: 'isGetSeriesEnabled'
        });

        parser.map({
            sourcePath: 'analytics.queryName',
            isRequired: true,
            targetPath: 'queryName'
        });

        return StateParserService.createResultsObject(parser, {}, StateParserErrorTranslationKeys.serializingStateFailed);
    }

    private static createResultsObject(parser: ObjectMapper, overrides: any = {}, errorTitleKey: string = null): StateParseResult {
        const { target, errors } = parser;

        // Notify user of parsing errors
        if(errors.length > 0){
            setTimeout(() => { // Wrap in setTimeout to avoid dispatching action in reducer error
                errors.forEach(err => {
                    NotificationService.showErrorNotification(errorTitleKey, err.message, null, {isTitleBypassed: false, isMessageBypassed: true})    
                })
            })
        }

        return {
            state: errors.length === 0 
                ? Object.assign(new AnalyticsState(), target, overrides)
                : new AnalyticsState(),
            errors: errors
        };
    }
}

const Validate = {
    isNonEmptyString: function(value: any) {
        return typeof(value) === "string" && value.length > 0;
    },
    isNonEmptyArray: function(value: any) {
        return Array.isArray(value) && value.length > 0;
    },
    isChartType: function(value: any) {
        return Object.values(ChartTypes).indexOf(value) !== -1;
    },
    isBoolean: function(value: any) {
        return typeof value === "boolean";
    },
    isString: function(value: any) {
        return typeof value === "string";
    },
    isNonNullObject: function(value: any) {
        return value !== null && typeof value === "object";
    },
    isNumber: function(value: any) {
        return !Number.isNaN(value) && typeof value === "number";
    },
    isNumeric: function(value: any){ // Also checks if strings can be parsed as a number
        return !isNaN(parseFloat(value)) && isFinite(value);
    }
};

import * as React from 'react';
import classNames from 'classnames/bind';
import Utils from '../../services/Utils';
import Placeholder from '../Placeholder/Placeholder';
import { KeyCodes, RawEventsTakeValues } from '../../../constants/Enums';
import Icon from '../Icon/Icon';
const cx = classNames.bind(require('./EventsTable.module.scss'));

interface Props {t: any; events: any; theme: string; clearEvents: any; isLoading: boolean; error: any; timezone: any; 
    loadingEventsProgress: number; getEvents: any; environmentIsLts: boolean; exploreEventsPayload: any; isMoreRawEventsAvailable: boolean;
    isRawEventsSetIncomplete: boolean; timeSeriesIdProperties: any;}
interface State { }

class EventsTable extends React.Component<Props, State> {

    private eventsTable;
    private titleRef;
    private closeRef;
    private eventsTableId = Utils.guid();

    constructor(props: Props) {
        super(props);
        this.titleRef = React.createRef();
        this.closeRef = React.createRef();
    }

    componentDidMount () {
        this.eventsTable = new Utils.tsiClient.ux.EventsTable(document.getElementById(this.eventsTableId));
        this.focusTitle();
    }

    private focusTitle = () => {
        this.titleRef && this.titleRef.current && this.titleRef.current.focus();
    }

    private focusClose = () => {
        this.closeRef && this.closeRef.current && this.closeRef.current.focus();
    }

    private titleKeyHandler = (event) => {
        if (event.keyCode === KeyCodes.Tab && event.shiftKey === true){ //shift tab
            event.preventDefault();
            this.focusClose();
        }
    }

    private closeKeyHandler = (event) => {
        if (event.keyCode === KeyCodes.Tab && event.shiftKey === false){ //tab
            event.preventDefault();
            this.focusTitle();
        }
    }

    private getEventsLabelAndActions = () => {
        return <div className={cx('labelAndActions')}>
                    {(!this.props.isLoading && this.props.events) &&
                        <span className={cx('eventCount')}> {`${this.props.events.length} ${this.props.t('events')}`} </span>
                    }
                    {(this.props.environmentIsLts && this.props.events && !this.props.isLoading && this.props.isMoreRawEventsAvailable) && 
                        <button onClick={() => this.props.getEvents(this.props.exploreEventsPayload, RawEventsTakeValues.Max)} className={cx('_base-tertiary-button')}>{this.props.t('loadAllEvents')}</button>
                    }
                </div>
    }

    render() {
        let noData = this.props.events && this.props.events.length === 0;
        if (this.props.events != null && !this.props.isLoading) {
            setTimeout(() => this.eventsTable.renderFromEventsTsx(this.props.events, {theme: this.props.theme, offset: this.props.timezone, timeSeriesIdProperties: this.props.timeSeriesIdProperties}));
        }
        if (this.eventsTable && (this.props.events === null || this.props.isLoading)) {
            document.getElementById(this.eventsTableId).innerHTML = '';
            this.eventsTable = new Utils.tsiClient.ux.EventsTable(document.getElementById(this.eventsTableId));
        }
        return (
        <div className={cx('theme-' + this.props.theme, 'card', {half: this.props.events != null, incomplete: this.props.isRawEventsSetIncomplete && !this.props.isLoading})}>
            <div tabIndex={0} ref={this.titleRef} onKeyDown={this.titleKeyHandler} className={cx('_base-title')}>Events
                {this.getEventsLabelAndActions()}
                {(this.props.isRawEventsSetIncomplete && !this.props.isLoading) &&
                    <div className={cx('incompleteEvents')}>
                        <Icon id={`iconStatusWarning-${this.props.theme}`} className={cx('icon14')}></Icon>
                        <span>{this.props.t('incompleteEvents')}</span>
                    </div>
                }
            </div>
            <div className={cx('eventsTableWrapper')}>
                <div id={this.eventsTableId} className={cx('eventsTable', {hidden: noData})}></div>
                <button onClick={this.props.clearEvents} onKeyDown={this.closeKeyHandler} className={cx('_base-primary-button')} ref={this.closeRef}>{this.props.t('close')}</button>
            </div>
            <Placeholder visible={this.props.isLoading} progress={this.props.loadingEventsProgress}><div>{this.props.t('loadingValues')}</div></Placeholder>
            <Placeholder className={cx('noEvents')} visible={noData && !this.props.isLoading && !this.props.error}><div>{this.props.t('noEvents')}</div></Placeholder>
            <Placeholder className={cx('noEvents')} visible={this.props.error}><div>{this.props.error}</div></Placeholder>
        </div>
        );

    }

}

export default EventsTable;
export const GET_QUERYRESULTS = 'GET_QUERYRESULTS';
export const GET_QUERYRESULTS_FULFILLED = 'GET_QUERYRESULTS_FULFILLED';
export const GET_EVENTS = 'GET_EVENTS';
export const GET_EVENTS_ERROR = 'GET_EVENTS_ERROR';
export const CLEAR_EVENTS = 'CLEAR_EVENTS';
export const GET_EVENTS_FULFILLED = 'GET_EVENTS_FULFILLED';
export const GET_ENVIRONMENTS = 'GET_ENVIRONMENTS';
export const GET_ENVIRONMENTS_FULFILLED = 'GET_ENVIRONMENTS_FULFILLED';
export const GET_AVAILABILITY = 'GET_AVAILABILITY';
export const GET_AVAILABILITY_FULFILLED = 'GET_AVAILABILITY_FULFILLED';
export const GET_MODEL = 'GET_MODEL';
export const GET_MODEL_FULFILLED = 'GET_MODEL_FULFILLED';
export const GET_MODEL_ERROR = 'GET_MODEL_ERROR';
export const SET_THEME = 'SET_THEME';
export const SET_SEARCHSPAN = 'SET_SEARCH_SPAN';
export const ADD_AGGREGATESQUERY = 'ADD_AGGREGATESQUERY';
export const PUT_TYPE = 'PUT_TYPE';
export const PUT_TYPE_FULFILLED = 'PUT_TYPE_FULFILLED';
export const PUT_HIERARCHY = 'PUT_HIERARCHY';
export const PUT_HIERARCHY_FULFILLED = 'PUT_HIERARCHY_FULFILLED';
export const PUT_INSTANCES = 'PUT_INSTANCES';
export const PUT_INSTANCES_FINALIZING = 'PUT_INSTANCES_FINALIZING';
export const PUT_INSTANCES_FULFILLED = 'PUT_INSTANCES_FULFILLED';
export const REMOVE_AGGREGATESQUERY = 'REMOVE_AGGREGATESQUERY';
export const TOGGLE_COMPACT_AVAILABILITY = 'TOGGLE_COMPACT_AVAILABILITY';
export const TOGGLE_ENVIRONMENTS = 'TOGGLE_ENVIRONMENTS';
export const SHOW_HIDE_TIMESERIESQUERY = 'SHOW_HIDE_TIMESERIESQUERY';
export const ZOOM = 'ZOOM';
export const GET_SHORTLINK = 'GET_SHORTLINK';
export const GET_SHORTLINK_FULFILLED = 'GET_SHORTLINK_FULFILLED';
export const GET_METADATA = 'GET_METADATA';
export const GET_METADATA_FULFILLED = 'GET_METADATA_FULFILLED';
export const SELECT_ENTITY_TYPE = 'SELECT_ENTITY_TYPE';
export const SELECT_ENTITY = 'SELECT_ENTITY';
export const SELECT_SUB_ENTITY = 'SELECT_SUB_ENTITY';
export const EDIT_SUBENTITY = 'EDIT_SUBENTITY';
export const ADD_SUBENTITY = 'ADD_SUBENTITY';
export const ADD_ENTITY = 'ADD_ENTITY';
export const EDIT_ENTITY = 'EDIT_ENTITY';
export const CLOSE_TSM_MODAL = 'CLOSE_TSM_MODAL';
export const SELECT_ENVIRONMENT = 'SELECT_ENVIRONMENT';
export const SET_TIMEZONE = 'SET_TIMEZONE';
export const CLEAR_PROGRESS = 'CLEAR_PROGRESS';
export const ADD_TIMESERIESQUERY = 'ADD_TIMESERIESQUERY';
export const REMOVE_TIMESERIESQUERY = 'REMOVE_TIMESERIESQUERY';
export const REMOVE_ALL_TIMESERIESQUERIES = 'REMOVE_ALL_TIMESERIESQUERIES';
export const SET_MAINTAIN_INTERVAL = 'SET_MAINTAIN_INTERVAL';
export const BULK_UPLOAD = 'BULK_UPLOAD';
export const SEARCH_INSTANCES = 'SEARCH_INSTANCES';
export const SEARCH_INSTANCES_FULFILLED = 'SEARCH_INSTANCES_FULFILLED';
export const GET_MODEL_TYPES = 'GET_MODEL_TYPES';
export const GET_MODEL_TYPES_FULFILLED = 'GET_MODEL_TYPES_FULFILLED';
export const GET_MODEL_HIERARCHIES = 'GET_MODEL_HIERARCHIES';
export const GET_MODEL_HIERARCHIES_FULFILLED = 'GET_MODEL_HIERARCHIES_FULFILLED';
export const SELECT_HIERARCHY = 'SELECT_HIERARCHY';
export const GET_ARTIFACTS = 'GET_ARTIFACTS';
export const GET_ARTIFACTS_FULFILLED = 'GET_ARTIFACTS_FULFILLED';
export const SET_ENVIRONMENT_FROM_SAVED_QUERY = 'SET_ENVIRONMENT_FROM_SAVED_QUERY';
export const BULK_PARSE_ANALYTICS_STATE = 'BULK_PARSE_ANALYTICS_STATE';
export const BULK_PARSE_APP_SETTINGS_STATE = 'BULK_PARSE_APP_SETTINGS_STATE';
export const FILL_STATE_FROM_SAVED_QUERY = 'FILL_STATE_FROM_SAVED_QUERY';
export const FILL_STATE_FROM_SHORTLINK = 'FILL_STATE_FROM_SHORTLINK'; 
export const FILL_STATE_FROM_URL = 'FILL_STATE_FROM_URL';
export const GET_PAGELOAD_STATE = 'GET_PAGELOAD_STATE';
export const GET_TENANTS = 'GET_TENANTS';
export const GET_TENANTS_FULFILLED = 'GET_TENANTS_FULFILLED';
export const SET_TENANT_ID = 'SET_TENANT_ID';
export const GET_TENANT_NAME = 'GET_TENANT_NAME';
export const GET_TENANT_NAME_FULFILLED = 'GET_TENANT_NAME_FULFILLED';
export const APPLY_SEARCH_SPAN = 'APPLY_SEARCH_SPAN';
export const CLEAR_SHORTLINK = 'CLEAR_SHORTLINK';
export const SAVE_QUERY = 'SAVE_QUERY';
export const SAVE_QUERY_FULFILLED = 'SAVE_QUERY_FULFILLED';
export const DELETE_QUERY = 'DELETE_QUERY';
export const SET_SAVED_QUERY_NAME = 'SET_SAVED_QUERY_NAME';
export const CLEAR_SAVED_QUERY_NAME = 'CLEAR_SAVED_QUERY_NAME';
export const SET_ADDITIONAL_FIELD_VISIBILITY = 'SET_ADDITIONAL_FIELD_VISIBILITY';
export const SET_ADDITIONAL_FIELD_AS_ONLY_VISIBLE = 'SET_ADDITIONAL_FIELD_AS_ONLY_VISIBLE';
export const SET_ALL_ADDITIONAL_FIELD_VISIBILITY = 'SET_ALL_ADDITIONAL_FIELD_VISIBILITY';
export const CLEAR_DELETE_QUERY = 'CLEAR_DELETE_QUERY';
export const DELETE_QUERY_FULFILLED = 'DELETE_QUERY_FULFILLED';
export const DELETE_QUERY_FAILED = 'DELETE_QUERY_FAILED';
export const SET_TSQ_SORT = 'SET_TSQ_SORT';
export const SET_AUTO_REFRESH = 'SET_AUTO_REFRESH';
export const SET_CHART_TYPE = 'SET_CHART_TYPE';
export const GET_INSTANCES_FULFILLED = 'GET_INSTANCES_FULFILLED';
export const SET_MARKERS = 'SET_MARKERS';
export const RESET_SWIMLANE_OPTIONS = 'RESET_SWIMLANE_OPTIONS';
export const ADD_SWIMLANE_HORIZONTAL_MARKER = 'ADD_SWIMLANE_HORIZONTAL_MARKER';
export const UPDATE_SWIMLANE_HORIZONTAL_MARKER = 'UPDATE_SWIMLANE_HORIZONTAL_MARKER';
export const REMOVE_SWIMLANE_HORIZONTAL_MARKER = 'REMOVE_SWIMLANE_HORIZONTAL_MARKER';
export const SERIALIZE_STATE_TO_LOCAL_STORAGE = 'SERIALIZE_STATE_TO_LOCAL_STORAGE';
export const HIDE_ALL_TIMESERIESQUERIES = 'HIDE_ALL_TIMESERIESQUERIES';
export const STICKY_TIMESERIESQUERY = 'STICKY_TIMESERIESQUERY'; 
export const UNSTICKY_TIMESERIESQUERIES = 'UNSTICKY_TIMESERIESQUERIES';
export const SET_LOADING_AGGREGATES_PROGRESS = 'SET_LOADING_AGGREGATES_PROGRESS';
export const SET_FOCUS_AND_STICKY_TRIGGERS = 'SET_FOCUS_AND_STICKY_TRIGGERS';
export const SET_SCATTER_MEASURES = 'SET_SCATTER_MEASURES';
export const SET_LOADING_EVENTS_PROGRESS = 'SET_LOADING_EVENTS_PROGRESS';
export const SET_MODAL_OPTIONS = 'SET_MODAL_OPTIONS';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const SET_ACTIVE_VISUALIZATION_REFERENCE = 'SET_ACTIVE_VISUALIZATION_REFERENCE';
export const SET_LINECHART_STACK_STATE = 'SET_LINECHART_STACK_STATE';
export const REGISTER_GQR_CANCEL_HANDLER = 'REGISTER_GQR_CANCEL_HANDLER';
export const SET_QUERY_FIELD = 'SET_QUERY_FIELD';
export const RESTORE_PREVIOUS_SESSION = 'RESTORE_PREVIOUS_SESSION';
export const REFRESH_PREVIOUS_SESSION_STATE = 'REFRESH_PREVIOUS_SESSION_STATE';
export const CLEAR_PREVIOUS_SESSION_STATE = 'CLEAR_PREVIOUS_SESSION_STATE';
export const SET_TSM_ENTITY_SORT = 'SET_TSM_ENTITY_SORT';
export const SET_TSM_SUBENTITY_SORT = 'SET_TSM_SUBENTITY_SORT';
export const STOP_AVAILABILITY_LONG_POLL = 'STOP_AVAILABILITY_LONG_POLL';
export const START_AVAILABILITY_LONG_POLL = 'START_AVAILABILITY_LONG_POLL';
export const SET_ACTIVE_PAGE = 'SET_ACTIVE_PAGE';
export const DOWNLOAD_ENTITIES = 'DOWNLOAD_ENTITIES';
export const DOWNLOAD_ENTITIES_FULLFILLED = 'DOWNLOAD_ENTITIES_FULLFILLED';
export const TOGGLE_FORCE_COLD = 'TOGGLE_FORCE_COLD';
export const SET_ISSAVEOPENSHAREVISIBLE = 'SET_ISSAVEOPENSHAREVISIBLE';
export const SET_SAVED_QUERY_FILTER = 'SET_SAVED_QUERY_FILTER';
export const GET_ALL_JOBS = 'GET_ALL_JOBS';
export const GET_ALL_JOBS_FULFILLED = 'GET_ALL_JOBS_FULFILLED';
export const GET_JOB_LOGS = 'GET_JOB_LOGS';
export const GET_JOB_LOGS_FULFILLED = 'GET_JOB_LOGS_FULFILLED';
export const CREATE_JOB = 'CREATE_JOB';
export const CREATE_JOB_FULFILLED = 'CREATE_JOB_FULFILLED';
export const MODIFY_JOB = 'MODIFY_JOB';
export const MODIFY_JOB_FULFILLED = 'MODIFY_JOB_FULFILLED';
export const UPDATE_SORT_ORDER = 'UPDATE_SORT_ORDER';
export const GET_BRUSHED_REGION_STATISTICS = 'GET_BRUSHED_REGION_STATISTICS';
export const HIDE_BRUSHED_REGION_STATISTICS = 'HIDE_BRUSHED_REGION_STATISTICS';
export const GET_BRUSHED_REGION_STATISTICS_FULFILLED = 'GET_BRUSHED_REGION_STATISTICS_FULFILLED';
export const SET_FOCUS_ON_ADD_MARKER_BUTTON_TRIGGER = 'SET_FOCUS_ON_ADD_MARKER_BUTTON_TRIGGER';
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const SHOW_ERROR_NOTIFICATION = 'SHOW_ERROR_NOTIFICATION';
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';
export const DISMISS_NOTIFICATION = 'DISMISS_NOTIFICATION';
export const MARK_NOTIFICATIONS_AS_SEEN = 'MARK_NOTIFICATIONS_AS_SEEN';
export const SET_TSID_FOR_LOOKUP = "SET_TSID_FOR_LOOKUP";
export const OPEN_FEEDBACK_FORM = 'SHOW_FEEDBACK_FORM';
export const CLOSE_FEEDBACK_FORM = 'CLOSE_FEEDBACK_FORM';
export const CLOSE_AND_CLEAR_FEEDBACK_FORM = 'CLOSE_AND_CLEAR_FEEDBACK_FORM';
export const TOGGLE_OPEN_APP_SETTINGS = 'TOGGLE_OPEN_APP_SETTINGS';
export const SWAP_IN_TSQ = 'SWAP_IN_TSQ';
export const DUPLICATE_TIMESERIESQUERY = 'DUPLICATE_TIMESERIESQUERY';
export const MIGRATE_TSM_VARIABLES = 'MIGRATE_TSM_VARIABLES';
export const MIGRATE_TSM_VARIABLES_FULFILLED = 'MIGRATE_TSM_VARIABLES_FULFILLED';
export const MIGRATE_SAVED_QUERIES = 'MIGRATE_SAVED_QUERIES';
export const MIGRATE_SAVED_QUERIES_FULFILLED = 'MIGRATE_SAVED_QUERIES_FULFILLED';
export const SET_MIGRATION_MODAL_VISIBILITY = 'SET_MIGRATION_MODAL_VISIBILITY';
export const CHECK_MIGRATION_IS_NECESSARY = 'CHECK_MIGRATION_IS_NECESSARY';
export const MIGRATE_SINGLE_SAVED_QUERY_FULFILLED = 'MIGRATE_SINGLE_SAVED_QUERY_FULFILLED';
export const CHECK_IF_SAVED_QUERIES_MIGRATION_IS_NECESSARY = 'CHECK_IF_SAVED_QUERIES_MIGRATION_IS_NECESSARY';
export const SET_ELEMENTS_THAT_NEED_MIGRATION = 'SET_ELEMENTS_THAT_NEED_MIGRATION';
export const SET_BULK_UPLOAD_INSTANCES_PROGRESS = 'SET_BULK_UPLOAD_INSTANCES_PROGRESS';
export const GET_MODEL_SETTINGS = 'GET_MODEL_SETTINGS';
export const GET_MODEL_SETTINGS_FULFILLED = 'GET_MODEL_SETTINGS_FULFILLED';
export const SUMMARIZE_SAVED_QUERY_RESULTS = 'SUMMARIZE_SAVED_QUERY_RESULTS';
export const SET_AGGREGATESQUERY = 'SET_AGGREGATESQUERY';
export const SET_VISIBLE_TSQ_FIELDS_IN_LOCAL_STORAGE = 'SET_VISIBLE_TSQ_FIELDS_IN_LOCAL_STORAGE';
export const SET_CHART_SETTINGS_STATE = 'SET_CHART_SETTINGS_STATE';
export const UPDATE_JUMBOTRON_STATE = 'UPDATE_JUMBOTRON_STATE';
export const SET_IS_GET_SERIES_ENABLED = "SET_IS_GET_SERIES_ENABLED";
export const SET_SHOULD_STICKY = "SET_SHOULD_STICKY";
export const SET_AUTO_REFRESH_TIME_SPAN_MILLIS = "SET_AUTO_REFRESH_TIME_SPAN_MILLIS";
export const SET_AUTO_REFRESH_CYCLE_MILLIS = "SET_AUTO_REFRESH_CYCLE_MILLIS";
export const SET_SWIMLANE_FIELD = "SET_SWIMLANE_FIELD";
export const SET_SELECTED_REFERENCE_DATA_SET_ID = 'SET_SELECTED_REFERENCE_DATA_SET_ID';
export const GET_REFERENCE_DATA_SETS = 'GET_REFERENCE_DATA_SETS';
export const GET_REFERENCE_DATA_SETS_FULFILLED = 'GET_REFERENCE_DATA_SETS_FULFILLED';
export const GET_REFERENCE_DATA_SET_ITEMS = 'GET_REFERENCE_DATA_SET_ITEMS';
export const GET_REFERENCE_DATA_SET_ITEMS_FULFILLED = 'GET_REFERENCE_DATA_SET_ITEMS_FULFILLED';
export const OPEN_REFERENCE_DATA_MODAL = 'OPEN_REFERENCE_DATA_MODAL';
export const CLOSE_REFERENCE_DATA_MODAL = 'CLOSE_REFERENCE_DATA_MODAL';
export const PUT_REFERENCE_DATA_SET_ITEMS = 'PUT_REFERENCE_DATA_SET_ITEMS';
export const PUT_REFERENCE_DATA_SET_ITEMS_FULFILLED = 'PUT_REFERENCE_DATA_SET_ITEMS_FULFILLED';
export const DELETE_REFERENCE_DATA_SET = 'DELETE_REFERENCE_DATA_SET';
export const DELETE_REFERENCE_DATA_SET_FULFILLED = 'DELETE_REFERENCE_DATA_SET_FULFILLED';
export const PUT_REFERENCE_DATA_SET = 'PUT_REFERENCE_DATA_SET';
export const PUT_REFERENCE_DATA_SET_FULFILLED = 'PUT_REFERENCE_DATA_SET_FULFILLED';
export const UPDATE_BULK_UPLOAD_REFERENCE_DATA_ITEMS_PROGRESS = 'UPDATE_BULK_UPLOAD_REFERENCE_DATA_ITEMS_PROGRESS';
export const SET_BULK_UPLOAD_REFERENCE_DATA_ITEMS_PROGRESS = 'SET_BULK_UPLOAD_REFERENCE_DATA_ITEMS_PROGRESS';
export const SET_SERIES_LABELS_ENABLED = "SET_SERIES_LABELS_ENABLED";

export enum ChartTypes {
    Linechart = 'linechart', 
    Heatmap = 'heatmap',
    Scatterplot = 'scatterplot'
}

export enum ChartDataTypes {
    Categorical = 'categorical',
    Numeric = 'numeric'
}

export enum AdvancedExplorations {
    Timeshift = 'timeshift',
    Scatterplot = 'scatterplot'
}

export enum ModalTypes {
    Settings = 'settings',
    ScatterPlot = 'scatterplot',
    EditColumns = 'editColumns'
}

export enum ChartSettingsTypes {
    Basics = 'basics',
    Swimlanes = 'swimlanes',
    Series = 'series',
    AutoRefresh = 'autoRefresh'
}

export enum CaretDirections {
    Left = "left",
    Right = "right",
    Top = "top",
    TopLeft = "topLeft",
    Bottom = "bottom",
    UpperLeft = "upperLeft"
}

export enum QueryTypes {
    TimeSeries = "tsq",
    Aggregates = "aq"
}

export enum GetAvailabilityReason{
    PageLoadOrEnvironmentChange = 'pageLoadOrEnvironmentChange',
    AutoRefresh = 'autoRefresh',
    ManualRefresh = 'manualRefresh',
    LongPoll = 'longPoll',
    OpenSavedQueryOrShortlink = 'openSavedQueryOrShortlink',
    LoadStateFromUrl = 'loadStateFromUrl'
}

export enum RequestStatus {
    Idle,
    Publishing, 
    Successful, 
    Unsuccessful, 
    Error, 
    Partial_Success,
    Finalizing
}

export enum MetadataPropertyTypes {Double = "Double", String = "String", DateTime = "DateTime", Long = "Long"}

export enum TSMCategoricalValueKind {Integer = "integer", String = "string"}

export enum TSMEntityKinds {Instances = "instances", Hierarchies = "hierarchies", Types = "types"}

export enum RequestMethods {Get = "get", Put = "put", Search = "search", Delete = "delete", Update = "update"}

export enum TSMModalNumbers {Type, Hierarchy, Instance, Type_Variable, Instance_InstanceFields, Type_Bulk, Hierarchy_Bulk, Instance_Bulk}

export enum AppPages {Analytics = "Analytics", Model = "Model", Jobs = "Jobs"}

export enum LocalStorageKeys {
    NewExplorerSplashSeen = "tsiHasSeenNewExplorerSplash", 
    TsmSplashSeen = "tsiHasSeenTsmSplash",
    SelectedHierarchyId = 'tsiSelectedHierarchyID',
    VisibleTSQFieldsInWell = 'tsiVisibleTSQFieldsInWell',
    SeriesLabelsVisible = 'tsiShowSeriesLabels'
}

export enum ClientDataFormat {
    RDX_20181120_Q = "RDX_20181120_Q",
    RDX_20160513 = "RDX_20160513",
    RDX_20160513_Q = "RDX_20160513_Q",
    RDX_20160513_P = "RDX_20160513_P",
    RDX_20200713_Q = "RDX_20200713_Q",
    Gen2SkuUrl = "GEN_2_SKU_URL",
    Gen1SkuUrl = "GEN_1_SKU_URL"
}

export enum KeyCodes {
    Tab = 9,
    Esc = 27,
    Enter = 13
}

export enum TSXMigrationStatus {
    NotStarted,
    Migrating,
    Successful,
    Error,
    NotNeeded,
    PartialSuccess
}

export enum DownloadStatus {
    NotStarted,
    Downloading,
    Finished
}

export enum RawEventsTakeValues {
    Min = 1000,
    Max = 250000
}

export enum SeriesSettingsTabs {
    basic = 'basic',
    advanced = 'advanced'
}

export enum YAxisState {
    auto = 'auto',
    manual = 'manual',
    min = 'min',
    max = 'max'
}

export enum XAxisShiftState {
    none = 'none',
    offset = 'offset',
    startAt = 'startAt'
}

export enum TimeUnit {
    milliseconds = 'milliseconds', 
    seconds = 'seconds', 
    minutes = 'minutes', 
    hours = 'hours', 
    days = 'days'
}

export enum CollapseMode {
    expand = 'expand',
    collapse = 'collapse',
    toggle = 'toggle'
}

export enum CollapsableComponents {
    tagExp = 'tag_explorer',
    well = 'well'
}

export enum AnalyticsCommandModals{
    openModal = 'openModal',
    saveModal = 'saveModal',
    shareModal = 'shareModal'
}

export enum InterpolationFunctions {
    none = '',
    curveLinear = "curveLinear",
    curveStep = "curveStep",
    curveStepBefore = "curveStepBefore",
    curveStepAfter = "curveStepAfter",
    curveBasis = "curveBasis",
    curveCardinal = "curveCardinal",
    curveMonotoneX = "curveMonotoneX",
    curveCatmullRom = "curveCatmullRom"
}

export enum CurveStepInterpolationFunctions{
    curveStep = 'curveStep',
    curveStepBefore = 'curveStepBefore',
    curveStepAfter = 'curveStepAfter'
}

export enum StateParserErrorTranslationKeys{
    validationFailed = 'stateParser.validationFailed',
    parsingUrlFailed = 'stateParser.parsingUrlFailed',
    parsingSavedQueryFailed = 'stateParser.parsingSavedQueryFailed',
    serializingStateFailed = 'stateParser.serializingStateFailed',
    sourcePathFailedValidator = 'stateParser.sourcePathFailedValidator',
    valueFailedValidator = 'stateParser.valueFailedValidator',
    jsonParseFailed = 'stateParser.jsonParseFailed',
    uriDecodeFailed = 'stateParser.uriDecodeFailed'
}

export enum ReferenceDataErrors {
    NameEmpty = 'nameEmpty',
    NameNotValid = 'nameNotValid',
    NameExists = 'nameExists',
    PrimaryKeys = 'primaryKeys'
}

export enum DataStringComparisonBehavior {
    Ordinal = 'Ordinal',
    OrdinalIgnoreCase = 'OrdinalIgnoreCase'
}

export enum ReferenceDataPrimaryKeyTypes {
    String = 'String',
    Double = 'Double',
    Bool = 'Bool',
    DateTime = 'DateTime'
}

export enum ErrorCodes {
    InvalidInput = 'InvalidInput',
    PartialSuccess = 'PartialSuccess',
    InvalidAuthenticationTokenTenant = 'InvalidAuthenticationTokenTenant'
}

export enum FileUploadStatus {
    Successful = 'successful',
    Error = 'error'
}

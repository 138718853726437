import { translate } from "react-i18next"
import { connect } from "react-redux"
import { OPEN_FEEDBACK_FORM, SAVE_QUERY, SET_SAVED_QUERY_NAME } from "../../../../constants/ActionTypes";
import { getQueryName, getSavedQueriesArray, getSaveQueryMessage, getIsSaveQueryMessageError } from "../../../state/reducers/AnalyticsReducer";
import { getTheme } from "../../../state/reducers/AppSettingsReducer";
import Save from "./Save"

const mapStateToProps = state => {
    return {
        theme: getTheme(state),
        queryName: getQueryName(state),
        queries: getSavedQueriesArray(state),
        saveQueryMessage: getSaveQueryMessage(state),
        isSaveQueryMessageError: getIsSaveQueryMessageError(state),
    }
}

const mapDispatchToProps = dispatch => ({
    saveQuery: (name, sharingScope) => {
        dispatch({type: SAVE_QUERY, payload: {name: name, sharingScope: sharingScope}});
        dispatch({type: SET_SAVED_QUERY_NAME, payload: name});
    },
    openFeedbackForm: (issue: string, requestId: string, responseText: string): void => dispatch({ 
        type: OPEN_FEEDBACK_FORM,
        payload: { issue, requestId, responseText }
    })
})

export default translate()(connect(mapStateToProps, mapDispatchToProps)(Save))
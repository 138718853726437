import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { getTheme } from '../../../state/reducers/AppSettingsReducer';
import BasicSettings from './BasicSettings';
import { getValidBucketSizes, getSearchSpan, getLinechartStackState, getIsGetSeriesEnabled, getChartType, getTimeSeriesQueries, getShouldSticky, getAggregatesQueries, getValueElementStickyTrigger, getIsSeriesLabelsEnabled, getJumbotronState } from '../../../state/reducers/AnalyticsReducer';
import { SET_MAINTAIN_INTERVAL, SET_SEARCHSPAN, GET_QUERYRESULTS, SET_LINECHART_STACK_STATE, SET_IS_GET_SERIES_ENABLED, SET_SHOULD_STICKY, UNSTICKY_TIMESERIESQUERIES, SET_SERIES_LABELS_ENABLED } from '../../../../constants/ActionTypes';
import TimeSeriesQuery from '../../../models/TimeSeriesQuery';
import AggregatesQuery from '../../../models/AggregatesQuery';
import { CollapsableComponents, QueryTypes } from '../../../../constants/Enums';

const mapStateToProps = state => {
    const timeSeriesQueries: Array<TimeSeriesQuery> = getTimeSeriesQueries(state); 
    const aggregateQueries: Array<AggregatesQuery> = getAggregatesQueries(state);
    const queries: any = timeSeriesQueries.length ? timeSeriesQueries : aggregateQueries;
    return { 
        theme: getTheme(state),
        validBucketSizes: getValidBucketSizes(state),
        searchSpan: getSearchSpan(state),
        linechartStackState: getLinechartStackState(state),
        isGetSeriesEnabled: getIsGetSeriesEnabled(state),
        chartType: getChartType(state),
        queries: queries,
        queriesType: timeSeriesQueries?.length ? QueryTypes.TimeSeries : QueryTypes.Aggregates,
        shouldSticky: getShouldSticky(state),
        stickyTrigger: getValueElementStickyTrigger(state),
        isSeriesLabelsEnabled: getIsSeriesLabelsEnabled(state),
        isWellCollapsed: getJumbotronState(state)[CollapsableComponents.well]
    };
};

const mapDispatchToProps = dispatch => ({ 
    setMaintainedIntervalSize: (intervalSize = null) => dispatch({type: SET_MAINTAIN_INTERVAL, payload: {maintainedIntervalSize: intervalSize}}),
    setSearchSpan: (from, to, bucketSize, validBucketSizes) => dispatch({type: SET_SEARCHSPAN, payload: {from, to, bucketSize, validBucketSizes}}),
    getQueryResults: () => dispatch({type: GET_QUERYRESULTS, payload: {unsetUnzoomStack: true}}),
    setStackState: (newState) => {dispatch({type: SET_LINECHART_STACK_STATE, payload: newState})},
    setIsGetSeriesEnabled: (payload: boolean) => {
        dispatch({type: SET_IS_GET_SERIES_ENABLED, payload});
        dispatch({type: GET_QUERYRESULTS});
    },
    setShouldSticky: (shouldSticky: boolean) => {
        if (!shouldSticky) {
            dispatch({type: UNSTICKY_TIMESERIESQUERIES});
        }
        dispatch({type: SET_SHOULD_STICKY, payload: shouldSticky});
    },
    setSeriesLabelsEnabled: (seriesLabelsEnabled: boolean) => {
        dispatch({type: SET_SERIES_LABELS_ENABLED, payload: seriesLabelsEnabled});
    }
});




const BasicSettingsContainer = translate()(connect(mapStateToProps, mapDispatchToProps)(BasicSettings));

export default BasicSettingsContainer;

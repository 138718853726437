import * as React from 'react';
import classNames from 'classnames/bind';
import Placeholder from '../Placeholder/Placeholder';
import ChartCommandingContainer from "../ChartCommanding/ChartCommanding.container"
const cx = classNames.bind(require('./ChartableComponent.module.scss'));

interface Props {t: any; tall: boolean; theme: string, queries: any, isLoadingAggregates:boolean, 
                 chartId: string; className?: string; modalOptions: any;}
interface State {isChartFocusVisible: boolean;}

class ChartableComponent extends React.Component<Props, State> {

    render() {
        let queries = this.props.queries.filter(ts => ts && ts.data);
        let chartData = queries.map(ts => ts.data);
        return (
        <div className={cx('theme-' + this.props.theme, 'card', 'short') + ' ' + this.props.className}>
            <ChartCommandingContainer hide={chartData.length === 0} modalOpen={this.props.modalOptions.modalType}/>
            <div tabIndex={0} aria-label={this.props.t('aChart')} role={'img'} className={cx('chartWrapper', {hidden: (chartData.length === 0)})}>
                <div tabIndex={-1} id={this.props.chartId} className={cx('chart')}>
                </div>
            </div>
    
            {(!this.props.isLoadingAggregates && this.props.queries.filter(ts => !ts.hidden && ts.data && !ts.noData()).length === 0 && this.props.queries.length) && <Placeholder className={cx('opaqueBg')} visible={true}><div>{this.props.t('noEvents')}</div></Placeholder>}
            {this.props.children}
        </div>
        );
    }

}

export default ChartableComponent;

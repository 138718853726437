import * as React from 'react';
import classNames from 'classnames/bind';
import TagExplorerContainer from '../../components/TagExplorer/TagExplorer.container';
import AnalyticsWorkspaceContainer from '../../components/AnalyticsWorkspace/AnalyticsWorkspace.container';
import AvailabilityChartContainer from '../../components/AvailabilityChart/AvailabilityChart.container';
import Placeholder from '../../components/Placeholder/Placeholder';
import AdHocQueryBuilderContainer from '../../components/AdHocQueryBuilder/AdHocQueryBuilder.container';
import TelemetryService from '../../services/TelemetryService';
import BaseQuery from '../../models/BaseQuery';
import EnvironmentsContainer from '../../components/Environments/Environments.container';
import NewExplorerSplashContainer from '../../components/NewFeatureSplash/Implementations/NewExplorerSplash/NewExplorerSplash.container';
import { LocalStorageKeys } from '../../../constants/Enums';
import Utils from '../../services/Utils';
import AnalyticsCommandBarContainer from '../../components/AnalyticsCommandBar/AnalyticsCommandBar.container';
const cx = classNames.bind(require('./Analytics.module.scss'));

interface Props { environmentName: string; environmentIsLts: boolean; theme: string; t: any; isModelLoading: any; tallBottom: boolean; 
                  getShortlink: any; isModelEmpty: boolean; isLoadingAggregates: boolean; getModel: any; queries: Array<BaseQuery>; openUserSettings: any;
                  isInitialPageLoad: boolean }
interface State { isResizableMaskEnabled: boolean; showNewExplorerSplash: boolean; }

class Analytics extends React.Component<Props, State> {
  private splashTimeout;

  constructor(props: Props) {
    super(props);
    this.state = {isResizableMaskEnabled: false, showNewExplorerSplash: false};
  }

  componentDidMount() {
    this.splashTimeout = setTimeout(() => this.setState({showNewExplorerSplash: !localStorage.getItem(LocalStorageKeys.NewExplorerSplashSeen)}), 3000);
  }

  componentWillUnmount() {
    clearTimeout(this.splashTimeout);
  }

  hideNewExplorerSplash = () => {
      localStorage.setItem(LocalStorageKeys.NewExplorerSplashSeen, 'true');
      this.setState({showNewExplorerSplash: false});
  }

  showMask = (e) => {
    this.setState({isResizableMaskEnabled: true});
    TelemetryService.logUserAction('tagExplorerResizeStart');
  }

  hideMask = (e) => {
    this.setState({isResizableMaskEnabled: false});
    TelemetryService.logUserAction('tagExplorerResizeStop');
  }

  render() {
    return <div className={cx('analyticsPage')}>
            <AnalyticsCommandBarContainer />
            <EnvironmentsContainer openUserSettings={this.props.openUserSettings} />
            <AvailabilityChartContainer />
            <div className={cx('bottom', {tall: this.props.tallBottom})}>
                <Placeholder className={cx('tagStatus')} visible={(this.props.isModelLoading || (this.props.environmentIsLts && !this.props.isModelLoading && this.props.isModelEmpty)) && !this.props.isLoadingAggregates}>
                    <div>
                    {(this.props.isModelLoading || !this.props.isModelEmpty) ? this.props.t('loadingModel') : this.props.t('noModel')}
                    {((this.props.queries.length > 0) && !this.props.isModelLoading && this.props.isModelEmpty) && <button onClick={this.props.getModel} className={cx('_base-primary-button')}>{this.props.t('reloadModel')}</button>}
                    </div>
                </Placeholder>
              {(!this.props.isModelLoading && !this.props.isModelEmpty) && 
                this.props.environmentIsLts ? <TagExplorerContainer onResizeStart={this.showMask} onResizeStop={this.hideMask} /> : <AdHocQueryBuilderContainer />
              }
              <AnalyticsWorkspaceContainer />
              <Placeholder className={cx('resizable-mask')} visible={this.state.isResizableMaskEnabled}></Placeholder>
              {(this.state.showNewExplorerSplash && !Utils.isDemo && this.props.environmentName) && <NewExplorerSplashContainer hideNewExplorerSplash={this.hideNewExplorerSplash} />}
            </div>
    </div>;
  }
}

export default Analytics;

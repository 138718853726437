import { store } from '../../index';
import { NotificationType, BypassLocaleOptions } from '../models/AppNotificationModels';
import { 
  SHOW_NOTIFICATION, 
  SHOW_ERROR_NOTIFICATION, 
  HIDE_NOTIFICATION,
  DISMISS_NOTIFICATION,
  MARK_NOTIFICATIONS_AS_SEEN, 
  SET_MIGRATION_MODAL_VISIBILITY} from '../../constants/ActionTypes';

export default class NotificationService {

  public static hasSeenApiFallbackNotification = false;

  public static showNotification(titleKey: string, messageKey: string, type = NotificationType.Info) {
    store.dispatch({ 
      type: SHOW_NOTIFICATION,
      payload: { titleKey, messageKey, type }
    })
  }

  public static showErrorNotification(titleKey: string, messageKey: string, xhr: XMLHttpRequest, bypasseLocaleOptions: BypassLocaleOptions = null) {
    store.dispatch({ 
      type: SHOW_ERROR_NOTIFICATION, 
      payload: { 
        titleKey, 
        messageKey, 
        requestId: (xhr && xhr.getResponseHeader) ? xhr.getResponseHeader('x-ms-request-id') : null,
        responseText: xhr ? xhr.response : null,
        bypasseLocaleOptions
      }
    });
  }

  public static showApiDeprecationNotification() {
    store.dispatch({ 
      type: SHOW_NOTIFICATION, 
      payload: { 
        titleKey: 'apiFallbackTitle', 
        messageKey: 'apiFallbackContent', 
        type: NotificationType.Error,
        action: {
          buttonLabelKey: 'tsxMigration.showMigration',
          buttonOnClick: () => {
              store.dispatch({type: SET_MIGRATION_MODAL_VISIBILITY, payload: { open: true } }); 
          }
        }
      }
    });
  }

  public static showMigrationNotification() {
    store.dispatch({
      type: SHOW_NOTIFICATION,
      payload: {
        titleKey: 'tsxMigration.migrationNeededTitle',
        messageKey: 'tsxMigration.migrationNeededMessage',
        type: NotificationType.Migration,
        action: {
            buttonLabelKey: 'tsxMigration.showMigration',
            buttonOnClick: () => {
                store.dispatch({type: SET_MIGRATION_MODAL_VISIBILITY, payload: { open: true } }); 
            }
        }
      }
    })
  }

  public static hideNotification(notificationId: string) {
    store.dispatch({ 
      type: HIDE_NOTIFICATION,
      payload: { notificationId } 
    });
  }

  public static dismissNotification(notificationId: string) {
    store.dispatch({ 
      type: DISMISS_NOTIFICATION,
      payload: { notificationId } 
    });

    this.markNotificationsAsSeen([notificationId]);
  }

  public static markNotificationsAsSeen(notificationIds: Array<string>) {
    store.dispatch({
      type: MARK_NOTIFICATIONS_AS_SEEN,
      payload: notificationIds
    })
  }
}
import update from 'immutability-helper';
import Utils from '../../services/Utils';
import {SET_THEME, GET_ENVIRONMENTS_FULFILLED, SELECT_ENVIRONMENT, TOGGLE_ENVIRONMENTS, GET_QUERYRESULTS, GET_TENANTS_FULFILLED, SET_TENANT_ID, GET_ENVIRONMENTS, GET_TENANT_NAME_FULFILLED, SET_ENVIRONMENT_FROM_SAVED_QUERY, GET_SHORTLINK_FULFILLED, CLEAR_SHORTLINK, SET_ACTIVE_PAGE, TOGGLE_OPEN_APP_SETTINGS} from '../../../constants/ActionTypes.js';
import AuthService from '../../services/AuthService';
import { AppPages } from '../../../constants/Enums';

function determineActivePage() {
    if(window.location.href.indexOf('/model') !== -1) return AppPages.Model;
    if(window.location.href.indexOf('/2da92df7c05c') !== -1) return AppPages.Jobs;
    return AppPages.Analytics;
}

const defaultState = {theme: localStorage.getItem('tsiTheme') ? localStorage.getItem('tsiTheme') : 'light', 
                      isAppSettingsOpen: false,
                      endpoint: Utils.tsiApiEndpoint(), 
                      environments: {}, 
                      selectedEnvironmentFqdn: null,
                      environmentsVisible: false,
                      tenants: {},
                      selectedTenantId: null,
                      isLoadingEnvironments: false,
                      shortlink: null,
                      shortlinkError: null,
                      isDoneGettingTenants: false,
                      activePage: determineActivePage()
                    };

function appSettingsReducer(state, action) {
    //intialized sessionStorageItem and sessionStorageTenantIdToNameMappingObject
    let sessionStorageItem = sessionStorage.getItem('rdxTenantIdToNameMapping');
    let sessionStorageTenantIdToNameMappingObject = sessionStorage ? JSON.parse(sessionStorageItem) : {};
    if (typeof state === 'undefined') {
        return defaultState;  // default state
    }
    switch (action.type) {
        case SET_THEME:
            let newTheme = state.theme === 'light' ? 'dark' : 'light';
            localStorage.setItem('tsiTheme', newTheme);
            return update(state, {theme: {$set: newTheme}});
        case GET_ENVIRONMENTS: 
            return update(state, {isLoadingEnvironments: {$set: true}});
        case GET_ENVIRONMENTS_FULFILLED:
            Utils.fadeOutLoadingSplash();
            let environments = {};
            action.payload.environments.forEach(env => environments[env.environmentFqdn] = env);
            return update(state, {environments: {$set: environments}, isLoadingEnvironments: {$set: false}});
        case SELECT_ENVIRONMENT:
            localStorage.setItem('rdxEnvironmentFqdn', action.payload.fqdn);
            return update(state, {selectedEnvironmentFqdn: {$set: action.payload.fqdn}});
        case SET_ENVIRONMENT_FROM_SAVED_QUERY: 
            let environmentFqdn = Object.keys(state.environments).filter(fqdn => state.environments[fqdn].environmentId === action.payload.queries[0].environmentId)[0];
            localStorage.setItem('rdxEnvironmentFqdn', environmentFqdn);
            return update(state, {selectedEnvironmentFqdn: {$set: environmentFqdn}});
        case TOGGLE_ENVIRONMENTS:
            return update(state, {environmentsVisible: {$set: !state.environmentsVisible}});
        case GET_TENANTS_FULFILLED:
            let keyedTenants = Utils.createKeyedObjectFromArray(action.payload.value, 'tenantId');
            let localStorageTenantId = localStorage.getItem('rdxTenantId');
            sessionStorageTenantIdToNameMappingObject = Utils.getValueOrDefault(JSON.parse(sessionStorage.getItem('rdxTenantIdToNameMapping')), {});
            let selectedTenantId;
            let tidFromUrl = Utils.getValueOrDefault(Utils.getUrlParam('tid'), null);
            
            if(AuthService.isDogfood || AuthService.isDev1){
                selectedTenantId = AuthService.isDogfood ? 'f686d426-8d16-42db-81b7-ab578e110ccd' : '72f988bf-86f1-41af-91ab-2d7cd011db47';
            }
            else if (tidFromUrl && keyedTenants[tidFromUrl]) {
                selectedTenantId = tidFromUrl;
            }
            else if (keyedTenants[localStorageTenantId]) {
                selectedTenantId = localStorageTenantId;
            }
            else {
                selectedTenantId = Utils.getValueOrDefault(Object.keys(keyedTenants)[0], 'common');
            }
            AuthService.tenantId = selectedTenantId;
            localStorage.setItem('rdxTenantId', selectedTenantId);
            return update(state, {tenants: {$set: keyedTenants}, selectedTenantId: {$set: selectedTenantId}, isDoneGettingTenants: {$set: true}});
        case SET_TENANT_ID:
            AuthService.tenantId = action.payload;
            localStorage.setItem('rdxTenantId', action.payload);
            window.history.pushState({}, null, window.location.href.split('?')[0]);
            window.location.reload();
            return state; // not relevant because of reload
        case GET_TENANT_NAME_FULFILLED: 
            let tenantObject = state.tenants[state.selectedTenantId];
            tenantObject.displayName = action.payload;
            sessionStorageTenantIdToNameMappingObject = Utils.getValueOrDefault(JSON.parse(sessionStorage.getItem('rdxTenantIdToNameMapping')), {});
            sessionStorageTenantIdToNameMappingObject[state.selectedTenantId] = tenantObject.displayName;
            sessionStorage.setItem('rdxTenantIdToNameMapping', JSON.stringify(sessionStorageTenantIdToNameMappingObject));
            return update(state, {tenants: {[state.selectedTenantId]: {$set: tenantObject}}});
        case GET_QUERYRESULTS:
            if (state.environmentsVisible) {
                Utils.triggerResize(500);
            }
            return update(state, {environmentsVisible: {$set: false}});
        case GET_SHORTLINK_FULFILLED: 
            if (action.payload.didGetShortlink) {
                let url = `${window.location.protocol}//${window.location.host}${window.location.pathname}?sid=${JSON.parse(action.payload.result).id}&tid=${state.selectedTenantId}${Utils.getEndpointFromUrlParams() ? `&endpoint=${Utils.getEndpointFromUrlParams()}` : ''}`;
                window.history.pushState({}, null, url);
                return update(state, {shortlink: {$set: url}});    
            } else {
                return update(state, {shortlinkError: {$set: action.payload.result}});
            }
        case CLEAR_SHORTLINK:
            return update(state, {shortlink: {$set: null}, shortlinkError: {$set: null}});
        case SET_ACTIVE_PAGE:
            return update(state, {activePage: {$set: action.payload}});
        case TOGGLE_OPEN_APP_SETTINGS: 
            return update(state, {isAppSettingsOpen: {$set: !state.isAppSettingsOpen}});
        default: 
            return state;
    }
}

export const getAppSettings = state => state.appSettings;
export const getTheme = state => getAppSettings(state).theme;
export const getEnvironments = state => {
    let keyedEnvironments = getAppSettings(state).environments;
    return Utils.getArrayFromKeyedObject(keyedEnvironments).sort((a, b) => a.displayName.toLowerCase() < b.displayName.toLowerCase() ? -1 : 1);
};
export const getSelectedEnvironmentFqdn = state => getAppSettings(state).selectedEnvironmentFqdn;
export const getSelectedEnvironmentResourceId = state => getSelectedEnvironment(state).resourceId;
export const getSelectedEnvironmentId = state => getSelectedEnvironment(state).environmentId;
export const getSelectedEnvironment = state => Utils.getValueOrDefault(getAppSettings(state).environments[getAppSettings(state).selectedEnvironmentFqdn], {});
export const getSelectedEnvironmentName = state => {
    let selectedEnvironment = getSelectedEnvironment(state);
    return selectedEnvironment ? selectedEnvironment.displayName : '';
};
export const getEnvironmentsVisible = state => getAppSettings(state).environmentsVisible;
export const getSelectedEnvironmentIsLts = state => getSelectedEnvironment(state).hasOwnProperty('features');
export const getTenants = state => Utils.getValueOrDefault(getAppSettings(state).tenants, {});
export const getTenantsList = state => Utils.getArrayFromKeyedObject(getTenants(state));
export const getSelectedTenantId = state => Utils.getValueOrDefault(getAppSettings(state).selectedTenantId, '');
export const getIsLoadingEnvironments = state => getAppSettings(state).isLoadingEnvironments;
export const getSelectedTenant = state => Utils.getValueOrDefault(getTenants(state)[getSelectedTenantId(state)], {tenantId: ''});
export const getShortlinkUrl = state => Utils.getValueOrDefault(getAppSettings(state).shortlink, null);
export const getEndpoint = state => getAppSettings(state).endpoint;
export const getIsDoneGettingTenants = state => getAppSettings(state).isDoneGettingTenants;
export const getActivePage = state => getAppSettings(state).activePage;
export const getIsAppSettingsOpen = state => getAppSettings(state).isAppSettingsOpen;
export const getShortlinkError = state => getAppSettings(state).shortlinkError;
export const getIsUserContributor = state => getSelectedEnvironment(state).roles && getSelectedEnvironment(state).roles.includes("Contributor");

export default appSettingsReducer;
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import AppNotificationArea from './AppNotificationArea';
import { getTheme } from '../../state/reducers/AppSettingsReducer';
import { getNotifications } from '../../state/reducers/AppReducer';
import { OPEN_FEEDBACK_FORM } from '../../../constants/ActionTypes';

const mapStateToProps = state => ({
    notifications: getNotifications(state),
    theme: getTheme(state),
});

const mapDispatchToProps = dispatch => ({
    openFeedbackForm: (issue: string, requestId: string, responseText: string) => dispatch({ 
        type: OPEN_FEEDBACK_FORM,
        payload: { issue, requestId, responseText }
    })
});

const AppNotificationAreaContainer = translate()(connect(mapStateToProps, mapDispatchToProps)(AppNotificationArea));

export default AppNotificationAreaContainer;
import 'rxjs';
import { Observable } from 'rxjs/Observable';
import { getSelectedEnvironmentFqdn, getSelectedEnvironmentIsLts, getActivePage } from '../reducers/AppSettingsReducer';
import { 
  GET_ALL_JOBS, 
  GET_ALL_JOBS_FULFILLED, 
  CREATE_JOB, 
  CREATE_JOB_FULFILLED, 
  SELECT_ENVIRONMENT, 
  SET_ACTIVE_PAGE, 
  MODIFY_JOB,
  MODIFY_JOB_FULFILLED,
  GET_JOB_LOGS,
  GET_JOB_LOGS_FULFILLED } from '../../../constants/ActionTypes';
import AuthService from './../../services/AuthService';
import Utils from '../../services/Utils';
import { AppPages } from '../../../constants/Enums';
import { JobInfo } from '../../models/JobsModels';
import { getJobsList } from '../reducers/JobsReducer';
import NotificationService from '../../services/NotificationService';

const jobsApiVersion = '?api-version=2019-10-11-privatepreview';

const buildJobHierarchy = responseJson => {
  let jobs = responseJson.jobInfos.map(JobInfo.fromObject);
  
  let topLevel = [];
  let children = {};

  for(let i = 0; i < jobs.length; i++) {
    let job = jobs[i];

    if(job.parentJobName === null) {
      topLevel.push(job);
    } else if(children.hasOwnProperty(job.parentJobName)) {
      children[job.parentJobName].push(job);
    } else {
      children[job.parentJobName] = [job];
    }
  }

  return { topLevel, children };
};

export const loadJobsInJobsPageEpic = (action$, store) => 
    action$.ofType(SELECT_ENVIRONMENT, SET_ACTIVE_PAGE)
    .mergeMap(() => {
        let state = store.getState();
        
        if (getActivePage(state) === AppPages.Jobs && getSelectedEnvironmentIsLts(state)) {
            return Observable.of({ type: GET_ALL_JOBS });
        } else {
            return Observable.empty();
        }
    });

export const getAllJobsEpic = (action$, store) => { 
  return action$.ofType(GET_ALL_JOBS) 
  .switchMap( () => {
    return AuthService.getTsiToken()
      .mergeMap(authToken => {
        let getAllJobsPromise = Utils.promiseHttpRequest(
          authToken, 
          `https://${getSelectedEnvironmentFqdn(store.getState())}/jobs${jobsApiVersion}`, 
          {}, 
          'GET');

        return Observable.fromPromise(getAllJobsPromise);
      })
      .mergeMap(response => {
        let responseJson = JSON.parse(response);
        try {
          const { topLevel, children } = buildJobHierarchy(responseJson);
          topLevel.forEach(job => job.mergeWithChildJobs(children[job.name]));

          return Observable.of({ type: GET_ALL_JOBS_FULFILLED, payload: { jobsList: topLevel, childJobs: children, error: null } });
        } catch(error) {
          console.error(`Could not parse server response: ${error}`);

          return Observable.of({ type: GET_ALL_JOBS_FULFILLED, payload: { jobsList: [], childJobs: {}, error } });
        }
      })
      .catch(xhr => {
        return Observable.of({ type: GET_ALL_JOBS_FULFILLED, payload: { jobsList: [], childJobs: {}, error: xhr } });
      });
  });
};

export const createNewJobEpic = (action$, store) => {
  return action$.ofType(CREATE_JOB) 
  .switchMap(({ payload }) => {
    let state = store.getState();
    return AuthService.getTsiToken()
      .mergeMap(authToken => {
        let jobName = encodeURIComponent(payload.jobName);
        let createJobPromise = Utils.promiseHttpRequest(
          authToken, 
          `https://${getSelectedEnvironmentFqdn(state)}/jobs/${jobName}${jobsApiVersion}`, 
          JSON.stringify(payload.requestBody), 
          'PUT');
        
        return Observable.fromPromise(createJobPromise);
      })
      .mergeMap(response => {
        try {
          let json = JSON.parse(response);
          let job = JobInfo.fromObject(json);
          if(job.parentJobName) {
            let parentJob: JobInfo = getJobsList(state).find(j => j.name === job.parentJobName);
            parentJob.recentlyModified = true;
          } else {
            job.recentlyModified = true;
          }

          return Observable.of({ type: CREATE_JOB_FULFILLED, payload: job });
        } catch(error) {
          console.error(`Failed to parse response from server: ${error}`);
          return Observable.of({ type: CREATE_JOB_FULFILLED, payload: {} });
        }
      })
      .catch(xhr => {
        let responseBody = xhr ? JSON.parse(xhr.response) : null;
        let messageKey = (responseBody && responseBody.error && responseBody.error.code) || null;

        NotificationService.showErrorNotification('jobs.createJobFailed',  `jobs.errors.${messageKey}`, xhr);

        return Observable.empty();
      });
  });

};

export const updateJobEpic = (action$, store) => {
  return action$.ofType(MODIFY_JOB)
  .switchMap(({ payload }) => {
    return AuthService.getTsiToken()
      .mergeMap(authToken => {
        let updateJobPromise = Utils.promiseHttpRequest(
          authToken,
          `https://${getSelectedEnvironmentFqdn(store.getState())}/jobs/${payload.jobName}/~${payload.action}${jobsApiVersion}`,
          JSON.stringify(payload.requestBody),
          'POST'
        );

        return Observable.fromPromise(updateJobPromise);
      })
      .mergeMap(response => {
        try {
          let job = JobInfo.fromObject(JSON.parse(response));
          return Observable.of({ type: MODIFY_JOB_FULFILLED, payload: job });
        } catch(error) {
          console.error(`Failed to parse response from server: ${error}`);
          return Observable.of({ type: MODIFY_JOB_FULFILLED, payload: {} });
        }
        
      })
      .catch(error => {
        console.error(error);
        return Observable.empty();
      });
  });
}

export const getJobLogs = (action$, store) => {
  return action$.ofType(GET_JOB_LOGS) 
  .switchMap( ({ payload }) => {
    return AuthService.getTsiToken()
      .mergeMap(authToken => {
        let getAllJobsPromise = Utils.promiseHttpRequest(
          authToken, 
          `https://${getSelectedEnvironmentFqdn(store.getState())}/jobs/${payload.jobName}/logs${jobsApiVersion}`, 
          {}, 
          'GET');

        return Observable.fromPromise(getAllJobsPromise);
      })
      .mergeMap(response => {
        let responseJson = JSON.parse(response);
        
        return Observable.of({ type: GET_JOB_LOGS_FULFILLED, payload: { logs: responseJson?.logs, error: null } });
      })
      .catch(xhr => {
        return Observable.of({ type: GET_JOB_LOGS_FULFILLED, payload: { logs: null, errorInfo: xhr } });
      });
  });
}
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import ChartableComponent from './ChartableComponent';
import { getTheme } from '../../state/reducers/AppSettingsReducer';
import { getAvailabilityIsCompact, getActiveQueries, getModalOptions, getIsLoadingAggregates } from '../../state/reducers/AnalyticsReducer';

const mapStateToProps = state => {
    return { 
        theme: getTheme(state),
        queries: getActiveQueries(state),
        isLoadingAggregates: getIsLoadingAggregates(state),
        tall: getAvailabilityIsCompact(state),
        modalOptions: getModalOptions(state)
    };
};

const ChartableComponentContainer = translate()(connect(mapStateToProps)(ChartableComponent));

export default ChartableComponentContainer;

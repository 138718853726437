import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { getTheme, getSelectedEnvironmentFqdn, getSelectedEnvironmentResourceId } from '../../state/reducers/AppSettingsReducer';
import { ReferenceDataModal } from './ReferenceDataModal';
import { CLEAR_PROGRESS } from '../../../constants/ActionTypes';

const mapStateToProps = state => {
    return { 
        theme: getTheme(state),
        environmentFqdn: getSelectedEnvironmentFqdn(state),
        selectedEnvironmentResourceId: getSelectedEnvironmentResourceId(state),
    };
};

const mapDispatchToProps = dispatch => ({
    clearProgress: () => dispatch({type: CLEAR_PROGRESS})
});

const ReferenceDataModalContainer = translate()(connect(mapStateToProps, mapDispatchToProps)(ReferenceDataModal));

export default ReferenceDataModalContainer;
import React from "react";
import ModalContainer from "../../Modal/Modal.container";
import {ProgressMessageField} from "./ProgressMessageField";
import TooltipableContainer from "../../Tooltipable/Tooltipable.container";
import classNames from 'classnames/bind';
import update from 'immutability-helper';
import { Variable } from "../../../models/Variable";
import Icon from "../../Icon/Icon";
import Header from "./Header";
import Utils from "../../../services/Utils";
import { emptyDisplayString } from "../../../../constants/Constants";
const cx = classNames.bind(require('../TsmModal.module.scss'));

interface Props {type: any; tsmModalComponent: any; }

export default class TypeModal extends React.Component<Props> {
    private typeNameTitleID = Utils.customID('typeNameTitle');
    private typeNameErrorFieldID = Utils.customID('typeNameErrorField');
    private progressMessageFieldID = Utils.customID('progressMessageFieldID');
    private emptyText = '(' + this.props.tsmModalComponent.props.t(emptyDisplayString) + ')';

    handleNameChange = event => {
        this.props.tsmModalComponent.setState(update(this.props.tsmModalComponent.state, {entity: {name: {$set: event.target.value}}}));
    };
    
    handleDescriptionChange = event => {
        this.props.tsmModalComponent.setState(update(this.props.tsmModalComponent.state, {entity: {description: {$set: event.target.value}}}));
    };

    render() {
        const tsmModalComponent = this.props.tsmModalComponent;
        this.props.type.updateErrors();

        return (
            <ModalContainer onClose={tsmModalComponent.closeModal} title={tsmModalComponent.props.entityToEdit ? tsmModalComponent.props.t('tsm.edit') + " " + tsmModalComponent.props.entityToEdit.name : tsmModalComponent.props.t('tsm.type.new')} className={cx('tsmEntityModal')} contentPositionContainerClassName={cx('tsmModalContentPositionContainer')} wrapperClassName={cx('tsmModalContentContainer')} contentClassName={cx('tsmModalContent')} isOffModalAutoClosingEnabled={false}>
                <div className={cx('modalContent')}>
                    <div className={cx('entityContainer')}>
                        <div className={cx('tabs', {lessMinHeight: tsmModalComponent.state.isSubEntityTab})}>
                            <button className={cx('tab', {selected: !tsmModalComponent.state.isSubEntityTab})} 
                                onClick={() => tsmModalComponent.setIsSubEntityTab(false)} role="tab"
                                aria-selected={!tsmModalComponent.state.isSubEntityTab}>
                                {tsmModalComponent.props.t('tsm.properties')}
                            </button>
                            <button className={cx('tab', {selected: tsmModalComponent.state.isSubEntityTab})} 
                                onClick={() => tsmModalComponent.setIsSubEntityTab(true)} role="tab"
                                aria-selected={tsmModalComponent.state.isSubEntityTab}>
                                {tsmModalComponent.props.t('tsm.type.variables')}
                            </button>
                        </div>
                        <div className={cx('form')}>
                            {tsmModalComponent.state.isSubEntityTab ? 
                                <div className={cx('build-area')}>
                                    <div className={cx('block')}> 
                                            <div className={cx('card-variables')}>
                                                <table>
                                                    <Header headers={[[tsmModalComponent.props.t('tsm.name'), 'name'], tsmModalComponent.props.t('tsm.type.variable.value'), [tsmModalComponent.props.t('tsm.type.variable.kind'), 'kind'], tsmModalComponent.props.t('tsm.actions')]} tsmModalComponent={tsmModalComponent}/>
                                                    <tbody>
                                                        {this.props.type.variables && this.props.type.variables.map((v, idx) =>
                                                            <tr tabIndex={0} key={idx} ref={el => { if (idx === this.props.type.variables.length - 1) tsmModalComponent.entitiesEnd = el; }}>
                                                                <td>{v.name ? v.name : this.emptyText}
                                                                    {v.errors?.name_unique && <div role="alert" className={cx('error-message', '_base-fade-in')}>{tsmModalComponent.props.t('tsm.type.validations.variable.name_unique')}</div>}
                                                                </td>
                                                                <td><span title={v.value ? v.value.tsx : this.emptyText}>{v.value ? v.value.tsx : this.emptyText}</span></td>
                                                                <td>{v.kind ? v.kind : this.emptyText}</td>
                                                                <td>
                                                                    <button className={cx('action-button')} onClick={() => tsmModalComponent.setSubEntityIndexToEdit(idx, Variable.fromObject(v, tsmModalComponent.state.valueOptions))} 
                                                                            aria-label={tsmModalComponent.props.t('tsm.type.variable.edit')} title={tsmModalComponent.props.t('tsm.edit')}>
                                                                        <Icon id={'iconEdit'} className={cx('icon16', tsmModalComponent.props.theme)}/>
                                                                    </button>
                                                                    <button className={cx('action-button', 'delete')} onClick={tsmModalComponent.removeEntityProperty(idx)} 
                                                                            aria-label={tsmModalComponent.props.t('tsm.type.variable.delete')} title={tsmModalComponent.props.t('tsm.delete')}>
                                                                        <Icon id={'iconDelete'} className={cx('icon16', tsmModalComponent.props.theme)}/>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                                {this.props.type.errors?.variables && <div className={cx('error-message', '_base-fade-in')} role="alert">{tsmModalComponent.props.t('tsm.type.validations.variables')}</div>}
                                            </div>
                                    </div>
                                    <div className={cx('sub-button', 'addVariable')}>
                                        <button aria-label={tsmModalComponent.props.t('tsm.type.addVariable')} tabIndex={0} 
                                            onClick={tsmModalComponent.addEntityProperty}>
                                            <Icon id={'iconAddBlue'} className={cx('icon16')}/>
                                            <span>{tsmModalComponent.props.t('tsm.type.addVariable')}</span>
                                        </button>
                                    </div>
                                </div>
                                :
                                <div className={cx('build-area')}>
                                    <div className={cx('block')}>
                                        <div id={this.typeNameTitleID} className={cx('title')}>{tsmModalComponent.props.t('tsm.name')}</div> 
                                        <input aria-labelledby={this.typeNameTitleID} aria-describedby={this.typeNameErrorFieldID} className={cx(this.props.type.errors?.name ? 'error' : '')} type="text" value={this.props.type.name} onChange={this.handleNameChange}/>
                                        {this.props.type.errors?.name && <div id={this.typeNameErrorFieldID} role="alert" className={cx('error-message', '_base-fade-in')}>{tsmModalComponent.props.t('tsm.type.validations.name')}</div>}
                                    </div>
                                    <div className={cx('block')}>
                                        <div className={cx('title')}>{tsmModalComponent.props.t('tsm.description')}</div> 
                                        <textarea aria-label={tsmModalComponent.props.t('tsm.description')} className={cx(this.props.type.errors?.description ? 'error' : '')} value={this.props.type.description ? this.props.type.description : ""} onChange={this.handleDescriptionChange}/>
                                        {this.props.type.errors?.description && <div className={cx('error-message', '_base-fade-in')}>{tsmModalComponent.props.t('tsm.type.validations.description')}</div>}
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className={cx('tsmModalBottom')}>
                        <button className={cx('showJson')} aria-label={tsmModalComponent.props.t('tsm.viewJson')} role="link" tabIndex={0} onKeyDown={tsmModalComponent.showJSON} onClick={tsmModalComponent.showJSON}><Icon id={"iconDocument"} className={cx('icon16')}/>{tsmModalComponent.props.t('tsm.viewJson')}</button>
                        <div className={cx('buttonWrapper')}>
                            <button aria-label={tsmModalComponent.props.t('cancel')} className={cx('cancelButton')} onKeyDown={tsmModalComponent.closeModal} onClick={tsmModalComponent.closeModal}>{tsmModalComponent.props.t('cancel')}</button>
                            {this.props.type.hasErrors() ?
                                <TooltipableContainer tooltip={tsmModalComponent.props.t('tsm.fixErrorsToSave')} position={'top'}>
                                    <button className={cx('saveButton', 'disabled', Utils.isMigrationEnabledAndNeededForVariables(tsmModalComponent.props.types, tsmModalComponent.props.isUserContributor) ? 'secondary' : '')} aria-disabled="true" disabled>{tsmModalComponent.props.t('save')}</button>
                                </TooltipableContainer>
                            :
                                <button className={cx('saveButton', Utils.isMigrationEnabledAndNeededForVariables(tsmModalComponent.props.types, tsmModalComponent.props.isUserContributor) ? 'secondary' : '')} onKeyDown={tsmModalComponent.createOrEditEntity} onClick={tsmModalComponent.createOrEditEntity} aria-describedby={this.progressMessageFieldID}>{tsmModalComponent.props.t('save')}</button>
                            }
                        </div>
                    </div>
                    <ProgressMessageField 
                        cx={cx}
                        entityType={tsmModalComponent.props.entityType} 
                        progress={tsmModalComponent.props.progress} 
                        errorCode={tsmModalComponent.props.errorCode} 
                        errorMessage={tsmModalComponent.props.errorMessage} 
                        method={tsmModalComponent.props.requestMethod} 
                        theme={tsmModalComponent.props.theme} 
                        t={tsmModalComponent.props.t}
                        progressMessageFieldID={this.progressMessageFieldID}
                        migrationNeeded={Utils.isMigrationEnabledAndNeededForVariables(tsmModalComponent.props.types, tsmModalComponent.props.isUserContributor)}
                        showMigrationActionHandler={tsmModalComponent.switchToMigrationModal}/>
                </div>
            </ModalContainer>
        )

    }
}
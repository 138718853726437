import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import RefreshCharts from './RefreshCharts';
import { SET_AUTO_REFRESH, GET_AVAILABILITY, GET_MODEL } from '../../../../constants/ActionTypes';
import { getTheme } from '../../../state/reducers/AppSettingsReducer';
import { getAutoRefreshCycleMillis, getIsAutoRefreshEnabled, getAvailabilityDistributionIsLoading, getIsLongPollingAvailability, getIsLoadingAggregates } from '../../../state/reducers/AnalyticsReducer';
import TelemetryService from '../../../services/TelemetryService'; 

const mapStateToProps = state => {
    return { 
        theme: getTheme(state),
        autoRefreshCycleMillis: getAutoRefreshCycleMillis(state),
        isAutoRefreshEnabled: getIsAutoRefreshEnabled(state),
        isAvailabilityDistributionIsLoading: getAvailabilityDistributionIsLoading(state),
        isLongPollingAvailability: getIsLongPollingAvailability(state),
        isLoadingAggregates: getIsLoadingAggregates(state)
    };
};

const mapDispatchToProps = dispatch => ({
    setAutoRefresh: (isAutoRefreshEnabled) => {
        dispatch({type: SET_AUTO_REFRESH, payload: isAutoRefreshEnabled});
        TelemetryService.logUserAction('autoRefresh', {enabled: isAutoRefreshEnabled});
    },
    getModel: () => {
        dispatch({type: GET_MODEL});
    },
    getAvailability: (payload) => {
        dispatch({type: GET_AVAILABILITY, payload: payload});
        TelemetryService.logUserAction('manualRefresh');
    }
});

const RefreshChartsContainer = translate(null, {withRef: true})(connect(mapStateToProps, mapDispatchToProps, null, { withRef: true})(RefreshCharts));

export default RefreshChartsContainer;
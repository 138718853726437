import * as React from 'react';
import classNames from 'classnames/bind';
import Utils from '../../services/Utils';
import TimeSeriesQuery from '../../models/TimeSeriesQuery';
import AuthService from '../../services/AuthService';
import TelemetryService from '../../services/TelemetryService';
import Resizable from '../Resizable/Resizable';
import Icon from '../Icon/Icon';
import { Link } from 'react-router-dom';
import NotificationService from '../../services/NotificationService';
import { CollapsableComponents } from '../../../constants/Enums';
const cx = classNames.bind(require('./TagExplorer.module.scss'));

interface Props { t: any; theme: string; selectedHierarchyId: any; model: any; hierarchies: any; instances: any; 
                types: any; fqdn: string; addTimeSeriesQuery: any; removeTimeSeriesQuery: any; getQueryResults: any; 
                selectHierarchy: any; metadata: any; onResizeStart: any; onResizeStop: any; timeSeriesQueries: any; 
                tsidForLookup: any; resetTSIDforLookup: any; jumbotronState: any, updateCollapseState}

class TagExplorer extends React.Component<Props> {
    private hierarchyNav;
    private wrapperId = Utils.guid();
    private firstAvailableSwimLane = 0;
    private isRegisteredForNotifications = false;
    private renderTimeout: number;
    private resizeConstants = {
        snapWidth: '220px',
        snapBuffer: 40,
        maxWidth: '720px',
        defaultWidth: '308px'
    }

    constructor(props) {
        super(props);
        this.renderTimeout = null;
    }

    stringToColour = (str, isForTree = false) => {
        if (isForTree) {
            return 'transparent';
        }
        return Utils.getColor(str);
    }

    shouldComponentUpdate(nextProps, nextState) {
        return  nextProps.model !== this.props.model || 
                nextProps.theme !== this.props.theme ||
                nextProps.t !== this.props.t || 
                nextProps.jumbotronState[CollapsableComponents.tagExp] !== this.props.jumbotronState[CollapsableComponents.tagExp]
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.tsidForLookup !== this.props.tsidForLookup && nextProps.tsidForLookup !== undefined) {
            this.hierarchyNav.showInstance(nextProps.tsidForLookup);
            this.props.resetTSIDforLookup();
        }
    }

    componentWillUnmount() {
        this.isRegisteredForNotifications = false;
        if (this.renderTimeout) {
            window.clearTimeout(this.renderTimeout);
        }
    }

    componentDidMount() {
        this.isRegisteredForNotifications = true;
    }

    addInstanceAsQuery(instance, type, nodeName, variableName) {
        let chartDataOptions: any = {};        
        let swimLaneNumber = this.firstAvailableSwimLane % 4;
        chartDataOptions.swimLane = swimLaneNumber === 0 ? 4 : swimLaneNumber;
        this.firstAvailableSwimLane++; // this is for the case of multiple selected variables showing up in continuous swimlane numbers

        let color = this.stringToColour(Utils.getInstanceAlias(instance) + "_" + variableName + "_" + this.props.timeSeriesQueries.length); 

        let tsq = TimeSeriesQuery.constructFromInstanceTypeVariable(type, instance, variableName, color, chartDataOptions);        
        this.props.addTimeSeriesQuery(tsq);
        this.props.getQueryResults();
    }

    onSelect = (selectedHierarchyId) => {
        this.props.selectHierarchy(selectedHierarchyId);
    }

    onError = (titleKey, messageKey, xhr) => {
        if (this.isRegisteredForNotifications) {
            NotificationService.showErrorNotification('sdkStrings.' + titleKey, 'sdkStrings.' + messageKey, xhr);
        }
    }

    onInstanceClick = instance => {
        this.firstAvailableSwimLane = Utils.getAvailableSwimLane(this.props.timeSeriesQueries);
        let stripHits = s => s.split('<hit>').join('').split('</hit>').join('');
        let addInstanceAction = (type, vName) => {
            if (instance.highlights) {
                instance.instanceFields = instance.highlights.instanceFieldNames.reduce((p, c, i) => {
                    if (i in instance.highlights.instanceFieldValues) {
                        p[stripHits(c)] = stripHits(instance.highlights.instanceFieldValues[i]); 
                    }
                    return p; }, 
                {});
                instance.description = stripHits(instance.highlights.description);
                instance.name = stripHits(instance.highlights.name);
            }
            this.addInstanceAsQuery(instance, type, instance.name, vName);
        };
        
        let variables = Utils.createVariablesList(instance.type, this.props.metadata);
        let contextMenuActions = variables.map((v: any) => {
            v.action = () => {
                addInstanceAction(v.type, v.name);
                if (v.addInlineVariableFlag)
                    TelemetryService.logUserAction('addInlineVariable');
            };
            return v;
        });
        this.hierarchyNav.drawContextMenu(contextMenuActions, {
            isSelectionEnabled: contextMenuActions.length > 1 ? true : false, 
            isFilterEnabled: contextMenuActions.length > 5 ? true : false
        });
    }

    render() {
        let hierarchyNavOptions = {
            theme: this.props.theme,
            hierarchyOptions: {
                instancesPageSize: 100,
                hierarchiesPageSize: 100,
                isInstancesRecursive: false,
                isInstancesHighlighted: true,
                instancesSort: "DisplayName",
                hierarchiesExpand: "OneLevel",
                hierarchiesSort: "Name"
            },
            selectedHierarchyId: this.props.selectedHierarchyId
        };

        this.renderTimeout = window.setTimeout(() => {
            this.hierarchyNav = this.hierarchyNav ? this.hierarchyNav : new Utils.tsiClient.ux.HierarchyNavigation(document.getElementById(this.wrapperId));
            if(this.props.fqdn)
                this.hierarchyNav.render(
                    this.props.fqdn, 
                    AuthService.getTsiTokenPromise, 
                    {...hierarchyNavOptions, 
                        onInstanceClick: instance => this.onInstanceClick(instance), 
                        onError: (titleKey, messageKey, xhr) => this.onError(titleKey, messageKey, xhr),
                        onSelect: (hierarchyId) => this.onSelect(hierarchyId),
                        strings: this.props.t('sdkStrings', {returnObjects: true})
                    });
        });

        return (
            <Resizable
                className={cx('theme-' + this.props.theme, 'wrapper')}
                size={{
                    width: this.props.jumbotronState[CollapsableComponents.tagExp] ? '0px' : this.resizeConstants.defaultWidth,
                    height: '100%'
                }}
                minWidth={'0px'}
                maxWidth={this.resizeConstants.maxWidth}
                isCollapsable={true}
                snapWidthBeforeCollapse={this.resizeConstants.snapWidth}
                snapBuffer={this.resizeConstants.snapBuffer}
                enable={{top: false, right:true, bottom:false, left:false}}
                onResizeStart={this.props.onResizeStart}
                onResizeStop={this.props.onResizeStop}
                onCollapsed={(isCollapsed) => {
                    this.props.updateCollapseState(isCollapsed);
                }}
                >
            
                {(this.props.instances.length === 1 && Utils.instanceHasEmptyTSID(this.props.instances[0]))
                && <WrongTsidColumnWarning props={{...this.props}} />}
                
                {(this.props.instances.length > 1 && this.props.hierarchies.length === 0) 
                && <NoHierarchiesWarning props={{...this.props}} />}
            
                <div className={cx('hierarchyNavWrapper')}>
                    <div id={this.wrapperId} className={cx('hierarchyNav')}></div>
                </div>
            </Resizable>
        );
    }
}

const NoHierarchiesWarning = ({props}) => {
    if(props.jumbotronState[CollapsableComponents.tagExp]) return null;
    return  <div className={cx('errorText') }>
                <Icon id='iCircle' className={cx('icon16')} />
                <div className={cx('text')}>
                    {props.t('noHierarchies')}
                    <Link to={`${Utils.isSampleEnvironments() ? Utils.sampleRoute : ''}/model`}>
                        Model
                    </Link>
                </div>
            </div>;
};

const WrongTsidColumnWarning = ({props}) => {
    return  <div className={cx('errorText') }>
                <Icon id='iCircle' className={cx('icon16')} />
                <div className={cx('text')}>
                    {props.t('wrongTSID')}
                    <a target="_blank" rel="noopener noreferrer" href="https://docs.microsoft.com/azure/time-series-insights/time-series-insights-update-how-to-id">{props.t('learnMore')}</a>
                </div>
            </div>;
}

export default TagExplorer;
import { translate } from "react-i18next"
import { connect } from "react-redux"
import { OPEN_FEEDBACK_FORM } from "../../../../constants/ActionTypes";
import { getShortlinkError, getShortlinkUrl, getTheme } from "../../../state/reducers/AppSettingsReducer";
import Share from "./Share"

const mapStateToProps = state => {
    return {
        theme: getTheme(state),
        shortlinkUrl: getShortlinkUrl(state),
        shortlinkError: getShortlinkError(state),
    }
}

const mapDispatchToProps = dispatch => ({
    openFeedbackForm: (issue: string, requestId: string, responseText: string): void => dispatch({ 
        type: OPEN_FEEDBACK_FORM,
        payload: { issue, requestId, responseText }
    })
})

export default translate()(connect(mapStateToProps, mapDispatchToProps)(Share))
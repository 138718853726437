/**
 * Represents a category for a type variable.
 * @constructor
 * @param {string} label - The label of the category.
 * @param {any[]} values - The picked value for the category.
 * @param {string} color - The picked color for the category.
 * @param {{}} [errors] - Errors in the category.
 */
export class VariableCategory {
    public label: string;
    public values: any[];
    public annotations?: {};
    public errors?: {};

    constructor() {
        this.label = "";
        this.values = [];
        this.annotations = {color: null};
    }

    static fromObject (obj) {
        let c = new VariableCategory ();
        c.label = obj.label;
        c.values = obj.values;
        if (obj.annotations) 
            c.annotations = obj.annotations;
        return c;
    }

    public updateErrors() {
        if (!this.label) {
            if (!this.errors) {
                this.errors = {};
            }
            this.errors["label"] = true;
        } else {
            if (this.errors && this.errors["label"]) {
                delete this.errors["label"];
            }
        }

        if (this.values.length === 0) {
            if (!this.errors) {
                this.errors = {};
            }
            this.errors["values"] = true;
        } else {
            if (this.errors && this.errors["values"]) {
                delete this.errors["values"];
            }
        }
    }

    public errorCount() {
        let errorCount = 0;
        if (this.errors) {
            errorCount += Object.keys(this.errors).length;
        }

        return errorCount;
    }

    public addError(errorEnum) {
        if (!this.errors) {
            this.errors = {};
        }
        this.errors[errorEnum] = true;
    }

    public removeError(errorEnum) {
        if (this.errors) {
            delete this.errors[errorEnum];
        }
    }
}
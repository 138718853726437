import { connect } from 'react-redux';
import Environments from './Environments';
import {GET_AVAILABILITY, TOGGLE_ENVIRONMENTS, SELECT_ENVIRONMENT, CLEAR_SAVED_QUERY_NAME} from '../../../constants/ActionTypes';
import { 
    getTheme, 
    getEnvironments, 
    getSelectedEnvironment, 
    getEnvironmentsVisible, 
    getIsLoadingEnvironments, 
    getSelectedTenant, 
    getActivePage } from '../../state/reducers/AppSettingsReducer';
import { getQueryName } from '../../state/reducers/AnalyticsReducer';
import { translate } from 'react-i18next';
import { GetAvailabilityReason } from '../../../constants/Enums';

const mapStateToProps = state => {
    return { 
        environments: getEnvironments(state),
        selectedEnvironment: getSelectedEnvironment(state),
        theme: getTheme(state),
        isVisible: getEnvironmentsVisible(state),
        isLoadingEnvironments: getIsLoadingEnvironments(state),
        selectedTenant: getSelectedTenant(state),
        activePage: getActivePage(state),
        selectedQueryName: getQueryName(state)
    };
};

const mapDispatchToProps = dispatch => ({
    selectEnvironment: (fqdn) => {
        dispatch({type: SELECT_ENVIRONMENT, payload: {fqdn: fqdn, isFromEnvironmentPicker: true}});
        dispatch({type: GET_AVAILABILITY, payload: {unsetQueries: true, getAvailabilityReason: GetAvailabilityReason.PageLoadOrEnvironmentChange}});
        dispatch({type: TOGGLE_ENVIRONMENTS});
        dispatch({type: CLEAR_SAVED_QUERY_NAME});
    },
    toggleEnvironments: () => dispatch({type: TOGGLE_ENVIRONMENTS})
});

const EnvironmentsContainer = translate()(connect(mapStateToProps, mapDispatchToProps)(Environments));

export default EnvironmentsContainer;

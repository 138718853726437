import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import AdvancedExploration from './AdvancedExploration';
import { SET_SCATTER_MEASURES, SET_CHART_TYPE, GET_QUERYRESULTS} 
    from '../../../constants/ActionTypes';
import { getTimeSeriesQueries, getModalOptions, getModalTimeSeriesQuery, getTimezone} 
    from '../../state/reducers/AnalyticsReducer';
import { getTheme } from '../../state/reducers/AppSettingsReducer';

const mapStateToProps = state => {
    return { 
        timeSeriesQueries: getTimeSeriesQueries(state),
        theme: getTheme(state),
        modalOptions: getModalOptions(state),
        modalTimeSeriesQuery: getModalTimeSeriesQuery(state),
        timezone: getTimezone(state)
    };
};

const mapDispatchToProps = dispatch => ({ 
    setScatterMeasures: (measureIDs) => {
        dispatch({type: SET_SCATTER_MEASURES, payload: measureIDs})
    },
    setChartType: (chartType) => dispatch({type: SET_CHART_TYPE, payload: chartType}),
    getQueryResults: () => dispatch({type: GET_QUERYRESULTS, payload: {unsetUnzoomStack: true}})
});

const AdvancedExplorationContainer = translate()(connect(mapStateToProps, mapDispatchToProps)(AdvancedExploration));

export default AdvancedExplorationContainer;

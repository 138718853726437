import * as React from 'react';
import classNames from 'classnames/bind';
import Icon from '../Icon/Icon';
import Utils from '../../services/Utils';
import Resizable from '../Resizable/Resizable';
import { ModalTypes, CaretDirections, ChartDataTypes, CollapsableComponents } from '../../../constants/Enums';
import ColorPickerContainer from '../ColorPicker/ColorPicker.container';
import TimeSeriesQuery from '../../models/TimeSeriesQuery';
import ModalContainer from '../Modal/Modal.container';

const cx = classNames.bind(require('./Well.module.scss'));

interface Props { t: any; timeSeriesQueries: Array<any>; removeTimeSeriesQuery: any; showHideTimeSeriesQuery: any; 
                  tsqFields: any; envId: string; theme: string; setTSQSort: any; tsqSortField: string; 
                  tsqSortDescending: boolean; onResizeStart: any; onResizeStop: any; setTimeShift: any;
                  isLoadingSavedTsq: boolean; focusTrigger: any; unfocusTrigger: any; stickyTrigger: any; 
                  stickiedTSQ: string; stickyTSQ: any; unstickyTSQ: any; scatterMeasures: any; setModalOptions: any;
                  closeModal: any; getQueryResults: any; setTSQField: any; isLoadingAggregates: boolean; setSwimLane: any;
                  setTSIDforLookup: any; duplicateTimeSeries: any; types: any; setTSQVariable: any; metadata: any; instances: any; 
                  setTSQColor: any; openFeedbackForm: (issue: string, requestId: string, responseText: string) => void; timezone: any;
                  setChartSettingsToSeries: (series: TimeSeriesQuery, tsqI: number) => void; jumbotronState: boolean; updateCollapseState: any;
                  clearLineChartMarkers: () => void, removeAllTimeSeriesQueries: () => void, resetSwimlaneOptions: () => void,
                  refreshPreviousSessionState: () => void; clearSavedQueryName: () => void }
                  
interface State { widthArray: any; scrollLeft: number; tableWidth: number; isRetryBannerVisible: boolean; isClearAllModalVisible: boolean }

class Well extends React.Component<Props, State> {

    private wellClass: string;
    private activeColorPicker: any;
    private clearAllButtonRef;
    private resizeConstants = {
        snapHeight: '110px',
        maxHeight: '536px',
        defaultHeight: '192px',
        snapBuffer: 30,
    }

    constructor(props: Props) {
        super(props);
        this.state = {
            widthArray: [],
            scrollLeft: 0,
            tableWidth: null,
            isRetryBannerVisible: false,
            isClearAllModalVisible: false
        };

        this.clearAllButtonRef = React.createRef();
    }

    componentDidMount() {
        this.wellClass = Utils.guid();
        window.addEventListener("resize", this.onWindowResize);
    }

    componentDidUpdate () {
        setTimeout (() => {
            if (document.getElementsByClassName(cx('tableHeaderRow')).length !== 0) {
                this.refCallback(document.getElementsByClassName(cx('tableHeaderRow'))[0]);
            }
        });
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.isLoadingAggregates && !nextProps.isLoadingAggregates) {
            if (this.isTsqsWith503Error(nextProps.timeSeriesQueries)) {
                this.setState({isRetryBannerVisible: true});
            } else {
                this.setState({isRetryBannerVisible: false});
            }
        }
    }

    componentWillUnmount () {
        window.removeEventListener("resize", this.onWindowResize);
    }

    render() {
        return (
            <Resizable 
                className={cx('theme-' + this.props.theme, 'outerWrapper', {hidden: this.props.timeSeriesQueries.length === 0}, 'wide') + " " + this.wellClass}
                size={{
                    width: '100%',
                    height: this.props.jumbotronState[CollapsableComponents.well] ? '0px' : this.resizeConstants.defaultHeight
                }}
                minHeight={'0px'}
                snapBuffer={this.resizeConstants.snapBuffer}
                maxHeight={this.resizeConstants.maxHeight}
                isCollapsable={true}
                snapWidthBeforeCollapse={this.resizeConstants.snapHeight}
                maxWidth={null}
                minWidth={null}
                enable={{top: true, right: false, bottom:false, left:false}}
                onResizeStart={this.props.onResizeStart}
                onResizeStop={this.props.onResizeStop}
                onCollapsed={(isCollapsed) => {
                    this.props.updateCollapseState(isCollapsed);
                }}
                >
                    {this.createWell(this.props.tsqSortField, this.headerWidthStyle())}
            </Resizable>
        );
    }

    adjustHeaderLeftPosition = (e) => {
        if (this.state.scrollLeft !== e.target.scrollLeft) {
            this.setState({scrollLeft: e.target.scrollLeft});
        }
    }

    private didWidthArrayChange = (newWidthArray) => {
        if (this.state.widthArray.length !== newWidthArray.length) {
            return true;
        }
        for (var i = 0; i < newWidthArray.length; i++) {
            if (newWidthArray[i] !== this.state.widthArray[i]){
                return true;
            }
        }
        return false;
    }

    refCallback = (element) => {
        let tableWidth = null;
        if (document.getElementsByClassName(cx('tableContainer')).length > 0) {
            tableWidth = document.getElementsByClassName(cx('tableContainer'))[0].clientWidth;
        }
        if (element && element.children) {
            let newWidthArray = Array.from(element.children).map((thElem: any) => thElem.clientWidth);
            let updateObject = {}; 
            if (tableWidth !== this.state.tableWidth) {
                updateObject['tableWidth'] = tableWidth;
            }
            if (this.didWidthArrayChange(newWidthArray)) {
                updateObject['widthArray'] = newWidthArray;
            } 
            if (Object.keys(updateObject).length !== 0) {
                this.setState(updateObject);
            }
        }
    };

    private headerCellWidth = (index) => {
        if (index < this.state.widthArray.length) {
            return {width: this.state.widthArray[index]};
        }
        return {width: 'inherit'};
    }

    private setLeftScroll = (elem) => {
        if (this.state !== null && this.state.scrollLeft !== null && elem !== null) {
            elem.scrollLeft = this.state.scrollLeft;
        }
    }

    private onWindowResize = () => {
        if (document.getElementsByClassName(cx('tableHeaderRow')).length !== 0) {
            this.refCallback(document.getElementsByClassName(cx('tableHeaderRow'))[0]);
        }
    }

    private clearChartAreaAndWell = () => {
        // Remove all time series from well and chart area, and remove all markers
        this.props.clearLineChartMarkers();
        this.props.resetSwimlaneOptions();
        this.props.removeAllTimeSeriesQueries();
        this.props.unstickyTSQ();
        this.props.refreshPreviousSessionState();
        this.props.clearSavedQueryName();
        Utils.usedColors = {};
    }

    removeTimeSeriesQuery = (ts, i) => {
        delete Utils.usedColors[ts.color];
        this.props.closeModal();
        let newStickiedTSQ = Utils.getNewStickiedAggKey(ts, this.props.timeSeriesQueries, this.props.stickiedTSQ);
        this.props.removeTimeSeriesQuery(i);
        if (this.props.stickiedTSQ !== newStickiedTSQ) {
            this.props.stickyTrigger(newStickiedTSQ, '');
        }
    }

    showHideTimeSeriesQuery = (ts, i) => {
        if (ts.data && this.props.stickiedTSQ === ts.data.aggKey && !ts.hidden) {
            this.props.stickyTrigger(null, '');
            this.props.unstickyTSQ();
        }
        this.props.showHideTimeSeriesQuery(i);
    }

    retryGetQueryForIndices(indices) {
        indices.forEach(i => this.props.setTSQField(i, 'isJustAdded', true));
        this.props.getQueryResults();
    }

    private getFieldName = (rawFieldName) => {
        switch (rawFieldName) {
            case 'timeSeriesId': return this.props.t('timeSeriesId');
            case 'timeSeriesName': return this.props.t('timeSeriesName');
            case 'Variable': return this.props.t('tsm.type.variable.title');
            case 'Description': return this.props.t('tsm.description');
            default: return rawFieldName;
        }
    }

    private sortByField = (fieldName) => {
        let isDescending = (fieldName === this.props.tsqSortField) ? !this.props.tsqSortDescending : true;
        if (fieldName === 'swimLane')
            isDescending = true;
        this.props.setTSQSort(fieldName, isDescending);
    }

    private getVisibleFields = () => {
        return Object.keys(this.props.tsqFields).filter(k => this.props.tsqFields[k]);
    }

    private headerWidthStyle = () => {
        if (this.state.tableWidth !== null) {
            return {width: this.state.tableWidth + "px", top: this.state.isRetryBannerVisible ? '32px' : '0px'}
        }
        return {width: "calc(100% - 20px)", top: this.state.isRetryBannerVisible ? '32px' : '0px'};
    }

    private isTsqsWithNodataOrError = () => {return this.props.timeSeriesQueries.filter(ts => ts.noData() || ts.hasError()).length > 0;}
    private isTsqsWith503Error = (tsqs) => {return tsqs.filter(ts => ts.hasError() && (ts.error.code === "TooManyRequests")).length > 0;}

    private getShiftLabel (ts: any) { 
        let rawText = Utils.getTimeShiftLabelFromValue(ts.timeShift, this.props.timezone, ts.startAt);
        let parsedTimeShift = Utils.parseShift(ts.timeShift);
        if (ts.startAt) {
            return `${this.props.t('settingsModal.startAt')} ${rawText}`;
        } else if (parsedTimeShift !== 0) {
            return `${rawText}`
        }
        return this.props.t('settingsModal.none');
    }

    private doesTimeShiftExist = () => {
        return this.props.timeSeriesQueries.some((tsq: TimeSeriesQuery) => {
            let timeShiftExists = tsq?.timeShift && Utils.parseShift(tsq.timeShift) !== 0;
            return timeShiftExists || tsq?.startAt;
        });
    }

    handleScroll = (e) => {
        if(this.activeColorPicker && e.target !== this.activeColorPicker.getColorPickerElem().select('.tsi-colorGrid').node()){
            this.activeColorPicker.hideColorGrid();
        }
    }

    getColor = (ts, idx) => {
        if(ts.dataType === ChartDataTypes.Categorical){
            let categories = Object.keys(ts.valueMapping).sort();
            let linearGradient = `linear-gradient(${categories.map((cat, i) => `${ts.valueMapping[cat].color} ${i*100/categories.length}% ${(i+1)*100/categories.length}%`).join(',')})`;
            return <div style={{background: linearGradient}} className={cx('colorSquare')}></div>
        }
        else {
            return <ColorPickerContainer 
                    className={cx('colorPicker')}
                    defaultColor={ts.color}
                    isColorValueHidden={true}
                    numberOfColors={24}
                    colors={Utils.defaultColors}
                    onSelect={(color) => {
                        this.handleColorChange(idx, color);
                        delete Utils.usedColors[ts.color];
                        Utils.usedColors[color] = true;
                    }}
                    onClick={(e, colorPicker) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if(this.activeColorPicker){
                            this.activeColorPicker.hideColorGrid();
                        }
                        this.activeColorPicker = colorPicker;
                        let targetElement = colorPicker.getColorPickerElem().node();
                        setTimeout(()=>{
                            targetElement.querySelector('.tsi-colorGrid').style.marginTop = -(
                                targetElement.querySelector('.tsi-colorGrid').clientHeight 
                                + targetElement.querySelector('.tsi-colorPickerButton').clientHeight 
                                + document.getElementsByClassName(cx('tableContainer'))[0].scrollTop) +'px';
                        })
                    }}>
                </ColorPickerContainer>;
            }
    }

    handleColorChange = (idx, colorValue) => {
       this.props.setTSQColor(idx, colorValue);
    }

    getVariables = (ts: any) => {
        let variables = null;
        let type = null;
        if (ts.instanceObject.type && ts.instanceObject.type.variables) {
            variables = ts.instanceObject.type.variables;
            type = ts.instanceObject.type;
        } else if (ts.instanceObject.typeId) {
            let matches = this.props.types.filter((type) => {
                return type.id === ts.instanceObject.typeId;
            });    
            if (matches && matches.length) {
                variables = matches[0].variables;
                type = matches[0];
            }
        }
        if (variables) {
            return Utils.createVariablesList(type, this.props.metadata);
        }
        return null;
    }

    createFeedbackLink = (issueText: string, responseText: string, isUpper: boolean) => {
        return <span className={cx('let-us-know')}>
            <button 
                aria-label={this.props.t('well.letUsKnow')} 
                onClick={() => this.props.openFeedbackForm( 
                    issueText, 
                    null, 
                    responseText)}> 
                {this.props.t(isUpper ? 'well.letUsKnowUpper' : 'well.letUsKnow')}
            </button>
        </span>
    }

    showClearAllModal = (show: boolean) => this.setState({ isClearAllModalVisible: show });

    renderClearAllModal = () => {
        return <ModalContainer 
            sourceElement={this.clearAllButtonRef.current}
            onClose={() => this.showClearAllModal(false) } 
            title={this.props.t('well.clearChartArea')}
            titleClassName={cx('clearAllModalTitle')}
            titleIconId={'iconDelete'} >
            <div className={cx('clearAllModalContent')}>
                <div>{this.props.t('well.confirmClearChartArea')}</div>
                <div className={cx('modalActionButtons')}>
                    <button 
                        className={cx('cancelButton')}
                        onClick={() => {
                            this.showClearAllModal(false);
                        }}>
                        {this.props.t('cancel')}
                    </button>
                    <button 
                        className={cx('saveButton')}
                        onClick={() => {
                            this.clearChartAreaAndWell();
                            this.showClearAllModal(false);
                        }}>
                        {this.props.t('confirm')}
                    </button>
                </div>
            </div>
        </ModalContainer>;
    }

    createWell = (tsqSortField, headerWidth) => {
        let visibleFields = this.getVisibleFields();
        let sortDirection = this.props.tsqSortDescending ? 'Down' : 'Up';
        let timeShiftExists = this.doesTimeShiftExist();
        let visibleFieldsOffset = this.isTsqsWithNodataOrError() ? (timeShiftExists ? 9 : 8) : (timeShiftExists ? 8 : 7);
        return <div className={cx('wrapper')} onScroll={this.adjustHeaderLeftPosition}>
            {this.state.isClearAllModalVisible && this.renderClearAllModal()}
            {this.state.isRetryBannerVisible && 
                <div className={cx('retryBanner')}>
                    <Icon id={'warningTriangle-' + this.props.theme} className={cx('icon16')}/>
                    <span>{this.props.t('well.retryFailedQueryOr')} 
                        <button onClick={() => {this.retryGetQueryForIndices(this.props.timeSeriesQueries.reduce((result, ts, idx) => {if (ts.hasError()) {result.push(idx);} return result;}, []))}}>{this.props.t('well.refreshAllFailedQuery')}</button> 
                    </span>
                </div>
            }
            <div className={cx('commandButtonContainer')}>
                <button className={cx('commandButtonSmall')} onClick={() => this.showClearAllModal(true)}>
                    <Icon id={'iconDelete'} className={cx('icon16', this.props.theme)} />
                </button>
                <button className={cx('commandButton')}
                    onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        let bcr = (e.target as any).getBoundingClientRect();
                        let focusModalX = bcr.left + (bcr.width / 2)
                        let focusModalY = window.innerHeight - bcr.top;
                        this.props.setModalOptions(null, focusModalX, focusModalY, ModalTypes.EditColumns, true, CaretDirections.Bottom, (e.currentTarget as any));
                    }}>
                    <div className={cx('commandButtonContent')}>
                        <Icon id={'iconEditColumns-' + this.props.theme} className={cx('toggleFieldsIcon')}/>
                        {this.props.t('well.showHideColumns')}
                    </div>
                </button>
            </div>
            
            <div className={cx('_base-wellStyleTable', 'wellHeaderRow')} ref={(element) => this.setLeftScroll(element)} style={headerWidth}>
                <div style={this.headerCellWidth(0)} className={cx('headerCell')}></div><div style={this.headerCellWidth(1)} className={cx('headerCell')}></div><div style={this.headerCellWidth(2)} className={cx('headerCell')}></div><div style={this.headerCellWidth(3)} className={cx('headerCell')}></div><div style={this.headerCellWidth(4)} className={cx('headerCell')}></div>
                {this.isTsqsWithNodataOrError() &&
                    <div style={this.headerCellWidth(5)} className={cx('headerCell')}></div>
                }
                <div style={this.headerCellWidth(this.isTsqsWithNodataOrError() ? 6 : 5)} className={cx('headerCell')}></div>
                {timeShiftExists && <div style={this.headerCellWidth(this.isTsqsWithNodataOrError() ? 7 : 6)} className={cx('headerCell')}>
                    <div className={cx('headerCellNonButton')}>
                        <div>{this.props.t('well.timeShift')}</div>
                    </div>
                </div>}
                <div onClick={() => this.sortByField('swimLane')} className={cx('headerCell', 'swimlaneHeader')}>
                    <button className={cx('headerContainer') + ' ' + (tsqSortField === 'swimLane' ? cx('sortColumn') : '')}>
                        <div>{this.props.t('well.lane')}</div>
                        <Icon id={cx('iconSort' + sortDirection + '-' + this.props.theme)} className={cx('icon')}/>
                    </button>
                </div>        
                {visibleFields.map((k, i) => 
                    <div key={i} onClick={() => this.sortByField(k)} style={this.headerCellWidth(i+visibleFieldsOffset)} className={cx('headerCell')}>
                        <button className={cx('headerContainer') + ' ' + (k === tsqSortField ? cx('sortColumn') : '')}>
                            <div>{this.getFieldName(k)}</div>
                            <Icon id={cx('iconSort' + sortDirection + '-' + this.props.theme)} className={cx('icon')}/>
                        </button>
                    </div>
                )}
                <div className={cx('headerCell')} style={this.headerCellWidth(this.state.widthArray.length - 2)}></div><div className={cx('headerCell')} style={this.headerCellWidth(this.state.widthArray.length - 1)}></div>
            </div>

            <div className={cx('tableContainer', this.state.isRetryBannerVisible ? 'shorterForBanner' : '')} onScroll={this.handleScroll}>
                <table className={cx('_base-wellStyleTable', 'wellContentTable')}>
                    <tbody>

                        {/* hidden actual table headers */}
                        <tr ref={(element) => this.refCallback(element)} className={cx('tableHeaderRow')}>
                            <th className={cx('smallCell')}></th><th className={cx('smallCell')}></th><th className={cx('smallCell')}></th><th className={cx('smallCell')}></th><th className={cx('smallCell')}></th><th className={cx('smallCell')}></th>
                            {this.isTsqsWithNodataOrError() &&
                                <th className={cx('smallCell')}></th>
                            }
                            {timeShiftExists && <th> 
                                <div className={cx('headerContainer')}>
                                    <div>{this.props.t('well.timeShift')}</div>
                                </div>
                            </th>}
                            <th className={cx('swimlaneCell')}></th>
                            {visibleFields.map((k, i) => 
                                <th key={i}>
                                    <div className={cx('headerContainer') + ' ' + (k === tsqSortField ? cx('sortColumn') : '')}>
                                        <div>{this.getFieldName(k)}</div>
                                        <Icon id={cx('iconSort' + sortDirection + '-' + this.props.theme)} className={cx('icon')}/>
                                    </div>
                                </th>
                            )}
                        </tr>

                        {this.props.timeSeriesQueries.map((ts, i) => {
                            let variables = this.getVariables(ts);
                            return <tr key={i} className={cx('tag', ts.hasError() ? 'opaque' : '') + (' tagWell' + i) + ((ts.data && (ts.data.aggKey === this.props.stickiedTSQ) && !ts.hidden) ? ' wellSticky' : '')} 
                                    onMouseOver={() => {this.props.focusTrigger(ts.data && ts.data.aggKey, '')}} 
                                    onMouseOut={() => {this.props.unfocusTrigger()}}
                                    onClick={() => {
                                        if (!ts.hidden && !ts.noData()) this.props.stickyTrigger(ts.data && ts.data.aggKey, '')}}
                                >
                                <td className={cx('smallCell')}>
                                    <button title={this.props.t('well.removeTimeSeries')} onClick={(e) => {e.stopPropagation(); this.removeTimeSeriesQuery(ts, i);}} className={cx('close')}>
                                        <Icon id={'iconDelete'} className={cx('icon16', this.props.theme)} />
                                    </button>
                                </td>
                                <td className={cx('smallCell')}>
                                    <button 
                                        onClick={(e) => {e.stopPropagation(); this.showHideTimeSeriesQuery(ts, i)}} 
                                        className={cx('checkBoxButton')} 
                                        tabIndex={0} 
                                        title={ts.hidden ? this.props.t('well.showTimeSeries') : this.props.t('well.hideTimeSeries')}
                                        role="checkbox" 
                                        aria-checked={!ts.hidden}
                                    >
                                        <Icon id='checkbox' className={cx('icon16') + ' tsiCb ' +  (!ts.hidden ? 'tsiCbSelected' : '')} />
                                    </button>
                                </td>
                                <td className={cx('ellipsisContainer', 'smallCell')}>
                                    <button 
                                        className={cx('settingsButton')} 
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            this.props.setChartSettingsToSeries(ts, i);
                                        }}
                                        title={this.props.t('well.showSettingsForTimeSeries')}
                                    >
                                        <Icon id={'iconGear-' + this.props.theme} className={cx('icon16')} />
                                    </button>
                                </td>
                                <td className={cx('ellipsisContainer', 'smallCell')}>
                                    <button 
                                        className={cx('settingsButton')} 
                                        onClick={(e) => { 
                                            e.stopPropagation();
                                            this.props.duplicateTimeSeries(i, ts);
                                        }}
                                        title={this.props.t('well.duplicateTimeSeries')}
                                    >
                                        <Icon id={'duplicateTimeseries'} className={cx('icon16')} />
                                    </button>
                                </td>
                                <td className={cx('ellipsisContainer', 'smallCell')}>
                                    <button 
                                        className={cx('settingsButton')} 
                                        onClick={(e) => { 
                                            e.stopPropagation();
                                            this.props.setTSIDforLookup(ts.instanceObject.timeSeriesId);
                                        }}
                                        title={this.props.t('well.showTimeSeriesInHierarchy')}
                                    >
                                        <Icon id={'lookup'} className={cx('icon16')} />
                                    </button>
                                </td>
                                {ts.noData() || ts.hasError() ?
                                    <td className={cx('alertField') + ' ' + cx('smallCell')}>
                                        {ts.hasError() ? (ts.error.code === "TooManyRequests" ? 
                                            <button className={cx('retryButton')} onClick={() => {
                                                this.retryGetQueryForIndices([i]);
                                            }} >
                                                <Icon id={'refresh-' + this.props.theme} className={cx('icon16')} title={this.props.t('retry')} ariaLabel={this.props.t('retry')}/>
                                            </button>
                                            :
                                            <div className={cx('noDataAlert')} title={ts.error.message}>
                                                <Icon id={'iconInfo-' + this.props.theme} className={cx('icon16')} />
                                                <span className={cx('text')}>
                                                    {ts.error.message}
                                                    <br/>
                                                    {this.createFeedbackLink(this.props.t('well.feedbackFormIssue'), ts.error.message, true)}
                                                </span>
                                            </div>)
                                        :
                                            <div className={cx('noDataAlert')}>
                                                <Icon id={'iconInfo-' + this.props.theme} className={cx('icon16')} />
                                                <span className={cx('text')}>
                                                    {this.props.t('noTsData')}
                                                    <br/>
                                                    <a target='_blank' rel="noopener noreferrer" href='https://aka.ms/tsinodataavailable'>{this.props.t('learnMore')}</a> {this.props.t('or')} {this.createFeedbackLink(this.props.t('well.feedbackFormIssue'), this.props.t('noTsData'), false)}
                                                </span>
                                            </div>
                                        }
                                    </td>
                                : this.isTsqsWithNodataOrError() && <td></td>}
                                
                                <td className={cx('smallCell', 'centerAligned')}>
                                    {this.getColor(ts, i)}
                                </td>
                                {timeShiftExists && <td>
                                    {this.getShiftLabel(ts)}
                                </td>}
                                <td className={cx('swimlaneCell')}>
                                    {Utils.wellSwimLanes.map((swimLane, swimLaneI) => 
                                        <button aria-label={`${this.props.t('well.selectSwimLane')} ${swimLane}`} key={swimLaneI} className={cx('swimlaneSelectionBox')} onClick={(e) => {this.props.setSwimLane(i, swimLane); e.stopPropagation();}}>
                                            <Icon id={`checkCircle-` + this.props.theme} className={cx('swimlaneCheckedIcon', {selected: (ts.swimLane === swimLane)})}></Icon>
                                        </button>
                                    )}
                                </td>
                                {visibleFields.map((k, j) => (k !== 'Variable' || variables === null) ? 
                                    <td key={j}> 
                                        {this.props.isLoadingSavedTsq ?
                                            <span className={cx('shimmer-bar')}></span>
                                            : k === 'timeSeriesId' ? 
                                                Utils.getFormattedHtml(Utils.getTSQProperty(ts, k), {monoClassName: cx('_base_mono')}) 
                                                : Utils.getTSQProperty(ts, k)
                                        }
                                    </td> : 
                                    <td key={j}> 
                                        <select aria-label={this.props.t('selectVariable')} className={cx('variableDropdown')} value={variables?.map(v => v.name)?.indexOf(Utils.getTSQProperty(ts, k))} onChange={(d) => this.props.setTSQVariable(i, ts, variables[d.target.value])}>
                                            {variables.map((v: any, vI: number) => 
                                                <option value={vI} key={vI}>{v.name}</option>
                                            )}
                                        </select>
                                    </td>
                                    )
                                }
                            </tr>;
                        })}
                    </tbody>
                </table>
            </div>
        </div>;
    }
}

export default Well;

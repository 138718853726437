import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import Modal from './Modal';
import { getTheme } from '../../state/reducers/AppSettingsReducer';
import { getModalOptions } from '../../state/reducers/AnalyticsReducer';

const mapStateToProps = state => {
    return { 
        theme: getTheme(state),
        modalOptions: getModalOptions(state)
    };
};

const ModalContainer = translate()(connect(mapStateToProps)(Modal));

export default ModalContainer;

import * as React from 'react';
import classNames from 'classnames/bind';
const cx = classNames.bind(require('./Placeholder.module.scss'));

interface Props { visible?: boolean; className?: string; progress?: number} 
interface State { displayNone: boolean; }

export default class Placeholder extends React.Component<Props, State> {
    
    static defaultProps = {visible: true}; 

    render() {
        return (<div className={cx('wrapper', this.props.className, {visible: this.props.visible}, {hidden: !this.props.visible})}>
            {this.props.children && 
            <div>
                {this.props.children}
                {(this.props.progress && this.props.progress > 0) ? 
                <div className={cx('progressOuter')}>
                    <div className={cx('progressInner', (this.props.progress < 5 ? 'noTransition' : ''))} style={{width: this.props.progress + '%'}}></div>
                </div>: ''} 
            </div>
            }
        </div>);
    }
}
import classNames from 'classnames/bind';
import React, { useState } from 'react';
import { AnalyticsCommandModals } from '../../../../constants/Enums';
import Icon from '../../Icon/Icon';
import ModalContainer from '../../Modal/Modal.container';
import {LetUsKnowLink} from '../AnalyticsCommandBar';
const cx = classNames.bind(require('./Share.module.scss'));

interface Props{
    setOpenModal: (modal: AnalyticsCommandModals, isOpen: boolean) => any;
    theme: string;
    t: any;
    shareButtonRef: any;
    shortlinkUrl: string;
    shortlinkError: string;
    openFeedbackForm: any;
}

const Share: React.FunctionComponent<Props> = (props) => {

    const [copied, setCopied] = useState(false);

    const shortlinkCopy =  () => {
        let copyText: HTMLInputElement = document.getElementById(cx('copyShortlinkButton')) as HTMLInputElement;
        copyText.select();      
        document.execCommand("copy");
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 3000);
        window.getSelection().removeAllRanges();
    }

    return(
        <ModalContainer contentClassName={cx('modalContent')} titleIconId={'link-'+props.theme} 
            onClose={() => props.setOpenModal(AnalyticsCommandModals.shareModal, false)} sourceElement={props.shareButtonRef.current}
            title={props.t('shareQueryTitle')}>
            <div className={cx('shareQueryWrapper', 'queriesModalContent')}>
                <div className={cx('linkInstructions')}>{props.t('shareQueryPrompt')}</div>
                <input className={cx('linkInput')} id={cx('copyShortlinkButton')} readOnly value={props.shortlinkUrl ? props.shortlinkUrl : ''} 
                    placeholder={(!props.shortlinkUrl && !props.shortlinkError) ? props.t('saveOpenShare.loading') : ''}/>
                <div className={cx('copyButtonContainer')}>
                    <button className={cx('copyButton')} onClick={shortlinkCopy} disabled={!props.shortlinkUrl}>
                        {props.t('copyLink')}
                    </button>
                    {copied && <span role="alert" className={cx('_base-fade-in')}>
                        {props.t('copied')}
                    </span>}
                </div>
                {props.shortlinkError && <ShareQueryErrorMessage {...props} message={props.shortlinkError}/> }
            </div>
        </ModalContainer>
    )
}

const ShareQueryErrorMessage = (props) => {
    return <div className={cx('statusMessage', 'error')}>
        <Icon className={cx('icon16')} id={'iconStatusError-' + props.theme}></Icon>
        <div>
            {props.t('saveOpenShare.shareError')}: {props.message + ' '} 
            <LetUsKnowLink {...props} issue={props.t('saveOpenShare.shareError')} />
        </div>
    </div>
}

export default Share;
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import Searchbox from './Searchbox';
import { getTheme } from '../../state/reducers/AppSettingsReducer';

const mapStateToProps = state => ({ 
  theme: getTheme(state)
});

const SearchboxContainer = translate()(connect(mapStateToProps)(Searchbox));

export default SearchboxContainer;
import * as React from 'react';
import classNames from 'classnames/bind';
import NewFeatureSplashContainer from '../../NewFeatureSplash.container';
import Icon from '../../../Icon/Icon';
import { LocalStorageKeys } from '../../../../../constants/Enums';
const cx = classNames.bind(require('./TsmSplash.module.scss'));

interface Props { t: any; theme: string; hideTsmSplash: any;} 
interface State {}

export default class TsmSplash extends React.Component<Props, State> {
    private firstLinkRef: any;

    constructor(props: Props) { 
        super(props);
        this.firstLinkRef = React.createRef();
    }

    render () {
        return <NewFeatureSplashContainer firstLinkRef={this.firstLinkRef} title={this.props.t('tsmSplash.timeSeriesModels')} hideSplash={this.props.hideTsmSplash} localStorageKey={LocalStorageKeys.TsmSplashSeen}>
                    <div className={cx('suggestion')}>
                        {this.props.t('tsmSplash.createModelPrompt')} {this.props.t('tsmSplash.add')} <a target="_blank" rel="noopener noreferrer" href="https://aka.ms/tsitypes" ref={this.firstLinkRef}>{this.props.t('tsmSplash.types')}</a>, <a target="_blank" rel="noopener noreferrer" href="https://aka.ms/tsihierarchies">{this.props.t('tsmSplash.hierarchies')}</a>, {this.props.t('and')} <a target="_blank" rel="noopener noreferrer" href="https://aka.ms/tsiinstances">{this.props.t('tsmSplash.instances')}</a> {this.props.t('tsmSplash.completeModel')}. {this.props.t('tsmSplash.clickHere')} <a target="_blank" rel="noopener noreferrer" href="https://insights.timeseries.azure.com/demo">{this.props.t('tsmSplash.sampleEnvironment')}</a>
                    </div>
                    <Icon id='tsmSplashInfographic' className={cx('infographic')}></Icon>
                </NewFeatureSplashContainer>
    }
}
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import AdHocQueryBuilder from './AdHocQueryBuilder';
import {ADD_AGGREGATESQUERY, GET_QUERYRESULTS, REMOVE_AGGREGATESQUERY, SERIALIZE_STATE_TO_LOCAL_STORAGE, SET_QUERY_FIELD} from '../../../constants/ActionTypes';
import { getTheme } from '../../state/reducers/AppSettingsReducer';
import { getMetadata, getAggregatesQueries } from '../../state/reducers/AnalyticsReducer';
import { QueryTypes } from '../../../constants/Enums';

const mapStateToProps = state => {
    return { 
        theme: getTheme(state),
        aggregatesQueries: getAggregatesQueries(state),
        metadata: getMetadata(state)
    };
};

const mapDispatchToProps = dispatch => ({ 
    removeAggregatesQuery: (idx) => {
        dispatch({type: REMOVE_AGGREGATESQUERY, payload: idx});
        dispatch({type: SERIALIZE_STATE_TO_LOCAL_STORAGE});
    },
    addAggregatesQuery: (ae) => {
        dispatch({type: ADD_AGGREGATESQUERY, payload: ae});
    },
    getQueryResults: () => dispatch({type: GET_QUERYRESULTS}),
    setAQField: (idx, field, value) => {dispatch({type: SET_QUERY_FIELD, payload: {type: QueryTypes.Aggregates, idx: idx, field: field, value: value}})}
});

const TagExplorerContainer = translate()(connect(mapStateToProps, mapDispatchToProps)(AdHocQueryBuilder));

export default TagExplorerContainer;

import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import Tooltipable from './Tooltipable';
import { getTheme } from '../../state/reducers/AppSettingsReducer';

const mapStateToProps = state => {
    return { 
        theme: getTheme(state),
    };
};

const TooltipableContainer = translate()(connect(mapStateToProps)(Tooltipable));

export default TooltipableContainer;

import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import Model from './Model';
import { getTheme, getSelectedEnvironmentIsLts } from '../../state/reducers/AppSettingsReducer';
import { isTsmModalVisible } from '../../state/reducers/ModelReducer';

const mapStateToProps = state => {
    return { 
        theme: getTheme(state),
        isTsmModalVisible: isTsmModalVisible(state),
        isEnvironmentSupportingTsm: getSelectedEnvironmentIsLts(state)
    };
};

const mapDispatchToProps = dispatch => ({
});

const ModelContainer = translate()(connect(mapStateToProps, mapDispatchToProps)(Model));

export default ModelContainer;

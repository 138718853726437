import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { getTheme } from '../../state/reducers/AppSettingsReducer';
import { getNotificationHistory, getUnseenNotifications } from '../../state/reducers/AppReducer';
import AppNotificationHistory from './AppNotificationHistory';
import { OPEN_FEEDBACK_FORM } from '../../../constants/ActionTypes';

const mapStateToProps = state => ({
    theme: getTheme(state),
    notifications: getNotificationHistory(state),
    unseenNotifications: getUnseenNotifications(state)
});

const mapDispatchToProps = dispatch => ({
    openFeedbackForm: (issue: string, requestId: string, responseText: string) => dispatch({ 
        type: OPEN_FEEDBACK_FORM,
        payload: { issue, requestId, responseText }
    })
});

const AppNotificationHistoryContainer = translate()(connect(mapStateToProps, mapDispatchToProps)(AppNotificationHistory));

export default AppNotificationHistoryContainer;

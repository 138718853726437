import { put, takeLatest, call, delay, takeEvery } from "redux-saga/effects";
import { GET_REFERENCE_DATA_SETS, GET_REFERENCE_DATA_SETS_FULFILLED, GET_REFERENCE_DATA_SET_ITEMS, GET_REFERENCE_DATA_SET_ITEMS_FULFILLED, 
        PUT_REFERENCE_DATA_SET_ITEMS, PUT_REFERENCE_DATA_SET_ITEMS_FULFILLED, DELETE_REFERENCE_DATA_SET, DELETE_REFERENCE_DATA_SET_FULFILLED, 
        PUT_REFERENCE_DATA_SET, PUT_REFERENCE_DATA_SET_FULFILLED, SET_BULK_UPLOAD_REFERENCE_DATA_ITEMS_PROGRESS, UPDATE_BULK_UPLOAD_REFERENCE_DATA_ITEMS_PROGRESS } from "../../constants/ActionTypes";
import AuthService from "../services/AuthService";
import Utils from "../services/Utils";
import { Action } from "../../constants/Interfaces";

function* fetchReferenceDataSets(action: Action) {
    try {
        const {resourceId, dataSetNameToSetAfter} = action.payload;
        const token = yield call(AuthService.getManagementTokenPromise);
        const response = yield Utils.tsiClient.server.getReferenceDatasets(token, resourceId);
        yield put({type: GET_REFERENCE_DATA_SETS_FULFILLED, payload: {referenceDataSets: response?.value, dataSetNameToSetAfter}});
    } catch (e:any) {
       yield put({type: GET_REFERENCE_DATA_SETS_FULFILLED, payload: JSON.parse(e.response)});
    }
}

function* fetchReferenceDataSetItems(action: Action) {
    try {
        const {envFqdn, dataSetName} = action.payload; 
        const token = yield call(AuthService.getTsiTokenPromise);
        const response = yield Utils.tsiClient.server.getReferenceDatasetRows(token, envFqdn, dataSetName);
        yield put({type: GET_REFERENCE_DATA_SET_ITEMS_FULFILLED, payload: {referenceDataSetItems: response}});
    } catch (e:any) {
       yield put({type: GET_REFERENCE_DATA_SET_ITEMS_FULFILLED, payload: JSON.parse(e.response)});
    }
}

function* putReferenceDataSetItems(action: Action) {
    try {
        const {items, envFqdn, dataSetName, onProgress} = action.payload; 
        const token = yield call(AuthService.getTsiTokenPromise);
        const response = yield Utils.tsiClient.server.postReferenceDatasetRows(token, envFqdn, dataSetName, items, p => onProgress(p));
        yield put({type: PUT_REFERENCE_DATA_SET_ITEMS_FULFILLED, payload: response});
    } catch (e:any) {
       yield put({type: PUT_REFERENCE_DATA_SET_ITEMS_FULFILLED, payload: JSON.parse(e.response)});
    }
}

function* putReferenceDataSet(action: Action) {
    try {
        const {resourceId, dataSetName} = action.payload;
        let {newDataSet} = action.payload;
        
        const token = yield call(AuthService.getManagementTokenPromise);
        const environment = yield call(() => Utils.tsiClient.server.getGen1Environment(token, resourceId));
        newDataSet.location = environment.location;
        const putReferenceDataResponse = yield call(() => Utils.tsiClient.server.putReferenceDataSet(token, resourceId, dataSetName, newDataSet));
        yield put({type: PUT_REFERENCE_DATA_SET_FULFILLED, payload: {referenceDataSet: putReferenceDataResponse}});
        yield delay(1500);
        yield put({type: PUT_REFERENCE_DATA_SET_ITEMS, payload: action.payload});    
    } catch (e:any) {
       yield put({type: PUT_REFERENCE_DATA_SET_FULFILLED, payload: JSON.parse(e.response)});
    }
}

function* deleteReferenceDataSet(action: Action) {
    try {
        const {resourceId, dataSetName} = action.payload; 
        const token = yield call(AuthService.getManagementTokenPromise);
        const response = yield Utils.tsiClient.server.deleteReferenceDataSet(token, resourceId, dataSetName);
        yield put({type: DELETE_REFERENCE_DATA_SET_FULFILLED, payload: {referenceDataSetItems: response}});
        yield put({type: GET_REFERENCE_DATA_SETS, payload: {resourceId}});
    } catch (e:any) {
       yield put({type: DELETE_REFERENCE_DATA_SET_FULFILLED, payload: JSON.parse(e.response)});
    }
}

function* updateBulkUploadProgress(action: Action) {
    yield put({type: SET_BULK_UPLOAD_REFERENCE_DATA_ITEMS_PROGRESS, payload: action.payload});
}

export default function* getReferenceDataSaga() {
    yield takeLatest(GET_REFERENCE_DATA_SETS, fetchReferenceDataSets);
    yield takeLatest(GET_REFERENCE_DATA_SET_ITEMS, fetchReferenceDataSetItems);
    yield takeLatest(PUT_REFERENCE_DATA_SET_ITEMS, putReferenceDataSetItems);
    yield takeLatest(DELETE_REFERENCE_DATA_SET, deleteReferenceDataSet);
    yield takeLatest(PUT_REFERENCE_DATA_SET, putReferenceDataSet);
    yield takeEvery(UPDATE_BULK_UPLOAD_REFERENCE_DATA_ITEMS_PROGRESS, updateBulkUploadProgress);
}
import React from "react";
import ModalContainer from "../../Modal/Modal.container";
import {ProgressMessageField} from "./ProgressMessageField";
import TooltipableContainer from "../../Tooltipable/Tooltipable.container";
import classNames from 'classnames/bind';
import update from 'immutability-helper';
import Icon from "../../Icon/Icon";
import Utils from "../../../services/Utils";
import { VariableCategory } from "../../../models/VariableCategory";
import { KeyCodes, MetadataPropertyTypes, TSMCategoricalValueKind } from "../../../../constants/Enums";
import Header from "./Header";
import ColorPickerContainer from "../../ColorPicker/ColorPicker.container";
import { VariableAggregationOperations_Aggregate, VariableAggregationOperations_Numeric, VariableAggregationOperations_Numeric_Interpolation, VariableAggregationOperations_Numeric_NonInterpolation, VariableInterpolationBoundarySpan_Default, VariableInterpolationKinds, VariableInterpolationKinds_Categorical, VariableKinds } from "../../../../constants/Constants";
const cx = classNames.bind(require('../TsmModal.module.scss'));

interface Props {variable: any; tsmModalComponent: any; idx: number; }

export default class TypeVariableModal extends React.Component<Props> {
    private componentGUID;
    private interpolationBoundarySpanNumber;
    private interpolationBoundarySpanKind;
    private advancedOptionsButtonRef;
    private learnMoreRef;
    private progressMessageFieldID = Utils.customID('progressMessageFieldID');

    constructor(props: Props) {
        super(props);
        this.componentGUID = Utils.guid();
        this.interpolationBoundarySpanNumber = Utils.guid();
        this.interpolationBoundarySpanKind = Utils.guid();
        this.advancedOptionsButtonRef = React.createRef();
        this.learnMoreRef = React.createRef();
    }

    private advancedForwardTabFunc = (e) => {
        if (e.keyCode === KeyCodes.Tab && e.shiftKey === false) {
            if(!this.props.tsmModalComponent.state.isAdvancedOptionsExpanded) {
                e.preventDefault();
                this.learnMoreRef && this.learnMoreRef.current && this.learnMoreRef.current.focus();
            }
        }
    }

    private learnMoreBackFunc = (e) => {
        if (e.keyCode === KeyCodes.Tab && e.shiftKey === true) {
            if(!this.props.tsmModalComponent.state.isAdvancedOptionsExpanded) {
                e.preventDefault();
                this.advancedOptionsButtonRef && this.advancedOptionsButtonRef.current && this.advancedOptionsButtonRef.current.focus();
            }
        }
    }

    render () {
        this.props.variable.updateErrors();
        const tsmModalComponent = this.props.tsmModalComponent;

        return (
            tsmModalComponent.state.subEntityCallFromInline ? // variable modal opened from the variables list with the inline edit button
                <ModalContainer 
                    onClose={tsmModalComponent.closeModal} 
                    title={this.props.idx !== null ? tsmModalComponent.props.t('tsm.edit') + " " + tsmModalComponent.state.entity.variables[this.props.idx].name : tsmModalComponent.props.t('tsm.type.newVariable')} 
                    className={cx('tsmEntityModal')} 
                    contentPositionContainerClassName={cx('tsmModalContentPositionContainer', tsmModalComponent.state.isAdvancedOptionsExpanded ? 'autoHeight' : '')} 
                    wrapperClassName={cx('tsmModalContentContainer')}
                    contentClassName={cx('tsmModalContent')} 
                    isOffModalAutoClosingEnabled={false}>
                    <div className={cx('modalContent')}>
                        <TypeVariableForm variable={this.props.variable} tsmModalComponent={tsmModalComponent} 
                                            interpolationBoundarySpanNumber={this.interpolationBoundarySpanNumber} interpolationBoundarySpanKind={this.interpolationBoundarySpanKind} 
                                            advancedOptionsButtonRef={this.advancedOptionsButtonRef} advancedForwardTabFunc={this.advancedForwardTabFunc} componentGUID={this.componentGUID}/>
                        <div className={cx('tsmModalBottom')}>
                        <a ref={this.learnMoreRef} onKeyDown={this.learnMoreBackFunc} className={cx('learnMore')} target="_blank" rel="noopener noreferrer" href="https://aka.ms/tsitypevariables">{tsmModalComponent.props.t('learnMore')}</a>
                            <div className={cx('buttonWrapper')}>
                                <button aria-label={tsmModalComponent.props.t('cancel')} className={cx('cancelButton')} onClick={tsmModalComponent.closeModal}>{tsmModalComponent.props.t('cancel')}</button>
                                {this.props.variable.hasErrors() ?
                                    <TooltipableContainer tooltip={tsmModalComponent.props.t('tsm.fixErrorsToSave')} position={'top'}>
                                        <button className={cx('saveButton', 'disabled', Utils.isMigrationEnabledAndNeededForVariables(tsmModalComponent.props.types, tsmModalComponent.props.isUserContributor) ? 'secondary' : '')} aria-disabled="true" disabled>{tsmModalComponent.props.t('save')}</button>
                                    </TooltipableContainer>
                                :
                                    <button aria-label={tsmModalComponent.props.t('save')} className={cx('saveButton', Utils.isMigrationEnabledAndNeededForVariables(tsmModalComponent.props.types, tsmModalComponent.props.isUserContributor) ? 'secondary' : '')} onKeyDown={tsmModalComponent.applySubEntity} onClick={tsmModalComponent.applySubEntity} aria-describedby={this.progressMessageFieldID}>{tsmModalComponent.props.t('save')}</button>
                                }
                            </div>
                        </div>
                        <ProgressMessageField
                            cx={cx} 
                            entityType={tsmModalComponent.props.entityType} 
                            progress={tsmModalComponent.props.progress} 
                            errorCode={tsmModalComponent.props.errorCode} 
                            errorMessage={tsmModalComponent.props.errorMessage} 
                            method={tsmModalComponent.props.requestMethod} 
                            theme={tsmModalComponent.props.theme} 
                            t={tsmModalComponent.props.t}
                            progressMessageFieldID={this.progressMessageFieldID}
                            migrationNeeded={Utils.isMigrationEnabledAndNeededForVariables(tsmModalComponent.props.types, tsmModalComponent.props.isUserContributor)}
                            showMigrationActionHandler={tsmModalComponent.switchToMigrationModal}/>
                    </div>
                </ModalContainer>
            :
            <ModalContainer // variable modal opened from type modal as second level slider
                onClose={() => tsmModalComponent.setSubEntityIndexToEdit(null)} 
                title={this.props.idx !== null && tsmModalComponent.state.entity.variables[this.props.idx].name ? tsmModalComponent.props.t('tsm.edit') + " " + tsmModalComponent.state.entity.variables[this.props.idx].name : tsmModalComponent.props.t('tsm.type.newVariable')} 
                titleIconId='caret-left' 
                titleIconLabel={tsmModalComponent.props.t('back')} 
                onTitleIcon={() => tsmModalComponent.setSubEntityIndexToEdit(null)} 
                className={cx('tsmEntityModal')} 
                contentPositionContainerClassName={cx('tsmModalContentPositionContainer', tsmModalComponent.state.isAdvancedOptionsExpanded ? 'autoHeight' : '')} 
                wrapperClassName={cx('tsmModalContentContainer', 'slideLeft')} 
                contentClassName={cx('tsmModalContent')} 
                isOffModalAutoClosingEnabled={false}>
                <div className={cx('modalContent')}>
                    <TypeVariableForm variable={this.props.variable} tsmModalComponent={tsmModalComponent} interpolationBoundarySpanNumber={this.interpolationBoundarySpanNumber} 
                                        interpolationBoundarySpanKind={this.interpolationBoundarySpanKind} advancedOptionsButtonRef={this.advancedOptionsButtonRef} 
                                        advancedForwardTabFunc={this.advancedForwardTabFunc} componentGUID={this.componentGUID}/>
                    <div className={cx('tsmModalBottom')}>
                    <a ref={this.learnMoreRef} onKeyDown={this.learnMoreBackFunc} className={cx('learnMore')} target="_blank" rel="noopener noreferrer" href="https://aka.ms/tsitypevariables">{tsmModalComponent.props.t('learnMore')}</a>
                        <div className={cx('buttonWrapper')}>
                            <button aria-label={tsmModalComponent.props.t('back')} className={cx('cancelButton')} onClick={() => tsmModalComponent.setSubEntityIndexToEdit(null)}>{tsmModalComponent.props.t('back')}</button>
                            {this.props.variable.hasErrors() ?
                                <TooltipableContainer tooltip={tsmModalComponent.props.t('tsm.fixErrorsToApply')} position={'top'}>
                                    <button className={cx('saveButton', 'disabled', Utils.isMigrationEnabledAndNeededForVariables(tsmModalComponent.props.types, tsmModalComponent.props.isUserContributor) ? 'secondary' : '')} aria-disabled="true" disabled>{tsmModalComponent.props.t('well.apply')}</button>
                                </TooltipableContainer>
                            :
                                <button aria-label={tsmModalComponent.props.t('well.apply')} className={cx('saveButton', Utils.isMigrationEnabledAndNeededForVariables(tsmModalComponent.props.types, tsmModalComponent.props.isUserContributor) ? 'secondary' : '')} onKeyDown={tsmModalComponent.applySubEntity} onClick={tsmModalComponent.applySubEntity}>{tsmModalComponent.props.t('well.apply')}</button>
                            }
                        </div>
                    </div>
                </div>
            </ModalContainer>
        )
    }    
}

const TypeVariableForm = ({variable, tsmModalComponent, interpolationBoundarySpanNumber, interpolationBoundarySpanKind, advancedOptionsButtonRef, advancedForwardTabFunc, componentGUID}) => {
    let activeColorPicker;

    let handleVariableNameChange = (event) => {
        tsmModalComponent.setState(update(tsmModalComponent.state, {subEntity: {name: {$set: event.target.value}}}));
    };

    let handleVariableKindChange = (event) => {
        if (event.target.value === "aggregate") {
            tsmModalComponent.setState(update(tsmModalComponent.state, {subEntity: {kind: {$set: event.target.value}, value: {$set: null}, interpolation: {$set: null}, filter: {$set: null}, aggregation: {$set: {"tsx" : "count()"}}, categories: {$set: null}, defaultCategory: {$set: null}}}));
        } else if (event.target.value === "numeric") {
            tsmModalComponent.setState(update(tsmModalComponent.state, {subEntity: {kind: {$set: event.target.value}, value: {$set: null}, interpolation: {$set: null}, filter: {$set: null}, aggregation: {$set: {"tsx" : "avg($value)"}}, categories: {$set: null}, defaultCategory: {$set: null}}}));
        } else {
            tsmModalComponent.setState(update(tsmModalComponent.state, {subEntity: {
                kind: {$set: event.target.value}, 
                aggregation: {$set: null}, 
                categories: {$set: [new VariableCategory()]}, 
                interpolation: {$set: {"kind": VariableInterpolationKinds_Categorical[0].toLowerCase(), "boundary": {"span": VariableInterpolationBoundarySpan_Default}}},
                value: {$set: null},
                filter: {$set: null}}}));
        }
    };

    let handleVariableValueChange = (event) => {
        if (event.target.value === "") {
            tsmModalComponent.setState(update(tsmModalComponent.state, {subEntity: {value: {$set: null}, categoryValueKind: {$set: TSMCategoricalValueKind.Integer}, filter: {$set: null}}}));
        } else {
            if (tsmModalComponent.state.subEntity.kind === 'categorical' && !tsmModalComponent.state.subEntity.isValueFreeform) {
                tsmModalComponent.setState(update(tsmModalComponent.state, {subEntity: {
                    value: {$set: {"tsx" : event.target.value}}, 
                    categoryValueKind: {$set: event.target.selectedOptions[0].getAttribute("md-type") === MetadataPropertyTypes.Double || event.target.selectedOptions[0].getAttribute("md-type") === MetadataPropertyTypes.Long ? TSMCategoricalValueKind.Integer : TSMCategoricalValueKind.String},
                    filter: {$set: {"tsx": "(" + event.target.value + ") != null"}}}}));
            } else {
                tsmModalComponent.setState(update(tsmModalComponent.state, {subEntity: {value: {$set: {"tsx" : event.target.value}}, categoryValueKind: {$set: TSMCategoricalValueKind.Integer}}}));
            }
        }
    };

    let handleVariableFilterChange = (event) => {
        if (event.target.value === "") {
            tsmModalComponent.setState(update(tsmModalComponent.state, {subEntity: {filter: {$set: null}}}));
        } else {
            tsmModalComponent.setState(update(tsmModalComponent.state, {subEntity: {filter: {$set: {"tsx" : event.target.value}}}}));
        }
    };

    let handleVariableInterpolationKindChange = (event) => {
        if (event.target.value === "") {
            tsmModalComponent.setState(update(tsmModalComponent.state, {subEntity: {interpolation: {$set: null}}}));
        } else {
            if (tsmModalComponent.state.subEntity.interpolation){
                if (tsmModalComponent.state.subEntity.kind === 'numeric') {
                    tsmModalComponent.setState(update(tsmModalComponent.state, {subEntity: {interpolation: {kind: {$set: event.target.value}}, isAggregationOperationFreeform: {$set: false}}}));
                } else {
                    tsmModalComponent.setState(update(tsmModalComponent.state, {subEntity: {interpolation: {kind: {$set: event.target.value}}}}));
                }
            } else {
                if (tsmModalComponent.state.subEntity.kind === 'numeric') {
                    tsmModalComponent.setState(update(tsmModalComponent.state, {subEntity: {interpolation: {$set: {"kind": event.target.value, "boundary": null}}, isAggregationOperationFreeform: {$set: false}}}));
                } else {
                    tsmModalComponent.setState(update(tsmModalComponent.state, {subEntity: {interpolation: {$set: {"kind": event.target.value, "boundary": null}}}}));
                }
            }
        }
    };

    let handleVariableInterpolationBoundaryChange = (e) => {
        let selectedSpanNumber = (document.getElementById(interpolationBoundarySpanNumber) as HTMLInputElement).value;
        let selectedSpanKind = (document.getElementById(interpolationBoundarySpanKind)  as HTMLSelectElement).value;

        if (selectedSpanNumber) {
            let span = Utils.tsiClient.utils.bucketSizeToTsqInterval(selectedSpanNumber + selectedSpanKind);
            if (tsmModalComponent.state.subEntity.interpolation){
                tsmModalComponent.setState(update(tsmModalComponent.state, {subEntity: {interpolation: {boundary: {$set: {span: span}}}}}));
            } else {
                tsmModalComponent.setState(update(tsmModalComponent.state, {subEntity: {interpolation: {$set: {"kind": null, "boundary": {"span": span}}}}}));
            }
        } else {
            tsmModalComponent.setState(update(tsmModalComponent.state, {subEntity: {interpolation: {boundary: {$set: null}}}}));
        }
    };

    let handleVariableAggregationOperationChange = (event) => {
        tsmModalComponent.setState(update(tsmModalComponent.state, {subEntity: {aggregation: {$set: {"tsx" : event.target.value}}}}));
    };

    let variableValueBoxStyleToggle = (e) => {
        if (Utils.isKeyDownAndNotEnter(e)) {return; }
        tsmModalComponent.setState(update(tsmModalComponent.state, {subEntity: {isValueFreeform: {$set: !tsmModalComponent.state.subEntity.isValueFreeform}}}));
    };

    let variableAggregationOperationBoxStyleToggle = (e) => {
        if (Utils.isKeyDownAndNotEnter(e)) {return; }
        e.stopPropagation();
        tsmModalComponent.setState(update(tsmModalComponent.state, {subEntity: {isAggregationOperationFreeform: {$set: !tsmModalComponent.state.subEntity.isAggregationOperationFreeform}}}));
    };

    let toggleAdvancedOptions = (e) => {
        if (Utils.isKeyDownAndNotEnter(e)) {return; }
        tsmModalComponent.setState({isAdvancedOptionsExpanded: !tsmModalComponent.state.isAdvancedOptionsExpanded});
    }

    let addCategory = (e) => {
        if (Utils.isKeyDownAndNotEnter(e)) {return; }
        let varCat = new VariableCategory();
        if (!tsmModalComponent.state.subEntity.categories)
            tsmModalComponent.setState(update(tsmModalComponent.state, {subEntity: {categories: {$set: [varCat]}}}));
        else 
            tsmModalComponent.setState(update(tsmModalComponent.state, {subEntity: {categories: {$push: [varCat]}}}));
    };

    let removeCategory = (idx) => (e) => {
        if (tsmModalComponent.state.subEntity.categories.length === 1){
            tsmModalComponent.setState(update(tsmModalComponent.state, {subEntity: {$unset: ["categories"]}}));
        } else {
            tsmModalComponent.setState(update(tsmModalComponent.state, {subEntity: {categories: {$splice: [[idx, 1]]}}}));
        }
    }

    let handleVariableDefaultCategoryLabelChange = (e) => {
        if (e.target.value === "") {
            tsmModalComponent.setState(update(tsmModalComponent.state, {subEntity: {defaultCategory: {$set: null}}}));
        } else {
            if (!tsmModalComponent.state.subEntity.defaultCategory)
                tsmModalComponent.state.subEntity.addDefaultCategory();
            tsmModalComponent.setState(update(tsmModalComponent.state, {subEntity: {defaultCategory: {label : {$set: e.target.value}}}}));
        }
    }

    let handleVariableDefaultCategoryColorChange = (colorValue) => {
        if (!tsmModalComponent.state.subEntity.defaultCategory)
            tsmModalComponent.state.subEntity.addDefaultCategory();
        else {
            if (tsmModalComponent.state.subEntity.defaultCategory.annotations) 
                tsmModalComponent.setState(update(tsmModalComponent.state, {subEntity: {defaultCategory: {annotations: {color: {$set: colorValue}}}}}));
            else
                tsmModalComponent.setState(update(tsmModalComponent.state, {subEntity: {defaultCategory: {annotations: {$set: {color: colorValue}}}}}));
        }
        
    }

    let categoryValueKindToggle = (e) => {
        if (Utils.isKeyDownAndNotEnter(e)) {return; }
        tsmModalComponent.setState(update(tsmModalComponent.state, {subEntity: {categoryValueKind: {$set: tsmModalComponent.state.subEntity.categoryValueKind === TSMCategoricalValueKind.String ? TSMCategoricalValueKind.Integer : TSMCategoricalValueKind.String}}}));
    };

    let handleCategoryLabelChange = (idx) => (e) => {
        tsmModalComponent.setState(update(tsmModalComponent.state, {subEntity: {categories: {[idx]: {label: {$set: e.target.value}}}}}));
    }

    let handleCategoryValuesChange = (idx) => (e) => {
        if (e.target.value === "") {
            tsmModalComponent.setState(update(tsmModalComponent.state, {subEntity: {categories: {[idx]: {values: {$set: []}}}}}));
        } else {
            tsmModalComponent.setState(update(tsmModalComponent.state, {subEntity: {categories: {[idx]: {values: {$set: e.target.value.split(',')}}}}}));
        }
    }

    let handleCategoryColorChange = (idx, colorValue) => {
        if (tsmModalComponent.state.subEntity.categories[idx].annotations)
            tsmModalComponent.setState(update(tsmModalComponent.state, {subEntity: {categories: {[idx]: {annotations: {color: {$set: colorValue}}}}}}));
        else
            tsmModalComponent.setState(update(tsmModalComponent.state, {subEntity: {categories: {[idx]: {annotations: {$set: {color: colorValue}}}}}}));
    }

    let getNumberFromISOFormat = (isoString) => {
        let inputString = isoString.toLowerCase();
        let startAt = inputString.indexOf('pt') !== -1 ? 2 : (inputString.indexOf('p') !== -1 ? 1 : 0);
        return Number(inputString.slice(startAt, inputString.length - 1));
    }

    let getID = (str) => {
        return Utils.customID(str, componentGUID)
    }

    let renderColorPicker = (idx, colorValue, isDefaultCategory = null) => {
        return <ColorPickerContainer 
                    className={cx('colorPicker')} 
                    numberOfColors={tsmModalComponent.state.subEntity.categories && tsmModalComponent.state.subEntity.categories.length ? tsmModalComponent.state.subEntity.categories.length + 15 : 30}
                    defaultColor={colorValue}
                    isColorValueHidden={true}
                    onSelect={colorValue => {
                        if (isDefaultCategory) {
                            handleVariableDefaultCategoryColorChange(colorValue);
                        } else {
                            handleCategoryColorChange(idx, colorValue);
                        }
                    }}
                    onClick={(e, colorPicker) => {
                        activeColorPicker = colorPicker;
                        let targetElement = colorPicker.getColorPickerElem().node();
                        if (isDefaultCategory) {
                            targetElement.querySelector('.tsi-colorGrid').style.top = ((document.getElementsByClassName(cx('block'))[4] as any).offsetTop - document.getElementsByClassName(cx('build-area'))[0].scrollTop + 69) + "px";
                        } else {
                            targetElement.querySelector('.tsi-colorGrid').style.top = ((document.getElementsByClassName(cx('block'))[3] as any).offsetTop - document.getElementsByClassName(cx('build-area'))[0].scrollTop + targetElement.parentElement.parentElement.offsetTop + 69) + "px";
                        }
                    }}>
                </ColorPickerContainer>
    }

    let handleScroll = () => {
        if (activeColorPicker) activeColorPicker.hideColorGrid();
    }

    return (
        <div className={cx('subEntityContainer')}>
            <div className={cx('form')}>
                <div className={cx('build-area', 'variable')} onScroll={handleScroll}>
                    <div className={cx('block')}>
                        <div id={getID('variableNameLabel')} className={cx('title')}>{tsmModalComponent.props.t('tsm.name')}</div> 
                        <input aria-labelledby={getID('variableNameLabel')} aria-describedby={getID('variableNameErrorLabel') + " " + getID('variableNameUniqueErrorLabel')} className={cx(variable.errors?.name || variable.errors?.name_unique ? 'error' : '')} type="text" value={variable.name} onChange={handleVariableNameChange}/>
                        {variable.errors?.name && <div id={getID('variableNameErrorLabel')} role="alert" className={cx('error-message', '_base-fade-in')}>{tsmModalComponent.props.t('tsm.type.validations.variable.name')}</div>}
                        {variable.errors?.name_unique && <div id={getID('variableNameUniqueErrorLabel')} role="alert" className={cx('error-message', '_base-fade-in')}>{tsmModalComponent.props.t('tsm.type.validations.variable.name_unique')}</div>}
                    </div>
                    <div className={cx('block')}>
                        <div id={getID('variableKindLabel')} className={cx('title')}>{tsmModalComponent.props.t('tsm.type.variable.kind')}</div>
                        <select aria-labelledby={getID('variableKindLabel')} aria-describedby={getID('variableKindErrorLabel')} className={cx(variable.errors?.kind ? 'error' : '')} value={variable.kind ? variable.kind : ""} onChange={handleVariableKindChange}>
                            <option key="N/A-kind" value="">{tsmModalComponent.props.t('tsm.defaultOption')}</option>
                            {VariableKinds.map((at, i) => <option key={at} value={at.toLowerCase()}>{at}</option>)}
                        </select>
                        {variable.errors?.kind && <div id={getID('variableKindErrorLabel')} role="alert" className={cx('error-message', '_base-fade-in')}>{tsmModalComponent.props.t('tsm.type.validations.variable.kind')}</div>}
                    </div>
                    <div className={cx('block')}>
                        <div id={getID('valueLabel')} className={cx('title')}>{tsmModalComponent.props.t('tsm.type.variable.value')}</div>
                        {variable.kind === "aggregate" ? 
                            <div className={cx('info-text', '_base-fade-in')} aria-readonly={true}>{tsmModalComponent.props.t('tsm.type.validations.variable.valueInvalidForAggregate')}</div>
                            :
                            [<div key="freeFormOptions" className={cx('freeformOptions')}>
                                <div className={cx('radioButtonContainer')} role="radiogroup" aria-labelledby={getID('valueLabel')}>
                                    <div className={cx('_base-radioButton')}>
                                        <input id={getID('quickValueInput')} tabIndex={variable.isValueFreeform ? -1 : 0} type="radio" aria-checked={!variable.isValueFreeform} checked={!variable.isValueFreeform} onChange={variableValueBoxStyleToggle} aria-labelledby={getID('quickValueLabel')}/>
                                        <label tabIndex={-1} htmlFor={getID('quickValueInput')} id={getID('quickValueLabel')} className={cx('checkmark')}>{tsmModalComponent.props.t('tsm.type.variable.quickValueSelection')}</label>
                                    </div>
                                    <div className={cx('_base-radioButton')}>
                                        <input id={getID('customValueInput')} tabIndex={variable.isValueFreeform ? 0 : -1} type="radio" aria-checked={variable.isValueFreeform} checked={variable.isValueFreeform} onChange={variableValueBoxStyleToggle} aria-labelledby={getID('customValueLabel')}/>
                                        <label tabIndex={-1} htmlFor={getID('customValueInput')} id={getID('customValueLabel')} className={cx('checkmark')}>{tsmModalComponent.props.t('tsm.type.variable.editValueInFreeform')}</label>
                                    </div>
                                </div>
                            </div>,
                            variable.isValueFreeform ? 
                                <textarea key="valueText" aria-labelledby={getID('valueLabel')} aria-describedby={getID('variableValueErrorLabel') + " " + getID('variableValueErrorForCountLabel')} placeholder={variable.kind === "categorical" ? "e.g. $event['columnName'].String" : "e.g. $event['columnName'].Double"} className={cx(variable.errors?.value ? 'error' : '')} value={variable.kind === "aggregate" ? "" : (variable.value?.tsx || "")} onChange={handleVariableValueChange} disabled={variable.kind === "aggregate"}/> 
                            :
                                <select key="valueSelect" aria-labelledby={getID('valueLabel')} aria-describedby={getID('variableValueErrorLabel') + " " + getID('variableValueErrorForCountLabel')} className={cx(variable.errors?.value ? 'error' : '')} value={variable.kind === "aggregate" ? "" : (variable.value?.tsx || "")} onChange={handleVariableValueChange} disabled={variable.kind === "aggregate"}>
                                    <option key="N/A-value" value="">{tsmModalComponent.props.t('tsm.defaultOption')}</option>
                                    {Utils.optionsFromMetadata(variable, tsmModalComponent.props.metadata).map(o => 
                                        <option key={o.key} value={o.value} md-type={o.mdType}>{o.text}</option>
                                    )}
                                </select>   
                            ]
                        }

                        {variable.isValueFreeform && variable.kind === "categorical" &&
                        <div className={cx('sub-block')}>
                            <div id={getID('categoryValueKindLabel')} className={cx('sub-title')}>{tsmModalComponent.props.t('tsm.type.variable.kind')}</div>
                            <div className={cx('radioButtonContainer', 'inline')} role="radiogroup" aria-labelledby={getID('categoryValueKindLabel')}>
                                <div className={cx('_base-radioButton')}>
                                    <input id={getID('categoryValueKindIntegerInput')} tabIndex={variable.categoryValueKind === TSMCategoricalValueKind.Integer ? 0 : -1} type="radio" aria-checked={variable.categoryValueKind === TSMCategoricalValueKind.Integer} checked={variable.categoryValueKind === TSMCategoricalValueKind.Integer} onChange={categoryValueKindToggle} aria-labelledby={getID('categoryValueKindIntegerLabel')}/>
                                    <label tabIndex={-1} htmlFor={getID('categoryValueKindIntegerInput')} id={getID('categoryValueKindIntegerLabel')} className={cx('checkmark')}>integer</label>
                                </div>
                                <div className={cx('_base-radioButton')}>
                                    <input id={getID('categoryValueKindStringInput')} tabIndex={variable.categoryValueKind === TSMCategoricalValueKind.String ? 0 : -1} type="radio" aria-checked={variable.categoryValueKind === TSMCategoricalValueKind.String} checked={variable.categoryValueKind === TSMCategoricalValueKind.String} onChange={categoryValueKindToggle} aria-labelledby={getID('categoryValueKindStringLabel')}/>
                                    <label tabIndex={-1} htmlFor={getID('categoryValueKindStringInput')} id={getID('categoryValueKindStringLabel')} className={cx('checkmark')}>string</label>
                                </div>
                            </div>
                        </div>}

                        {variable.errors?.value && <div id={getID('variableValueErrorLabel')} role="alert" className={cx('error-message', '_base-fade-in')}>{tsmModalComponent.props.t('tsm.type.validations.variable.value')}</div>}
                        {variable.errors?.value_count && <div id={getID('variableValueErrorForCountLabel')} role="alert" className={cx('error-message', '_base-fade-in')}>{tsmModalComponent.props.t('tsm.type.validations.variable.value_count')}</div>}
                    </div>

                    {variable.kind === "categorical" &&
                        [<div key={"categories"} className={cx('block', 'nonPositioned')}>
                            <div id={getID('categoriesTitle')} className={cx('title')}>{tsmModalComponent.props.t('tsm.type.variable.categories')}</div>
                            <div className={cx('grid')}>
                                <table aria-labelledby={getID('categoriesTitle')}>
                                    <Header headers={[tsmModalComponent.props.t('tsm.type.variable.label'), tsmModalComponent.props.t('tsm.type.variable.values'), tsmModalComponent.props.t('tsm.type.variable.color'), '']} tsmModalComponent={tsmModalComponent}/>
                                    <tbody>
                                        {variable.categories && variable.categories.map((c, idx) =>
                                            <tr key={idx} ref={el => { if (idx === variable.categories.length - 1) tsmModalComponent.entitiesEnd = el; }}>
                                                <td className={cx('categoryLabel')}> 
                                                    <input aria-label={tsmModalComponent.props.t('tsm.type.variable.category') + (idx + 1) + tsmModalComponent.props.t('tsm.type.variable.label')} aria-describedby={getID('categoryLabelErrorField') + "_" + idx + " " + getID('categoryLabelUniqueErrorField') + "_" + idx} type="text" value={c.label} onChange={handleCategoryLabelChange(idx)}/>
                                                    {c.errors?.label && <div className={cx('relativePosWrapper')}><div id={getID('categoryLabelErrorField') + "_" + idx} role="alert" className={cx('error-message', '_base-fade-in')}>{tsmModalComponent.props.t('tsm.type.validations.variable.category.label')}</div></div>}
                                                    {c.errors?.label_unique && <div className={cx('relativePosWrapper')}><div id={getID('categoryLabelUniqueErrorField') + "_" + idx} role="alert" className={cx('error-message', '_base-fade-in')}>{tsmModalComponent.props.t('tsm.type.validations.variable.category.label_unique')}</div></div>}
                                                </td>
                                                <td> 
                                                    <input aria-label={tsmModalComponent.props.t('tsm.type.variable.category') + (idx + 1) + tsmModalComponent.props.t('tsm.type.variable.values')} aria-describedby={getID('categoryValueErrorField') + "_" + idx} type="text" placeholder={variable.categoryValueKind === TSMCategoricalValueKind.String ? "e.g. Good,Bad" : "e.g. 1,2"} value={c.values.map(a => a === null ? "null" : a)} onChange={handleCategoryValuesChange(idx)}/>
                                                    {c.errors?.values && <div className={cx('relativePosWrapper')}><div id={getID('categoryValueErrorField') + "_" + idx} role="alert" className={cx('error-message', '_base-fade-in')}>{tsmModalComponent.props.t('tsm.type.validations.variable.category.values')}</div></div>}
                                                </td>
                                                <td>
                                                    {renderColorPicker(idx, c.annotations && c.annotations.color ? c.annotations.color : null)}
                                                </td>
                                                <td>
                                                    <button className={cx('action-button')} onClick={removeCategory(idx)} aria-label={tsmModalComponent.props.t('tsm.type.variable.removeCategory')} title={tsmModalComponent.props.t('tsm.type.variable.removeCategory')}><Icon id={"iconClose-" + tsmModalComponent.props.theme} className={cx('icon16')}/></button>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                                {variable.errors?.categories && <div className={cx('relativePosWrapper')}><div className={cx('error-message', '_base-fade-in')} role="alert">{tsmModalComponent.props.t('tsm.type.validations.variable.categories')}</div></div>}
                            </div>
                            <div className={cx('sub-button')}>
                                <button aria-label={tsmModalComponent.props.t('tsm.type.variable.addCategory')} tabIndex={0} onClick={addCategory}><Icon id={'iconAddBlue'} className={cx('icon16')}/><span>{tsmModalComponent.props.t('tsm.type.variable.addCategory')}</span></button>
                            </div>
                        </div>,
                        <div key={"default-category"} className={cx('block', 'nonPositioned')}>
                            <div id={getID('defaultCategoryLabel')} className={cx('title')}>{tsmModalComponent.props.t('tsm.type.variable.defaultCategory')}</div>
                            <div className={cx('defaultCategoryWrapper')}>
                                <input aria-labelledby={getID('defaultCategoryLabel')} aria-describedby={getID('defaultCategoryErrorField')} placeholder="e.g. Label" type="text" value={variable.defaultCategory ? variable.defaultCategory.label : ''} onChange={handleVariableDefaultCategoryLabelChange}/>
                                {renderColorPicker(variable.categories ? variable.categories.length : 0, 
                                    variable.defaultCategory && variable.defaultCategory.annotations && variable.defaultCategory.annotations.color ? variable.defaultCategory.annotations.color : null, 
                                    true)}
                            </div>
                            {variable.errors?.default_category && <div className={cx('relativePosWrapper')}><div id={getID('defaultCategoryErrorField')} role="alert" className={cx('error-message', '_base-fade-in')}>{tsmModalComponent.props.t('tsm.type.validations.variable.default_category')}</div></div>}
                        </div>]
                    }

                    <div className={cx('block', 'advancedOptionsLine')} ref={advancedOptionsButtonRef} role="button" onClick={toggleAdvancedOptions} 
                            onKeyDown={(e) => {advancedForwardTabFunc(e); toggleAdvancedOptions(e);}} tabIndex={0} 
                            aria-label={tsmModalComponent.props.t('tsm.type.variable.advancedOptions')} 
                            aria-describedby={getID('advancedOptionsSummaryField')} aria-controls={getID('advancedOptions')}
                            aria-expanded={tsmModalComponent.state.isAdvancedOptionsExpanded}
                            aria-hidden={!tsmModalComponent.state.isAdvancedOptionsExpanded}>
                        <div className={cx('advancedOptionsButton')}>
                            <Icon id={cx('caret-down')} className={cx('icon16', (tsmModalComponent.state.isAdvancedOptionsExpanded ? 'expanded' : 'collapsed'))}/>
                            <span>{tsmModalComponent.props.t('tsm.type.variable.advancedOptions')}</span>
                        </div>
                        {!tsmModalComponent.state.isAdvancedOptionsExpanded &&
                            <div id={getID('advancedOptionsSummaryField')} className={cx('advancedSummary', '_base-fade-in')}>
                                {[variable.aggregation && variable.aggregation.tsx !== "" ? tsmModalComponent.props.t('tsm.type.variable.aggregationOperation') + " : " + variable.aggregation.tsx : "",
                                variable.interpolation && variable.interpolation.kind ? (tsmModalComponent.props.t('tsm.type.variable.interpolation') + " : " + variable.interpolation.kind + (variable.interpolation.boundary ? " | " + variable.interpolation.boundary.span : "")) : "",
                                variable.filter ? tsmModalComponent.props.t('tsm.type.variable.filter') + " : " + variable.filter.tsx : ""].filter(x => x !== "").join(', ')}
                            </div>
                        }
                    </div>
                        <div id={getID('advancedOptions')} className={cx('block', 'advancedOptions', (tsmModalComponent.state.isAdvancedOptionsExpanded ? '' : 'hidden'))}>
                            <div className={cx('block')}>
                                <div id={getID('aggregationOperationLabel')} className={cx('title')}>{tsmModalComponent.props.t('tsm.type.variable.aggregationOperation')}</div>
                                {variable.kind === "categorical" ? 
                                    <div className={cx('info-text', '_base-fade-in')} aria-readonly={true}>{tsmModalComponent.props.t('tsm.type.validations.variable.aggregateNotSupportedForCategorical')}</div> 
                                :
                                [<div key="aggregationOpDiv" className={cx('freeformOptions')}>
                                    <div className={cx('radioButtonContainer')} role="radiogroup" aria-labelledby={getID('aggregationOperationLabel')}>
                                        <div className={cx('_base-radioButton')}>
                                            <input id={getID('aggregationOperationQuickValueSelectionInput')} tabIndex={variable.isAggregationOperationFreeform ? -1 : 0} type="radio" aria-checked={!variable.isAggregationOperationFreeform} checked={!variable.isAggregationOperationFreeform} onChange={variableAggregationOperationBoxStyleToggle} aria-labelledby={getID('aggregationOperationQuickValueSelectionLabel')}/>
                                            <label tabIndex={-1} htmlFor={getID('aggregationOperationQuickValueSelectionInput')} id={getID('aggregationOperationQuickValueSelectionLabel')} className={cx('checkmark')}>{tsmModalComponent.props.t('tsm.type.variable.quickValueSelection')}</label>    
                                        </div>
                                        <div className={cx('_base-radioButton')}>
                                            <input id={getID('aggregationOperationCustomValueSelectionInput')} tabIndex={variable.isAggregationOperationFreeform ? 0 : -1} type="radio" aria-checked={variable.isAggregationOperationFreeform} checked={variable.isAggregationOperationFreeform} onChange={variableAggregationOperationBoxStyleToggle} aria-labelledby={getID('aggregationOperationCustomValueSelectionLabel')}/>
                                            <label tabIndex={-1} htmlFor={getID('aggregationOperationCustomValueSelectionInput')} id={getID('aggregationOperationCustomValueSelectionLabel')} className={cx('checkmark')}>{tsmModalComponent.props.t('tsm.type.variable.editValueInFreeform')}</label>
                                        </div>
                                    </div>
                                </div>,
                                variable.isAggregationOperationFreeform ?
                                    <textarea key="aggregationOpText" aria-labelledby={getID('aggregationOperationLabel')} aria-describedby={getID('aggregationOperationErrorField') + " " + getID('aggregationOperationAlertField')} 
                                                disabled={variable.kind === "categorical" || (variable.kind === "numeric" && variable.interpolation)} aria-label={tsmModalComponent.props.t('tsm.type.variable.title') + tsmModalComponent.props.t('tsm.type.variable.aggregationOperation')} 
                                                placeholder="e.g. avg($value)" className={cx(variable.errors?.aggregation_operation ? 'error' : '')} value={variable.aggregation?.tsx || ""} onChange={handleVariableAggregationOperationChange}/>
                                :
                                    <select key="aggregationOpSelect" disabled={variable.kind === "categorical"} aria-labelledby={getID('aggregationOperationLabel')} aria-describedby={getID('aggregationOperationErrorField') + " " + getID('aggregationOperationAlertField')} className={cx(variable.errors?.aggregation_operation ? 'error' : '')} value={variable.aggregation?.tsx || ""} onChange={handleVariableAggregationOperationChange}>
                                        {!(variable.kind === "numeric" && variable.interpolation) &&
                                            <option key="N/A-redOp" value="">{tsmModalComponent.props.t('tsm.defaultOption')}</option>
                                        }
                                        {variable.kind === null ? 
                                            VariableAggregationOperations_Aggregate.concat(VariableAggregationOperations_Numeric.filter(item => VariableAggregationOperations_Aggregate.indexOf(item) < 0))
                                            .map((aO, i) => <option key={"redOpN-" + i} value={aO}>{aO.substr(0, aO.indexOf('(')).toUpperCase()}</option>)
                                        : variable.kind === "aggregate" ?
                                            VariableAggregationOperations_Aggregate.map((aO, i) => <option key={"redOp-" + i} value={aO}>{aO.substr(0, aO.indexOf('(')).toUpperCase()}</option>) 
                                            :
                                            variable.kind === "numeric" && variable.interpolation ?
                                                VariableAggregationOperations_Numeric_Interpolation.map((aO, i) => <option key={"redOp-" + i} value={aO}>{aO.substr(0, aO.indexOf('(')).toUpperCase()}</option>)
                                                :
                                                VariableAggregationOperations_Numeric.map((aO, i) => <option key={"redOp-" + i} value={aO}>{aO.substr(0, aO.indexOf('(')).toUpperCase()}</option>)
                                        }
                                    </select>
                                ]}
                                {variable.kind === "numeric" && variable.interpolation && variable.isAggregationOperationFreeform && 
                                    <div className={cx('info-text', '_base-fade-in')} id={getID('aggregationOperationAlertField')} role="alert">{tsmModalComponent.props.t('tsm.type.validations.variable.customAggregateNotSupportedForInterpolatedNumeric')}</div> 
                                }
                                {variable.errors?.aggregation_operation && <div className={cx('relativePosWrapper')}><div id={getID('aggregationOperationErrorField')} role="alert" className={cx('error-message', '_base-fade-in')}>{tsmModalComponent.props.t('tsm.type.validations.variable.aggregation_operation')}</div></div>}
                            </div>
                            <div className={cx('block')}>
                                <div id={getID('interpolationLabel')} className={cx('title')}>{tsmModalComponent.props.t('tsm.type.variable.interpolation')}</div>
                                {variable.kind === "aggregate" ? 
                                    <div className={cx('info-text', '_base-fade-in')} aria-readonly={true}>{tsmModalComponent.props.t('tsm.type.validations.variable.interpolationInvalidForAggregate')}</div> 
                                : variable.kind === "numeric" && variable.aggregation && VariableAggregationOperations_Numeric_NonInterpolation.includes(variable.aggregation.tsx) ?
                                    <div className={cx('info-text', '_base-fade-in')} aria-readonly={true}>{tsmModalComponent.props.t('tsm.type.validations.variable.interpolationInvalidForAggretationOperation')}</div> 
                                :
                                [
                                <div key={'interpolation-kind'} className={cx('sub-block')}>
                                    <div id={getID('interpolationKindLabel')} className={cx('sub-title')}>{tsmModalComponent.props.t('tsm.type.variable.kind')}</div>
                                    <select aria-labelledby={getID('interpolationLabel') + " " + getID('interpolationKindLabel')} aria-describedby={getID('interpolationKindErrorField')} className={cx(variable.errors?.interpolation?.kind ? 'error' : '')} value={variable.interpolation?.kind || ""} onChange={handleVariableInterpolationKindChange} disabled={variable.kind === "aggregate"}>
                                        <option key="N/A-kind" value="">{tsmModalComponent.props.t('tsm.defaultOption')}</option>
                                        {variable.kind === "categorical" ? 
                                            VariableInterpolationKinds_Categorical.map((iK, i) => <option key={iK} value={iK.toLowerCase()}>{iK}</option>) : 
                                            VariableInterpolationKinds.map((iK, i) => <option key={iK} value={iK.toLowerCase()}>{iK}</option>)
                                        }
                                    </select>
                                    {variable.errors?.interpolation_kind && <div className={cx('relativePosWrapper')}><div id={getID('interpolationKindErrorField')} role="alert" className={cx('error-message', '_base-fade-in')}>{tsmModalComponent.props.t('tsm.type.validations.variable.interpolation_kind')}</div></div>}
                                    {variable.errors?.interpolation_required && <div className={cx('relativePosWrapper')}><div id={getID('interpolationRequiredErrorField')} role="alert" className={cx('error-message', '_base-fade-in')}>{tsmModalComponent.props.t('tsm.type.validations.variable.interpolation_required')}</div></div>}
                                </div>,
                                variable.interpolation?.kind && 
                                <div key={'interpolation-boundary'} className={cx('sub-block')}>
                                    <div id={getID('interpolationBoundarySpanLabel')} className={cx('sub-title')}>{tsmModalComponent.props.t('tsm.type.variable.boundarySpan') + ' (' + tsmModalComponent.props.t('tsm.optional') + ')'}</div>
                                    <div className={cx('boundarySpan')}>
                                        <input aria-labelledby={getID('interpolationBoundarySpanLabel')} id={interpolationBoundarySpanNumber} type="number" min="0" placeholder={tsmModalComponent.props.t('tsm.type.variable.enterNumber')} value={variable.interpolation?.boundary?.span ? getNumberFromISOFormat(variable.interpolation.boundary.span) : 0} onChange={handleVariableInterpolationBoundaryChange}/>
                                        <select aria-label={tsmModalComponent.props.t('tsm.type.variable.boundarySpan') + tsmModalComponent.props.t('tsm.type.variable.kind')} id={interpolationBoundarySpanKind} value={variable.interpolation?.boundary?.span.toLowerCase().slice(-1) || ""} onChange={handleVariableInterpolationBoundaryChange}>
                                            <option key="boundary-s" value="s">{tsmModalComponent.props.t('tsm.type.variable.seconds').toLowerCase()}</option>
                                            <option key="boundary-m" value="m">{tsmModalComponent.props.t('tsm.type.variable.minutes').toLowerCase()}</option>
                                            <option key="boundary-h" value="h">{tsmModalComponent.props.t('tsm.type.variable.hours').toLowerCase()}</option>
                                            <option key="boundary-d" value="d">{tsmModalComponent.props.t('tsm.type.variable.days').toLowerCase()}</option>
                                        </select>
                                    </div>
                                </div>]}
                            </div>
                            <div className={cx('block')}>
                                <div id={getID('filterLabel')} className={cx('title')}>{tsmModalComponent.props.t('tsm.type.variable.filter') + ' (' + tsmModalComponent.props.t('tsm.optional') + ')'}</div>
                                <input aria-labelledby={getID('filterLabel')} placeholder="e.g. $event['Value'].Double != 0" type="text" value={variable.filter?.tsx || ""} onChange={handleVariableFilterChange}/>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    );
}
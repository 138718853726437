export enum JobsErrorCode {
  None = 'None',
  InvalidJobParameters = 'InvalidJobParameters',
  DataSizeLimitExceeded = 'DataSizeLimitExceeded',
  TimeLimitExceeded = 'TimeLimitExceeded',
  InternalError = 'InternalError',
  UnknownError = 'UnknownError',
  DataError = 'DataError'
}

export enum JobType {
  Import = 'Import',
  Undo = 'Undo',
  Cancel = 'Cancel',
};

export class JobInfo {
  name: string;
  parentJobName: string;
  type: JobType;
  status: JobStatus;
  created: Date;
  startTime: Date;
  endTime: Date;
  childJobs: Array<JobInfo>;
  alert: boolean;
  tasksCompleted: number;
  totalTasks: number;
  errorType: JobsErrorCode;
  importSourceFolder: string;
  importTimestampProperty: string;
  recentlyModified: boolean;

  // For jobs with child jobs we need a value for status that takes into
  // account the status of the latest child. For example, if the latest child
  // is queued, we want to show that at the top level in the UI.
  topLevelStatus: JobStatus;

  static fromObject(obj: any): JobInfo {
    let jobInfo = new JobInfo();
    
    jobInfo.name = obj.jobName;
    jobInfo.parentJobName = (obj.jobParameters && obj.jobParameters.sourceJobName) || null;
    jobInfo.type = obj.type;
    jobInfo.status = obj.status;
    jobInfo.tasksCompleted = obj.tasksCompletedCount;
    jobInfo.totalTasks = obj.totalTasksCount;
    jobInfo.importSourceFolder = obj.jobParameters.importSourceFolder;
    jobInfo.importTimestampProperty = obj.jobParameters.importTimestampProperty;
    jobInfo.childJobs = [];
    jobInfo.alert = undefined; // The value for alert can't be set here because it depends on child jobs.
    jobInfo.recentlyModified = false;

    jobInfo.created = (obj.creationTimeUtc && new Date(obj.creationTimeUtc)) || null;
    jobInfo.startTime = (obj.startTimeUtc && new Date(obj.startTimeUtc)) || null;
    jobInfo.endTime = (obj.endTimeUtc && new Date(obj.endTimeUtc)) || null;

    if(obj.jobErrorInfo){
      if(!Object.values(JobsErrorCode).includes(obj.jobErrorInfo.code)) {
        jobInfo.errorType = JobsErrorCode.UnknownError;
      } else {
        jobInfo.errorType = obj.jobErrorInfo.code;
      }
    } else{
      jobInfo.errorType = JobsErrorCode.None;
    }

    // These values could be modified depending on the state of any existing child jobs.
    jobInfo.alert = jobInfo.status === JobStatus.Failed;
    jobInfo.topLevelStatus = jobInfo.status;

    return jobInfo;
  }

  mergeWithChildJobs(childJobs: Array<JobInfo>) {
    if(!childJobs || childJobs.length === 0) {
      return;
    }

    // Sort child jobs by date created, from oldest to most recent.
    childJobs.sort((a, b) => (a.created ? String(a.created) : '').localeCompare(b.created ? String(b.created) : ''));

    this.childJobs = childJobs;
    this.setJobStatusFromChildJobs();
  }

  appendChild(child: JobInfo) {
    this.childJobs.push(child);
    this.setJobStatusFromChildJobs();
  }

  setJobStatusFromChildJobs() {
    let latest = this.childJobs[this.childJobs.length - 1];
    
    this.alert = latest.status === JobStatus.Failed;

    // If any undo children, make JobStatus Undone
    if(this.childJobs.filter(cj => cj.type === JobType.Undo).length){
      this.topLevelStatus = JobStatus.Undone;
    }

    // If the latest child job is in progress, reflect that on the top level status.
    if(latest.status === JobStatus.Running || latest.status === JobStatus.Queued) {
      this.topLevelStatus = latest.status;
    }
  }
}

export class JobStatus {
  static readonly Cancelling: string = 'Canceling'; // jobInfo.API uses British spelling
  static readonly Cancelled = 'Canceled'; // jobInfo.API uses British spelling
  static readonly Failed = 'Failed';
  static readonly Queued = 'Queued';
  static readonly Running = 'Running';
  static readonly Succeeded = 'Succeeded';
  static readonly RolledBack = 'RolledBack';
  static readonly Undone = 'Undone';

  static isDone(status: JobStatus) {
    return status === this.Cancelled || status === this.Failed || status === this.Succeeded || status === this.RolledBack;
  }
}

export enum JobsPageStatus { 
  Loading, 
  Loaded, 
  Refreshing, 
  Error 
};
import { HierarchyLevel } from "./HierarchyLevel";

/**
 * Represents a hierarchy.
 * @constructor
 * @param {string} [id] - ID of the hierarchy.
 * @param {string} name - The name of the hierarchy.
 * @param {HierarchyLevel[]} [levels] - Levels defined for the hierarchy.
 * @param {{}} [errors] - Errors in the hierarchy, see HierarchyEntityErrors enums for details.
 */
export default class HierarchyEntity {
    public id?: string;
    public name: string;
    public levels: HierarchyLevel[];
    public errors?: {};

    constructor() {
        this.name = "";
        this.addLevel();
    }

    static fromObject = (obj: any) => {
        let he = new HierarchyEntity();
        he.id = obj.id;
        he.name = obj.name;
        
        let levels: HierarchyLevel[] = [];
        obj.source.instanceFieldNames.forEach(e => {
            let level = new HierarchyLevel();
            level.name = e;
            levels.push(level);
        });

        if (!he.levels) {
            he.levels = [];
        }
        he.levels = levels;

        return he;
    }

    public convertToJSON() {
        let data = {};
        data["put"] = [];

        let hierarchy = {};
        if (this.id) {
            hierarchy["id"] = this.id;
        }
        hierarchy["name"] = this.name;

        let source = {};
        source["instanceFieldNames"] = this.levels.map(a => a.name);
        hierarchy["source"] = source;
        
        data["put"].push(hierarchy);
        
        return JSON.stringify(data, null, 2);
    }

    public addLevel(obj = null) {
        let level;
        if (obj) {
            level = HierarchyLevel.fromObject(obj);
        } else {
            level = new HierarchyLevel ();
        }
        
        if (!this.levels) {
            this.levels = [];
        }
        this.levels.push (level);
    }

    public hasErrors() {
        return this.errorCount() > 0;
    }

    public updateErrors() {
        if (this.name === "") {
            if (!this.errors) {
                this.errors = {};
            }
            this.errors["name"] = true;
        } else {
            if (this.errors && this.errors["name"]) {
                delete this.errors["name"];
            }
        }

        if (!this.levels.length) {
            if (!this.errors) {
                this.errors = {};
            }
            this.errors["levels"] = true;
        } else {
            if (this.errors && this.errors["levels"]) {
                delete this.errors["levels"];
            }
            this.levels.forEach((l) => {
                l.updateErrors();
            });

            let uniqueName = true;
            this.levels.forEach((l, idx) => {
                this.levels.forEach((l2, idx2) => {
                    if (idx !== idx2) {
                        if (l.name && l.name === l2.name) {
                            l.addError("name_unique");
                            uniqueName = false;
                        }    
                    }
                });
                if (uniqueName) {
                    l.removeError("name_unique");
                }
                uniqueName = true;
            });
        }
    }

    public errorCount() {
        let errorCount = 0;
        if (this.errors) {
            errorCount += Object.keys(this.errors).length;
        }

        if (this.levels) {
            this.levels.forEach((l) => {
                errorCount += l.errorCount();
            });
        }

        return errorCount;
    }
}
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { getSelectedEnvironmentIsLts, getTheme } from '../../state/reducers/AppSettingsReducer';
import ChartSettings from './ChartSettings';
import { SET_CHART_SETTINGS_STATE } from '../../../constants/ActionTypes';
import { getChartSettingsState, getAvailabilityIsCompact, getWarmStoreRange, getEnvironmentHasWarmStore } from '../../state/reducers/AnalyticsReducer';
import { ChartSettingsTypes } from '../../../constants/Enums';

const mapStateToProps = state => {
    return { 
        theme: getTheme(state),
        chartSettingsState: getChartSettingsState(state),
        availabilityIsCompact: getAvailabilityIsCompact(state),
        isPaygEnvWithoutWarmStore: getSelectedEnvironmentIsLts(state) && (getWarmStoreRange(state) === null || !getEnvironmentHasWarmStore(state))
    };
};

const mapDispatchToProps = dispatch => ({ 
    closeModal: () => {
        dispatch({type: SET_CHART_SETTINGS_STATE, payload: {
            isOpen: false,
            type: ChartSettingsTypes.Basics
        }})
    },
    setTab: (type: ChartSettingsTypes) => {
        dispatch({type: SET_CHART_SETTINGS_STATE, payload: {
            isOpen: true,
            type: type
        }})
    }
});

const ChartSettingsContainer = translate()(connect(mapStateToProps, mapDispatchToProps)(ChartSettings));

export default ChartSettingsContainer;

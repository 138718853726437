import * as React from 'react';
import classNames from 'classnames/bind';
import LineChartContainer from '../../components/LineChart/LineChart.container';
import EventsTableContainer from '../EventsTable/EventsTable.container';
import Placeholder from '../Placeholder/Placeholder';
import WellContainer from '../Well/Well.container';
import TelemetryService from '../../services/TelemetryService';
import ScatterPlotContainer from '../ScatterPlot/ScatterPlot.container';
import HeatmapContainer from '../Heatmap/Heatmap.container';
import Icon from '../Icon/Icon';
import { ChartTypes, ModalTypes } from '../../../constants/Enums';
import AdvancedExplorationContainer from '../AdvancedExploration/AdvancedExploration.container';
import BrushedRegionStatisticsContainer from '../BrushedRegionStatistics/BrushedRegionStatistics.container';
import { BrushedRegionStatisticsState } from '../../models/BrushedRegionStatisticsState';
import WellColumnsSettingsContainer from '../WellColumnsSettings/WellColumnsSettings.container';
import ChartSettingsContainer from '../ChartSettings/ChartSettings.container';
import { ChartSettingsState } from '../../models/Interfaces';

const cx = classNames.bind(require('./AnalyticsWorkspace.module.scss'));

interface Props {t: any; theme: string; events: Array<any>; isLoadingAggregates: boolean; queries: any; 
    environmentIsLts: boolean; isLoadingEvents: boolean; chartType: string; 
    loadingAggregatesProgress: number; modalOptions: any; closeModal: any; previousSessionState: any; 
    chartSettingsState: ChartSettingsState; restorePreviousSession: any; 
    brushedRegionStatisticsState: BrushedRegionStatisticsState; toggleJumboOff: any;}

interface State {isResizableMaskEnabled: boolean; }


class AnalyticsWorkspace extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {isResizableMaskEnabled: false};
    }

    showMask = (e) => {
        this.setState({isResizableMaskEnabled: true});
        TelemetryService.logUserAction('wellResizeStart');
    }

    hideMask = (e) => {
        this.setState({isResizableMaskEnabled: false});
        TelemetryService.logUserAction('wellResizeStop');
    }

    componentWillReceiveProps(nextProps){
        if((nextProps.queries.length === 0 && !nextProps.isLoadingAggregates)) this.props.toggleJumboOff();
    }
    
    render() {
        return (
            <div className={cx('wrapper')}>
                <div className={cx('top')}>
                    <Placeholder visible={this.props.isLoadingAggregates} progress={this.props.loadingAggregatesProgress}>
                        {this.props.isLoadingAggregates && <div aria-live="polite">{this.props.t('loadingValues')}</div>}
                    </Placeholder>
                    {(this.props.queries.length === 0 && !this.props.isLoadingAggregates) && 
                    <Placeholder className={cx('modelChoose')} visible={true}>
                        <Icon id={`lightbulbPlaceholder-${this.props.theme}`} className={cx('lbph')}/>
                        <span>
                            {this.props.environmentIsLts ? this.props.t('chooseFromModel') : this.props.t('addQueryFromBuilder')}
                            {this.props.previousSessionState && ' ' + this.props.t('or')}
                        </span>
                        {this.props.previousSessionState && 
                            <button onClick={this.props.restorePreviousSession} className={cx('_base-primary-button')}>{this.props.t('restoreSession')}</button>
                        }
                    </Placeholder>}
                    <Chart props={{chartType: this.props.chartType}}/>
                    {this.props.brushedRegionStatisticsState.isVisible && <BrushedRegionStatisticsContainer></BrushedRegionStatisticsContainer>}
                    {this.props.environmentIsLts && <WellContainer onResizeStart={this.showMask} onResizeStop={this.hideMask} />}
                    <Placeholder className={cx('resizable-mask')} visible={this.state.isResizableMaskEnabled}></Placeholder>
                    {(this.props.modalOptions.modalType === ModalTypes.ScatterPlot) && 
                        <AdvancedExplorationContainer 
                            suggestedScatterMeasures = {this.props.modalOptions.suggestedScatterMeasures}
                            closeModal = {this.props.closeModal}
                            focusModalX = {this.props.modalOptions.focusModalX}
                            focusModalY = {this.props.modalOptions.focusModalY}
                        />
                    }

                    {(this.props.chartSettingsState?.isOpen && <ChartSettingsContainer/>)}

                    {this.props.modalOptions.modalType === ModalTypes.EditColumns && 
                        <WellColumnsSettingsContainer 
                            closeModal = {this.props.closeModal}
                            focusModalX = {this.props.modalOptions.focusModalX}
                            focusModalY = {this.props.modalOptions.focusModalY}
                        />
                    }
                     
                </div>
                <div className={cx('eventsWrapper', {hid: this.props.events === null, slid: this.props.events !== null || this.props.isLoadingEvents})}>
                    {(this.props.events !== null || this.props.isLoadingEvents) && <EventsTableContainer />}
                </div>
            </div>
        );
    }

}

export default AnalyticsWorkspace;

const Chart = ({props}) => {
    switch(props.chartType) {
        case ChartTypes.Linechart:
            return <LineChartContainer/>;
        case ChartTypes.Heatmap:
            return <HeatmapContainer/>;
        case ChartTypes.Scatterplot:
            return <ScatterPlotContainer/>;
        default:
            return <LineChartContainer/>;
    }
}
/** This component uses some codes from an open source package
 * 're-resizable' on https://www.npmjs.com/package/re-resizable 
*/
import * as React from 'react';
import classNames from 'classnames/bind';
import Utils from '../../services/Utils';
import Icon from '../Icon/Icon';
const cx = classNames.bind(require('./Resizable.module.scss'));

interface Props {className?: string, size: Size, minWidth?: string, minHeight?: string, maxWidth?: string, maxHeight?: string,
     enable?: Enable, onResizeStart?: any, onResize?: any, onResizeStop?: any, collapsed?: boolean, children?: React.ReactNode, 
     isCollapsable?: boolean, snapWidthBeforeCollapse?: string, snapBuffer?: number, onCollapsed?: any} 

interface State { isResizing: boolean, direction: Direction, resizerPos: {top?: string, right?: string, bottom?: string, left?: string},
    originalResizerPos: {top?: string, right?: string, bottom?: string, left?: string}, width: string, height: string, resizeCursor: string, 
    original: {x: number, y: number, width: number, height: number, collapsed?: any}, collapse: snapCollapse, snap: snapCollapse, wasCollapsed: snapCollapse}

type snapCollapse = {right: boolean, top: boolean};

type Size = {width: string, height: string};

type Enable = {top?: boolean, right?: boolean, bottom?: boolean, left?: boolean};

type Direction = 'top' | 'right' | 'bottom' | 'left';

enum CollapseDir {'top', 'right'};

type NumberSize = {width: number, height: number};

const endsWith = (str: string, searchStr: string): boolean => str.substr(str.length - searchStr.length, searchStr.length) === searchStr;

const getStringSize = (n: number | string): string => {
    if (n.toString() === 'auto') return n.toString();
    if (endsWith(n.toString(), 'px')) return n.toString();
    if (endsWith(n.toString(), '%')) return n.toString();
    return `${n}px`;
};

const clampNeg = (n: number, min: number, max: number): number => Math.min(Math.max(n, max), min);

export default class Resizable extends React.Component<Props, State> {
    resizable: any;
    resizers: {top: any, right: any; bottom: any; left: any; };

    static defaultProps = {
        snapBuffer: 60, 
        snapWidthBeforeCollapse: '110px',
        isCollapsable: false,
        onCollapsed: () => null
    };

    constructor(props: Props) {
        super(props);
        this.state = {
            isResizing: false,
            resizeCursor: 'auto',
            width:
                typeof (this.props.size && this.props.size.width) === 'undefined'
                ? 'auto'
                : (this.props.size && this.props.size.width),
            height:
                typeof (this.props.size && this.props.size.height) === 'undefined'
                ? 'auto'
                : (this.props.size && this.props.size.height),
            direction: 'right',
            resizerPos: {},
            originalResizerPos: {},
            original: {
                x: 0,
                y: 0,
                width: 0,
                height: 0,
            },
            /* When a panel is open, collapse forces the panel to close below a certain dragged width. 
            When a panel is collapsed, snap forces the panel to open above a certain dragged width. */
            collapse: {right: false, top: false},
            snap: {right: false, top: false},
            wasCollapsed: {right: false, top: false}
        };

        this.onMouseMove = this.onMouseMove.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);
        this.resizers = {top: null, right: null, bottom: null, left: null};
    }

    componentDidMount() {
        this.parentNode.addEventListener('mouseup', this.onMouseUp);
        this.parentNode.addEventListener('mousemove', this.onMouseMove);
        this.parentNode.addEventListener('mouseleave', this.onMouseUp);
    }
    
    componentWillUnmount() {
        this.parentNode.removeEventListener('mouseup', this.onMouseUp);
        this.parentNode.removeEventListener('mousemove', this.onMouseMove);
        this.parentNode.removeEventListener('mouseleave', this.onMouseUp);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.size.width !== nextProps.size.width) {
            this.setState((prevState) => ({
                width: nextProps.size.width,
                collapse: {right: parseInt(nextProps.size.width) === 0, top: prevState.collapse.top}
            }));
        }
        if (this.props.size.height !== nextProps.size.height) {
            this.setState((prevState) => ({
                height: nextProps.size.height,
                collapse: {right: prevState.collapse.right, top: parseInt(nextProps.size.height) === 0}
            }));
        }
    }

    get parentNode(): HTMLElement {
        return ((this.resizable).parentNode);
    }

    get size(): NumberSize {
        let width = this.resizable.offsetWidth;
        let height = this.resizable.offsetHeight;
        return { width, height };
    }

    get sizeStyle(): { width: string, height: string } {
        const width = this.state.width;
        const height = this.state.height;
        return { width, height };
    }

    get resizerStyle(): { top?: string, right?: string; bottom?: string; left?: string } {
        if (!this.state.isResizing) return {};
        const top = this.state.resizerPos ? this.state.resizerPos.top : null;
        const right = this.state.resizerPos ? this.state.resizerPos.right : null;
        const bottom = this.state.resizerPos ? this.state.resizerPos.bottom : null;
        const left = this.state.resizerPos ? this.state.resizerPos.left : null;
        return {top, right, bottom, left};
    }

    getParentSize(): { width: number, height: number } {
        const size = {width: this.parentNode.offsetWidth, height: this.parentNode.offsetHeight};
        return size;
    }

    calculateNewSizeString(newSize: number | string, kind: 'width' | 'height'): string {
        const propsSize = this.props.size && this.props.size[kind];
        return this.state[kind] === 'auto' &&
          this.state.original[kind] === newSize &&
          (typeof propsSize === 'undefined' || propsSize === 'auto')
          ? 'auto'
          : getStringSize(newSize);
    }

    onResizeStart(event: React.MouseEvent<HTMLElement>, direction: Direction){
        let clientX = 0;
        let clientY = 0;
        clientX = event.clientX;
        clientY = event.clientY;

        if (event.button === 3) {return;}

        if (this.props.onResizeStart) {
            this.props.onResizeStart(event);
        }

        const targetedResizer = window.getComputedStyle(event.currentTarget);
    
        this.setState((prevState) => ({
            wasCollapsed: prevState.collapse,
            original: {x: clientX, y: clientY, width: this.size.width, height: this.size.height},
            isResizing: true,
            direction,
            resizeCursor: targetedResizer.cursor,
            resizerPos: {},
            originalResizerPos: {top: targetedResizer.top, right: targetedResizer.right, bottom: targetedResizer.bottom, left: targetedResizer.left}
        }));
      }

    getCollapsableResizer(dir: CollapseDir, event: MouseEvent, original, calculatedResizer){
        let snap = {right: false, top: false};
        let collapse = {right: false, top: false};
        let parentOffset, snapTarget, collapseTarget, newResizer = calculatedResizer, bumperOffset = 10;

        // ---- Horizontal Resizer ----
        if(dir === CollapseDir.right){
            parentOffset = this.parentNode.getBoundingClientRect().left; // left of container (px position)
            snapTarget = parentOffset + parseInt(this.props.snapWidthBeforeCollapse); // offset adjusted snap position
            collapseTarget = parentOffset + this.props.snapBuffer; // offset adjusted collapse position

            const collapseHorizontal = () => {
                collapse.right = true;
                newResizer = original.width + parseInt(this.state.originalResizerPos.right);
            }
            const snapHorizontal = () => {
                newResizer = original.x - snapTarget - bumperOffset; 
                snap.right = true;
            }

            if(this.state.wasCollapsed.right){ // Resizer started collapsed
                if(event.clientX >= parentOffset && event.clientX <= collapseTarget){
                    collapseHorizontal();
                } else if(event.clientX <= snapTarget){
                    snapHorizontal();
                } 
            }
            else if(event.clientX >= snapTarget - this.props.snapBuffer && event.clientX <= snapTarget){ // sticky resizer to snapTarget
                snapHorizontal();
            } else if(event.clientX <= snapTarget){ // collapse resizer 
                collapseHorizontal();
            }
        } else{ // ---- Vertical Resizer ----
            parentOffset = window.innerHeight - this.parentNode.getBoundingClientRect().bottom; 
            snapTarget = window.innerHeight - parentOffset - parseInt(this.props.snapWidthBeforeCollapse);
            collapseTarget = window.innerHeight - parentOffset - this.props.snapBuffer;

            const collapseVertical = () => {
                collapse.top = true;
                newResizer = original.height + parseInt(this.state.originalResizerPos.top);
            }

            const snapVertical = () => {
                newResizer = snapTarget - original.y - bumperOffset;
                snap.top = true;
            }
            if(this.state.wasCollapsed.top){ // Resizer started collapsed
                if(event.clientY <= window.innerHeight - parentOffset && event.clientY >= collapseTarget){ 
                    collapseVertical();
                } else if(event.clientY >= snapTarget){
                    snapVertical();
                }
            }
            else if(event.clientY <= snapTarget + this.props.snapBuffer && event.clientY >= snapTarget){ // sticky resizer to snapTarget
                snapVertical();
            } else if(event.clientY >= snapTarget){ // collapse resizer 
                collapseVertical();
            }
        }

        this.setState({snap, collapse})
        return newResizer;
    }

    onMouseMove(event: MouseEvent) {
        if (!this.state.isResizing) return;
        window.getSelection().removeAllRanges();
        const clientX = event.clientX;
        const clientY = event.clientY;
        const {direction, original, originalResizerPos} = this.state;
        let { maxWidth, minWidth, maxHeight, minHeight} = this.props;
        let newResizerRight : any = 0;
        let newResizerTop : any = 0;

        if (/right/i.test(direction)) {
            let originalResizerPosRight = parseInt(originalResizerPos.right);
            newResizerRight = originalResizerPosRight - (clientX - original.x);
            const computedMinRight = minWidth ? originalResizerPosRight + (original.width - parseInt(minWidth)) : originalResizerPosRight + (original.width - 10);
            const computedMaxRight = maxWidth ? originalResizerPosRight - (parseInt(maxWidth) - original.width) : newResizerRight;

            newResizerRight = clampNeg(newResizerRight, computedMinRight, computedMaxRight);

            if(this.props.isCollapsable){
                newResizerRight = this.getCollapsableResizer(CollapseDir.right, event, original, newResizerRight)
            }
            
            this.setState({resizerPos: {right: getStringSize(newResizerRight)}});
        }
        if (/top/i.test(direction)) {
            let originalResizerPosTop = parseInt(originalResizerPos.top);
            newResizerTop = (clientY - original.y) + originalResizerPosTop;
            const computedMinTop = minHeight ? originalResizerPosTop + (original.height - parseInt(minHeight)) : originalResizerPosTop + (original.height - 10);
            const computedMaxTop = maxHeight ? originalResizerPosTop - (parseInt(maxHeight) - original.height) : newResizerTop;

            newResizerTop = clampNeg(newResizerTop, computedMinTop, computedMaxTop);

            if(this.props.isCollapsable){
                newResizerTop = this.getCollapsableResizer(CollapseDir.top, event, original, newResizerTop)
            }

            this.setState({resizerPos: {top: getStringSize(newResizerTop)}});
        }
    }

    onMouseUp(event: MouseEvent) {
        const {isResizing} = this.state;
        if (!isResizing) return;

        const { direction, original, width, height, resizerPos, originalResizerPos } = this.state;
        const parentSize = this.getParentSize();
        let newWidth : any = original.width;
        let newHeight : any = original.height;
        if (/right/i.test(direction)) {
            let widthDiff = parseInt(originalResizerPos.right) - parseInt(resizerPos.right);
            newWidth = original.width + widthDiff;

            if(this.state.snap.right){
                newWidth = this.props.snapWidthBeforeCollapse;
            }

            if(this.state.collapse.right){
                newWidth = 0;
                // Trigger onCollapse
                this.props.onCollapsed(true);
            }  else{
                // Trigger onOpen
                this.props.onCollapsed(false);
            }
        }
        if (/top/i.test(direction)) {
            let heightDiff = parseInt(originalResizerPos.top) - parseInt(resizerPos.top);
            newHeight = original.height + heightDiff;

            if(this.state.snap.top){
                newHeight = this.props.snapWidthBeforeCollapse;
            }

            if(this.state.collapse.top){
                newHeight = 0;
                // Trigger onCollapse
                this.props.onCollapsed(true);
            }  else{
                // Trigger onOpen
                this.props.onCollapsed(false);
            }
        }

        if (width && typeof width === 'string' && endsWith(width, '%')) {
            const percent = (newWidth / parentSize.width) * 100;
            newWidth = `${percent}%`;
        }

        if (height && typeof height === 'string' && endsWith(height, '%')) {
            const percent = (newHeight / parentSize.height) * 100;
            newHeight = `${percent}%`;
        }

        this.setState({width: this.calculateNewSizeString(newWidth, 'width'), height: this.calculateNewSizeString(newHeight, 'height')});
        Utils.triggerResize(500);

        if (this.props.onResizeStop) {
            this.props.onResizeStop(event);
        }
        this.setState({ isResizing: false, resizeCursor: 'auto'});
    }
    
    render() {
        const userSelect = this.state.isResizing ? 'none' : 'auto';
        return (
            <div
                ref={(c) => {if (c) {this.resizable = c;}}}
                style={{
                    position: 'relative',
                    ...this.sizeStyle,
                    userSelect: userSelect,
                    maxWidth: this.props.maxWidth,
                    maxHeight: this.props.maxHeight,
                    minWidth: this.props.minWidth,
                    minHeight: this.props.minHeight
                }}
                className={this.props.className}
            >
                {this.state.isResizing && (
                <div
                    style={{
                    height: '100%',
                    width: '100%',
                    backgroundColor: 'rgba(0,0,0,0)',
                    cursor: `${this.state.resizeCursor || 'auto'}`,
                    opacity: 0,
                    position: 'fixed',
                    zIndex: 9999,
                    top: '0',
                    left: '0',
                    bottom: '0',
                    right: '0',
                    }}
                />
                )}

                {this.props.children}
                {!this.props.collapsed && this.renderResizer()}
            </div>
        );
    }

    renderResizer() {
        const {enable} = this.props;
        if (!enable) return null;
        const resizers = Object.keys(enable).map(dir => {
          if (enable[dir] !== false) {
            const backgroundColor = this.state.isResizing ? 'rgb(220, 220, 220)' : 'rgba(0,0,0,0)';
            const zIndex = this.state.isResizing ? 15 : 9;
            return (<div key={dir} ref={(c) => {if (c) {this.resizers[dir] = c;}}} className={cx('base', dir)} style={{...this.resizerStyle, backgroundColor, zIndex}} onMouseDown={(e) => this.onResizeStart(e, dir as Direction)}><Icon id={'handleBar'} className={cx('handle')} /></div>);
          }
          return null;
        });
        return (<span>{resizers}</span>);
    }
}
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import ScatterPlot  from './ScatterPlot';
import { getTheme, getSelectedEnvironmentIsLts } from '../../state/reducers/AppSettingsReducer';
import { getAvailabilityIsCompact, getTimezone, getActiveQueries, getScatterMeasures, getIsLoadingAggregates} from '../../state/reducers/AnalyticsReducer';
import { SET_CHART_TYPE, SET_ACTIVE_VISUALIZATION_REFERENCE, GET_QUERYRESULTS } from '../../../constants/ActionTypes';

const mapStateToProps = state => {
    return { 
        theme: getTheme(state),
        tall: getAvailabilityIsCompact(state),
        queries: getActiveQueries(state),
        environmentIsLts: getSelectedEnvironmentIsLts(state),
        isLoadingAggregates: getIsLoadingAggregates(state),
        timezone: getTimezone(state),
        scatterMeasures: getScatterMeasures(state)
    };
}

const mapDispatchToProps = dispatch => ({ 
    setChartType: (chartType) => dispatch({type: SET_CHART_TYPE, payload: chartType}),
    setActiveChartReference: (ref) => {
        dispatch({type: SET_ACTIVE_VISUALIZATION_REFERENCE, payload: ref});
    },
    getQueryResults: () => dispatch({type: GET_QUERYRESULTS})
});

const ScatterPlotContainer = translate()(connect(mapStateToProps, mapDispatchToProps)(ScatterPlot));

export default ScatterPlotContainer;
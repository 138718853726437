import * as React from 'react';
import cx from './Modal.module.scss';
import Icon from '../Icon/Icon';
import { CaretDirections, KeyCodes } from '../../../constants/Enums';
import TooltipableContainer from '../Tooltipable/Tooltipable.container';
import Utils from '../../services/Utils';

interface Props { t: any; theme: string; onClose?: any; title?: string; titleIconId?: string; onTitleIcon?: any; className?: string; 
    contentClassName?: string; hasCloseButton?: boolean; pageX?: number; pageY?: number; caret?: string; 
    wrapperClassName?: string; contentPositionContainerClassName?: string; modalOptions: any; titleClassName?: any; modalDimensions?: any; 
    isTitleIconTooltipable?: boolean; titleIconTooltipClassName?: string; titleIconTooltip?: string; sourceElement?: any; titleIconLabel?: string, 
    isOffModalAutoClosingEnabled?: boolean} 
interface State { }

export default class Modal extends React.Component<Props, State> {

    static defaultProps = {className: '', contentClassName: '', wrapperClassName: '', 
    contentPositionContainerClassName: '', titleClassName : '', hasCloseButton: true, 
    titleIconTooltipClassName: '', isOffModalAutoClosingEnabled: true, onClose: () => {}}

    private dialogRef;
    private firstElemRef;
    private lastElemRef;
    private modalTitleID = Utils.customID('modal-title');

    constructor(props: Props) {
        super(props);
        this.dialogRef = React.createRef();
    }

    private focusDialog = () => {
        this.dialogRef && this.dialogRef.current && this.dialogRef.current.focus();
    }

    private focusFirstElem = () => {
        this.firstElemRef && this.firstElemRef.focus();
    }

    private focusLastElem = () => {
        this.lastElemRef && this.lastElemRef.focus();
    }
    
    componentDidMount () {
        let firstFocusableIndexInContent = this.props.onTitleIcon && this.props.hasCloseButton ? 2 : (this.props.onTitleIcon || this.props.hasCloseButton ? 1 : 0);
        this.firstElemRef = Utils.getFocusableElemAtIdx(document.getElementsByClassName(cx['content'])[0], firstFocusableIndexInContent);
        this.focusFirstElem();
    }

    componentWillUnmount () {
        if (this.props.sourceElement) {
            this.props.sourceElement.focus();
        }
    }

    private dialogKeyHandler = (event) => {
        this.lastElemRef = Utils.getLastFocusableElem(document.getElementsByClassName(cx['content'])[0]);
        let activeElement = document.activeElement;
        if (activeElement === this.lastElemRef && (event.keyCode === KeyCodes.Tab && event.shiftKey === false)){ //last element is focused and tab is pressed
            event.preventDefault();
            this.focusDialog();
        } else if (activeElement === this.dialogRef.current && (event.keyCode === KeyCodes.Tab && event.shiftKey === true)) {  //modal itself is focused and shift tab is pressed
            event.preventDefault();
            this.focusLastElem();
        }
    }

    private modalKeyHandler = (event) => {
        if (event.keyCode === KeyCodes.Esc) {
            event.preventDefault();
            this.onClose();
        }
    }

    private onClose = () => {
        this.props.onClose();
        if (this.props.sourceElement) {
            this.props.sourceElement.focus();
        }
    }

    render() {
        let contentStyle = {position: 'absolute' as 'absolute', left: 'unset', top: 'unset', bottom: 'unset', right: 'unset'};
        let caretClassModifier;

        if(this.props.pageX !== undefined && this.props.pageY !== undefined && this.props.modalDimensions){
            let offset = this.props.modalDimensions; // woo woo
            if(this.props.caret === CaretDirections.Bottom){
                contentStyle.left = Math.round(this.props.pageX - (offset.width / 2))  + 'px';
                contentStyle.bottom = Math.round(this.props.pageY + (offset.height + 20)) + 'px';
                caretClassModifier = 'bottom';
            } else if(this.props.caret === CaretDirections.Top){
                contentStyle.left = Math.round(this.props.pageX - (offset.width / 2))  + 'px';
                contentStyle.bottom = Math.round(this.props.pageY - 18) + 'px';
                caretClassModifier = 'top';
            } else if(this.props.caret === CaretDirections.TopLeft){
                contentStyle.left = Math.round(this.props.pageX - (offset.width / 2))  + 'px';
                contentStyle.bottom = Math.round(this.props.pageY - 18) + 'px';
                caretClassModifier = 'topLeft';
            }
            else{
                // Default to 'from left' flyout
                contentStyle.left = Math.round(this.props.pageX) + 16 + 'px';
                contentStyle.top = Math.round(this.props.pageY - (offset.height / 2)) + 'px';
                caretClassModifier = 'left';
                if(window.innerWidth - this.props.pageX < offset.width){
                    contentStyle.left = Math.round(this.props.pageX - offset.width) - 30 + 'px';
                    caretClassModifier = 'right';
                }
                if(this.props.caret === CaretDirections.UpperLeft){
                    caretClassModifier = 'upper' + caretClassModifier;
                }
            }
        } else {
            caretClassModifier = 'hide';
        }

        return (
            <div className={`${cx['modal']} ${this.props.className}`} onKeyDown={this.modalKeyHandler} onClick={(e) => {this.props.isOffModalAutoClosingEnabled ? this.props.onClose() : e.stopPropagation()}}>
                <div className={`${cx['contentPositionContainer']} ${this.props.contentPositionContainerClassName}`} style={contentStyle}>
                    <div className={`${cx['contentContainer']} ${this.props.wrapperClassName}`}>
                        <div tabIndex={0} className={`${cx['content']} ${this.props.contentClassName}`} onClick={(e) => {e.stopPropagation()}} onKeyDown={this.dialogKeyHandler} ref={this.dialogRef} role="dialog" aria-modal="true" aria-labelledby={this.modalTitleID}>
                            {this.props.title && 
                                <div className={`${cx['title']} ${this.props.titleClassName && this.props.titleClassName} ${this.props.titleIconId && cx['withIcon']}`}>
                                    {this.props.titleIconId && (
                                        this.props.onTitleIcon ?
                                            this.props.isTitleIconTooltipable ? 
                                                <TooltipableContainer className={`${cx['titleIcon']} ${this.props.titleIconTooltipClassName}`} tooltip={this.props.titleIconTooltip}>
                                                    <button aria-label={this.props.titleIconLabel} title={this.props.titleIconLabel} className={cx['titleIconButton']} onClick={this.props.onTitleIcon}>
                                                        <Icon id={this.props.titleIconId} className={cx['icon16']}></Icon>
                                                    </button>
                                                </TooltipableContainer>
                                            :
                                                <button className={cx['titleIconButton']} onClick={this.props.onTitleIcon} aria-label={this.props.titleIconLabel} title={this.props.titleIconLabel}>
                                                    <Icon id={this.props.titleIconId} className={cx['icon16']}></Icon>
                                                </button>
                                        :
                                            <Icon id={this.props.titleIconId} className={cx['icon16']}></Icon>
                                    )}
                                    <h1 className={`${cx['titleText']}`} id={this.modalTitleID}>{Utils.getFormattedHtml(this.props.title, {monoClassName: `${cx['_base_mono']}`})}</h1>
                                </div>
                            }
                            {this.props.hasCloseButton &&
                                <button className={`${cx['closeButton']} ${!this.props.hasCloseButton && cx['isHidden']}`} onClick={this.onClose} title={this.props.t('close')} aria-label={this.props.t('close')}>
                                <Icon id={'iconClose-' + this.props.theme} className={`${cx['linkIcon']} ${cx['icon']}`}/>       
                            </button>}
                            {this.props.children}
                        </div>
                        <div className={`${cx['modalCaret']} ${cx[caretClassModifier]}`}></div>
                    </div>
                </div>
            </div>
        );
    }
}

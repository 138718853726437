import * as React from 'react';
import Utils from '../../services/Utils';
import ChartableComponentContainer from '../ChartableComponent/ChartableComponent.container';
import Placeholder from '../Placeholder/Placeholder';
import { ChartTypes } from '../../../constants/Enums';
import cx from './ScatterPlot.module.scss';

interface Props {t: any; theme: string; tall: boolean; queries: any; isLoadingAggregates: 
                boolean; environmentIsLts: boolean; timezone: string; scatterMeasures: any;
                setChartType: any; getQueryResults: any; setActiveChartReference: any;}
interface State {}

class ScatterPlot extends React.Component<Props, State> {

    private chart;
    private chartId = Utils.guid();

    shouldComponentUpdate(nextProps) {
        return Utils.shouldChartableComponentRender(this.props, nextProps) || 
            this.props.scatterMeasures !== nextProps.scatterMeasures;
    }

    renderChart = (chartData, spMeasures) =>{
        setTimeout(() => {
            this.chart = !this.chart ? new Utils.tsiClient.ux.ScatterPlot(document.getElementById(this.chartId)) : this.chart;

            this.props.setActiveChartReference(this.chart);

            this.chart.render([chartData], {
                hideChartControlPanel: true,
                theme: this.props.theme,
                offset: this.props.timezone,
                tooltip: true,
                legend: (this.props.environmentIsLts ? 'hidden' : 'shown'),
                spMeasures,
                spAxisLabels: [spMeasures[0], spMeasures[1]],
                strings: this.props.t('sdkStrings', {returnObjects: true})
            }, [{color: this.props.theme === 'light' ? '#646464' : '#DDDDDD' }])
        })
    }

    checkTimestampsPresent = (dataToCheck: any) => {
        return Object.keys(dataToCheck[Object.keys(dataToCheck)[0]][""]).length > 0 ? true : false;
    }

    convertToLineChart = () => {
        this.props.setChartType(ChartTypes.Linechart); 
        if (this.props.queries.length !== 0) {
            this.props.getQueryResults();
        }
    }

    render(){
        // Deep copy queries into chartData by JSON parsing
        let chartData = JSON.parse(JSON.stringify(this.props.queries));

        // Convert to linechart if queries or scatter measures not present
        if(!this.props.scatterMeasures || Object.keys(this.props.scatterMeasures).length === 0 || this.props.queries.length === 0){
            this.convertToLineChart();
            return null;
        } 

        let dataReady = true;

        // Check to see if queries have data
        chartData.forEach(element => {
            if(Object.values(this.props.scatterMeasures).indexOf(element.queryID) !== -1){
                if(element.data == null) {
                    dataReady = false;
                }
                if(element.dataType !== 'numeric'){
                    dataReady = false;
                    this.convertToLineChart();
                }
            }
        });

        if(!dataReady) {this.chart = null; return null};

        let scatterMeasureIdxMap = Object.assign({}, this.props.scatterMeasures);

        // Match scatterMeasure IDs to query indices
        Object.keys(scatterMeasureIdxMap).forEach((key) => {
            let getQueryIndexFromId = (id) => {
                let matchingIdx = null;
                chartData.forEach((el,idx) => {
                    if(el.queryID === id) matchingIdx = idx;
                });
                return matchingIdx;
            }
            scatterMeasureIdxMap[key] = getQueryIndexFromId(scatterMeasureIdxMap[key]);
        })

        let measureNames = Utils.getScatterPlotMeasureNames(chartData, scatterMeasureIdxMap);
        let spMeasures = [measureNames.X_MEASURE,
                          measureNames.Y_MEASURE]

        if(this.props.scatterMeasures.R_MEASURE != null)  
            spMeasures.push(measureNames.R_MEASURE);

        // Shape scatter plot data
        let scatterPlotData = Utils.mergeSeriesForScatterPlot(chartData, scatterMeasureIdxMap);

        let scatterQueriesEmpty = false;

        // Show placeholder if no timestamps present in data
        if(!this.checkTimestampsPresent(scatterPlotData)){
            scatterQueriesEmpty = true;
        } else{
            this.renderChart(scatterPlotData, spMeasures);
        }

        return (
            <ChartableComponentContainer chartId={this.chartId}>
                {scatterQueriesEmpty && 
                    <Placeholder className={cx['scatterPlotPlaceholder']} visible={true}><div>{this.props.t('noEvents')}</div></Placeholder>
                }
            </ChartableComponentContainer>
        );
    }
}

export default ScatterPlot;
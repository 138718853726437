import { i18nInstance } from '../../i18n';
import { StateParserErrorTranslationKeys } from '../../constants/Enums';

type DefaultValueGetter = (target: any) => any;
type ValueMapper = (value: any, additionalInputs?: Array<any>, target?: Record<string, any>) => any;
type ArrayMapper = (element: any, additionalInputs?: Array<any>, target?: Record<string, any>, index?: number) => any;

interface InputValueInfo {
    sourcePath: string;
    isRequired: boolean;
    calcDefault?: DefaultValueGetter;
    validators?: Array<Function>;
};

interface MappingInfo extends InputValueInfo {
    targetPath: string;
    additionalInputs?: Array<InputValueInfo>;
    valueMapper?: ValueMapper;
}

interface ArrayMappingInfo extends InputValueInfo {
    targetPath: string;
    additionalInputs?: Array<InputValueInfo>;
    elementMapper: ArrayMapper;
};

interface ObjectMappingInfo extends InputValueInfo {
    targetPath: string;
    valueMapper: ValueMapper;
    keyMapper?: ValueMapper;
}

// Utility class that facilitates mapping from one object to another. The goal is to make
// the transforms that take place explicit and easy to read. The constructor takes the 
// source object from which it will read the values, and optionally, a target object unto
// which it will map the results, adding new properties or replacing existing ones as 
// necessary. If no target object is supplied, a new empty one is used instead.
// The object is mapped, one property at a time, using one of the provided map functions. 
// The presence of any errors in the errors array means that the contents of the target
// object are partial and can be invalid.
export class ObjectMapper {
    public source: any;
    public target: any;
    public errors: Array<any>;

    constructor(source, target = {}) {
        this.source = source;
        this.target = target;
        this.errors = [];
    }

    map({ sourcePath, targetPath, isRequired, calcDefault, validators, additionalInputs, valueMapper }: MappingInfo): void {
        // In some cases there is no main 'source' property to map, and the target value is a function of 
        // generated values, current state, etc. In those cases, sourcePath can be null and no value will
        // be extracted, validated, and no default value will be used.
        let extracted: any;
        if (sourcePath !== null) {
            extracted = ObjectMapper.extractNestedValue(this.source, sourcePath);

            if (extracted === undefined) {
                if (isRequired) {
                    this.errors.push(`Required property at path ${sourcePath} is missing.`);
                    return;
                } else {
                    try {
                        this.setNestedProperty(targetPath, calcDefault(this.target));
                    } catch (reason) {
                        this.errors.push(`Could not set property at path ${sourcePath} to its default value: ${reason}`);
                    }
                    return;
                }
            }

            if (!this.validate(extracted, validators, sourcePath)) {
                return;
            }
        }

        let additionalValues = [];
        if (additionalInputs !== undefined) {
            additionalValues = this.getAdditionalInputValues(this.source, additionalInputs);
        }

        if (valueMapper === undefined) {
            valueMapper = x => x;
        }

        try {
            let finalValue = valueMapper(extracted, additionalValues, this.target);
            this.setNestedProperty(targetPath, finalValue);
        } catch (reason) {
            this.errors.push(reason);
        }
    }

    mapArray({ sourcePath, targetPath, isRequired, calcDefault, validators, additionalInputs, elementMapper }: ArrayMappingInfo): void {
        let sourceArray = ObjectMapper.extractNestedValue(this.source, sourcePath);

        if (sourceArray === undefined) {
            if (isRequired) {
                this.errors.push(`Required property at path ${sourcePath} is missing.`);
                return;
            } else {
                try {
                    this.setNestedProperty(targetPath, calcDefault(this.target));
                } catch (reason) {
                    this.errors.push(`Could not set property at path ${sourcePath} to its default value: ${reason}`);
                }
                return;
            }
        }

        if (!this.validate(sourceArray, validators, sourcePath)) {
            return;
        }

        let mappedArray = sourceArray.map((element, index: number) => {
            let additionalValues = [];
            if (additionalInputs !== undefined) {
                additionalValues = this.getAdditionalInputValues(element, additionalInputs);
            }

            try {
                return elementMapper(element, additionalValues, this.target, index);
            } catch(reason) {
                this.errors.push(`Failed to map value in array at index ${index}: ${reason}`);
                return undefined;
            }
        });

        try {
            this.setNestedProperty(targetPath, mappedArray);
        } catch (reason) {
            this.errors.push(reason);
        }
    }

    mapObject({ sourcePath, targetPath, isRequired, calcDefault, validators, valueMapper, keyMapper }: ObjectMappingInfo): void {
        let sourceObject =  ObjectMapper.extractNestedValue(this.source, sourcePath);

        if (sourceObject === undefined) {
            if (isRequired) {
                this.errors.push(`Required property at path ${sourcePath} is missing.`);
                return;
            } else {
                try {
                    this.setNestedProperty(targetPath, calcDefault(this.target));
                } catch (reason) {
                    this.errors.push(`Could not set property at path ${sourcePath} to its default value: ${reason}`);
                }
                return;
            }
        }

        if (!this.validate(sourceObject, validators, sourcePath)) {
            return;
        }

        let mappedObject = {};
        let keyValuePairs = Object.entries(sourceObject);
        if (keyMapper === undefined) {
            keyMapper = x => x;
        }

        for(let index = 0; index < keyValuePairs.length; index++) {
            const [key, value] = keyValuePairs[index];

            try {
                let mappedKey = keyMapper(key);
                let mappedValue = valueMapper(value);

                mappedObject[mappedKey] = mappedValue;
            } catch(reason) {
                this.errors.push(`Failed to map key value pair (${key}, ${value}) at index ${index}: ${reason}`)
            }
        }

        try {
            this.setNestedProperty(targetPath, mappedObject);
        } catch (reason) {
            this.errors.push(reason);
        }
    }

    static extractNestedValue(source: any, path: string): any {
        if (source === null || typeof (source) !== "object") {
            return undefined;
        }

        let current = source;

        for (let segment of path.split('.')) {
            if (!current.hasOwnProperty(segment)) {
                return undefined;
            }

            current = current[segment];
        }

        return current;
    }

    private validate(value: any, validators: Array<Function>, sourcePath?: string): boolean {
        if (!Array.isArray(validators) || validators.length === 0) {
            return true;
        }

        for (let validator of validators) {
            try {
                if(!validator(value)){
                    if(sourcePath){
                        throw new Error(i18nInstance.t(StateParserErrorTranslationKeys.sourcePathFailedValidator, {sourcePath, validator: validator.name}))
                    } else{
                        throw new Error(i18nInstance.t(StateParserErrorTranslationKeys.valueFailedValidator, {value, type: typeof(value), validator: validator.name}))
                    }
                }
            } catch (reason) {
                this.errors.push(reason);
                return false;
            }
        }

        return true;
    }

    private getAdditionalInputValues(source: any, inputInfo: Array<InputValueInfo>): Array<any> {
        let values = [];

        for (let input of inputInfo) {
            let extracted = ObjectMapper.extractNestedValue(source, input.sourcePath);

            if (extracted === undefined) {
                if (input.isRequired) {
                    this.errors.push(`Required property at path ${input.sourcePath} is missing.`);
                } else {
                    values.push(input.calcDefault(this.target));
                }
            } else {
                if (!this.validate(extracted, input.validators, input.sourcePath)) {
                    continue;
                }

                values.push(extracted);
            }
        }

        return values;
    }

    private setNestedProperty(path: string, value: any) {
        if (this.target === null || typeof (this.target) !== "object") {
            return;
        }

        let current = this.target;
        let segments = path.split('.')

        let i;
        for (i = 0; i < segments.length - 1; i++) {
            let segment = segments[i];
            if (!current.hasOwnProperty(segment)) {
                current[segment] = {};
            }

            current = current[segment];
        }

        current[segments[i]] = value;
    }
}
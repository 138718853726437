import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { getTheme } from '../../state/reducers/AppSettingsReducer';
import { FluentCommandBar } from './FluentCommandBar';

const mapStateToProps = state => {
    return { 
        theme: getTheme(state),
    }
}

const FluentCommandBarContainer = translate()(connect(mapStateToProps)(FluentCommandBar));
export default FluentCommandBarContainer;

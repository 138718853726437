import * as React from 'react';
import Utils from '../../services/Utils';
import update from 'immutability-helper';
import TelemetryService from '../../services/TelemetryService';
import ChartableComponentContainer from '../ChartableComponent/ChartableComponent.container';
import { RawEventsTakeValues, MetadataPropertyTypes} from '../../../constants/Enums';
import cx from './LineChart.module.scss';
import AggregatesQuery from '../../models/AggregatesQuery';

interface Props { t: any; getEvents: any; theme: string; events: any; tall: boolean; queries: any;
                 zoom: any; environmentIsLts: boolean; isLoadingAggregates: boolean; timezone: string;
                 setMaintainedIntervalSize: any; maintainedIntervalSize: any; applySearchSpan: any;
                 setChartType: any; setFocusTriggers: any; stickyTSQ: any;
                 markers: Array<any>; setMarkers: any;
                 unstickyTSQs: any; showHideTimeSeriesQuery: any; removeQuery: any;
                 setActiveChartReference: any; linechartStackState: any; getBrushedRegionStatistics: any; 
                 swimLaneOptions: any; triggerAddMarkerButtonFocus: any; metadata: any; getQueryResults: any; 
                 setAQField: any; addAggregatesQuery: any; setAggregatesQuery: any; 
                 setChartSettingsToSeries: (series: any, tsqI: number) => void; shouldSticky: boolean;
                 setChartSettingsToLane: (lane: string) => void; isSeriesLabelsEnabled: boolean; isWellCollapsed: boolean;}
interface State { }

class LineChart extends React.Component<Props, State> {

    private brushActions;
    private zoomAndSetMaintainInterval;
    private currentMouseoverValue;
    private chart;
    private chartId = Utils.guid();

    componentDidMount() { 

        this.zoomAndSetMaintainInterval = (fromMillis, toMillis, direction) => {
            this.props.zoom(fromMillis, toMillis, direction);
            if (this.props.queries && this.props.queries.length !== 0) {
                if (this.props.maintainedIntervalSize == null) {
                    this.props.setMaintainedIntervalSize(this.props.queries[0].searchSpan.bucketSize);
                }
            } else {
                this.props.setMaintainedIntervalSize(null);
            }
            this.props.applySearchSpan();
        };

    }

    shouldComponentUpdate(nextProps) {
        return Utils.shouldChartableComponentRender(this.props, nextProps) ||
            this.props.events !== nextProps.events || 
            this.props.linechartStackState !== nextProps.linechartStackState || 
            this.props.shouldSticky !== nextProps.shouldSticky ||
            this.props.swimLaneOptions !== nextProps.swimlaneOptions ||
            this.props.isSeriesLabelsEnabled !== nextProps.isSeriesLabelsEnabled ||
            this.props.isWellCollapsed !== nextProps.isWellCollapsed ||
            this.props.markers !== nextProps.markers;
    }

    // line chart options methods
    lcOnMouseover = (aggKey, splitBy) => {
        if (aggKey + splitBy === this.currentMouseoverValue) {
            return;
        }
        Utils.triggerWellClass(this.props.queries, aggKey, splitBy, 'wellHover');
        this.currentMouseoverValue = aggKey + splitBy;
    }
    lcOnMouseout = () => {
        let elements = document.getElementsByClassName('wellHover');
        while (elements.length > 0) {
            elements[0].classList.remove('wellHover');
        }
        this.currentMouseoverValue = null;
    }
    lcOnSticky = (aggKey, splitBy) => {
        this.props.stickyTSQ(aggKey);
    }
    lcOnUnsticky = () => {
        this.props.unstickyTSQs();
    }

    onMarkersChange = (markers) => {
        let markersChanged = false;
        if (markers && this.props.markers && markers.length === this.props.markers.length) {
            let sortedMarkers = markers.sort();
            let sortedPreviousMarkers = this.props.markers.sort();
            sortedMarkers.forEach((marker, i) => {
                if (marker !== sortedPreviousMarkers[i]) {
                    markersChanged = true;
                }
            });
        } else {
            markersChanged = true;
        }

        if (markers && markers.length === 0 && this.props.markers && this.props.markers.length === 1) {
            this.props.triggerAddMarkerButtonFocus();
        }

        if (markersChanged) {
            this.props.setMarkers(markers);
        }
    }

    removeQuery = (i, color, isLts = true) => {
        delete Utils.usedColors[color];
        this.props.removeQuery(i, isLts);
    }

    setBrushActions = () => {
        this.brushActions = [
            {
                name: this.props.t('zoom'),
                action: (fromTime, toTime) => {
                    let fromDate = new Date(fromTime);
                    let toDate = new Date(toTime);
                    if (toDate.valueOf() - fromDate.valueOf() < 1000) {
                        toDate = new Date(fromDate.valueOf() + 1000);
                    } 
                    this.zoomAndSetMaintainInterval(fromDate.valueOf(), toDate.valueOf());
                    TelemetryService.logUserAction('zoomFromChart');
                }
            },
            {  
                name: this.props.t('exploreEvents'),
                action: (fromTime, toTime) => {
                    if (this.props.environmentIsLts) {
                        this.props.getEvents(Utils.getLtsExploreEventsPayload(this.props.queries, fromTime, toTime, RawEventsTakeValues.Min));
                    } 
                    else {
                        this.props.getEvents({predicateString: Utils.getSSkuExploreEventsPredicateString(this.props.queries, this.chart.getVisibilityState())}, {}, (new Date(fromTime)).valueOf(), (new Date(toTime)).valueOf());
                    }
                }
            }
        ];
        if(this.props.environmentIsLts){
            this.brushActions.push({
                name: this.props.t('viewStats'),
                action: (fromDateString, toDateString) => {
                    let brushSelection = document.getElementById(this.chartId).getElementsByClassName('brushElem')[0].getElementsByClassName('selection')[0];
                    let brushSelectionBoundingClientRect = brushSelection.getBoundingClientRect();
                    this.props.getBrushedRegionStatistics(new Date(fromDateString).valueOf(), new Date(toDateString).valueOf(), brushSelectionBoundingClientRect.left + brushSelectionBoundingClientRect.width / 2, brushSelectionBoundingClientRect.top + brushSelectionBoundingClientRect.height/2 + 40);
                    TelemetryService.logUserAction('getBrushedRegionStatistics');
                }
            })
        }
    }

    addAsTerm = (query, timeSeriesName) => {
        let additionalPredicate = query.splitByObject ? `[${query.splitByObject.property}].${query.splitByObject.type} = '${timeSeriesName}' ` : '';
        let predicate = AggregatesQuery.constructPredicateFromInput(query.predicate && query.predicate.predicateString ? query.predicate.predicateString + (additionalPredicate ? ' AND ' + additionalPredicate : '') : additionalPredicate);
        let queryName = `${this.props.t('query')} ${this.props.queries.length + 1}`;
        
        let aQ = new AggregatesQuery(predicate, query.measureObject.input, query.measureTypes, query.searchSpan, query.splitByObject, Utils.getColor(''), queryName);
        this.props.addAggregatesQuery(aQ);
        this.props.getQueryResults();
    }

    showOnly = (query, i, timeSeriesName, actionBeforeGetQueryResults = () => {}) => {
        let additionalPredicate = query.splitByObject ? `[${query.splitByObject.property}].${query.splitByObject.type} = '${timeSeriesName}' ` : '';
        let predicate = AggregatesQuery.constructPredicateFromInput(query.predicate && query.predicate.predicateString ? query.predicate.predicateString + (additionalPredicate ? ' AND ' + additionalPredicate : '') : additionalPredicate);

        let aQ = new AggregatesQuery(predicate, query.measureObject.input, query.measureTypes, query.searchSpan, query.splitByObject, query.color, query.alias);
        this.props.setAggregatesQuery(aQ, i);
        actionBeforeGetQueryResults();
        this.props.getQueryResults();
    }

    excludeThis = (query, i, timeSeriesName) => {
        let additionalPredicate = query.splitByObject ? `[${query.splitByObject.property}].${query.splitByObject.type} != '${timeSeriesName}' ` : '';
        let predicate = AggregatesQuery.constructPredicateFromInput(query.predicate && query.predicate.predicateString ? query.predicate.predicateString + (additionalPredicate ? ' AND ' + additionalPredicate : '') : additionalPredicate);

        let aQ = new AggregatesQuery(predicate, query.measureObject.input, query.measureTypes, query.searchSpan, query.splitByObject, query.color, query.alias);
        this.props.setAggregatesQuery(aQ, i);
        this.props.getQueryResults();
    }

    addOnClickToSwimlaneOptions = (swimlaneOptions) => {
        Object.keys(swimlaneOptions).forEach((laneKey) => {
            swimlaneOptions[laneKey].onClick = () => this.props.setChartSettingsToLane(laneKey)
        });
        return swimlaneOptions;
    }

    renderChart (queries, chartData) {
        setTimeout(() => {
            this.setBrushActions();
            this.chart = !this.chart ? new Utils.tsiClient.ux.LineChart(document.getElementById(this.chartId)) : this.chart;
            this.props.setFocusTriggers(this.chart.labelMouseover, this.chart.labelMouseout, this.chart.stickyOrUnstickySeries);

            this.props.setActiveChartReference(this.chart);
            
            let queriesCopy;
            let maintainedIntervalSize = this.props.maintainedIntervalSize;
            if (maintainedIntervalSize !== null) {
                queriesCopy = queries.map((q) => {
                    return update(q, {searchSpan: {
                        bucketSize: {$set: maintainedIntervalSize}
                    }});
                });
            } else {
                queriesCopy = queries;
            }
            
            queriesCopy.forEach((q, i) => {
                if ((q.hidden || !q.data || q.noData()) && this.props.environmentIsLts) {
                    q.visibilityState = [false];
                } else {
                    q.visibilityState = [true];
                }
                //unset visibility state if not LTS
                if (!this.props.environmentIsLts) {
                    q.visibilityState = null;
                }

                if(!this.props.environmentIsLts){
                    let splitBys = this.props.metadata.filter(m => m.type === MetadataPropertyTypes.String).map(m => m.name).sort();
                    q.contextMenu = [
                        {
                            name: this.props.t('splitByPhrase'),
                            isNested: true,
                            subActions: splitBys.map(mD => {
                                return {
                                    name: mD,
                                    action: (dataGroup, timeSeriesName) => {
                                        this.showOnly(q, i, timeSeriesName, () => {this.props.setAQField(i, 'splitByObject', AggregatesQuery.constructSplitByObjectFromInput(mD))});
                                    }
                                }
                            })
                        },
                        {
                            name: this.props.t('addAsTerm'),
                            action: (dataGroup, timeSeriesName) => {
                                this.addAsTerm(q, timeSeriesName);
                            }
                        },
                        {
                            name: this.props.t('showOnlyThis'),
                            action: (dataGroup, timeSeriesName) => {
                                this.showOnly(q, i, timeSeriesName);
                            }
                        },
                        {
                            name: this.props.t('excludeThis'),
                            action: (dataGroup, timeSeriesName) => {
                                this.excludeThis(q, i, timeSeriesName);
                            }
                        },
                        {
                            name: this.props.t('well.removeTimeSeries'),
                            action: (dataGroup, timeSeriesName) => {
                                Object.keys(dataGroup[dataGroup.alias]).length > 1 ? 
                                    this.excludeThis(q, i, timeSeriesName)
                                    : this.removeQuery(i, q.color, false);
                            }
                        }
                    ];  
                } else {
                    q.contextMenu = [
                        {
                            name: this.props.t('well.hideTimeSeries'),
                            action: () => {
                                this.props.showHideTimeSeriesQuery(i);
                            },
                        },
                        {
                            name: this.props.t('well.removeTimeSeries'),
                            action: () => {
                                this.removeQuery(i, q.color);
                            }
                        },
                        {
                            name: this.props.t('well.settings'),
                            action: (ae,sb,ts,event) => {
                                this.props.setChartSettingsToSeries(q, i);
                            }
                        }
                    ]
                }
            });

            if (!this.props.isLoadingAggregates) {
                let chartOptions = {
                    hideChartControlPanel: true, 
                    theme: this.props.theme, 
                    grid: false, 
                    tooltip: true, 
                    legend: (this.props.environmentIsLts ? 'hidden' : 'shown'),
                    brushContextMenuActions: this.brushActions, 
                    onSticky: this.lcOnSticky, 
                    onUnsticky: this.lcOnUnsticky,
                    onMouseout: this.lcOnMouseout, 
                    onMouseover: this.lcOnMouseover, 
                    offset: this.props.timezone, 
                    markers: this.props.markers,
                    autoTriggerBrushContextMenu: true, 
                    onMarkersChange: this.onMarkersChange, 
                    color: this.props.theme === 'light' ? '#136BFB' : '#60AAFF',
                    yAxisState: this.props.linechartStackState, 
                    strings: this.props.t('sdkStrings', { returnObjects: true }),
                    swimLaneOptions: this.addOnClickToSwimlaneOptions({ ...this.props.swimLaneOptions }), 
                    labelSeriesWithMarker: this.props.isSeriesLabelsEnabled || this.props.isWellCollapsed
                };

                this.chart.render(chartData, chartOptions, queriesCopy);
            }
        });
    }

    render() {
        let queries = this.props.queries.filter(ts => ts && ts.data);
        let chartData = queries.map(ts => ts.data);
        if ((chartData.length || this.props.events != null)) {
            this.renderChart(queries, chartData);
        }
        return (
        <ChartableComponentContainer className={cx['lineChartComponent']} chartId={this.chartId}>
        </ChartableComponentContainer>
        );
    }

}

export default LineChart;

import React, { useState } from "react";
import ModalContainer from "../../Modal/Modal.container";
import classNames from 'classnames/bind';
import Placeholder from "../../Placeholder/Placeholder";
import Icon from "../../Icon/Icon";
import Utils from '../../../services/Utils';
import { AnalyticsCommandModals } from '../../../../constants/Enums'
import { LetUsKnowLink } from "../AnalyticsCommandBar";
const cx = classNames.bind(require('./Open.module.scss'));

interface Props{
    setOpenModal: (modal: AnalyticsCommandModals, isOpen: boolean) => any;
    theme: string;
    t: any;
    savedQueryFilter: string;
    setSavedQueryFilter: any;
    queries: any;
    openSavedQuery: any;
    environments: any;
    deletedQueryStatus: any;
    deleteQuery: any;
    openButtonRef: any;
    openFeedbackForm: any;
}

const Open: React.FunctionComponent<Props> = (props) => {
    const [isOnlyShowingPersonal, setIsOnlyShowingPersonal] = useState(false);
    const [isDeletingQuery, setIsDeletingQuery] = useState(false);
    const [queryToBeDeleted, setQueryToBeDeleted] = useState(null);

    const getSearchAriaLabel = () => {
        return props.savedQueryFilter.length === 0 ? props.t('searchSavedViews') : props.queries.length + ' results';
    };

    const queryFilter = () => {
        if (!isOnlyShowingPersonal) {
            return props.queries.filter((q) => {
                return q.sharingScope === 'Environment';
            });
        } else {
            return props.queries.filter((q) => {
                return q.sharingScope === 'Personal';
            });
        }
    }

    const openSavedQuery = (query) => {
        props.openSavedQuery(query);
        props.setOpenModal(AnalyticsCommandModals.openModal, false);
    }

    const openDeleteQueryModal = (q, e) => {
        setQueryToBeDeleted(q);
        setIsDeletingQuery(true);
        e.stopPropagation();
    }

    const getMarkedName = name => {
        return Utils.getMarkedHtmlBySearch(name, props.savedQueryFilter);
    }

    const closeDeleteQueryModal = (shouldDelete) => {
        if (shouldDelete && queryToBeDeleted !== null) {
            props.deleteQuery(queryToBeDeleted);
        }
        setTimeout(() => {
            setIsDeletingQuery(false);
            setQueryToBeDeleted(null);
        }, 100);
    }

    return(
        <ModalContainer sourceElement={props.openButtonRef.current}
            onClose={() => props.setOpenModal(AnalyticsCommandModals.openModal, false) } title={props.t('openQueryTitle')} titleIconId={'openFolder-'+props.theme}>
                <div className={cx('shownQueriesScopeWrapper')}>
                    <button className={cx('shownQueriesScope', {selected: !isOnlyShowingPersonal})} aria-checked={!isOnlyShowingPersonal} role='checkbox' onClick={() => setIsOnlyShowingPersonal(false)}>
                        {props.t('shared')}
                    </button>
                    <button className={cx('shownQueriesScope', {selected: isOnlyShowingPersonal})} role='checkbox' aria-checked={isOnlyShowingPersonal} onClick={() => setIsOnlyShowingPersonal(true)}>
                        {props.t('personal')}
                    </button>
                    <input className={cx('queryFilter')} type="text" aria-live="assertive"
                        value={props.savedQueryFilter} onChange={e => {props.setSavedQueryFilter(e.target.value)}} 
                        aria-label={getSearchAriaLabel()} placeholder={props.t('searchSavedViews')}>
                    </input>
                </div>
                <div className={cx('queries')}>
                    {queryFilter().length === 0 ? 
                        <Placeholder visible={true} className={cx('noQueriesModal')}><div>{props.t('noQueries')}</div></Placeholder> : 
                        <table>
                            <thead>
                                <tr className={cx('queryListHeader')}>
                                    <th></th>
                                    <th className={cx('queryListName')}>{props.t('queryListName')}</th>
                                    <th className={cx('queryListEnvironment')}>{props.t('queryListEnvironment')}</th>
                                </tr>
                            </thead>
                        <tbody>
                            {queryFilter().map((q, idx) => 
                            <tr onKeyDown={e => {!Utils.isKeyDownAndNotEnter(e) && openSavedQuery(q);}} tabIndex={0} key={idx} onClick={(e) => openSavedQuery(q)} className={cx('query')}>
                                {
                                ((props.environments[q.queries[0].environmentId] && props.environments[q.queries[0].environmentId].roles.indexOf('Contributor') !== -1) || isOnlyShowingPersonal)
                                    ? <td className={cx('queryListDelete')}>
                                        <button onKeyDown={e => {!Utils.isKeyDownAndNotEnter(e) && openDeleteQueryModal(q, e);}} onClick={(e) => openDeleteQueryModal(q, e)} className={cx('queryListDeleteButton')}>
                                            <Icon id={'iconDelete'} className={cx('deleteIcon', 'icon', props.theme)}/>                
                                        </button>
                                    </td> 
                                    : <td></td>
                                }
                                <td className={cx('queryListName')}>{getMarkedName(q.name)}</td>
                                <td className={cx('queryListEnvironment')}>{Utils.getValueOrDefault(props.environments[q.queries[0].environmentId], {}).displayName}</td>
                            </tr>)}
                        </tbody>
                    </table>}
                    {(isDeletingQuery || (props.deletedQueryStatus !== null))  && 
                        <div className={cx('modal', 'queryDeletePrompt')}>
                            <div className={cx('queryDeletePromptTitle')}>{props.t('saveOpenShare.deleteQuery')}</div>
                            <div className={cx('queryDeletePromptText')}>{props.t('saveOpenShare.areYouSure')}</div>
                            <div className={cx('queryDeletePromptButtons')}>
                                <button autoFocus={true} onClick={() => closeDeleteQueryModal(true)} className={cx('queryDeleteActionButton')}>{props.t('delete')}</button>
                                <button onClick={() => closeDeleteQueryModal(false)} className={cx('queryDeleteActionButton')}>{props.t('cancel')}</button>
                            </div>
                            {props.deletedQueryStatus !== null && props.deletedQueryStatus.status !== 'inProgress'
                                && <SavedQueryStatusMessage props={{...props, 
                                    message: (props.deletedQueryStatus as any).status === 'success' ? (props.deletedQueryStatus as any).message : props.t('saveOpenShare.deleteFailed'), 
                                    isError: !((props.deletedQueryStatus as any).status === 'success')}} />
                            }
                        </div> 
                    }
                </div>
        </ModalContainer>
    );
}

const SavedQueryStatusMessage = ({props}) => {
    return <div className={cx('statusMessage', {error: props.isError})}>
        <Icon className={cx('icon16')} id={props.isError ? ('iconStatusError-' + props.theme) : ('iconStatusOK-' + props.theme)}></Icon>
        {props.message + ' '} {props.isError && <LetUsKnowLink {...props} issue={props.t('saveOpenShare.saveError')}/>}
    </div>
};

export default Open;
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import SortableTable from './SortableTable';
import { getTheme } from '../../state/reducers/AppSettingsReducer';

const mapStateToProps = state => ({ 
  theme: getTheme(state)
});

const SortableTaleContainer = translate()(connect(mapStateToProps)(SortableTable));

export default SortableTaleContainer;
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { getTheme, getSelectedEnvironment } from '../../state/reducers/AppSettingsReducer';
import TsxMigration from './TsxMigration';
import { SET_MIGRATION_MODAL_VISIBILITY, 
        MIGRATE_TSM_VARIABLES, 
        MIGRATE_SAVED_QUERIES, 
        DOWNLOAD_ENTITIES, 
        OPEN_FEEDBACK_FORM } from '../../../constants/ActionTypes';
import { getSavedQueryTSXMigrationStatus, 
        getVariableTSXMigrationStatus, 
        getVariableTSXMigrationResultErrors, 
        getSavedQueryTSXMigrationResultErrors,
        getDownloadTypesForMigrationStatus,
        getSavedQueriesThatNeedMigration,
        getCompletedSavedQueryMigrationsCount,
        getFailedMigratedSavedQueries } from '../../state/reducers/AppReducer';
import { TSMEntityKinds } from '../../../constants/Enums';

const mapStateToProps = state => {
    return { 
        theme: getTheme(state),
        variableTSXMigrationStatus: getVariableTSXMigrationStatus(state),
        savedQueryTSXMigrationStatus: getSavedQueryTSXMigrationStatus(state),
        selectedEnvironment: getSelectedEnvironment(state),
        variableTSXMigrationResultErrors: getVariableTSXMigrationResultErrors(state),
        savedQueryTSXMigrationResultErrors: getSavedQueryTSXMigrationResultErrors(state),
        downloadStatus: getDownloadTypesForMigrationStatus(state),
        savedQueriesThatNeedMigration: getSavedQueriesThatNeedMigration(state),
        completedSavedQueryMigrationsCount: getCompletedSavedQueryMigrationsCount(state),
        failedMigratedSavedQueries: getFailedMigratedSavedQueries(state),
    };
};

const mapDispatchToProps = dispatch => ({ 
    closeModal: (isDone: boolean) => dispatch({type: SET_MIGRATION_MODAL_VISIBILITY, payload: { open: false, isDone } }),
    migrateTSMVariables: () => dispatch({type: MIGRATE_TSM_VARIABLES}),
    migrateSavedQueries: () => dispatch({type: MIGRATE_SAVED_QUERIES}),
    downloadTypes: () => dispatch({type: DOWNLOAD_ENTITIES, payload: {kind: TSMEntityKinds.Types, isForMigration: true}}),
    openFeedbackForm: (issue: string, requestId: string, responseText: string): void => dispatch({ 
        type: OPEN_FEEDBACK_FORM,
        payload: { issue, requestId, responseText }
    })
});

const TsxMigrationContainer = translate()(connect(mapStateToProps, mapDispatchToProps)(TsxMigration));

export default TsxMigrationContainer;

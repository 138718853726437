import 'rxjs';
import { Observable } from 'rxjs/Observable';
import { combineEpics } from 'redux-observable';
import AuthService from './../../services/AuthService';
import Utils from '../../services/Utils';
import { GET_QUERYRESULTS, GET_EVENTS, GET_ENVIRONMENTS, GET_QUERYRESULTS_FULFILLED, 
        GET_EVENTS_FULFILLED, GET_ENVIRONMENTS_FULFILLED, GET_AVAILABILITY, GET_AVAILABILITY_FULFILLED, GET_MODEL, 
        GET_MODEL_FULFILLED, CLEAR_EVENTS, PUT_TYPE, PUT_TYPE_FULFILLED, PUT_HIERARCHY, PUT_HIERARCHY_FULFILLED, 
        PUT_INSTANCES, PUT_INSTANCES_FULFILLED, REMOVE_AGGREGATESQUERY, GET_SHORTLINK, GET_SHORTLINK_FULFILLED, ZOOM, 
        GET_METADATA, GET_METADATA_FULFILLED, SELECT_ENVIRONMENT, SEARCH_INSTANCES_FULFILLED, 
        SEARCH_INSTANCES, GET_MODEL_TYPES, GET_MODEL_TYPES_FULFILLED, GET_MODEL_HIERARCHIES, GET_MODEL_HIERARCHIES_FULFILLED, 
        GET_MODEL_ERROR, GET_ARTIFACTS, GET_ARTIFACTS_FULFILLED, BULK_PARSE_ANALYTICS_STATE, FILL_STATE_FROM_SAVED_QUERY, FILL_STATE_FROM_URL,
        SET_ENVIRONMENT_FROM_SAVED_QUERY, FILL_STATE_FROM_SHORTLINK, GET_TENANTS, GET_TENANTS_FULFILLED, GET_TENANT_NAME, GET_TENANT_NAME_FULFILLED, 
        APPLY_SEARCH_SPAN, DELETE_QUERY, SAVE_QUERY, SET_SAVED_QUERY_NAME, GET_EVENTS_ERROR, CLEAR_DELETE_QUERY, DELETE_QUERY_FULFILLED,
        DELETE_QUERY_FAILED, GET_INSTANCES_FULFILLED, SERIALIZE_STATE_TO_LOCAL_STORAGE, SET_LOADING_AGGREGATES_PROGRESS, SET_LOADING_EVENTS_PROGRESS,
        REGISTER_GQR_CANCEL_HANDLER, REMOVE_TIMESERIESQUERY, RESTORE_PREVIOUS_SESSION, CLEAR_PREVIOUS_SESSION_STATE, STOP_AVAILABILITY_LONG_POLL,
        SET_ACTIVE_PAGE, DOWNLOAD_ENTITIES, DOWNLOAD_ENTITIES_FULLFILLED, SAVE_QUERY_FULFILLED, SET_ISSAVEOPENSHAREVISIBLE, 
        GET_BRUSHED_REGION_STATISTICS_FULFILLED, HIDE_BRUSHED_REGION_STATISTICS, GET_BRUSHED_REGION_STATISTICS, GET_ALL_JOBS, MIGRATE_TSM_VARIABLES, 
        MIGRATE_TSM_VARIABLES_FULFILLED, CHECK_MIGRATION_IS_NECESSARY, MIGRATE_SAVED_QUERIES, MIGRATE_SAVED_QUERIES_FULFILLED, MIGRATE_SINGLE_SAVED_QUERY_FULFILLED, 
        SET_ELEMENTS_THAT_NEED_MIGRATION, SELECT_ENTITY_TYPE, SET_BULK_UPLOAD_INSTANCES_PROGRESS, PUT_INSTANCES_FINALIZING, SUMMARIZE_SAVED_QUERY_RESULTS, GET_MODEL_SETTINGS, GET_MODEL_SETTINGS_FULFILLED } from '../../../constants/ActionTypes';
import { getSelectedEnvironment, getSelectedEnvironmentFqdn, getSelectedEnvironmentIsLts, getSelectedEnvironmentId, getAppSettings, getEndpoint, getActivePage } from '../reducers/AppSettingsReducer';
import { getSearchSpan, getAggregatesQueries, getTimeSeriesQueries, getSavedQueriesArray, getAvailabilityDistribution, getCancelGQRHandler, getWarmStoreRange, 
        getEnvironmentHasWarmStore, getPreviousSessionState, getForceCold, getIsGetSeriesEnabled } from '../reducers/AnalyticsReducer';
import { Subject } from 'rxjs';
import TelemetryService from '../../services/TelemetryService';
import { GetAvailabilityReason, AppPages, TSMEntityKinds, ClientDataFormat, TSXMigrationStatus, StateParserErrorTranslationKeys } from '../../../constants/Enums';
import { getAllJobsEpic, createNewJobEpic, updateJobEpic, loadJobsInJobsPageEpic, getJobLogs } from './JobsEpics'; 

import TimeSeriesQuery from '../../models/TimeSeriesQuery';
import NotificationService from '../../services/NotificationService';
import { NotificationType } from '../../models/AppNotificationModels';
import { getTypes } from '../reducers/ModelReducer';
import { getNotifications, getNotificationHistory, getSavedQueriesThatNeedMigration, getTypesThatNeedMigration, getFailedMigratedSavedQueries } from '../reducers/AppReducer';
import { 
    filterSavedQueriesByNeedToMigrate,
    migrateSavedQuery,
    migrateTSMVariables, 
    MigrationResult, 
    filterTypesByNeedToMigrate} from '../../services/MigrationService';
import { i18nInstance } from '../../../i18n';
import { eventCountThresholdForGetSeries, timeSeriesDefinitions } from '../../../constants/Constants';
 
const getAggregatesEpic = (action$, store) => action$.ofType(GET_QUERYRESULTS)
        .switchMap(() => {
                let state = store.getState();
                const fqdn = getSelectedEnvironmentFqdn(state);
                let ss = getSearchSpan(state);
                let isLts = getSelectedEnvironmentIsLts(state);
                let envId = getSelectedEnvironmentId(state);
                const progressSubject = new Subject<number>();
                if (isLts) {
                    const cancelHandlerSubject = new Subject();
                    let tsqs = getTimeSeriesQueries(state);
                    let availabilityDistribution = getAvailabilityDistribution(state);                    
                    let isWarm = determineIsWarm(state, ss.from, ss.to, availabilityDistribution.retentionPeriod);

                    if (tsqs.length === 0) {
                        return Observable.of({type: GET_QUERYRESULTS_FULFILLED, payload: {result: [], env: {id: envId, isLts: isLts}}});
                    } else {                 
                        // logic to ensure that queries that were just added are the only ones we search, if there were any
                        let tsqArray = [];
                        let indicesWithResults = [];
                        let indicesOfRawTsqs = [];
                        tsqs.forEach((ae, i) => {
                            ae.searchSpan = {...ss}; 
                            if(ae.isRawData) {
                                indicesOfRawTsqs.push(i);
                            }
                        });
                        let queriesJustAdded = tsqs.filter(q => q.isJustAdded);
                        if (queriesJustAdded.length) {
                            tsqs.forEach((q, i) => {
                                if(q.isJustAdded){
                                    indicesWithResults.push(i); 
                                }
                            });
                        } else {
                            indicesWithResults = tsqs.map((q, i) => i);
                            queriesJustAdded = null;
                        }

                        (queriesJustAdded || tsqs).forEach(q => {
                            let tsqExp = q.toTsq(true);
                            if (getIsGetSeriesEnabled(state) && TimeSeriesQuery.isNumericKindVariable(q.variableObject, q.dataType)) { // if avgminmax or custom variable add event count inline variable manually to check if getSeries is possible
                                tsqExp.aggregateSeries.inlineVariables[`${Utils.guidForInlineEventCount}`] = {
                                    kind: 'aggregate',
                                    aggregation: {
                                        tsx: 'count()'
                                    }
                                };
                                tsqExp.aggregateSeries.projectedVariables.push(Utils.guidForInlineEventCount);
                            }
                            tsqArray.push(tsqExp);
                        });
                        
                        if (getCancelGQRHandler(state)) {
                            getCancelGQRHandler(state)();
                        }
                        
                        let ajaxRequest = AuthService.getTsiToken()
                            .mergeMap(token => {
                                let promiseAndCancel = Utils.tsiClient.server.getCancellableTsqResults(token, fqdn, tsqArray, p => progressSubject.next(p/2),false,  isWarm ? 'WarmStore' : 'ColdStore');
                                cancelHandlerSubject.next(promiseAndCancel[1]);
                                cancelHandlerSubject.complete();
                                return promiseAndCancel[0];
                            })
                            .mergeMap((result: any) => {
                                let newResult = tsqs.map(() => {return {timestamps: [], __ignoreResult__: true}});
                                let getSeriesArray = []; // add some of aggregateSeries query results for the second call of getSeries if possible
                                if(result !== '__CANCELLED__'){
                                    result.forEach((r, i) => {
                                        if (getIsGetSeriesEnabled(state) && TimeSeriesQuery.isNumericKindVariable(tsqs[indicesWithResults[i]].variableObject, tsqs[indicesWithResults[i]].dataType) && !r.hasOwnProperty('__tsiError__')) {
                                            let inlineEventCountVariable = r.properties.find(p => p.name === Utils.guidForInlineEventCount);
                                            let eventCount = inlineEventCountVariable.values.reduce((acc, v) => acc + v);
                                            let inlineEventCountVariableIndex = r.properties.findIndex(p => p.name === Utils.guidForInlineEventCount);
                                            r.properties.splice(inlineEventCountVariableIndex, 1); // to remove the inline Event Count variable that was manually added for aggregate series call
                                            if (eventCount < eventCountThresholdForGetSeries) {
                                                let tsqExp = tsqs[indicesWithResults[i]].toTsq(false, false, true);
                                                let rawDataInlineVariable = TimeSeriesQuery.getRawDataInlineVariable(tsqs[indicesWithResults[i]].variableObject, tsqs[indicesWithResults[i]].dataType); // for getSeries rename the measure type to differentiate raw values and only request for avg value or custom variable value
                                                tsqExp.getSeries.inlineVariables = {[rawDataInlineVariable.key]: rawDataInlineVariable.value};
                                                tsqExp.getSeries.projectedVariables = [rawDataInlineVariable.key];
                                                getSeriesArray.push([indicesWithResults[i], tsqExp]);
                                            } else {
                                                let idx = indicesOfRawTsqs.indexOf(indicesWithResults[i]);
                                                if (idx !== -1) {
                                                    indicesOfRawTsqs.splice(idx, 1);
                                                }
                                            }
                                        } else {
                                            let idx = indicesOfRawTsqs.indexOf(indicesWithResults[i]);
                                            if (idx !== -1) {
                                                indicesOfRawTsqs.splice(idx, 1);
                                            }
                                        }
                                        newResult[indicesWithResults[i]] = r;
                                    });
                                }
                                if (getSeriesArray.length) {
                                    let ajaxRequest = AuthService.getTsiToken()
                                        .mergeMap(token => {
                                            let promiseAndCancel = Utils.tsiClient.server.getCancellableTsqResults(token, fqdn, getSeriesArray.map(item => item[1]), p => progressSubject.next(50 + p/2),false,  isWarm ? 'WarmStore' : 'ColdStore');
                                            cancelHandlerSubject.next(promiseAndCancel[1]);
                                            cancelHandlerSubject.complete();
                                            return promiseAndCancel[0];
                                        })
                                        .map((result:any) => {
                                            if(result !== '__CANCELLED__'){
                                                result.forEach((r, i) => {
                                                    if(!indicesOfRawTsqs.includes(getSeriesArray[i][0])) {
                                                        indicesOfRawTsqs.push(getSeriesArray[i][0]);
                                                    }
                                                    newResult[getSeriesArray[i][0]] = r;
                                                });
                                            }
                                            return ({ type: GET_QUERYRESULTS_FULFILLED, payload: {result: newResult, indicesOfRawTsqs, env: {id: envId, isLts: isLts}} });
                                        })
                                        .share();
                                    return Observable.merge(ajaxRequest, 
                                        cancelHandlerSubject.map(payload => ({ type: REGISTER_GQR_CANCEL_HANDLER, payload})),  
                                        progressSubject.map(payload => ({ type: SET_LOADING_AGGREGATES_PROGRESS, payload })).takeUntil(ajaxRequest))
                                        .takeUntil(action$.ofType(SELECT_ENVIRONMENT, REMOVE_TIMESERIESQUERY));
                                } else {
                                    return Observable.of(({ type: GET_QUERYRESULTS_FULFILLED, payload: {result: newResult, indicesOfRawTsqs, env: {id: envId, isLts: isLts}} }));
                                }
                            })
                            .concat(Observable.of({ type: SERIALIZE_STATE_TO_LOCAL_STORAGE }))
                            .share();
                        return Observable.merge(ajaxRequest, 
                            cancelHandlerSubject.map(payload => ({ type: REGISTER_GQR_CANCEL_HANDLER, payload})),  
                            progressSubject.map(payload => ({ type: SET_LOADING_AGGREGATES_PROGRESS, payload })).takeUntil(ajaxRequest))
                            .takeUntil(action$.ofType(SELECT_ENVIRONMENT, REMOVE_TIMESERIESQUERY));
                    }
                }
                else {
                    let aqs = getAggregatesQueries(state);
                    aqs.forEach(ae => ae.searchSpan = ss);
                    let tsxArray = aqs.map(ae => ae.toTsx(true));
                    tsxArray.forEach(tsx => { 
                        if (tsx.aggregates[0].dimension.uniqueValues) {
                            tsx.aggregates[0].dimension.uniqueValues.take = 50; 
                        }
                    });
                    if (tsxArray.length === 0) {
                        return Observable.of({type: GET_QUERYRESULTS_FULFILLED, payload: {result: [], env: {id: envId, isLts: isLts}}});
                    } else {
                        let ajaxRequest = AuthService.getTsiToken()
                                            .mergeMap(token => Utils.tsiClient.server.getAggregates(token, fqdn, tsxArray, p => progressSubject.next(p)))
                                            .map(result => ({ type: GET_QUERYRESULTS_FULFILLED, payload: {result: result, env: {id: envId, isLts: isLts}} }))
                                            .concat(Observable.of({ type: SERIALIZE_STATE_TO_LOCAL_STORAGE }))
                                            .catch(err => Observable.of({type: GET_QUERYRESULTS_FULFILLED, payload: {result: [], env: {id: envId, isLts: isLts}}}))
                                            .share();
                        return Observable.merge(ajaxRequest, progressSubject.map(payload => ({ type: SET_LOADING_AGGREGATES_PROGRESS, payload }))
                            .takeUntil(ajaxRequest))
                            .takeUntil(action$.ofType(SELECT_ENVIRONMENT, REMOVE_AGGREGATESQUERY));
                    }
                }
            }
        );

const getEventsEpic = (action$, store) => action$.ofType(GET_EVENTS)
    .mergeMap(({payload: {predicateObject, options, minMillis, maxMillis}}) => {
        let subject = new Subject<number>();
        let state = store.getState();
        let availabilityDistribution = getAvailabilityDistribution(state);
        let isWarm = getSelectedEnvironmentIsLts(state) && 
            determineIsWarm(state, predicateObject[0].getEvents.searchSpan.from, predicateObject[0].getEvents.searchSpan.to, availabilityDistribution.retentionPeriod);
        let ajaxRequest = AuthService.getTsiToken()
                            .mergeMap(token => getSelectedEnvironmentIsLts(state) ? 
                                            Observable.from(Utils.tsiClient.server.getTsqResults(token, getSelectedEnvironmentFqdn(state), predicateObject, p => subject.next(p), true, isWarm ? 'WarmStore' : 'ColdStore')) : 
                                            Observable.from(Utils.tsiClient.server.getEvents(token, getSelectedEnvironmentFqdn(state), predicateObject,  options, minMillis, maxMillis)))
                            .map((result: any) => getSelectedEnvironmentIsLts(state) ? 
                                        ({ type: GET_EVENTS_FULFILLED, payload: {moreEventsAvailable: (result && result.moreEventsAvailable ? result.moreEventsAvailable : false), events: Utils.tsiClient.ux.transformTsqResultsToEventsArray(result)} }) : 
                                        ({ type: GET_EVENTS_FULFILLED, payload: {events: Utils.tsiClient.ux.transformTsxToEventsArray(result, {})} }))
                            .share();
        return Observable.merge(ajaxRequest, subject.map(payload => ({ type: SET_LOADING_EVENTS_PROGRESS, payload })).takeUntil(ajaxRequest))
        .catch(err => Observable.of({type: GET_EVENTS_ERROR, payload: Utils.getErrorResponse(err)}))
        .takeUntil(action$.ofType(GET_EVENTS, CLEAR_EVENTS));
    });

const getBrushedRegionStatisticsEpic = (action$, store) =>
    action$.ofType(GET_BRUSHED_REGION_STATISTICS)
        .switchMap(({payload: {fromMillis, toMillis}}) => {
            return AuthService.getTsiToken()
            .mergeMap(token => {
                let state = store.getState();
                let availabilityDistribution = getAvailabilityDistribution(state);                    
                let isWarm = determineIsWarm(state, fromMillis, toMillis, availabilityDistribution.retentionPeriod);
                let tsqs = getTimeSeriesQueries(state) as Array<TimeSeriesQuery>;
                let indicesWithResults = [];
                let tsqArray = [];
                tsqs.forEach((q: any, i) => {
                    let isAggregateVariable = Utils.isAggregateVariable(q);
                    if(!q.hidden && !isAggregateVariable){
                        indicesWithResults.push(i);
                        tsqArray.push((q as any).toStatsTsq(fromMillis, toMillis));
                    }
                });
                if(tsqArray.length === 0){
                    let newResult = tsqs.map(() => {return null;});
                    return Observable.of({type: GET_BRUSHED_REGION_STATISTICS_FULFILLED, payload: newResult}) as any;
                }
                else{
                    return Observable.from(Utils.tsiClient.server.getTsqResults(token, getSelectedEnvironmentFqdn(state), tsqArray, () => {}, false, isWarm ? 'WarmStore' : 'ColdStore'))
                                    .map((result: any) => {
                                        let newResult = tsqs.map(() => {return null;});
                                        result.forEach((r, i) => {
                                            newResult[indicesWithResults[i]] = r;
                                        });
                                        return ({type: GET_BRUSHED_REGION_STATISTICS_FULFILLED, payload: newResult});
                                    });
                }
            })
            .takeUntil(action$.ofType(HIDE_BRUSHED_REGION_STATISTICS))
        })

const applySearchSpanEpic = (action$, store) => 
    action$.ofType(APPLY_SEARCH_SPAN)
        .switchMap(({payload}) => {
            let state = store.getState();
            return action$.ofType(GET_QUERYRESULTS_FULFILLED)
            .take(1)
            .mergeMap(result => {
                let zoomPayload = {
                    from: (new Date(state.analytics.searchSpan.from)).valueOf(),
                    to:  (new Date(state.analytics.searchSpan.to)).valueOf(),
                    bucketSize: state.analytics.searchSpan.bucketSize
                };
                return Observable.of({type: ZOOM, payload: zoomPayload});
            })
            .startWith({type: GET_QUERYRESULTS});
        });


const getTenantsEpic = (action$, store) =>
    action$.ofType(GET_TENANTS)
        .mergeMap(() => {
            return AuthService.getManagementToken()
            .mergeMap(token => Utils.promiseHttpRequest(token, `${AuthService.getConstantValue('azureManagement')}tenants?api-version=2020-01-01`, {}, "GET"))
            .mergeMap(response => {
                response = JSON.parse(response as any);
                return Observable.of({type: GET_TENANTS_FULFILLED, payload: response} as any)
                        .concat(Observable.of({type: GET_ENVIRONMENTS}), Observable.of({type: GET_TENANT_NAME}));
            })
            .catch(xhr => {
                if(!AuthService.isMooncake){
                    NotificationService.showErrorNotification('apiErrors.getTenantsFailed', 'apiErrors.getTenantsFailedMessage', xhr);
                }
                return Observable.of({type: GET_TENANTS_FULFILLED, payload: {value: []}} as any)
                                 .concat(Observable.of({type: GET_ENVIRONMENTS}), Observable.of({type: GET_TENANT_NAME}))
            });
        });

const getTenantNameEpic = (action$, store) => 
    action$.ofType(GET_TENANT_NAME)
    .mergeMap(() => {
        return AuthService.getGraphToken()
        .mergeMap(token => Utils.promiseHttpRequest(token, `${AuthService.getConstantValue('graph')}v1.0/organization/${AuthService.tenantId}`, {}, "GET"))
        .mergeMap((response: any) => {
            response = JSON.parse(response);
            let displayName = response && response.displayName ? response.displayName : 'Unknown Directory';
            return Observable.of({type: GET_TENANT_NAME_FULFILLED, payload: displayName});
        })
        .catch(err => Observable.empty());
    });


const getEnvironmentsEpic = (action$, store) =>
    action$.ofType(GET_ENVIRONMENTS)
      .mergeMap(() => {
          return AuthService.getTsiToken()
            .mergeMap(token => {
                if (Utils.isSampleEnvironments()) {
                    return Utils.tsiClient.server.getSampleEnvironments(token, store.getState().appSettings.endpoint);
                }
                else {
                    return Utils.tsiClient.server.getEnvironments(token, store.getState().appSettings.endpoint);
                }
            })
            .mergeMap((response: any) => {
                    // check if user has access to environment passed in url
                    let environmentIdFromUrl = Utils.getUrlParam('environmentId');
                    let matchedEnvironment = (response && response.environments) ? response.environments.filter(env => env.environmentId === environmentIdFromUrl)[0] : null;
                    if(!matchedEnvironment && environmentIdFromUrl){
                        NotificationService.showErrorNotification('accessDenied', i18nInstance.t('noAccessToEnvironment', {id: environmentIdFromUrl}), null);
                    }

                    return Observable.from(response.environments.sort((a, b) => a.displayName.toLowerCase() < b.displayName.toLowerCase() ? -1 : 1))
                    .take(1)
                    .mergeMap(({ environmentFqdn }: any) => {
                        let environmentIdFromUrl = Utils.getUrlParam('environmentId');
                        let localStorageEnvFqdn = localStorage.getItem('rdxEnvironmentFqdn');
                        if (environmentIdFromUrl || localStorageEnvFqdn) {
                            let matchedEnvironment = response.environments.filter(env => env.environmentId === environmentIdFromUrl)[0];
                            matchedEnvironment = matchedEnvironment ? matchedEnvironment : response.environments.filter(env => env.environmentFqdn === localStorageEnvFqdn)[0];
                            if (matchedEnvironment) {
                                return Observable.of({type: SELECT_ENVIRONMENT, payload: {fqdn: matchedEnvironment.environmentFqdn}} as any);
                            }
                            else{
                                return Observable.of({type: SELECT_ENVIRONMENT, payload: {fqdn: environmentFqdn}} as any);
                            }
                        }
                        else if (Utils.isSampleEnvironments()) {
                            return Observable.of({type: SELECT_ENVIRONMENT, payload: {fqdn: '10000000-0000-0000-0000-100000000109.env.timeseries.azure.com'}} as any);
                        }
                        else { 
                            return Observable.of({type: SELECT_ENVIRONMENT, payload: {fqdn: environmentFqdn}} as any);
                        }
                    })
                    .concat(Observable.from([{ type: GET_ENVIRONMENTS_FULFILLED, payload: response },
                        { type: GET_AVAILABILITY, payload: {getAvailabilityReason: GetAvailabilityReason.PageLoadOrEnvironmentChange} },
                        { type: GET_ARTIFACTS},
                        { type: FILL_STATE_FROM_SHORTLINK },
                        ]));
                }
            )
            .catch(xhr => {
                NotificationService.showErrorNotification('apiErrors.getEnvironmentsFailed', 'apiErrors.getEnvironmentsFailedMessage', xhr);
                return Observable.of({type: GET_ENVIRONMENTS_FULFILLED, payload: {environments: []}});
            });
        });
    
const downloadEntitiesEpic = (action$, store) =>
    action$.ofType(DOWNLOAD_ENTITIES)
    .mergeMap(({payload}) => {
        let fqdn = getSelectedEnvironmentFqdn(store.getState());
        if (payload.kind === TSMEntityKinds.Types) {
            return AuthService.getTsiToken()
            .mergeMap(token => Utils.tsiClient.server.getTimeseriesTypes(token, fqdn))
            .map(result => ({type: DOWNLOAD_ENTITIES_FULLFILLED, payload: {json: result['types'], fileName: TSMEntityKinds.Types, isForMigration: payload.isForMigration}}))
            .catch(err => Observable.of({type: DOWNLOAD_ENTITIES_FULLFILLED, payload: {error: Utils.getErrorResponse(err)}}));
        } else if (payload.kind === TSMEntityKinds.Hierarchies) {
            return AuthService.getTsiToken()
            .mergeMap(token => Utils.tsiClient.server.getTimeseriesHierarchies(token, fqdn))
            .map(result => ({type: DOWNLOAD_ENTITIES_FULLFILLED, payload: {json: result['hierarchies'], fileName: TSMEntityKinds.Hierarchies}}))
            .catch(err => Observable.of({type: DOWNLOAD_ENTITIES_FULLFILLED, payload: {error: Utils.getErrorResponse(err)}}));
        } else {
            return AuthService.getTsiToken()
            .mergeMap(token => Utils.tsiClient.server.getTimeseriesInstances(token, fqdn, 10000000))
            .map(result => ({type: DOWNLOAD_ENTITIES_FULLFILLED, payload: {json: result, fileName: TSMEntityKinds.Instances}}))
            .catch(err => Observable.of({type: DOWNLOAD_ENTITIES_FULLFILLED, payload: {error: Utils.getErrorResponse(err)}}))
            .takeUntil(action$.ofType(SELECT_ENVIRONMENT, SELECT_ENTITY_TYPE, SET_ACTIVE_PAGE));
        }
    });


const searchInstancesEpic = (action$, store) =>
    action$.ofType(SEARCH_INSTANCES)
    .mergeMap(({payload}) => {
        let fqdn = getSelectedEnvironmentFqdn(store.getState());
        return AuthService.getTsiToken()
        .mergeMap(token => Utils.tsiClient.server.getTimeseriesInstancesSearch(token, fqdn, payload.searchText, payload.continuationToken))
        .map(result => ({type: SEARCH_INSTANCES_FULFILLED, payload: result}))
        .catch(err => Observable.of({type: SEARCH_INSTANCES_FULFILLED, payload: {error: Utils.getErrorResponse(err)}}));
    });

const searchInstancesInModelPageEpic = (action$, store) => 
    action$.ofType(SELECT_ENVIRONMENT, SET_ACTIVE_PAGE)
    .mergeMap(() => {
        let state = store.getState();
        if (getActivePage(state) === AppPages.Model && getSelectedEnvironmentIsLts(state)) {
            return Observable.from([{type: GET_MODEL_TYPES, payload: {isForSearch: true}},
                {type: GET_MODEL_HIERARCHIES, payload: {isForSearch: true}},
                {type: SEARCH_INSTANCES, payload: {searchText: '', continuationToken: null}},
                {type: GET_MODEL_SETTINGS}
            ])
        } else {
            return Observable.empty();
        }
    });

const getModelSettingsEpic = (action$, store) =>
    action$.ofType(GET_MODEL_SETTINGS)
    .mergeMap(() => {
        let fqdn = getSelectedEnvironmentFqdn(store.getState());
        return AuthService.getTsiToken()
        .mergeMap(token => Utils.tsiClient.server.getTimeseriesModel(token, fqdn))
        .map(result => ({type: GET_MODEL_SETTINGS_FULFILLED, payload: result}))
        .catch(err => Observable.of({type: GET_MODEL_SETTINGS_FULFILLED, payload: {error: Utils.getErrorResponse(err)}}));
    });

const getModelEpic = (action$, store) => 
        action$.ofType(GET_MODEL)
        .mergeMap(() => {
            let state = store.getState();
            if (!getSelectedEnvironmentIsLts(state)) {
                return Observable.of({type: GET_MODEL_ERROR, payload: 'Model not supported'});
            }
            else {
                let fqdn = getSelectedEnvironmentFqdn(state);
                return AuthService.getTsiToken().mergeMap(token => {
                    return Observable.forkJoin(Utils.tsiClient.server.getTimeseriesTypes(token, fqdn), 
                                            Utils.tsiClient.server.getTimeseriesHierarchies(token, fqdn), 
                                            Utils.tsiClient.server.getTimeseriesInstances(token, fqdn, Utils.maxHierarchyInstanceCount))
                            .map(([types, hierarchies, instances]) => ({type: GET_MODEL_FULFILLED, payload: {instances: instances, ...types as {}, ...hierarchies as {}}}));
                })          
                .catch(xhr => {
                    NotificationService.showErrorNotification('apiErrors.getModelFailed', 'apiErrors.getModelFailedMessage', xhr);
                    TelemetryService.logUserAction('getModelFailed', {requestStatus: xhr.status});
                    return Observable.of({type: GET_MODEL_ERROR, payload: Utils.getErrorResponse(xhr)})
                })
                .takeUntil(action$.ofType(SELECT_ENVIRONMENT));
            }
    });

const bulkParseAnalyticsEpic = (action$, store) =>
    action$.ofType(BULK_PARSE_ANALYTICS_STATE)
    .switchMap(() => {
        return AuthService.getTsiToken()
        .mergeMap((token) => {
            let state = store.getState();
            let isLts = getSelectedEnvironmentIsLts(state);

            if (isLts) {
                let tsqs = getTimeSeriesQueries(state);
                let timeSeriesIds = tsqs.map(tsq => tsq.instanceObject.timeSeriesId);
                return timeSeriesIds.length ?
                    Observable.fromPromise(Utils.tsiClient.server.getTimeseriesInstances(token, getSelectedEnvironmentFqdn(store.getState()), Utils.maxHierarchyInstanceCount, timeSeriesIds))
                                        .map((result) => ({type: GET_INSTANCES_FULFILLED, payload: result}))
                                        .catch(err => Observable.of({type: GET_INSTANCES_FULFILLED, payload: {error: Utils.getErrorResponse(err)}})) :
                    Observable.empty();
            } else {
                return Observable.empty();
            }
        })
        .concat(Observable.from([{type: APPLY_SEARCH_SPAN}]));
    });

const loadStateFromUrlEpic = (actions$, store) =>
    actions$.ofType(FILL_STATE_FROM_URL)
    .switchMap(() =>{
        let stateQueryParam = Utils.getUrlParam('state');
        let timeSeriesDefinitionsQueryParam = Utils.getUrlParam(timeSeriesDefinitions);
        let selectedEnvironmentIsLts = getSelectedEnvironmentIsLts(store.getState());
        
        // Parse gen1 style query
        if(!stateQueryParam && timeSeriesDefinitionsQueryParam && !selectedEnvironmentIsLts) {
            TelemetryService.logUserAction('parsingGen1SkuUrl');
            let timeSeriesDefinitions: any, queryParams: any = Utils.queryParams;
            try{
                timeSeriesDefinitions = JSON.parse( decodeURIComponent( timeSeriesDefinitionsQueryParam ) );
            } catch(reason:any) {
                NotificationService.showErrorNotification(i18nInstance.t(StateParserErrorTranslationKeys.jsonParseFailed, {sourcePath: 'timeSeriesDefinitions'}), reason.message, null, {isTitleBypassed: true, isMessageBypassed: true})
                TelemetryService.logUserAction('gen1UrlParseTimeSeriesDefinitionsFailed', { timeSeriesDefinitionsQueryParam, reason });
                return Observable.empty();
            }

            try{
                Object.keys(queryParams).forEach(key => {
                    queryParams[key] = decodeURIComponent(queryParams[key])
                });
            } catch(reason:any){
                NotificationService.showErrorNotification(i18nInstance.t(StateParserErrorTranslationKeys.uriDecodeFailed), reason.message, null, {isTitleBypassed: true, isMessageBypassed: true})
                TelemetryService.logUserAction('gen1DecodeURIComponentFailed', { reason });
                return Observable.empty();
            }

            return Observable.of({
                type: BULK_PARSE_ANALYTICS_STATE, payload: { 
                    clientDataType: ClientDataFormat.Gen1SkuUrl, 
                    clientData: {
                        ...Utils.queryParams,
                        timeSeriesDefinitions
                    }
                }
            });
        } else if(stateQueryParam && selectedEnvironmentIsLts){ // Parse gen2 style query
            TelemetryService.logUserAction('deeplink');
            let json: any;

            try{
                json = JSON.parse( decodeURIComponent( stateQueryParam ) );
            } catch(reason:any) {
                NotificationService.showErrorNotification(i18nInstance.t(StateParserErrorTranslationKeys.jsonParseFailed, {sourcePath: 'state'}), reason.message, null, {isTitleBypassed: true, isMessageBypassed: true})
                return Observable.empty();
            }

            return Observable.of({
                type: BULK_PARSE_ANALYTICS_STATE, payload: { clientDataType: ClientDataFormat.Gen2SkuUrl, clientData: json }
            });
        } else{
            return Observable.empty();
        }
    });

const bulkStateLoadEpic = (action$) => 
    action$.ofType(FILL_STATE_FROM_SAVED_QUERY)
    .switchMap(({payload}) => {
        return action$.ofType(GET_AVAILABILITY_FULFILLED)
        .take(1)
        .mergeMap(() => Observable.of({ type: BULK_PARSE_ANALYTICS_STATE, payload: payload }))
        .concat(Observable.from([{ type: APPLY_SEARCH_SPAN }]))
        .startWith({
            type: GET_AVAILABILITY, payload: {getAvailabilityReason: GetAvailabilityReason.OpenSavedQueryOrShortlink}
        });
    });

const restorePreviousSessionEpic = (action$, store) =>
    action$.ofType(RESTORE_PREVIOUS_SESSION)
    .mergeMap(() => {
        let localStorageStateString = getPreviousSessionState(store.getState());
        if(localStorageStateString){
            try{
                let localStorageState = JSON.parse(localStorageStateString);
                let state = getAppSettings(store.getState());
                let environmentFqdn = Object.keys(state.environments).filter(fqdn => state.environments[fqdn].environmentId === localStorageState.queries[0].environmentId)[0];
                if(environmentFqdn) {
                    return Observable.concat(
                        Observable.defer(() => Observable.of({type: SET_ENVIRONMENT_FROM_SAVED_QUERY, payload: localStorageState })),
                        Observable.defer(() => Observable.of({type: SET_SAVED_QUERY_NAME, payload: localStorageState?.clientData?.analytics?.queryName || '' })),
                        Observable.defer(() => Observable.of({type: FILL_STATE_FROM_SAVED_QUERY, payload: localStorageState })));
                }
                else {
                    return Observable.of({type: CLEAR_PREVIOUS_SESSION_STATE});
                }
            }
            catch{
                return Observable.of({type: CLEAR_PREVIOUS_SESSION_STATE});
            }
        }
        return Observable.of({type: CLEAR_PREVIOUS_SESSION_STATE});
    });

const getStateFromSnapShotEpic = (action$, store) => 
    action$.ofType(FILL_STATE_FROM_SHORTLINK)
    .mergeMap(() => {
        let sid = Utils.getShortlinkId();
        if (sid) {
            return AuthService.getTsiToken()
            .mergeMap(token => {
                return Observable.fromPromise(Utils.promiseHttpRequest(token, Utils.tsiApiEndpoint() + '/snapshots/' + sid + 
                                                Utils.apiVersion, null, 'GET'));
            })
            .mergeMap((response: string) => {
                let snapshotResponse = JSON.parse(response);
                return Observable.concat( Observable.defer(() => Observable.of({type: SET_ENVIRONMENT_FROM_SAVED_QUERY, payload: snapshotResponse })),
                                            Observable.defer(() => Observable.of({type: FILL_STATE_FROM_SAVED_QUERY, payload: snapshotResponse })));
            })
            .catch(xhr => {
                NotificationService.showErrorNotification('accessDenied', 'noAccessToShortlink', xhr);
                return Observable.empty();
            });
        } 
        else if (Utils.isDemo) {
            return Observable.concat(Observable.defer(() => Observable.of({type: SET_ENVIRONMENT_FROM_SAVED_QUERY, payload: Utils.demoState })),
                                        Observable.defer(() => Observable.of({type: FILL_STATE_FROM_SAVED_QUERY, payload: Utils.demoState })));
        }
        else
            return Observable.empty();
    });

const getModelTypesEpic = (action$, store) => 
    action$.ofType(GET_MODEL_TYPES)
    .mergeMap(({payload}) => {
        if (!getSelectedEnvironmentIsLts(store.getState())) {
            return Observable.of({type: GET_MODEL_ERROR, payload: 'Model not supported'});
        }
        else {
            return AuthService.getTsiToken()
            .mergeMap(token => Utils.tsiClient.server.getTimeseriesTypes(token, getSelectedEnvironmentFqdn(store.getState())))
            .map((result: any) => ({type: GET_MODEL_TYPES_FULFILLED, payload: {...result, ...payload}}))
            .concat(payload && payload.shouldCheckMigration ? Observable.of({type: CHECK_MIGRATION_IS_NECESSARY}) : Observable.empty())
            .catch(err => Observable.of({type: GET_MODEL_TYPES_FULFILLED, payload: {error: Utils.getErrorResponse(err)}}));
        }
});

const getModelHierarchiesEpic = (action$, store) => 
    action$.ofType(GET_MODEL_HIERARCHIES)
    .mergeMap(({payload}) => {
        return AuthService.getTsiToken()
        .mergeMap(token => Utils.tsiClient.server.getTimeseriesHierarchies(token, getSelectedEnvironmentFqdn(store.getState())))
        .map((result: any) => ({type: GET_MODEL_HIERARCHIES_FULFILLED, payload: {...result, ...payload}}))
        .catch(err => Observable.of({type: GET_MODEL_HIERARCHIES_FULFILLED, payload: {error: Utils.getErrorResponse(err)}}));
});
   
const getAvailabilityEpic = (action$, store) => action$.ofType(GET_AVAILABILITY)
    .mergeMap(({payload}) => {
        let state = store.getState();
        let hasWarm = getSelectedEnvironmentIsLts(state) && getEnvironmentHasWarmStore(state);
        let endpoint = getSelectedEnvironmentIsLts(state) ? Utils.tsmTsqApiVersion : Utils.apiVersion;
        let environmentFqdn = getSelectedEnvironmentFqdn(state);

        if([GetAvailabilityReason.PageLoadOrEnvironmentChange, GetAvailabilityReason.OpenSavedQueryOrShortlink].includes(payload.getAvailabilityReason)) {
            //remove any previous notifications for previously visited environments to cover switching from lts to non lts environments
            let previousMigrationNotifications = [...getNotifications(state), ...getNotificationHistory(state)].filter(n => n.type === NotificationType.Migration);
            if(previousMigrationNotifications) {
                let ids = previousMigrationNotifications.map(n => n.id);
                ids.forEach(id => {
                    NotificationService.dismissNotification(id);
                });
            }
        }

        if(!environmentFqdn) {
            return Observable.of({type: GET_AVAILABILITY_FULFILLED, payload: {availabilityDistribution: null}});
        }
        return AuthService.getTsiToken()
        .mergeMap(token => Utils.tsiClient.server.getAvailability(token, environmentFqdn, endpoint, hasWarm))
        .mergeMap((result: any) => {
            let returnPayload = {
                availabilityDistribution: null
            } as any;

            if(result !== null){
                if ('availability' in result) {
                    result = result['availability'];
                }

                returnPayload = {
                    availabilityDistribution: (!result || Object.keys(result).length === 0) ? null : result,
                    getAvailabilityReason: payload.getAvailabilityReason
                };
                returnPayload['warmStoreRange'] = (result && result.warmStoreRange) ? result.warmStoreRange : null;
            }
            return Observable.of({ type: GET_AVAILABILITY_FULFILLED, payload: returnPayload });
        })
        .concat(
            [GetAvailabilityReason.PageLoadOrEnvironmentChange, GetAvailabilityReason.OpenSavedQueryOrShortlink].includes(payload.getAvailabilityReason) ? (getSelectedEnvironmentIsLts(state) ? Observable.from([{type: GET_METADATA}, {type: GET_MODEL_SETTINGS}]) : Observable.of({type: GET_METADATA})) : Observable.empty(), 
            [GetAvailabilityReason.PageLoadOrEnvironmentChange].includes(payload.getAvailabilityReason) ? Observable.of({type: FILL_STATE_FROM_URL}) : Observable.empty(),
            [GetAvailabilityReason.PageLoadOrEnvironmentChange].includes(payload.getAvailabilityReason) && getActivePage(state) === AppPages.Jobs && getSelectedEnvironmentIsLts(state) ? Observable.of({type: GET_ALL_JOBS}) : Observable.empty(),
            [GetAvailabilityReason.PageLoadOrEnvironmentChange, GetAvailabilityReason.OpenSavedQueryOrShortlink].includes(payload.getAvailabilityReason) ? Observable.from([{type: GET_MODEL}, {type: GET_MODEL_TYPES, payload: {shouldCheckMigration: getSelectedEnvironmentIsLts(state) ? true : false}}]) : Observable.empty()
        )
        .concat(Observable.defer(() => {
            // special block for autorefresh logic to avoid firing against cold
            if([GetAvailabilityReason.AutoRefresh, GetAvailabilityReason.ManualRefresh].includes(payload.getAvailabilityReason)){

                // S SKUs are good to go
                let isLts = getSelectedEnvironmentIsLts(state);
                if(!isLts){
                    return Observable.of({type: GET_QUERYRESULTS});
                }

                // manual refresh is good to go
                if(payload.getAvailabilityReason === GetAvailabilityReason.ManualRefresh){
                    return Observable.of({type: GET_QUERYRESULTS});
                }
                else{
                    // don't execute auto-refresh queries against cold
                    let ss = getSearchSpan(state);
                    let availabilityDistribution = getAvailabilityDistribution(state);       
                    let isQueryHittingWarmStore = Utils.isQueryRangeInWarmStoreRange([ss.from, ss.to], getWarmStoreRange(state), availabilityDistribution.retentionPeriod) && getEnvironmentHasWarmStore(state) && !getForceCold(state);
                    if(!isQueryHittingWarmStore){
                        NotificationService.showNotification('autoRefresh.notExecutedTitle', 'autoRefresh.notExecutedReason');
                        return Observable.empty();
                    }    
                    else{
                        return Observable.of({type: GET_QUERYRESULTS});
                    }                
                }
            }
        }))
        .catch(xhr => {
            NotificationService.showErrorNotification('apiErrors.getAvailabilityFailed', 'apiErrors.getAvailabilityFailedMessage', xhr);
            TelemetryService.logUserAction('getAvailabilityFailed', {requestStatus: xhr.status, responseText: xhr.response});
            return Observable.of({type: GET_AVAILABILITY_FULFILLED, payload: {availabilityDistribution: null}})
        })
        .takeUntil(action$.ofType(SELECT_ENVIRONMENT, GET_AVAILABILITY, STOP_AVAILABILITY_LONG_POLL));
    });


const getMetadataEpic = (action$, store) => action$.ofType(GET_METADATA)
.mergeMap(() => 
    AuthService.getTsiToken()
    .mergeMap(token => {
        let state = store.getState();
        let availabilityDistribution = getAvailabilityDistribution(state);
        let availabilityRange = availabilityDistribution && availabilityDistribution.range !== undefined ? 
                                    availabilityDistribution.range : {from: new Date(0), to: new Date()};
        
        if (getSelectedEnvironmentIsLts(state)) {
            return Utils.tsiClient.server.getEventSchema(token, getSelectedEnvironmentFqdn(store.getState()), new Date(availabilityRange.from).getTime(), new Date(availabilityRange.to).getTime());
        }
        else {
            return Utils.tsiClient.server.getMetadata(token, getSelectedEnvironmentFqdn(store.getState()), new Date(availabilityRange.from).getTime(), new Date(availabilityRange.to).getTime());
        }
    })
    .mergeMap(result => Observable.of({type: GET_METADATA_FULFILLED, payload: result}))
    .catch(err => Observable.of({type: GET_METADATA_FULFILLED, payload: []}))
    .takeUntil(action$.ofType(SELECT_ENVIRONMENT))
);


const putTypeEpic = (action$, store) => action$.ofType(PUT_TYPE)
    .mergeMap(({payload}) => {
        let isUpload = !payload.entity;  // payload.entity is used for deleting types
        return AuthService.getTsiToken()
        .mergeMap(token => {
            return Observable.fromPromise(Utils.tsiClient.server.postTimeSeriesTypes(token, getSelectedEnvironmentFqdn(store.getState()), payload.entity ? payload.entity : payload))
                    .mergeMap((newApiResults: any) => {
                        if(isUpload){
                            let parsedPayload = JSON.parse(payload);
                            let indicesOfTypesWithOldSyntax = [];
                            newApiResults.put.forEach((r, i) => {
                                let index = JSON.stringify(r).indexOf('UnsupportedTSXVersionTSX01')
                                if(index !== -1){
                                    indicesOfTypesWithOldSyntax.push(i);
                                }
                            });
                            if(indicesOfTypesWithOldSyntax.length){
                                let oldSyntaxPayload = {put: []};
                                parsedPayload.put.forEach((p, i) => {
                                    if(indicesOfTypesWithOldSyntax.indexOf(i) !== -1){
                                        oldSyntaxPayload.put.push(p);
                                    }
                                })  
                                return Observable.fromPromise(Utils.tsiClient.server.postTimeSeriesTypes(token, getSelectedEnvironmentFqdn(store.getState()), JSON.stringify(oldSyntaxPayload), true))
                                        .mergeMap((oldApiResults: any) => {
                                            indicesOfTypesWithOldSyntax.forEach((i, j) => {
                                                newApiResults.put[i] = oldApiResults.put[j];
                                            })
                                            return Observable.of(newApiResults);
                                        })
                                        .catch(err => {
                                            // swallow on fallback to old api call failures
                                            return Observable.of(newApiResults);
                                        })
                            }
                        }
                        return Observable.of(newApiResults);
                    })
        })
        .mergeMap((result: any) => Utils.isResponseWithError(result) ? 
            Observable.of({type: PUT_TYPE_FULFILLED, payload: result})
            : 
            Observable.of({type: PUT_TYPE_FULFILLED, payload: result})
                    .delay(5000)
                    .concat(Observable.from([{type: GET_MODEL_TYPES}, {type: GET_MODEL}]))
        )
        .catch(err => {
            return Observable.of({type: PUT_TYPE_FULFILLED, payload: err && err.response ? JSON.parse(err.response) : err});
        });
    });

const putHierarchyEpic = (action$, store) => action$.ofType(PUT_HIERARCHY)
    .mergeMap(({payload}) => {
        return AuthService.getTsiToken()
        .mergeMap(token => Utils.promiseHttpRequest(token, 'https://' + getSelectedEnvironmentFqdn(store.getState()) + '/timeseries/hierarchies/$batch' + Utils.tsmTsqApiVersion, payload.entity ? payload.entity : payload))
        .mergeMap((result: any) => Utils.isResponseWithError(JSON.parse(result)) ?
            Observable.of({type: PUT_HIERARCHY_FULFILLED, payload: JSON.parse(result)}) :
            Observable.of({type: PUT_HIERARCHY_FULFILLED, payload: JSON.parse(result)})
                    .delay(5000)
                    .concat(Observable.from([{type: GET_MODEL_HIERARCHIES}, {type: GET_MODEL}]))
        )
        .catch(err => {
            return Observable.of({type: PUT_HIERARCHY_FULFILLED, payload: JSON.parse(err.response)});
        });
    });

const putInstancesEpic = (action$, store) => action$.ofType(PUT_INSTANCES)
    .mergeMap(({payload}) => {
        const progressSubject = new Subject<number>();
        let ajaxRequest = AuthService.getTsiToken()
            .mergeMap(token => Utils.promiseHTTPRequestInBatch(token, 'https://' + getSelectedEnvironmentFqdn(store.getState()) + '/timeseries/instances/$batch' + Utils.tsmTsqApiVersion, payload.instances, 'POST', p => progressSubject.next(p)))
            .mergeMap((result: any) => {
                let afterPutObservable = Observable.of({type: SEARCH_INSTANCES, payload: {searchText: payload.searchText, continuationToken: null}})
                                            .delay(5000)
                                            .concat(Observable.from([{type: PUT_INSTANCES_FULFILLED, payload: {method: payload.method, result: JSON.parse(result)}}, {type: GET_MODEL}]));
                return Utils.isResponseWithError(JSON.parse(result)) ?
                    Observable.of({type: PUT_INSTANCES_FULFILLED, payload: {method: payload.method, result: JSON.parse(result)}}) : 
                    payload.isBulk ? 
                        Observable.of({type: PUT_INSTANCES_FINALIZING})
                            .delay(1000)
                            .concat(afterPutObservable) :
                            afterPutObservable 
            })
            .catch(err => (Observable.of({type: PUT_INSTANCES_FULFILLED, payload: {method: payload.method, result: JSON.parse(err.response)}})))
            .share();
        return Observable.merge(ajaxRequest, progressSubject.map(payload => ({type: SET_BULK_UPLOAD_INSTANCES_PROGRESS, payload}))
                .takeUntil(ajaxRequest))
                .catch(err => (Observable.of({type: PUT_INSTANCES_FULFILLED, payload: {method: payload.method, result: JSON.parse(err.response)}})))
    });

const getArtifactsEpic = (action$, store) => action$.ofType(GET_ARTIFACTS)
    .mergeMap(() => {
        return AuthService.getTsiToken()
        .mergeMap(token => Utils.promiseHttpRequest(token, store.getState().appSettings.endpoint + '/artifacts' + Utils.apiVersion, null, 'GET'))
        .mergeMap((response: string) => Observable.from([{type: GET_ARTIFACTS_FULFILLED, payload: JSON.parse(response)}, {type: CHECK_MIGRATION_IS_NECESSARY}]))
        .catch(err => {
            console.log('Get Artifacts error: ' + JSON.stringify(err));
            return Observable.empty();
        });
    });

const deleteStoreStateModalRef = (storeState) => {
    if (storeState.analytics.modalOptions && storeState.analytics.modalOptions.sourceElement) {
        delete storeState.analytics.modalOptions.sourceElement;
    }
    return storeState;
}

const getShortlinkEpic = (action$, store) => action$.ofType(GET_SHORTLINK)
        .mergeMap(() => {
            let storeState = store.getState();
            let activeVisualizationReference = storeState.analytics.activeVisualizationReference;
            delete storeState.analytics.activeVisualizationReference;
            storeState = deleteStoreStateModalRef(storeState);
            let state = JSON.parse(JSON.stringify(storeState));
            let snapshotPayload = Utils.createArtifactsPayload(state, getSelectedEnvironmentId(state), '', 'Environment');
            storeState.analytics.activeVisualizationReference = activeVisualizationReference;
            return AuthService.getTsiToken()
                .mergeMap(token => Utils.promiseHttpRequest(token, getEndpoint(state) + '/snapshots' + Utils.apiVersion, JSON.stringify(snapshotPayload)))
                .map(response => ({type: GET_SHORTLINK_FULFILLED, payload: {didGetShortlink: true, result: response}}))
                .catch(err => {
                    return Observable.of({type: GET_SHORTLINK_FULFILLED, payload: {didGetShortlink: false, result: Utils.formatErrorMessage(err.response)}});
                });
        });

const serializeStateToLocalStorage = (action$, store) => action$.ofType(SERIALIZE_STATE_TO_LOCAL_STORAGE)
        .mergeMap(() => {
            let storeState = store.getState();
            let activeVisualizationReference = storeState.analytics.activeVisualizationReference;
            delete storeState.analytics.activeVisualizationReference;
            storeState = deleteStoreStateModalRef(storeState);
            let state = JSON.parse(JSON.stringify(storeState));
            let snapshotPayload = Utils.createArtifactsPayload(state, getSelectedEnvironmentId(state), '', 'Environment');
            storeState.analytics.activeVisualizationReference = activeVisualizationReference;
            localStorage.setItem('tsiPageState', JSON.stringify(snapshotPayload));
            return Observable.empty();
        })

const saveQueryEpic = (action$, store) => action$.ofType(SAVE_QUERY)
        .mergeMap(({payload}) => {
            let storeState = store.getState();
            let activeVisualizationReference = storeState.analytics.activeVisualizationReference;
            delete storeState.analytics.activeVisualizationReference;
            storeState = deleteStoreStateModalRef(storeState);
            let state = JSON.parse(JSON.stringify(storeState));
            let name = payload.name;
            let environmentId = getSelectedEnvironmentId(store.getState());
            let queryList = getSavedQueriesArray(state);
            let verb = (queryList.map(q => q.name).indexOf(name) === -1) ? 'POST' : 'MERGE';
            let artifactPayload = Utils.createArtifactsPayload(state, environmentId, name, payload.sharingScope);
            storeState.analytics.activeVisualizationReference = activeVisualizationReference;
            if (verb === 'MERGE') {
                let filteredList = queryList.filter((q) => {
                    return q['name'] === name;
                });
                if (filteredList[0] && filteredList[0]['id']) {
                    artifactPayload['id'] = filteredList[0]['id'];   
                }
            }
            return AuthService.getTsiToken()
                .mergeMap(token => 
                    Utils.promiseHttpRequest(token, state.appSettings.endpoint + '/artifacts' + 
                                             (verb === 'MERGE' ? ('/' + artifactPayload['id']) : '') + Utils.apiVersion, 
                                             JSON.stringify(artifactPayload), verb))
                .mergeMap(() => Observable.from([{type: GET_ARTIFACTS}, {type: SAVE_QUERY_FULFILLED, payload: true}])
                                .concat(Observable.from([{type: SAVE_QUERY_FULFILLED, payload: null}, {type: SET_ISSAVEOPENSHAREVISIBLE, payload: false}]).delay(1500)))
                .catch((e) => {
                    TelemetryService.logUserAction('saveArtifactFailed', {error: e.responseText});
                    return Observable.from([{type: GET_ARTIFACTS}, {type: SAVE_QUERY_FULFILLED, payload: Utils.formatErrorMessage(e.response) + (e?.status === 413 ? ` ${i18nInstance.t('payloadTooLarge')}` : '')}]);
                });
        });

const setSavedQueryNameEpic = (action$, store) => action$.ofType(SET_SAVED_QUERY_NAME)
    .mergeMap(() => {
        return Observable.of({type: SERIALIZE_STATE_TO_LOCAL_STORAGE})
    });

const deleteQueryEpic = (action$, store) => action$.ofType(DELETE_QUERY)
    .mergeMap(({payload}) => {
        return AuthService.getTsiToken()
            .mergeMap(token => 
                Utils.promiseHttpRequest(token, store.getState().appSettings.endpoint + '/artifacts/' + payload.id + Utils.apiVersion, 
                                     null, 'DELETE'))
                .flatMap((response) => {
                    return Observable.of(({type: GET_ARTIFACTS}))
                        .delay(2000)
                        .concat(Observable.of({type: CLEAR_DELETE_QUERY}))
                        .startWith({type: DELETE_QUERY_FULFILLED, payload: payload} as any);
                })
                .catch((e) => {
                    return Observable.of(({type: GET_ARTIFACTS}))
                        .delay(2000)
                        .concat(Observable.of({type: CLEAR_DELETE_QUERY}))
                        .startWith({type: DELETE_QUERY_FAILED, payload: "error"} as any);
                })
            .takeUntil(action$.ofType(DELETE_QUERY)); 
    });

const checkMigrationIsNecessaryEpic = (action$, store) =>
    action$.ofType(CHECK_MIGRATION_IS_NECESSARY)
    .mergeMap(() => {
        let state = store.getState();
        let types = getTypes(state);
        let savedQueries = getSavedQueriesArray(state);
        let selectedEnvrionment = getSelectedEnvironment(state);
        let isUserContributor = selectedEnvrionment.roles && selectedEnvrionment.roles.includes("Contributor");

        //remove any previous notifications for previously visited environments
        let previousMigrationNotifications = [...getNotifications(state), ...getNotificationHistory(state)].filter(n => n.type === NotificationType.Migration);
        if(previousMigrationNotifications) {
            let ids = previousMigrationNotifications.map(n => n.id);
            ids.forEach(id => {
                NotificationService.dismissNotification(id);
            });
        }

        let savedQueriesToMigrate = filterSavedQueriesByNeedToMigrate(savedQueries, selectedEnvrionment.environmentFqdn, isUserContributor);
        let typesToMigrate = filterTypesByNeedToMigrate(types, isUserContributor);
        if(typesToMigrate.length > 0 || savedQueriesToMigrate.length > 0) {
            NotificationService.showMigrationNotification();

            return Observable.of({
                type: SET_ELEMENTS_THAT_NEED_MIGRATION,
                payload: {
                    savedQueries: savedQueriesToMigrate,
                    types: typesToMigrate,
                    isUserContributor
                }
            }); 
        }

        return Observable.empty(); 
    });

const migrateTSMVariablesEpic = (action$, store) => 
    action$.ofType(MIGRATE_TSM_VARIABLES)
    .mergeMap(() => {
        const state = store.getState();
        let typesThatNeedMigration = getTypesThatNeedMigration(state).map(t => ({...t}));

        if(typesThatNeedMigration.length === 0) {
            return Observable.of({ 
                type: MIGRATE_TSM_VARIABLES_FULFILLED, 
                payload: { migrationStatus: TSXMigrationStatus.Successful }
            });
        }

        let migrationResult: MigrationResult = migrateTSMVariables(typesThatNeedMigration);
        if (migrationResult.errors.length) { //TODO: maybe we can still send migrationResult.value if migrationResult.errors.length !== types.length for partial upload for successfully migrated types
            let errorPayload = {code: "VariablesValidationsFailInTypeMapper", message: migrationResult.errors.join(',')};
            return Observable.of({
                type: MIGRATE_TSM_VARIABLES_FULFILLED, 
                payload: {migrationStatus: TSXMigrationStatus.Error, error: errorPayload}});
        } else {
            let newTypes = JSON.parse(migrationResult.value);
            let payload = JSON.stringify({put: newTypes});
            return AuthService.getTsiToken()
                .mergeMap(token => Utils.tsiClient.server.postTimeSeriesTypes(token, getSelectedEnvironmentFqdn(store.getState()), payload))
                .mergeMap((result) => {
                    if (Utils.isResponseWithError(result)) {
                        return Observable.of({
                            type: MIGRATE_TSM_VARIABLES_FULFILLED, 
                            payload: {migrationStatus: TSXMigrationStatus.Error, result: result}})
                                .concat(Observable.from([{type: GET_MODEL_TYPES}, {type: GET_MODEL}, {type: GET_QUERYRESULTS}]));
                    } else {
                        let types = result['put'].map(r => r.timeSeriesType);
                        return Observable.of({
                            type: MIGRATE_TSM_VARIABLES_FULFILLED, 
                            payload: {migrationStatus: TSXMigrationStatus.Successful, types: types}})
                                .concat(Observable.from([{type: GET_MODEL_TYPES}, {type: GET_MODEL}, {type: GET_QUERYRESULTS}]))
                    }
                })
                .catch(err => {
                    return Observable.of({type: MIGRATE_TSM_VARIABLES_FULFILLED, payload: {migrationStatus: TSXMigrationStatus.Error, result: JSON.parse(err.response)}});
                });
        }
    });

const migrateSavedQueriesEpic = (action$, store) =>
    action$.ofType(MIGRATE_SAVED_QUERIES)
    .switchMap( () => {
        const maxConcurrentMigrateCalls = 10;
        const state = store.getState();
        const apiEndpoint = getEndpoint(state);
        const queriesThatNeedMigration = getSavedQueriesThatNeedMigration(state);

        if(queriesThatNeedMigration.length === 0) {
            return Observable.of({ 
                type: MIGRATE_SAVED_QUERIES_FULFILLED,
                payload: { migrationStatus: TSXMigrationStatus.Successful }
            });
        }

        return AuthService.getTsiToken()
            .mergeMap(authToken => {
                return Observable.from(queriesThatNeedMigration)
                    .mergeMap((savedQuery: any) => {
                        let migratedQuery = migrateSavedQuery(savedQuery);
                        if(migratedQuery.errors.length > 0) {
                            return Observable.of({
                                id: savedQuery.id,
                                name: savedQuery.name,
                                sharingScope: savedQuery.sharingScope,
                                error: `Failed to migrate TSX: ${migratedQuery.errors.join('; ')}`
                            });
                        }

                        let updateSavedQueryPromise = Utils.tsiClient.server.updateSavedQuery(authToken, migratedQuery.value, apiEndpoint);
                        return Observable.fromPromise(updateSavedQueryPromise)
                        .mergeMap(() => {
                            return Observable.of({
                                id: savedQuery.id,
                                name: savedQuery.name,
                                sharingScope: savedQuery.sharingScope,
                                error: null
                            });
                        })
                        .catch(error => {
                            let errorMessage = error;                            
                            if(errorMessage.response) { // If it's an XHR object, unwrap it
                                let json = JSON.parse(errorMessage.response);
                                errorMessage = json.error && json.error.message ? json.error.message : error;
                            }

                            return Observable.of({
                                id: savedQuery.id,
                                name: savedQuery.name,
                                sharingScope: savedQuery.sharingScope,
                                error: errorMessage
                            });
                        });
                    }, maxConcurrentMigrateCalls)
                    .mergeMap(result => {
                        return Observable.of({
                        type: MIGRATE_SINGLE_SAVED_QUERY_FULFILLED,
                        payload: result
                    })});
            })
            .concat(Observable.of({ type: MIGRATE_SAVED_QUERIES_FULFILLED }))
            .catch(error => {
                let errorMessage = error;

                // If it's an XHR object, unwrap it
                if(errorMessage.response) {
                    let json = JSON.parse(errorMessage.response);
                    errorMessage = json.error && json.error.message ? json.error.message : error;
                }

                return Observable.of({ 
                    type: MIGRATE_SAVED_QUERIES_FULFILLED, 
                    payload: { error: { code: 'TsxParseError', message: errorMessage } } 
                })
            });
    });

// This epic is necessary for the telemetry reducer to know whether saved query migration
// succeeded or any of the calls failed. The MIGRATE_SAVED_QUERIES_FULFILLED action does not
// contain the status of the migration because to determine the overall status, we would 
// need to wait until all individual calls complete. This would block the progress bar from 
// updating. An alternative is to use multicasting, but I think this approach is more straighforward.
const summarizeSavedQueriesResultsEpic = (action$, store) =>
    action$.ofType(MIGRATE_SAVED_QUERIES_FULFILLED)
    .mergeMap(() => {
        const state = store.getState();
        const totalNumberOfQueries = getSavedQueriesThatNeedMigration(state).length;
        const failedMigratedSavedQueries = getFailedMigratedSavedQueries(state);
        const numberOfSuccessfulMigrations = totalNumberOfQueries - failedMigratedSavedQueries.length;

        return Observable.of({
            type: SUMMARIZE_SAVED_QUERY_RESULTS,
            payload: {
                migrationStatus: failedMigratedSavedQueries.length === 0 ? TSXMigrationStatus.Successful : TSXMigrationStatus.Error,
                totalNumberOfQueries,
                numberOfSuccessfulMigrations,
                failedMigratedSavedQueries
            }
        })
    });

const determineIsWarm = (state: any, fromMillis: number, toMillis: number, retentionPeriod: any) => 
    getEnvironmentHasWarmStore(state) 
    && !getForceCold(state)
    && Utils.isQueryRangeInWarmStoreRange([fromMillis, toMillis], getWarmStoreRange(state), retentionPeriod);

export const rootEpic = combineEpics(
    getAggregatesEpic,
    getEventsEpic,
    getEnvironmentsEpic,
    getAvailabilityEpic,
    getModelEpic,
    putTypeEpic,
    putHierarchyEpic,
    putInstancesEpic,
    getShortlinkEpic,
    getMetadataEpic,
    searchInstancesEpic,
    getModelTypesEpic,
    getModelHierarchiesEpic,
    getArtifactsEpic,
    bulkParseAnalyticsEpic,
    bulkStateLoadEpic,
    loadStateFromUrlEpic,
    getStateFromSnapShotEpic,
    getTenantsEpic,
    getTenantNameEpic,
    applySearchSpanEpic,
    saveQueryEpic,
    setSavedQueryNameEpic,
    deleteQueryEpic,
    serializeStateToLocalStorage,
    restorePreviousSessionEpic,
    searchInstancesInModelPageEpic,
    downloadEntitiesEpic,
    getAllJobsEpic, 
    createNewJobEpic,  
    updateJobEpic, 
    getJobLogs,
    getBrushedRegionStatisticsEpic,
    loadJobsInJobsPageEpic,
    migrateTSMVariablesEpic,
    migrateSavedQueriesEpic,
    checkMigrationIsNecessaryEpic,
    getModelSettingsEpic,
    summarizeSavedQueriesResultsEpic
);
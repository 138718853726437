import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import AppContainer from './application/pages/App/App.container';
import { I18nContainer } from './i18n';
import { createEpicMiddleware } from 'redux-observable';
import { rootEpic } from './application/state/epics';
import rootReducer from './application/state/reducers/index';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import AuthService from './application/services/AuthService';
import { registerIcons } from '@fluentui/react/lib/Styling';
import { GeneralIcons } from './constants/CustomFluentIconPacks';
require('es6-object-assign').polyfill(); // object.assign polyfilling to ensure IE can show not supported splash

registerIcons(GeneralIcons);

export const store = createStore(
    rootReducer,
    applyMiddleware(createEpicMiddleware(rootEpic))
);

let render = () => {
    if (window.self === window.top) { 
        ReactDOM.render(
            <Provider store={store}>
                <I18nContainer>
                    <BrowserRouter>
                    <AppContainer />
                    </BrowserRouter>
                </I18nContainer>
            </Provider>,
            document.getElementById('root'))
     }
     else {
        AuthService.login(() => {return; });
     }
}

render();
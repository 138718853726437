import update from 'immutability-helper';
import { Action, IReferenceDataSet, ReferenceDataSetItem } from "../../constants/Interfaces";
import { CLOSE_REFERENCE_DATA_MODAL, DELETE_REFERENCE_DATA_SET, DELETE_REFERENCE_DATA_SET_FULFILLED, GET_REFERENCE_DATA_SETS, 
    GET_REFERENCE_DATA_SETS_FULFILLED, GET_REFERENCE_DATA_SET_ITEMS, GET_REFERENCE_DATA_SET_ITEMS_FULFILLED, OPEN_REFERENCE_DATA_MODAL, SET_SELECTED_REFERENCE_DATA_SET_ID } from "../../constants/ActionTypes";
import { ReferenceDataPrimaryKeyTypes, RequestStatus } from '../../constants/Enums';
import Utils from '../services/Utils';

export const defaultReferenceDataState = {
    isLoadingDataSets: true,
    isLoadingDataSetItems: false,
    referenceDataSets: null, 
    selectedReferenceDataSetId: null, 
    referenceDataSetItems: null,
    deleteFinished: false,
    progress: RequestStatus.Idle,
    errorCode: null,
    errorMessage: null,
    isModalVisible: false,
    isModalForNewDataSet: false
};

export const referenceDataReducer = (state, action: Action) => {
    const payload = action.payload;
    if (typeof state === 'undefined') {
        return defaultReferenceDataState;
    }

    switch (action.type) {
        case GET_REFERENCE_DATA_SETS:
            return update(state, {isLoadingDataSets: {$set: true}, referenceDataSets: { $set: [] }, referenceDataSetItems: {$set: []}, selectedReferenceDataSetId: { $set: null }, progress: {$set: RequestStatus.Idle}, errorCode: {$set: null}, errorMessage: {$set: null}});
        case GET_REFERENCE_DATA_SETS_FULFILLED:
            if (payload.error) { // error from catch block
                return update(state, {progress: {$set: RequestStatus.Error}, errorCode: {$set: Utils.getErrorCode(payload.error)}, errorMessage: {$set: Utils.getErrorMessage(payload.error)}, 
                    isLoadingDataSets: {$set: false}, referenceDataSets: { $set: [] }, selectedReferenceDataSetId: { $set: null }});
            } else {
                const sets = payload.referenceDataSets.map(s => s as IReferenceDataSet);
                const dataSetIdToSetAfter = sets.find(s => s.name === payload.dataSetNameToSetAfter)?.id;
                return update(state, {isLoadingDataSets: {$set: false}, progress: {$set: RequestStatus.Idle}, errorCode: {$set: null}, errorMessage: {$set: null}, referenceDataSets: { $set: sets }, selectedReferenceDataSetId: { $set: dataSetIdToSetAfter || sets[0]?.id }});
            }
        case SET_SELECTED_REFERENCE_DATA_SET_ID:
            return update(state, {selectedReferenceDataSetId: { $set: payload.referenceDataSetId }});
        case GET_REFERENCE_DATA_SET_ITEMS:
            return update(state, {referenceDataSetItems: {$set: []}, isLoadingDataSetItems: {$set: true}});
        case GET_REFERENCE_DATA_SET_ITEMS_FULFILLED:
            if (payload.error) { // error from catch block
                return update(state, {progress: {$set: RequestStatus.Error}, errorCode: {$set: Utils.getErrorCode(payload.error)}, errorMessage: {$set: Utils.getErrorMessage(payload.error)}, 
                    isLoadingDataSetItems: {$set: false}});
            } else {
                const items = payload.referenceDataSetItems.map(s => s as ReferenceDataSetItem);
                const propertyTypes = items[0]?.schema?.properties?.map(p => p.type);

                items.forEach(item => {// for each row, initialize the date object for the datetime type columns, this is needed for sorting
                    item.values.forEach((column, idx) => {
                        if (propertyTypes[idx] === ReferenceDataPrimaryKeyTypes.DateTime) {
                            item.values[idx] = new Date(column);
                        }
                    });
                });

                return update(state, {referenceDataSetItems: { $set: items }, isLoadingDataSetItems: {$set: false}});
            }
        case DELETE_REFERENCE_DATA_SET:
            return update(state, {deleteFinished: {$set: false}, progress: {$set: RequestStatus.Publishing}, errorCode: {$set: null}, errorMessage: {$set: null}});
        case DELETE_REFERENCE_DATA_SET_FULFILLED:
            if (payload.error) { // error from catch block
                return update(state, {progress: {$set: RequestStatus.Error}, errorCode: {$set: Utils.getErrorCode(payload.error)}, errorMessage: {$set: Utils.getErrorMessage(payload.error)}, 
                    deleteFinished: {$set: true}});
            } else {
                return update(state, {deleteFinished: {$set: true}, progress: {$set: RequestStatus.Successful}, errorCode: {$set: null}, errorMessage: {$set: null}});
            }
        case OPEN_REFERENCE_DATA_MODAL:
            return update (state, {isModalVisible: {$set: true}, isModalForNewDataSet: {$set: action.payload.isForNew}});
        case CLOSE_REFERENCE_DATA_MODAL:
            return update (state, {isModalVisible: {$set: false}, isModalForNewDataSet: {$set: false}});
        default:
            return state;
    }
};

export const getSelectedReferenceDataSetName = state => state.referenceDataSets?.find(s => s.id === state.selectedReferenceDataSetId)?.name;
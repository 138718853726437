import React from 'react';
import classNames from 'classnames/bind';
import DropdownMenuContainer from '../../DropdownMenu/DropdownMenu.container';
import Icon from '../../Icon/Icon';
import {milliToReadableTimeSpanMap, milliToReadableIntervalMap } from '../../../../constants/Constants'
const cx = classNames.bind(require('./AutoRefreshSettings.module.scss'));

interface Props { t: any; theme: string; autoRefreshTimeSpanMillis: number, setAutoRefreshTimeSpan: (millis: number, isEnabled: boolean) => any;
    isAutoRefreshEnabled: boolean; autoRefreshCycleMillis: number; setAutoRefreshCycle: (cycleMillis: number, isEnabled: boolean) => any;}

export default function AutoRefreshSettings (props:Props) {
    return (
        <div className={cx('settingsTab')}>
            <h4 className={cx('selectionHeader')}>{props.t('autoRefresh.timeSpan')}</h4>
            <DropdownMenuContainer 
                dropdownMenuOptions={
                    Object.keys(milliToReadableTimeSpanMap).map(key => [null, props.t(`autoRefresh.${key}`), () => {
                        props.setAutoRefreshTimeSpan(Number(key), props.isAutoRefreshEnabled);
                    }])
                } 
                dropdownMenuSide='right'
                containerClassName={cx('dropdownMenuWrapper')}
                menuClassName={cx("dropdownMenu")}
                buttonClassName={cx("dropdownButton")}
                menuItemClassName={cx("dropdownMenuOption")}
                menuItemIconClassName={cx("dropdownMenuItemIcon")}
                menuButtonLabel={props.t(`autoRefresh.${props.autoRefreshTimeSpanMillis}`)}
                selectedValue={props.t(`autoRefresh.${props.autoRefreshTimeSpanMillis}`)}
            >
                <div className={cx('buttonContent')}>
                    <div className={cx('buttonContentText')}>
                        {props.t(`autoRefresh.${props.autoRefreshTimeSpanMillis}`)}
                    </div>
                    <Icon id='chevron' className={cx('chevron')}></Icon>
                </div>
            </DropdownMenuContainer>

            <h4 className={cx('selectionHeader')}>{props.t('autoRefresh.refreshRate')}</h4>
            <DropdownMenuContainer 
                dropdownMenuOptions={
                    Object.keys(milliToReadableIntervalMap).map(key => [null, props.t(`autoRefresh.${key}`), () => {
                        props.setAutoRefreshCycle(Number(key), props.isAutoRefreshEnabled);
                    }])
                } 
                dropdownMenuSide='right'
                containerClassName={cx('dropdownMenuWrapper')}
                menuClassName={cx("dropdownMenu")}
                buttonClassName={cx("dropdownButton")}
                menuItemClassName={cx("dropdownMenuOption")}
                menuItemIconClassName={cx("dropdownMenuItemIcon")}
                menuButtonLabel={props.t(`autoRefresh.${props.autoRefreshCycleMillis}`)}
                selectedValue={props.t(`autoRefresh.${props.autoRefreshCycleMillis}`)}
            >
                <div className={cx('buttonContent')}>
                    <div className={cx('buttonContentText')}>
                        {props.t(`autoRefresh.${props.autoRefreshCycleMillis}`)}
                    </div>
                    <Icon id='chevron' className={cx('chevron')}></Icon>
                </div>
            </DropdownMenuContainer>
        </div>
    ); 
}
import React from "react";
import ModalContainer from "../../Modal/Modal.container";
import {ProgressMessageField} from "./ProgressMessageField";
import TooltipableContainer from "../../Tooltipable/Tooltipable.container";
import classNames from 'classnames/bind';
import update from 'immutability-helper';
import Icon from "../../Icon/Icon";
import Utils from "../../../services/Utils";
import Header from "./Header";
import { InstanceField } from "../../../models/InstanceField";
const cx = classNames.bind(require('../TsmModal.module.scss'));

interface Props {instance: any; tsmModalComponent: any; }

export default class InstanceModal extends React.Component<Props> {
    private timeSeriesIdTitleID = Utils.customID('timeSeriesIdTitle');
    private timeSeriesIdNullID = Utils.customID('timeSeriesIdNull');
    private tsidErrorFieldID = Utils.customID('tsidErrorField');
    private timeSeriesTypeTitleID = Utils.customID('timeSeriesTypeTitle');
    private typeErrorFieldID = Utils.customID('typeErrorField');
    private timeSeriesNameTitleID = Utils.customID('timeSeriesNameTitle');
    private timeSeriesDescriptionTitleID = Utils.customID('timeSeriesDescriptionTitle');
    private instanceFieldTableTitleID = Utils.customID('instanceFieldTableTitle');
    private progressMessageFieldID = Utils.customID('progressMessageField');
    private instanceFieldNameErrorFieldID = Utils.customID('instanceFieldNameErrorField');
    private instanceFieldNameUniqueErrorFieldID = Utils.customID('instanceFieldNameUniqueErrorField')

    handleIdChange = (event, idx, setToNull = null) => {
        if (setToNull) {
            this.props.tsmModalComponent.setState(update(this.props.tsmModalComponent.state, {entity: {timeSeriesId: {[idx]: {$set: null}}}}));
        } else {
            this.props.tsmModalComponent.setState(update(this.props.tsmModalComponent.state, {entity: {timeSeriesId: {[idx]: {$set: event.target.value ? event.target.value : ''}}}}));
        }
        
    };

    handleNameChange = event => {
        this.props.tsmModalComponent.setState(update(this.props.tsmModalComponent.state, {entity: {name: {$set: event.target.value}}}));
    };

    handleTypeChange = (event) => {
        this.props.tsmModalComponent.setState(update(this.props.tsmModalComponent.state, {entity: {typeId: {$set: event.target.value}}}));
    };

    handleDescriptionChange = event => {
        this.props.tsmModalComponent.setState(update(this.props.tsmModalComponent.state, {entity: {description: {$set: event.target.value}}}));
    };

    handleHierarchyChange = (hierarchyId) => (e) => {
        if (Utils.isKeyDownAndNotEnter(e)) {return; }
        let newState;
        if (this.props.instance.hierarchyIds?.indexOf(hierarchyId) >= 0) {
            newState = removeHierarchy(this.props.tsmModalComponent.state, hierarchyId);
            newState = removeInstanceFields(newState, hierarchyId, this.props.tsmModalComponent.props.hierarchies);
        } else {
            newState = addHierarchy(this.props.tsmModalComponent.state, hierarchyId);
            let selectedH = this.props.tsmModalComponent.props.hierarchies.find(h => h.id === hierarchyId);
            selectedH.source.instanceFieldNames.forEach(iF => {
                newState = addInstanceField(newState, iF);
            });
        }
        this.props.tsmModalComponent.setState(newState);
    };

    handleInstanceFieldChange = (idx) => (event) => {
        this.props.tsmModalComponent.setState(update(this.props.tsmModalComponent.state, {entity: {instanceFields: {[idx]: {value: {$set: event.target.value}}}}}));
    };

    handleInstanceFieldNameChange = (idx) => (event) => {
        this.props.tsmModalComponent.setState(update(this.props.tsmModalComponent.state, {entity: {instanceFields: {[idx]: {name: {$set: event.target.value}}}}}));
    };

    isInstanceFieldIsInHierarchy = (name: string) => {
        let exist = false;
        if (this.props.instance.hierarchyIds) {
            this.props.instance.hierarchyIds.forEach((hId) => {
                let hierarchy = this.props.tsmModalComponent.props.hierarchies.find(h => {return hId === h.id; });
                if (hierarchy?.source.instanceFieldNames.indexOf(name) >= 0) {
                    exist = true;
                    return;
                }
            });
        }
        return exist;
    };

    render () {
        this.props.instance.updateErrors();
        const tsmModalComponent = this.props.tsmModalComponent;
        return (
            <ModalContainer onClose={tsmModalComponent.closeModal} 
                            title={tsmModalComponent.props.entityToEdit ? tsmModalComponent.props.t('tsm.edit') + " " + (tsmModalComponent.props.entityToEdit.name ? Utils.stripHits(tsmModalComponent.props.entityToEdit.name) : Utils.getTimeSeriesIdToDisplay(tsmModalComponent.props.entityToEdit)) : tsmModalComponent.props.t('tsm.instance.new')} 
                            className={cx('tsmEntityModal')} contentPositionContainerClassName={cx('tsmModalContentPositionContainer')} 
                            wrapperClassName={cx('tsmModalContentContainer')} contentClassName={cx('tsmModalContent')} isOffModalAutoClosingEnabled={false}>
                <div className={cx('modalContent')}>
                    <div className={cx('entityContainer')}>
                        <div className={cx('tabs', {lessMinHeight: tsmModalComponent.state.isSubEntityTab})}>
                            <button className={cx('tab', {selected: !tsmModalComponent.state.isSubEntityTab})} 
                                onClick={() => tsmModalComponent.setIsSubEntityTab(false)} role="tab"
                                aria-selected={!tsmModalComponent.state.isSubEntityTab}>
                                {tsmModalComponent.props.t('tsm.properties')}
                            </button>
                            <button className={cx('tab', {selected: tsmModalComponent.state.isSubEntityTab})} 
                                onClick={() => tsmModalComponent.setIsSubEntityTab(true)} role="tab"
                                aria-selected={tsmModalComponent.state.isSubEntityTab}>
                                {tsmModalComponent.props.t('tsm.instanceFields')}
                            </button>
                        </div>
                        <div className={cx('form')}>
                            {!tsmModalComponent.state.isSubEntityTab ? 
                                <div className={cx('build-area', 'instance')}>
                                    <div className={cx('block')}>
                                        <div id={this.timeSeriesIdTitleID} className={cx('title')}>{tsmModalComponent.props.t('timeSeriesId')}</div> 
                                        {this.props.instance.timeSeriesId.map((id, idx) =>
                                            <div key={'tsidContainer-' + idx} className={cx('tsidContainer', idx === this.props.instance.timeSeriesId.length - 1 ? 'last' : '')}>
                                                <input aria-labelledby={this.timeSeriesIdTitleID} aria-describedby={this.tsidErrorFieldID} 
                                                        disabled={tsmModalComponent.props.entityToEdit || id === null} 
                                                        placeholder={`${tsmModalComponent.props.timeSeriesIdProperties[idx].name} (${tsmModalComponent.props.timeSeriesIdProperties[idx].type})`}
                                                        className={cx('tsid', this.props.instance.errors?.id ? 'error' : '')} 
                                                        type="text" value={id === null ? '' : id} onChange={(e) => this.handleIdChange(e, idx)}/>
                                                <div className={cx('_base-radioButton', 'nullTsidContainer')}>
                                                    <button role="checkbox" className={cx('_base-transparentBgButton')}
                                                        disabled={tsmModalComponent.props.entityToEdit}
                                                        aria-checked={id === null}
                                                        onClick={(e) => this.handleIdChange(e, idx, id !== null)}
                                                        aria-labelledby={this.timeSeriesIdNullID}>
                                                        <Icon id='checkbox' className={cx('icon16') + ' tsiCb ' +  (id === null ? 'tsiCbSelected' : '')} />
                                                    </button>
                                                    <span className={cx(tsmModalComponent.props.entityToEdit ? 'disabled' : '', '_base_mono')} id={this.timeSeriesIdNullID} onClick={(e) => {if(!tsmModalComponent.props.entityToEdit) {this.handleIdChange(e, idx, id !== null)}}}>null</span>
                                                </div>
                                            </div>
                                        )}
                                        {this.props.instance.errors?.id && <div id={this.tsidErrorFieldID} role="alert" className={cx('error-message', '_base-fade-in')}>{tsmModalComponent.props.t('tsm.instance.validations.id')}</div>}
                                    </div>
                                    <div className={cx('block')}>
                                        <div id={this.timeSeriesTypeTitleID} className={cx('title')}>{tsmModalComponent.props.t('tsm.type.title')}</div> 
                                        <select aria-labelledby={this.timeSeriesTypeTitleID} aria-describedby={this.typeErrorFieldID} className={cx(this.props.instance.errors?.typeId ? 'error' : '')} value={this.props.instance.typeId ? this.props.instance.typeId : ""} onChange={this.handleTypeChange}>
                                            <option key="N/A" value="">{tsmModalComponent.props.t('tsm.defaultOption')}</option>
                                            {tsmModalComponent.props.types.map((t, idx) => <option key={t.id} value={t.id}>{t.name}</option>)}
                                        </select>
                                        {this.props.instance.errors?.typeId && <div id={this.typeErrorFieldID} role="alert" className={cx('error-message', '_base-fade-in')}>{tsmModalComponent.props.t('tsm.instance.validations.type')}</div>}
                                    </div>
                                    <div className={cx('block')}>
                                        <div id={this.timeSeriesNameTitleID} className={cx('title')}>{tsmModalComponent.props.t('tsm.name')}</div> 
                                        <input aria-labelledby={this.timeSeriesNameTitleID} placeholder={tsmModalComponent.props.t('tsm.instance.namePlaceholder')} type="text" value={this.props.instance.name || ''} onChange={this.handleNameChange}/>
                                    </div>
                                    <div className={cx('block')}>
                                        <div id={this.timeSeriesDescriptionTitleID} className={cx('title')}>{tsmModalComponent.props.t('tsm.description')}</div> 
                                        <textarea aria-labelledby={this.timeSeriesDescriptionTitleID} value={this.props.instance.description ? this.props.instance.description : "" } onChange={this.handleDescriptionChange}/>
                                    </div>
                                </div>
                                :
                                <div className={cx('build-area', 'instance')}>
                                    <div className={cx('block')}>
                                        <div className={cx('title', 'bold')}>{tsmModalComponent.props.t('tsm.hierarchies')}</div>
                                        <div className={cx('checkbox-list')}>
                                            {tsmModalComponent.props.hierarchies.length ? tsmModalComponent.props.hierarchies?.map((h, idx) => 
                                                <div key={h.id} className={cx('hierarchy')}>
                                                        <div className={cx('checkbox-line')}>
                                                            <button role="checkbox" className={cx('_base-transparentBgButton')}
                                                                title={this.props.instance.hierarchyIds?.indexOf(h.id) >= 0 ? tsmModalComponent.props.t('unselect') : tsmModalComponent.props.t('select')}
                                                                aria-checked={this.props.instance.hierarchyIds?.indexOf(h.id) >= 0}
                                                                onClick={this.handleHierarchyChange(h.id)}
                                                                aria-labelledby={"checkboxTitle_" + h.id}>
                                                                <Icon id='checkbox' className={cx('icon16') + ' tsiCb ' +  (this.props.instance.hierarchyIds?.indexOf(h.id) >= 0 ? 'tsiCbSelected' : '')} />
                                                            </button>
                                                            <span id={"checkboxTitle_" + h.id} onClick={this.handleHierarchyChange(h.id)}>{h.name}</span>
                                                        </div>
                                                </div>)
                                            : <div tabIndex={0} className={cx('sub-block', '_base-fade-in', 'italic')}>{tsmModalComponent.props.t('tsm.hierarchy.noHierarchyAddedYet')}</div>
                                            }
                                        </div>
                                    </div>
                                    <div className={cx('block')}>
                                        <div className={cx('title', 'bold')} id={this.instanceFieldTableTitleID}>{tsmModalComponent.props.t('tsm.instanceFields')}</div>
                                        <div className={cx('grid')}>
                                            {this.props.instance.instanceFields && this.props.instance.instanceFields.length ?
                                                <table aria-labelledby={this.instanceFieldTableTitleID}>
                                                    <Header headers={[tsmModalComponent.props.t('tsm.name'), tsmModalComponent.props.t('tsm.type.variable.value'), '']} tsmModalComponent={tsmModalComponent}/>
                                                    <tbody>
                                                        {this.props.instance.instanceFields.map((iF, idx) => 
                                                            <tr key={"instanceField-" + idx} className={cx('_base-fade-in')} ref={el => { if (this.props.instance.instanceFields !== undefined && idx === this.props.instance.instanceFields.length - 1) tsmModalComponent.entitiesEnd = el; }}>
                                                                <td className={cx('instanceFieldName')}>
                                                                    {this.isInstanceFieldIsInHierarchy(iF.name) ? 
                                                                        <span title={iF.name + " " + tsmModalComponent.props.t('tsm.instance.fromHierarchy')}>{iF.name} {tsmModalComponent.props.t('tsm.instance.fromHierarchy')}</span>
                                                                    :
                                                                        <input aria-label={tsmModalComponent.props.t('tsm.instance.fieldName')} 
                                                                                aria-describedby={this.instanceFieldNameErrorFieldID + "_" + idx + " " + this.instanceFieldNameUniqueErrorFieldID + "_" + idx} 
                                                                                placeholder={tsmModalComponent.props.t("tsm.instance.addFieldName")} 
                                                                                type="text" value={iF.name} onChange={this.handleInstanceFieldNameChange(idx)} disabled={this.isInstanceFieldIsInHierarchy(iF.name)}/>
                                                                    }
                                                                    {iF.errors?.name && <div id={this.instanceFieldNameErrorFieldID + "_" + idx} role="alert" className={cx('error-message', '_base-fade-in', 'inGridHalfSize')}>{tsmModalComponent.props.t('tsm.instance.validations.instanceField.name')}</div>}
                                                                    {iF.errors?.name_unique && <div id={this.instanceFieldNameUniqueErrorFieldID + "_" + idx} className={cx('error-message', '_base-fade-in', 'inGridHalfSize')}>{tsmModalComponent.props.t('tsm.instance.validations.instanceField.name_unique')}</div>}
                                                                </td>
                                                                <td className={cx('instanceFieldValue')}>
                                                                    <input aria-label={tsmModalComponent.props.t('tsm.instance.fieldValue') + tsmModalComponent.props.t('for') + ". " + iF} 
                                                                            placeholder={tsmModalComponent.props.t("tsm.instance.addFieldValue")} type="text" value={iF.value} onChange={this.handleInstanceFieldChange(idx)}/>
                                                                </td>
                                                                <td>
                                                                    <button className={cx('action-button')} onClick={tsmModalComponent.removeEntityProperty(idx)} onKeyDown={tsmModalComponent.removeEntityProperty(idx)} title={tsmModalComponent.props.t('remove')} aria-label={tsmModalComponent.props.t('tsm.instance.removeInstanceField')}>
                                                                        <Icon id={"iconClose-" + tsmModalComponent.props.theme} className={cx('icon16')}/>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>   
                                            : <div tabIndex={0} className={cx('sub-block', '_base-fade-in', 'italic')}>{tsmModalComponent.props.t('tsm.instance.noInstanceFieldYet')}</div>
                                            }
                                        </div>
                                        <div className={cx('sub-button')}>
                                            <button aria-label={tsmModalComponent.props.t('tsm.instance.addInstanceField')}
                                                onClick={tsmModalComponent.addEntityProperty}>
                                                <Icon id={'iconAddBlue'} className={cx('icon16')}/>
                                                <span>{tsmModalComponent.props.t('tsm.instance.addInstanceField')}</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                }
                        </div>
                    </div>
                    <div className={cx('tsmModalBottom')}>
                        <button className={cx('showJson')} aria-label={tsmModalComponent.props.t('tsm.viewJson')} role="link" tabIndex={0} onKeyDown={tsmModalComponent.showJSON} onClick={tsmModalComponent.showJSON}><Icon id={"iconDocument"} className={cx('icon16')}/>{tsmModalComponent.props.t('tsm.viewJson')}</button>
                        <div className={cx('buttonWrapper')}>
                            <button aria-label={tsmModalComponent.props.t('cancel')} className={cx('cancelButton')} onKeyDown={tsmModalComponent.closeModal} onClick={tsmModalComponent.closeModal}>{tsmModalComponent.props.t('cancel')}</button>
                            {this.props.instance.hasErrors() ?
                                <TooltipableContainer tooltip={tsmModalComponent.props.t('tsm.fixErrorsToSave')} position={'top'}>
                                    <button aria-label={tsmModalComponent.props.t('save')} aria-disabled="true" disabled className={cx('saveButton', 'disabled')}>{tsmModalComponent.props.t('save')}</button>
                                </TooltipableContainer>
                            :
                                <button aria-label={tsmModalComponent.props.t('save')} className={cx('saveButton')} onKeyDown={tsmModalComponent.createOrEditEntity} onClick={tsmModalComponent.createOrEditEntity} aria-describedby={this.progressMessageFieldID}>{tsmModalComponent.props.t('save')}</button>
                            }
                        </div>
                    </div>
                    <ProgressMessageField 
                        cx={cx}
                        entityType={tsmModalComponent.props.entityType} 
                        progress={tsmModalComponent.props.progress} 
                        errorCode={tsmModalComponent.props.errorCode} 
                        errorMessage={tsmModalComponent.props.errorMessage} 
                        method={tsmModalComponent.props.requestMethod} 
                        theme={tsmModalComponent.props.theme} 
                        t={tsmModalComponent.props.t}
                        progressMessageFieldID={this.progressMessageFieldID}/>
                </div>
            </ModalContainer>
        )
    }
}

function addHierarchy(state, hId) {
    if (!state.entity.hierarchyIds) {
        return update(state, {entity : {hierarchyIds: {$set: [hId]}}});
    } else {
        return update(state, {entity : {hierarchyIds: {$push: [hId]}}});
    }
}

function removeHierarchy(state, hId) {
    if (state.entity.hierarchyIds.length === 1) {
        return update(state, {entity: {$unset: ['hierarchyIds']}});
    } else {
        let hierarchyIndex = state.entity.hierarchyIds.findIndex(x => x === hId);
        return update(state, {entity: {hierarchyIds: {$splice: [[hierarchyIndex, 1]]}}});
    }
}

function addInstanceField(state, field) {
    if (!state.entity.instanceFields) {
        return update(state, {entity : {instanceFields: {$set: [InstanceField.fromObject({name: field})]}}});
    } else if (state.entity.instanceFields.filter(iF => iF.name === field).length === 0) {
        return update(state, {entity : {instanceFields: {$push: [InstanceField.fromObject({name: field})]}}});
    }
    else {
        return state;
    }
}

function removeInstanceFields(state, removedHierarchyId, hierarchies) {
    let removedHierarchy = hierarchies.find(h => h.id === removedHierarchyId);
    let instanceFieldsToBeRemoved = [];
    if (state.entity.instanceFields) {
        removedHierarchy.source.instanceFieldNames.forEach(iFToRemove => {
            let existInOtherHierarchy = false;
            if (state.entity.hierarchyIds) {
                state.entity.hierarchyIds.forEach((hId) => {
                    let otherHierarchy = hierarchies.find(h => hId === h.id);
                    if (otherHierarchy?.source.instanceFieldNames.indexOf(iFToRemove) >= 0) {
                        existInOtherHierarchy = true;
                        return;
                    }
                });
            }
            if (!existInOtherHierarchy) {
                instanceFieldsToBeRemoved.push(iFToRemove);
            }
        });
        if (!state.entity.instanceFields.length) {
            return update(state, {entity: {$unset: ['instanceFields']}});
        } else {
            let newInstanceFieldArray = state.entity.instanceFields.filter(iF => !instanceFieldsToBeRemoved.includes(iF.name));
            return update(state, {entity: {instanceFields: {$set: newInstanceFieldArray}}});
        }
    } else {
        return state;
    }
}
import * as React from 'react';
import classNames from 'classnames/bind';
import Icon from '../Icon/Icon';
import ModalContainer from '../../components/Modal/Modal.container';
import { KeyCodes } from '../../../constants/Enums';
const cx = classNames.bind(require('./NewFeatureSplash.module.scss'));

interface Props { t: any; theme: string; firstLinkRef: any; title: string; hideSplash: any; localStorageKey: string; noFivehead?: boolean; noButtons?: boolean; mustDismissWithButton?: boolean; shorter?: boolean} 
interface State {}

export default class NewFeatureSplash extends React.Component<Props, State> {
    private gotItBtnRef: any;

    constructor(props: Props) {
        super(props);
        this.gotItBtnRef = React.createRef();
        
    }

    componentWillMount = () => {
        if(this.props.firstLinkRef && this.props.firstLinkRef.current){
            this.props.firstLinkRef.onKeyDown = (e) => {
                if (e.keyCode === KeyCodes.Tab && e.shiftKey) {
                    e.preventDefault();
                    this.focusGotItBtn();
                }
            }
        }
    }

    private gotItBtnKeyDown = (e) => {
        if (e.keyCode === KeyCodes.Tab && !e.shiftKey) {
            e.preventDefault();
            this.focusFirstLink();
        }
    }

    private focusFirstLink () {
        this.props.firstLinkRef && this.props.firstLinkRef.current && this.props.firstLinkRef.current.focus();
    }

    private focusGotItBtn () {
        this.gotItBtnRef && this.gotItBtnRef.current && this.gotItBtnRef.current.focus();
    }

    componentDidMount() {
        setTimeout(() => {
            this.focusGotItBtn();
        });                         
    }  
    
    render () {
        return <ModalContainer isOffModalAutoClosingEnabled={!this.props.mustDismissWithButton} hasCloseButton={this.props.noButtons} className={cx('fiveheadSplashWrapper', 'newFeatureSplashWrapper', {shorter: this.props.shorter})} onClose={() => this.props.hideSplash()}>
            <div className={cx('content')}>
                <div className={cx('fivehead', {hidden: this.props.noFivehead})} >
                    <Icon id='logo' className={cx('logo')}></Icon>                  
                </div>
                <div className={cx('text')}>
                    <h1 className={cx('title')}>
                        {this.props.title}
                    </h1>
                    {this.props.children}
                    <button className={cx('dismissA', {hidden: this.props.noButtons})} onClick={() => {localStorage.setItem(this.props.localStorageKey, 'true'); this.props.hideSplash(); }}>
                        {this.props.t('newFeatureSplash.dontShow')}
                    </button>
                    <button className={cx('gotItButton', {hidden: this.props.noButtons})} onClick={this.props.hideSplash} onKeyDown={this.gotItBtnKeyDown}
                        ref={this.gotItBtnRef}>
                        {this.props.t('newFeatureSplash.gotIt')}
                    </button>
                </div>
            </div>
        </ModalContainer>;
    }
}
import * as React from 'react';
import { RequestStatus, TSMEntityKinds, RequestMethods } from '../../../../constants/Enums';
import Icon from '../../Icon/Icon';

export const ProgressMessageField = ({cx, entityType, progress, errorCode, errorMessage, method, theme, t, progressMessageFieldID, migrationNeeded = null, showMigrationActionHandler = null}) => {
    return <div className={cx('progress-messages')}>
            {progress !== RequestStatus.Idle &&
                <div className={cx('actions-return-messagebox', {error: progress === RequestStatus.Error || progress === RequestStatus.Unsuccessful}, {success: progress === RequestStatus.Successful}, {warning: progress === RequestStatus.Partial_Success})}>
                    {progress === RequestStatus.Publishing || progress === RequestStatus.Finalizing ?
                            <Icon id={'info'} className={cx('icon16')}/>
                        :
                            <Icon id={(progress === RequestStatus.Error || progress === RequestStatus.Unsuccessful ? ('iconStatusError-' + theme) : (progress === RequestStatus.Partial_Success ? 'warning' : ('iconStatusOK-' + theme)))} className={cx('icon16')}/>
                    }
                    {entityType === TSMEntityKinds.Types ? 
                        <span id={progressMessageFieldID} role="alert">
                            {
                                progress === RequestStatus.Successful ? 
                                (
                                    method === RequestMethods.Put ? 
                                    t('tsm.type.messages.putSuccess') : 
                                    t('tsm.type.messages.deleteSuccess')
                                ) : 
                                (
                                    progress === RequestStatus.Partial_Success ? // only happens with put method
                                    (
                                        t('tsm.type.messages.putPartialSuccess') + ": " + errorMessage
                                    ) : 
                                    (
                                        progress === RequestStatus.Unsuccessful ? 
                                        (
                                                method === RequestMethods.Put ? 
                                                t('tsm.type.messages.putUnsuccess') + ": " + (errorCode === "InvalidInput" ? errorMessage : t('tsm.type.errors.' + errorCode) + ' ' + t('details') + ':\n' + errorMessage) : 
                                                t('tsm.type.messages.deleteUnsuccess') + ": " + (errorCode === "InvalidInput" ? errorMessage : t('tsm.type.errors.' + errorCode))
                                        ) : 
                                        (
                                            progress === RequestStatus.Error ? 
                                                (
                                                    method === RequestMethods.Put ? 
                                                    t('tsm.type.messages.putError') + ": " + ((errorCode === "ErrorTrace" || errorCode === 'InvalidInput') ? errorMessage : t('tsm.type.errors.' + errorCode)) :
                                                    t('tsm.type.messages.deleteError') + ": " + ((errorCode === "ErrorTrace" || errorCode === 'InvalidInput') ? errorMessage : t('tsm.type.errors.' + errorCode))
                                                ) : 
                                                (progress === RequestStatus.Publishing ? t('tsm.publishing') : "")
                                        )
                                    )
                                )
                            }
                        </span>
                    : entityType === TSMEntityKinds.Hierarchies ? 
                        <span id={progressMessageFieldID} role="alert">
                            {
                                progress === RequestStatus.Successful ? 
                                (
                                    method === RequestMethods.Put ? 
                                    t('tsm.hierarchy.messages.putSuccess') : 
                                    t('tsm.hierarchy.messages.deleteSuccess')
                                ) : 
                                (
                                    progress === RequestStatus.Partial_Success ? // only happens with put method
                                    (
                                        t('tsm.type.messages.putPartialSuccess') + ": " + errorMessage
                                    ) : 
                                    (
                                        progress === RequestStatus.Unsuccessful ? 
                                        (
                                            method === RequestMethods.Put ? 
                                            t('tsm.hierarchy.messages.putUnsuccess') + ": " + t('tsm.hierarchy.errors.' + errorCode) : 
                                            t('tsm.hierarchy.messages.deleteUnsuccess') + ": " + t('tsm.hierarchy.errors.' + errorCode)
                                        ) : 
                                        (
                                            progress === RequestStatus.Error ? 
                                            (
                                                method === RequestMethods.Put ? 
                                                t('tsm.hierarchy.messages.putError') + ": " + ((errorCode === "ErrorTrace" || errorCode === 'InvalidInput') ? errorMessage : t('tsm.hierarchy.errors.' + errorCode)) : 
                                                    method === RequestMethods.Update ?
                                                    t('tsm.hierarchy.messages.updateError') + ": " + ((errorCode === "ErrorTrace" || errorCode === 'InvalidInput') ? errorMessage : t('tsm.hierarchy.errors.' + errorCode)) :     
                                                    t('tsm.hierarchy.messages.deleteError') + ": " + ((errorCode === "ErrorTrace" || errorCode === 'InvalidInput') ? errorMessage : t('tsm.hierarchy.errors.' + errorCode))
                                            ) : 
                                            (progress === RequestStatus.Publishing ? t('tsm.publishing') : "")
                                        )
                                    )
                                )
                            }
                        </span>
                    :   <span id={progressMessageFieldID} role="alert">
                            {
                                progress === RequestStatus.Successful ? 
                                (
                                    method === RequestMethods.Put ? 
                                    t('tsm.instance.messages.putSuccess') : 
                                    (
                                        method === RequestMethods.Update ? 
                                        t('tsm.instance.messages.updateSuccess') : 
                                        t('tsm.instance.messages.deleteSuccess')
                                    )
                                ) : 
                                (
                                    progress === RequestStatus.Unsuccessful ? 
                                    (
                                        method === RequestMethods.Put ? 
                                        t('tsm.instance.messages.putUnsuccess') + ": " + t('tsm.instance.errors.' + errorCode) : 
                                        (
                                            method === RequestMethods.Update ? 
                                            t('tsm.instance.messages.updateUnsuccess') + ": " + t('tsm.instance.errors.' + errorCode) : 
                                            t('tsm.instance.messages.deleteUnsuccess') + ": " + t('tsm.instance.errors.' + errorCode)
                                        )
                                    ) : 
                                    (
                                        progress === RequestStatus.Partial_Success ? 
                                        (
                                            method === RequestMethods.Put ? 
                                            t('tsm.instance.messages.putPartialSuccess') + ": " + errorMessage : 
                                            t('tsm.instance.messages.updatePartialSuccess') + ": " + errorMessage
                                        ) : 
                                        (
                                            progress === RequestStatus.Error ? 
                                            (
                                                method === RequestMethods.Put ? 
                                                t('tsm.instance.messages.putError') + ": " + (errorCode === "ErrorTrace" ? errorMessage : t('tsm.instance.errors.' + errorCode)) : 
                                                (
                                                    method === RequestMethods.Search ? 
                                                    t('tsm.instance.messages.searchError') + ": " + (errorCode === "ErrorTrace" ? errorMessage : t('tsm.instance.errors.' + errorCode)) : 
                                                    (
                                                        method === RequestMethods.Update ? 
                                                        t('tsm.instance.messages.updateError') + ": " + (errorCode === "ErrorTrace" ? errorMessage : t('tsm.instance.errors.' + errorCode)) : 
                                                        t('tsm.instance.messages.deleteError') + ": " + (errorCode === "ErrorTrace" ? errorMessage : t('tsm.instance.errors.' + errorCode))
                                                    )
                                                )
                                            ) :
                                            (progress === RequestStatus.Publishing ? 
                                                t('tsm.publishing') :
                                                (
                                                    progress === RequestStatus.Finalizing ? t('tsm.finalizing') : ""
                                                )
                                            )
                                        )
                                    )
                                )
                            }
                        </span>
                    }
                </div>
            }
            {(entityType === TSMEntityKinds.Types && migrationNeeded) &&
                <div className={cx('actions-return-messagebox', 'warning')}>
                    <Icon id={'info'} className={cx('icon16')}></Icon>
                    <span className={cx('migration-info')}>{t('tsxMigration.pendingMigrations')}</span>
                    <button className={cx('_base-primary-button')} onClick={showMigrationActionHandler}>{t('tsxMigration.showMigration')}</button>
                </div>
            }
        </div>
}
import * as React from 'react';
import classNames from 'classnames/bind';
const cx = classNames.bind(require('./TextField.module.scss'));

const TextField = ({ label, value, handleOnChange, description, errorMessage }) => <div className={cx('container')}>
  <label>
    <div className={cx('name')}>
      { label }
    </div>
    <div className={cx('description')}>
      { description }
    </div>
    <input value={value} onChange={handleOnChange} />
    {
      errorMessage && <div className={cx('error')}>{ `* ${errorMessage}` }</div>
    }
    
  </label>
</div>;

export default TextField;
import React from "react";
import classNames from 'classnames/bind';
import Icon from "../../Icon/Icon";
const cx = classNames.bind(require('../TsmModal.module.scss'));

interface Props {headers: any[]; tsmModalComponent: any;}

export default class Header extends React.Component<Props> {
    render() {
        return (
            <thead>
                <tr className={cx('headers')}>
                    {this.props.headers.map((h, idx) => 
                        <th key={idx} className={cx('header', h === '' ? 'hidden' : '')}  >
                            {Array.isArray(h) ? 
                                <button className={cx('header-button')} onClick={() => this.props.tsmModalComponent.setOrToggleSorting(h[1])}
                                        aria-label={this.props.tsmModalComponent.props.t('sortBy') + " " + h[0] + " " + 
                                        (this.props.tsmModalComponent.state.sortingField === h[1] ? 
                                            this.props.tsmModalComponent.props.t('sorted') + (this.props.tsmModalComponent.state.sortDirection === 'Down' ? this.props.tsmModalComponent.props.t('ascending') : this.props.tsmModalComponent.props.t('descending')) 
                                        : '')}>
                                    <span>{h[0]}</span>
                                    <div className={cx('sort', (this.props.tsmModalComponent.state.sortingField === h[1] ? 'visible' : ''))}>
                                        <Icon id={cx('iconSort' + this.props.tsmModalComponent.state.sortDirection + '-' + this.props.tsmModalComponent.props.theme)} className={cx('icon16')}/>
                                    </div>
                                </button>
                            : (h === 'empty' ? 
                                <span className={cx('header-text')} aria-hidden={true}></span>
                            :
                                <span className={cx('header-text')}>{h === '' ? this.props.tsmModalComponent.props.t('tsm.actions') : h}</span>
                            )}
                        </th>
                    )}
                </tr>
            </thead>
        );
    }

}
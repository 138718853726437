import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { getTheme } from '../../../state/reducers/AppSettingsReducer';
import SeriesSettings from './SeriesSettings';
import { getChartSettingsState, getTimeSeriesQueries, getSearchSpan, getTimezone, getAggregatesQueries } from '../../../state/reducers/AnalyticsReducer';
import { SET_QUERY_FIELD, SET_CHART_SETTINGS_STATE, GET_QUERYRESULTS } from '../../../../constants/ActionTypes';
import { QueryTypes, ChartSettingsTypes } from '../../../../constants/Enums';
import { ChartSettingsState } from '../../../models/Interfaces';
import TimeSeriesQuery from '../../../models/TimeSeriesQuery';
import Utils from '../../../services/Utils';
import AggregatesQuery from '../../../models/AggregatesQuery';

const mapStateToProps = state => {
    const chartSettings: ChartSettingsState = getChartSettingsState(state);
    const timeSeriesQueries: Array<TimeSeriesQuery> = getTimeSeriesQueries(state); 
    const aggregateQueries: Array<AggregatesQuery> = getAggregatesQueries(state);
    const queries: any = timeSeriesQueries.length ? timeSeriesQueries : aggregateQueries;
    const tsqI: number = chartSettings.activeTSQI < queries.length ? chartSettings.activeTSQI : 0;
    const query: any = (tsqI in queries) ? queries[tsqI] : null;
    let offsetArray = query ? Utils.deconstructOffset(query.timeShift) : [null, null, null];

    return { 
        theme: getTheme(state),
        chartSettings: getChartSettingsState(state),
        query: query,
        queries: queries,
        queriesType: timeSeriesQueries?.length ? QueryTypes.TimeSeries : QueryTypes.Aggregates,
        startAtString: getStartAtString(query, getSearchSpan(state)),
        searchSpan: getSearchSpan(state),
        offsetIsPositive: offsetArray[0],
        offsetQuantity: offsetArray[1],
        offsetUnit: offsetArray[2],
        timezone: getTimezone(state)
    };
};

const mapDispatchToProps = dispatch => ({ 
    setQueryField: (queryType: QueryTypes, idx: number, field: string, value: any) => {
        dispatch({type: SET_QUERY_FIELD, payload: {type: queryType, idx: idx, field: field, value: value}});        
    },
    setTSQField: (idx: number, field: string, value: any) => {
        dispatch({type: SET_QUERY_FIELD, payload: {type: QueryTypes.TimeSeries, idx: idx, field: field, value: value}});
    },
    setAQField: (idx: number, field: string, value: any) => {
        dispatch({type: SET_QUERY_FIELD, payload: {type: QueryTypes.Aggregates, idx: idx, field: field, value: value}});
    },
    setActiveTSQI: (activeTSQI: number) => {
        dispatch({type: SET_CHART_SETTINGS_STATE, payload: {
            type: ChartSettingsTypes.Series,
            isOpen: true,
            activeTSQI: activeTSQI  
        }});
    },
    clearShift: (idx) => {
        dispatch({type: SET_QUERY_FIELD, payload: {type: QueryTypes.TimeSeries, idx: idx, field: 'startAt', value: null}});
        dispatch({type: SET_QUERY_FIELD, payload: {type: QueryTypes.TimeSeries, idx: idx, field: 'timeShift', value: ''}});
        dispatch({type: GET_QUERYRESULTS});
    },
    setTimeShift: (idx, timeShift) => {
        dispatch({type: SET_QUERY_FIELD, payload: {type: QueryTypes.TimeSeries, idx: idx, field: 'timeShift', value: timeShift}});
        dispatch({type: SET_QUERY_FIELD, payload: {type: QueryTypes.TimeSeries, idx: idx, field: 'startAt', value: null}});
        dispatch({type: GET_QUERYRESULTS});
    },
    setStartAt: (idx: number, startAt: string) => {
        dispatch({type: SET_QUERY_FIELD, payload: {type: QueryTypes.TimeSeries, idx: idx, field: 'startAt', value: startAt}});
        dispatch({type: SET_QUERY_FIELD, payload: {type: QueryTypes.TimeSeries, idx: idx, field: 'timeShift', value: '0s'}});
        dispatch({type: GET_QUERYRESULTS});
    }
});

const getStartAtString = (tsq, searchSpan) => {
    if (tsq?.startAt) {
        return tsq.startAt;
    } else {
        return new Date(searchSpan?.from).toISOString();
    }
}

const SeriesSettingsContainer = translate()(connect(mapStateToProps, mapDispatchToProps)(SeriesSettings));

export default SeriesSettingsContainer;

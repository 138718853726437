import Utils from "./Utils";
import AuthService from "./AuthService";
import { store } from '../../index';

export default class TelemetryService {

    static logUserAction = (() => {
        if (window.location.href.indexOf('insights-local.timeseries.azure.com') !== -1) {
            return () => {};
        }
        else {
            return (actionName: string, payload: any = {}) => {

                // Grab selected environment ID from store
                // *Note:  Using selector (getSelectedEnvironmentId) in this file causes module loading issues, which are solved by
                // extracting selectors into a separate file from reducers.  Selectors are avoided here for that reason.
                try{
                    let state: any = store.getState();
                    let appSettingsState = state?.appSettings;
                    let environmentId = Utils.getValueOrDefault(appSettingsState?.environments?.[appSettingsState?.selectedEnvironmentFqdn], {})?.environmentId;
                    if(environmentId){
                        payload.environmentId = environmentId; // Attach environmentId to TelemetryService
                    }
                } catch(err){
                    console.error(err);
                }
                 
                payload.eventType = actionName;
                payload.clientTimestamp = (new Date()).toISOString();
                payload.sessionId = Utils.sessionId;
                payload.source = 't7';
                payload.url = window.location.href;
                AuthService.getTsiTokenPromise().then(token => {
                    Utils.promiseHttpRequest(token, `${Utils.tsiApiEndpoint()}/telemetry?api-version=2016-12-12`, JSON.stringify(payload), "POST").finally(() => {});
                });
            };
        }
    })();

}
import TelemetryService from "../../services/TelemetryService";
import { SELECT_ENVIRONMENT, ADD_AGGREGATESQUERY, ADD_TIMESERIESQUERY, FILL_STATE_FROM_SHORTLINK, SET_ENVIRONMENT_FROM_SAVED_QUERY, GET_SHORTLINK_FULFILLED, 
         SAVE_QUERY, REMOVE_TIMESERIESQUERY, SHOW_HIDE_TIMESERIESQUERY, REMOVE_AGGREGATESQUERY, ZOOM, TOGGLE_ENVIRONMENTS, GET_EVENTS_FULFILLED, GET_EVENTS_ERROR, 
         SET_TIMEZONE, SET_THEME, PUT_TYPE_FULFILLED, PUT_HIERARCHY_FULFILLED, PUT_INSTANCES_FULFILLED, GET_QUERYRESULTS_FULFILLED, GET_TENANTS_FULFILLED, 
         SEARCH_INSTANCES_FULFILLED, SET_QUERY_FIELD, CREATE_JOB, MODIFY_JOB, SET_MIGRATION_MODAL_VISIBILITY, MIGRATE_TSM_VARIABLES, MIGRATE_TSM_VARIABLES_FULFILLED, 
         MIGRATE_SAVED_QUERIES, SUMMARIZE_SAVED_QUERY_RESULTS, SET_ELEMENTS_THAT_NEED_MIGRATION, DOWNLOAD_ENTITIES, SET_IS_GET_SERIES_ENABLED, UPDATE_JUMBOTRON_STATE, SET_SERIES_LABELS_ENABLED, SET_CHART_SETTINGS_STATE, SET_SWIMLANE_FIELD } from "../../../constants/ActionTypes";
import Utils from "../../services/Utils";
import { CollapsableComponents, TSXMigrationStatus } from '../../../constants/Enums';

// This reducer is used explicitly for 
function telemetryReducer(state, action) {
    if (typeof state === 'undefined') {
        return null;
    }
    switch (action.type) {
        case ADD_TIMESERIESQUERY:
            TelemetryService.logUserAction('addTimeseriesQuery', {fromSearch: tryGetInstanceSearchText().length > 0});
            return null;
        case REMOVE_TIMESERIESQUERY:
            TelemetryService.logUserAction('removeTimeseriesQuery');
            return null;
        case SHOW_HIDE_TIMESERIESQUERY:
            TelemetryService.logUserAction('showHideTimeseriesQuery');
            return null;
        case ADD_AGGREGATESQUERY: 
            TelemetryService.logUserAction('addAggregatesQuery');
            return null;
        case REMOVE_AGGREGATESQUERY: 
            TelemetryService.logUserAction('removeAggregatesQuery');
            return null;
        case SELECT_ENVIRONMENT:
            TelemetryService.logUserAction('selectEnvironment', {});
            return null;
        case FILL_STATE_FROM_SHORTLINK:
            let sid = Utils.getShortlinkId();
            if(sid)
                TelemetryService.logUserAction('loadFromShortlink');
            return null;
        case SET_ENVIRONMENT_FROM_SAVED_QUERY:
            TelemetryService.logUserAction('openSavedQuery');
            return null;
        case GET_SHORTLINK_FULFILLED:
            TelemetryService.logUserAction('createShortlink');
            return null;
        case SAVE_QUERY:
            TelemetryService.logUserAction('savedQuery');
            return null;
        case ZOOM:
            if (action.payload.direction) {
                TelemetryService.logUserAction('zoom', {direction: action.payload.direction});
            }
            return null;
        case TOGGLE_ENVIRONMENTS:
            TelemetryService.logUserAction('expandCollapseEnvironments');
            return null;
        case GET_EVENTS_FULFILLED:
            TelemetryService.logUserAction('getEvents', {success: true, count: action.payload.events ? action.payload.events.length : 0});
            return null;
        case GET_EVENTS_ERROR:
            TelemetryService.logUserAction('getEvents', {success: false});
            return null;
        case SET_TIMEZONE:
            TelemetryService.logUserAction('setTimezone', {timezone: action.payload.timezone});
            return null;
        case SET_THEME: 
            TelemetryService.logUserAction('setTheme');
            return null;
        case PUT_TYPE_FULFILLED:
            logTsmApiCallResult(action.payload, 'Type');
            return null;
        case PUT_HIERARCHY_FULFILLED:
            logTsmApiCallResult(action.payload, 'Hierarchy');
            return null;
        case PUT_INSTANCES_FULFILLED:
        case SEARCH_INSTANCES_FULFILLED:
            logTsmApiCallResult(action.payload, 'Instances');
            return null;
        case GET_QUERYRESULTS_FULFILLED:
            TelemetryService.logUserAction('queryFulfilled', {type: action.payload.env.isLts ? 'PAYG' : 'S'});
            return null;
        case GET_TENANTS_FULFILLED:
            setTimeout(() => {  // ensure that tenantID has been set before we log these values
                TelemetryService.logUserAction('userAgent', {userAgent: navigator.userAgent});
                TelemetryService.logUserAction('avaliableResolution', {width: window.screen.availWidth, height: window.screen.availHeight});
            });
            return null;
        case SET_QUERY_FIELD: // for set includeEnvelope, interpolationFunction, includeDots, timeShift, color
            TelemetryService.logUserAction('setQueryField', {type: action.payload.type, field: action.payload.field, value: action.payload.field});
            return null;
        case CREATE_JOB:
            let type = action.payload && action.payload.requestBody && action.payload.requestBody.type;
            TelemetryService.logUserAction('createJob', { type });
            return null;
        case MODIFY_JOB:
            let status = action.payload && action.payload.requestBody && action.payload.requestBody.status;
            TelemetryService.logUserAction('updateJob', { status });
            return null;
        case SET_MIGRATION_MODAL_VISIBILITY:
            if(action.payload.open) {
                TelemetryService.logUserAction('tsx.openedMigrationModal');
            } else {
                if(action.payload.isDone) {
                    TelemetryService.logUserAction('tsx.completedMigration');
                } else {
                    TelemetryService.logUserAction('tsx.closedMigrationModalBeforeFinishing');
                }
            }
            return null;
        case MIGRATE_TSM_VARIABLES:
            TelemetryService.logUserAction('tsx.migratingVariables');
            return null;
        case MIGRATE_TSM_VARIABLES_FULFILLED:
            if(action.payload.migrationStatus === TSXMigrationStatus.Error) {
                TelemetryService.logUserAction('tsx.migrateVariablesFailed', { error: action.payload.result });
            }
            return null;
        case MIGRATE_SAVED_QUERIES:
            TelemetryService.logUserAction('tsx.migratingSavedQueries');
            return null;
        case SUMMARIZE_SAVED_QUERY_RESULTS:
            const { migrationStatus,
                totalNumberOfQueries,
                numberOfSuccessfulMigrations,
                failedMigratedSavedQueries } = action.payload;

            TelemetryService.logUserAction('tsx.migrateSavedQueriesFailed', { 
                migrationStatus,
                totalNumberOfQueries,
                numberOfSuccessfulMigrations,
                failedMigratedSavedQueriesCount: failedMigratedSavedQueries.length,
                failedMigratedSavedQueriesIds: failedMigratedSavedQueries.map(query => query.id) });
                
            return null;
        case SET_ELEMENTS_THAT_NEED_MIGRATION:
            TelemetryService.logUserAction('tsx.migrationNotificationShown', { isUserContributor: action.payload.isUserContributor });
            return null;
        case DOWNLOAD_ENTITIES:
            if(action.payload.isForMigration) {
                TelemetryService.logUserAction('tsx.savedVariables');
            }
            return null;
        case SET_IS_GET_SERIES_ENABLED:
            let isGetSeriesEnabled = action.payload;
            TelemetryService.logUserAction('isGetSeriesEnabled', {isGetSeriesEnabled});
            if (isGetSeriesEnabled) {
                TelemetryService.logUserAction('intervalSlider', {interval: 'raw'});
            }
            return null;
        case UPDATE_JUMBOTRON_STATE:
            let target = action?.payload?.target;
            if(target === 'toggle'){
                TelemetryService.logUserAction('jumbotronToggled');
            } else if(target === CollapsableComponents.tagExp) {
                TelemetryService.logUserAction('tagExplorerCollapseStateChanged', {isCollapsed: action?.payload?.isCollapsed});
            } else if(target === CollapsableComponents.well) {
                TelemetryService.logUserAction('wellCollapseStateChanged', {isCollapsed: action?.payload?.isCollapsed});
            }
            return null;
        case SET_SERIES_LABELS_ENABLED:
            TelemetryService.logUserAction('setSeriesLabelsEnabled', {isEnabled: action.payload});
            return null;
        case SET_CHART_SETTINGS_STATE:
            TelemetryService.logUserAction('setChartSettingsState', {state: action.payload});
            return null;
        case SET_SWIMLANE_FIELD:
            TelemetryService.logUserAction('setSwimlaneField', action.payload);
            return null;
        default: 
            return null;
    }
}

function logTsmApiCallResult(payload: any, entityType: string) {
    if (payload.error) {
        TelemetryService.logUserAction(`put${entityType}Error`, {error: JSON.stringify(payload.error)});
    }
    else if (payload.put) {
        if (payload.put[0] && payload.put[0].error) {
            TelemetryService.logUserAction(`put${entityType}Error`, {error: JSON.stringify(payload.put[0].error)});
        }
    }
   else if (payload.delete) {
        if (payload.delete[0] && payload.delete[0].code) {
            TelemetryService.logUserAction(`delete${entityType}Error`, {error: JSON.stringify(payload.delete[0].error)});
        }
    }
    else if (payload.update) {
        if (payload.update[0] && payload.update[0].error) {
            TelemetryService.logUserAction(`update${entityType}Error`, {error: JSON.stringify(payload.update[0].error)});
        }
    }
}

function tryGetInstanceSearchText() {
    try {
        return (document.getElementsByClassName('tsi-modelAutocompleteInput')[0] as any).value;
    }
    catch (err) {
        return '';
    }
}

export default telemetryReducer;
import * as React from 'react';
import classNames from 'classnames/bind';
import AuthService from '../../services/AuthService';
import Icon from '../Icon/Icon';
import ModalContainer from '../Modal/Modal.container';
import Placeholder from '../Placeholder/Placeholder';
import { RequestStatus, TSMEntityKinds, RequestMethods, DownloadStatus } from '../../../constants/Enums';
import {TypesTable} from './EntityTablesImplementations/TypesTableComponents';
import {HierarchiesTable} from './EntityTablesImplementations/HierarchiesTableComponents';
import {InstancesTable} from './EntityTablesImplementations/InstancesTableComponents';
import Utils from '../../services/Utils';
import { SortOrder } from '../SortableTable/SortableTable';
import { ProgressMessageField } from '../TsmModal/Implementations/ProgressMessageField';
import InstanceEntity from '../../models/InstanceEntity';
const cx = classNames.bind(require('./TsmEntities.module.scss'));

interface Props {
    environment: any,
    downloadEntities: any
    searchInstances: any,
    selectedEntities: any,
    selectedEntityType: any,
    selectEntityType: any,
    theme: string,
    selectedEntity: any; 
    selectedEntityId: string, 
    selectedSubEntiyIdx: number; 
    selectEntity: any; 
    selectSubEntity: any; 
    closeEntityDrawer: any; 
    addEntity: any; 
    editEntity: any; 
    bulkUpload: any; 
    t: any;
    types: any; 
    hierarchies: any; 
    putType: any; 
    putHierarchy: any; 
    putInstances: any; 
    isTsmModalVisible: boolean;  
    instancesContinuationToken: any; 
    searchText: string; 
    isLoadingEntityType: boolean; 
    progress: RequestStatus; 
    errorMessage: string; 
    errorCode: string; 
    requestMethod: string; 
    clearProgress: any; 
    tsmEntitySortField: string; 
    tsmEntitySortOrder: SortOrder; 
    tsmSubEntitySortField: string; 
    tsmSubEntitySortOrder: SortOrder; 
    tsmSubEntitySortParentIdx: string; 
    setTSMEntitySort: any; 
    setTSMSubEntitySort: any; 
    editSubEntity: any; 
    addSubEntity: any;
    downloadStatus: DownloadStatus;
    isUserContributor: boolean;
}

interface State { 
    expandedEntityIndices: Array<number>,
    isConfirmDeleteModalHidden: boolean,
    searchTerm: string
}

export default class TsmEntities extends React.Component<Props, State> {
    private entitiesEnd = null;
    private instanceSearchComponent = null;
    private searchID;
    private lastElemBeforeModal;
    private continuationToken = null;
    private instancesList = null;
    private progressMessageFieldID: string;
    private tableContainerID: string;

    constructor(props: Props) {
        super(props);
        this.progressMessageFieldID = Utils.customID('progressMessageFieldID');
        this.searchID = Utils.customID('search');
        this.tableContainerID = Utils.customID('tableContainer');
        this.state = {
            isConfirmDeleteModalHidden: true, 
            expandedEntityIndices: [],
            searchTerm: ''
        };
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.environment.environmentFqdn === nextProps.environment.environmentFqdn) {
            if ((this.props.selectedEntities.length !== 0) && (this.props.selectedEntities.length < nextProps.selectedEntities.length)) { /** after adding a new item (with the exception of instances) or loading more instances, scroll to the bottom in the view */
                this.scrollToBottom();
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if ((this.props.selectedEntityType === TSMEntityKinds.Instances && 
            (prevProps.selectedEntityType !== this.props.selectedEntityType || 
                (this.props.searchText !== prevProps.searchText && this.props.searchText === ''))) ||
                prevProps.theme !== this.props.theme) {
            this.continuationToken = null;
            this.initializeSearchInstances(this.props);
            this.setState({expandedEntityIndices: []});
        }
        else if (prevProps.environment.environmentFqdn !== this.props.environment.environmentFqdn) {
            this.continuationToken = null;
            this.initializeSearchInstances(this.props);
            this.setState({expandedEntityIndices: []});
        }

        if (prevProps.isTsmModalVisible !== this.props.isTsmModalVisible && !this.props.isTsmModalVisible) {
            this.focusBackToLastElemBeforeModal();
        } else if (!prevState.isConfirmDeleteModalHidden && this.state.isConfirmDeleteModalHidden) {
            this.focusBackToLastElemBeforeModal();          
        } else if (prevProps.isLoadingEntityType !== this.props.isLoadingEntityType && !this.props.isLoadingEntityType) {
            this.setState({expandedEntityIndices: []});
            if (this.props.selectedEntityType === TSMEntityKinds.Instances) {
                Utils.tryFocusElement(cx('tabs'), 0, "button");
            } else if (this.props.selectedEntityType === TSMEntityKinds.Hierarchies) {
                Utils.tryFocusElement(cx('tabs'), 1, "button");
            } else {
                Utils.tryFocusElement(cx('tabs'), 2, "button");
            }
        }
    }

    componentDidMount() {
        this.props.selectEntityType(this.props.selectedEntityType);
        this.initializeSearchInstances(this.props);
        Utils.tryFocusElement(cx('tabs'), 0, "button");
    }

    // beginning of component functions
    initializeSearchInstances = (props) => {
        let tableId = document.getElementById(this.tableContainerID);
        let table = tableId ? tableId.getElementsByTagName('table') : [];
        this.instancesList = table.length ? table[0] : null;
        this.instanceSearchComponent = new Utils.tsiClient.ux.ModelAutocomplete(document.getElementById(this.searchID));
        this.instanceSearchComponent.render(
            props.environment.environmentFqdn, 
            AuthService.getTsiTokenPromise, 
            {
                theme: props.theme, 
                onInput: (text, event) => {
                    if (event.which === 13 || event.keyCode === 13 || text.length === 0) {
                        this.setState({expandedEntityIndices: []});
                        this.continuationToken = null;
                        props.searchInstances({searchText: text, continuationToken: null});
                    }
                },
                strings: this.props.t('sdkStrings', {returnObjects: true})
            });
    }

    setEntityType = (entityType) => (e) => {
        this.setState({expandedEntityIndices: []});
        this.props.selectEntityType(entityType);
    }

    toggleExpand = (idx) => {
        if (this.props.progress !== RequestStatus.Publishing) {
            this.props.clearProgress();
        }

        let indices = this.state.expandedEntityIndices;
        let index = indices.indexOf(idx);
        if (index === -1) {
            indices.push(idx);
        } else {
            indices.splice(index, 1)
        }
        this.setState({expandedEntityIndices: indices});
        this.props.setTSMSubEntitySort(null, null, SortOrder.Unsorted);
    }

    toggleSelect = (id) => (e) => {
        if (Utils.isKeyDownAndNotEnter(e)) {return; }
        if (this.props.selectedEntityId === id) {
            this.props.selectEntity(null);
        } else {
            this.props.selectEntity(id);
        }
    }

    copyId = (id) => (event) => {
        if (Utils.isKeyDownAndNotEnter(event)) {return; }
        event.preventDefault();
        event.stopPropagation();
        let copyIdMessage = document.getElementById("copyIdMessage-" + id) as HTMLSpanElement;
        copyIdMessage.classList.remove(cx('_base-fade-in-and-out'));
        let copyText = document.getElementById("copyId-" + id) as HTMLInputElement;
        copyText.value = id;
        copyText.focus();
        copyText.select();
        
        let result = document.execCommand("copy");
        if (result) {
            copyIdMessage.innerText = this.props.t('copied');
        } else {
            copyIdMessage.innerText = this.props.t('error');
        }
        copyIdMessage.classList.add(cx('_base-fade-in-and-out'));
    }

    addEntity = (e) => {
        this.lastElemBeforeModal = e.currentTarget;
        this.props.addEntity();
    }

    focusBackToLastElemBeforeModal = () => {
        this.lastElemBeforeModal && this.lastElemBeforeModal.focus();
    }

    bulkUpload = (e) => {
        this.lastElemBeforeModal = e.currentTarget;
        this.props.bulkUpload();
    }

    editEntity = (entityId) => (e) => {
        if (Utils.isKeyDownAndNotEnter(e)) {return; }
        this.lastElemBeforeModal = e.currentTarget;
        e.stopPropagation();
        this.props.editEntity(entityId);
    }

    showEntityJSON = (entityIdx) => (e) => {
        if (Utils.isKeyDownAndNotEnter(e)) {return; }
        e.stopPropagation();
        if (this.props.progress !== RequestStatus.Publishing) {
            this.props.clearProgress();
        }

        let selectedEntity = this.props.selectedEntities[entityIdx];
        let JSONObject;
        if (this.props.selectedEntityType === TSMEntityKinds.Instances) {
            let entity = {...selectedEntity};
            let type = this.props.types.find(t => t.name === (selectedEntity.type ? Utils.stripHits(selectedEntity.type) : Utils.stripHits(selectedEntity.typeName)));
            entity.typeId = type?.id;
            entity = InstanceEntity.fromObject(entity);
            JSONObject = entity.convertToJSON();
        } else {
            JSONObject = JSON.stringify({put: [selectedEntity]}, null, 2);
        }
        
        let w = window.open("", this.props.t('tsm.viewJson'), "width=500,height=400");
        w.document.write('<pre></pre>');
        w.document.getElementsByTagName("pre")[0].innerText = JSONObject;
    }

    editSubEntity = (entityId, subEntityIdx) => (e) => {
        this.lastElemBeforeModal = e.currentTarget;
        this.props.editSubEntity(entityId, subEntityIdx);
    }

    addSubEntity = (entityId) => (e) => {
        this.lastElemBeforeModal = e.currentTarget;
        this.props.addSubEntity(entityId);
    }

    deleteSubEntity = () => {
        if (this.props.progress !== RequestStatus.Publishing) {  // to prevent double publishing if there is already a request sent 
            let JSONObject = {};
            let selectedEntity = JSON.parse(JSON.stringify(this.props.selectedEntity));

            if (this.props.selectedEntityType === TSMEntityKinds.Types) {
                delete selectedEntity.variables[Object.keys(selectedEntity.variables)[this.props.selectedSubEntiyIdx]];
                JSONObject["put"] = [];
                JSONObject["put"].push(selectedEntity);
                this.props.putType(JSON.stringify(JSONObject), RequestMethods.Delete);
            } else if (this.props.selectedEntityType === TSMEntityKinds.Hierarchies) {
                selectedEntity.source.instanceFieldNames.splice(this.props.selectedSubEntiyIdx, 1);
                JSONObject["put"] = [];
                JSONObject["put"].push(selectedEntity);
                this.props.putHierarchy(JSON.stringify(JSONObject), RequestMethods.Delete);
            } else {
                delete selectedEntity.instanceFields[Object.keys(selectedEntity.instanceFields)[this.props.selectedSubEntiyIdx]];
                Object.keys(selectedEntity.instanceFields).forEach(k => {let newVal = Utils.stripHits(selectedEntity.instanceFields[k]); let newK = Utils.stripHits(k); delete selectedEntity.instanceFields[k]; selectedEntity.instanceFields[newK] = newVal; });
                this.props.types.forEach(t => {if (t.name === (selectedEntity.type ? Utils.stripHits(selectedEntity.type) : Utils.stripHits(selectedEntity.typeName))) {selectedEntity.typeId = t.id; }});
                delete selectedEntity.typeName;
                delete selectedEntity.type;
                delete selectedEntity.hierarchyNames;
                delete selectedEntity.timeSeriesIdKey;
                delete selectedEntity.highlightedTimeSeriesId;
                if (selectedEntity.hasOwnProperty('name')) {
                    if (selectedEntity.name === "") {
                        delete selectedEntity.name;
                    } else {
                        selectedEntity.name = Utils.stripHits(selectedEntity.name);
                    }
                }
                if (selectedEntity.hasOwnProperty('description')) {
                    selectedEntity.description = Utils.stripHits(selectedEntity.description);
                }
                JSONObject["update"] = [];
                JSONObject["update"].push(selectedEntity);
                this.props.putInstances(JSON.stringify(JSONObject), RequestMethods.Delete, this.props.searchText);
            }

            this.setState({expandedEntityIndices: []});
        }
    }

    setConfirmDeleteModalHidden = (isHidden, e, entityId = null, subEntityIdx = null) => {
        if (e){
            if (Utils.isKeyDownAndNotEnter(e)) {return; }
            if (!isHidden) {
                this.lastElemBeforeModal = e.currentTarget;
            }
            e.stopPropagation();    
        }
        if (this.props.progress !== RequestStatus.Publishing) {
            this.props.clearProgress();
        }
        this.props.selectEntity(entityId);
        this.props.selectSubEntity(subEntityIdx);
        this.setState({isConfirmDeleteModalHidden: isHidden});
    }

    deleteEntity = () => {
        if (this.props.progress !== RequestStatus.Publishing) {  // to prevent double publishing if there is already a request sent 
            let JSONObject;

            if (this.props.selectedEntityType === TSMEntityKinds.Types) {
                JSONObject = {delete: {typeIds: [this.props.selectedEntityId]}};
                this.props.putType(JSON.stringify(JSONObject), RequestMethods.Delete);
            } else if (this.props.selectedEntityType === TSMEntityKinds.Hierarchies) {
                JSONObject = {delete: {hierarchyIds: [this.props.selectedEntityId]}};
                this.props.putHierarchy(JSON.stringify(JSONObject), RequestMethods.Delete);
            } else {
                JSONObject = {delete: {timeSeriesIds: [this.props.selectedEntity.timeSeriesId]}};
                this.props.putInstances(JSON.stringify(JSONObject), RequestMethods.Delete, this.props.searchText);
            }

            this.setState({expandedEntityIndices: []});
        }
    }

    sortByField = (fieldName) => {
        let newOrder = fieldName === this.props.tsmEntitySortField && this.props.tsmEntitySortOrder === SortOrder.Descending
            ? SortOrder.Ascending
            : SortOrder.Descending;
        this.props.setTSMEntitySort(fieldName, newOrder);
        this.setState({expandedEntityIndices: []});
    }

    sortSubByField = (fieldName, idx) => {
        let newOrder = fieldName === this.props.tsmSubEntitySortField && this.props.tsmSubEntitySortOrder === SortOrder.Descending
            ? SortOrder.Ascending
            : SortOrder.Descending;
        this.props.setTSMSubEntitySort(idx, fieldName, newOrder);
    }

    scrollToBottom = () => {
        if (this.entitiesEnd) {
            this.entitiesEnd.scrollIntoView({ behavior: "smooth", block: "end", inline: "end" });
        }
    }

    showJSON = () => {
        if (this.props.progress !== RequestStatus.Publishing) {
            this.props.clearProgress();
        }
        let JSONObject = this.props.selectedEntity.convertToJSON();
        let w = window.open("", this.props.t('tsm.viewJson'), "width=400,height=300");
        w.document.write('<pre></pre>');
        w.document.getElementsByTagName("pre")[0].innerText = JSONObject;
    }  
    
    download = () => {
        this.props.downloadEntities(this.props.selectedEntityType);
    }

    loadMoreInstances = (e) => {
        if (Utils.isKeyDownAndNotEnter(e)) {return; }
        let lastCT = this.props.instancesContinuationToken;
        if (lastCT !== 'END' && this.continuationToken !== lastCT) {
            this.continuationToken = lastCT;
            this.props.searchInstances({searchText: this.props.searchText, continuationToken: this.continuationToken});
        }
    };

    handleScroll = () => {
        let instancesListWrapper = document.getElementById(this.tableContainerID);
        this.instancesList = instancesListWrapper.getElementsByTagName('table')[0];

        if ((instancesListWrapper.scrollTop + instancesListWrapper.clientHeight) > this.instancesList.clientHeight) {
            this.loadMoreInstances(null);
        }
    };

    render() {
        const tableProps = {
            entities: this.props.selectedEntities,
            types: this.props.types,
            hierarchies: this.props.hierarchies,
            selectedEntityIndex: this.props.selectedEntities.findIndex(entity => this.props.selectedEntityType === TSMEntityKinds.Instances ? this.props.selectedEntityId === entity.timeSeriesIdKey : this.props.selectedEntityId === entity.id),
            selectedSubEntityIndex: this.props.selectedSubEntiyIdx,
            expandedEntityIndices: this.state.expandedEntityIndices,
            handlers: {
                onClickRow: this.toggleExpand,
                onClickSort: this.sortByField, 
                onCopyId: this.copyId, 
                onEditEntity: this.editEntity, 
                onShowEntityJSON: this.showEntityJSON,
                onConfirmDeletion: this.setConfirmDeleteModalHidden,
                onClickSubEntitySort: this.sortSubByField,
                onAddSubEntity: this.addSubEntity,
                onEditSubEntity: this.editSubEntity,
                onConfirmDeletionSubEntity: this.setConfirmDeleteModalHidden,
                loadMoreInstances: this.loadMoreInstances
            },
            sort: {
                sOrder: this.props.tsmEntitySortOrder,
                sColumnKey: this.props.tsmEntitySortField,
                sOrderSubEntity: this.props.tsmSubEntitySortOrder,
                sColumnKeySubEntity: this.props.tsmSubEntitySortField,
                sParentIndex: this.props.tsmSubEntitySortParentIdx
            },
            t: this.props.t,
            theme: this.props.theme,
            isUserContributor: this.props.isUserContributor,
            progressMessageFieldID: this.progressMessageFieldID
        };
        
        return (
            <div className={cx('theme-' + this.props.theme, 'wrapper')}>
                <Placeholder className={cx('entityTypeStatus')} visible={this.props.isLoadingEntityType}>
                    <div>{this.props.t('loadingModelEntities')}</div>
                </Placeholder>
                {this.props.requestMethod === RequestMethods.Delete &&
                    <ProgressMessageField 
                        cx={cx}
                        entityType={this.props.selectedEntityType} 
                        progress={this.props.progress} 
                        errorCode={this.props.errorCode} 
                        errorMessage={this.props.errorMessage} 
                        method={this.props.requestMethod} 
                        theme={this.props.theme}
                        t={this.props.t}
                        progressMessageFieldID={this.progressMessageFieldID}/>
                }
                <EntityTabs component={this}/>
                <CommandingField props={{entityType: this.props.selectedEntityType, component: this}}/>
                
                <div className={cx('list')}>
                    {!this.props.isLoadingEntityType && this.props.selectedEntities.length === 0 ? 
                        <Placeholder className={cx('noDataPlaceholder')} visible={true}><span aria-label={this.props.t('tsm.noData')}>{this.props.t('tsm.noData')}</span></Placeholder>
                    : 
                        <>
                            <div className={cx('table-container')} id={this.tableContainerID} onScroll={this.props.selectedEntityType === TSMEntityKinds.Instances ? this.handleScroll : null}>
                                {this.props.selectedEntityType === TSMEntityKinds.Types ? <TypesTable {...tableProps}/> :
                                    this.props.selectedEntityType === TSMEntityKinds.Hierarchies ? <HierarchiesTable {...tableProps}/> :
                                        <InstancesTable {...tableProps}/>}
                                <div className={cx('list-end')} ref={el => { this.entitiesEnd = el; }}></div>
                            </div>
                            {this.props.selectedEntityType === TSMEntityKinds.Instances && !this.props.isLoadingEntityType && this.props.instancesContinuationToken !== 'END' && this.props.selectedEntities.length !== 0 &&
                                <div className={cx('show-more')}>
                                    <button tabIndex={0} onClick={this.loadMoreInstances} aria-label={this.props.t('showMore') + this.props.t('tsm.instances')}>{this.props.t('showMore') + "..."}</button>
                                </div>
                            }
                        </>
                    }
                </div>

                {!this.state.isConfirmDeleteModalHidden ? 
                    <ConfirmationBox props={{theme: this.props.theme, t: this.props.t, setConfirmDeleteModalHidden: this.setConfirmDeleteModalHidden, deleteFunc: this.props.selectedSubEntiyIdx != null ? this.deleteSubEntity : this.deleteEntity}}/>
                : ""}
            </div>
        );
    }
}

const ConfirmationBox = ({props}) => {
    return (
        <ModalContainer hasCloseButton={false} title={props.t('tsm.confirmDelete')} onClose={() => props.setConfirmDeleteModalHidden(true, null)}>
            <div className={cx('confirmationModalContent')}>
                <div className={cx('message')}>{props.t('tsm.confirmDeleteQuestion')}</div>
                <div className={cx('buttonWrapper')}>
                    <button className={cx('modal-button-secondary')} onClick={e => props.setConfirmDeleteModalHidden(true, e)} aria-label={props.t('cancel')}>{props.t('cancel')}</button>
                    <button className={cx('modal-button-primary')} onClick={e => {props.setConfirmDeleteModalHidden(true, e); props.deleteFunc(); }} aria-label={props.t('tsm.delete')}>{props.t('tsm.delete')}</button>
                </div>
            </div>
        </ModalContainer>
    );
};

const EntityTabs = (props) => {
    return (
    <div className={cx('tabs')} role="tablist" aria-label={props.component.props.t('tsm.title')}>
            {Object.keys(TSMEntityKinds).map((e, idx) => 
                <button key={idx} className={cx('tab', {selected: TSMEntityKinds[e] === props.component.props.selectedEntityType})} role="tab"
                    aria-selected={TSMEntityKinds[e] === props.component.props.selectedEntityType}
                    onClick={props.component.setEntityType(TSMEntityKinds[e])}
                    aria-label={TSMEntityKinds[e]}>
                    {props.component.props.t('tsm.' + TSMEntityKinds[e])}
                </button>
            )}
        </div>
    );
}


const CommandingField = ({props}) => {
    const {t, theme, selectedEntityType, downloadStatus, isUserContributor} = props.component.props;
    let translateEntityType = selectedEntityType === TSMEntityKinds.Types ? "type" : selectedEntityType === TSMEntityKinds.Hierarchies ? "hierarchy" : "instance";
    return (
        <div className={cx('commands', (!isUserContributor && (selectedEntityType !== TSMEntityKinds.Instances) ? 'lessSpace' : ''))}>
            {isUserContributor &&
                <div className={cx('main-actions')}>
                    <button className={cx('action-button')} onClick={props.component.addEntity} aria-label={t(`tsm.${translateEntityType}.addShort`)}>
                        <Icon id={'iconAdd-' + theme} className={cx('icon16')}/>
                        <span>{t(`tsm.${translateEntityType}.addShort`)}</span>
                    </button>
                    <button className={cx('action-button')} onClick={props.component.bulkUpload} aria-label={t('tsm.bulkUpload')}>
                        <Icon id={'iconUpload-' + theme} className={cx('icon16')}/>
                        <span>{t('tsm.bulkUpload')}</span>
                    </button>
                    {selectedEntityType === TSMEntityKinds.Instances && downloadStatus === DownloadStatus.Downloading ?
                        <div className={cx('downloading')}>
                            <Icon id={'spinner'} className={cx('icon14')}/> 
                            <span>{t('downloading')}</span>
                        </div>
                    :
                    <button className={cx('action-button')} onClick={props.component.download} aria-label={t(`tsm.${translateEntityType}.download`)}>
                        <Icon id={'download'} className={cx('downloadIcon')}/>
                        <span>{t(`tsm.${translateEntityType}.download`)}</span>
                    </button>
                    }
                </div>
            }
            {props.entityType === TSMEntityKinds.Instances ? <div className={cx('search')} id={props.component.searchID}></div> : ""}
        </div>
    );
};

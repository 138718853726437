import * as React from 'react';
import classNames from 'classnames/bind';
import ModalContainer from '../../components/Modal/Modal.container';
import { TSXMigrationStatus, DownloadStatus } from '../../../constants/Enums';
import Icon from '../Icon/Icon';
import TooltipableContainer from '../Tooltipable/Tooltipable.container';
import { TSXMigrationResponseError } from '../../models/Interfaces';
const cx = classNames.bind(require('./TsxMigration.module.scss'));

interface Props { 
    t: any; 
    theme: string; 
    closeModal: (isDone: boolean) => any;
    variableTSXMigrationStatus: TSXMigrationStatus;
    savedQueryTSXMigrationStatus: TSXMigrationStatus;
    variableTSXMigrationResultErrors: Array<TSXMigrationResponseError>;
    savedQueryTSXMigrationResultErrors: Array<TSXMigrationResponseError>;
    selectedEnvironment: any;
    migrateTSMVariables: any;
    migrateSavedQueries: any;
    downloadTypes: any;
    savedQueriesThatNeedMigration: Array<any>;
    completedSavedQueryMigrationsCount: number;
    failedMigratedSavedQueries: Array<any>;
    openFeedbackForm: (issue: string, requestId: string, responseText: string) => void;
    downloadStatus: DownloadStatus;
} 
interface State {}

export default class TsxMigration extends React.Component<Props, State> {

    isUserContributor = () => {
        return this.props.selectedEnvironment.roles && this.props.selectedEnvironment.roles.indexOf('Contributor') !== -1
    }

    isMigrationCompleted = () => {
        return this.isUserContributor() ? 
            (this.props.variableTSXMigrationStatus === TSXMigrationStatus.NotNeeded || this.props.variableTSXMigrationStatus === TSXMigrationStatus.Successful) 
                && (this.props.savedQueryTSXMigrationStatus === TSXMigrationStatus.NotNeeded || this.props.savedQueryTSXMigrationStatus === TSXMigrationStatus.Successful)
            : (this.props.savedQueryTSXMigrationStatus === TSXMigrationStatus.NotNeeded || this.props.savedQueryTSXMigrationStatus === TSXMigrationStatus.Successful);
    }

    getQueriesErrorHtml = () => {
        const { t, failedMigratedSavedQueries } = this.props;

        return <div>
            { t('tsxMigration.errorMessageSavedQueries') }
            <ul>
                { failedMigratedSavedQueries.map((failed, index) => <li key={`migrationError-${index}`}>
                    <div>Name: {failed.name}</div>
                    <div>Type: {failed.sharingScope === 'Personal' ? t('tsxMigration.personal'): t('tsxMigration.shared')}</div>
                    <div>Error: {failed.error}</div>
                </li>) }
            </ul>
        </div>;
    }

    getErrorMessage = () => {
        const { 
            t, 
            variableTSXMigrationStatus, 
            variableTSXMigrationResultErrors, 
            savedQueryTSXMigrationResultErrors,
            failedMigratedSavedQueries } = this.props;
        
        if(failedMigratedSavedQueries.length > 0) {
            let message = failedMigratedSavedQueries
                .map(query => `Name: '${query.name}', Id:${query.id}, Type: ${query.sharingScope === 'Personal' ? t('tsxMigration.personal'): t('tsxMigration.shared')}, Error: ${query.error}; `)
                .join('\n');
            return t('tsxMigration.errorMessageSavedQueries') + message;
        }

        const mapErrorToMessage = (err: any) => ['InvalidInput', 'ErrorTrace', 'TsxParseError'].includes(err.code) 
            ? err.message 
            : this.props.t(`tsxMigration.errors.${err.code}`);

        if(variableTSXMigrationStatus === TSXMigrationStatus.Error) {
            return t('tsxMigration.errorMessageVariables') + variableTSXMigrationResultErrors.map(mapErrorToMessage).join(" --- ")
        } else if(variableTSXMigrationStatus === TSXMigrationStatus.PartialSuccess) {
            return t('tsxMigration.partialSuccessMessageVariables') + variableTSXMigrationResultErrors.map(mapErrorToMessage).join(" --- ")
        } else {
            return t('tsxMigration.errorMessageSavedQueries') + savedQueryTSXMigrationResultErrors.map(mapErrorToMessage).join(" --- ")
        }
    }
      
    render () {
        const { variableTSXMigrationStatus, savedQueryTSXMigrationStatus, failedMigratedSavedQueries } = this.props;
        const savedQueryProgressPercent = this.props.savedQueriesThatNeedMigration.length > 0 
            ? (this.props.completedSavedQueryMigrationsCount / this.props.savedQueriesThatNeedMigration.length) * 100
            : 0;

        const variableMigrationStatus = () => {
            switch(variableTSXMigrationStatus) {
                case TSXMigrationStatus.Successful:
                    return <div className={cx('staticText')}>
                        <Icon id={'iconStatusOK-' + this.props.theme} className={cx('icon14')}/> 
                        <span>{this.props.t('tsxMigration.migrationCompleted')}</span>
                    </div>
                case TSXMigrationStatus.Migrating:
                    return <div className={cx('staticText')}>
                        <Icon id={'spinner'} className={cx('icon14', 'migrating')}/> 
                        <span>{this.props.t('tsxMigration.migratingVariables')}</span>
                    </div>
                default: 
                    if (this.isUserContributor() && variableTSXMigrationStatus === TSXMigrationStatus.NotNeeded) {
                        return <div className={cx('staticText')}>
                            <Icon id={'iconStatusOK-' + this.props.theme} className={cx('icon14')}/> 
                            <span>{this.props.t('tsxMigration.variableMigrationNotNeeded')}</span>
                        </div>
                    } else {
                        return <button className={cx('action')} disabled={!this.isUserContributor() || this.props.downloadStatus !== DownloadStatus.Finished} onClick={this.props.migrateTSMVariables}>
                            <Icon id={'migration'} className={cx('icon16')}/> 
                            <span>{this.props.t('tsxMigration.migrateVariables')}</span>
                        </button>
                    }
            }
        }
        return <ModalContainer hasCloseButton={true} 
                    className={cx('tsxMigrationWrapper')} 
                    contentClassName={cx('migrationModalContent')} 
                    title={this.props.t('tsxMigration.migrationTitle') + " " + this.props.selectedEnvironment.displayName} 
                    onClose={() => this.props.closeModal(false)}
                    isOffModalAutoClosingEnabled={false}>
            <div className={cx('content')}>
                <div className={cx('instructions')}>
                    <span>{this.props.t('tsxMigration.migrationModalContent')}</span>
                    <a className={cx('learnMore')} target="_blank" rel="noopener noreferrer" href="http://aka.ms/updatetsx">{this.props.t('learnMore')}</a>
                </div>
                <div className={cx('steps')}>
                    {this.isUserContributor() && 
                        <div className={cx('step')}>
                            <h4>{this.props.t('tsxMigration.step') + " 1: " + this.props.t('tsxMigration.backupYourTypes')}</h4>
                            <span className={cx('stepInfo')}>
                                {this.props.t('tsxMigration.backupTypesFirst')}
                            </span>
                            <div>
                                {(this.props.downloadStatus === DownloadStatus.Finished || variableTSXMigrationStatus === TSXMigrationStatus.NotNeeded || variableTSXMigrationStatus === TSXMigrationStatus.Successful) ?
                                    <div className={cx('staticText')}>
                                        <Icon id={'iconStatusOK-' + this.props.theme} className={cx('icon14')}/> 
                                        <span>{this.props.t('tsxMigration.downloadTypesFinished')}</span>
                                    </div>
                                    :
                                    <button className={cx('action')} onClick={this.props.downloadTypes}>
                                        <Icon id={'download'} className={cx('icon16')}/> 
                                        <span>{this.props.t('tsm.type.download')}</span>
                                    </button>
                                }
                            </div>
                        </div>
                    }
                    <div className={cx('step')}>
                        <h4>{this.props.t('tsxMigration.step') + (this.isUserContributor() ? " 2: " : " 1: ") + this.props.t('tsxMigration.migrateVariables')}</h4>
                        {!this.isUserContributor() && 
                            <span className={cx('stepInfo')}>
                                {this.props.t('tsxMigration.readerRoleSkipVariableMigrationInfo')}
                            </span>
                        }
                        {this.isUserContributor() && variableTSXMigrationStatus !== TSXMigrationStatus.NotNeeded &&
                            <div className={cx('warningTextWrapper')}>
                                {this.props.t('tsxMigration.migrateVariablesInfo')}
                            </div>
                        }
                        <div>
                            {variableMigrationStatus()}
                        </div>
                    </div>
                    <div className={cx('step')}>
                        <h4>{this.props.t('tsxMigration.step') + (this.isUserContributor() ? " 3: " : " 2: ") + this.props.t('tsxMigration.migrateSavedQueries')}</h4>
                        <div>
                            {savedQueryTSXMigrationStatus === TSXMigrationStatus.NotStarted &&
                                <button className={cx('action')} disabled={!(variableTSXMigrationStatus === TSXMigrationStatus.NotNeeded || variableTSXMigrationStatus === TSXMigrationStatus.Successful)} onClick={this.props.migrateSavedQueries}>
                                    <Icon id={'migration'} className={cx('icon16')}/> 
                                    <span>{this.props.t('tsxMigration.migrateSavedQueries')}</span>
                                </button>
                            }
                            {savedQueryTSXMigrationStatus === TSXMigrationStatus.Migrating &&
                                <>
                                    <div className={cx('progress')}>
                                        <div className={cx('progress-bar')}>
                                            <div className={cx('progress-bar-total')}></div>
                                            <div className={cx('progress-bar-completed', { failed: failedMigratedSavedQueries.length > 0 })} style={ { width: `${savedQueryProgressPercent}%` } }></div>
                                        </div>
                                        <div>{`${Math.round(savedQueryProgressPercent)}%`}</div>
                                    </div>

                                    <div className={cx('staticText')}>
                                        <Icon id={'spinner'} className={cx('icon14', 'migrating')}/> 
                                        <span>{this.props.t('tsxMigration.migratingSavedQueries')}</span>
                                    </div>
                                </>
                            }
                            {(savedQueryTSXMigrationStatus === TSXMigrationStatus.Successful || savedQueryTSXMigrationStatus === TSXMigrationStatus.Error) &&
                                <>
                                    <div className={cx('progress')}>
                                        <div className={cx('progress-bar')}>
                                            <div className={cx('progress-bar-total')}></div>
                                            <div className={cx('progress-bar-completed', { failed: failedMigratedSavedQueries.length > 0 })} style={ { width: '100%' } }></div>
                                        </div>
                                        <div>100%</div>
                                    </div>
                                    <div className={cx('staticText')}>
                                        {savedQueryTSXMigrationStatus === TSXMigrationStatus.Successful && <Icon id={'iconStatusOK-' + this.props.theme} className={cx('icon14')}/> }
                                        {savedQueryTSXMigrationStatus === TSXMigrationStatus.Error && <Icon id={'iconStatusWarning-' + this.props.theme} className={cx('icon14')}/> }
                                        <span>{this.props.t('tsxMigration.migrationCompleted')}</span>
                                    </div>
                                </>
                            }
                            {savedQueryTSXMigrationStatus === TSXMigrationStatus.NotNeeded &&
                                <div className={cx('staticText')}>
                                    <Icon id={'iconStatusOK-' + this.props.theme} className={cx('icon14')}/> 
                                    <span>{this.props.t('tsxMigration.savedQueryMigrationNotNeeded')}</span>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className={cx('bottom')}>
                    <div className={cx('buttonWrapper')}>
                        {!this.isMigrationCompleted() 
                            ? <TooltipableContainer tooltip={this.props.t('tsxMigration.completeMigrationsToProceed')} position={'top'}>
                                <button className={cx('doneButton')} disabled>{this.props.t('tsxMigration.done')}</button>
                            </TooltipableContainer>
                            : <button className={cx('doneButton')} onClick={() => this.props.closeModal(true)}>
                                {this.props.t('tsxMigration.done')}
                            </button>
                        }
                    </div>
                </div>
                {(this.props.variableTSXMigrationStatus === TSXMigrationStatus.Error 
                    || this.props.variableTSXMigrationStatus === TSXMigrationStatus.PartialSuccess) && 
                    <div className={cx('error-field')}>
                        <div className={cx('error-message-box')}>
                            <Icon id={('iconStatusError-' + this.props.theme)} className={cx('icon16')}/>
                            <span className={cx('error-message')}>
                                {this.getErrorMessage()}
                            </span>
                        </div>
                        <div className={cx('let-us-know')}>
                            <button  
                                aria-label={this.props.t('tsxMigration.letUsKnow')} 
                                onClick={() => this.props.openFeedbackForm(
                                    this.props.t('tsxMigration.feedbackFormIssue'), 
                                    null, 
                                    this.getErrorMessage())}>
                                {this.props.t('tsxMigration.letUsKnow')}
                            </button>
                        </div>
                    </div>}
                {(this.props.savedQueryTSXMigrationStatus === TSXMigrationStatus.Error) && 
                    <div className={cx('error-field')}>
                        <div className={cx('error-message-box')}>
                            <Icon id={('iconStatusError-' + this.props.theme)} className={cx('icon16')}/>
                            <span className={cx('error-message')}>
                                {this.getQueriesErrorHtml()}
                            </span>
                        </div>
                        <div className={cx('let-us-know')}>
                            <button
                                aria-label={this.props.t('tsxMigration.letUsKnow')} 
                                onClick={() => this.props.openFeedbackForm(
                                    this.props.t('tsxMigration.feedbackFormIssue'), 
                                    null, 
                                    this.getErrorMessage())}>
                                {this.props.t('tsxMigration.letUsKnow')}
                            </button>
                        </div>
                    </div>}
            </div>
        </ModalContainer>;
    }
}

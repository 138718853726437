import React, { useEffect, useState, useCallback } from 'react';
import classNames from 'classnames/bind';
import DropdownMenuContainer from '../../DropdownMenu/DropdownMenu.container';
import HorizontalMarkerEditor from '../../HorizontalMarkerEditor/HorizontalMarkerEditor';
import { ChartSettingsState } from '../../../models/Interfaces';
import { YAxisState, ChartDataTypes, QueryTypes } from '../../../../constants/Enums';
import InfoBanner from '../../InfoBanner/InfoBanner';
import Utils from '../../../services/Utils';
import Icon from '../../Icon/Icon';
import {lanes} from '../../../../constants/Constants';
const cx = classNames.bind(require('./SwimlaneSettings.module.scss'));

interface Props { 
    t: any; 
    theme: string; 
    queries: any[];
    queriesType: QueryTypes;
    chartSettings: ChartSettingsState;
    activeVisualizationRef: any;
    setSwimlaneField: (swimlane: string, field: string, value: any) => null;
    setYAxisState: (swimlane: string, yAS: YAxisState) => any;
    setYAxisCustomExtents: (swimlane: string, min: Number, max: Number) => any;
    addHorizontalMarker: (swimlane: string) => void;
    updateHorizontalMarker: (swimlane: string, markerIndex: number, value: number | string, color: string) => void;
    removeHorizontalMarker: (swimlane: string, markerIndex: number) => void;
    laneOptions: any; 
    selectedLane: string; 
    linechartStackState: any;
}

export enum YAxisWarning {
    categorical = 'categorical',
    notLinechart = 'notlinechart',
    HiddenOrnoData="HiddenOrnoData",
    none = 'none'
}

const defaultYAxisError = {min: false, max: false, gt: false};

const yAxisIds = {
    yAxisLabelId: Utils.customID('yAxisLabel'),
    yAxisAutoLabelId: Utils.customID('yAxisAuto'),
    yAxisManualLabelId: Utils.customID('yAxisManual'),
    yAxisAutoRadioId: Utils.customID('yAxisAutoRadio'),
    yAxisManualRadioId: Utils.customID('yAxisManualRadio'),
    yAxisMinInputId: Utils.customID('yAxisMinInput'),
    yAxisMaxInputId: Utils.customID('yAxisMaxInput'),
    yAxisMinLabelId: Utils.customID('yAxisMinLabel'),
    yAxisMaxLabelId: Utils.customID('yAxisMaxLabel')
};

// eslint-disable-next-line eqeqeq
const getSwimlaneQueries = (queries, selectedSwimLane) => queries.filter(q => q.swimLane == selectedSwimLane);

export default function SwimlaneSettings (props:Props) {
    const [selectedLane, setSelectedLane] = useState(props.selectedLane ? props.selectedLane : '1');
    const [labelText, setLabelText] = useState('');
    const [yAxisWarning, setYAxisWarning] = useState(YAxisWarning.none);
    const [yExtentsValid, setYExtentsValid] = useState(false); 
    const [yAxisError, setYAxisError] = useState(defaultYAxisError);
    const [autoYActive, setAutoYActive] = useState(
        selectedLane && props.laneOptions[selectedLane]?.yAxisExtentState 
        ? props.laneOptions[selectedLane]?.yAxisExtentState !== YAxisState.manual 
        : true
    );
    const [yAxisMin, setYAxisMin] = useState('');
    const [yAxisMax, setYAxisMax] = useState('');
    const [timedRefresh, setTimedRefresh] = useState(null);
    const [swimlaneQueries, setSwimlaneQueries] = useState(getSwimlaneQueries(props.queries, selectedLane));

    const elementIds = {
        axisTypeLabelId: Utils.customID('axisTypeLabelId'),
        axisSharedLabelId: Utils.customID('axisSharedLabelId'),
        axisSharedRadioId: Utils.customID('axisSharedRadioId'),
        axisOverlapLabelId: Utils.customID('axisOverlapLabelId'),
        axisOverlapRadioId: Utils.customID('axisOverlapRadioId'),
        axisTextLabelId: Utils.customID('axisTextLabelId'),
        axisTextInputId: Utils.customID('axisTextInputId'),
    }

    const getYAxisState = () => {
        return props.laneOptions[selectedLane]?.yAxisType;
    }

    const setLaneLabel = (text: string) => {
        props.setSwimlaneField(selectedLane, 'label', text);
    }

    const getLaneLabelText = (lane: string) => {
        return props.laneOptions[lane]?.label ? props.laneOptions[lane].label : '';
    }

    const handleLabelKeydown = (e) => {
        if (e?.key === 'Enter') {
            setLaneLabel(labelText);
        }
    }

    const isLabelOutOfSync = () => {
        return props.laneOptions[selectedLane]?.label !== labelText;
    }

    const getYAxisWarningText = (warning: YAxisWarning) => {
        if(warning === YAxisWarning.categorical){
            return props.t('settingsModal.categoricalWarning');
        }
        if(warning === YAxisWarning.notLinechart){
            return props.t('settingsModal.linechartOnlyWarning');
        }
        else{
            return props.t('settingsModal.hiddenNoDataWarning');
        }
    }

    const handleYAxisMinMaxInputChange = (e: any, input: string) => {
        let localYAxisError: any = {...defaultYAxisError};

        // Grab latest min & max vals locally
        let latestMin, latestMax;
        if(input === YAxisState.min){
            latestMin = e.target.value; 
            latestMax = yAxisMax;
            setYAxisMin(e.target.value);
        } 
        if(input === YAxisState.max){
            latestMin = yAxisMin;
            latestMax = e.target.value; 
            setYAxisMax(e.target.value);
        }

        // Check & update error conditions
        if(latestMin === '' || latestMax === ''){
            localYAxisError.min = latestMin === '';
            localYAxisError.max = latestMax === '';
        } 
        else if(Number(latestMin) >= Number(latestMax)){
            localYAxisError = {min: true, max: true, gt: true};
        }
        setYAxisError(localYAxisError);

        // If valid, update global y extent state 
        if(latestMin !== '' && latestMax !== '' && Number(latestMin) < Number(latestMax)){
            props.setYAxisCustomExtents(selectedLane, Number(latestMin), Number(latestMax));
        }
    }

    const validateAndGetYExtents = useCallback(() =>{
        const aVR = props.activeVisualizationRef;

        if(swimlaneQueries.length > 0 && swimlaneQueries.every(q => q.dataType === ChartDataTypes.Categorical)) {
            setYAxisWarning(YAxisWarning.categorical);
            return null;
        }

        if(swimlaneQueries.length === 0 && !swimlaneQueries.some(q => q.visibilityState?.[0])){
            setYAxisWarning(YAxisWarning.HiddenOrnoData);
            return null;
        }

        if(aVR && 'getYExtents' in aVR && aVR['getYExtents'] instanceof Function){
            let yExtents = aVR.getYExtents();

            // map yExtends, which are ordered by TSQ, to lanes number (1-indexed)
            let laneNumberToIndexMap = Object.fromEntries(
                props.queries.map((q, index) => [q.swimLane, index])
            );

            if(selectedLane in laneNumberToIndexMap){
                setYAxisWarning(YAxisWarning.none);
                return yExtents[laneNumberToIndexMap[selectedLane]];
            }
        }

        setYAxisWarning(YAxisWarning.notLinechart);
        return null;
    }, [selectedLane, props.activeVisualizationRef, swimlaneQueries, props.queries]);

    const resetAutoMinMax = useCallback(() => {
        let min = '', max = '';
        setTimedRefresh(setTimeout(() => {
            let yExtent = validateAndGetYExtents();
            if(yExtent !== null){
                let precision = 3;
                if(yExtent[0] !== yExtent[1]){
                    while(min === max){ // Dynamically find appropriate fixed decimal precision
                        min = yExtent[0].toFixed(precision);
                        max = yExtent[1].toFixed(precision);
                        if(precision >= 8){
                            min = max = '';
                            break;
                        }
                        precision++;
                    }
                }
            } 
            setYAxisMin(min);
            setYAxisMax(max);
            setYAxisError({...defaultYAxisError});
        }, 500));
    }, [validateAndGetYExtents]);

    useEffect(() => {
        setLabelText(props.laneOptions[selectedLane]?.label ? props.laneOptions[selectedLane].label : '');
    }, [props.laneOptions, selectedLane]);

    useEffect(() => {
        if (props.queriesType === QueryTypes.TimeSeries) {
            setYExtentsValid(validateAndGetYExtents() !== null);
            setAutoYActive(!yExtentsValid || (props.laneOptions[selectedLane]?.yAxisExtentState !== YAxisState.manual));    
        }
    }, [props.queriesType, props.laneOptions, selectedLane, validateAndGetYExtents, yExtentsValid]);

    useEffect(() => {
        resetAutoMinMax();
    }, [resetAutoMinMax]);

    useEffect(() => {
        return () => {
            clearTimeout(timedRefresh);
        }
    }, [timedRefresh]);

    const updateSelectedSwimlane = (lane) => {
        setSelectedLane(lane);
        setSwimlaneQueries(getSwimlaneQueries(props.queries, lane));
    }

    return (<div className={cx('settingsTab', 'swimlanesTab')}>
        <DropdownMenuContainer 
            dropdownMenuOptions={lanes.map((lane: string, i) => 
                [<Icon id={`lane${lane}`} className={cx('laneIcon')}></Icon>, getLaneLabelText(lane), () => updateSelectedSwimlane(lane)]
            )}
            dropdownMenuSide='right'
            containerClassName={cx('laneSelectionMenuWrapper')}
            menuClassName={cx("laneSelectionMenu")}
            buttonClassName={cx("laneSelectionButton")}
            menuItemClassName={cx("laneSelectionMenuItem")}
            menuButtonLabel={``}
            menuItemIconClassName={cx('laneIcon')}
            selectedValue={(selectedLane)}
        >
            <div className={cx('laneSelectionButtonContent')}>
                <Icon id={`lane${selectedLane}`} className={cx('laneIcon')}></Icon>
                <span className={cx(`laneName`)}>{getLaneLabelText(selectedLane)}</span>
                <Icon id='chevron' className={cx('chevron')}></Icon>
            </div>
        </DropdownMenuContainer>
        
        <div className={cx("tsqSettingsFields")}>

            <div className={cx("yAxisMinMax")}>
                <div className={cx('tsqSettingsFieldTitle')} id={yAxisIds.yAxisLabelId}>
                    {props.t('settingsModal.yAxisRange')}
                </div>
                {!yExtentsValid ?
                    <div className={cx('customYAxisWarning')}>
                        {getYAxisWarningText(yAxisWarning)}
                    </div> :
                    <div>
                        <div className={cx('radioButtonContainer')} role="radiogroup" aria-labelledby={yAxisIds.yAxisLabelId}>
                            <div className={cx('_base-radioButton', 'yAxisRadio')}>
                                <input
                                    name="yAxisExtentState"
                                    type="radio"
                                    aria-labelledby={yAxisIds.yAxisAutoLabelId}
                                    aria-checked={autoYActive}
                                    checked={autoYActive}
                                    id={yAxisIds.yAxisAutoRadioId}
                                    onChange={() => {
                                        resetAutoMinMax();
                                        props.setYAxisState(selectedLane, YAxisState.auto);
                                    }}
                                    disabled={!yExtentsValid} />
                                <label id={yAxisIds.yAxisAutoLabelId} htmlFor={yAxisIds.yAxisAutoRadioId}>
                                    {props.t('settingsModal.auto')}
                                </label>
                            </div>
                            <div className={cx('_base-radioButton', 'yAxisRadio')}>
                                <input
                                    name="yAxisExtentState"
                                    type="radio"
                                    aria-labelledby={yAxisIds.yAxisManualLabelId}
                                    aria-checked={!autoYActive}
                                    checked={!autoYActive}
                                    id={yAxisIds.yAxisManualRadioId}
                                    onChange={() =>
                                        props.setYAxisState(selectedLane, YAxisState.manual)
                                    }
                                    disabled={!yExtentsValid} />
                                <label id={yAxisIds.yAxisManualLabelId} htmlFor={yAxisIds.yAxisManualRadioId}>
                                    {props.t('settingsModal.manual')}
                                </label>
                            </div>
                        </div>
                        <div className={cx('yAxisCustomExtentsContainer', `${autoYActive ? 'disabled' : ''}`)}>
                            <div className={cx('inputElement')}>
                                <label tabIndex={-1} htmlFor={yAxisIds.yAxisMinInputId} id={yAxisIds.yAxisMinLabelId}>
                                    {props.t('settingsModal.min')}
                                </label>
                                <input
                                    type="number"
                                    id={yAxisIds.yAxisMinInputId}
                                    disabled={autoYActive}
                                    aria-labelledby={yAxisIds.yAxisMinLabelId}
                                    className={cx(`${yAxisError.min ? 'error' : ''}`)}
                                    tabIndex={autoYActive ? -1 : null}
                                    onChange={(e) => handleYAxisMinMaxInputChange(e, YAxisState.min)}
                                    value={yAxisMin} />
                            </div>
                            {yAxisError.gt ? <div className={cx('minGreaterError')}> {'>='} </div> : null}
                            <div className={cx('inputElement')}>
                                <label tabIndex={-1} htmlFor={yAxisIds.yAxisMaxInputId} id={yAxisIds.yAxisMaxLabelId}>
                                    {props.t('settingsModal.max')}
                                </label>
                                <input
                                    type="number"
                                    id={yAxisIds.yAxisMaxInputId}
                                    disabled={autoYActive}
                                    aria-labelledby={yAxisIds.yAxisMaxLabelId}
                                    className={cx(`${yAxisError.max ? 'error' : ''}`)}
                                    tabIndex={autoYActive ? -1 : null}
                                    onChange={(e) => handleYAxisMinMaxInputChange(e, YAxisState.max)}
                                    value={yAxisMax} />
                            </div>
                        </div>
                    </div>
                }
            </div>
                    
            <div className={cx("yAxisType")}>
                <div className={cx('sectionHeader')} id={elementIds.axisTypeLabelId}>
                    {props.t('settingsModal.yAxisType')}
                </div>
                <div>
                    <div className={cx('yAxisSelectionContainer')} role="radiogroup" aria-labelledby={elementIds.axisTypeLabelId}> 
                        <div className={cx('_base-radioButton', 'yAxisRadio')}>
                            <input 
                                name="yAxisType" 
                                type="radio"
                                aria-labelledby={elementIds.axisSharedLabelId} 
                                aria-checked={getYAxisState() === Utils.getStackStates().Shared} 
                                checked={getYAxisState() === Utils.getStackStates().Shared} 
                                id={elementIds.axisSharedRadioId}
                                onChange={() => {
                                    props.setSwimlaneField(selectedLane, 'yAxisType', Utils.getStackStates().Shared)
                                }} />
                            <label 
                                id={elementIds.axisSharedLabelId} 
                                htmlFor={elementIds.axisSharedRadioId}>
                                    {props.t('commanding.shared')}
                            </label>
                        </div>
                        <div className={cx('_base-radioButton', 'yAxisRadio')}>
                            <input 
                                name="yAxisType" 
                                type="radio" 
                                aria-labelledby={elementIds.axisOverlapLabelId} 
                                aria-checked={getYAxisState() === Utils.getStackStates().Overlap} 
                                checked={getYAxisState() === Utils.getStackStates().Overlap} 
                                id={elementIds.axisOverlapRadioId} 
                                onChange={() => {
                                    props.setSwimlaneField(selectedLane, 'yAxisType', Utils.getStackStates().Overlap)
                                }}/>
                            <label 
                                id={elementIds.axisOverlapLabelId} 
                                htmlFor={elementIds.axisOverlapRadioId}>
                                    {props.t('commanding.overlap')}
                            </label>
                        </div>
                    </div>
                </div>

                {(getYAxisState() === Utils.getStackStates().Overlap 
                || props.linechartStackState === Utils.getStackStates().Overlap) && 
                <InfoBanner>{props.t('settingsModal.someSettingsDisabledWithOverlap')}</InfoBanner>}
                
                <div className={cx('sectionHeader')} id={elementIds.axisTextLabelId}>
                    {props.t('settingsModal.label')}
                </div>
                <div className={cx('labelContainer')}>
                    <input 
                        className={cx('labelInput')} 
                        placeholder={props.t('settingsModal.laneLabelPlaceholder')} 
                        value={labelText} 
                        onChange={(e) => setLabelText(e.target.value)} 
                        type="text" 
                        onKeyDown={e => handleLabelKeydown(e)} />
                    <button 
                        className={cx('applyButton')} 
                        onClick={() => {setLaneLabel(labelText)}} 
                        disabled={!isLabelOutOfSync()}></button>
                </div>
            </div>
            <div className={cx('sectionHeader', 'extraHeaderMargin')} id={elementIds.axisTypeLabelId}>
                {props.t('settingsModal.referenceLines')}
            </div>
            <HorizontalMarkerEditor 
                t={props.t} 
                theme={props.theme} 
                markers={props.laneOptions[selectedLane].horizontalMarkers}
                addEmptyMarker={() => props.addHorizontalMarker(selectedLane)}
                updateMarker={(markerIndex, value, color) => 
                    props.updateHorizontalMarker(selectedLane, markerIndex, value, color)} 
                removeMarker={(markerIndex) => props.removeHorizontalMarker(selectedLane, markerIndex)} />
        </div>
    </div>);
}
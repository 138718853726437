import React from "react";
import FluentCommandBarContainer from "../FluentCommandBar/FluentCommandBar.container";
import classNames from 'classnames/bind';
import { CommandBarUtilities } from "../FluentCommandBar/FluentCommandBar";
import { ICommandBarItemProps, TooltipHost } from "@fluentui/react";
import { analyticsCommandingIcons } from '../../../constants/CustomFluentIconPacks';
import {AnalyticsCommandModals} from '../../../constants/Enums'
import Utils from '../../services/Utils';
import OpenContainer from './Open/Open.container'
import SaveContainer from "./Save/Save.container";
import ShareContainer from "./Share/Share.container";
import RefreshChartsContainer from "./RefreshCharts/RefreshCharts.container";
import Icon from "../Icon/Icon";

const cx = classNames.bind(require('./AnalyticsCommandBar.module.scss'));

interface Props{
    t: any;
    theme: string;
    isSaveOpenShareVisible: boolean;
    setIsSaveOpenShareVisible: any;
    getShortlink: any;
    clearShortlink: any;
    isAvailabilityDistributionIsLoading: boolean;
    setChartSettingsToAutoRefresh: any;
    isPaygEnvWithoutWarmStore: boolean;
    environmentIsLts: boolean;
    warmStoreRange: any;
    environmentHasWarmStore: boolean;
    autoRefreshCycleMillis: number;
    isAutoRefreshEnabled: boolean;
    autoRefreshTimeSpanMillis: number;
    cycleAutoRefresh: (isEnabled: boolean) => any;
}

interface State{
    openModal: boolean,
    saveModal: boolean,
    shareModal: boolean
}

export default class AnalyticsCommandBar extends React.Component<Props, State>{

    private openButtonRef;
    private saveButtonRef;
    private shareButtonRef;
    private refreshRef;
    private burndownRef;

    private defaultModalState = {
        [AnalyticsCommandModals.openModal]: false,
        [AnalyticsCommandModals.saveModal]: false,
        [AnalyticsCommandModals.shareModal]: false
    }

    constructor(props){
        super(props);

        this.state = {
            ...this.defaultModalState
        }

        this.openButtonRef = React.createRef();
        this.saveButtonRef = React.createRef();
        this.shareButtonRef = React.createRef();
        this.refreshRef = React.createRef();
        this.burndownRef = React.createRef();
        this.refreshBurndownOnVisibilityChange = this.refreshBurndownOnVisibilityChange.bind(this);
    }

    componentDidMount(){
        document.addEventListener("visibilitychange", this.refreshBurndownOnVisibilityChange);
    }

    componentWillUnmount(){
        document.removeEventListener("visibilitychange", this.refreshBurndownOnVisibilityChange);
    }

    refreshBurndownOnVisibilityChange(){
        if(this.burndownRef?.current){
            if(document.hidden){
                // Stop animation
                this.burndownRef.current.style.display = "none";
            }
            else{
                // Start animation
                this.toggleBurndownVisibility();
            }
        }
    }

    toggleBurndownVisibility = () => {
        if(this.burndownRef?.current?.style){
            this.burndownRef.current.style.display = "none";
            setTimeout(() => {
                this.burndownRef.current.style.display = "block";
            })
        }
    }

    setOpenModal = (modal: AnalyticsCommandModals, isOpen: boolean) => {
        this.setState({
            ...this.defaultModalState,
            [modal]: isOpen
        });
        this.props.setIsSaveOpenShareVisible(isOpen);

        if(modal === AnalyticsCommandModals.shareModal){
            this.props.getShortlink();
        } else{
            this.props.clearShortlink();
        }
    }

    render(){
        return(
            <div className={cx('AnalyticsCommandBarWrapper')}>
                <div className={cx('FluentCommandBar')}>
                    <FluentCommandBarContainer
                        leftItems={this.getLeftItems()}
                        height='40px'
                        icons={analyticsCommandingIcons}
                        shiftOnReduce={true}
                        onDataGrown={(item) => {
                            if(item.key === "autoRefresh"){
                                this.props.cycleAutoRefresh(this.props.isAutoRefreshEnabled);
                            }
                        }}
                    />
                    {(this.state.openModal && this.props.isSaveOpenShareVisible) && 
                        <OpenContainer 
                            setOpenModal={this.setOpenModal}
                            openButtonRef={this.openButtonRef}
                        />
                    }
                    {(this.state.saveModal && this.props.isSaveOpenShareVisible) && 
                        <SaveContainer
                            setOpenModal={this.setOpenModal}
                            saveButtonRef={this.saveButtonRef}
                        />
                    }
                    {(this.state.shareModal && this.props.isSaveOpenShareVisible) && 
                        <ShareContainer
                            setOpenModal={this.setOpenModal}
                            shareButtonRef={this.shareButtonRef}
                        />  
                    }
                </div>
                <RefreshChartsContainer ref={this.refreshRef} />
            </div>
        )
    }

    private focusAutoRefreshSettings (e) {
        e.preventDefault();
        e.stopPropagation();
        this.props.setChartSettingsToAutoRefresh();
    }

    getBurnerClassName = () => {
        let cycleTime = this.props.autoRefreshCycleMillis
        const milliClassNameMap = {
            [1000 * 15]: 'burn_15s',
            [1000 * 30]: 'burn_30s',
            [1000 * 60]: 'burn_60s'
        }
        if(cycleTime in milliClassNameMap){
            return cx('burner', milliClassNameMap[cycleTime])
        } else{
            return '';
        }
    }

    getAutoRefreshComponent(renderedInOverflow: boolean = false){
        let isPaygEnvWithoutWarmStore = this.props.environmentIsLts && (this.props.warmStoreRange === null || !this.props.environmentHasWarmStore);
        
        let toggleButton = <div className={cx('autoRefreshToggleContainer', this.props.isAutoRefreshEnabled ? cx('burn') : '')}>
                                <button key='toggleButton' role="checkbox" disabled={isPaygEnvWithoutWarmStore && !this.props.isAutoRefreshEnabled} aria-checked={this.props.isAutoRefreshEnabled} 
                                    aria-label={this.props.t('autoRefresh.autoRefresh')} className={cx('toggleSliderWrapper', this.props.isAutoRefreshEnabled ? '' : 'off')} 
                                    onClick={() => this.refreshRef.current.getWrappedInstance().wrappedInstance.handleAutoRefreshChange()}>
                                    <div className={cx('toggleButton')}></div>
                                </button>
                                <div className={cx('burndownContainer')}>
                                    {!renderedInOverflow && <div className={this.getBurnerClassName()} ref={this.burndownRef}></div>}
                                </div>
                            </div>

        let autoRefreshTitle =  <div className={cx('auto-refresh-title')}>
                                    <span className={cx('autoRefreshText')} title={this.props.t('autoRefresh.autoRefresh')}>{this.props.t('autoRefresh.autoRefresh')}</span>
                                </div>;

        let tooltipContent = this.props.t('autoRefresh.warmOnlyAutoRefresh');

        let onOffText = this.props.isAutoRefreshEnabled ? this.props.t('on') : this.props.t('off');

        const onTooltipToggle = () => CommandBarUtilities.themifyFloatingLayer(this.props.theme);
        const calloutProps = {
            styles: CommandBarUtilities.getCalloutStyles(this.props.theme, false),
            gapSpace: 4,
            calloutMaxWidth: 240
        }
        
        if(!isPaygEnvWithoutWarmStore){
            if(!this.props.isAutoRefreshEnabled){
                tooltipContent = '';
            } else{
                tooltipContent = this.props.t('autoRefresh.refreshInterval') + " " + this.props.t(`autoRefresh.${this.props.autoRefreshCycleMillis}`)
            }
        }

        return (
            <div className={cx('auto-refresh-wrapper')}>
                {autoRefreshTitle}
                <TooltipHost 
                    content={tooltipContent}
                    onTooltipToggle={onTooltipToggle}
                    calloutProps={calloutProps}
                >
                    {toggleButton}
                </TooltipHost>
                
                { !isPaygEnvWithoutWarmStore &&
                    [<span key='1' className={cx('toggle-text')} title={onOffText}>{onOffText}</span>,
                    <TooltipHost
                        content={
                            this.props.t('autoRefresh.refreshInfo1') + 
                            ' [' + this.props.t(`autoRefresh.${this.props.autoRefreshTimeSpanMillis}`) + '] ' + 
                            this.props.t('autoRefresh.refreshInfo2') + 
                            ' [' + this.props.t(`autoRefresh.${this.props.autoRefreshCycleMillis}`) + ']'
                        }
                        onTooltipToggle={onTooltipToggle}
                        calloutProps={calloutProps}
                        key='2'
                    >
                        <div className={cx('infoCircleWrapper')}>
                            <Icon id={'info'} className={cx('icon16')}/>
                        </div>
                    </TooltipHost>
                    ]
                }
            </div>
        ); 
    }

    getLeftItems: () => ICommandBarItemProps[] = () => {
        return([
            { 
                key: 'open', 
                disabled: Utils.isSampleEnvironments(),
                text: this.props.t('openQuery'),
                componentRef: this.openButtonRef,
                ariaLabel: this.props.t('openQueryTitle'),
                onClick: () => this.setOpenModal(AnalyticsCommandModals.openModal, true), 
                iconProps: { iconName: this.props.theme === 'light' ? 'openFolderLight' : 'openFolderDark' },
                className: CommandBarUtilities.style('commandItem','wide', Utils.isSampleEnvironments() ? 'iconDisabled' : ''),
            },
            { 
                key: 'save', 
                disabled: Utils.isSampleEnvironments(),
                text: this.props.t('saveQuery'),
                componentRef: this.saveButtonRef,
                ariaLabel: this.props.t('saveQueryTitle'),
                onClick: () => this.setOpenModal(AnalyticsCommandModals.saveModal, true), 
                iconProps: { iconName: this.props.theme === 'light' ? 'iconSaveLight' : 'iconSaveDark' },
                className: CommandBarUtilities.style('commandItem','wide', Utils.isSampleEnvironments() ? 'iconDisabled' : ''),
            },
            { 
                key: 'share', 
                text: this.props.t('shareQuery'),
                componentRef: this.shareButtonRef,
                ariaLabel: this.props.t('shareQueryTitle'),
                onClick: () => this.setOpenModal(AnalyticsCommandModals.shareModal, true), 
                iconProps: { iconName: this.props.theme === 'light' ? 'linkLight' : 'linkDark' },
                className: CommandBarUtilities.style('commandItem','wide'),
            },
            { 
                key: 'refresh', 
                title: this.props.t('refresh'),
                text: this.props.t('refresh'),
                onClick: () => {
                    this.refreshRef.current.getWrappedInstance().wrappedInstance.refreshAvailabilityAndLineCharts(true)
                    this.toggleBurndownVisibility();
                }, 
                iconProps: { 
                    iconName: this.props.theme === 'light' ? 
                        this.props.isAvailabilityDistributionIsLoading ? 'refreshLightRotating' : 'refreshLight'
                        :   this.props.isAvailabilityDistributionIsLoading ? 'refreshDarkRotating' : 'refreshDark' 
                },
                className: CommandBarUtilities.style('commandItem', 'wide'),
                split: true,
                subMenuProps: !this.props.isPaygEnvWithoutWarmStore ? {
                    styles: CommandBarUtilities.getMenuStyles(this.props.theme, '115.59px'), // Applies theme and width to submenu callout
                    className: CommandBarUtilities.style('dropdownMenu'), // Styling for dropdown menu
                    onMenuOpened: () => CommandBarUtilities.themifyFloatingLayer(this.props.theme), // Callback to apply 'theme-dark' | 'theme-light' to floating layer
                    items: [
                        { 
                            key: 'configureAutoRefresh', 
                            text: this.props.t('autoRefresh.configureAutoRefresh'),
                            onClick: (e) => { this.focusAutoRefreshSettings(e) },
                            iconProps: { 
                                iconName: this.props.theme === 'light' ? 'gearLight' : 'gearDark'
                            },
                        },
                    ],
                } : null
            },
            { 
                key: 'autoRefresh', 
                ariaLabel: this.props.t('autoRefresh.autoRefresh'),
                className: CommandBarUtilities.style('commandItem','wide', 'autoRefreshOverflow'),
                onRender: (item) => {
                    return this.getAutoRefreshComponent(item.renderedInOverflow)
                },
            },
        ])
    }
}

export const LetUsKnowLink = (props) => {
    return <button 
        aria-label={props.t('well.letUsKnowUpper')} 
        onClick={() => props.openFeedbackForm(props.issue, null, props.message)}>
        {props.t('well.letUsKnowUpper')}
    </button>
}


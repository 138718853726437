import { translate } from "react-i18next"
import { connect } from "react-redux"
import Utils from '../../../services/Utils';
import { SET_ENVIRONMENT_FROM_SAVED_QUERY, SET_SAVED_QUERY_NAME, FILL_STATE_FROM_SAVED_QUERY, DELETE_QUERY, SET_SAVED_QUERY_FILTER, OPEN_FEEDBACK_FORM } from "../../../../constants/ActionTypes"
import { getSavedQueriesArray, getDeletedQueryStatus, getSavedQueryFilter } from "../../../state/reducers/AnalyticsReducer"
import { getEnvironments, getTheme } from "../../../state/reducers/AppSettingsReducer"
import Open from "./Open"

const mapStateToProps = state => {
    return {
        theme: getTheme(state),
        queries: getSavedQueriesArray(state),
        environments: Utils.createKeyedObjectFromArray(getEnvironments(state), 'environmentId'),
        deletedQueryStatus: getDeletedQueryStatus(state),
        savedQueryFilter: getSavedQueryFilter(state),
    }
}

const mapDispatchToProps = dispatch => ({
    openSavedQuery: (query) => {
        dispatch({type: SET_ENVIRONMENT_FROM_SAVED_QUERY, payload: query}); 
        dispatch({type: SET_SAVED_QUERY_NAME, payload: query.name});
        dispatch({type: FILL_STATE_FROM_SAVED_QUERY, payload: query});
    },
    deleteQuery: (query) => {
        dispatch({type: DELETE_QUERY, payload: query});
        dispatch({type: SET_SAVED_QUERY_NAME, payload: null});
    },
    setSavedQueryFilter: filter => {
        dispatch({type: SET_SAVED_QUERY_FILTER, payload: filter});
    },
    openFeedbackForm: (issue: string, requestId: string, responseText: string): void => dispatch({ 
        type: OPEN_FEEDBACK_FORM,
        payload: { issue, requestId, responseText }
    })
})

export default translate()(connect(mapStateToProps, mapDispatchToProps)(Open))
import * as React from 'react';
import classNames from 'classnames/bind';
import Icon from '../Icon/Icon';
import { NotificationInfo } from '../../models/AppNotificationModels';
import NotificationService from '../../services/NotificationService';
import SidePane from '../Modal/SidePane/SidePane';
import AppNotification from '../AppNotification/AppNotification';
const cx = classNames.bind(require('./AppNotificationHistory.module.scss'));

interface Props {
    t: any,
    theme: string,
    notifications: Array<NotificationInfo>,
    unseenNotifications: Array<string>,
    openFeedbackForm: (issue: string, requestId: string, responseText: string) => {}
}

interface State { isOpen: boolean; }

export default class AppNotificationHistory extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = { isOpen: false };
    }

    handleOnOpenPanel = () => {
        this.setState({ isOpen: !this.state.isOpen });

        let notificationIds = this.props.notifications.map(n => n.id);
        NotificationService.markNotificationsAsSeen(notificationIds);
    }

    handleOnCloseNotification = (id: string) => {
        NotificationService.dismissNotification(id);
    }

    handleOnClickTellUsMore = (notificationId: string, issue: string, requestId: string, responseText: string) => {
        this.setState({ isOpen: false });
        this.props.openFeedbackForm(issue, requestId, responseText);
    }

    render() {
        const { t, theme, notifications, unseenNotifications } = this.props;
        let unseenCounter = unseenNotifications.length;

        return <div className={cx('wrapper')}>
            <button aria-label={t('openNotificationsHistory')} onClick={this.handleOnOpenPanel} className={cx('openToggle', {open: this.state.isOpen})}>
                <Icon id="notifications" className={cx('icon16')} />
                {unseenCounter > 0 && <div className={cx('unseenCounter')}>{unseenCounter > 10 ? '+' : `${unseenCounter}`}</div>}
            </button>
            {this.state.isOpen &&
                <SidePane
                    wrapperClassName={cx('paneWrapper')}
                    title={t('notifications.title')}
                    handleOnClose={() => { this.setState({ isOpen: false }) }}>
                    <div className={cx('notificationsList')}>
                        {notifications.length === 0 && <div className={cx('noNotificationsMessage')}>
                            {t('notifications.none')}
                        </div>}
                        {notifications.length > 0 && notifications.map(n => <AppNotification
                            key={`notification-${n.id}`}
                            t={t}
                            theme={theme}
                            id={n.id}
                            titleKey={n.titleKey}
                            bypasseLocaleOptions={n.bypasseLocaleOptions}
                            messageKey={n.messageKey}
                            type={n.type}
                            requestId={n.requestId}
                            responseText={n.responseText}
                            action={n.action ? {...n.action, buttonOnClick: () => {this.handleOnOpenPanel(); n.action.buttonOnClick();}} : null}
                            isForToast={false}
                            handleOnClose={this.handleOnCloseNotification}
                            handleOnOpenFeedback={this.handleOnClickTellUsMore} />)}
                    </div>
                </SidePane>}
        </div>;
    }
}
import * as React from 'react';
import classNames from 'classnames/bind';
import ModalContainer from '../../components/Modal/Modal.container';
import TextField from '../../components/TextField/TextField';
import Icon from '../../components/Icon/Icon';
import LogViewer from '../../components/LogViewer/LogViewer';
const cx = classNames.bind(require('./Jobs.module.scss'));

export const ConfirmActionModal = ({ t, theme, title, description, warning, handleOnClickConfirm, handleOnClose, sourceElement }) =>
    <ModalContainer title={title} onClose={handleOnClose} sourceElement={sourceElement}>
        <div className={cx('addNewModal')}>
            <div className={cx('modalContent')}>
                <p className={cx('modalDescription')}>{description}</p>
                {warning && <div className={cx('modalDescription')}>
                    <Icon id={`iconStatusWarning-${theme}`} className={cx('icon16')} />
                    {warning}
                </div>}
            </div>
            <div className={cx('modalButtons')}>
                <button className={cx('saveButton')} onClick={handleOnClickConfirm} aria-label={t('confirm')}>{t('confirm')}</button>
                <button className={cx('cancelButton')} onClick={handleOnClose} aria-label={t('cancel')}>{t('cancel')}</button>
            </div>
        </div>
    </ModalContainer>;

export const AddNewJobModal = ({
    t,
    handleOnClose,
    handleOnSave,
    jobName,
    handleOnJobNameChange,
    jobNameErrorMessage,
    importPath,
    handleOnImportPathChange,
    importPathErrorMessage,
    timestamp,
    handleOnTimestampChange,
    timestampErrorMessage,
    sourceElement }) =>
    <ModalContainer title={t('jobs.addNewTitle')} onClose={handleOnClose} sourceElement={sourceElement}>
        <div className={cx('addNewModal')}>
            <div className={cx('modalContent')}>
                <div className={cx('modalDescription')}>
                    {t('jobs.addNewDescription')}
                    <Icon id='blueInfo' title={t('jobs.addNewInfotip')} className={cx('icon16', 'infotip')} />
                </div>
                <TextField
                    label={t('jobs.name')}
                    description={t('jobs.nameFieldDescription')}
                    value={jobName}
                    handleOnChange={handleOnJobNameChange}
                    errorMessage={jobNameErrorMessage} />
                <TextField
                    label={t('jobs.path')}
                    description={t('jobs.pathFieldDescription')}
                    value={importPath}
                    handleOnChange={handleOnImportPathChange}
                    errorMessage={importPathErrorMessage} />
                <TextField
                    label={t('jobs.timestamp')}
                    description={t('jobs.timestampFieldDescription')}
                    value={timestamp}
                    handleOnChange={handleOnTimestampChange}
                    errorMessage={timestampErrorMessage} />
            </div>
            <div className={cx('modalButtons')}>
                <button className={cx('cancelButton')} onClick={handleOnClose} aria-label={t('cancel')}>{t('cancel')}</button>
                <button className={cx('saveButton')} onClick={handleOnSave} aria-label={t('save')}>{t('save')}</button>
            </div>
        </div>
    </ModalContainer>;

export const JobsLogsModal = ({
    t,
    theme,
    logContents,
    errorMessage,
    handleOnClose,
    handleOnRefresh,
    handleOnDownload,
    sourceElement,
    jobName }) => {
    let logsMessage = null;
    let areLogsEmpty = Array.isArray(logContents) && logContents.length === 0;
    if(errorMessage) {
        logsMessage = errorMessage;
    } else if(areLogsEmpty) {
        logsMessage = t('jobs.emptyLogs');
    }

    return <ModalContainer title={jobName} onClose={handleOnClose} sourceElement={sourceElement.current} 
        titleClassName={cx('logsModalTitle')} contentClassName={cx('logsModalContent')}>
        <div className={cx('viewLogsModal')}>
            <LogViewer t={t} logLines={logContents} message={logsMessage} />
            <div className={cx('modalButtons')}>
                <button className={cx('secondaryButton', 'buttonWithIcon')} onClick={handleOnRefresh} aria-label={t('refresh')}>
                    <Icon id={'refresh-' + theme} className={cx({ icon16: true })} />
                    {t('refresh')}
                </button>
                <button disabled={areLogsEmpty} className={cx('secondaryButton', 'buttonWithIcon')} onClick={handleOnDownload} aria-label={t('jobs.downloadLogs')}>
                    <Icon id={'download'} className={cx('downloadIcon')}/>
                    {t('jobs.downloadLogs')}
                </button>
                <button className={cx('primaryButton')} onClick={handleOnClose} aria-label={t('close')}>{t('close')}</button>
            </div>
        </div>
    </ModalContainer>
};

import React, { useState } from 'react';
import Icon from '../Icon/Icon';
import classNames from 'classnames/bind';
const cx = classNames.bind(require('./WarningBanner.module.scss'));

interface Props {
    t: any;
    theme: string;
    hideBannerSettingKey: string;
    onClick?: () => void;
    children: any;
}

const noOp = () => { };

export default function WarningBanner({ t, theme, hideBannerSettingKey, onClick = null, children }: Props) {
    const shouldHideBanner = sessionStorage.getItem(hideBannerSettingKey) === null;
    const [showBanner, setShowBanner] = useState(shouldHideBanner);

    const handleDismissBanner = () => {
        sessionStorage.setItem(hideBannerSettingKey, 'true');
        setShowBanner(false);
    };

    return <>{showBanner && <div className={cx('banner', { clickable: onClick !== null })}
        onClick={onClick || noOp}>
        <div className={cx('bannerWrapper')}>
            <Icon id={`iconStatusWarning-${theme}`} className={cx('icon24')} />
            <span className={cx('bannerText')}>{children}</span>
            <button className={cx('closeButton')} tabIndex={1} onClick={handleDismissBanner} aria-label={t('close')}>
                <Icon id={'iconClose-' + theme} className={cx('icon')} />
            </button>
        </div>
    </div>}</>;
}
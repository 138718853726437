import * as React from 'react';
import classNames from 'classnames/bind';
import { NotificationType, NotificationAction, BypassLocaleOptions } from "../../models/AppNotificationModels";
import Icon from "../Icon/Icon";
import Utils from '../../services/Utils';
const cx = classNames.bind(require('./AppNotification.module.scss'));

export function getIconName(type: NotificationType, theme: string) {
    switch (type) {
        case NotificationType.Error: return `iconStatusError-${theme}`;
        case NotificationType.Migration: return `iconStatusWarning-${theme}`;
        case NotificationType.Warning: return `iconStatusWarning-${theme}`;
        case NotificationType.Info: return 'info';
        default: return 'info';
    }
}

interface NotificationProps {
    t: any,
    id: string,
    titleKey: string,
    messageKey: string,
    theme: string,
    type: NotificationType,
    bypasseLocaleOptions: BypassLocaleOptions,
    requestId: string,
    responseText: string,
    action: NotificationAction,
    isForToast: boolean,
    handleOnClose: (id: string, wasClosedManually: boolean) => void,
    handleOnOpenFeedback: (notificationId: string, issue: string, requestId: string, responseText: string) => void
}

class AppNotification extends React.Component<NotificationProps> {
    readonly timeBeforeCloseMs: number = 10 * 1000;
    private timeout: number;

    constructor(props) {
        super(props);
        this.timeout = null;
    }

    componentDidMount() {
        if(this.props.isForToast){
            this.timeout = window.setTimeout(
                () => this.props.handleOnClose(this.props.id, false),
                this.timeBeforeCloseMs);
        }
    }

    componentWillUnmount() {
        if (this.timeout) {
            window.clearTimeout(this.timeout);
        }
    }

    render() {
        const { t, theme, titleKey, messageKey, type, id, handleOnClose, handleOnOpenFeedback, requestId, responseText, action, bypasseLocaleOptions } = this.props;
        let iconName = getIconName(type, theme);

        return <div className={cx('notification')}>
            <button className={cx('closeButton')} onClick={() => handleOnClose(id, true)}>
                <Icon id={`iconClose-${theme}`} className={cx('icon16')} />
            </button>
            <div className={cx('iconArea')}>
                <Icon id={iconName} className={cx('icon24')} />
            </div>
            <div className={cx('notificationContent')}>
                <div className={cx('title')}>{bypasseLocaleOptions?.isTitleBypassed ? titleKey : t(titleKey)}</div>
                <div className={cx('message')}>{bypasseLocaleOptions?.isMessageBypassed ? messageKey : t(messageKey)}</div>
                <div className={cx('responseText')}>{Utils.getErrorResponse({response: responseText}, () => null)}</div>
                <div className={cx('notificationFooter', action ? 'justifySpaceBetween' : '')}>
                    {action !== null &&
                        <button className={cx('actionButton')} onClick={action.buttonOnClick}>{t(action.buttonLabelKey)}</button>
                    }
                    <button
                        className={cx('anchorButton')}
                        aria-label={t('notifications.letUsKnow')} 
                        onClick={() => handleOnOpenFeedback(id, t(messageKey), requestId, responseText)}>
                        {t('notifications.letUsKnow')}
                    </button>
                </div>
            </div>
        </div>;
    }
}

export default AppNotification;
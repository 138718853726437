import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import EventsTable from './EventsTable';
import { CLEAR_EVENTS, GET_EVENTS } from '../../../constants/ActionTypes';
import { getTheme, getSelectedEnvironmentIsLts } from '../../state/reducers/AppSettingsReducer';
import { getIsLoadingEvents, getEventsError, getTimezone, getLoadingEventsProgress, getExploreEventsPayload, getIsMoreRawEventsAvailable, getIsRawEventsSetIncomplete } from '../../state/reducers/AnalyticsReducer';
import { getTimeSeriesIdProperties } from '../../state/reducers/ModelReducer';

const mapStateToProps = state => {
    return { 
        events: state.analytics.events,
        theme: getTheme(state),
        isLoading: getIsLoadingEvents(state),
        error: getEventsError(state),
        timezone: getTimezone(state),
        loadingEventsProgress: getLoadingEventsProgress(state),
        environmentIsLts: getSelectedEnvironmentIsLts(state),
        exploreEventsPayload: getExploreEventsPayload(state),
        isMoreRawEventsAvailable: getIsMoreRawEventsAvailable(state),
        isRawEventsSetIncomplete: getIsRawEventsSetIncomplete(state),
        timeSeriesIdProperties: getTimeSeriesIdProperties(state)
    };
};

const mapDispatchToProps = dispatch => ({
    clearEvents: () => dispatch({type: CLEAR_EVENTS}),
    getEvents: (predicateObject, take) => {
        predicateObject.forEach((p, i) => {p.getEvents.take = take;});
        dispatch({type: GET_EVENTS, payload: {predicateObject: predicateObject, forceIsMoreRawEventsAvailableToBeFalse: true}});
    }
});

const EventsTableContainer = translate()(connect(mapStateToProps, mapDispatchToProps)(EventsTable));

export default EventsTableContainer;

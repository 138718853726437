import icons from '../../resources/icons/icons.js';

export default class IconService {

    static idToSvgMap = {};
    static svgSymbols;

    static iconDocument: Document = (() => {
        let parser = new DOMParser();
        return parser.parseFromString(icons, "text/html");
    })();

    static getSvg(id: string) {
        if (id in this.idToSvgMap) {
            return this.idToSvgMap[id];
        }
        else {
            let el = this.iconDocument.getElementById(id);
            if (!el) {
                id = 'placeholder';
                el = this.iconDocument.getElementById(id);
            }
            let elClone = el.cloneNode(true) as Element;
            elClone.removeAttribute('id');
            Object.assign(elClone['style'], {height: '100%', width: '100%', position: 'absolute'});
            let svgContent = new XMLSerializer().serializeToString(elClone);
            this.idToSvgMap[id] = svgContent;
            return svgContent; 
        }
    }
}
    
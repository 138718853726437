import * as React from 'react';
import classNames from 'classnames/bind';
import Icon from '../Icon/Icon';
import Utils from '../../services/Utils';
import ModalContainer from '../Modal/Modal.container';
import { AppPages, KeyCodes } from '../../../constants/Enums';
const cx = classNames.bind(require('./Environments.module.scss'));

interface Props {
    t: any; 
    theme: string; 
    openUserSettings: any; 
    selectedTenant: any; 
    isLoadingEnvironments: boolean; 
    environments: Array<any>; 
    selectEnvironment: any; 
    selectedEnvironment: any; 
    isVisible: any; 
    toggleEnvironments: any; 
    activePage: string;
    selectedQueryName: string; }
interface State { focusedOptionFqdn: any;}

class Environments extends React.Component<Props, State> {

    private outerButtonRef;

    constructor(props: Props) {
        super(props);
        this.state = {focusedOptionFqdn: this.getInitialFocusedOptionFqdn(this.props.selectedEnvironment.environmentFqdn)};
        this.outerButtonRef = React.createRef();
    }

    componentDidUpdate (prevProps) {
        if (prevProps.selectedEnvironment.environmentFqdn !== this.props.selectedEnvironment.environmentFqdn) {
            this.setState({focusedOptionFqdn: this.props.selectedEnvironment.environmentFqdn});
        }
        if (this.props.activePage !== prevProps.activePage && this.props.activePage === AppPages.Analytics) {
            this.focusOuterButton();
        }
    }

    private getInitialFocusedOptionFqdn = (selectedFqdn) => {
        if (selectedFqdn) {
            return selectedFqdn;
        }
        if (this.props.environments && this.props.environments.length !== 0) {
            return this.props.environments[0].environmentFqdn;
        }
        return null;
    }

    expandCollapse = () => {
        this.props.toggleEnvironments();
        Utils.triggerResize(500);
    }

    selectEnvironment = (environmentFqdn) => (e) => {
        if (Utils.isKeyDownAndNotEnter(e)) {return; }
        this.props.selectEnvironment(environmentFqdn);
        this.focusOuterButton();
    }

    private focusOuterButton = () => {
        this.outerButtonRef && this.outerButtonRef.current && this.outerButtonRef.current.focus();
    }

    private getNextOptionIndex = (previousOption, optionsLength, isDown) => {
        return (previousOption + optionsLength + (isDown ? 1 : -1)) % optionsLength;
    }

    render() {
        return (
            <div className={cx('theme-' + this.props.theme, 'wrapper', this.props.activePage === AppPages.Analytics ? 'withBorderTop' : '')}>
                {(this.props.isLoadingEnvironments || this.props.environments.length === 0) && 
                    <LoadingAndEmptyModal props={{className: cx({noEnvsPositionContainer: this.props.environments.length === 0, hidden: !(this.props.isLoadingEnvironments || this.props.environments.length === 0)}), ...this.props}} />}
                <div className={cx('_base-breadcrumb', {singleEnv: this.props.environments.length <= 1})}>
                    <Icon id='environment' className={cx('icon24')}/>
                    {(this.props.environments.length === 1) ? (<h1>{this.props.selectedEnvironment.displayName}</h1>) : 
                    (<button ref={this.outerButtonRef} className={cx({hasFocus: this.props.isVisible})} onClick={this.props.toggleEnvironments} aria-expanded={this.props.isVisible} aria-haspopup='true'>
                        {this.props.selectedEnvironment.displayName}
                        <Icon id='chevron' className={cx('icon20')}/>
                    </button>)}
                </div>
                { this.props.selectedQueryName 
                    && <div className={cx('savedQueryName')}> {this.props.selectedQueryName}</div> }
                {(this.props.isVisible && this.props.environments.length > 1) &&
                    <ModalContainer hasCloseButton={false} contentClassName={cx('content')} className={cx('envsModal')} onClose={this.props.toggleEnvironments}>
                        <div className={cx('envsWrapper')}>
                        {this.props.environments.map(({displayName, environmentFqdn}, i) => {
                            return (<button ref={btn => (btn && environmentFqdn === this.state.focusedOptionFqdn) && btn.focus()} 
                                onClick={this.selectEnvironment(environmentFqdn)} key={i} tabIndex={0} 
                                className={cx({selected: environmentFqdn === this.props.selectedEnvironment.environmentFqdn})}
                                onKeyDown={(e) => {
                                    if (e.keyCode === KeyCodes.Tab) {
                                        e.preventDefault();
                                        let nextIndex = this.getNextOptionIndex(i, this.props.environments.length, !e.shiftKey);
                                        this.setState({focusedOptionFqdn: this.props.environments[nextIndex].environmentFqdn})
                                    }
                                    if (e.keyCode === KeyCodes.Esc) {
                                        this.setState({focusedOptionFqdn: this.props.selectedEnvironment.environmentFqdn});
                                        this.props.toggleEnvironments();
                                        this.focusOuterButton();
                                    }
                                }}>
                                <span>{displayName}</span>
                            </button>);
                        })}
                        </div>
                    </ModalContainer>
                }
            </div>
        );
    }
}

const LoadingAndEmptyModal = ({props}) => {
    let noEnvironmentContent = <div className={cx('noEnvs')}>
        <div className={cx('title')}>There are no environments available</div>
        <div className={cx('suggestion')}>Try one of the following:</div>
        <div className={cx('suggestion')}>&#8226;<a href={`https://${window.location.hostname}/demo`}>Check out a sample</a></div>
        <div className={cx('suggestion')}>&#8226;<a target="_blank" rel="noopener noreferrer" href="https://aka.ms/tsicreatenewenv">Create a new environment</a></div>
        <div className={cx('suggestion')}>&#8226;<button onClick={props.openUserSettings}>Select another directory</button>(recommended)</div>
    </div>;

    return <ModalContainer hasCloseButton={false} className={cx('fiveheadSplashWrapper') + ' ' + props.className}>
        <div className={cx('content')}>
            <div className={cx('fivehead')}>
                <Icon id='logo' className={cx('logo')}></Icon>
            </div>
            <div className={cx('text')}>
                {props.isLoadingEnvironments ? <div className={cx("title")}>{props.t('loadingEnvironments')}</div> : noEnvironmentContent}
            </div>
        </div>
    </ModalContainer>;
};

export default Environments;

import * as React from 'react';
import Utils from '../../services/Utils';
import update from 'immutability-helper';
import ChartableComponentContainer from '../ChartableComponent/ChartableComponent.container';

interface Props { t: any; theme: string; tall: boolean; queries: any; environmentIsLts: boolean;
     isLoadingAggregates: boolean; timezone: string; maintainedIntervalSize: any; setFocusTriggers: any;
     setActiveChartReference: any;}
interface State { }

class Heatmap extends React.Component<Props, State> {
    private chart;
    private chartId = Utils.guid();
    private currentMouseoverValue = '';

    shouldComponentUpdate(nextProps) {
        return Utils.shouldChartableComponentRender(this.props, nextProps);
    }

    heatmapOnMouseover = (aggKey, splitby) => {
        if (aggKey + splitby === this.currentMouseoverValue) {
            return;
        }
        Utils.triggerWellClass(this.props.queries, aggKey, splitby, 'wellHover');
        this.currentMouseoverValue = aggKey + splitby;
    }

    heatmapOnMouseout = () => {
        let elements = document.getElementsByClassName('wellHover');
        while (elements.length > 0) {
            elements[0].classList.remove('wellHover');
        }
        this.currentMouseoverValue = null;
    }

    renderChart (queries, chartData) {
        setTimeout(() => {
            this.chart = !this.chart ? new Utils.tsiClient.ux.Heatmap(document.getElementById(this.chartId)) : this.chart;
            this.props.setFocusTriggers(() => {}, () => {}, () => {});
            this.props.setActiveChartReference(this.chart);
            let queriesCopy;
            let maintainedIntervalSize = this.props.maintainedIntervalSize;
            if (maintainedIntervalSize !== null) {
                queriesCopy = queries.map((q) => {
                    return update(q, {searchSpan: {
                        bucketSize: {$set: maintainedIntervalSize}
                    }});
                });
            } else {
                queriesCopy = queries;
            }

            let chartData = queriesCopy.map(ts => ts.data);

            queriesCopy.forEach((q) => {
                if ((q.hidden || !q.data || q.noData()) && this.props.environmentIsLts) {
                    q.visibilityState = [false];
                } else {
                    q.visibilityState = [true];
                }
                
                //unset visibility state if not LTS
                if (!this.props.environmentIsLts) {
                    q.visibilityState = null;
                }
            });

            this.chart.render(chartData, {hideChartControlPanel: true, theme: this.props.theme,  offset: this.props.timezone, legend: (this.props.environmentIsLts ? 'hidden' : 'shown'),
                onMouseover: this.heatmapOnMouseover, onMouseout: this.heatmapOnMouseout, strings: this.props.t('sdkStrings', {returnObjects: true})}, queriesCopy);
        });
    }

    render() {
        let queries = this.props.queries.filter(ts => ts && ts.data);
        let chartData = queries.map(ts => ts.data);
        if ((chartData.length)) {
            this.renderChart(queries, chartData);
        }
        return (
        <ChartableComponentContainer chartId={this.chartId}>
        </ChartableComponentContainer>
        );
    }
}

export default Heatmap;

import * as React from 'react';
import classNames from 'classnames/bind';
import { NotificationInfo } from '../../models/AppNotificationModels';
import NotificationService from '../../services/NotificationService';
import AppNotification from '../AppNotification/AppNotification';
import { LocalStorageKeys } from '../../../constants/Enums';
const cx = classNames.bind(require('./AppNotificationArea.module.scss'));

interface Props {
    t: any,
    theme: string,
    notifications: Array<NotificationInfo>,
    openFeedbackForm: (issue: string, requestId: string, responseText: string) => void
}

class AppNotificationArea extends React.Component<Props> {

    handleOnCloseNotification = (id: string, wasClosedManually: boolean) => {
        NotificationService.hideNotification(id);
        if (wasClosedManually) {
            NotificationService.markNotificationsAsSeen([id]);
        }
    }
    
    handleOnLetUsKnow = (notificatonId: string, issue: string, requestId: string, responseText: string) => {
        this.handleOnCloseNotification(notificatonId, true);
        this.props.openFeedbackForm(issue, requestId, responseText);
    }

    render() {
        const { t, theme, notifications } = this.props;
        return <div className={cx('container', {hide: !localStorage.getItem(LocalStorageKeys.NewExplorerSplashSeen)})}>
            {notifications.map(n => <AppNotification
                t={t}
                theme={theme}
                id={n.id}
                titleKey={n.titleKey}
                bypasseLocaleOptions={n.bypasseLocaleOptions}
                key={`notification-${n.id}`}
                type={n.type}
                messageKey={n.messageKey}
                requestId={n.requestId}
                responseText={n.responseText}
                action={n.action ? {...n.action, buttonOnClick: () => {this.handleOnCloseNotification(n.id, true); n.action.buttonOnClick();}} : null}
                handleOnClose={this.handleOnCloseNotification}
                isForToast={true}
                handleOnOpenFeedback={this.handleOnLetUsKnow} />)}
        </div>;
    }
}

export default AppNotificationArea;
import * as React from 'react';
import classNames from 'classnames/bind';
import Icon from '../Icon/Icon';
import Utils from '../../services/Utils';
import { KeyCodes } from '../../../constants/Enums';
import TooltipableContainer from '../Tooltipable/Tooltipable.container';
const cx = classNames.bind(require('./DropdownMenu.module.scss'));

interface Props { t: any; theme: string; dropdownMenuOptions: Array<any>; dropdownMenuSide: string; menuButtonLabel: string;
    menuClassName?: string; buttonClassName?: string; menuItemClassName?: string; menuItemTextClassName?: string;
    menuItemIconClassName?: string; containerClassName?: string; dropdownOpenCallback?: any; dropdownCloseCallback?: any; 
    selectedValue?: string;} 
// dropdownMenuOptions format: [<Icon>, text, action, options]
interface State { menuIsVisible: boolean; focusedValue: string; }

export default class DropdownMenu extends React.Component<Props, State> {

    private componentId: string;
    private buttonRef: any;
    constructor(props: Props) {
        super(props);
        this.state = { menuIsVisible: false, focusedValue: this.getInitialFocusedValue(this.props.selectedValue) };
        this.buttonRef = React.createRef();
    }

    private getInitialFocusedValue = (selectedValue) => {
        if (selectedValue) {
            return selectedValue;
        }
        if (this.props.dropdownMenuOptions && this.props.dropdownMenuOptions.length !== 0) {
            return this.props.dropdownMenuOptions[0][1];
        }
        return null;
    }

    private handleClickOutside = (event) => {
        let ellipsisContainer = document.getElementById(this.componentId);    
        if (ellipsisContainer && ellipsisContainer !== event.target && !ellipsisContainer.contains(event.target as HTMLInputElement)) {    
            this.setState({menuIsVisible: false});
        }
    }   

    componentDidUpdate(prevProps, prevState) {
        if (this.props.selectedValue !== prevProps.selectedValue) {
            this.setState({focusedValue: this.getInitialFocusedValue(this.props.selectedValue)});
        }
        if(prevState.menuIsVisible !== this.state.menuIsVisible){
            if(this.state.menuIsVisible && this.props.dropdownOpenCallback){
                this.props.dropdownOpenCallback();
            } else if(this.props.dropdownCloseCallback){
                this.props.dropdownCloseCallback();
            }
        }
    }

    componentDidMount() {
        this.componentId = Utils.guid();
        document.addEventListener('mouseup', this.handleClickOutside);
    }  
    
    componentWillUnmount() {
        document.removeEventListener('mouseup', this.handleClickOutside);
    }

    private focusEllipsisButton () {
        this.buttonRef && this.buttonRef.current && this.buttonRef.current.focus();
    }

    private getNextOptionIndex = (previousOption, optionsLength, isDown) => {
        return (previousOption + optionsLength + (isDown ? 1 : -1)) % optionsLength;
    }

    render() {
        let dropDownIcon = this.props.children ? this.props.children : 
            <Icon id={'iconEllipsis-' + this.props.theme} className={cx('ellipsisIcon')}/>;
        let menuOptionButton = (menuOption, i) => 
            <button 
                disabled={menuOption[3]?.disabled}
                role='menuitem'
                ref={btn => (btn && menuOption[1] === this.state.focusedValue) && btn.focus()}
                key={i} className={`${cx('dropdownMenuOption')} ${this.props.menuItemClassName ? this.props.menuItemClassName : ''}`} onClick={() => {
                    menuOption[2](); 
                    this.setState({menuIsVisible: false});
                    this.focusEllipsisButton();
                }} onKeyDown={(event) => {
                    if (event.keyCode === KeyCodes.Tab){
                        event.preventDefault();
                        let nextOptionIndex = this.getNextOptionIndex(i, this.props.dropdownMenuOptions.length, event.shiftKey === false);
                        this.setState({focusedValue: this.props.dropdownMenuOptions[nextOptionIndex][1]});
                    }
                    if (event.keyCode === KeyCodes.Esc){
                        this.setState({menuIsVisible: false, focusedValue: this.props.selectedValue});
                        this.focusEllipsisButton();
                    }
                }}> 
                <div className={`${cx('dropdownMenuOptionIcon')} ${this.props.menuItemIconClassName ? this.props.menuItemIconClassName: ''}`}>{menuOption[0]}</div>
                <div className={`${cx('dropdownMenuOptionText')} ${this.props.menuItemTextClassName ? this.props.menuItemTextClassName: ''}`}>{menuOption[1]}</div>
            </button>;
        return (<div className={`${cx('ellipsisContainer')} ${this.props.containerClassName ? this.props.containerClassName: ''}`} id={this.componentId}>
            <button className={`${cx('ellipsisButton')} ${this.props.buttonClassName ? this.props.buttonClassName : ''}`} 
                onClick={() => this.setState({menuIsVisible: !this.state.menuIsVisible})}
                title={this.props.menuButtonLabel} 
                aria-expanded={this.state.menuIsVisible}
                aria-label={this.props.menuButtonLabel}
                ref={this.buttonRef}
                role='menu'
                aria-haspopup="true"
            >
                {dropDownIcon}
            </button>
            {(this.state.menuIsVisible) ? 
                (<div className={`${cx("dropdownMenu", this.props.dropdownMenuSide)} ${this.props.menuClassName ? this.props.menuClassName : ''}`}>
                    {this.props.dropdownMenuOptions.map((menuOption, i) => 
                        menuOption[3]?.tooltipable ?
                            <TooltipableContainer key={i} tooltip={menuOption[3]?.tooltipable.tooltip} className={menuOption[3]?.tooltipable.className} tooltipClassName={menuOption[3]?.tooltipable.tooltipClassName}>
                                {menuOptionButton(menuOption, i)}
                            </TooltipableContainer>
                            : menuOptionButton(menuOption, i)
                    )}
                </div>) : 
                ''} 
        </div>);
    }
}
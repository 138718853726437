import update from 'immutability-helper';
import {SELECT_ENTITY_TYPE, ADD_ENTITY, CLOSE_TSM_MODAL, PUT_TYPE, PUT_HIERARCHY, PUT_INSTANCES, PUT_TYPE_FULFILLED, 
        PUT_HIERARCHY_FULFILLED, PUT_INSTANCES_FULFILLED, CLEAR_PROGRESS, BULK_UPLOAD, 
        SEARCH_INSTANCES, SEARCH_INSTANCES_FULFILLED, GET_MODEL_TYPES, GET_MODEL_TYPES_FULFILLED, 
        GET_MODEL_HIERARCHIES, GET_MODEL_HIERARCHIES_FULFILLED, SELECT_ENVIRONMENT, SET_TENANT_ID, 
        SET_TSM_ENTITY_SORT, SET_TSM_SUBENTITY_SORT, EDIT_ENTITY, SELECT_ENTITY, SET_ACTIVE_PAGE, 
        EDIT_SUBENTITY, SELECT_SUB_ENTITY, ADD_SUBENTITY, DOWNLOAD_ENTITIES, DOWNLOAD_ENTITIES_FULLFILLED, 
        MIGRATE_TSM_VARIABLES_FULFILLED, SET_BULK_UPLOAD_INSTANCES_PROGRESS, PUT_INSTANCES_FINALIZING, 
        GET_MODEL_SETTINGS, GET_MODEL_SETTINGS_FULFILLED} from '../../../constants/ActionTypes.js';
import Utils from '../../services/Utils';
import { TSMEntityKinds, RequestStatus, RequestMethods, DownloadStatus } from '../../../constants/Enums';
import { SortOrder } from '../../components/SortableTable/SortableTable';

const defaultState = {
    selectedEntityType: TSMEntityKinds.Instances,
    selectedEntityId: null,
    selectedSubEntityIdx: null,
    progress: RequestStatus.Idle,
    isTsmModalVisible: false,
    model: {types: {}, hierarchies: {}, instances: {}},
    isLoadingEntityType: false,
    singleOrBulkUpload: 'single',
    instancesContinuationToken: null,
    searchText: null,
    errorCode: "",
    errorMessage: "",
    requestMethod: "",
    tsmEntitySortField: null,
    tsmEntitySortOrder: SortOrder.Unsorted,
    tsmSubEntitySortParentIdx: null,
    tsmSubEntitySortField: null,
    tsmSubEntitySortOrder: SortOrder.Unsorted,
    downloadStatus: DownloadStatus.NotStarted,
    bulkUploadInstancesProgress: 0,
    modelSettings: {name: '', timeSeriesIdProperties: [], defaultTypeId: ''}
};

function modelReducer(state, action) {
    if (typeof state === 'undefined') {
        return defaultState;
    }
    
    let payload;
    let requestMethod; // change the intialization place .the scope is same as it was previously for the request method  
    switch (action.type) {
        
        case SELECT_ENTITY_TYPE:
            let selectedEntityType = action.payload.selectedEntityType;
            return update(state, {selectedEntityType: {$set: selectedEntityType}, selectedEntityId: {$set: null}, 
                                    isTsmModalVisible: {$set: false}, progress: {$set: RequestStatus.Idle}, searchText: {$set: null}, 
                                    isLoadingEntityType: {$set: true}, tsmEntitySortField: {$set: null}, 
                                    tsmEntitySortOrder: {$set: SortOrder.Unsorted}, tsmSubEntitySortField: {$set: null}, 
                                    tsmSubEntitySortOrder: {$set: SortOrder.Unsorted}, tsmSubEntitySortParentIds: {$set: null},
                                    downloadStatus: {$set: DownloadStatus.NotStarted}});
        case SELECT_ENTITY:
            return update(state, {selectedEntityId: {$set: action.payload.entityId}});
        case SELECT_SUB_ENTITY:
            return update(state, {selectedSubEntityIdx: {$set: action.payload.subEntityIdx}});
        case EDIT_SUBENTITY:
            return update(state, {selectedSubEntityIdx: {$set: action.payload.subEntityIdx}, selectedEntityId: {$set: action.payload.entityId}, isTsmModalVisible: {$set: true}, progress: {$set: RequestStatus.Idle}});
        case ADD_SUBENTITY:
            return update(state, {selectedSubEntityIdx: {$set: -1}, selectedEntityId: {$set: action.payload.entityId}, isTsmModalVisible: {$set: true}, progress: {$set: RequestStatus.Idle}});
        case ADD_ENTITY:
            return update(state, {selectedEntityId: {$set: null}, singleOrBulkUpload: {$set: 'single'}, isTsmModalVisible: {$set: true}, progress: {$set: RequestStatus.Idle}});
        case BULK_UPLOAD:
            return update(state, {selectedEntityId: {$set: null}, singleOrBulkUpload: {$set: 'bulk'}, isTsmModalVisible: {$set: true}, progress: {$set: RequestStatus.Idle}});
        case EDIT_ENTITY:
            return update(state, {selectedEntityId: {$set: action.payload.entityId}, singleOrBulkUpload: {$set: 'single'}, isTsmModalVisible: {$set: true}, progress: {$set: RequestStatus.Idle}});
        case CLOSE_TSM_MODAL:
            return update(state, {isTsmModalVisible: {$set: false}, selectedEntityId: {$set: null}, selectedSubEntityIdx: {$set: null}, progress: {$set: RequestStatus.Idle}, singleOrBulkUpload: {$set: 'single'}});
        case CLEAR_PROGRESS:
            return update(state, {progress: {$set: RequestStatus.Idle}});
        case PUT_TYPE:
        case PUT_HIERARCHY:
            requestMethod = typeof(action.payload) === "object" && action.payload.method && action.payload.method === RequestMethods.Delete ? RequestMethods.Delete : RequestMethods.Put;
            return update(state, {progress: {$set: RequestStatus.Publishing}, requestMethod: {$set: requestMethod}});
        case PUT_INSTANCES:
            requestMethod = action.payload.method ? action.payload.method : (JSON.parse(action.payload.instances).put ? RequestMethods.Put : (JSON.parse(action.payload.instances).update ? RequestMethods.Update : RequestMethods.Delete));
            return update(state, {progress: {$set: RequestStatus.Publishing}, bulkUploadInstancesProgress: {$set: 0}, instancesContinuationToken: {$set: ''}, requestMethod: {$set: requestMethod}});
        case SEARCH_INSTANCES:
            let text = action.payload.searchText;
            if (state.searchText !== null && (state.searchText === text) && state.instancesContinuationToken !== '' && state.instancesContinuationToken !== 'END') {
                return update(state, {requestMethod: {$set: RequestMethods.Search}});
            } else {
                return update(state, {searchText: {$set: text}, model: {instances: {$set: {}}}, instancesContinuationToken: {$set: ''}, selectedEntityId: {$set: null}, 
                                        progress: {$set: state.progress !== RequestStatus.Partial_Success ? RequestStatus.Idle : state.progress}, requestMethod: {$set: RequestMethods.Search}, 
                                        isLoadingEntityType: {$set: true}, tsmEntitySortField: {$set: null}, tsmEntitySortOrder: {$set: SortOrder.Unsorted}, tsmSubEntitySortField: {$set: null}, 
                                        tsmSubEntitySortOrder: {$set: SortOrder.Unsorted}, tsmSubEntitySortParentIds: {$set: null}});
            }
        case SEARCH_INSTANCES_FULFILLED:
            payload = action.payload;
            if (payload.error) {
                return update(state, {progress: {$set: RequestStatus.Error}, errorCode: {$set: "ErrorTrace"}, errorMessage: {$set: payload.error}, isLoadingEntityType: {$set: false}});
            } else if (payload.instances) {
                let newInstances;
                newInstances = Object.assign({}, state.model.instances);
                if (payload.instances.hasOwnProperty('hits')) {
                    payload.instances.hits.forEach((i, idx) => {
                        let instance = i.highlights;
                        instance.highlightedTimeSeriesId = Utils.getHighlightedTimeSeriesId(i);
                        instance.timeSeriesId = i.timeSeriesId;
                        instance.timeSeriesIdKey = Utils.getInstanceKey(i);
                        let instanceFields = {};
                        i.highlights.instanceFieldNames.forEach ((fN, idx) => instanceFields[fN] = i.highlights.instanceFieldValues[idx] ? i.highlights.instanceFieldValues[idx] : "");
                        instance["instanceFields"] = instanceFields;
                        delete instance.instanceFieldNames;
                        delete instance.instanceFieldValues;
                        
                        newInstances[instance.timeSeriesIdKey] = instance;
                    });
                }
                return update(state, {model: {instances: {$set: newInstances}}, 
                                        instancesContinuationToken: {$set: payload.instances.hasOwnProperty('continuationToken') ? payload.instances.continuationToken : "END"}, 
                                        isLoadingEntityType: {$set: false}});
            } else {
                return update(state, {isLoadingEntityType: {$set: false}});
            }
        case PUT_TYPE_FULFILLED:
        case PUT_HIERARCHY_FULFILLED:
            payload = action.payload;
            if (payload.error) {
                return update(state, {progress: {$set: RequestStatus.Error}, errorCode: {$set: Utils.getErrorCode(payload.error)}, errorMessage: {$set: Utils.getErrorMessage(payload.error)}});
            } else {
                if (payload.put) {
                    let itemsWithError = payload.put.filter(obj => obj && obj.error);
                    if (itemsWithError.length === 0) {
                        return update(state, {progress: {$set: RequestStatus.Successful}, errorCode: {$set: ""}, errorMessage: {$set: ""}});
                    } else {
                        if (payload.put.length === 1) { // single
                            return update(state, {progress: {$set: RequestStatus.Unsuccessful}, errorCode: {$set: Utils.getErrorCode(payload.put[0].error)}, errorMessage: {$set: Utils.getErrorMessage(payload.put[0].error)}});
                        } else { // bulk
                            let errorMessagesCompiled = itemsWithError.map(item => Utils.getErrorMessage(item.error)).join('\n');
                            return update(state, {progress: {$set: RequestStatus.Partial_Success}, errorCode: {$set: 'BulkUploadPartialError'}, errorMessage: {$set: errorMessagesCompiled}});
                        }
                    }
                } else if (payload.delete) {
                    if (payload.delete[0] && payload.delete[0].code) {
                        return update(state, {progress: {$set: RequestStatus.Unsuccessful}, errorCode: {$set: Utils.getErrorCode(payload.delete[0])}, errorMessage: {$set: Utils.getErrorMessage(payload.delete[0])}});
                    } else {
                        return update(state, {progress: {$set: RequestStatus.Successful}, errorCode: {$set: ""}, errorMessage: {$set: ""}});
                    }
                }
            }
            break;
        case PUT_INSTANCES_FINALIZING:
            return update(state, {progress: {$set: RequestStatus.Finalizing}, bulkUploadInstancesProgress: {$set: 100}});
        case PUT_INSTANCES_FULFILLED:
            let result = action.payload.result;
            let method = action.payload.method;
            if ("error" in result) {
                return update(state, {progress: {$set: RequestStatus.Error}, errorCode: {$set: Utils.getErrorCode(result.error)}, errorMessage: {$set: Utils.getErrorMessage(result.error)}});
            } else {
                if (method === RequestMethods.Put) {
                    if (result.put[0] && result.put[0].error) {
                        if (result.put[0].error.code === "PartialSuccess") { /** Need to check for a specific code here as it is manually created for bulk upload*/
                            return update(state, {progress: {$set: RequestStatus.Partial_Success}, requestMethod: {$set: RequestMethods.Put}, 
                                                    errorCode: {$set: Utils.getErrorCode(result.put[0].error)}, errorMessage: {$set: Utils.getErrorMessage(result.put[0].error)}});
                        } else {
                            return update(state, {progress: {$set: RequestStatus.Unsuccessful}, requestMethod: {$set: RequestMethods.Put}, 
                                                    errorCode: {$set: Utils.getErrorCode(result.put[0].error)}, errorMessage: {$set: Utils.getErrorMessage(result.put[0].error)}});
                        }
                    } else {
                        return update(state, {progress: {$set: RequestStatus.Successful}, requestMethod: {$set: RequestMethods.Put}, errorCode: {$set: ""}, errorMessage: {$set: ""}});
                    }
                } else if (method === RequestMethods.Update) {
                    if (result.update[0] && result.update[0].error) {
                        if (result.update[0].error.code === "PartialSuccess") { /** Need to check for a specific code here as it is manually created for bulk upload*/
                            return update(state, {progress: {$set: RequestStatus.Partial_Success}, requestMethod: {$set: RequestMethods.Update}, 
                                                    errorCode: {$set: Utils.getErrorCode(result.update[0].error)}, errorMessage: {$set: Utils.getErrorMessage(result.update[0].error)}});
                        } else {
                            return update(state, {progress: {$set: RequestStatus.Unsuccessful}, requestMethod: {$set: RequestMethods.Update}, 
                                                    errorCode: {$set: Utils.getErrorCode(result.update[0].error)}, errorMessage: {$set: Utils.getErrorMessage(result.update[0].error)}});
                        }
                    } else {
                        return update(state, {progress: {$set: RequestStatus.Successful}, requestMethod: {$set: RequestMethods.Update}, errorCode: {$set: ""}, errorMessage: {$set: ""}});
                    }
                } else if (method === RequestMethods.Delete) {
                    if (result.delete && result.delete[0] && result.delete[0].code) {
                        return update(state, {progress: {$set: RequestStatus.Unsuccessful}, requestMethod: {$set: RequestMethods.Delete}, 
                                                errorCode: {$set: Utils.getErrorCode(result.delete[0])}, errorMessage: {$set: Utils.getErrorMessage(result.delete[0])}});
                    } else if (result.put && result.put[0] && result.put[0].error) { // deleting instance fields is sent with put method in request
                        return update(state, {progress: {$set: RequestStatus.Unsuccessful}, requestMethod: {$set: RequestMethods.Delete}, 
                            errorCode: {$set: Utils.getErrorCode(result.put[0].error)}, errorMessage: {$set: Utils.getErrorMessage(result.put[0].error)}});
                    } else {
                        return update(state, {progress: {$set: RequestStatus.Successful}, requestMethod: {$set: RequestMethods.Delete}, errorCode: {$set: ""}, errorMessage: {$set: ""}});
                    }
                }
            }
            break;
        case GET_MODEL_TYPES:
            if (state.isTsmModalVisible) {
                return state;
            }
            return update(state, {model: {types: {$set: {}}, isLoadingEntityType: {$set: true}}});
        case GET_MODEL_TYPES_FULFILLED:
            payload = action.payload;
            if (payload.error) {
                if (payload.isForSearch) {
                    return state;
                }
                return update(state, {progress: {$set: RequestStatus.Error}, errorCode: {$set: "ErrorTrace"}, errorMessage: {$set: payload.error}, isLoadingEntityType: {$set: false}});
            } else {
                let types = {};
                payload.types.forEach(t => types[t.id] = t);
                if (payload.isForSearch) {
                    return update(state, {model: {types: {$set: types}}});
                }
                return update(state, {model: {types: {$set: types}}, isLoadingEntityType: {$set: false}});
            }
        case GET_MODEL_HIERARCHIES:
            if (state.isTsmModalVisible) {
                return state;
            }
            return update(state, {model: {hierarchies: {$set: {}}, isLoadingEntityType: {$set: true}}});
        case GET_MODEL_HIERARCHIES_FULFILLED:
            payload = action.payload;
            if (payload.error) {
                if (payload.isForSearch) {
                    return state;
                }
                return update(state, {progress: {$set: RequestStatus.Error}, errorCode: {$set: "ErrorTrace"}, errorMessage: {$set: payload.error}, isLoadingEntityType: {$set: false}});
            } else {
                let hierarchies = {};
                payload.hierarchies.forEach(h => hierarchies[h.id] = h);
                if (payload.isForSearch) {
                    return update(state, {model: {hierarchies: {$set: hierarchies}}});
                }
                return update(state, {model: {hierarchies: {$set: hierarchies}}, isLoadingEntityType: {$set: false}});
            }
        case SELECT_ENVIRONMENT:
        case SET_ACTIVE_PAGE:
            return update(state, {selectedEntityType: {$set: TSMEntityKinds.Instances}, selectedEntityId: {$set: null}, 
                                    isLoadingEntityType: {$set: true}, isTsmModalVisible: {$set: false}, progress: {$set: RequestStatus.Idle}, 
                                    searchText: {$set: null}, tsmEntitySortField: {$set: null}, tsmEntitySortOrder: {$set: SortOrder.Unsorted}, 
                                    tsmSubEntitySortField: {$set: null}, tsmSubEntitySortOrder: {$set: SortOrder.Unsorted}, tsmSubEntitySortParentIds: {$set: null},
                                    downloadStatus: {$set: DownloadStatus.NotStarted}});
        case SET_TENANT_ID:
            return update(state, {model: {$set: defaultState.model}});
        case SET_TSM_ENTITY_SORT:
            return update(state, {tsmEntitySortField: {$set: action.payload.sortField}, tsmEntitySortOrder: {$set: action.payload.sortOrder}});
        case SET_TSM_SUBENTITY_SORT: 
            return update(state, {tsmSubEntitySortParentIdx: {$set: action.payload.entityIdx}, tsmSubEntitySortField: {$set: action.payload.sortField}, tsmSubEntitySortOrder: {$set: action.payload.sortOrder}});
        case DOWNLOAD_ENTITIES:
            if(action.payload.isForMigration) {
                return state;
            }
            return update(state, {downloadStatus: {$set: DownloadStatus.Downloading}});
        case DOWNLOAD_ENTITIES_FULLFILLED:
            if(action.payload.isForMigration) {
                return state;
            }
            if (action.payload.error) {
                return update(state, {progress: {$set: RequestStatus.Error}, errorCode: {$set: "DownloadError"}});
            } else {
                Utils.downloadJSON(action.payload.json, action.payload.fileName);
                return update(state, {downloadStatus: {$set: DownloadStatus.Finished}});
            }
        case MIGRATE_TSM_VARIABLES_FULFILLED:
            return update(state, {progress: {$set: RequestStatus.Idle}});
        case SET_BULK_UPLOAD_INSTANCES_PROGRESS:
            return update(state, {bulkUploadInstancesProgress: {$set: action.payload}});
        case GET_MODEL_SETTINGS:
            return update(state, {modelSettings: {$set: {name: '', timeSeriesIdProperties: [], defaultTypeId: ''}}});
        case GET_MODEL_SETTINGS_FULFILLED:
            if (action.payload.error) {
                return state;
            } else {
                return update(state, {modelSettings: {$set: action.payload.modelSettings}});
            };
        default: 
            return state;
    }
}

export const getModel = state => state.model;
export const getTypes = state => {
    let keyedTypes = getModel(state).model.types;
    return Object.keys(keyedTypes).map(tid => keyedTypes[tid]);
};
export const getHierarchies = state => {
    let keyedTypes = getModel(state).model.hierarchies;
    return Object.keys(keyedTypes).map(hid => keyedTypes[hid]);
};
export const getSelectedEntityType = state => getModel(state).selectedEntityType;
export const getTSMEntitySortField = state => getModel(state).tsmEntitySortField;
export const getTSMEntitySortOrder = state => getModel(state).tsmEntitySortOrder;
export const getTSMSubEntitySortField = state => getModel(state).tsmSubEntitySortField;
export const getTSMSubEntitySortOrder = state => getModel(state).tsmSubEntitySortOrder;
export const getTSMSubEntitySortParentIdx = state => getModel(state).tsmSubEntitySortParentIdx;
export const getSelectedEntities = state => {
    let model = getModel(state).model;
    let selectedEntityType = getSelectedEntityType(state);

    let result = Object.keys(model[selectedEntityType]).map(eid => model[selectedEntityType][eid]);

    if (result.length > 0) {
        let sortField = getModel(state).tsmEntitySortField;
        let isDescending = getModel(state).tsmEntitySortOrder === SortOrder.Descending;
        let subSortField = getModel(state).tsmSubEntitySortField;
        let subIsDescending = getModel(state).tsmSubEntitySortOrder === SortOrder.Descending;
        let subSortParentIdx = getModel(state).tsmSubEntitySortParentIdx;

        let compareForSort = (a, b, isDescending) => {
            if (a === b) {
                return 0;
            }
            if (a === null) {
                return (isDescending ? 1 : -1);
            }
            if (b === null) {
                return (isDescending ? -1 : 1);
            }
            return ((a < b) === isDescending) ? 1 : -1;
        }

        if (sortField) {
            result.sort((a, b) => {
                let valueA = Utils.getTSMProperty(a, sortField);
                let valueB = Utils.getTSMProperty(b, sortField);
                return compareForSort(valueA, valueB, isDescending);
            });
        }
        if (subSortField && result.length >= subSortParentIdx) {
            let subEntityArray;
            if(selectedEntityType === TSMEntityKinds.Types) {
                subEntityArray = Object.keys(result[subSortParentIdx].variables).map(vName => {return {'name': vName, ...result[subSortParentIdx].variables[vName]}});
            } else if(selectedEntityType === TSMEntityKinds.Instances) {
                subEntityArray = Object.keys(result[subSortParentIdx].instanceFields).map(iF => {return {'instanceFieldName': iF, 'instanceFieldValue': result[subSortParentIdx].instanceFields[iF]}});
            }
            if (subSortField) {
                subEntityArray.sort((a,b) => {
                    let valueA = a[subSortField] ? a[subSortField] : null;
                    let valueB = b[subSortField] ? b[subSortField] : null;
                    return compareForSort(valueA, valueB, subIsDescending);
                });
                if (selectedEntityType === TSMEntityKinds.Types) {
                    result[subSortParentIdx].variables = Utils.createKeyedObjectFromArray(subEntityArray, 'name');
                } else if (selectedEntityType === TSMEntityKinds.Instances) {
                    result[subSortParentIdx].instanceFields = subEntityArray.reduce((p, c) => {p[c['instanceFieldName']] = c['instanceFieldValue']; return p; }, {});
                }
            }
        }
    }

    return result;
};
export const getSelectedEntity = state => {
    let selectedEntityId = getModel(state).selectedEntityId;
    let selectedEntityType = getSelectedEntityType(state);
    if (selectedEntityId) {
        return getModel(state).model[selectedEntityType][selectedEntityId];
    } else {
        return null;
    }
};
export const getSelectedSubEntityIdx = state => getModel(state).selectedSubEntityIdx;
export const getSelectedEntityId = state => getModel(state).selectedEntityId;
export const isTsmModalVisible = state => getModel(state).isTsmModalVisible;
export const getProgress = state => getModel(state).progress;
export const getErrorCode = state => getModel(state).errorCode;
export const getErrorMessage = state => getModel(state).errorMessage;
export const getRequestMethod = state => getModel(state).requestMethod;
export const getAddType = state => getModel(state).singleOrBulkUpload;
export const getEntityTypeLoadingStatus = state => getModel(state).isLoadingEntityType;
export const getInstancesContinuationToken = state => getModel(state).instancesContinuationToken;
export const getSearchText = state => getModel(state).searchText;
export const getDownloadStatus = state => getModel(state).downloadStatus;
export const getBulkUploadInstancesProgress = state => getModel(state).bulkUploadInstancesProgress;
export const getTimeSeriesIdProperties = state => getModel(state).modelSettings.timeSeriesIdProperties;

export default modelReducer;
import React from 'react';
import ColorPickerContainer from '../ColorPicker/ColorPicker.container';
import InfoBanner from '../InfoBanner/InfoBanner';
import { ActionButton, IconButton } from '@fluentui/react/lib/Button';
import { IIconProps } from 'office-ui-fabric-react/lib/Icon';
import { AppTheme } from '../../../constants/Constants';
import '../../../resources/styles/global.scss';
import classNames from 'classnames/bind';
const cx = classNames.bind(require('./HorizontalMarkerEditor.module.scss'));

const getAddIcon = (theme: string): IIconProps => theme === AppTheme.Light 
    ? { iconName: 'addLight' }
    : { iconName: 'addDark' };

const getDeleteIcon = (theme: string): IIconProps => theme === AppTheme.Light 
    ? { iconName: 'closeLight' }
    : { iconName: 'closeDark' };

interface IHorizontalMarker {
    value: number | string;
    color: string;
}

interface Props {
    t: any;
    theme: string;
    markers?: IHorizontalMarker[];
    warning?: string;
    maxHorizontalMarkers?: number;
    addEmptyMarker: () => void;
    updateMarker: (markerIndex: number, value: number | string, color: string) => void;
    removeMarker: (markerIndex: number) => void;
}

export default function HorizontalMarkerEditor({ 
    t, 
    theme, 
    markers = [], 
    warning = null,
    maxHorizontalMarkers = 5,
    addEmptyMarker,
    updateMarker,
    removeMarker
}: Props) {
    return (<div className={cx('horizontalMarkers')}>
        { markers.length > 0 && 
            <div className={cx('header')}>
                <span className={cx('columnValue')}>{t('settingsModal.value')}</span>
                <span className={cx('columnColor')}>{t('settingsModal.color')}</span>
            </div> }
        { markers.map((hm, index) => <div key={`marker-${index}`} className={cx('markerRow')}>
            <input 
                type="number" 
                className={cx('columnValue')} 
                value={hm.value} 
                onChange={(event) => updateMarker(index, Number(event.target.value), hm.color)} />
            <ColorPickerContainer 
                className={cx('colorPicker', 'columnColor')}
                numberOfColors={15}
                defaultColor={hm.color || '#008272'}
                onSelect={(color: string) => updateMarker(index, hm.value, color)} />
            <IconButton 
                iconProps={getDeleteIcon(theme)} 
                className={cx('noHover')}
                onClick={() => removeMarker(index)} />
        </div>) }
        <ActionButton 
            className={cx('addMarkerButton')}
            iconProps={getAddIcon(theme)} 
            onClick={addEmptyMarker} 
            disabled={markers.length === maxHorizontalMarkers}>
            {t('settingsModal.newReferenceLine')}
        </ActionButton>
        { warning && <InfoBanner>{warning}</InfoBanner> }
    </div>);
}
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import BrushedRegionStatistics from './BrushedRegionStatistics';
import { HIDE_BRUSHED_REGION_STATISTICS } from '../../../constants/ActionTypes';
import { getActiveQueries, getBrushedRegionStatisticsState, getTimezone } from '../../state/reducers/AnalyticsReducer';

const mapStateToProps = state => {
    return { 
        queries: getActiveQueries(state),
        brushedRegionStatisticsState: getBrushedRegionStatisticsState(state),
        timezone: getTimezone(state)
    };
};

const mapDispatchToProps = dispatch => ({ 
    hideBrushedRegionStatistics: () => {
        dispatch({type: HIDE_BRUSHED_REGION_STATISTICS})
    }
});

const BrushedRegionStatisticsContainer = translate()(connect(mapStateToProps, mapDispatchToProps)(BrushedRegionStatistics));

export default BrushedRegionStatisticsContainer;

import * as React from 'react';
import ModalContainer from '../Modal/Modal.container';
import Placeholder from '../Placeholder/Placeholder';
import cx from './BrushedRegionStatistics.module.scss';
import TimeSeriesQuery from '../../models/TimeSeriesQuery';
import Utils from '../../services/Utils';
import { BrushedRegionStatisticsState, TimeSeriesQueryStatistics } from '../../models/BrushedRegionStatisticsState';
import { CaretDirections, ChartDataTypes } from '../../../constants/Enums';

interface Props { t: any; queries: Array<any>; brushedRegionStatisticsState: BrushedRegionStatisticsState; 
                  hideBrushedRegionStatistics: any; timezone: string} 
interface State { }

export default class BrushedRegionStatistics extends React.Component<Props, State> {

    getStatisticsTable = (tsq: TimeSeriesQuery, statistics: TimeSeriesQueryStatistics) => {
        let isAggregateVariable = Utils.isAggregateVariable(tsq);
        if(isAggregateVariable){
            return <h6 tabIndex={0} className={cx['noStats']}>{this.props.t('noStatsForAggs')}</h6>;
        }
        else if(!statistics){
            return <h6 tabIndex={0} className={cx['statError']}>{this.props.t('statsUnavailable')}</h6>;
        }
        else {
            return <div>
                        {tsq.noData() && <h6 tabIndex={0} className={cx['noStats']}>{this.props.t('noTsData')}</h6>}
                        {(tsq.error && tsq.error.message) && <h6 tabIndex={0} className={cx['statError']}>{tsq.error.message}</h6>}
                        {!tsq.noData() && 
                            (statistics.errorMessage 
                                ? <h5 tabIndex={0} className={cx['statError']}>{statistics.errorMessage}</h5> 
                                : <div className={cx['statTable']}>
                                    {Array.from(statistics.values).map(([statName, statValue], idx) => {
                                        return (tsq.dataType !== ChartDataTypes.Categorical || statValue !== 0) && 
                                                <div key={idx} tabIndex={0} aria-label={`${this.props.t(statName)}, ${statValue}`} className={cx['stat']}>
                                                    <div className={cx['prop']}>
                                                        {tsq.dataType === ChartDataTypes.Categorical && <div className={cx['catColor']} style={{backgroundColor: tsq.valueMapping[statName].color}}></div>}
                                                        <div>{this.props.t(statName)}</div>
                                                    </div>
                                                    <div className={cx['val']}>{Utils.formatNumberToReasonablePrecision(statValue)}</div>
                                                </div>                                        
                                        })
                                    }
                                </div>)
                        }
                    </div>
        }
    }

    getBorderColor = (tsq: TimeSeriesQuery) => {
        if(tsq.dataType === ChartDataTypes.Categorical){
            let categories = Object.keys(tsq.valueMapping).sort();
            let linearGradient = `linear-gradient(${categories.map((cat, i) => `${tsq.valueMapping[cat].color} ${i*100/categories.length}% ${(i+1)*100/categories.length}%`).join(',')}) 1`;
            return {borderImage: linearGradient};
        }
        else{
            return {borderColor: tsq.color};
        }
    }

    render() {        
        return <ModalContainer 
                contentClassName={cx['statsModalContent']} wrapperClassName={cx['statsModalWrapper']} 
                title={this.props.t('statsForThisRegion')} onClose={this.props.hideBrushedRegionStatistics}
                pageX={this.props.brushedRegionStatisticsState.x} pageY={this.props.brushedRegionStatisticsState.y}
                caret={CaretDirections.UpperLeft} modalDimensions={{height: 400, width: 400}}
                titleClassName={cx['statsTitle']} className={cx['statsModal']}>
                <div className={cx['statsSubtitle']}>
                    <div>{`${Utils.formatDateTime(this.props.brushedRegionStatisticsState.from, this.props.timezone)} - ${Utils.formatDateTime(this.props.brushedRegionStatisticsState.to, this.props.timezone)}`}</div>
                    <div>{Utils.formatTimeRange(this.props.brushedRegionStatisticsState.from, this.props.brushedRegionStatisticsState.to)}</div>
                </div>
                <div className={cx['statsContainer']}>
                    {this.props.brushedRegionStatisticsState.isLoading 
                        ? <Placeholder>{this.props.t('loadingStats')}</Placeholder> 
                        : (this.props.queries as Array<TimeSeriesQuery>).map((tsq, idx) =>
                            {
                              if(tsq.hidden){
                                  return null;
                              }
                              else{
                                let statistics = this.props.brushedRegionStatisticsState.data[idx];
                                return <div key={idx} className={cx['statBlock']} style={this.getBorderColor(tsq)}>
                                            <div className={cx['seriesName']}>
                                                <div className={cx['name']}>
                                                    <h4 tabIndex={0} className={cx['id']}>{Utils.getFormattedHtml(Utils.getTSQProperty(tsq, 'alias'), {monoClassName: cx['_base_mono']})}</h4>
                                                    <h5 tabIndex={0} className={cx['variable']}>{Utils.getTSQProperty(tsq, 'Variable')}</h5>
                                                </div>
                                            </div>
                                            {this.getStatisticsTable(tsq, statistics)}
                                        </div>
                              }
                        })   
                    }
                </div>
            </ModalContainer>
    }
}
import { AsyncModalState } from "./Interfaces";

export class BrushedRegionStatisticsState implements AsyncModalState{
    public isLoading: boolean;
    public isVisible: boolean;
    public x: number;
    public y: number;
    public from: Date;
    public to: Date;
    public data: Array<TimeSeriesQueryStatistics> = [];  // data will be the same length as queries, and match each position
    
    constructor(isLoading = false, isVisible = false, x = 0, y = 0, from = new Date(), to = new Date()){
        this.isLoading = isLoading;
        this.isVisible = isVisible;
        this.x = x;
        this.y = y;
        this.from = from;
        this.to = to;
    }
}

export interface TimeSeriesQueryStatistics {
    values?: Map<string, number>;
    errorMessage?: string
}
import { GET_QUERYRESULTS_FULFILLED, GET_QUERYRESULTS, GET_EVENTS, GET_EVENTS_FULFILLED, GET_AVAILABILITY, GET_MODEL, 
    GET_MODEL_FULFILLED, ADD_AGGREGATESQUERY, REMOVE_AGGREGATESQUERY, CLEAR_EVENTS,  SHOW_HIDE_TIMESERIESQUERY, ZOOM, GET_AVAILABILITY_FULFILLED, 
    TOGGLE_COMPACT_AVAILABILITY, GET_METADATA_FULFILLED, SET_SEARCHSPAN, SET_TIMEZONE, REMOVE_TIMESERIESQUERY, REMOVE_ALL_TIMESERIESQUERIES,
    ADD_TIMESERIESQUERY, SET_MAINTAIN_INTERVAL, SELECT_HIERARCHY, GET_MODEL_ERROR, GET_ARTIFACTS_FULFILLED, 
    BULK_PARSE_ANALYTICS_STATE, SET_SAVED_QUERY_NAME, CLEAR_SAVED_QUERY_NAME, GET_EVENTS_ERROR, CLEAR_DELETE_QUERY, DELETE_QUERY_FULFILLED,
    DELETE_QUERY_FAILED, DELETE_QUERY, SET_ADDITIONAL_FIELD_VISIBILITY, SET_TSQ_SORT, SET_ADDITIONAL_FIELD_AS_ONLY_VISIBLE, 
    SET_AUTO_REFRESH, SET_MARKERS, ADD_SWIMLANE_HORIZONTAL_MARKER, UPDATE_SWIMLANE_HORIZONTAL_MARKER, REMOVE_SWIMLANE_HORIZONTAL_MARKER,
    SET_CHART_TYPE, GET_INSTANCES_FULFILLED, SELECT_ENVIRONMENT, HIDE_ALL_TIMESERIESQUERIES, RESET_SWIMLANE_OPTIONS,
    SET_FOCUS_AND_STICKY_TRIGGERS, STICKY_TIMESERIESQUERY, UNSTICKY_TIMESERIESQUERIES, SET_LOADING_AGGREGATES_PROGRESS, SET_LOADING_EVENTS_PROGRESS,
    SET_SCATTER_MEASURES, SET_MODAL_OPTIONS, CLOSE_MODAL, SET_LINECHART_STACK_STATE, REGISTER_GQR_CANCEL_HANDLER, 
    SET_ACTIVE_VISUALIZATION_REFERENCE, SET_QUERY_FIELD, CLEAR_PREVIOUS_SESSION_STATE, REFRESH_PREVIOUS_SESSION_STATE, STOP_AVAILABILITY_LONG_POLL, 
    START_AVAILABILITY_LONG_POLL, TOGGLE_FORCE_COLD, SAVE_QUERY_FULFILLED, SET_ISSAVEOPENSHAREVISIBLE, SAVE_QUERY, 
    SET_SAVED_QUERY_FILTER, GET_BRUSHED_REGION_STATISTICS, HIDE_BRUSHED_REGION_STATISTICS, GET_BRUSHED_REGION_STATISTICS_FULFILLED, 
    SET_FOCUS_ON_ADD_MARKER_BUTTON_TRIGGER, SET_TSID_FOR_LOOKUP, SWAP_IN_TSQ, DUPLICATE_TIMESERIESQUERY, MIGRATE_TSM_VARIABLES_FULFILLED,
    SET_AGGREGATESQUERY, SET_VISIBLE_TSQ_FIELDS_IN_LOCAL_STORAGE, SET_ALL_ADDITIONAL_FIELD_VISIBILITY, SET_IS_GET_SERIES_ENABLED, UPDATE_JUMBOTRON_STATE, 
    SET_CHART_SETTINGS_STATE, SET_SHOULD_STICKY, SET_AUTO_REFRESH_TIME_SPAN_MILLIS, SET_AUTO_REFRESH_CYCLE_MILLIS, SET_SWIMLANE_FIELD, SET_SERIES_LABELS_ENABLED } from '../../../constants/ActionTypes.js';
import update from 'immutability-helper';
import Utils from '../../services/Utils';
import { getSelectedEnvironmentIsLts, getSelectedEnvironment, getEnvironments } from './AppSettingsReducer';
import TimeSeriesQuery from '../../models/TimeSeriesQuery';
import { ChartTypes, QueryTypes, GetAvailabilityReason, TSMEntityKinds, ClientDataFormat, ChartDataTypes, TSXMigrationStatus, LocalStorageKeys, CollapseMode, CollapsableComponents, ChartSettingsTypes } from '../../../constants/Enums';
import { BrushedRegionStatisticsState, TimeSeriesQueryStatistics } from '../../models/BrushedRegionStatisticsState';
import { StateParserService, StateParseResult, AnalyticsState } from '../../services/StateParserService';
import { autoRefreshCycleMillis_Default, autoRefreshTimeSpanMillis_Default, defaultSwimlaneOptions } from '../../../constants/Constants';

const defaultState = Object.assign(new AnalyticsState(), {
    // Overwrite AnalyticsState defaults
    previousSessionState: localStorage.getItem('tsiPageState'),
    searchSpan: {from: (new Date()).valueOf(), to: (new Date()).valueOf(), bucketSize: '1h'},
    selectedHierarchyId: localStorage.getItem(LocalStorageKeys.SelectedHierarchyId),

    // Add to AnalyticsState properties
    tsqSortField: null,
    tsqSortDescending: true,
    loadingAggregatesProgress: 0,
    model: {types: {}, hierarchies: {}, instances: {}},
    isModelLoading: true,
    events: null,
    isLoadingEvents: false,
    loadingEventsProgress: 0,
    eventsError: null,
    availabilityDistribution: null,
    isAvailabilityDistributionLoading: true,
    isLongPollingAvailability: true,
    availabilityIsCompact: false,
    metadata: [],
    timezone: 'Local',
    maintainedIntervalSize: null,
    savedQueries: {},
    saveQueryMessage: null,
    isSaveOpenShareVisible: false,
    sid: null,
    queryName: null,
    deletedQueryStatus: null,
    validBucketSizes: [],
    availabilityRerenderTrigger: false,  // flip this value to rerender the avaialability chart explicitly, like this: availabilityRerenderTrigger: {$set: !state.availabilityRerenderTrigger}
    autoRefreshCycleMillis: autoRefreshCycleMillis_Default,
    autoRefreshTimeSpanMillis: autoRefreshTimeSpanMillis_Default,
    valueElementFocusTrigger: () => {},
    valueElementUnfocusTrigger: () => {},
    valueElementStickyTrigger: () => {},
    stickiedTSQ: null,
    modalOptions: {},
    exploreEventsPayload: null,
    activeVisualizationReference: null,
    cancelGQRHandler: null,
    warmStoreRange: null,
    savedQueryFilter: '',
    brushedRegionStatisticsState: new BrushedRegionStatisticsState(),
    focusAddMarkerButtonTrigger: null,
    tsidForLookup: undefined,
    isMoreRawEventsAvailable: false,
    isRawEventsSetIncomplete: false,
    visibleTSQFieldsFromLocalStorage: JSON.parse(localStorage.getItem(LocalStorageKeys.VisibleTSQFieldsInWell)) || {}, // keyed with environment fqdn
    chartSettingsState: {isOpen: false, type: ChartSettingsTypes.Basics, activeTSQI: 0, activeSwimlane: '1'},
    isJumbotronActive: false,
    jumbotronState: {[CollapsableComponents.well]: false, [CollapsableComponents.tagExp]: false},
    shouldSticky: false,
    isSeriesLabelsEnabled: !!localStorage.getItem(LocalStorageKeys.SeriesLabelsVisible),
});

function analyticsReducer(state, action) {
if (typeof state === 'undefined') {
   return defaultState;  // default state
}
//intialized here
let envId;
switch (action.type) {
    case GET_QUERYRESULTS:
        return update(state, {
                            isLoadingAggregates: {$set: true}, loadingAggregatesProgress: {$set: 3},
                            events: {$set: null},
                            unzoomStack: (action.payload && action.payload.unsetUnzoomStack ? {$set: []} : {$set: state.unzoomStack})
                        }); 
    case REGISTER_GQR_CANCEL_HANDLER:
        return update(state, {cancelGQRHandler: {$set: action.payload}});
    case SET_LOADING_AGGREGATES_PROGRESS:
        return update(state, {loadingAggregatesProgress: {$set: Math.max(action.payload, 3)}});
    case GET_EVENTS:
        return update(state, {
            isMoreRawEventsAvailable: {$set: !(action.payload.forceIsMoreRawEventsAvailableToBeFalse)}, // allow events table to force this property to false to allow "Show all events" to only be clicked once
            exploreEventsPayload: {$set: action.payload.predicateObject}, isLoadingEvents: {$set: true}, loadingEventsProgress: {$set: 3}, eventsError: {$set: null}, isAutoRefreshEnabled: {$set: false}});
    case SET_LOADING_EVENTS_PROGRESS:
        return update(state, {loadingEventsProgress: {$set: action.payload}});
    case CLEAR_EVENTS:
        return update(state, {isMoreRawEventsAvailable: {$set: true}, events: {$set: null}, isLoadingEvents: {$set: false}, loadingEventsProgress: {$set: 0}});
    case GET_AVAILABILITY:
        if([GetAvailabilityReason.PageLoadOrEnvironmentChange, GetAvailabilityReason.OpenSavedQueryOrShortlink].includes(action.payload.getAvailabilityReason)){
            let newTSQFields = (action.payload && action.payload.unsetQueries) ? Object.assign({}, defaultState.timeSeriesQueriesFields) : 
                state.timeSeriesQueriesFields;
            Utils.usedColors = {};
            return update(state, {availabilityDistribution: {$set: null},
                                    isAvailabilityDistributionLoading: {$set: true}, 
                                    isModelLoading: {$set: true},
                                    availabilityIsCompact: {$set: false},
                                    aggregatesQueries: {$set: []}, timeSeriesQueries: {$set: []}, 
                                        events: {$set: null}, timeSeriesQueriesFields: {$set: newTSQFields}});
        }
        else{
            return update(state, {isModelLoading: {$set: false}});
        }
    case SET_ADDITIONAL_FIELD_VISIBILITY: 
        return update(state, {timeSeriesQueriesFields: {[action.payload.field]: {$set: action.payload.value}}});
    case SET_ADDITIONAL_FIELD_AS_ONLY_VISIBLE:
        let modifiedTSQFields = {};
        Object.keys(state.timeSeriesQueriesFields).forEach((tsqField) => {
            modifiedTSQFields[tsqField] = (tsqField === action.payload);
        });
        return update(state, {timeSeriesQueriesFields: {$set: modifiedTSQFields}});
    case SET_ALL_ADDITIONAL_FIELD_VISIBILITY:
        modifiedTSQFields = {};
        Object.keys(state.timeSeriesQueriesFields).forEach((tsqField) => {
            modifiedTSQFields[tsqField] = action.payload;
        });
        return update(state, {timeSeriesQueriesFields: {$set: modifiedTSQFields}});
    case SET_VISIBLE_TSQ_FIELDS_IN_LOCAL_STORAGE:
        envId = action.payload;
        let newVisibleTSQFieldsInLocalStorage = {...state.visibleTSQFieldsFromLocalStorage};
        newVisibleTSQFieldsInLocalStorage[envId] = Object.keys(state.timeSeriesQueriesFields).filter(f => state.timeSeriesQueriesFields[f]);
        localStorage.setItem(LocalStorageKeys.VisibleTSQFieldsInWell, JSON.stringify(newVisibleTSQFieldsInLocalStorage));
        return update(state, {visibleTSQFieldsFromLocalStorage: {[envId]: {$set: newVisibleTSQFieldsInLocalStorage[envId]}}});
    case SET_TSQ_SORT: 
        return update(state, {tsqSortField: {$set: action.payload.sortField}, tsqSortDescending: {$set: action.payload.isDescending}, timeSeriesQueries: {$set: [...state.timeSeriesQueries]}});
    case GET_QUERYRESULTS_FULFILLED:
        envId = action.payload.env.id;
        let result = action.payload.result;
        let indicesOfRawTsqs = action.payload.indicesOfRawTsqs || [];
        let newQs = action.payload.env.isLts ? [].concat(state.timeSeriesQueries) : [].concat(state.aggregatesQueries);
        newQs.forEach((ts, i) => {
            if (result[i].hasOwnProperty('__tsiError__')) {
                ts.error = result[i]['__tsiError__'].error;
            }
            else if (!result[i].hasOwnProperty('__ignoreResult__')) {
                ts.error = null;
            }
            if (action.payload.env.isLts) {
                // make necessary changes when tsq result is changing from aggregate data to raw or vice versa
                if (indicesOfRawTsqs.includes(i) && state.chartType === ChartTypes.Linechart) {
                    TimeSeriesQuery.applyChangesForRawSeries(ts);
                } else {
                    if (ts.isRawData) {
                        TimeSeriesQuery.applyChangesForAggregateSeries(ts, state.searchSpan.bucketSize);
                    }
                }
            }
        });
        let transformedResult = action.payload.env.isLts ? Utils.tsiClient.ux.transformTsqResultsForVisualization(result, state.timeSeriesQueries) : Utils.tsiClient.ux.transformAggregatesForVisualization(result, state.aggregatesQueries);
        let tsqFields = state.timeSeriesQueriesFields;
        newQs.forEach((ts, i) => {
            if(!('__ignoreResult__' in result[i])){
                ts.data = transformedResult[i];
                if (action.payload.env.isLts) {
                    if (!tsqFields.hasOwnProperty("timeSeriesId") && ts.instanceObject.timeSeriesId && 
                            ts.instanceObject.timeSeriesId.length > 0) {
                        tsqFields["timeSeriesId"] = true;
                    }
                    if (!tsqFields.hasOwnProperty("timeSeriesName") && ts.instanceObject.name) {
                        tsqFields = {timeSeriesId: tsqFields["timeSeriesId"], timeSeriesName: true, ...tsqFields};
                    }
                    Object.keys(ts.additionalFields).forEach(k => {
                        if (!tsqFields.hasOwnProperty(k)) {
                            tsqFields[k] = true;
                        }
                    });
                    if (state.visibleTSQFieldsFromLocalStorage[envId]) { //override field visibility from storage if exist
                        Object.keys(tsqFields).forEach(f => {
                            tsqFields[f] = state.visibleTSQFieldsFromLocalStorage[envId].includes(f);
                        });
                    }
                }
            }
        });
        newQs = Utils.addAggKeys(newQs, transformedResult);
        let stickiedTSQ = state.stickiedTSQ;
        newQs.forEach((q) => {
            if (q.noData() && state.stickiedTSQ === q.data.aggKey) {
                stickiedTSQ = null;
            }
            q.isJustAdded = false;
        })

        if (action.payload.env.isLts) {
            return update(state, {isLoadingAggregates: {$set: false}, loadingAggregatesProgress: {$set: 100},
                                    timeSeriesQueries: {$set: newQs}, timeSeriesQueriesFields: {$set: tsqFields}, maintainedIntervalSize: {$set: null}, 
                                    isLoadingSavedTsq: {$set: false}, stickiedTSQ: {$set: stickiedTSQ}, cancelGQRHandler: {$set: null}
                                });
        }
        else {
            return update(state, {isLoadingAggregates: {$set: false}, loadingAggregatesProgress: {$set: 100}, aggregatesQueries: {$set: newQs}, maintainedIntervalSize: {$set: null}, isLoadingSavedTsq: {$set: false}});
        }
    case SELECT_HIERARCHY: 
        localStorage.setItem(LocalStorageKeys.SelectedHierarchyId, action.payload);
        return update(state, {selectedHierarchyId: {$set: action.payload}});
    case GET_MODEL:
        return update(state, {model: {$set: defaultState.model}, isModelLoading: {$set: true}});
    case GET_MODEL_FULFILLED:
        let keyedModel = {};
        keyedModel[TSMEntityKinds.Instances] = action.payload.instances.reduce((p, c) => {p[Utils.getInstanceKey(c)] = c; return p; }, {});
        keyedModel[TSMEntityKinds.Hierarchies] = Utils.createKeyedObjectFromArray(action.payload.hierarchies, 'id');
        keyedModel[TSMEntityKinds.Types] = Utils.createKeyedObjectFromArray(action.payload.types, 'id');
        return update(state, {model: {$set: keyedModel}, isModelLoading: {$set: false}});
    case GET_MODEL_ERROR: {
        return update(state, {model: {$set: defaultState.model}, isModelLoading: {$set: false}});
    }
    case GET_EVENTS_FULFILLED:
        return update(state, {
            isMoreRawEventsAvailable: {$set: action.payload.moreEventsAvailable && state.isMoreRawEventsAvailable}, // this property can be forced false by EventsTable, since the "Show all events" button can only be clicked once
            isRawEventsSetIncomplete: {$set: !state.isMoreRawEventsAvailable && action.payload.moreEventsAvailable}, // if more events are available according to the API, but we already clicked show all, the set is incomplete
            events: {$set: action.payload.events}, isLoadingEvents: {$set: false}, loadingEventsProgress: {$set: 100}, eventsError: {$set: null}});
    case GET_EVENTS_ERROR:
        return update(state, {events: {$set: []}, isLoadingEvents: {$set: false}, eventsError: {$set: action.payload}});
    case ADD_AGGREGATESQUERY:
        return update(state, {aggregatesQueries: {$push: [action.payload]}, availabilityIsCompact: {$set: true}});
    case REMOVE_AGGREGATESQUERY:
        return update(state, {aggregatesQueries: {$splice: [[action.payload, 1]]}});
    case SET_AGGREGATESQUERY:
        return update(state, {aggregatesQueries: {[action.payload.idx]: {$set: action.payload.aQ}}});
    case ADD_TIMESERIESQUERY:
        let sortField = state.tsqSortField === 'swimLane' ? null : state.tsqSortField;
        return update(state, {timeSeriesQueries: {$push: [action.payload]}, availabilityIsCompact: {$set: true}, tsqSortField: {$set: sortField}});
    case DUPLICATE_TIMESERIESQUERY:
        let duplicateTSQ = TimeSeriesQuery.fromObject(action.payload);
        duplicateTSQ.hidden = false;
        duplicateTSQ.queryID = Utils.guid();
        duplicateTSQ.swimLane = Utils.getAvailableSwimLane(state.timeSeriesQueries);
        let duplicateIndex = state.timeSeriesQueries.findIndex(tsqCurr => tsqCurr === action.payload);
        let tsqsWithDuplicate = [];
        for (var i = 0; i < state.timeSeriesQueries.length; i++) {
            tsqsWithDuplicate.push(state.timeSeriesQueries[i])
            if (i === duplicateIndex) {
                tsqsWithDuplicate.push(duplicateTSQ);
            }
        }
        let updatedSortField = state.tsqSortField === 'swimLane' ? null : state.tsqSortField;
        return update(state, {timeSeriesQueries: {$set: tsqsWithDuplicate}, availabilityIsCompact: {$set: true}, tsqSortField: {$set: updatedSortField}});         
    case SWAP_IN_TSQ: 
        action.payload.tsq.queryID = state.timeSeriesQueries[action.payload.idx].queryID; // use old queryID for new query
        return update(state, {timeSeriesQueries: {[action.payload.idx]: {$set: action.payload.tsq}}});
    case REMOVE_TIMESERIESQUERY:
        let ts = state.timeSeriesQueries[action.payload];
        let newStickiedTSQ = Utils.getNewStickiedAggKey(ts, state.timeSeriesQueries, state.stickiedTSQ);
        let queriesCopy = [].concat(state.timeSeriesQueries);
        queriesCopy.splice(action.payload, 1);
        let queriesWithAggKeys = Utils.updateAggKeys(queriesCopy);
        let newChartTypeOnRemove = state.chartType;
        if(state.chartType === ChartTypes.Scatterplot && 
            Object.values(state.scatterMeasures).indexOf(state.timeSeriesQueries[action.payload].queryID) !== -1)
                newChartTypeOnRemove = ChartTypes.Linechart;
        return update(state, 
            {timeSeriesQueries: {$set: queriesWithAggKeys}, 
            chartType: {$set: newChartTypeOnRemove}, 
            stickiedTSQ: {$set: newStickiedTSQ}}
        );
    case REMOVE_ALL_TIMESERIESQUERIES:
        return update(state, {
            timeSeriesQueries: {$set: []}, 
            chartType: {$set: ChartTypes.Linechart}, 
            stickiedTSQ: {$set: null}}
        );
    case SHOW_HIDE_TIMESERIESQUERY:
        let newChartTypeOnHide = state.chartType === ChartTypes.Scatterplot ? ChartTypes.Linechart : state.chartType;
        return update(state, {timeSeriesQueries: {[action.payload]: {hidden: {$set: !state.timeSeriesQueries[action.payload].hidden}}}, chartType: {$set: newChartTypeOnHide}});
    case SET_FOCUS_ON_ADD_MARKER_BUTTON_TRIGGER: 
        return update(state, {focusAddMarkerButtonTrigger: {$set: action.payload}});
    case STICKY_TIMESERIESQUERY:
        return update(state, {stickiedTSQ: {$set: action.payload}});
    case UNSTICKY_TIMESERIESQUERIES:
        return update(state, {stickiedTSQ: {$set: null}});         
    case HIDE_ALL_TIMESERIESQUERIES: 
        let newTimeSeriesQueries = [];
        state.timeSeriesQueries.forEach((q) => {
            let newTimeSeriesQuery = TimeSeriesQuery.fromObject(q);
            newTimeSeriesQuery.hidden = true;
            newTimeSeriesQuery.queryID = q.queryID;
            newTimeSeriesQueries.push(newTimeSeriesQuery);
        });  
        return update(state, {timeSeriesQueries: {$set: newTimeSeriesQueries}});
    case ZOOM:
        let fromMillis = new Date(state.searchSpan.from).valueOf(); 
        let toMillis = new Date(state.searchSpan.to).valueOf();
        let durationMillis = toMillis - fromMillis;
        let unzoomSearchSpan;
        if (action.payload.direction) {
            switch (action.payload.direction) {
                case 'in':
                    toMillis -= durationMillis / 3;
                    fromMillis += durationMillis / 3;
                    let diffFrom1000 = 1000 - (toMillis - fromMillis); 
                    if (diffFrom1000 > 0) {
                        toMillis += Math.ceil(diffFrom1000 / 2);
                        fromMillis -= Math.floor(diffFrom1000 / 2);
                    }
                    break;
                case 'out':
                    toMillis += durationMillis / 3;
                    fromMillis -= durationMillis / 3;
                    break;
                case 'left':
                    toMillis -= durationMillis / 3;
                    fromMillis -= durationMillis / 3;
                    break;
                case 'right':
                    toMillis += durationMillis / 3;
                    fromMillis += durationMillis / 3;
                    break;
                case 'unzoom': 
                    unzoomSearchSpan = state.unzoomStack[0];
                    fromMillis = unzoomSearchSpan.from;
                    toMillis = unzoomSearchSpan.to;
                    break;
                case 'snapRight':
                    let searchSpan = state.searchSpan;
                    let searchSpanLengthMillis = new Date(searchSpan.to).valueOf() - new Date(searchSpan.from).valueOf();
                    toMillis = new Date(getAvailabilityDistribution({analytics: state}).range.to).valueOf();
                    fromMillis = toMillis - searchSpanLengthMillis;
                    break;
            }
        }
        else {
            fromMillis =  Utils.getValueOrDefault(action.payload.from, new Date(state.searchSpan.from).valueOf());
            toMillis = Utils.getValueOrDefault(action.payload.to, new Date(state.searchSpan.to).valueOf());
        }

        let parsedBucketSize = action.payload ? action.payload.bucketSize : null;
        let updatedSearchSpan = {from: new Date(fromMillis), to: new Date(toMillis)};
        let validBucketSizes = Utils.getValidBucketSizes(fromMillis, toMillis);
        let unzoomStackAction = {$set: state.unzoomStack} as any;
        let isSearchSpanRelative = toMillis === new Date(getAvailabilityDistribution({analytics: state}).range.to).valueOf();
        if (parsedBucketSize) {
            updatedSearchSpan['bucketSize'] = parsedBucketSize;
        } 
        else if(action.payload.direction === 'unzoom') {
            updatedSearchSpan['bucketSize'] = unzoomSearchSpan.bucketSize;
            unzoomStackAction = {$splice: [[unzoomSearchSpan, 1]]};
        } 
        else { // IF bucket size isn't set, set to the proportional bucket;
            updatedSearchSpan['bucketSize'] = Utils.getNewBucketSize(state.validBucketSizes, validBucketSizes, state.searchSpan.bucketSize);
            unzoomStackAction = {$unshift: [{...state.searchSpan}]} as any;
        }
        return update(state, {unzoomStack: unzoomStackAction, isSearchSpanRelative: {$set: isSearchSpanRelative}, searchSpan: {$set: updatedSearchSpan}, validBucketSizes: {$set: validBucketSizes}, availabilityRerenderTrigger: {$set: !state.availabilityRerenderTrigger}});
    case GET_AVAILABILITY_FULFILLED:
        let stateToUpdate =  { availabilityDistribution: {$set: action.payload.availabilityDistribution},
            isAvailabilityDistributionLoading: {$set: false},
            maintainAvailability: {$set: null},
            warmStoreRange: {$set: action.payload.warmStoreRange}
        };

        if (action.payload.availabilityDistribution) {

            let clampedResponse = Utils.cullValuesOutOfRange(action.payload.availabilityDistribution);
            if (Object.keys(clampedResponse[1]).length && 
                    action.payload.getAvailabilityReason !== GetAvailabilityReason.AutoRefresh &&
                    action.payload.getAvailabilityReason !== GetAvailabilityReason.LongPoll) {
                console.log("Culled availability values:")
                console.log(clampedResponse[1]);
            }
            action.payload.availabilityDistribution = clampedResponse[0];

            // clamp availability range left to warm store range if they are within 90 seconds of each other
            if(action.payload && action.payload.warmStoreRange){
                let warmStoreRangeToMillis = new Date(action.payload.warmStoreRange[1]).valueOf();
                let availabilityToMillis = new Date(action.payload.availabilityDistribution.range.to).valueOf();
                if(warmStoreRangeToMillis < availabilityToMillis && (availabilityToMillis - warmStoreRangeToMillis < 90*1000)){
                    action.payload.availabilityDistribution.range.to = action.payload.warmStoreRange[1];
                }
            }

            if(action.payload.getAvailabilityReason === GetAvailabilityReason.PageLoadOrEnvironmentChange){
                let startMillis = new Date(action.payload.availabilityDistribution.range.from).valueOf();
                let toLess24HoursMillis = new Date(action.payload.availabilityDistribution.range.to).valueOf() - 1000 * 60 * 60 * 24;
                let fromValue = Math.max(startMillis, toLess24HoursMillis);
                let parsedSearchSpan = { to: new Date(action.payload.availabilityDistribution.range.to).valueOf(), from: fromValue};
                let validBucketSizes = Utils.getValidBucketSizes(fromValue, parsedSearchSpan['to']);
                stateToUpdate['validBucketSizes'] = {$set: validBucketSizes};
                parsedSearchSpan['bucketSize'] = Utils.getNewBucketSize([], validBucketSizes, '');
                stateToUpdate['searchSpan'] = {$set: parsedSearchSpan};
                stateToUpdate['isSearchSpanRelative'] = {$set: true};
            }
            else if ([GetAvailabilityReason.AutoRefresh].includes(action.payload.getAvailabilityReason) && getIsSearchSpanRelative({analytics: state})){
                let newSearchSpan = {}
                let searchSpanRangeMillis = state.searchSpan.to - state.searchSpan.from;
                newSearchSpan['to'] = (new Date(action.payload.availabilityDistribution.range.to)).valueOf();
                newSearchSpan['from'] =  (new Date(action.payload.availabilityDistribution.range.to)).valueOf() - searchSpanRangeMillis;
                newSearchSpan['bucketSize'] = state.searchSpan.bucketSize;
                stateToUpdate['searchSpan'] = {$set: newSearchSpan};
                stateToUpdate['isSearchSpanRelative'] = {$set: true};
            }
            stateToUpdate['availabilityRerenderTrigger'] = {$set: !state.availabilityRerenderTrigger};
        }
        // swallow on empty availability distribution for auto refresh, manual refresh, and long poll
        else if([GetAvailabilityReason.AutoRefresh, GetAvailabilityReason.ManualRefresh, GetAvailabilityReason.LongPoll].includes(action.payload.getAvailabilityReason)){
            stateToUpdate = {isAvailabilityDistributionLoading: {$set: false}} as any;
        }
        return update(state, stateToUpdate);
    case DELETE_QUERY:
        return update(state, { deletedQueryStatus: {$set: {status: 'inProgress'}}});
    case CLEAR_DELETE_QUERY:
        return update(state, { deletedQueryStatus: {$set: null}});
    case DELETE_QUERY_FULFILLED:
        return update(state, { deletedQueryStatus: {$set: {status: 'success', message: 'Deleted query "' + action.payload.name + '" successfully'}}});
    case DELETE_QUERY_FAILED:
        return update(state, { deletedQueryStatus: {$set: {status: 'failure', message: action.payload}}});
    case TOGGLE_COMPACT_AVAILABILITY:
        return update(state, {availabilityIsCompact: {$set: !state.availabilityIsCompact}});
    case TOGGLE_FORCE_COLD:
        return update(state, {forceCold: {$set: !state.forceCold}, availabilityRerenderTrigger: {$set: !state.availabilityRerenderTrigger}});
    case GET_METADATA_FULFILLED:
        return update(state, {metadata: {$set: action.payload.sort((a, b) => {return Utils.caseInsensitiveSortFunction(a.name, b.name)})}});
    case GET_ARTIFACTS_FULFILLED:
        let keyedArtifacts = {};
        action.payload.filter(a => a.clientDataType !== ClientDataFormat.RDX_20160513_P).forEach(a => {
            keyedArtifacts[a.id] = a;
        });
        return update(state, {savedQueries: {$set: keyedArtifacts}});
    case SET_SAVED_QUERY_NAME: 
        return update(state, {queryName: {$set: action.payload}});
    case CLEAR_SAVED_QUERY_NAME:
        return update(state, {queryName: {$set: null}});
    case SET_FOCUS_AND_STICKY_TRIGGERS:
        return update(state, {
            valueElementFocusTrigger: {$set: action.payload.valueElementFocusTrigger}, 
            valueElementUnfocusTrigger: {$set: action.payload.valueElementUnfocusTrigger},
            valueElementStickyTrigger: {$set: action.payload.valueElementStickyTrigger} 
        });
    case SET_SEARCHSPAN:
        let bucketSize = action.payload.bucketSize;
        if (!bucketSize) {
            bucketSize = Utils.getDimensionAndIntegerForRangeAndBuckets((new Date(action.payload.from)).valueOf(), (new Date(action.payload.to)).valueOf());
        }
        return update(state, {isSearchSpanRelative: {$set: !!action.payload.isSearchSpanRelative}, validBucketSizes: {$set: action.payload.validBucketSizes}, searchSpan: {$set: {from: action.payload.from, to: action.payload.to, bucketSize: bucketSize}}});
    case SET_TIMEZONE:
        return update(state, {timezone: {$set: action.payload.timezone}});
    case SET_MAINTAIN_INTERVAL: 
        return update(state, {maintainedIntervalSize: {$set: action.payload.maintainedIntervalSize}, isAutoRefreshEnabled: {$set: false}});
    case BULK_PARSE_ANALYTICS_STATE: 
        let parseStateResult: StateParseResult;

        if(action.payload.clientDataType === ClientDataFormat.Gen2SkuUrl){
            parseStateResult = StateParserService.parseUrlState(action.payload.clientData,  state);
        } else if(action.payload.clientDataType === ClientDataFormat.Gen1SkuUrl){
            parseStateResult = StateParserService.parseGen1UrlState(action.payload.clientData, state);
        } else if ([ClientDataFormat.RDX_20200713_Q, ClientDataFormat.RDX_20181120_Q].includes(action.payload.clientDataType)) { // Gen 2 saved query
            parseStateResult = StateParserService.parseT7StyleQuery(action.payload.clientData, state);
        } else if(action.payload.clientDataType === ClientDataFormat.RDX_20160513_Q){ // Gen 1 saved query (RDX_20160513_Q)
            parseStateResult = StateParserService.parseT6StyleQuery(action.payload.queries[0].clientData, state);
        } else{
            console.error(`State object format ${action.payload.clientDataType} not found`);
            return state;
        }

        // URL state objects can be constructed by users, so we want to display parsing errors to help them
        // with debugging.
        if(parseStateResult.errors.length > 0) {
            console.error(`State object format ${action.payload.clientDataType} could not be parsed successfully:\n${parseStateResult.errors.join('\n')}`);
        }

        let newState = parseStateResult.state;
        Utils.initializeUsedColors(newState.aggregatesQueries, newState.timeSeriesQueries);

        return update(state, { $merge: newState });
    case GET_INSTANCES_FULFILLED:
        if (!Utils.isResponseWithError(action.payload) && action.payload.get && Array.isArray(action.payload.get)) {
            let tsqs = [...state.timeSeriesQueries];
            action.payload.get.forEach((response) => {
                let instance = null;

                if (!response.error) {
                    instance = response.instance;
                    let matches = tsqs.filter((tsq) => {
                        return tsq.alias === Utils.getInstanceAlias(instance);
                    });
                    
                    matches.forEach(tsq => {
                        let additionalFields = {};
                        
                        additionalFields['Variable'] = tsq.additionalFields['Variable'];
                        if (instance.name) {
                            additionalFields['timeSeriesName'] = instance.name;
                        }
                        if (instance.description) {
                            additionalFields['Description'] = instance.description;
                        }

                        additionalFields = {...additionalFields, ...instance.instanceFields};

                        tsq.instanceObject = instance;
                        tsq.additionalFields = additionalFields;
                    });
                }
            });
            return update(state, {timeSeriesQueries: {$set: tsqs}, isLoadingSavedTsq: {$set: false}});
        }
        return update(state, {isLoadingSavedTsq: {$set: false}});
    case SET_MARKERS:
        return update(state, {markers: {$set: action.payload}});
    case SET_AUTO_REFRESH:
        if(action.payload){
            let newSearchSpan = {};
            let wrappedState = {analytics: state};
            let searchSpanLengthMillis = state.autoRefreshTimeSpanMillis;
            newSearchSpan['to'] = new Date(getAvailabilityDistribution(wrappedState).range.to);
            newSearchSpan['from'] = new Date(newSearchSpan['to'].valueOf() - searchSpanLengthMillis);
            newSearchSpan['bucketSize'] = Utils.getDimensionAndIntegerForRangeAndBuckets(0, searchSpanLengthMillis, StateParserService.DefaultNumberOfBuckets);
            let validBucketSizes = Utils.getValidBucketSizes(newSearchSpan['from'].valueOf(), newSearchSpan['to'].valueOf());
            return update(state, {isAutoRefreshEnabled: {$set: true}, searchSpan: {$set: newSearchSpan}, 
                isSearchSpanRelative: {$set: true}, validBucketSizes: {$set: validBucketSizes}});
        }
        else{
            return update(state, {isAutoRefreshEnabled: {$set: false}});
        }
    case SELECT_ENVIRONMENT:
        return update(state, {unzoomStack: {$set: []}, loadingAggregatesProgress: {$set: 0}, isLoadingAggregates: {$set: false}, 
                            isAutoRefreshEnabled: {$set: (action.payload && action.payload.isFromEnvironmentPicker) ? false : state.isAutoRefreshEnabled}});
    case SET_CHART_TYPE:
        return update(state, {chartType: {$set: action.payload}, stickiedTSQ: {$set: action.payload === ChartTypes.Linechart ? state.stickiedTSQ : null}, isGetSeriesEnabled: {$set: action.payload === ChartTypes.Linechart ? true : false}});
    case SET_SCATTER_MEASURES:
        return update(state, {  scatterMeasures: {$set: action.payload}, 
                                timeSeriesQueries: {$apply: tsqs => tsqs.map(tsq => {
                                    let newTsq = TimeSeriesQuery.fromObject(tsq);
                                    if(Object.values(action.payload).indexOf(tsq.queryID) !== -1) {
                                        newTsq.hidden = false;
                                    } else {
                                        newTsq.hidden = true;
                                    }
                                    return newTsq;
                                })}
                            });
    case SET_MODAL_OPTIONS:
        const getSuggestedScatterMeasures = (TSQs: any, xIdx: any) => {
            xIdx = xIdx ? xIdx : TSQs.indexOf(TSQs.filter((d) => !d.hidden && (d.dataType === ChartDataTypes.Numeric))[0]);
            if(!xIdx) xIdx = 0;
            let targetQuery = TSQs.filter((d, idx) => !d.hidden && (d.dataType === ChartDataTypes.Numeric) && idx !== xIdx)[0];
            let yIdx = targetQuery ? TSQs.findIndex(d => targetQuery.queryID === d.queryID) : xIdx;
            targetQuery = TSQs.filter((d, idx) => !d.hidden && (d.dataType === ChartDataTypes.Numeric) && idx !== xIdx && idx !== yIdx)[0];
            let rIdx = targetQuery ? TSQs.findIndex(d => targetQuery.queryID === d.queryID) : -1;

            return {
                X_MEASURE: xIdx,
                Y_MEASURE: yIdx,
                R_MEASURE: rIdx
            }
        }

        let modalOptions = {
            suggestedScatterMeasures: getSuggestedScatterMeasures(state.timeSeriesQueries, action.payload.tsqI),
            focusModalX: action.payload.focusModalX,
            focusModalY: action.payload.focusModalY,
            tsqAdvancedExplorationIndex: action.payload.tsqI,
            modalType: action.payload.modalType,
            caretDirection: action.payload.caretDirection ? action.payload.caretDirection : null,
            fromWell: action.payload.fromWell,
            sourceElement: action.payload.sourceElement
        }
        return update(state, {modalOptions: {$set: modalOptions}})
    case CLOSE_MODAL:
        return update(state, {modalOptions: {modalType: {$set: null}}})
    case SET_QUERY_FIELD:
        return action.payload.type === QueryTypes.TimeSeries 
                ? update(state, {timeSeriesQueries: {[action.payload.idx]: {[action.payload.field]: {$set: action.payload.value}}}})
                : update(state, {aggregatesQueries: {[action.payload.idx]: {[action.payload.field]: {$set: action.payload.value}}}});
    case SET_ACTIVE_VISUALIZATION_REFERENCE:
        return update(state, {activeVisualizationReference: {$set: action.payload}});
    case SET_LINECHART_STACK_STATE:
        return update(state, {linechartStackState: {$set: action.payload}});
    case REFRESH_PREVIOUS_SESSION_STATE:
        return update(state, {previousSessionState: {$set: localStorage.getItem('tsiPageState')}});
    case CLEAR_PREVIOUS_SESSION_STATE: 
        return update(state, {previousSessionState: {$set: null}});
    case STOP_AVAILABILITY_LONG_POLL: 
        return update(state, {isLongPollingAvailability: {$set: false}, isAvailabilityDistributionLoading: {$set: false}});
    case START_AVAILABILITY_LONG_POLL: 
        return update(state, {isLongPollingAvailability: {$set: true}});          
    case SAVE_QUERY: 
        return update(state, {saveQueryMessage: {$set: null}});
    case SAVE_QUERY_FULFILLED: 
        return update(state, {saveQueryMessage: {$set: action.payload}});
    case SET_SAVED_QUERY_FILTER: 
        return update(state, {savedQueryFilter: {$set: action.payload}});
    case SET_ISSAVEOPENSHAREVISIBLE: 
        return update(state, {isSaveOpenShareVisible: {$set: action.payload}, saveQueryMessage: {$set: null}});
    case GET_BRUSHED_REGION_STATISTICS:
        let brushedRegionStatisticsState = new BrushedRegionStatisticsState(true, true, action.payload.x, action.payload.y, new Date(action.payload.fromMillis), new Date(action.payload.toMillis));
        return update(state, {brushedRegionStatisticsState: {$set: brushedRegionStatisticsState}});
    case HIDE_BRUSHED_REGION_STATISTICS:
        return update(state, {brushedRegionStatisticsState: {isVisible: {$set: false}}});
    case GET_BRUSHED_REGION_STATISTICS_FULFILLED: 
        let newBrushedState = {brushedRegionStatisticsState: {isLoading: {$set: false}}};
        if(action.payload && action.payload.length){
            let newBrushedRegionStatisticsStateData: Array<TimeSeriesQueryStatistics> = [];
            action.payload.forEach((stats, idx) => {
                if(stats){
                    if('__tsiError__' in stats){
                        newBrushedRegionStatisticsStateData[idx] = {errorMessage: Utils.getErrorMessage(stats['__tsiError__'].error)};
                    }
                    else{
                        let statsObject = {values: new Map<string, number>()};
                        stats.properties.forEach(p => statsObject.values.set(p.name, p.values[0]));
                        newBrushedRegionStatisticsStateData[idx] = statsObject;
                    }
                }
            });
            newBrushedState.brushedRegionStatisticsState['data'] = {$set: newBrushedRegionStatisticsStateData}
        }
        return update(state, newBrushedState);
    case SET_TSID_FOR_LOOKUP:
        return update(state, {tsidForLookup: {$set: action.payload}});
    case MIGRATE_TSM_VARIABLES_FULFILLED: // to reflect the changes in variables on currently added tsqs in the well
        let migrationStatus = action.payload.migrationStatus;
        let tsqs = state.timeSeriesQueries;
        let newTsqs = [];
        let types = [];
        if (migrationStatus === TSXMigrationStatus.Error || migrationStatus === TSXMigrationStatus.PartialSuccess) {
            if (action.payload.result) {
                types = action.payload.result.put.filter(t => !t.error);
            }
        } else if (migrationStatus === TSXMigrationStatus.Successful) {
            types = action.payload.types;
        }
        if(types) {
            tsqs.forEach(tsq => {
                let updatedTsq;
                let tsqType = types.find(t => tsq.instanceObject.type ? 
                                                (t.id || t.timeSeriesType.id) === tsq.instanceObject.type.id : 
                                                (t.id || t.timeSeriesType.id) === tsq.instanceObject.typeId);
                if (tsqType) {
                    let variables = Utils.createVariablesList((tsqType.timeSeriesType || tsqType), state.metadata);
                    updatedTsq = TimeSeriesQuery.constructFromInstanceTypeVariable(variables.find(v => v.name === tsq.variableAlias).type, tsq.instanceObject, tsq.variableAlias, tsq.color, {swimLane: tsq.swimLane});
                    newTsqs.push(updatedTsq);
                } else {
                    newTsqs.push(tsq);
                }
            });
            return update(state, {timeSeriesQueries: {$set: newTsqs}});
        } else {
            return state;
        }
    case SET_CHART_SETTINGS_STATE:
        if (!('activeTSQI' in action.payload)) {
            let activeTSQI = state.chartSettingsState.activeTSQI; // Default to previous query index
            
            if(state.timeSeriesQueries.length === 0){ // Nullify activeTSQI if no queries present
                activeTSQI = null;
            } else if(typeof(state.chartSettingsState.activeTSQI) !== 'number'){ // If queries present and last activeTSQI was null, reset to 0
                activeTSQI = 0;
            }

            action.payload.activeTSQI = activeTSQI;
        }
        
        return update(state, {chartSettingsState: {$set: action.payload}});
    case UPDATE_JUMBOTRON_STATE:
        let newJumboState = null, newActiveState = state.isJumbotronActive, availabilityIsCompact = state.availabilityIsCompact;
        if(action.payload.target === CollapsableComponents.tagExp){ // Collapse state changed on tag explorer
            newJumboState =  {
                [CollapsableComponents.tagExp]: action.payload.isCollapsed,
                [CollapsableComponents.well]: state.jumbotronState[CollapsableComponents.well],
            }
            newActiveState = action.payload.isCollapsed === state.jumbotronState[CollapsableComponents.well] ? action.payload.isCollapsed : state.isJumbotronActive;
        } else if(action.payload.target === CollapsableComponents.well){ // Collapse state changed on well
            newJumboState = {
                [CollapsableComponents.tagExp]: state.jumbotronState[CollapsableComponents.tagExp],
                [CollapsableComponents.well]: action.payload.isCollapsed,
            }
            newActiveState = action.payload.isCollapsed === state.jumbotronState[CollapsableComponents.tagExp] ? action.payload.isCollapsed : state.isJumbotronActive;
        } else if(action.payload.target === CollapseMode.toggle){ // Toggle jumbotron mode
            newJumboState = {
                [CollapsableComponents.tagExp]: !state.isJumbotronActive,
                [CollapsableComponents.well]: !state.isJumbotronActive,
            }
            newActiveState = !state.isJumbotronActive;
            availabilityIsCompact = newActiveState;
        } else if(action.payload.target === CollapseMode.expand){ // Expand jumbotron
            newJumboState = {
                [CollapsableComponents.tagExp]: true,
                [CollapsableComponents.well]: true,
            }
            newActiveState =  true;
        } else if(action.payload.target === CollapseMode.collapse){ // Collapse jumbotron
            newJumboState = {
                [CollapsableComponents.tagExp]: false,
                [CollapsableComponents.well]: false,
            }
            newActiveState = false;
        } else{
            return state;
        }

        return update(state, {
            jumbotronState: {$set: newJumboState}, 
            isJumbotronActive: {$set: newActiveState},
            availabilityIsCompact: {$set: availabilityIsCompact}
        });
    case SET_IS_GET_SERIES_ENABLED:
        return update(state, {isGetSeriesEnabled: {$set: action.payload}});
    case SET_SHOULD_STICKY:
        return update(state, {shouldSticky: {$set: action.payload}});
    case SET_AUTO_REFRESH_TIME_SPAN_MILLIS:
        return update(state, {autoRefreshTimeSpanMillis: {$set: action.payload}});
    case SET_AUTO_REFRESH_CYCLE_MILLIS:
        return update(state, {autoRefreshCycleMillis: {$set: action.payload}});
    case SET_SWIMLANE_FIELD:
        return update(state, {
            swimLaneOptions: {
                [action.payload.swimlane]: {
                    [action.payload.field]: {
                        $set: action.payload.value
                    }
                }
            }
        });
    case ADD_SWIMLANE_HORIZONTAL_MARKER: {
        const { swimlane, value, color } = action.payload;
        const previousMarkers = state.swimLaneOptions[swimlane].horizontalMarkers || [];
        const newMarkers = [
            ...previousMarkers,
            { value , color }
        ];

        return update(state, {
            swimLaneOptions: {
                [action.payload.swimlane]: {
                    horizontalMarkers: {
                        $set: newMarkers
                    }
                }
            }
        });
    }
    case UPDATE_SWIMLANE_HORIZONTAL_MARKER: {
        const { swimlane, markerIndex, value, color } = action.payload;
        const previousMarkers = state.swimLaneOptions[swimlane].horizontalMarkers;
        const newMarkers = [...previousMarkers];
        newMarkers[markerIndex] = { value, color };
        return update(state, {
            swimLaneOptions: {
                [action.payload.swimlane]: {
                    horizontalMarkers: {
                        $set: newMarkers
                    }
                }
            }
        });
    }
    case REMOVE_SWIMLANE_HORIZONTAL_MARKER: {
        const { swimlane, markerIndex } = action.payload;
        const previousMarkers = state.swimLaneOptions[swimlane].horizontalMarkers;
        return update(state, {
            swimLaneOptions: {
                [action.payload.swimlane]: {
                    horizontalMarkers: {
                        $set: previousMarkers.filter((_m, i) => i !== markerIndex)
                    }
                }
            }
        });
    }
    case RESET_SWIMLANE_OPTIONS:
        return update(state, {
            swimLaneOptions: { $set: {...defaultSwimlaneOptions} }
        });
    case SET_SERIES_LABELS_ENABLED:
        localStorage.setItem(LocalStorageKeys.SeriesLabelsVisible, action.payload);
        return update(state, {isSeriesLabelsEnabled: {$set: action.payload}});
    default: 
       return state;
}
}
export const getAnalytics = state => state.analytics;
export const getIsLoadingAggregates = state => getAnalytics(state).isLoadingAggregates;
export const getAvailabilityDistribution = state => getAnalytics(state).availabilityDistribution;
export const getAvailabilityDistributionIsLoading = state => getAnalytics(state).isAvailabilityDistributionLoading;
export const getMetadata = state => getAnalytics(state).metadata;
export const getSearchSpan = state => getAnalytics(state).searchSpan;
export const getAvailabilityIsCompact = state => getAnalytics(state).availabilityIsCompact;
export const getTimezone = state => getAnalytics(state).timezone;
export const getAggregatesQueries = state => getAnalytics(state).aggregatesQueries;
export const getTimeSeriesQueries = state => {
    let tSQs = getAnalytics(state).timeSeriesQueries; 
    let sortField = getAnalytics(state).tsqSortField;
    let isDescending = getAnalytics(state).tsqSortDescending;
    if (sortField === null || sortField === undefined) {
    return tSQs;
    }
    tSQs.sort((a, b) => {
    let valueA = Utils.getTSQProperty(a, sortField);
    let valueB = Utils.getTSQProperty(b, sortField);
    if (valueA === valueB) {
        return 0;
    }
    if (valueA === null) {
        return (isDescending ? 1 : -1);
    }
    if (valueB === null) {
        return (isDescending ? -1 : 1);
    }
    return ((valueA < valueB) === isDescending) ? -1 : 1;
    });
    return tSQs;
};
export const getActiveQueries = state => getSelectedEnvironmentIsLts(state) ? 
    getTimeSeriesQueries(state) : getAnalytics(state).aggregatesQueries;
export const getMaintainedIntervalSize = state => getAnalytics(state).maintainedIntervalSize;
export const getLoadingAggregatesProgress = state => getAnalytics(state).loadingAggregatesProgress;
export const getLoadingEventsProgress = state => getAnalytics(state).loadingEventsProgress;
export const getModel = state => Utils.getValueOrDefault(getAnalytics(state).model, defaultState.model);
export const getHierarchies = state => Utils.getValueOrDefault(getModel(state).hierarchies, {});
export const getQueryName = state => Utils.getValueOrDefault(getAnalytics(state).queryName, '');
export const getHierarchiesArray = state => Utils.getArrayFromKeyedObject(getHierarchies(state));
export const getSelectedHierarchyId = state => getAnalytics(state).selectedHierarchyId;
export const getInstancesArray = state => Utils.getArrayFromKeyedObject(Utils.getValueOrDefault(getModel(state).instances, {}));
export const getTypesArray = state => Utils.getArrayFromKeyedObject(Utils.getValueOrDefault(getModel(state).types, {}));
export const getIsModelLoading = state => getAnalytics(state).isModelLoading;
export const getIsModelEmpty = state => getInstancesArray(state).length === 0;
export const getSavedQueriesArray = state => {
    let regexp = new RegExp(getSavedQueryFilter(state), 'i');
    let savedQueries = Utils.getArrayFromKeyedObject(getAnalytics(state).savedQueries)
            .filter(q => regexp.test(q.name));
    let environments = Utils.createKeyedObjectFromArray(getEnvironments(state), 'environmentId');
    let getEnvironmentNameFromSavedQuery = sq => {
        return sq.queries && sq.queries[0] && sq.queries[0].environmentId && environments[sq.queries[0].environmentId] ? environments[sq.queries[0].environmentId].displayName : null
    }
    savedQueries.sort((a,b) => {
        let leftEnvironmentName = getEnvironmentNameFromSavedQuery(a);
        let rightEnvironmentName = getEnvironmentNameFromSavedQuery(b);
        if(leftEnvironmentName === rightEnvironmentName){
            return Utils.caseInsensitiveSortFunction(a.name, b.name);
        }
        else{
            return Utils.caseInsensitiveSortFunction(leftEnvironmentName, rightEnvironmentName);
        }
    })
    return savedQueries;
};
export const getIsLoadingEvents = state => getAnalytics(state).isLoadingEvents;
export const getEventsError = state => getAnalytics(state).eventsError;
export const getTSQFields = state => getAnalytics(state).timeSeriesQueriesFields;
export const getDeletedQueryStatus = state => getAnalytics(state).deletedQueryStatus;
export const getTSQSortField = state => getAnalytics(state).tsqSortField;
export const getTSQSortDescending = state => getAnalytics(state).tsqSortDescending;
export const getAvailabilityRerenderTrigger = state => getAnalytics(state).availabilityRerenderTrigger;
export const getValidBucketSizes = state => getAnalytics(state).validBucketSizes;
export const getAutoRefreshCycleMillis = state => getAnalytics(state).autoRefreshCycleMillis;
export const getAutoRefreshTimeSpanMillis = state => getAnalytics(state).autoRefreshTimeSpanMillis;
export const getIsAutoRefreshEnabled = state => getAnalytics(state).isAutoRefreshEnabled;
export const getIsSearchSpanRelative = state => getAnalytics(state).isSearchSpanRelative;
export const getIsLongPollingAvailability = state => getAnalytics(state).isLongPollingAvailability;
export const getUnzoomStack = state => getAnalytics(state).unzoomStack;
export const getChartType = state => getAnalytics(state).chartType;
export const getIsLoadingSavedTsq = state => getAnalytics(state).isLoadingSavedTsq;
export const getMarkers = state => getAnalytics(state).markers;
export const getValueElementFocusTrigger = state => getAnalytics(state).valueElementFocusTrigger;
export const getValueElementUnfocusTrigger = state => getAnalytics(state).valueElementUnfocusTrigger;
export const getValueElementStickyTrigger = state =>  getAnalytics(state).valueElementStickyTrigger;
export const getStickiedTSQ = state => getAnalytics(state).stickiedTSQ;
export const getScatterMeasures = state => getAnalytics(state).scatterMeasures;
export const getExploreEventsPayload = state => getAnalytics(state).exploreEventsPayload;
export const getModalOptions = state => getAnalytics(state).modalOptions;
export const getModalTimeSeriesQuery = state => { 
    let analyticsState = getAnalytics(state); 
    if(analyticsState.modalOptions && analyticsState.modalOptions.tsqAdvancedExplorationIndex in analyticsState.timeSeriesQueries)
        return analyticsState.timeSeriesQueries[analyticsState.modalOptions.tsqAdvancedExplorationIndex]
    else
        return null; 
}
export const getLinechartStackState = state => getAnalytics(state).linechartStackState;
export const getActiveVisualizationReference = state => getAnalytics(state).activeVisualizationReference;
export const getCancelGQRHandler = state => getAnalytics(state).cancelGQRHandler;
export const getWarmStoreRange = state => getAnalytics(state).warmStoreRange;
export const getForceCold = state => getAnalytics(state).forceCold;
export const getEnvironmentHasWarmStore = state => {
    let selectedEnvironment = getSelectedEnvironment(state);
    return (selectedEnvironment.features && (selectedEnvironment.features.indexOf('WarmStore') !== -1));
} 
export const getPreviousSessionState = state => getAnalytics(state).previousSessionState;
export const getSaveQueryMessage = state => getAnalytics(state).saveQueryMessage;
export const getIsSaveQueryMessageError = state => typeof(getAnalytics(state).saveQueryMessage) === 'string';
export const getIsSaveOpenShareVisible = state => getAnalytics(state).isSaveOpenShareVisible;
export const getSavedQueryFilter = state => getAnalytics(state).savedQueryFilter;
export const getBrushedRegionStatisticsState = (state):BrushedRegionStatisticsState => getAnalytics(state).brushedRegionStatisticsState;
export const getSwimLaneOptions = state => getAnalytics(state).swimLaneOptions;
export const getFocusAddMarkerButtonTrigger = state => getAnalytics(state).focusAddMarkerButtonTrigger;
export const getTsidForLookup = state => getAnalytics(state).tsidForLookup;
export const getIsMoreRawEventsAvailable = state => getAnalytics(state).isMoreRawEventsAvailable;
export const getIsRawEventsSetIncomplete = state => getAnalytics(state).isRawEventsSetIncomplete;
export const getChartSettingsState = state => getAnalytics(state).chartSettingsState;
export const getJumbotronActive = state => getAnalytics(state).isJumbotronActive;
export const getJumbotronState = state => getAnalytics(state).jumbotronState;
export const getIsGetSeriesEnabled = state => getAnalytics(state).isGetSeriesEnabled;
export const getShouldSticky = state => getAnalytics(state).shouldSticky;
export const getIsSeriesLabelsEnabled = state => getAnalytics(state).isSeriesLabelsEnabled;

export default analyticsReducer;

import * as React from 'react';
import classNames from 'classnames/bind';
import ModalContainer from '../../Modal/Modal.container';
import { TSMEntityKinds, RequestStatus, FileUploadStatus } from '../../../../constants/Enums';
import Utils from '../../../services/Utils';
import Icon from '../../Icon/Icon';
import update from 'immutability-helper';
import { ProgressMessageField } from './ProgressMessageField';
import Placeholder from '../../Placeholder/Placeholder';
const cx = classNames.bind(require('../TsmModal.module.scss'));

interface Props {tsmModalComponent: any;}

export default class BulkUploadModal extends React.Component<Props> {
    private fileUploadStatusFieldID = Utils.customID('fileUploadStatusField');
    private uploadOnlyLabelID = Utils.customID('uploadOnlyLabel');
    private progressMessageFieldID = Utils.customID('progressMessageFieldID');

    handleUpdateOnlyChange = (e) => {
        if (Utils.isKeyDownAndNotEnter(e)) {return; }
        if (this.props.tsmModalComponent.props.progress !== RequestStatus.Publishing) {
            this.props.tsmModalComponent.props.clearProgress();
        }
        this.props.tsmModalComponent.setState(update( this.props.tsmModalComponent.state, {updateOnly: {$set: ! this.props.tsmModalComponent.state.updateOnly}}));
    };

    render() {
        const tsmModalComponent = this.props.tsmModalComponent;
        let title =  tsmModalComponent.props.entityType === TSMEntityKinds.Types ? tsmModalComponent.props.t('tsm.type.bulkUpload') : tsmModalComponent.props.entityType === TSMEntityKinds.Hierarchies ? tsmModalComponent.props.t('tsm.hierarchy.bulkUpload') : tsmModalComponent.props.t('tsm.instance.bulkUpload');
        return (
            <ModalContainer isOffModalAutoClosingEnabled={false} onClose={tsmModalComponent.closeModal} title={title} className={cx('tsmEntityModal')} contentPositionContainerClassName={cx('tsmModalContentPositionContainer')} wrapperClassName={cx('tsmModalContentContainer')} contentClassName={cx('tsmModalContent')}>
                <div className={cx('modalContent')}>
                    <div className={cx('bulkUploadContainer')}>
                        <div className={cx('form')}>
                            <div className={cx('build-area')}>
                                <div className={cx('block', 'long')}>
                                    <p> {tsmModalComponent.props.t('tsm.bulkUploadMessage')}</p>
                                    <div className={cx('file-upload-area')}>
                                        <button aria-label= {tsmModalComponent.props.t('tsm.chooseFile')} 
                                                aria-describedby={"myFileName " + this.fileUploadStatusFieldID} id="myFileLabel" className={cx('_base-secondary-button', 'label-button')}
                                                onClick={() => {document.getElementById(cx("refDataFileUpload")).click()}}>
                                            {tsmModalComponent.props.t('tsm.chooseFile')}
                                        </button>
                                        <input id={cx("refDataFileUpload")} type="file" onChange={(e) => tsmModalComponent.handleFiles(e.target.files[0])}/>
                                        <span id="myFileName"> {tsmModalComponent.props.t('tsm.noFileChosen')}</span>
                                    </div>
                                    {tsmModalComponent.state.fileUploadStatus !== null ? 
                                        <div className={cx('upload-status', tsmModalComponent.state.fileUploadStatus)}>
                                            {tsmModalComponent.state.fileUploadStatus === FileUploadStatus.Successful ? 
                                                    <Icon role="img" tabIndex={0} ariaLabel={tsmModalComponent.state.fileUploadMessage} id={'iconStatusOK-' + tsmModalComponent.props.theme} className={cx('icon16')}/>
                                                :
                                                    <Icon role="img" tabIndex={0} ariaLabel={tsmModalComponent.state.fileUploadMessage} id={'iconStatusError-' + tsmModalComponent.props.theme} className={cx('icon16')}/>
                                            }
                                            <span id={this.fileUploadStatusFieldID} role="alert"> {tsmModalComponent.state.fileUploadMessage}</span>
                                        </div>
                                        :
                                        ""
                                    }
                                    <textarea id={cx("JSONText")} disabled aria-label={tsmModalComponent.props.t('tsm.bulkUploadTextField')}/>
                                    {tsmModalComponent.props.entityType === TSMEntityKinds.Instances &&
                                        <div className={cx('update-only')}>
                                            <button role="checkbox" className={cx('_base-transparentBgButton')}
                                                    aria-label= {tsmModalComponent.props.t('tsm.chooseFile')}
                                                    aria-checked={tsmModalComponent.state.updateOnly}
                                                    onClick={this.handleUpdateOnlyChange}
                                                    aria-labelledby={this.uploadOnlyLabelID}
                                                    title={tsmModalComponent.state.updateOnly ? tsmModalComponent.props.t('unselect') : tsmModalComponent.props.t('select')}>
                                                <Icon id='checkbox' className={cx('icon16') + ' tsiCb ' +  (tsmModalComponent.state.updateOnly ? 'tsiCbSelected' : '')}/>
                                            </button>
                                            <span id={this.uploadOnlyLabelID} onClick={this.handleUpdateOnlyChange}>{tsmModalComponent.props.t('tsm.instance.updateOnly')}</span>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={cx('tsmModalBottom')}>
                        <div className={cx('buttonWrapper')}>
                            <button aria-label={tsmModalComponent.props.t('cancel')} className={cx('cancelButton')} onKeyDown={tsmModalComponent.closeModal} 
                                    onClick= {tsmModalComponent.closeModal}>
                                {tsmModalComponent.props.t('cancel')}
                            </button>
                            {tsmModalComponent.state.fileUploadStatus !== FileUploadStatus.Successful ? 
                                <button aria-label={tsmModalComponent.props.t('upload')} className={cx('saveButton', 'disabled')} aria-disabled="true" disabled>{tsmModalComponent.props.t('upload')}</button>
                            :
                                <button aria-label={tsmModalComponent.props.t('upload')} className={cx('saveButton')} onKeyDown={tsmModalComponent.createOrEditEntity} onClick={tsmModalComponent.createOrEditEntity} aria-describedby={this.progressMessageFieldID}>{tsmModalComponent.props.t('upload')}</button>
                            }
                        </div>
                    </div>
                    {(tsmModalComponent.props.progress === RequestStatus.Publishing && tsmModalComponent.props.entityType === TSMEntityKinds.Instances) &&
                        <Placeholder progress={tsmModalComponent.props.bulkUploadInstancesProgress}>
                            <div>{tsmModalComponent.props.t('tsm.instance.uploadingInstances')}</div>
                        </Placeholder>
                    }
                    <ProgressMessageField 
                        cx={cx}
                        entityType={tsmModalComponent.props.entityType} 
                        progress={tsmModalComponent.props.progress} 
                        errorCode={tsmModalComponent.props.errorCode} 
                        errorMessage={tsmModalComponent.props.errorMessage} 
                        method={tsmModalComponent.props.requestMethod} 
                        theme={tsmModalComponent.props.theme} 
                        t={tsmModalComponent.props.t}
                        progressMessageFieldID={this.progressMessageFieldID}/>
                </div>
            </ModalContainer>
        )
    }
}
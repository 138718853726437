import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import Demo from './Demo';
import { getTheme } from '../../state/reducers/AppSettingsReducer';

const mapStateToProps = state => {
    return { 
        theme: getTheme(state),
    };
};

const DemoContainer = translate()(connect(mapStateToProps)(Demo));

export default DemoContainer;

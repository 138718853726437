import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import ColorPicker from './ColorPicker';
import { getTheme } from '../../state/reducers/AppSettingsReducer';

const mapStateToProps = state => {
    return { 
        theme: getTheme(state),
    };
};

const ColorPickerContainer = translate()(connect(mapStateToProps)(ColorPicker));

export default ColorPickerContainer;

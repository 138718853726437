import * as React from 'react';
import classNames from 'classnames/bind';
import SidePane from '../Modal/SidePane/SidePane';
import { i18nInstance } from '../../../i18n';
import Utils from '../../services/Utils';
import TelemetryService from '../../services/TelemetryService';
import Icon from '../Icon/Icon';
const cx = classNames.bind(require('./AppSettings.module.scss'));

interface Props { theme: string; toggleOpenAppSettings: any; setTheme: any; isAppSettingsOpen: boolean; t: any; } 
interface State { } 

export default class AppSettings extends React.Component<Props, State> {
    
    public setLanguage = (language = "English") => {
        i18nInstance.changeLanguage(Utils.languageToCodeMapping[language]);  
        TelemetryService.logUserAction('setLanguage', {language: language});
    }

    render() {
        let self = this;
        let i18nLanguage = i18nInstance.language;
        let matchedLanguage = Object.keys(Utils.languageToCodeMapping).filter(k => Utils.languageToCodeMapping[k] === i18nLanguage);
        let selectedLanguage = matchedLanguage.length ? matchedLanguage[0] : 'English';
        return <div className={cx('wrapper')}>
            <button aria-label={this.props.t('appSettings.open')} onClick={this.props.toggleOpenAppSettings} className={cx('openToggle', {open: this.props.isAppSettingsOpen})}>
                <Icon id="iconGear-dark" className={cx('icon16')} />
            </button>
            {this.props.isAppSettingsOpen && 
                <SidePane title={this.props.t('appSettings.settings')} handleOnClose={this.props.toggleOpenAppSettings}>
                    <div className={cx('menuElement')}>
                        <div className={cx('title')}>{this.props.t('appSettings.theme')}</div>
                        <button disabled={this.props.theme === 'light'} className={cx('themeButton', 'light')} onClick={this.props.setTheme}>
                            <div className={cx('thumbWrapper')}>
                                <Icon id="linechart" className={cx('iconThumb')}/>
                            </div>
                            <span>{this.props.t('appSettings.light')}</span>
                        </button>
                        <button disabled={this.props.theme === 'dark'} className={cx('themeButton', 'dark')} onClick={this.props.setTheme}>
                            <div className={cx('thumbWrapper')}>
                                <Icon id="linechart" className={cx('iconThumb')}/>
                            </div>
                            <span>{this.props.t('appSettings.dark')}</span>
                        </button>
                    </div>
                    <div className={cx('menuElement')}>
                        <div className={cx('title')}>{this.props.t('appSettings.language')}</div>
                        <select value={selectedLanguage} onChange={function(e) {
                            self.setLanguage(e.target.value);
                            }}>
                            {Object.keys(Utils.languageToCodeMapping).map(k => <option key={k}>{k}</option>)};
                        </select>
                    </div>
                </SidePane>
            }
        </div>
    }
}
import Utils from "../services/Utils";
import { InstanceField } from "./InstanceField";

/**
 * Represents an instance.
 * @constructor
 * @param {[]} timeSeriesId - Timeseries id of the instance.
 * @param {[]} highlightedTimeSeriesId - Hightlighted timeseries id of the instance.
 * @param {string} timeSeriesIdKey - The key for timeseries id of the instance.
 * @param {string} name - The name of the instance.
 * @param {string} description - The description of the instance.
 * @param {string} [typeId] - The type id of the instance.
 * @param {[]} [hierarchyIds] - Hierarchy ids assigned for the instance.
 * @param {{}} [errors] - Errors in the instance, see InstanceEntityErrors enums for details.
 */
export default class InstanceEntity {
    public timeSeriesId: string[];
    public timeSeriesIdKey: string;
    public highlightedTimeSeriesId?: string[];
    public name?: string;
    public description?: string;
    public typeId?: string;
    public hierarchyIds?: string[];
    public instanceFields?: Array<InstanceField | any>;
    public errors?: {};

    constructor(numberOfTsids: number) {
        this.timeSeriesId = [];
        Array(numberOfTsids).fill(0).map(() => this.timeSeriesId.push(""));
    }

    static fromObject = (obj: any, numberOfTsids: number = 1) => {
        let iE = new InstanceEntity(numberOfTsids);
        iE.timeSeriesId = obj.timeSeriesId;
        
        if (obj.typeId) {
            iE.typeId = obj.typeId;
        }

        if (obj.description) {
            iE.description = Utils.stripHits(obj.description);
        }

        if (obj.name) {
            iE.name = Utils.stripHits(obj.name);
        }

        if (obj.hierarchyIds) {
            iE.hierarchyIds = obj.hierarchyIds;
        } 

        if (obj.instanceFields) {
            iE.instanceFields = [];
            Object.keys(obj.instanceFields).forEach(name => {
                let instanceField = new InstanceField();
                instanceField.name = Utils.stripHits(name);
                instanceField.value = Utils.stripHits(obj.instanceFields[name]);
                iE.instanceFields.push(instanceField);
            });
        } 
        return iE;
    }

    public convertToJSON() {
        let data = {};
        data["put"] = [];

        let instance = {};
        instance["timeSeriesId"] = this.timeSeriesId;
        instance["typeId"] = this.typeId;
        instance["name"] = (this.name && this.name.trim().length) ? this.name.trim() : null;
        instance["description"] = this.description;
        instance["hierarchyIds"] = this.hierarchyIds;
        if (this.instanceFields) {
            let instanceFields = {};
            this.instanceFields.forEach((iF) => {instanceFields[iF.name] = iF.value;});
            if (Object.keys(instanceFields).length) {
                instance["instanceFields"] = instanceFields;
            }
        } 

        data["put"].push(instance);
        
        return JSON.stringify(data, null, 2);    
    }

    public hasErrors() {
        return this.errorCount() > 0;
    }

    public updateErrors() {
        if (this.timeSeriesId.filter(id => id === "").length) {
            if (!this.errors) {
                this.errors = {};
            }
            this.errors["id"] = true;
        } else {
            if (this.errors && this.errors["id"]) {
                delete this.errors["id"];
            }
        }

        if (!this.typeId) {
            if (!this.errors) {
                this.errors = {};
            }
            this.errors["typeId"] = true;
        } else {
            if (this.errors && this.errors["typeId"]) {
                delete this.errors["typeId"];
            }
        }

        if (this.instanceFields && this.instanceFields.length) {
            this.instanceFields.forEach((iF) => {
                iF.updateErrors();
            });

            let uniqueName = true;
            this.instanceFields.forEach((iF, idx) => {
                this.instanceFields.forEach((iF2, idx2) => {
                    if (idx !== idx2) {
                        if (iF.name && iF.name === iF2.name) {
                            iF.addError("name_unique");
                            uniqueName = false;
                        }    
                    }
                });
                if (uniqueName) {
                    iF.removeError("name_unique");
                }
                uniqueName = true;
            });
        }
    }

    public errorCount() {
        let errorCount = 0;
        if (this.errors) {
            errorCount += Object.keys(this.errors).length;
        }

        if (this.instanceFields) {
            this.instanceFields.forEach((l) => {
                errorCount += l.errorCount();
            });
        }

        return errorCount;
    }
}
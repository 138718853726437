import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import PbiModal from './PbiModal';
import { getTheme, getSelectedEnvironmentIsLts, getSelectedEnvironment } from '../../../state/reducers/AppSettingsReducer';
import { 
    getActiveQueries, 
    getSearchSpan, 
    getEnvironmentHasWarmStore, 
    getAvailabilityDistribution, 
    getForceCold, 
    getWarmStoreRange, 
    getIsSearchSpanRelative} from '../../../state/reducers/AnalyticsReducer';

const mapStateToProps = state => {
    return { 
        theme: getTheme(state),
        searchSpan: getSearchSpan(state),
        environmentIsLts: getSelectedEnvironmentIsLts(state),
        timeSeriesQueries: getActiveQueries(state),
        selectedEnvironment: getSelectedEnvironment(state),
        environmentHasWarmStore : getEnvironmentHasWarmStore(state),
        availabilityDistribution: getAvailabilityDistribution(state),
        forceCold: getForceCold(state),
        warmStoreRange: getWarmStoreRange(state),
        isGlobalSearchSpanRelative: getIsSearchSpanRelative(state)
    };
};

const PbiModalContainer = translate()(connect(mapStateToProps)(PbiModal));

export default PbiModalContainer;

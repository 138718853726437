import * as React from 'react';
import classNames from 'classnames/bind';
import Icon from '../../Icon/Icon';
import { SortableTableColumn } from '../../SortableTable/SortableTable';
import SortableTableContainer from '../../SortableTable/SortableTable.container';
import Utils from '../../../services/Utils';
const cx = classNames.bind(require('../TsmEntities.module.scss'));

const TypesColumns: Array<SortableTableColumn> =  [
    { key: 'name', i18nKey: 'tsm.name', isSortable: true},
    { key: 'id', i18nKey: 'tsm.type.id', isSortable: true, style: { minWidth: '340px' } },
    { key: 'description', i18nKey: 'tsm.description', isSortable: true },
    { key: 'variables', i18nKey: 'tsm.type.variables', isSortable: true },
    { key: 'actions', i18nKey: 'tsm.actions', isSortable: false, style: { width: '164px' } }
];

const VariablesColumns: Array<SortableTableColumn> =  [
    { key: 'name', i18nKey: 'tsm.name', isSortable: true},
    { key: 'kind', i18nKey: 'tsm.type.variable.kind', isSortable: true},
    { key: 'value', i18nKey: 'tsm.type.variable.value', isSortable: false},
    { key: 'actions', i18nKey: 'tsm.actions', isSortable: false}
];

/*
    'entity' refers to type
    'subEntity' refers to variable in a type
*/
export const TypesTable = ({entities, selectedEntityIndex, selectedSubEntityIndex, expandedEntityIndices, handlers: {onClickRow, onClickSort, onCopyId, onEditEntity, onShowEntityJSON, onConfirmDeletion, onClickSubEntitySort, onAddSubEntity, onEditSubEntity, onConfirmDeletionSubEntity}, sort: {sOrder, sColumnKey, sOrderSubEntity, sColumnKeySubEntity, sParentIndex}, t, theme, isUserContributor, progressMessageFieldID}) => {
    return <SortableTableContainer
            columns={TypesColumns}  
            rows={entities} 
            handleOnSort={onClickSort} 
            canExpandRows={true}
            sortOrder={sOrder}
            sortColumnIndex={TypesColumns.findIndex(column => column.key === sColumnKey)}
            selectedRowIndex={selectedEntityIndex}
            handleOnClickRow={onClickRow}
            expandedRowIndices={expandedEntityIndices}
            renderRow={(type, index, expanded, selected, onClick) => 
                <TypesTableRow 
                    key={`typestablerow-${index}`} 
                    t={t}
                    theme={theme}
                    type={type}
                    rowIndex={index} 
                    expanded={expanded}
                    selected={selected}
                    handlers={{onClick, onCopyId}}
                    actionButton={createActionButtonsForType({t, theme, typeId: type.id, index, handlers: {onEditEntity, onShowEntityJSON, onConfirmDeletion}, isUserContributor, progressMessageFieldID})} />
            }
            renderExpandedRowContents={(type, idx) => <TypeDetails 
                                                        t={t} 
                                                        theme={theme} 
                                                        type={type} 
                                                        typeIdx={idx} 
                                                        selectedVariableIndex={selectedSubEntityIndex} 
                                                        handlers={{onClickSubEntitySort, onAddSubEntity, onEditSubEntity, onConfirmDeletionSubEntity}} 
                                                        sort={{sOrder: sOrderSubEntity, sColumnKey: sColumnKeySubEntity, sOrderVisible: idx === sParentIndex}} 
                                                        isUserContributor={isUserContributor} 
                                                        progressMessageFieldID={progressMessageFieldID}/>}/> 
}

const TypesTableRow = ({t, theme, type, rowIndex, expanded, selected, handlers: {onClick, onCopyId}, actionButton}) =>
    <tr tabIndex={0}
        className={cx('row', 'bodyRow', {bodyRowExpanded: expanded}, { bodyRowSelected: selected })} 
        onClick={() => onClick(rowIndex)} onKeyDown={(e) => {if (!Utils.isKeyDownAndNotEnter(e)) {onClick(rowIndex)}}}>
        <td className={cx('expand', 'table-cell')}>
            <div className={cx('cell-wrapper')}>
                <button className={cx('inline')}
                    title={t('expandCollapse')} 
                    aria-label={t('expandCollapse')} 
                    aria-controls={type.id + "_details"}
                    aria-expanded={expanded}> 
                    <Icon 
                        id={cx('caret-down')} 
                        className={cx('icon12', 'expandIcon', { rotated: expanded })} />
                </button>
            </div>
        </td>
        <TypesTableCell sortable={true}>{type.name}</TypesTableCell>
        <TypesTableCell sortable={true}>
            <span className={cx('card-id')}>
                <span className={cx('copy-id')}>{type.id}</span>
                <input tabIndex={-1} id={"copyId-" + type.id} type="text" readOnly onClick={() => {return false;}} aria-label={t('tsm.type.copyId')} aria-describedby={"copyIdMessage-" + type.id}></input>
                <button className={cx('copy-id-button')} onClick={onCopyId(type.id)} onKeyDown={onCopyId(type.id)} 
                        aria-label={t('tsm.type.copyId')} title={t('tsm.type.copyId')}>
                    <Icon id={'iconCopy-' + theme} className={cx('icon16')}/>
                </button>
                <span role="alert" id={"copyIdMessage-" + type.id} className={cx('copy-id-message')}></span>
            </span>
        </TypesTableCell>
        <TypesTableCell sortable={true}>{type.description ? type.description : '-'}</TypesTableCell>
        <TypesTableCell sortable={true}>{type.variables ? Object.keys(type.variables).length : 0}</TypesTableCell>

        <td className={cx('table-cell', 'actions')}>
            <div className={cx('cell-wrapper')}>
                {actionButton}
            </div>
        </td>
    </tr>;

const TypesTableCell = ({sortable, children}) => 
    <td className={cx('table-cell', {sortableCell: sortable})}>
        <div className={cx('cell-wrapper')}>
            <span>{children}</span>
        </div>
    </td>;

const createActionButtonsForType = ({t, theme, typeId, index, handlers: {onEditEntity, onShowEntityJSON, onConfirmDeletion}, isUserContributor, progressMessageFieldID}) => 
    isUserContributor ? 
        [<button key={`edit-${index}`} className={cx('action-button', 'inline')} onClick={onEditEntity(typeId)} onKeyDown={onEditEntity(typeId)} 
                aria-label={t('tsm.type.edit')} aria-describedby={progressMessageFieldID} title={t('tsm.type.edit')}>
            <Icon id={'iconEdit'} className={cx('icon16', theme)}/>
        </button>,
        <button key={`show-${index}`} className={cx('action-button', 'inline')} onClick={onShowEntityJSON(index)} onKeyDown={onShowEntityJSON(index)} 
                aria-label={t('tsm.viewJson')} title={t('tsm.viewJson')}>
            <Icon id={"iconDocument"} className={cx('icon16')}/>
        </button>,
        <button key={`delete-${index}`} className={cx('action-button', 'inline', 'delete')} onClick={e => onConfirmDeletion(false, e, typeId)} 
                onKeyDown={e => onConfirmDeletion(false, e, typeId)} 
                aria-describedby={progressMessageFieldID}
                aria-label={t('tsm.type.delete')} title={t('tsm.type.delete')}>
            <Icon id={'iconDelete'} className={cx('icon16', theme)}/>
        </button>]
    : '';


const TypeDetails = ({t, theme, type, typeIdx, selectedVariableIndex, handlers: {onClickSubEntitySort, onAddSubEntity, onEditSubEntity, onConfirmDeletionSubEntity}, sort: {sOrder, sColumnKey, sOrderVisible}, isUserContributor, progressMessageFieldID}) => 
    <>
        {(type.variables && Object.keys(type.variables).length !== 0) && 
        <div className={cx('expanded-row-table-container')}>
            <SortableTableContainer
                columns={VariablesColumns}  
                rows={Object.keys(type.variables).map(vName => {return {name: vName, ...type.variables[vName]}})} 
                handleOnSort={(fieldName) => onClickSubEntitySort(fieldName, typeIdx)} 
                canExpandRows={false}
                sortOrder={sOrder}
                sortColumnIndex={VariablesColumns.findIndex(column => column.key === sColumnKey)}
                ariaLabel={t('tsm.type.variables')}
                selectedRowIndex={selectedVariableIndex}
                isSortVisible={sOrderVisible}
                renderRow={(variable, index, expanded, selected) => 
                    <VariablesTableRow 
                        key={`variablestablerow-${index}`} 
                        t={t}
                        variable={variable}
                        selected={selected}
                        actionButton={createActionButtonsForVariable({t, theme, variableName: Object.keys(type.variables)[index], typeId: type.id, index, handlers: {onEditSubEntity, onConfirmDeletionSubEntity}, isUserContributor, progressMessageFieldID})} />
                }/> 
        </div>}
        {isUserContributor &&
        <div className={cx('sub-button')}>
            <button className={cx('action-button')} onClick={onAddSubEntity(type.id)} aria-label={t('tsm.type.addVariable')}>
                <Icon id={'iconAddBlue'} className={cx('icon16')}/>
                <span>{t('tsm.type.addVariable')}</span>
            </button>
        </div>}
    </>;

const VariablesTableRow = ({t, variable, selected, actionButton}) => 
    <tr className={cx('row', {bodyRowSelected: selected})}>
        <TypesTableCell sortable={true}>{variable.name}</TypesTableCell>
        <TypesTableCell sortable={true}>{variable.kind}</TypesTableCell>
        <TypesTableCell sortable={false}>{variable.value ? variable.value.tsx : '(' + t('empty').toLowerCase() + ')'}</TypesTableCell>

        <td className={cx('table-cell', 'actions')}>
            <div className={cx('cell-wrapper')}>
                {actionButton}
            </div>
        </td>
    </tr>;


const createActionButtonsForVariable = ({t, theme, variableName, typeId, index, handlers: {onEditSubEntity, onConfirmDeletionSubEntity}, isUserContributor, progressMessageFieldID}) => 
    isUserContributor ? 
        <>
            <button key={`edit-variable-${index}`} className={cx('action-button')} onClick={onEditSubEntity(typeId, index)} 
                    aria-label={t('tsm.type.variable.title') + (index + 1) + variableName + t('tsm.edit')}
                    aria-describedby={progressMessageFieldID}
                    title={t('tsm.type.variable.edit')}>
                <Icon id={'iconEdit'} className={cx('icon16', theme)}/>
            </button>
            <button key={`delete-variable-${index}`} className={cx('action-button', 'delete')} onClick={e => onConfirmDeletionSubEntity(false, e, typeId, index)} 
                    aria-label={t('tsm.type.variable.title') + (index + 1) + variableName + t('tsm.delete')}
                    aria-describedby={progressMessageFieldID}
                    title={t('tsm.type.variable.delete')}>
                <Icon id={'iconDelete'} className={cx('icon16', theme)}/>
            </button>
        </>
    : '';
import * as React from 'react';
import classNames from 'classnames/bind';
import Icon from '../../components/Icon/Icon';
import Utils from '../../services/Utils';
import { JobStatus, JobsErrorCode, JobType, JobInfo } from '../../models/JobsModels';
import TooltipableContainer from '../../components/Tooltipable/Tooltipable.container';
const cx = classNames.bind(require('./Jobs.module.scss'));

const dateFormatter = Utils.tsiClient.utils.timeFormat(true, true, 0, true, 0, 'YYYY/MM/DD H:mm [UTC]');
const formatDate = date => date ? dateFormatter(date) : '-';

const StatusIcon = ({ status, theme, title }) => {
    let iconId: string;
    switch (status) {
        case JobStatus.Succeeded: iconId = 'iconStatusOK'; break;
        case JobStatus.Failed:
        case JobStatus.RolledBack: iconId = 'iconStatusError'; break;
        case JobStatus.Cancelled: iconId = 'iconStatusCancelled'; break;
        case JobStatus.Queued: iconId = 'iconStatusQueued'; break;
        case JobStatus.Undone: iconId = 'iconStatusUndo'; break;

        default: iconId = 'iconStatusInProgress'; break;
    }

    let spin = iconId === 'iconStatusInProgress';
    return <Icon id={`${iconId}-${theme}`} className={cx({ icon24: true, spinningIcon: spin, inProgressIcon: spin })} title={title} />;
};

export const JobsTableRow = ({ t, theme, rowIndex, job, expanded, onClickRow, actionButton }) => {
    let { alert, name, type, topLevelStatus, created, startTime, recentlyModified } = job;

    return <tr
        tabIndex={0}
        className={cx('row', 'bodyRow', { bodyRowExpanded: expanded, recentlyModified })}
        onClick={() => onClickRow(rowIndex)}
        onKeyDown={(e) => { if (!Utils.isKeyDownAndNotEnter(e)) { onClickRow(rowIndex) } }}>
        <td className={cx('expand', 'tableCell')}>
            <button
                title={t('expandCollapse')}
                aria-label={t('expandCollapse')}
                aria-controls={name + "_details"}
                aria-expanded={expanded}>
                <Icon
                    id={cx('caret-down')}
                    className={cx('icon12', 'expandIcon', { rotated: expanded })} />
            </button>
        </td>
        <JobsTableCell sortable={true} center={true}>
            <StatusIcon status={topLevelStatus} theme={theme} title={t(`jobs.status.${topLevelStatus}`)} />
        </JobsTableCell>
        <td className={cx('alert', 'tableCell')}>
            {alert && <TooltipableContainer className={cx('warningTooltip')} tooltip={t('jobs.warningTooltip')} position="top">
                <Icon
                    id={`iconStatusWarning-${theme}`}
                    className={cx('icon24')} />
            </TooltipableContainer>}
        </td>
        <JobsTableCell sortable={true}>{formatDate(created)}</JobsTableCell>
        <JobsTableCell sortable={true}>{name}</JobsTableCell>
        <JobsTableCell sortable={true}>{type}</JobsTableCell>
        <JobsTableCell sortable={true}>{formatDate(startTime)}</JobsTableCell>
        <td>
            {actionButton}
        </td>
    </tr>
};

const JobsTableCell = ({ sortable, center = false, children }) => <td className={cx({ tableCell: true, sortableCell: sortable })}>
    <span className={cx('cellWrapper', { center })}>
        <span>{children}</span>
    </span>
</td>;

const RelatedJobInfo = ({ t, theme, type, name, status, tasksCompleted, totalTasks, created, startTime, endTime, errorType, showAction, handleOnViewLogs, setJobLogsModalSourceElement }) => {
    const viewLogsButtonRef = React.useRef(null);

    return <div className={cx('relatedJobCard', { successfulJob: status === JobStatus.Succeeded, failedJob: status === JobStatus.Failed || status === JobStatus.RolledBack })}>
        <div className={cx('relatedJob')}>
            <div className={cx('jobHeader')}>
                <div className={cx('statusSummary')}>
                    <StatusIcon status={status === JobStatus.Succeeded && type === JobType.Undo ? JobStatus.Undone : status} theme={theme} title={t(`jobs.status.${status}`)} />
                    <span className={cx('mainLabel')}>{type}</span>
                </div>
                <div className={cx('detailsField')}>
                    {t(`jobs.status.${status}`)} ({tasksCompleted}/{totalTasks} {t('jobs.progress')})
                </div>
            </div>
            <div className={cx('detailsField')}>
                <span className={cx('label')}>{t('jobs.table.name')}</span>
                <span className={cx('value')} title={name}>{name}</span>
            </div>
            <div className={cx('detailsField')}>
                <span className={cx('label')}>{t('jobs.table.created')}</span>
                <span className={cx('value')}>{formatDate(created)}</span>
            </div>
            <div className={cx('detailsField')}>
                <span className={cx('label')}>{t('jobs.table.startTime')}</span>
                <span className={cx('value')}>{formatDate(startTime)}</span>
            </div>
            <div className={cx('detailsField')}>
                <span className={cx('label')}>{t('jobs.table.endTime')}</span>
                <span className={cx('value')}>{formatDate(endTime)}</span>
            </div>
            {(errorType !== JobsErrorCode.None) && <div className={cx('detailsField')}>
                <span className={cx('label')}>{t('jobs.error')} </span>
                <span className={cx('value', 'errorMessage')}>
                    <span>{t(`jobs.errors.${errorType}`)}</span>
                    {showAction && <span> {t(`jobs.errors.${errorType}Action`)}</span>}
                </span>
            </div>}
        </div>
        <div>
            <button ref={viewLogsButtonRef} className={cx('viewLogsButton')} onClick={() => handleOnViewLogs(name, viewLogsButtonRef)}>
                {t('jobs.table.logs')}
            </button>
        </div>
    </div>
};

export const JobsDetailsSlideout = ({
    t,
    theme,
    actionButton,
    job,
    handleOnViewLogs,
    setJobLogsModalSourceElement }) => {
    const { alert,
        name,
        status,
        type,
        tasksCompleted,
        totalTasks,
        importSourceFolder,
        importTimestampProperty,
        errorType,
        childJobs,
        created,
        startTime,
        endTime } = job as JobInfo;

    let topLevelErrorType = errorType;

    if (childJobs && childJobs.length > 0) {
        let latest = childJobs[childJobs.length - 1];
        topLevelErrorType = latest.errorType;
    }

    return <div className={cx('jobSlideout')} id={name + "_details"}>
        <div className={cx('allDetails')}>
            <div className={cx('jobsSlideoutHeader')}>{t('jobs.jobInfo')}</div>
            <div className={cx('allDetailsFields')}>
                <div className={cx('detailsField')}>
                    <span className={cx('label')}>{t('jobs.name')}</span>
                    <span className={cx('value')} title={name}>{name}</span>
                </div>
                <div className={cx('detailsField')}>
                    <span className={cx('label')}>{t('jobs.importPath')}</span>
                    <span className={cx('value')}>{importSourceFolder}</span>
                </div>
                <div className={cx('detailsField')}>
                    <span className={cx('label')}>{t('jobs.timestamp')}</span>
                    <span className={cx('value')}>{importTimestampProperty}</span>
                </div>
                {(topLevelErrorType !== JobsErrorCode.None) &&
                    <div className={cx(alert ? 'withAlert' : '')}>
                        <div className={cx('detailsField')}>
                            <span className={cx('label')}>{t('jobs.error')}</span>
                            <span className={cx('value', 'errorMessage')}>
                                <span>{t(`jobs.errors.${topLevelErrorType}`)} </span>
                                {!alert && <span>{t(`jobs.errors.${topLevelErrorType}Action`)}</span>}
                                {alert && <span className={cx('uncleanStateAction')}>{t('jobs.errors.UncleanStateAction')}</span>}
                            </span>
                        </div>
                        {actionButton &&
                            <div className={cx('detailsField')}>
                                <span className={cx('value', 'actionButton')}>{actionButton}</span>
                            </div>}
                    </div>
                }
            </div>
        </div>
        <div className={cx('relatedInfo')}>
            <div className={cx('jobsSlideoutHeader', 'jobsHistory')}>{t('jobs.history')}</div>
            <div className={cx('timelineContainer')}>
                <RelatedJobInfo
                    key={`job-${name}-original`}
                    t={t}
                    theme={theme}
                    type={type}
                    name={name}
                    status={status}
                    tasksCompleted={tasksCompleted} totalTasks={totalTasks}
                    created={created}
                    startTime={startTime}
                    endTime={endTime}
                    errorType={errorType}
                    showAction={childJobs && childJobs.length === 0 && !alert}
                    handleOnViewLogs={handleOnViewLogs}
                    setJobLogsModalSourceElement={setJobLogsModalSourceElement} />
                {childJobs && childJobs.map((childJob, index) =>
                    [
                        <div key={`job-${name}-arrow-${index}`} className={cx('rightArrow')}><Icon id="rightArrow" /></div>,
                        <RelatedJobInfo
                            key={`job-${name}-child-${index}`}
                            t={t}
                            theme={theme}
                            showAction={index === childJobs.length - 1 && !alert}
                            handleOnViewLogs={handleOnViewLogs}
                            setJobLogsModalSourceElement={setJobLogsModalSourceElement}
                            {...childJob} />
                    ]
                )}
            </div>
        </div>
    </div>;
};
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import ChartCommanding from './ChartCommanding';
import { getTheme, getSelectedEnvironmentIsLts, getSelectedEnvironment } from '../../state/reducers/AppSettingsReducer';
import { getActiveQueries, getMaintainedIntervalSize, getUnzoomStack, getChartType, getModalOptions, getLinechartStackState, 
    getActiveVisualizationReference, getValidBucketSizes, getSearchSpan, getEnvironmentHasWarmStore, getFocusAddMarkerButtonTrigger, getIsGetSeriesEnabled, getJumbotronActive, getTimeSeriesQueries } from '../../state/reducers/AnalyticsReducer';
import { APPLY_SEARCH_SPAN, SET_MAINTAIN_INTERVAL, ZOOM, SET_CHART_TYPE, SET_MODAL_OPTIONS, CLOSE_MODAL, SET_LINECHART_STACK_STATE, SET_SEARCHSPAN, 
        GET_QUERYRESULTS, GET_EVENTS, SET_FOCUS_ON_ADD_MARKER_BUTTON_TRIGGER, SET_IS_GET_SERIES_ENABLED, UPDATE_JUMBOTRON_STATE, SET_CHART_SETTINGS_STATE } from '../../../constants/ActionTypes';
import { CollapseMode, ChartSettingsTypes, QueryTypes } from '../../../constants/Enums';
import Utils from '../../services/Utils';

const mapStateToProps = state => {
    return { 
        theme: getTheme(state),
        queries: getActiveQueries(state),
        maintainedIntervalSize: getMaintainedIntervalSize(state),
        unzoomStack: getUnzoomStack(state),
        chartType: getChartType(state),
        modalOptions: getModalOptions(state),
        activeVisualizationReference: getActiveVisualizationReference(state),
        linechartStackState: getLinechartStackState(state),
        environmentIsLts: getSelectedEnvironmentIsLts(state),
        timeSeriesQueries: getActiveQueries(state),
        selectedEnvironment: getSelectedEnvironment(state),
        validBucketSizes: getValidBucketSizes(state),
        searchSpan: getSearchSpan(state),
        environmentHasWarmStore : getEnvironmentHasWarmStore(state),
        focusAddMarkerButtonTrigger: getFocusAddMarkerButtonTrigger(state),
        isJumbotronActive: getJumbotronActive(state),
        isGetSeriesEnabled: getIsGetSeriesEnabled(state),
        queriesType: getTimeSeriesQueries(state)?.length ? QueryTypes.TimeSeries : QueryTypes.Aggregates
    };
};

const mapDispatchToProps = dispatch => ({ 
    zoom: (fromMillis, toMillis, direction = null) => dispatch({type: ZOOM, payload: {from: fromMillis, to: toMillis, direction: direction}}),
    setMaintainedIntervalSize: (intervalSize = null) => dispatch({type: SET_MAINTAIN_INTERVAL, payload: {maintainedIntervalSize: intervalSize}}),
    applySearchSpan: () => dispatch({type: APPLY_SEARCH_SPAN}),
    setChartType: (chartType) => dispatch({type: SET_CHART_TYPE, payload: chartType}),
    setModalOptions: (tsqI, focusModalX, focusModalY, modalType, fromWell, caretDirection, sourceElement) => {
        let options = {tsqI, focusModalX, focusModalY, modalType, fromWell, caretDirection, sourceElement};
        dispatch({type: SET_MODAL_OPTIONS, payload: options});
    },
    closeModal: () => {dispatch({type: CLOSE_MODAL})},
    setStackState: (newState) => {dispatch({type: SET_LINECHART_STACK_STATE, payload: newState})},
    setSearchSpan: (from, to, bucketSize, validBucketSizes) => dispatch({type: SET_SEARCHSPAN, payload: {from, to, bucketSize, validBucketSizes}}),
    getQueryResults: () => dispatch({type: GET_QUERYRESULTS, payload: {unsetUnzoomStack: true}}),
    getEvents: (predicateObject, options, minMillis, maxMillis) => dispatch({type: GET_EVENTS, payload: {
        predicateObject: predicateObject, 
        options: options, 
        minMillis: minMillis, 
        maxMillis: maxMillis
    }}),
    unsetFocusAddMarkerButtonTrigger: () => {
        dispatch({type: SET_FOCUS_ON_ADD_MARKER_BUTTON_TRIGGER, payload: null});
    },
    setChartSettingsToBasics: () => {
        dispatch({type: SET_CHART_SETTINGS_STATE, payload: {
            isOpen: true,
            type: ChartSettingsTypes.Basics
        }})
    },
    toggleJumbo: () => {
        dispatch({type: UPDATE_JUMBOTRON_STATE, payload: {target: CollapseMode.toggle}});
        Utils.triggerResize(500);
    },
    setIsGetSeriesEnabled: (payload: boolean) => {
        dispatch({type: SET_IS_GET_SERIES_ENABLED, payload});
    }
});

const ChartCommandingContainer = translate()(connect(mapStateToProps, mapDispatchToProps)(ChartCommanding));

export default ChartCommandingContainer;

import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import Heatmap from './Heatmap';
import { getTheme, getSelectedEnvironmentIsLts } from '../../state/reducers/AppSettingsReducer';
import { getAvailabilityIsCompact, getTimezone, getActiveQueries, getMaintainedIntervalSize, getIsLoadingAggregates } from '../../state/reducers/AnalyticsReducer';
import { SET_FOCUS_AND_STICKY_TRIGGERS, SET_ACTIVE_VISUALIZATION_REFERENCE } from '../../../constants/ActionTypes';

const mapStateToProps = state => {
    return { 
        theme: getTheme(state),
        maintainedIntervalSize: getMaintainedIntervalSize(state),
        tall: getAvailabilityIsCompact(state),
        queries: getActiveQueries(state),
        environmentIsLts: getSelectedEnvironmentIsLts(state),
        isLoadingAggregates: getIsLoadingAggregates(state),
        timezone: getTimezone(state)
    };
};


const mapDispatchToProps = dispatch => ({
    setFocusTriggers: (focusTrigger, unfocusTrigger, stickyTrigger) => dispatch({type: SET_FOCUS_AND_STICKY_TRIGGERS, payload: 
        {valueElementFocusTrigger: focusTrigger, valueElementUnfocusTrigger: unfocusTrigger, valueElementStickyTrigger: stickyTrigger}}),
    setActiveChartReference: (ref) => {
        dispatch({type: SET_ACTIVE_VISUALIZATION_REFERENCE, payload: ref});
    }
});

const HeatmapContainer = translate()(connect(mapStateToProps, mapDispatchToProps)(Heatmap));

export default HeatmapContainer;

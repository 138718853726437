import * as React from 'react';
import classNames from 'classnames/bind';
import Icon from '../../components/Icon/Icon';
import SortableTableContainer from '../../components/SortableTable/SortableTable.container';
import Placeholder from '../../components/Placeholder/Placeholder';
import { SortableTableColumn, SortOrder } from '../../components/SortableTable/SortableTable';
import { JobInfo, JobStatus, JobsPageStatus } from '../../models/JobsModels';
import { ConfirmActionModal, AddNewJobModal, JobsLogsModal } from './JobsModals';
import { JobsTableRow, JobsDetailsSlideout } from './JobsTableComponents';
import EnvironmentsContainer from '../../components/Environments/Environments.container';
import Utils from '../../services/Utils';
import SearchboxContainer from '../../components/Searchbox/Searchbox.container';
const cx = classNames.bind(require('./Jobs.module.scss'));

interface Props {
  t: any,
  theme: string,
  status: JobsPageStatus,
  jobs: Array<any>,
  sortOrder: SortOrder,
  sortedColumnIndex: number,
  callFailedWith500: boolean,
  jobLogs: Array<JobLogs>,
  jobLogsErrorMessage: string
  getAllJobs: () => any[],
  refreshJobs: () => any[],
  createImportJob: (jobParameters: any) => {},
  undoJob: (jobName: string, sourceJobName: string) => {},
  cancelJob: (jobName: string) => {},
  updateSortOrder: (sortOrder: SortOrder, sortedColumnIndex: number) => void,
  getJobLogs: (jobName: string) => {},
  openUserSettings: any,
  isUserContributor: boolean
}

interface State {
  showAddNewModal: boolean,
  showConfirmActionModal: boolean,
  showLogsModal: boolean,
  newJobName: string,
  newJobImportPath: string,
  newJobTimestampProperty: string,
  jobNameErrorMessage: string,
  importPathErrorMessage: string,
  timestampErrorMessage: string,
  actionModalTitle: string,
  actionModalDescription: string,
  actionModalWarning: string,
  actionModalCallback: Function,
  actionModalSourceElement: any,
  searchTerm: string,
  expandedRows: Array<number>,
  selectedJobName: string,
  jobLogsModalSourceElement: any
}

export const JobsColumns: Array<SortableTableColumn> = [
  { key: 'status', i18nKey: 'jobs.table.status', isSortable: true, style: { width: '60px' } },
  { key: 'alert', i18nKey: 'jobs.table.alert', isSortable: true, style: { width: '52px' } },
  { key: 'created', i18nKey: 'jobs.table.created', isSortable: true, isDateOrNumber: true, style: { width: '180px' } },
  { key: 'name', i18nKey: 'jobs.table.name', isSortable: true, style: { minWidth: '300px' } },
  { key: 'type', i18nKey: 'jobs.table.type', isSortable: true, style: { width: '110px' } },
  { key: 'startTime', i18nKey: 'jobs.table.startTime', isSortable: true, isDateOrNumber: true, style: { width: '180px' } },
  { key: 'action', i18nKey: 'jobs.table.action', isSortable: false, style: { width: '100px' } }
];

interface JobLogs {
  taskName: string;
  taskLogs: Array<string>;
  taskStatus: string;
}

export default class Jobs extends React.Component<Props, State> {
  private addNewButtonRef;
  private refreshInterval: number = null;
  private refreshDurationMs = 15 * 1000;

  constructor(props) {
    super(props);
    this.addNewButtonRef = React.createRef();

    this.state = {
      showAddNewModal: false,
      showConfirmActionModal: false,
      showLogsModal: false,
      newJobName: '',
      newJobImportPath: '',
      newJobTimestampProperty: '',
      jobNameErrorMessage: '',
      importPathErrorMessage: '',
      timestampErrorMessage: '',
      actionModalTitle: '',
      actionModalDescription: '',
      actionModalWarning: '',
      actionModalSourceElement: null,
      actionModalCallback: () => { },
      searchTerm: '',
      expandedRows: [],
      selectedJobName: '',
      jobLogsModalSourceElement: null
    };
  }

  handleOnVisibilityChange = () => {
    if(document.hidden) {
      this.pauseJobsLongPoll();
    } else {
      this.startJobsLongPoll();
    }
  }

  componentDidMount() {
    this.startJobsLongPoll();

    document.addEventListener("visibilitychange", this.handleOnVisibilityChange);
  }

  componentWillUnmount() {
    this.pauseJobsLongPoll();

    // Remove visibility change handler to avoid having multiple copies of the same handler.
    document.removeEventListener("visibilitychange", this.handleOnVisibilityChange);
  }

  componentDidUpdate() {
    // If the server failed with a 500 status code, the issue is mostly likely a non-temporary
    // one, such as the user having selected a region that doesn't have a jobs cluster. In this
    // case, polling the server for updates won't fix the issue.
    // Once the backend sends a more specific error message, we can modify this logic so not all 
    // 500 status codes cause a long polling to pause.
    if(this.props.callFailedWith500) {
      this.pauseJobsLongPoll();
    }
  }

  startJobsLongPoll() {
    this.refreshInterval = window.setInterval(() => {
      this.props.refreshJobs();
    }, this.refreshDurationMs);
  }

  pauseJobsLongPoll() {
    if(this.refreshInterval) {
      window.clearInterval(this.refreshInterval);
    }
  }

  handleOnClickAddNew = () => {
    this.setState({
      showAddNewModal: true
    });
  }

  handleOnCloseAddNew = () => {
    this.setState({
      showAddNewModal: false
    });
  }

  handleOnClickRefresh = () => {
    this.props.refreshJobs();

    // Restart polling to avoid back-to-back updates.
    this.pauseJobsLongPoll();
    this.startJobsLongPoll();
  }

  handleOnChangeNewJobName = (event) => {
    let newJobName = event.target.value;
    let jobNameErrorMessage = this.validateNameField(newJobName);

    this.setState({ newJobName, jobNameErrorMessage });
  }

  handleOnChangeNewJobImportPath = (event) => {
    let newJobImportPath = event.target.value;
    let importPathErrorMessage = this.validateRequiredField(newJobImportPath);

    this.setState({ newJobImportPath, importPathErrorMessage });
  }

  handleOnChangeNewJobTimestampProperty = (event) => {
    let newJobTimestampProperty = event.target.value;
    let timestampErrorMessage = this.validateRequiredField(newJobTimestampProperty);

    this.setState({ newJobTimestampProperty, timestampErrorMessage });
  }

  validateNameField(value) {
    const isNameValid = (value) => {
      let regexp = /^[a-zA-Z0-9-_.]+$/; // alpha-numeric, dots, dashes, and underscores
      return value.search(regexp) !== -1;
    }

    // Check for duplicate job name
    let duplicateName = false;
    this.props.jobs.forEach(job => {
      if(job.name === value){
        duplicateName = true;
      }
    })

    if(duplicateName){
      return this.props.t('jobs.duplicateName');
    }

    // Names must be <= 100 characters (28 chars left for -undo-guid string)
    if(value.length > 100){
      return this.props.t('jobs.nameToLong');
    }

    return !value || !value.trim() || !isNameValid(value) ? this.props.t('jobs.requiredNameField') + 'A-Z a-z 0-9 . _ -' : '';
  }

  validateRequiredField(value) {
    return !value || !value.trim() ? this.props.t('jobs.fillRequiredField') : '';
  }

  handleOnCreateImportJob = () => {
    const { newJobName, newJobImportPath, newJobTimestampProperty } = this.state;

    // Make sure input is valid
    let jobNameErrorMessage = this.validateNameField(newJobName);
    let importPathErrorMessage = this.validateRequiredField(newJobImportPath);
    let timestampErrorMessage = this.validateRequiredField(newJobTimestampProperty);

    if(jobNameErrorMessage || importPathErrorMessage || timestampErrorMessage) {
      this.setState({ jobNameErrorMessage, importPathErrorMessage, timestampErrorMessage });
      return;
    }

    this.props.createImportJob({
      jobName: newJobName,
      importSourceFolder: newJobImportPath,
      importTimestampProperty: newJobTimestampProperty
    });

    this.setState({
      showAddNewModal: false,
      newJobName: '',
      newJobImportPath: '',
      newJobTimestampProperty: '',
      jobNameErrorMessage: '',
      importPathErrorMessage: '',
      timestampErrorMessage: ''
    })
  }

  handleOnClickSort = (columnKey) => {
    const { sortOrder, sortedColumnIndex, updateSortOrder } = this.props;
    const columnIndex = JobsColumns.findIndex(column => column.key === columnKey);

    let newOrder = sortedColumnIndex === columnIndex && sortOrder === SortOrder.Descending
      ? SortOrder.Ascending
      : SortOrder.Descending;

    // Collapse rows so we don't expand incorrect rows after sorting
    this.setState({
      expandedRows: []
    });

    updateSortOrder(newOrder, columnIndex);
  }

  handleOnClickRow = (rowIndex) => {
    let index = this.state.expandedRows.indexOf(rowIndex);

    this.setState(prevState => {
      let expandedRows = [...prevState.expandedRows];

      if(index === -1) {
        expandedRows.push(rowIndex);
      } else {
        expandedRows.splice(index, 1);
      }

      return { expandedRows };
    });
  }

  handleOnCloseActionModal = () => {
    this.setState({
      showConfirmActionModal: false,
      actionModalTitle: '',
      actionModalDescription: '',
      actionModalCallback: () => { }
    });
  }

  handleOnViewLogs = (jobName: string, viewLogsButtonRef: any) => {
    this.props.getJobLogs(jobName);
    this.setState({ 
      showLogsModal: true,
      selectedJobName: jobName,
      jobLogsModalSourceElement: viewLogsButtonRef
    });
  }

  createActionButtonForJob = (job: JobInfo) => {
    let onClickHandler;

    // Non-contributors cannot apply job actions
    if(!this.props.isUserContributor) {
      return null;
    }

    // If the original job ended more than 30 days ago, do not allow job actions. This may could be relaxed
    // for future job types, but for now we play it safe and disallow actions for any job that is older than
    // 30 days.
    if(job.endTime && Utils.calcDiffBetweenDatesInDays(job.endTime, new Date()) > 30) {
      return null;
    }

    // If the job has been completed, for some scenarios allow the user to undo it.
    if(job.status === JobStatus.Succeeded || job.status === JobStatus.Failed) {
      // Only allow undo if the last child job failed.
      let latestChild = job.childJobs.length === 0 ? null : job.childJobs[job.childJobs.length - 1];
      if(latestChild && latestChild.status !== JobStatus.Failed) {
        return null;
      }

      onClickHandler = (event) => {
        if(Utils.isKeyDownAndNotEnter(event)) { return; }
        event.stopPropagation();

        this.setState({
          showConfirmActionModal: true,
          actionModalTitle: this.props.t('jobs.actions.undoJobTitle'),
          actionModalDescription: this.props.t('jobs.actions.undoJobDescription'),
          actionModalWarning: this.props.t('jobs.actions.undoJobWarning'),
          actionModalSourceElement: event.currentTarget,
          actionModalCallback: () => {
            // Using {undo-[length 5 guid]-jobName} for undo job name.
            this.props.undoJob(`undo-${Utils.guid().slice(0,5)}-${job.name}`.slice(0,128), job.name);
            this.handleOnCloseActionModal();
          }
        });
      }

      return <button className={cx('secondaryButton')} onClick={onClickHandler} onKeyDown={onClickHandler} aria-label={this.props.t('jobs.actions.undo')}>
        <span>{this.props.t('jobs.actions.undo')}</span>
      </button>;
    }

    // If the job is still running, allow the user to cancel it.
    if(job.status === JobStatus.Queued || job.status === JobStatus.Running) {
      onClickHandler = (event) => {
        if(Utils.isKeyDownAndNotEnter(event)) { return; }
        event.stopPropagation();

        this.setState({
          showConfirmActionModal: true,
          actionModalTitle: this.props.t('jobs.actions.cancelJobTitle'),
          actionModalDescription: this.props.t('jobs.actions.cancelJobDescription'),
          actionModalSourceElement: event.currentTarget,
          actionModalCallback: () => {
            this.props.cancelJob(job.name);
            this.handleOnCloseActionModal();
          }
        });
      }

      return <button className={cx('secondaryButton')} onClick={onClickHandler} onKeyDown={onClickHandler} aria-label={this.props.t('jobs.actions.cancel')}>
        <span>{this.props.t('jobs.actions.cancel')}</span>
      </button>;
    }

    return null;
  }

  handleOnChangeSearchTerm = (event) => {
    this.setState({
      searchTerm: event.target.value,
      expandedRows: []
    });
  }

  sortAndfilterJobList() {
    const parentJobs = this.props.jobs;
    const { sortOrder, sortedColumnIndex } = this.props;

    let regex = new RegExp(this.state.searchTerm, 'gi');
    let filtered = this.state.searchTerm === ''
      ? [...parentJobs]
      // Search for items in the name column
      : parentJobs.filter(job => (job.name && job.name.match(regex)));

    let columnKey = JobsColumns[sortedColumnIndex].key;
    if(columnKey === 'status') {
      columnKey = 'topLevelStatus';
    }

    let sortFunction;
    if(JobsColumns[sortedColumnIndex].isDateOrNumber) {
      sortFunction = sortOrder === SortOrder.Ascending
        ? (a, b) => a[columnKey] - b[columnKey]
        : (a, b) => b[columnKey] - a[columnKey];
    } else {
      sortFunction = sortOrder === SortOrder.Ascending
        ? (a, b) => String(a[columnKey] || '').localeCompare(String(b[columnKey] || ''))
        : (a, b) => String(b[columnKey] || '').localeCompare(String(a[columnKey] || ''));
    }

    filtered.sort(sortFunction);

    return filtered;
  }

  formatJobLogs = (jobLogs: Array<JobLogs>, includeStyling: boolean): Array<string> => {
    if (jobLogs === null) { return null; }

    let result = [];
    const logDelimiter = "************************************************************";

    jobLogs.forEach((logs: JobLogs) => {
      let taskName = includeStyling ? { styling: { fontWeight: 'bold' }, content: logs.taskName } : logs.taskName;
      result = result.concat([
        logDelimiter,
        taskName,
        logDelimiter,
        ...logs.taskLogs,
        // Logs are not localized, hence why we use a hard-coded string here.
        `Task status: ${logs.taskStatus}`,
        ''
      ]);
    });
      
    return result;
  }

  handleDownloadLogs = () => {
    const logsString = this.formatJobLogs(this.props.jobLogs, false).join('\n');
    const logsBlob = new Blob([logsString], { type: 'text/plain '});
    const blobUrl = URL.createObjectURL(logsBlob);
    const link = document.createElement("a");
    link.setAttribute("href", blobUrl);
    link.setAttribute("download", 'job_logs.txt');
    link.setAttribute("tabindex", "0");
    link.innerHTML= "";
    let linkElement = document.body.appendChild(link);
    link.click();
    document.body.removeChild(linkElement);
  }

  setJobLogsModalSourceElement = element => {
    this.setState({ jobLogsModalSourceElement: element });
  }

  render() {
    const { t, theme, status, jobs, sortOrder, sortedColumnIndex } = this.props;

    return <div className={cx('jobsPage')}>
      <EnvironmentsContainer openUserSettings={this.props.openUserSettings} />
      {this.state.showAddNewModal &&
        <AddNewJobModal
          t={t}
          handleOnClose={this.handleOnCloseAddNew}
          handleOnSave={this.handleOnCreateImportJob}
          jobName={this.state.newJobName}
          importPath={this.state.newJobImportPath}
          timestamp={this.state.newJobTimestampProperty}
          handleOnJobNameChange={this.handleOnChangeNewJobName}
          handleOnImportPathChange={this.handleOnChangeNewJobImportPath}
          handleOnTimestampChange={this.handleOnChangeNewJobTimestampProperty}
          jobNameErrorMessage={this.state.jobNameErrorMessage}
          importPathErrorMessage={this.state.importPathErrorMessage}
          timestampErrorMessage={this.state.timestampErrorMessage}
          sourceElement={this.addNewButtonRef.current} />}

      {this.state.showConfirmActionModal &&
        <ConfirmActionModal
          t={t}
          theme={theme}
          title={this.state.actionModalTitle}
          description={this.state.actionModalDescription}
          warning={this.state.actionModalWarning}
          handleOnClickConfirm={this.state.actionModalCallback}
          handleOnClose={this.handleOnCloseActionModal}
          sourceElement={this.state.actionModalSourceElement} />}

      {this.state.showLogsModal &&
        <JobsLogsModal
          t={t}
          theme={theme}
          logContents={this.formatJobLogs(this.props.jobLogs, true)}
          errorMessage={this.props.jobLogsErrorMessage}
          handleOnClose={() => this.setState({ showLogsModal: false })}
          handleOnRefresh={() => this.props.getJobLogs(this.state.selectedJobName)}
          handleOnDownload={this.handleDownloadLogs}
          sourceElement={this.state.jobLogsModalSourceElement} 
          jobName={this.state.selectedJobName}/>}

      <div className={cx('commands')}>
        <div className={cx('commandButtons')}>
          { this.props.isUserContributor &&
            <button ref={this.addNewButtonRef} className={cx('tsiButton')} onClick={this.handleOnClickAddNew} aria-label={t('jobs.addNew')}>
              <Icon id={'iconAdd-' + theme} className={cx('icon16')} />
              <span>{t('jobs.addNew')}</span>
            </button>
          }
          <button className={cx('tsiButton')} onClick={this.handleOnClickRefresh} aria-label={t('refresh')}>
            <Icon id={'refresh-' + theme} className={cx({ icon16: true, spinningIcon: status === JobsPageStatus.Loading })} />
            <span>{t('refresh')}</span>
          </button>
        </div>
        <div className={cx('searchboxArea')}>
          <SearchboxContainer
            placeholder={t('search')}
            value={this.state.searchTerm}
            handleOnChange={this.handleOnChangeSearchTerm} />
        </div>
      </div>

      <div className={cx('main')}>
        {status === JobsPageStatus.Loading && <Placeholder visible={status === JobsPageStatus.Loading}>
          {t('jobs.loading')}
        </Placeholder>}

        {((status === JobsPageStatus.Loaded || status === JobsPageStatus.Refreshing) && jobs.length > 0)
          && <SortableTableContainer
            columns={JobsColumns}
            rows={this.sortAndfilterJobList()}
            handleOnSort={this.handleOnClickSort}
            canExpandRows={true}
            expandedRowIndices={this.state.expandedRows}
            sortOrder={sortOrder}
            sortColumnIndex={sortedColumnIndex}
            handleOnClickRow={this.handleOnClickRow}
            renderRow={(job, index, expanded, selected, handleOnClick) =>
              <JobsTableRow
                theme={theme}
                key={`jobstablerow-${job.name}-${job.status}`}
                t={t}
                job={job}
                rowIndex={index}
                expanded={expanded} onClickRow={handleOnClick}
                actionButton={this.createActionButtonForJob(job)} />}
                renderExpandedRowContents={job =>
                  <JobsDetailsSlideout 
                    t={t} 
                    theme={theme} 
                    actionButton={this.createActionButtonForJob(job)} 
                    job={job} 
                    handleOnViewLogs={this.handleOnViewLogs}
                    setJobLogsModalSourceElement={this.setJobLogsModalSourceElement}/>}
          />}

        {((status === JobsPageStatus.Loaded || status === JobsPageStatus.Refreshing) && jobs.length === 0)
          && <Placeholder visible={jobs.length === 0}>{t('jobs.noJobs')}</Placeholder>}

        {status === JobsPageStatus.Error && <Placeholder visible={status === JobsPageStatus.Error}>
          {this.props.callFailedWith500 ? t('jobs.errorLoadingNoRetry') : t('jobs.errorLoading')}
        </Placeholder>}
      </div>

      <div className={cx('footer')}></div>
    </div>;
  }
}
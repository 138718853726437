import * as React from 'react';
import classNames from 'classnames/bind';
import ModalContainer from '../Modal.container';
const cx = classNames.bind(require('./SidePane.module.scss'));

const SidePane = ({ title, handleOnClose, wrapperClassName = '', panePositionClassName = '', className='', titleIconId = null, children }) => 
<ModalContainer 
  className={cx('sidePane', className)} 
  contentPositionContainerClassName={cx('panePosition', panePositionClassName)}
  wrapperClassName={cx('paneWrapper', wrapperClassName)}
  contentClassName={cx('paneContent')}
  title={title} 
  titleIconId={titleIconId}
  onClose={handleOnClose}>
  { children }
</ModalContainer>;

export default SidePane;
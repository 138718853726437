import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import Jobs from './Jobs';
import { getTheme } from '../../state/reducers/AppSettingsReducer';
import {
    getJobsPageStatus,
    getJobsList,
    getJobsSortOrder,
    getJobsSortedColumIndex,
    getCallFailedWith500,
    getJobLogs,
    getJobLogsErrorMessage
} from '../../state/reducers/JobsReducer';
import { GET_ALL_JOBS, UPDATE_SORT_ORDER, CREATE_JOB, MODIFY_JOB, GET_JOB_LOGS } from '../../../constants/ActionTypes';
import { JobType } from '../../models/JobsModels';
import { getIsUserContributor } from '../../state/reducers/AppSettingsReducer'

const mapStateToProps = state => ({
    theme: getTheme(state),
    status: getJobsPageStatus(state),
    jobs: getJobsList(state),
    sortOrder: getJobsSortOrder(state),
    sortedColumnIndex: getJobsSortedColumIndex(state),
    callFailedWith500: getCallFailedWith500(state),
    jobLogs: getJobLogs(state),
    jobLogsErrorMessage: getJobLogsErrorMessage(state),
    isUserContributor: getIsUserContributor(state)
});

const mapDispatchToProps = dispatch => ({
    getAllJobs: (): Array<any> => dispatch({ type: GET_ALL_JOBS }),
    refreshJobs: (): Array<any> => dispatch({ type: GET_ALL_JOBS, payload: { isRefresh: true } }),
    createImportJob: ({jobName, importSourceFolder, importTimestampProperty}) => dispatch({
        type: CREATE_JOB,
        payload: {
            jobName,
            requestBody: {
                type: JobType.Import,
                jobParameters: {
                    importSourceFolder,
                    importTimestampProperty
                }
            }
        }
    }),
    undoJob: (jobName: string, sourceJobName: string) => dispatch({
        type: CREATE_JOB,
        payload: {
            jobName,
            requestBody: {
                type: JobType.Undo,
                jobParameters: {
                    sourceJobName
                }
            }
        }
    }),
    cancelJob: (jobName) => dispatch({
        type: MODIFY_JOB,
        payload: {
            jobName,
            action: 'cancel'
        }
    }),
    updateSortOrder: (sortOrder, sortedColumnIndex) => dispatch({
        type: UPDATE_SORT_ORDER,
        payload: {
            sortOrder,
            sortedColumnIndex
        }
    }),
    getJobLogs: (jobName) => dispatch({ 
        type: GET_JOB_LOGS,
        payload: { jobName } 
    })
});

const JobsContainer = translate()(connect(mapStateToProps, mapDispatchToProps)(Jobs));

export default JobsContainer;
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import LineChart from './LineChart';
import {GET_EVENTS, ZOOM, SET_MAINTAIN_INTERVAL, APPLY_SEARCH_SPAN, SET_CHART_TYPE, SET_MARKERS, SET_FOCUS_AND_STICKY_TRIGGERS, 
    STICKY_TIMESERIESQUERY, UNSTICKY_TIMESERIESQUERIES, SHOW_HIDE_TIMESERIESQUERY, REMOVE_TIMESERIESQUERY,
    SET_ACTIVE_VISUALIZATION_REFERENCE, GET_BRUSHED_REGION_STATISTICS, SET_FOCUS_ON_ADD_MARKER_BUTTON_TRIGGER, SERIALIZE_STATE_TO_LOCAL_STORAGE, 
    REMOVE_AGGREGATESQUERY, GET_QUERYRESULTS, SET_QUERY_FIELD, ADD_AGGREGATESQUERY, SET_AGGREGATESQUERY, SET_CHART_SETTINGS_STATE} from '../../../constants/ActionTypes';
import { getTheme, getSelectedEnvironmentIsLts } from '../../state/reducers/AppSettingsReducer';
import { getAvailabilityIsCompact, getTimezone, getActiveQueries, getMaintainedIntervalSize, getMarkers, 
    getLinechartStackState, getSwimLaneOptions, getIsLoadingAggregates, getMetadata, getShouldSticky, getIsSeriesLabelsEnabled, getJumbotronState } from '../../state/reducers/AnalyticsReducer';
import { QueryTypes, ChartSettingsTypes, CollapsableComponents } from '../../../constants/Enums';
import { ChartSettingsState } from '../../models/Interfaces';

const mapStateToProps = state => {
    return { 
        theme: getTheme(state),
        maintainedIntervalSize: getMaintainedIntervalSize(state),
        events: state.analytics.events,
        tall: getAvailabilityIsCompact(state),
        queries: getActiveQueries(state),
        environmentIsLts: getSelectedEnvironmentIsLts(state),
        isLoadingAggregates: getIsLoadingAggregates(state),
        timezone: getTimezone(state),
        markers: getMarkers(state),
        linechartStackState: getLinechartStackState(state),
        swimLaneOptions: getSwimLaneOptions(state),
        metadata: getMetadata(state),
        shouldSticky: getShouldSticky(state),
        isSeriesLabelsEnabled: getIsSeriesLabelsEnabled(state),
        isWellCollapsed: getJumbotronState(state)[CollapsableComponents.well]
    };
};

const mapDispatchToProps = dispatch => ({ 
    getEvents: (predicateObject, options, minMillis, maxMillis) => dispatch({type: GET_EVENTS, payload: {
        predicateObject: predicateObject, 
        options: options, 
        minMillis: minMillis, 
        maxMillis: maxMillis
    }}),
    zoom: (fromMillis, toMillis, direction = null) => dispatch({type: ZOOM, payload: {from: fromMillis, to: toMillis, direction: direction}}),
    setMaintainedIntervalSize: (intervalSize = null) => dispatch({type: SET_MAINTAIN_INTERVAL, payload: {maintainedIntervalSize: intervalSize}}),
    applySearchSpan: () => dispatch({type: APPLY_SEARCH_SPAN}),
    setChartType: (chartType) => dispatch({type: SET_CHART_TYPE, payload: chartType}),
    setMarkers: (markers) => {
        dispatch({type: SET_MARKERS, payload: markers});
        dispatch({type: SERIALIZE_STATE_TO_LOCAL_STORAGE});
    },
    setFocusTriggers: (focusTrigger, unfocusTrigger, stickyTrigger) => dispatch({type: SET_FOCUS_AND_STICKY_TRIGGERS, payload: 
        {valueElementFocusTrigger: focusTrigger, valueElementUnfocusTrigger: unfocusTrigger, valueElementStickyTrigger: stickyTrigger}}),
    stickyTSQ: (aggKey) => dispatch({type: STICKY_TIMESERIESQUERY, payload: aggKey}),
    unstickyTSQs: (aggKey) => dispatch({type: UNSTICKY_TIMESERIESQUERIES}),
    showHideTimeSeriesQuery: (idx) => dispatch({type: SHOW_HIDE_TIMESERIESQUERY, payload: idx}),
    removeQuery: (idx, isLts = true) => {
        isLts ? dispatch({type: REMOVE_TIMESERIESQUERY, payload: idx}) : 
                dispatch({type: REMOVE_AGGREGATESQUERY, payload: idx});
        dispatch({type: SERIALIZE_STATE_TO_LOCAL_STORAGE});
    },
    setChartSettingsToSeries: (series: any, tsqI: number): void => {
        let chartSettings: ChartSettingsState = {
            isOpen: true,
            type: ChartSettingsTypes.Series,
            activeTSQ: series,
            activeTSQI: tsqI
        }
        dispatch({
            type: SET_CHART_SETTINGS_STATE,
            payload: chartSettings
        });
    },
    setChartSettingsToLane: (lane: string): void => {
        let chartSettings: ChartSettingsState = {
            isOpen: true,
            type: ChartSettingsTypes.Swimlanes,
            activeSwimlane: lane
        }
        dispatch({
            type: SET_CHART_SETTINGS_STATE,
            payload: chartSettings
        });
    },
    setActiveChartReference: (ref) => {
        dispatch({type: SET_ACTIVE_VISUALIZATION_REFERENCE, payload: ref});
    },
    getBrushedRegionStatistics: (fromMillis, toMillis, x, y) => {
        dispatch({type: GET_BRUSHED_REGION_STATISTICS, payload: {fromMillis, toMillis, x, y}});
    },
    triggerAddMarkerButtonFocus: () => {
        dispatch({type: SET_FOCUS_ON_ADD_MARKER_BUTTON_TRIGGER, payload: true});
    },
    getQueryResults: () => dispatch({type: GET_QUERYRESULTS}),
    setAQField: (idx, field, value) => {dispatch({type: SET_QUERY_FIELD, payload: {type: QueryTypes.Aggregates, idx: idx, field: field, value: value}})},
    addAggregatesQuery: (ae) => {
        dispatch({type: ADD_AGGREGATESQUERY, payload: ae});
    },
    setAggregatesQuery: (aQ, idx) => {
        dispatch({type: SET_AGGREGATESQUERY, payload: {aQ: aQ, idx: idx}});
    }
});

const LineChartContainer = translate()(connect(mapStateToProps, mapDispatchToProps)(LineChart));

export default LineChartContainer;

import { DataStringComparisonBehavior, ReferenceDataErrors, ReferenceDataPrimaryKeyTypes } from "../../constants/Enums";
import { IReferenceDataSet, ReferenceDataSetKeyProperty } from "../../constants/Interfaces";

interface ReferenceDataSetModel extends IReferenceDataSet {
    errors?: {};
    updateErrors(): void;
}

/**
 * Represents a reference data set.
 * @constructor
 * @param {string} name - The name of the data set.
 * @param {{}} [errors] - Errors in the data set, see HierarchyLevelError enums for details.
 */
export class ReferenceDataSet implements ReferenceDataSetModel {
    id: string;
    location: string;
    name: string;
    properties: { 
        dataStringComparisonBehavior: DataStringComparisonBehavior; 
        keyProperties: Array<ReferenceDataSetKeyProperty>; 
    };
    errors?: {};

    static existingName;

    constructor(existingReferenceDataSetName: string = null) {
        this.name = '';
        this.location = '';
        this.properties = {
            keyProperties: [{name: '', type: ReferenceDataPrimaryKeyTypes.String}],
            dataStringComparisonBehavior: DataStringComparisonBehavior.Ordinal
        }
        ReferenceDataSet.existingName = existingReferenceDataSetName;
    }

    private isNameValid = (value) => {
        let regexp = /^[a-zA-Z0-9]{3,63}$/; // alpha-numeric, dots, dashes, and underscores
        return value.search(regexp) !== -1;
    }

    public updateErrors() {
        if (!this.name) {
            if (!this.errors) {
                this.errors = {};
            }
            this.errors[ReferenceDataErrors.NameEmpty] = true;
        } else {
            if (this.errors && this.errors[ReferenceDataErrors.NameEmpty]) {
                delete this.errors[ReferenceDataErrors.NameEmpty];
            }
            if (!this.isNameValid(this.name)) {
                if (!this.errors) {
                    this.errors = {};
                }
                this.errors[ReferenceDataErrors.NameNotValid] = true;
            } else {
                if (this.errors && this.errors[ReferenceDataErrors.NameNotValid]) {
                    delete this.errors[ReferenceDataErrors.NameNotValid];
                }
            }
        }

        if (ReferenceDataSet.existingName === this.name) {
            if (!this.errors) {
                this.errors = {};
            }
            this.errors[ReferenceDataErrors.NameExists] = true;
        } else {
            if (this.errors && this.errors[ReferenceDataErrors.NameExists]) {
                delete this.errors[ReferenceDataErrors.NameExists];
            }
        }

        if (this.properties.keyProperties.length > 0) {
            if (this.errors && this.errors[ReferenceDataErrors.PrimaryKeys]) {
                delete this.errors[ReferenceDataErrors.PrimaryKeys];
            }
        } else {
            if (!this.errors) {
                this.errors = {};
            }
            this.errors[ReferenceDataErrors.PrimaryKeys] = true;
        }
        if(!this.hasErrors()) {
            delete this.errors;
        }
    } 

    public hasErrors() {
        return this.errorCount() > 0;
    } 

    public errorCount() {
        let errorCount = 0;
        if (this.errors) {
            errorCount += Object.keys(this.errors).length;
        }

        return errorCount;
    }
}
import * as React from 'react';
import classNames from 'classnames/bind';
import TsmEntitiesContainer from '../../components/TsmEntities/TsmEntities.container';
import TsmModalContainer from '../../components/TsmModal/TsmModal.container';
import EnvironmentsContainer from '../../components/Environments/Environments.container';
import TsmSplashContainer from '../../components/NewFeatureSplash/Implementations/TsmSplash/TsmSplashContainer';
import { LocalStorageKeys } from '../../../constants/Enums';
import ReferenceDataContainer from '../../components/ReferenceData/ReferenceData.container';
const cx = classNames.bind(require('./Model.module.scss'));

interface Props { 
  theme: any; 
  t: any; 
  isTsmModalVisible: boolean; 
  isEnvironmentSupportingTsm: boolean; 
  openUserSettings: any; }
interface State { showTsmSplash: boolean; }

class Model extends React.Component<Props, State> {

  tsmSplashTimeout = null;
  
  constructor(props: Props) {
    super(props);
    this.state = {
        showTsmSplash: false
    };
  }

  componentDidMount() {
    this.tsmSplashTimeout = setTimeout(() => this.setState({showTsmSplash: !localStorage.getItem(LocalStorageKeys.TsmSplashSeen)}), 1000);
  }

  componentWillUnmount(){
    clearTimeout(this.tsmSplashTimeout);
  }

  hideTsmSplash = () => {
    this.setState({showTsmSplash: false});
  }

  render() {
    return <div className={cx('ModelPage')}>
        <EnvironmentsContainer openUserSettings={this.props.openUserSettings} />
        {this.props.isEnvironmentSupportingTsm ? 
            <div className={cx('rhs')}>
                <TsmEntitiesContainer/>
                {this.props.isTsmModalVisible && <TsmModalContainer/>}
            </div>
            : 
            <div className={cx('rhs')}>
                <ReferenceDataContainer/>
            </div>
        }
        {this.props.isEnvironmentSupportingTsm && this.state.showTsmSplash && <TsmSplashContainer hideTsmSplash={this.hideTsmSplash} />}
        </div>;
    }
}

export default Model; 
import { connect } from 'react-redux';
import TagExplorer from './TagExplorer';
import {GET_QUERYRESULTS, ADD_TIMESERIESQUERY, REMOVE_TIMESERIESQUERY, SELECT_HIERARCHY, SET_TSID_FOR_LOOKUP, SERIALIZE_STATE_TO_LOCAL_STORAGE, UPDATE_JUMBOTRON_STATE} from '../../../constants/ActionTypes';
import { getTheme, getSelectedEnvironmentFqdn } from '../../state/reducers/AppSettingsReducer';
import { getHierarchiesArray, getInstancesArray, getTypesArray, getModel, getSelectedHierarchyId, 
        getMetadata, getTimeSeriesQueries, getTsidForLookup, getJumbotronState } from '../../state/reducers/AnalyticsReducer';
import { translate } from 'react-i18next';
import { CollapsableComponents } from '../../../constants/Enums';

const mapStateToProps = state => {
    return { 
        model: getModel(state),
        hierarchies: getHierarchiesArray(state),
        instances: getInstancesArray(state),
        types: getTypesArray(state),
        selectedHierarchyId: getSelectedHierarchyId(state),
        fqdn: getSelectedEnvironmentFqdn(state),
        theme: getTheme(state),
        metadata: getMetadata(state),
        timeSeriesQueries: getTimeSeriesQueries(state),
        tsidForLookup: getTsidForLookup(state),
        jumbotronState: getJumbotronState(state)
    };
};

const mapDispatchToProps = (dispatch) => ({ 
    addTimeSeriesQuery: (tsq) => {
        dispatch({type: ADD_TIMESERIESQUERY, payload: tsq});
    },
    removeTimeSeriesQuery: (tsq) => dispatch({type: REMOVE_TIMESERIESQUERY, payload: tsq}),
    getQueryResults: () => dispatch({type: GET_QUERYRESULTS}),
    selectHierarchy: (id) => {
        dispatch({type: SELECT_HIERARCHY, payload: id});
        dispatch({type: SERIALIZE_STATE_TO_LOCAL_STORAGE});
    },
    resetTSIDforLookup: () => {
        dispatch({type: SET_TSID_FOR_LOOKUP, payload: undefined});
    },
    updateCollapseState: (isCollapsed: boolean) => {
        dispatch({type: UPDATE_JUMBOTRON_STATE, payload: {target: CollapsableComponents.tagExp, isCollapsed}})
    }
});

const TagExplorerContainer = translate()(connect(mapStateToProps, mapDispatchToProps)(TagExplorer));

export default TagExplorerContainer;

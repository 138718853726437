import * as React from 'react';
import Icon from '../Icon/Icon';
import classNames from 'classnames/bind';
const cx = classNames.bind(require('./Searchbox.module.scss'));

interface Props { t: any, theme: string, placeholder: string, value: string, handleOnChange: any, handleOnClear?: () => void};
interface State { isFocused: boolean };

export default class Searchbox extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      isFocused: false
    };

    this.handleOnFocus = this.handleOnFocus.bind(this);
    this.handleOnBlur = this.handleOnBlur.bind(this);
  }

  handleOnFocus() {
    this.setState({
      isFocused: true
    });
  }

  handleOnBlur() {
    this.setState({
      isFocused: false
    });
  }

  render() {
    const { t, theme, placeholder, value, handleOnChange, handleOnClear } = this.props;
    const { isFocused } = this.state;

    return <div className={cx('searchbox', { focused: isFocused })}>
      <Icon id={'magnifyingGlass-' + theme} className={cx('icon16')}/>
      <input className={cx('searchboxInput')} 
        type="text" 
        placeholder={placeholder} 
        value={value}
        onChange={handleOnChange}
        onFocus={this.handleOnFocus} 
        onBlur={this.handleOnBlur} />
      {(handleOnClear && value) && 
        <button className={cx('clearButton')} onClick={handleOnClear} title={t('clear')} aria-label={t('clear')}>
          <Icon id={'iconClose-' + theme} className={cx('icon16', 'iconClose')}/>
        </button>
      }
    </div>;
  }
}

// This should be an enum, but the codebase treats theme as a string. We will
// need to refactor the app to use an enum instead.
export const AppTheme = {
    Light: 'light',
    Dark: 'dark'
};

export const nullTsidDisplayString = 'null';
export const emptyDisplayString = 'empty'; // if changed please make sure corresponding translation exist

export const eventCountThresholdForGetSeries = 1000;

export const milliToReadableTimeSpanMap = {
    [1000 * 60 * 15]: '15 minutes',
    [1000 * 60 * 30]: '30 minutes',
    [1000 * 60 * 45]: '45 minutes',
    [1000 * 60 * 60]: '1 hour',
    [1000 * 60 * 60 * 6]: '6 hours',
    [1000 * 60 * 60 * 12]: '12 hours',
    [1000 * 60 * 60 * 24]: '1 day'
}

export const milliToReadableIntervalMap = {
    [1000 * 15]: '15 seconds',
    [1000 * 30]: '30 seconds',
    [1000 * 60]: '60 seconds',
}

export const VariableKinds = ["Numeric", "Aggregate", "Categorical"]; /** Derived" */
export const VariableAggregationOperations_Numeric = ["min($value)", "max($value)", "sum($value)", "avg($value)", "first($value)", "last($value)", "stdev($value)", "median($value)", "left($value)", "right($value)", "twavg($value)", "twsum($value)"];
export const VariableAggregationOperations_Numeric_Interpolation = ["left($value)", "right($value)", "twavg($value)", "twsum($value)"];
export const VariableAggregationOperations_Numeric_NonInterpolation = ["min($value)", "max($value)", "sum($value)", "avg($value)", "first($value)", "last($value)", "stdev($value)", "median($value)"];
export const VariableAggregationOperations_Aggregate = ["count()"];
export const VariableInterpolationKinds = ["Step", "Linear"];
export const VariableInterpolationKinds_Categorical = ["Step"];
export const VariableInterpolationBoundarySpan_Default = "PT5M";
export const autoRefreshCycleMillis_Default = 30 * 1000;
export const autoRefreshTimeSpanMillis_Default = 60 * 60 * 1000;
export const lanes = ['1', '2', '3', '4'];
export const defaultSwimlaneOptions = {
    1: {yAxisType: 'shared', label: null},
    2: {yAxisType: 'shared', label: null},
    3: {yAxisType: 'shared', label: null},
    4: {yAxisType: 'shared', label: null}
}

export const maxReferenceDataSetLimit = 2;
export const maxReferenceDataSetKeyPropertiesLimit = 3;
export const bulkUploadOverflowCharacterLimit = 1200;
export const referenceDataSetItemsPaginationSize = 100;
export const clientHeightFactorTresholdForInfiniteScroll = 1.2;
export const debounceDelayTimeForSearch = 250; //ms
export const timeSeriesDefinitions = 'timeSeriesDefinitions';

import { VariableCategory } from "./VariableCategory";
import { TSMCategoricalValueKind } from "../../constants/Enums";

/**
 * Represents a variable for a type.
 * @constructor
 * @param {string} name - The name of the variable.
 * @param {string} kind - The type of the variable, see VariableTypes enums for details.
 * @param {string} value - The selected value for the variable.
 * @param {VariableCategory[]} [categories] - Categories defined for the variable.
 * @param {{}} [errors] - Errors in the variable, see VariablePropertiesErrors enums for details.
 */
export class Variable {
    public name: string;
    public kind: string;
    public value?: {tsx: string};
    public isValueFreeform: boolean;
    public isAggregationOperationFreeform: boolean;
    public filter?: {tsx: string};
    public interpolation?: {kind: any, boundary: {span: any}};
    public aggregation: {tsx: string};
    public categories?: VariableCategory[];
    public categoryValueKind?: TSMCategoricalValueKind;
    public defaultCategory?: VariableCategory;
    public errors?: {};

    constructor(valueOptions: any) {
        this.name = "";
        this.kind = null;
        this.aggregation = {tsx: ""};
        this.isValueFreeform = valueOptions.length === 0;
        this.isAggregationOperationFreeform = false;
        this.categoryValueKind = TSMCategoricalValueKind.Integer;
    }

    static fromObject (obj, valueOptions: any) {
        let v = new Variable (valueOptions);
        v.name = obj.name;
        v.kind = obj.kind;
        v.value = obj.value;
        v.isValueFreeform = !(v.value && valueOptions.indexOf(v.value.tsx) !== -1);
        v.filter = obj.filter;
        v.interpolation = obj.interpolation;
        v.aggregation = obj.aggregation;
        if (obj.categories) {
            v.categories = obj.categories;
            v.defaultCategory = obj.defaultCategory;
            if (typeof obj.categories[0].values[0] === "string") 
                v.categoryValueKind = TSMCategoricalValueKind.String;
            else
                v.categoryValueKind = TSMCategoricalValueKind.Integer; //TODO: or long?
        }
        v.isAggregationOperationFreeform = !(v.aggregation && VariableAggregationOperations_Numeric.concat(VariableAggregationOperations_Aggregate).indexOf(v.aggregation.tsx) !== -1);
        return v;
    }

    public addVariableCategory(obj) {
        let category;
        if (obj) {
            category = VariableCategory.fromObject(obj);
        } else {
            category = new VariableCategory();
        }
        if (!this.categories) {
            this.categories = [];
        }

        this.categories.push(category);
    }

    public addDefaultCategory(obj) {
        this.defaultCategory = new VariableCategory();
        delete this.defaultCategory.values;
    }

    public updateErrors() { /** Name, Kind, Aggregation and Value (except for Count operation) are required fields */
        if (!this.name) {
            if (!this.errors) {
                this.errors = {};
            }
            this.errors["name"] = true;
        } else {
            if (this.errors && this.errors["name"]) {
                delete this.errors["name"];
            }
        }

        if (!this.kind) {
            if (!this.errors) {
                this.errors = {};
            }
            this.errors["kind"] = true;
        } else {
            if (this.errors && this.errors["kind"]) {
                delete this.errors["kind"];
            }
        }

        if (this.kind !== "categorical" && this.aggregation && !this.aggregation.tsx) {
            if (!this.errors) {
                this.errors = {};
            }
            this.errors["aggregation_operation"] = true;
        } else {
            if (this.errors && this.errors["aggregation_operation"]) {
                delete this.errors["aggregation_operation"];
            }
        }

        if (this.kind !== "aggregate") {
            if (!this.value || (this.value && this.value.tsx === "")) {
                if (!this.errors) {
                    this.errors = {};
                }
                this.errors["value"] = true;
            } else {
                if (this.errors && this.errors["value"]) {
                    delete this.errors["value"];
                }
            }
            if (this.errors && this.errors["value_count"]) {
                delete this.errors["value_count"];
            }
        } else {
            if (this.errors && this.errors["value"]) {
                delete this.errors["value"];
            }
            if (this.value) {
                if (!this.errors) {
                    this.errors = {};
                }
                this.errors["value_count"] = true;
            } else {
                if (this.errors && this.errors["value_count"]) {
                    delete this.errors["value_count"];
                }
            }
        }

        if (this.kind === "aggregate" && this.errors) {
            if (this.errors["interpolation_kind"])
                delete this.errors["interpolation_kind"];
        } else {
            if (this.interpolation && this.interpolation.boundary && !this.interpolation.kind) {
                if (!this.errors) {
                    this.errors = {};
                }
                this.errors["interpolation_kind"] = true;
            } else {
                if (this.errors && this.errors["interpolation_kind"]) {
                    delete this.errors["interpolation_kind"];
                }
            }
        }

        if (this.kind === "numeric" && this.aggregation && this.aggregation.tsx && 
            (this.aggregation.tsx === "left($value)" || this.aggregation.tsx === "right($value)" || this.aggregation.tsx === "twavg($value)" || this.aggregation.tsx === "twsum($value)")) {
            if (!this.interpolation) {
                if (!this.errors) {
                    this.errors = {};
                }
                this.errors["interpolation_required"] = true;
            } else {
                if (this.errors && this.errors["interpolation_required"]) {
                    delete this.errors["interpolation_required"];
                }
            }
        } else {
            if (this.errors && this.errors["interpolation_required"]) {
                delete this.errors["interpolation_required"];
            }
        }

        if (this.kind === "categorical" && (!this.defaultCategory || !this.defaultCategory.label)) {
            if (!this.errors) {
                this.errors = {};
            }
            this.errors["default_category"] = true;
        } else {
            if (this.errors && this.errors["default_category"]) {
                delete this.errors["default_category"];
            }
        }


        if (this.kind === "categorical") {
            if (!this.categories) {
                if (!this.errors) {
                    this.errors = {};
                }
                this.errors["categories"] = true;
            } else {
                if (this.errors && this.errors["categories"]) {
                    delete this.errors["categories"];
                }
                this.categories.forEach((c, idx) => {
                    c.updateErrors();
                });

                let uniqueName = true;
                this.categories.forEach((a, idx) => {
                    this.categories.forEach((a2, idx2) => {
                        if (idx !== idx2) {
                            if (a.label && a.label === a2.label) {
                                a.addError("label_unique");
                                uniqueName = false;
                            }    
                        }
                    });
                    if (uniqueName) {
                        a.removeError("label_unique");
                    }
                });
            }
        }
    }

    public errorCount() {
        let errorCount = 0;
        if (this.errors) {
            errorCount += Object.keys(this.errors).length;
        }

        return errorCount;
    }

    public hasErrors() {
        return this.errorCount() > 0;
    }

    public addError(errorEnum) {
        if (!this.errors) {
            this.errors = {};
        }
        this.errors[errorEnum] = true;
    }

    public removeError(errorEnum) {
        if (this.errors) {
            delete this.errors[errorEnum];
        }
    }
}

export const VariableKinds = ["Numeric", "Aggregate"]; /** "Categorical", "Derived" */
export const VariableAggregationOperations_Numeric = ["min($value)", "max($value)", "sum($value)", "avg($value)", "left($value)"];
export const VariableAggregationOperations_Aggregate = ["count()"];
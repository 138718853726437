import React from "react";
import ModalContainer from "../../Modal/Modal.container";
import TooltipableContainer from "../../Tooltipable/Tooltipable.container";
import classNames from 'classnames/bind';
import update from 'immutability-helper';
import Icon from "../../Icon/Icon";
import Header from "./Header";
import Utils from "../../../services/Utils";
import { ProgressMessageField } from "./ProgressMessageField";
const cx = classNames.bind(require('../TsmModal.module.scss'));

interface Props {hierarchy: any; tsmModalComponent: any; }

export default class HierarchyModal extends React.Component<Props> {
    private hierarchyNameTitleID = Utils.customID('hierarchyNameTitle');
    private nameErrorFieldID = Utils.customID('nameErrorField');
    private levelNameErrorFieldID = Utils.customID('levelNameErrorField');
    private levelNameUniqueErrorFieldID = Utils.customID('levelNameUniqueErrorField');
    private levelsTableTitleID = Utils.customID('levelsTableTitle');
    private progressMessageFieldID = Utils.customID('progressMessageFieldID');

    handleNameChange = event => {
        this.props.tsmModalComponent.setState(update(this.props.tsmModalComponent.state, {entity: {name: {$set: event.target.value}}}));
    };

    handleLevelNameChange = (idx) => (event) => {
        this.props.tsmModalComponent.setState(update(this.props.tsmModalComponent.state, {entity: {levels: {[idx]: {name: {$set: event.target.value}}}}}));
    };

    render () {
        const tsmModalComponent = this.props.tsmModalComponent;
        this.props.hierarchy.updateErrors();
    
        return (
            <ModalContainer onClose={tsmModalComponent.closeModal} title={tsmModalComponent.props.entityToEdit ? tsmModalComponent.props.t('tsm.edit') + " " + tsmModalComponent.props.entityToEdit.name : tsmModalComponent.props.t('tsm.hierarchy.new')} className={cx('tsmEntityModal')} contentPositionContainerClassName={cx('tsmModalContentPositionContainer')} wrapperClassName={cx('tsmModalContentContainer')} contentClassName={cx('tsmModalContent')} isOffModalAutoClosingEnabled={false}>
                <div className={cx('modalContent')}>
                    <div className={cx('entityContainer')}>
                        <div className={cx('form')}>
                            <div className={cx('build-area')}>
                                <div className={cx('block')}>
                                    <div id={this.hierarchyNameTitleID} className={cx('title')}>{tsmModalComponent.props.t('tsm.name')}</div> 
                                    <input aria-labelledby={this.hierarchyNameTitleID} aria-describedby={this.nameErrorFieldID} className={cx(this.props.hierarchy.errors?.name ? 'error' : '')} 
                                            type="text" value={this.props.hierarchy.name} onChange={this.handleNameChange}/>
                                    {this.props.hierarchy.errors?.name && <div id={this.nameErrorFieldID} role="alert" className={cx('error-message', '_base-fade-in')}>{tsmModalComponent.props.t('tsm.type.validations.name')}</div>}
                                </div>
                                <div className={cx('block')}>
                                    <div id={this.levelsTableTitleID} className={cx('title')}>{tsmModalComponent.props.t('tsm.hierarchy.levels')}</div> 
                                    {this.props.hierarchy.levels ? 
                                        <div className={cx('grid')}>
                                            {this.props.hierarchy.levels && this.props.hierarchy.levels.length > 0 &&
                                                <table aria-labelledby={this.levelsTableTitleID}>
                                                    <Header headers={[tsmModalComponent.props.t('tsm.name'), '']} tsmModalComponent={tsmModalComponent}/>
                                                    <tbody>
                                                        {this.props.hierarchy.levels.map((l, idx) =>
                                                            <tr key={"level-" + idx} className={cx('_base-fade-in')} ref={el => { if (idx === this.props.hierarchy.levels.length - 1) tsmModalComponent.entitiesEnd = el; }}>
                                                                <td className={cx('level')}>
                                                                    <input aria-label={tsmModalComponent.props.t('tsm.hierarchy.levelName') + (idx + 1)} aria-describedby={this.levelNameErrorFieldID + "_" + idx + " " + this.levelNameUniqueErrorFieldID + "_" + idx} 
                                                                            className={cx(l.errors?.name || l.errors?.name_unique ? 'error' : '')} type="text" value={l.name} onChange={this.handleLevelNameChange(idx)}/>
                                                                    {l.errors?.name && <div id={this.levelNameErrorFieldID + "_" + idx} role="alert" className={cx('error-message', '_base-fade-in')}>{tsmModalComponent.props.t('tsm.hierarchy.validations.level.name')}</div>}
                                                                    {l.errors?.name_unique && <div id={this.levelNameUniqueErrorFieldID + "_" + idx} className={cx('error-message', '_base-fade-in')}>{tsmModalComponent.props.t('tsm.hierarchy.validations.level.name_unique')}</div>}
                                                                </td>
                                                                <td>
                                                                    <button className={cx('action-button')} onClick={tsmModalComponent.removeEntityProperty(idx)} onKeyDown={tsmModalComponent.removeEntityProperty(idx)} 
                                                                            title={tsmModalComponent.props.t('tsm.hierarchy.deleteLevel')} aria-label={tsmModalComponent.props.t('tsm.hierarchy.deleteLevel') + (idx + 1)}>
                                                                        <Icon id={"iconClose-" + tsmModalComponent.props.theme} className={cx('icon16')}/>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            }
                                            {this.props.hierarchy.errors?.levels && <div className={cx('error-message', '_base-fade-in')} role="alert">{tsmModalComponent.props.t('tsm.hierarchy.validations.levels')}</div>}
                                        </div>
                                    :
                                    <span>{tsmModalComponent.props.t('tsm.none')}</span>
                                    }
                                    <div className={cx('sub-button')}>
                                        <button aria-label={tsmModalComponent.props.t('tsm.hierarchy.addLevel')}
                                            onClick={tsmModalComponent.addEntityProperty}>
                                            <Icon id={'iconAddBlue'} className={cx('icon16')}/>
                                            <span>{tsmModalComponent.props.t('tsm.hierarchy.addLevel')}</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={cx('tsmModalBottom')}>
                        <button className={cx('showJson')} aria-label={tsmModalComponent.props.t('tsm.viewJson')} role="link" tabIndex={0} onKeyDown={tsmModalComponent.showJSON} onClick={tsmModalComponent.showJSON}><Icon id={"iconDocument"} className={cx('icon16')}/>{tsmModalComponent.props.t('tsm.viewJson')}</button>
                        <div className={cx('buttonWrapper')}>
                            <button aria-label={tsmModalComponent.props.t('cancel')} className={cx('cancelButton')} onKeyDown={tsmModalComponent.closeModal} onClick={tsmModalComponent.closeModal}>{tsmModalComponent.props.t('cancel')}</button>
                            {this.props.hierarchy.hasErrors() ?
                                <TooltipableContainer tooltip={tsmModalComponent.props.t('tsm.fixErrorsToSave')} position={'top'}>
                                    <button aria-disabled="true" disabled className={cx('saveButton', 'disabled')}>{tsmModalComponent.props.t('save')}</button>
                                </TooltipableContainer>
                            :
                                <button className={cx('saveButton')} onKeyDown={tsmModalComponent.createOrEditEntity} onClick={tsmModalComponent.createOrEditEntity} aria-describedby={this.progressMessageFieldID}>{tsmModalComponent.props.t('save')}</button>
                            }
                        </div>
                    </div>
                    <ProgressMessageField 
                        cx={cx}
                        entityType={tsmModalComponent.props.entityType} 
                        progress={tsmModalComponent.props.progress} 
                        errorCode={tsmModalComponent.props.errorCode} 
                        errorMessage={tsmModalComponent.props.errorMessage} 
                        method={tsmModalComponent.props.requestMethod} 
                        theme={tsmModalComponent.props.theme}
                        t={tsmModalComponent.props.t}
                        progressMessageFieldID={this.progressMessageFieldID}/>
                </div>
            </ModalContainer>
        )
    }
}
/**
 * Represents a level in a hierarchy.
 * @constructor
 * @param {string} name - The name of the level.
 * @param {{}} [errors] - Errors in the level, see HierarchyLevelError enums for details.
 */
export class HierarchyLevel {
    public name: string;
    public errors?: {};

    constructor() {
        this.name = "";
    }

    static fromObject (obj) {
        let h = new HierarchyLevel ();
        h.name = obj.name;
        return h;
    }

    public updateErrors() {
        if (!this.name) {
            if (!this.errors) {
                this.errors = {};
            }
            this.errors["name"] = true;
        } else {
            if (this.errors && this.errors["name"]) {
                delete this.errors["name"];
            }
        }
    }

    public hasErrors() {
        return this.errorCount() > 0;
    }

    public errorCount() {
        let errorCount = 0;
        if (this.errors) {
            errorCount += Object.keys(this.errors).length;
        }

        return errorCount;
    }

    public addError(errorEnum) {
        if (!this.errors) {
            this.errors = {};
        }
        this.errors[errorEnum] = true;
    }

    public removeError(errorEnum) {
        if (this.errors) {
            delete this.errors[errorEnum];
        }
    }
}
import React, { useState } from "react";
import { AnalyticsCommandModals } from "../../../../constants/Enums";
import ModalContainer from "../../Modal/Modal.container";
import classNames from 'classnames/bind';
import Utils from "../../../services/Utils";
import Icon from "../../Icon/Icon";
import { LetUsKnowLink } from "../AnalyticsCommandBar";
const cx = classNames.bind(require('./Save.module.scss'));

interface Props{
    setOpenModal: (modal: AnalyticsCommandModals, isOpen: boolean) => any;
    theme: string;
    t: any;
    saveButtonRef: any;
    queryName: string;
    saveQuery: any;
    queries: any;
    saveQueryMessage: string;
    isSaveQueryMessageError: boolean;
    openFeedbackForm: any;
}

const Save: React.FunctionComponent<Props> = (props) => {
    const nameLabelID = Utils.customID('nameInstructions');
    
    const [queryName, setQueryName] = useState(null);
    const [sharingScope, setSharingScope] = useState('Personal')

    const toggleSharingScope = () => {
        let newScope = sharingScope === 'Personal' ? 'Environment' : 'Personal';
        setSharingScope(newScope);
    }

    const getQueryNameToBeSaved = () => {
        if (queryName !== null) {
            return queryName;
        }
        if (queryName === null && props.queryName !== null) {
            return props.queryName;
        }
        return null;
    }

    const saveQuery = () => {
        let queryName = getQueryNameToBeSaved();
        if (queryName !== null && queryName.length > 0) {
            props.saveQuery(queryName, sharingScope);
        }
    }

    const isExistingQueryName = () => {
        let queryName = getQueryNameToBeSaved();
        if (queryName === null || queryName.length === 0) {
            return false;
        }
        return (props.queries.map(q => q.name).indexOf(queryName) !== -1);
    }
    
    return(
        <ModalContainer contentClassName={cx('modalContent')} titleIconId={'iconSave-'+props.theme} sourceElement={props.saveButtonRef.current}
            title={props.t('saveQueryTitle')} onClose={() => props.setOpenModal(AnalyticsCommandModals.saveModal, false)}>
            <div className={cx('saveQueryWrapper', 'queriesModalContent')}>
                <div id={nameLabelID} className={cx('nameInstructions')}>{props.t('nameQueryInstructions')}</div>
                <ControlledQueryNameInput queryName={props.queryName} setQueryName={setQueryName} component={this} labelID={nameLabelID}/>
                <button className={cx('shareThisQuery')} role='switch' onClick={() => toggleSharingScope()}
                    aria-checked={sharingScope !== 'Personal'}>
                    <div className={cx('toggleSliderWrapper', {off: sharingScope === 'Personal'})}><div className={cx('toggleButton')}></div></div>
                    <span>{props.t('shareQueryAction')}</span>
                </button>
                <div className={cx('saveButtonWrapper')}>
                    <button className={cx('saveButton')} onClick={saveQuery}>{props.t('saveQuery')}</button>
                    {(isExistingQueryName() && !(props.saveQueryMessage && !props.isSaveQueryMessageError)) ? <span className={cx('savePrompt')}>{props.t('saveWillOverwritePrompt')}</span> : ''}
                </div>
                {props.saveQueryMessage && <SavedQueryStatusMessage props={{...props, message: props.isSaveQueryMessageError ? props.saveQueryMessage : props.t('saveQuerySuccess'), isError: props.isSaveQueryMessageError}} />}
            </div>
        </ModalContainer>
    )
}

const ControlledQueryNameInput = (props) => {    
    let changeQueryNameFromInput = (queryInput) => {
        props.setQueryName(queryInput.target.value);
    };
    return (<input aria-labelledby={props.labelID} id={cx('nameInput')} className={cx('nameInput')} defaultValue={props.queryName} 
            onChange={changeQueryNameFromInput}/>);
};

const SavedQueryStatusMessage = ({props}) => {
    return <div className={cx('statusMessage', {error: props.isError})}>
        <Icon className={cx('icon16')} id={props.isError ? ('iconStatusError-' + props.theme) : ('iconStatusOK-' + props.theme)}></Icon>
        {props.message + ' '} {props.isError && <LetUsKnowLink {...props} issue={props.t('saveOpenShare.saveError')}/>}
    </div>
};

export default Save;
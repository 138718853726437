import classNames from 'classnames/bind';
import React, { useEffect, useState, useCallback } from 'react';
import ModalContainer from '../Modal/Modal.container';
import Icon from '../Icon/Icon';
import {  PUT_REFERENCE_DATA_SET, PUT_REFERENCE_DATA_SET_ITEMS, UPDATE_BULK_UPLOAD_REFERENCE_DATA_ITEMS_PROGRESS } from '../../../constants/ActionTypes';
import { useReducerAndSaga } from '../../hooks/useReducerAndSaga';
import { defaultReferenceDataModalState, referenceDataModalReducer } from '../../reducers/referenceDataModalReducer';
import getReferenceDataSaga from '../../sagas/referenceDataSaga';
import { ReferenceDataSet } from '../../models/ReferenceDataSet';
import update from 'immutability-helper';
import { DataStringComparisonBehavior, FileUploadStatus, ReferenceDataErrors, ReferenceDataPrimaryKeyTypes, RequestMethods, RequestStatus } from '../../../constants/Enums';
import { useMemo } from 'react';
import Utils from '../../services/Utils';
import TooltipableContainer from '../Tooltipable/Tooltipable.container';
import DropdownMenuContainer from '../DropdownMenu/DropdownMenu.container';
import Placeholder from '../Placeholder/Placeholder';
import { bulkUploadOverflowCharacterLimit, maxReferenceDataSetKeyPropertiesLimit } from '../../../constants/Constants';
import TelemetryService from '../../services/TelemetryService';
const cx = classNames.bind(require('./ReferenceDataModal.module.scss'));
interface Props { 
    t: any; 
    theme: string; 
    environmentFqdn: string;
    closeModal: any;
    clearProgress: any;
    selectedDataSetName: string;
    isForNewReferenceDataSet: boolean;
    selectedEnvironmentResourceId: string;
    onItemsUploadComplete: any;
    onDataSetUploadComplete: any;
}

interface State { 
    fileUploadStatus: FileUploadStatus;
    fileUploadMessage: string;
    itemsJSON: any;
    isStep1Finished: boolean; // uploading reference data set with key properties selected
    isStep2Finished: boolean; // uploading items into the (newly created) data set
    progress: RequestStatus;
    errorCode: string;
    errorMessage: string;
    bulkUploadProgress: number;
}

const initialState: State = defaultReferenceDataModalState;
export const ProgressMessageContext = React.createContext({cx: null, t: null, theme: 'light', progress: null, progressMessageFieldID: null, method: null, errorCode: null, errorMessage: null});

export const ReferenceDataModal = (props: Props) => {
    const progressMessageFieldID = Utils.customID('progressMessageField');
    const nameErrorFieldID = Utils.customID('referenceDataNameErrorField');
    const nameExistsErrorFieldID = Utils.customID('referenceDataNameExistsErrorField');
    const fileUploadErrorFieldID = Utils.customID('fileUploadErrorField');

    const [state, dispatch] = useReducerAndSaga(referenceDataModalReducer, initialState, getReferenceDataSaga); // Notes: the name of custom hooks should start with "use" keyword https://reactjs.org/docs/hooks-custom.html#extracting-a-custom-hook 
    
    const {t, theme, closeModal, clearProgress, environmentFqdn, selectedDataSetName, isForNewReferenceDataSet, selectedEnvironmentResourceId, onItemsUploadComplete, onDataSetUploadComplete} = props;
    const {progress, errorCode, errorMessage, isStep1Finished, isStep2Finished, bulkUploadProgress}= state;

    const [fileUploadStatus, setFileUploadStatus] = useState(null);
    const [fileUploadMessage, setFileUploadMessage] = useState(null);
    const [itemsJSON, setItemsJSON] = useState(null);
    const [newReferenceDataSet, setNewReferenceDataSet] = useState(function getInitialState() {
        return isForNewReferenceDataSet ? new ReferenceDataSet(selectedDataSetName) : null;
    });

    //custom component functions
    const close = useCallback(() => { // needed to keep useCallback here since it is used in useEffect
        closeModal();
        if (isForNewReferenceDataSet && isStep1Finished) {
            onDataSetUploadComplete(newReferenceDataSet.name);
        } else {
            if (isStep2Finished) {
                onItemsUploadComplete();
            }
        }
    }, [closeModal, isForNewReferenceDataSet, newReferenceDataSet, isStep1Finished, isStep2Finished, onDataSetUploadComplete, onItemsUploadComplete]);

    const handleFiles = useCallback((file) => {
        let reader = new FileReader();
        let fileType = file.name.split('.').pop().toLowerCase();
        (document.getElementById(cx("JSONText")) as HTMLTextAreaElement).value = "";
        (document.getElementById('myFileName') as HTMLSpanElement).innerHTML = file.name;
        if (progress !== RequestStatus.Publishing) {
            clearProgress();
        }

        if (fileType === 'json') {
            reader.onload = (evt: any) => {
                let text = evt.target.result;
                let JSONArray = null;
                try {
                    JSONArray = JSON.parse(text);
                    if (!Array.isArray(JSONArray)) {throw new SyntaxError(t('referenceData.uploadJSONArray'))};
                    setFileUploadStatus(FileUploadStatus.Successful);
                    setFileUploadMessage(`${t('validFile')} (${JSONArray.length} ${t('referenceData.items')})`);
                } catch (e) {
                    if (e instanceof SyntaxError) {
                        setFileUploadStatus(FileUploadStatus.Error);
                        setFileUploadMessage(t('parseFailed'));
                    } else {
                        setFileUploadStatus(FileUploadStatus.Successful);
                        setFileUploadMessage(t('validFile'));
                    }
                }
                if (text) {
                    (document.getElementById(cx('refDataFileUpload')) as HTMLInputElement).value = null;
                    if (text.length > bulkUploadOverflowCharacterLimit) {
                        (document.getElementById(cx("JSONText")) as HTMLTextAreaElement).value = text.substring(0, bulkUploadOverflowCharacterLimit).concat(`\n ... (${t('continued')})`);
                    } else {
                        (document.getElementById(cx("JSONText")) as HTMLTextAreaElement).value = text;
                    }
                    
                    setItemsJSON(JSONArray);
                }
            };
            reader.readAsText(file, "UTF-8");
        } else {
            setFileUploadStatus(FileUploadStatus.Error);
            setFileUploadMessage(t('referenceData.invalidFileType'));
        }
    }, [clearProgress, progress, t]);

    const memoizedColumnsFromJSON = useMemo(() => { // to show options as columns (keys in the uploaded JSON) in primary key selection dropdown
        let columns = [];
        if(Array.isArray(itemsJSON)) { //uploaded items json may not be array but an object instead, make sure it is array
            itemsJSON?.forEach?.(item => {
                let keys = Object.keys(item);
                keys.forEach(k => {
                    if(columns.indexOf(k) === -1) columns.push(k);
                });
            });
        }
        return columns;
    }, [itemsJSON]);

    const upload = () => {
        if (progress !== RequestStatus.Publishing && progress !== RequestStatus.Finalizing) {  // not to let double publishing if there is one request already sent
            dispatch({type: PUT_REFERENCE_DATA_SET_ITEMS, payload: {
                items: itemsJSON, 
                dataSetName: isForNewReferenceDataSet ? newReferenceDataSet.name : selectedDataSetName, 
                envFqdn: environmentFqdn,
                onProgress: (p) => dispatch({type: UPDATE_BULK_UPLOAD_REFERENCE_DATA_ITEMS_PROGRESS, payload: p})}
            });
            TelemetryService.logUserAction('putReferenceDataItems');
        }
    };

    const create = () => {
        if (progress !== RequestStatus.Publishing && progress !== RequestStatus.Finalizing) {  // not to let double publishing if there is one request already sent
            dispatch({type: PUT_REFERENCE_DATA_SET, payload: {
                newDataSet: newReferenceDataSet, 
                dataSetName: newReferenceDataSet.name, 
                envFqdn: environmentFqdn, 
                resourceId: selectedEnvironmentResourceId, 
                items: itemsJSON,
                onProgress: (p) => dispatch({type: UPDATE_BULK_UPLOAD_REFERENCE_DATA_ITEMS_PROGRESS, payload: p})}
            });
            TelemetryService.logUserAction('putReferenceDataSet');
        }
    };

    const retrievePrimaryKeyColumnType = useCallback((key) => {// to convert the data types in parsed json into valid data type options for showing in dropdown/API request, useCallback is used since it is used in useEffect
        let type;
        if(Array.isArray(itemsJSON)) { //uplaoded items json may not be array but an object instead, make sure it is array
            itemsJSON?.some(item => {
                if (item[key]) {
                    type = typeof item[key];
                    return;
                }
            });
        }
        return type === 'number' ? ReferenceDataPrimaryKeyTypes.Double : type === 'boolean' ? ReferenceDataPrimaryKeyTypes.Bool : ReferenceDataPrimaryKeyTypes.String;
    }, [itemsJSON]);

    const handleNameChange = event => {
        const value = event.target.value;
        setNewReferenceDataSet((prevState) => {
            return update(prevState, {name: {$set: value}});
        });
    };

    const handlePrimaryKeyChange = (idx) => event => {
        const value = event.target.value;
        setNewReferenceDataSet((prevState) => {
            return update(prevState, {properties: {keyProperties: {[idx]: {$set: {name: value, type: retrievePrimaryKeyColumnType(value)}}}}});
        });
    };
    
    const handlePrimaryKeyTypeChange = (idx, value) => {
        setNewReferenceDataSet((prevState) => {
            return update(prevState, {properties: {keyProperties: {[idx]: {type: {$set: value}}}}});
        });
    };

    const handleDataStringComparison = event => {
        const value = event.target.value;
        setNewReferenceDataSet((prevState) => {
            return update(prevState, {properties: {dataStringComparisonBehavior: {$set: value}}});
        });
    };

    const addPrimaryKey = () => {
        const nextNonKeyColumn = memoizedColumnsFromJSON.find((c) => newReferenceDataSet.properties.keyProperties.map(kP => kP.name).indexOf(c) === -1);
        setNewReferenceDataSet((prevState) => {
            return update(prevState, {properties: {keyProperties: {$push: [{name: nextNonKeyColumn, type: retrievePrimaryKeyColumnType(nextNonKeyColumn)}]}}});
        });
    };

    const removePrimaryKey = (idx) => event => {
        setNewReferenceDataSet((prevState) => {
            return update(prevState, {properties: {keyProperties: {$splice: [[idx, 1]]}}});
        });
    };
    

    // side-effects
    useEffect(() => {
        let onUploadTimeout;
        if (isStep2Finished && progress === RequestStatus.Successful) {
            onUploadTimeout = window.setTimeout(() => {close();}, 1500);
        }
        return () => {// cleanup
            window.clearTimeout(onUploadTimeout);
        }
    }, [isStep2Finished, progress, close]);    

    useEffect(() => {
        if(isForNewReferenceDataSet && itemsJSON) { // to set the first key in the uploaded JSON as the first primary key by default whenever itemsJSON changes
            setNewReferenceDataSet((prevState) => {
                return update(prevState, {properties: {keyProperties: {$set: [{name: memoizedColumnsFromJSON[0], type: retrievePrimaryKeyColumnType(memoizedColumnsFromJSON[0])}]}}});
            });
        }
    }, [itemsJSON, isForNewReferenceDataSet, retrievePrimaryKeyColumnType, memoizedColumnsFromJSON]);    
    
    const title =  isForNewReferenceDataSet ? t('referenceData.addNewDataSet') : t('referenceData.bulkImportItems');
    newReferenceDataSet?.updateErrors();
    const errors = newReferenceDataSet?.errors;
    return <ModalContainer isOffModalAutoClosingEnabled={false} onClose={close} title={title} className={cx('tsmEntityModal')} contentPositionContainerClassName={cx('tsmModalContentPositionContainer')} wrapperClassName={cx('tsmModalContentContainer')} contentClassName={cx('tsmModalContent')}>
            <div className={cx('modalContent')}>
                <div className={cx(isForNewReferenceDataSet ? 'entityContainer' : 'bulkUploadContainer')}>
                    <div className={cx('form')}>
                        <div className={cx('build-area')}>
                            {isForNewReferenceDataSet &&
                                <div className={cx('block')}>
                                    <div className={cx('title')}>{t('referenceData.nameTitle')}</div> 
                                    <input type="text" disabled={isStep1Finished} value={newReferenceDataSet?.name} onChange={handleNameChange}/>
                                    {errors?.[ReferenceDataErrors.NameEmpty] && <div id={nameErrorFieldID} role="alert" className={cx('error-message', '_base-fade-in')}>{t('referenceData.validations.nameEmpty')}</div>}
                                    {(newReferenceDataSet?.name !== "" && errors?.[ReferenceDataErrors.NameNotValid]) && <div id={nameErrorFieldID} role="alert" className={cx('error-message', '_base-fade-in')} title={t('referenceData.validations.nameNotValid')}>{t('referenceData.validations.nameNotValid')}</div>}
                                    {errors?.[ReferenceDataErrors.NameExists] && <div id={nameExistsErrorFieldID} role="alert" className={cx('error-message', '_base-fade-in')}>{t('referenceData.validations.nameExists')}</div>}
                                </div>
                            }
                            <div className={cx('block', 'long')}>
                                <div className={cx('title')}> {t('referenceData.uploadJSONTitle')}</div>
                                <div className={cx('file-upload-area')}>
                                    <button id="myFileLabel" className={cx('_base-secondary-button', 'label-button')}
                                            aria-describedby={fileUploadErrorFieldID}
                                            onClick={() => {document.getElementById(cx("refDataFileUpload")).click()}}>
                                        {t('tsm.chooseFile')}
                                    </button>
                                    <input id={cx("refDataFileUpload")} type="file" onChange={(e) => handleFiles(e.target.files[0])}/>
                                    <span id="myFileName"> {t('tsm.noFileChosen')}</span>
                                </div>
                                {fileUploadStatus !== null && 
                                    <div className={cx('upload-status', fileUploadStatus)}>
                                        {fileUploadStatus === FileUploadStatus.Successful ? 
                                                <Icon id={'iconStatusOK-' + theme} className={cx('icon16')}/>
                                            :
                                                <Icon id={'iconStatusError-' + theme} className={cx('icon16')}/>
                                        }
                                        <span role="alert"> {fileUploadMessage}</span>
                                    </div>
                                }
                                <textarea className={cx('jsonTextArea', fileUploadStatus !== FileUploadStatus.Successful ? 'tall' : '')} id={cx("JSONText")} disabled aria-label={t('tsm.bulkUploadTextField')}/>
                                {fileUploadStatus === null && <div id={fileUploadErrorFieldID} role="alert" className={cx('error-message', '_base-fade-in', 'relativePositioned')}>{t('referenceData.validations.file')}</div>}
                            </div>
                            {isForNewReferenceDataSet && fileUploadStatus === FileUploadStatus.Successful &&
                                <>
                                    <div className={cx('block', 'primaryKeysBlock')}>
                                        <div className={cx('title')}>{t('referenceData.primaryKeyTitle')}</div>
                                        <div className={cx('grid')}>
                                            {newReferenceDataSet?.properties?.keyProperties.length > 0 &&
                                                <table>
                                                    <thead>
                                                        <tr className={cx('headers')}>
                                                            <th className={cx('header')}><span className={cx('header-text')}>{t('referenceData.keyName')}</span></th>
                                                            <th className={cx('header')}><span className={cx('header-text')}>{t('referenceData.keyType')}</span></th>
                                                            <th className={cx('header', 'primaryKeysTableActionHeader')}></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {newReferenceDataSet?.properties?.keyProperties.map((kProp, idx) => 
                                                            <tr key={`keyProp_${idx}`}>
                                                                <td className={cx('primaryKeySelection')}>
                                                                    <select disabled={fileUploadStatus !== FileUploadStatus.Successful || isStep1Finished} value={kProp.name} onChange={handlePrimaryKeyChange(idx)}>
                                                                        {memoizedColumnsFromJSON.filter(c => kProp.name === c || !newReferenceDataSet?.properties?.keyProperties.find(k => k.name === c))?.map((k, i) => <option key={idx + '_' + k} value={k}>{k}</option>)}
                                                                    </select>
                                                                </td>
                                                                <td>
                                                                    <DropdownMenuContainer 
                                                                        dropdownMenuOptions={Object.values(ReferenceDataPrimaryKeyTypes).map((t: any, i) => 
                                                                            [<Icon id={Utils.referenceDataItemTypeToIconName(t)} className={cx('icon16')}></Icon>, 
                                                                                t, 
                                                                                () => handlePrimaryKeyTypeChange(idx, t),
                                                                                {disabled: isStep1Finished}
                                                                            ]
                                                                        )}
                                                                        dropdownMenuSide='right'
                                                                        containerClassName={cx('typeSelectionMenuWrapper')}
                                                                        menuClassName={cx("typeSelectionMenu")}
                                                                        buttonClassName={cx("typeSelectionButton")}
                                                                        menuItemClassName={cx("typeSelectionMenuItem")}
                                                                        menuButtonLabel={``}
                                                                        selectedValue={kProp.type}
                                                                    >
                                                                        <div className={cx('typeSelectionButtonContent')}>
                                                                            <Icon id={Utils.referenceDataItemTypeToIconName(kProp.type)} className={cx('icon16')}></Icon>
                                                                            <span className={cx('typeName')}>{kProp.type}</span>
                                                                            <Icon id='chevron' className={cx('chevron')}></Icon>
                                                                        </div>
                                                                    </DropdownMenuContainer>
                                                                </td>
                                                                <td>
                                                                    {(idx !== 0 && !isStep1Finished) &&
                                                                        <button className={cx('action-button')} onClick={removePrimaryKey(idx)}>
                                                                            <Icon id={"iconClose-" + theme} className={cx('icon16')}/>
                                                                        </button>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            }
                                        </div>
                                        {!isStep1Finished && newReferenceDataSet?.properties?.keyProperties.length < maxReferenceDataSetKeyPropertiesLimit && newReferenceDataSet?.properties?.keyProperties.length < memoizedColumnsFromJSON.length  &&
                                            <div className={cx('sub-button')}>
                                                <button onClick={addPrimaryKey}>
                                                    <Icon id={'iconAddBlue'} className={cx('icon16')}/>
                                                    <span>{t('referenceData.addPrimaryKey')}</span>
                                                </button>
                                            </div>
                                        }
                                    </div>
                                    <div className={cx('block', 'dataStringComparisonBlock')}>
                                        <div className={cx('title')}>{t('referenceData.dataStringComparisonTitle')}</div>
                                        <select disabled={fileUploadStatus !== FileUploadStatus.Successful} value={newReferenceDataSet?.properties?.dataStringComparisonBehavior} onChange={handleDataStringComparison}>
                                            {(fileUploadStatus === FileUploadStatus.Successful && itemsJSON) &&
                                                <>
                                                    <option key={'Ordinal'} value={DataStringComparisonBehavior.Ordinal}>Case sensitive</option>
                                                    <option key={'OrdinalIgnoreCase'} value={DataStringComparisonBehavior.OrdinalIgnoreCase}>Case insensitive</option>
                                                </>
                                            }
                                        </select>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
                <div className={cx('tsmModalBottom')}>
                    <div className={cx('buttonWrapper')}>
                        <button aria-label={t('cancel')} className={cx('cancelButton')} onClick={close}>
                            {t('cancel')}
                        </button>
                        {newReferenceDataSet?.hasErrors() || fileUploadStatus !== FileUploadStatus.Successful ?
                                <TooltipableContainer tooltip={isForNewReferenceDataSet ? t('referenceData.fixErrorsToSave') : t('referenceData.fixErrorsToUpload')} position={'bottom'}>
                                    <button aria-label={t('upload')} className={cx('saveButton', 'disabled')} aria-disabled="true" disabled>{isForNewReferenceDataSet ? t('save') : t('upload')}</button>
                                </TooltipableContainer>
                            :
                            <button aria-label={t('upload')} className={cx('saveButton')} onClick={isForNewReferenceDataSet && !isStep1Finished ? create : upload}>{isForNewReferenceDataSet ? t('save') : t('upload')}</button>
                        }
                    </div>
                </div>
                {(progress === RequestStatus.Publishing && ((isForNewReferenceDataSet && isStep1Finished) || !isForNewReferenceDataSet)) &&
                    <Placeholder progress={bulkUploadProgress}>
                        <div>{t('referenceData.uploadingItems')}</div>
                    </Placeholder>
                }
                <ProgressMessageField props={{cx, t, theme, progress, progressMessageFieldID, method: RequestMethods.Put, errorCode, errorMessage}}/>
            </div>
        </ModalContainer>
}

export const ProgressMessageField = ({props}) => { // this component is also used by ReferenceData component as well
    const {cx, t, theme, progress, progressMessageFieldID, method, errorCode, errorMessage} = props;
    return <div className={cx('progress-messages')}>
            {progress !== RequestStatus.Idle &&
                <div className={cx('actions-return-messagebox', {error: progress === RequestStatus.Error || progress === RequestStatus.Unsuccessful}, {success: progress === RequestStatus.Successful}, {warning: progress === RequestStatus.Partial_Success})}>
                    {progress === RequestStatus.Publishing || progress === RequestStatus.Finalizing ?
                        <Icon id={'info'} className={cx('icon16')}/>
                        : <Icon id={(progress === RequestStatus.Error || progress === RequestStatus.Unsuccessful ? ('iconStatusError-' + theme) : (progress === RequestStatus.Partial_Success ? 'warning' : ('iconStatusOK-' + theme)))} className={cx('icon16')}/>
                    }
                    <span id={progressMessageFieldID} role="alert">
                        {
                            progress === RequestStatus.Successful ? 
                                method === RequestMethods.Put ? 
                                    t('referenceData.messages.putSuccess') 
                                    : t('referenceData.messages.deleteSuccess')
                            : progress === RequestStatus.Partial_Success ? // valid only for put method
                                t('referenceData.messages.putPartialSuccess') + ": " + errorMessage
                            : progress === RequestStatus.Unsuccessful ? 
                                method === RequestMethods.Put ? 
                                    t('referenceData.messages.putUnsuccess') + ": " + (errorCode === "InvalidInput" ? errorMessage : t('referenceData.errors.' + errorCode) + ' ' + t('details') + ':\n' + errorMessage)
                                    : t('referenceData.messages.deleteUnsuccess') + ": " + (errorCode === "InvalidInput" ? errorMessage : t('referenceData.errors.' + errorCode) + ' ' + t('details') + ':\n' + errorMessage)      
                            : progress === RequestStatus.Error ?
                                method === RequestMethods.Put ? 
                                    t('referenceData.messages.putError') + ": " + ((errorCode === "ErrorTrace" || errorCode === 'InvalidInput') ? errorMessage : t('referenceData.errors.' + errorCode))
                                    : method === RequestMethods.Get ?
                                        t('referenceData.messages.getError') + ": " + ((errorCode === "ErrorTrace" || errorCode === 'InvalidInput') ? errorMessage : t('referenceData.errors.' + errorCode))
                                        : t('referenceData.messages.deleteError') + ": " + ((errorCode === "ErrorTrace" || errorCode === 'InvalidInput') ? errorMessage : t('referenceData.errors.' + errorCode))
                            : progress === RequestStatus.Publishing ? t('tsm.publishing') : ""
                                
                        }
                    </span>
                </div>
            }
        </div>
}


import { ObjectMapper } from './ObjectMapper';

const markerRegex = /\$event\.\[/;
const markerStrLength = '$event.['.length;
const NewTsxRestrictedCharacters = ['\'', '\\'];

export class MigrationResult {
    value: string;
    errors: string[];
}

export function migrateOldToNewTsx(tsx: string): string {
    let migrated = tsx.trim();

    for(let match of extractOldEventStatements(tsx)) {
        let { oldTsx, migratedTsx } = match;
        migrated = migrated.replace(oldTsx, migratedTsx);
    }

    return migrated;
}

// Returns list of saved queries that need to be migrated.
export function filterSavedQueriesByNeedToMigrate(
    savedQueries: Array<any>, 
    currentEnvironmentFqdn: string,
    isUserContributor: boolean): Array<any> {
    let result = [];
    const doesTsqUseOldTsx = tsq => {
        let { variableObject } = tsq;

        return variableObject && Object.values(variableObject).some(isVariableInOldSyntax);
    };

    for(let savedQuery of savedQueries) {
        // Ignore non LTS environments
        if(!["RDX_20181120_Q", "RDX_20200713_Q"].includes(savedQuery.clientDataType)) {
            continue;
        }

        // Ignore queries that don't belong to the currently selected environment
        if(ObjectMapper.extractNestedValue(savedQuery, 'clientData.appSettings.selectedEnvironmentFqdn') !== currentEnvironmentFqdn) {
            continue;
        }

        // If the user is not a contributor for this environment, ignore non-personal queries
        if(!isUserContributor && savedQuery.sharingScope !== "Personal") {
            continue;
        }

        // Ignore queries that don't contain TSX syntax that needs to be migrated
        let timeSeriesQueries = ObjectMapper.extractNestedValue(savedQuery, 'clientData.analytics.timeSeriesQueries');
        if(timeSeriesQueries && timeSeriesQueries.some(doesTsqUseOldTsx)) {
            result.push(savedQuery);
        }
    }

    return result;
}

export function migrateSavedQuery(savedQuery): MigrationResult {
    return transformSavedQuery(savedQuery, isOldTsx, migrateOldToNewTsx);
}

export function migrateTsqToNewTsx(tsq) {
    let mapper = new ObjectMapper(tsq, {...tsq});

    // If the Tsq doesn't have an aggregateSeries property, or if that aggregateSeries property
    // doens't have an inlineVariable return the unmodified tsq.
    if(ObjectMapper.extractNestedValue(tsq, 'aggregateSeries.inlineVariables') === undefined) {
        return tsq;
    }

    mapper.mapObject({
        sourcePath: 'aggregateSeries.inlineVariables',
        targetPath: 'aggregateSeries.inlineVariables',
        isRequired: true,
        valueMapper: variable => {
            // If the object contains no old tsx, skip it.
            if((variable.value && !isOldTsx(variable.value.tsx)) 
            && (variable.aggregation && !isOldTsx(variable.aggregation.tsx)) 
            && (variable.filter && !isOldTsx(variable.filter.tsx))) { 
                return variable;
            }

            let migrated = {...variable};
            if(variable.value) {
                migrated.value.tsx = migrateOldToNewTsx(variable.value.tsx);
            }
            if(variable.aggregation) {
                migrated.aggregation.tsx = migrateOldToNewTsx(variable.aggregation.tsx);
            }
            if(variable.filter) {
                migrated.filter.tsx = migrateOldToNewTsx(variable.filter.tsx);
            }

            return migrated;
        }
    });

    return mapper.errors.length > 0 ? tsq : mapper.target;
}

export function transformSavedQuery(savedQuery, detectionFunc, tranformFunc): MigrationResult {
    let mapper = new ObjectMapper(savedQuery, {...savedQuery});
    let wasThereAnError = false;

    mapper.mapArray({
        sourcePath: 'clientData.analytics.timeSeriesQueries',
        targetPath: 'clientData.analytics.timeSeriesQueries',
        isRequired: true,
        validators: [(x: any) => Array.isArray(x)],
        elementMapper: timeSeriesQuery => {
            let tsqMapper = new ObjectMapper(timeSeriesQuery, {...timeSeriesQuery});
            
            tsqMapper.mapObject({
                sourcePath: 'variableObject',
                targetPath: 'variableObject',
                isRequired: true,
                valueMapper: obj => {
                    // If the object contains no old tsx, skip it.
                    if((obj.value && !detectionFunc(obj.value.tsx)) 
                        && (obj.aggregation && !detectionFunc(obj.aggregation.tsx)) 
                        && (obj.filter && !detectionFunc(obj.filter.tsx))) { 
                        return obj;
                    }

                    let migrated = {...obj};
                    if(obj.value) {
                        migrated.value.tsx = tranformFunc(obj.value.tsx);
                    }
                    if(obj.aggregation) {
                        migrated.aggregation.tsx = tranformFunc(obj.aggregation.tsx);
                    }
                    if(obj.filter) {
                        migrated.filter.tsx = tranformFunc(obj.filter.tsx);
                    }

                    return migrated;
                }
            });

            if(tsqMapper.errors.length > 0) {
                wasThereAnError = true;
                mapper.errors.concat(tsqMapper.errors);
                return null;
            }

            return tsqMapper.target;
        } 
    });

    // If there was an error return the original value to avoid returning
    // a potentially malformed answer.
    return {
        value: wasThereAnError ? savedQuery : mapper.target,
        errors: mapper.errors
    };
}

export function isVariableInOldSyntax(variable: any): boolean {
    return (variable.value && isOldTsx(variable.value.tsx))
            || (variable.aggregation && isOldTsx(variable.aggregation.tsx))
            || (variable.filter && isOldTsx(variable.filter.tsx));
}

export function filterTypesByNeedToMigrate(types: Array<any>, isUserContributor: boolean): Array<any> {
    if (!isUserContributor) {
        return [];
    }

    let typesThatNeedsMigration = [];
    types.forEach(t => {
        if (Object.values(t.variables).some(isVariableInOldSyntax)) {
            typesThatNeedsMigration.push(t);
        }
    });
    return typesThatNeedsMigration;
}

export function migrateTSMVariables(types: Array<any>): MigrationResult {
    const VariableAggregationOperations_Numeric_NonInterpolation = ["min($value)", "max($value)", "sum($value)", "avg($value)", "first($value)", "last($value)", "stdev($value)", "median($value)"];
    let errors = [];
    let newTypes = [];
    let wasThereAnError = false;
    types.forEach((t) => {
        let mapper = new ObjectMapper(t, {...t});

        mapper.mapObject({
            sourcePath: 'variables',
            targetPath: 'variables',
            isRequired: true,
            valueMapper: variable => {
                if((variable.value && !isOldTsx(variable.value.tsx)) 
                    && (variable.aggregation && !isOldTsx(variable.aggregation.tsx)) 
                    && (variable.filter && !isOldTsx(variable.filter.tsx))) { 
                    return variable;
                }
    
                let migrated = {...variable};
                if(variable.value) {
                    migrated.value.tsx = migrateOldToNewTsx(variable.value.tsx);
                }
                if(variable.aggregation) {
                    migrated.aggregation.tsx = migrateOldToNewTsx(variable.aggregation.tsx);
                }
                if(variable.filter) {
                    migrated.filter.tsx = migrateOldToNewTsx(variable.filter.tsx);
                }
                if (variable.kind === "numeric" && 
                    variable.aggregation && 
                    VariableAggregationOperations_Numeric_NonInterpolation.includes(variable.aggregation.tsx) &&
                    variable.interpolation) {
                        delete migrated.interpolation;
                }
                
                return migrated;
            }
        });

        if(mapper.errors.length > 0) {
            wasThereAnError = true;
            errors.concat(mapper.errors);
            return null;
        }

        newTypes.push(mapper.target);
    });

    // If there was an error return the original value to avoid returning
    // a potentially malformed answer.
    return {
        value: JSON.stringify(wasThereAnError ? types : newTypes),
        errors: errors
    };
}

export function escapeName(name: string): string {
    let escapedCharacters: string[] = [];

    for(let char of name.replace(/]]/g, "]")) {
        if(NewTsxRestrictedCharacters.includes(char)) {
            escapedCharacters.push('\\');
        }

        escapedCharacters.push(char);
    }

    return escapedCharacters.join('');
}

export function isOldTsx(tsx: string): boolean {
    // Create a regex in order to ensure clean state for .exec() call and
    // to set global option.
    let regex = new RegExp(markerRegex, 'g');
    let match: RegExpExecArray;

    // eslint-disable-next-line
    while(match = regex.exec(tsx)) {
        let endIndex: number = match.index + markerStrLength;

        while(endIndex < tsx.length) {
            if(tsx[endIndex] !== ']') {
                endIndex++;
                continue;
            }

            // Check if we've reached the end of an $event statement. 
            if(endIndex === tsx.length - 1 || tsx[endIndex + 1] !== ']') {
                return true; 
            }
            
            // If we're here that means we found two closing square brackets
            // in a row. Skip over them and continue.
            endIndex += 2;
        }
    }

    return false;
}

function extractOldEventStatements(tsx: string): any[] {
    // Create a regex in order to ensure clean state for .exec() call and
    // to set global option.
    let regex = new RegExp(markerRegex, 'g');
    let match: RegExpExecArray;
    let results = [];

    // eslint-disable-next-line
    while(match = regex.exec(tsx)) {
        let startIndex: number, endIndex: number, identifierStartIndex: number;
        startIndex = match.index;
        identifierStartIndex = match.index + markerStrLength;
        endIndex = match.index + markerStrLength;

        while(endIndex < tsx.length) {
            if(tsx[endIndex] !== ']') {
                endIndex++;
                continue;
            }

            // Check if we've reached the end of an $event statement. 
            if(endIndex === tsx.length - 1 || tsx[endIndex + 1] !== ']') {
                let identifier = tsx.substring(identifierStartIndex, endIndex);
                let escaped = escapeName(identifier);
                results.push({
                    oldTsx: tsx.substring(startIndex, endIndex + 1),
                    migratedTsx: `$event['${escaped}']`
                });
                
                break; 
            }
            
            // If we're here that means we found two closing square brackets
            // in a row. Skip over them and continue.
            endIndex += 2;
        }
    }

    return results;
}
import * as React from 'react';
import classNames from 'classnames/bind';
import NewFeatureSplashContainer from '../../NewFeatureSplash.container';
import Icon from '../../../Icon/Icon';
import { LocalStorageKeys } from '../../../../../constants/Enums';
const cx = classNames.bind(require('./NewExplorerSplash.module.scss'));

interface Props { t: any; theme: string; hideNewExplorerSplash: any; checkMigrationIsNecessary: any; } 
interface State { carouselPage: number, pageSlideDirection: string; burning: boolean;}

export default class NewExplorerSplash extends React.Component<Props, State> {
    private firstLinkRef: any;
    private totalCarouselPages: number = 3;
    constructor(props: Props) { 
        super(props);
        this.firstLinkRef = React.createRef();
        this.state = {carouselPage: 0, pageSlideDirection: 'left', burning: true};
    }

    setCarouselPage = i => {
        this.setState({carouselPage: i, pageSlideDirection: this.state.carouselPage < i ? 'left' : (this.state.carouselPage === i ? '' : 'right'), burning: false});
    }

    generateDots = () => {
        let dots = [];
        for(var i = 0; i < this.totalCarouselPages; i++){
            dots.push(i);
        }
        dots = dots.map(i => <div key={i} onClick={() => this.setCarouselPage(i)} className={cx('dot', {filled: (this.state.carouselPage === i)})}></div>);
        return dots;
    }

    getCarouselPages = () => {
        let page1 = <div key={0} className={cx('page', this.state.pageSlideDirection, {shown: this.state.carouselPage === 0})}>
            <div className={cx('page1img')}></div>
            <div className={cx('text')}>{this.props.t('newExplorerSplash.page1')}</div>
        </div>;
        let page2 = <div key={1} className={cx('page', 'page2', this.state.pageSlideDirection, {shown: this.state.carouselPage === 1})}>
            <div className={cx('page2img')}></div>
            <div className={cx('text')}>{this.props.t('newExplorerSplash.page2')}</div>
        </div>;
        let page3 = <div key={2} className={cx('page', 'page3', this.state.pageSlideDirection, {shown: this.state.carouselPage === 2})}>
            <div className={cx('images')}>
                <div className={cx('page3imgaWrapper')} onClick={() => window.open('https://insights.timeseries.azure.com/demo')}>
                    <div className={cx('page3aimg')}></div>
                    <div className={cx('text')}>{this.props.t('newExplorerSplash.page3a')}</div>
                </div>
                <div className={cx('page3imgbWrapper')} onClick={() => window.open('https://aka.ms/learnatsi')}>
                    <div className={cx('page3bimg')}></div>
                    <div className={cx('text')}>{this.props.t('newExplorerSplash.page3b')}</div>
                </div>
            </div>
            <div className={cx('text')}>{this.props.t('newExplorerSplash.page3')}</div>
        </div>;
        return [page1, page2, page3];
    }

    render () {
        return <NewFeatureSplashContainer shorter={true} mustDismissWithButton={true} noFivehead={true} noButtons={true} firstLinkRef={this.firstLinkRef} title={''} hideSplash={() => {this.props.hideNewExplorerSplash(); this.props.checkMigrationIsNecessary();}} localStorageKey={LocalStorageKeys.NewExplorerSplashSeen}>
                    <div className={cx('carousel')}>
                        {this.state.carouselPage !== 0 && <button onClick={() => {this.setCarouselPage(this.state.carouselPage - 1)}} className={cx('leftButton')}>
                            <Icon className={cx('icon16')} id="chevron"></Icon>
                        </button>}
                        {this.state.carouselPage !== this.totalCarouselPages - 1 && <button onClick={() => {this.setCarouselPage(this.state.carouselPage + 1)}} className={cx('rightButton')}>
                            <Icon className={cx('icon16')} id="chevron"></Icon>
                        </button>}
                        {this.getCarouselPages()}
                        <div className={cx('dots')}>
                            {this.generateDots()}
                        </div>
                    </div>
                </NewFeatureSplashContainer>
    }
}
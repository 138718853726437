import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import {SET_ADDITIONAL_FIELD_VISIBILITY, SET_ADDITIONAL_FIELD_AS_ONLY_VISIBLE, 
        SERIALIZE_STATE_TO_LOCAL_STORAGE, SET_VISIBLE_TSQ_FIELDS_IN_LOCAL_STORAGE, SET_ALL_ADDITIONAL_FIELD_VISIBILITY} 
    from '../../../constants/ActionTypes';
import { getTheme, getSelectedEnvironmentId } from '../../state/reducers/AppSettingsReducer';
import { getTSQFields, getModalOptions } from '../../state/reducers/AnalyticsReducer';
import WellColumnsSettings from './WellColumnsSettings';

const mapStateToProps = state => {
    return { 
        tsqFields: getTSQFields(state),
        envId: getSelectedEnvironmentId(state),
        theme: getTheme(state),
        modalOptions: getModalOptions(state)
    };
};

const mapDispatchToProps = dispatch => ({
    setFieldVisibility: (envId, field, visibility) => {
        dispatch({type: SET_ADDITIONAL_FIELD_VISIBILITY, payload: {field: field, value: visibility}});
        dispatch({type: SERIALIZE_STATE_TO_LOCAL_STORAGE});
        dispatch({type: SET_VISIBLE_TSQ_FIELDS_IN_LOCAL_STORAGE, payload: envId});
    },
    setFieldAsOnlyVisible: (envId, field) => {
        dispatch({type: SET_ADDITIONAL_FIELD_AS_ONLY_VISIBLE, payload: field});
        dispatch({type: SERIALIZE_STATE_TO_LOCAL_STORAGE});
        dispatch({type: SET_VISIBLE_TSQ_FIELDS_IN_LOCAL_STORAGE, payload: envId});
    },
    setAllFieldsVisibility: (envId, visibility) => {
        dispatch({type: SET_ALL_ADDITIONAL_FIELD_VISIBILITY, payload: visibility});
        dispatch({type: SERIALIZE_STATE_TO_LOCAL_STORAGE});
        dispatch({type: SET_VISIBLE_TSQ_FIELDS_IN_LOCAL_STORAGE, payload: envId});
    }
});

const WellColumnsSettingsContainer = translate()(connect(mapStateToProps, mapDispatchToProps)(WellColumnsSettings));

export default WellColumnsSettingsContainer;

import * as React from 'react';
import classNames from 'classnames/bind';
import { ChartTypes, ModalTypes, CaretDirections, RawEventsTakeValues, QueryTypes } from '../../../constants/Enums';
import Utils from '../../services/Utils';
import PbiModalContainer from './PbiModal/PbiModal.container';
import TelemetryService from '../../services/TelemetryService';
import AdvancedExploration from '../AdvancedExploration/AdvancedExploration';
import { ICommandBarItemProps, FocusZoneDirection} from '@fluentui/react';
import FluentCommandBarContainer from '../FluentCommandBar/FluentCommandBar.container';
import { CommandBarUtilities } from '../FluentCommandBar/FluentCommandBar';
import { chartCommandingIcons } from '../../../constants/CustomFluentIconPacks';
const cx = classNames.bind(require('./ChartCommanding.module.scss'));

interface Props {t: any; theme: string; queries: any; zoom: any; setMaintainedIntervalSize: any; 
    applySearchSpan: any; maintainedIntervalSize: any; unzoomStack: any; chartType: any;
    setChartType: any; hide: any; setModalOptions: any; closeModal:any; modalOpen: any;
    modalOptions: any; activeVisualizationReference: any; setStackState: any;
    linechartStackState: any; environmentIsLts: boolean; validBucketSizes: Array<any>; searchSpan: any; 
    setSearchSpan: any; getQueryResults: any; timeSeriesQueries: Array<any>; selectedEnvironment: any;
    environmentHasWarmStore: boolean; getEvents: any; focusAddMarkerButtonTrigger: any; unsetFocusAddMarkerButtonTrigger: any;
    toggleJumbo: any; isJumbotronActive: boolean; setIsGetSeriesEnabled:any; isGetSeriesEnabled: boolean; setChartSettingsToBasics: any;
    queriesType: QueryTypes; }

interface State { pbiModalVisible: boolean; };

class ChartCommanding extends React.Component<Props, State> {
    private chartSelectionID = Utils.guid();
    private chartSelectionButtonRef;
    private stackStates;
    private pbiQueryId = Utils.guid();
    private moreActionsContainerRef;

    constructor(props: Props) {
        super(props);
        this.stackStates = Utils.getStackStates();
        this.state = {
            pbiModalVisible: false,
        };
        this.chartSelectionButtonRef = React.createRef();
        this.moreActionsContainerRef = React.createRef();
    }

    private focusSettings (e) {
        e.preventDefault();
        e.stopPropagation();
        this.props.setChartSettingsToBasics();
    }

    private getBucketSizeText () {
        return Utils.intervalSizeText(this.props.queries, QueryTypes.TimeSeries, this.props.t('rawData'), this.props.searchSpan.bucketSize);
    }

    zoomAndSetMaintainInterval = (fromMillis, toMillis, direction) => {
        this.props.zoom(fromMillis, toMillis, direction);
        if (this.props.queries && this.props.queries.length !== 0) {
            if (this.props.maintainedIntervalSize == null) {
                this.props.setMaintainedIntervalSize(this.props.queries[0].searchSpan.bucketSize);
            }
        } else {
            this.props.setMaintainedIntervalSize(null);
        }
        this.props.applySearchSpan();
    };

    openScatterPlotModal = () => {
        let bcr = document.getElementById(this.chartSelectionID).getBoundingClientRect();
        let focusModalX = bcr.left + 18 + AdvancedExploration.advancedExploreDimensions.width / 2;
        let focusModalY = window.innerHeight - bcr.bottom;
        this.props.setModalOptions(0, focusModalX, focusModalY, ModalTypes.ScatterPlot, true, CaretDirections.TopLeft, this.chartSelectionButtonRef.current);
    }

    private handleClosePbiModal = () => {
        this.setState({ pbiModalVisible: false });
    }

    showGrid = () => {        
        this.props.activeVisualizationReference.showGrid();
    }

    getActiveChartString = () => {
        if(this.props.chartType === ChartTypes.Scatterplot || (this.props.modalOpen && this.props.modalOptions.modalType === ModalTypes.ScatterPlot)){
            return this.props.t('commanding.scatterplot');
        }
        else if(this.props.chartType === ChartTypes.Heatmap){
            return this.props.t('commanding.heatmap');
        } else{
            return this.props.t('commanding.linechart');
        }
    }

    getActiveChartIconName = () => {
        if(this.props.chartType === ChartTypes.Scatterplot || (this.props.modalOpen && this.props.modalOptions.modalType === ModalTypes.ScatterPlot)){
            return ChartTypes.Scatterplot + this.props.theme;
        }
        else if(this.props.chartType === ChartTypes.Heatmap){
            return ChartTypes.Heatmap;
        } else{
            return ChartTypes.Linechart
        }
    }

    getActiveStackString = () => {
        return this.props.t('commanding.' + this.props.linechartStackState);
    }

    getActiveStackIconName = () => {
        if(this.props.linechartStackState === this.stackStates.Stacked)
            return this.stackStates.Stacked;
        else if(this.props.linechartStackState === this.stackStates.Shared)
            return this.stackStates.Shared;
        else 
        return this.stackStates.Overlap;
    }

    render(){
        if(this.props.hide) return null;
        return (
            <div className={cx('commandBarWrapper', this.props.isJumbotronActive ? 'jumbo': '')}>
                {/*  Use CommandBarUtilities.style('example') to add custom classNames.
                    .example{} must be referenced in FluentCommandBar.module.scss where
                    all FluentCommandBar styles are set */}
               <FluentCommandBarContainer 
                    leftItems={this.getCommandBarLeftItems()} // snap left
                    rightItems={this.getCommandBarRightItems()} // snap right
                    icons={chartCommandingIcons} // pass in custom icons to register
               />
                {this.state.pbiModalVisible && 
                    <PbiModalContainer 
                        moreActionsElement={this.moreActionsContainerRef.current}
                        pbiQueryId={this.pbiQueryId}
                        handleClosePbiModal={this.handleClosePbiModal} /> }
            </div>
        );
    }

    // Items to render on left of command bar -- will collapse into overflow menu if constrained
    getCommandBarLeftItems: () => ICommandBarItemProps[] = () => [
    {
        key: 'chartType',
        ariaLabel: `${this.props.t('commanding.chartSelection')} ${this.getActiveChartString()}`,
        text: this.getActiveChartString(),
        id: this.chartSelectionID,
        className: CommandBarUtilities.style('commandItem'), // Bind styles with CommandBarUtilities.  Will use FluentCommandBar.module.scss stylesheet.
        componentRef: this.chartSelectionButtonRef,
        iconProps: { iconName: this.getActiveChartIconName() }, // Custom icon to display.  Name must match icon passed into <FluentCommandBar /> icons prop
        subMenuProps: {
            styles: CommandBarUtilities.getMenuStyles(this.props.theme, '115.59px'), // Applies theme and width to submenu callout
            className: CommandBarUtilities.style('dropdownMenu'), // Styling for dropdown menu
            onMenuOpened: () => CommandBarUtilities.themifyFloatingLayer(this.props.theme), // Callback to apply 'theme-dark' | 'theme-light' to floating layer
            items: [ // Sub menu items
                { 
                    key: 'linechart', 
                    text: this.props.t('commanding.linechart'), 
                    iconProps: { iconName: 'linechart' },
                    onClick: () => {
                        this.props.setChartType(ChartTypes.Linechart); 
                        if(this.props.environmentIsLts) {
                            this.props.getQueryResults();
                        }
                    } 
                },
                { 
                    key: 'heatmap', 
                    text: this.props.t('commanding.heatmap'), 
                    iconProps: { iconName: 'heatmap' },
                    onClick: () => {
                        this.props.setChartType(ChartTypes.Heatmap); 
                        if(this.props.environmentIsLts) {
                            this.props.getQueryResults();
                        }
                    }
                },
                { 
                    key: 'scatterplot', 
                    text: this.props.t('commanding.scatterplot'), 
                    iconProps: { iconName: this.props.theme === 'light' ? ChartTypes.Scatterplot + 'light' : ChartTypes.Scatterplot + 'dark' },
                    onClick:() => this.openScatterPlotModal(),
                    disabled: !this.props.environmentIsLts
                },
            ],
        },
    },
    { 
        key: 'zoomPan', 
        text: this.props.t('commanding.zoom'), 
        ariaLabel: this.props.t('commanding.zoomAndPan'),
        iconProps: { iconName: 'zoom' }, 
        className: CommandBarUtilities.style('commandItem'),
        subMenuProps: {
            styles: CommandBarUtilities.getMenuStyles(this.props.theme),
            className: CommandBarUtilities.style('dropdownMenu','zoomPanSubMenu'),
            onMenuOpened: () => CommandBarUtilities.themifyFloatingLayer(this.props.theme),
            focusZoneProps: {direction: FocusZoneDirection.domOrder },
            items: [
                {
                    key: 'zoomOut',
                    ariaLabel: this.props.t('commanding.zoomOut'),
                    iconOnly: true,
                    iconProps: { iconName: this.props.theme === 'light' ? 'zoomOutLight' : 'zoomOutDark'},
                    onClick: (e) => {e.preventDefault(); this.zoomAndSetMaintainInterval(null, null, 'out')}
                },
                {
                    key: 'zoomIn',
                    ariaLabel: this.props.t('commanding.zoomIn'),
                    iconOnly: true,
                    iconProps: { iconName: this.props.theme === 'light' ? 'zoomInLight' : 'zoomInDark'},
                    onClick: (e) => {e.preventDefault(); this.zoomAndSetMaintainInterval(null, null, 'in')}
                },
                {
                    key: 'panLeft',
                    ariaLabel: this.props.t('commanding.moveLeft'),
                    iconOnly: true,
                    iconProps: { iconName: 'leftChevron'},
                    onClick: (e) => {e.preventDefault(); this.zoomAndSetMaintainInterval(null, null, 'left')}
                },
                {
                    key: 'panRight',
                    ariaLabel: this.props.t('commanding.moveRight'),
                    iconOnly: true,
                    iconProps: { iconName: 'rightChevron'},
                    onClick: (e) => {e.preventDefault(); this.zoomAndSetMaintainInterval(null, null, 'right')}
                },
                {
                    key: 'snapRight',
                    ariaLabel: this.props.t('snapRight'),
                    iconOnly: true,
                    iconProps: { iconName: 'rightChevron', className: CommandBarUtilities.style('chevronEnd') },
                    onClick: (e) => {e.preventDefault(); this.zoomAndSetMaintainInterval(null, null, 'snapRight')}
                }
            ]
        }
    },
    { 
        key: 'stackState', 
        ariaLabel: `${this.props.t('commanding.axisType')} ${this.props.t('commanding.' + this.props.linechartStackState)}`,
        text: this.getActiveStackString(), 
        iconProps: { iconName: this.getActiveStackIconName() }, 
        className: CommandBarUtilities.style('commandItem'),
        subMenuProps: {
            styles: CommandBarUtilities.getMenuStyles(this.props.theme, '93.92px'),
            className: CommandBarUtilities.style('dropdownMenu'),
            onMenuOpened: () => CommandBarUtilities.themifyFloatingLayer(this.props.theme),
            items: [
                { 
                    key: 'stacked', 
                    text: this.props.t('commanding.stacked'), 
                    iconProps: { iconName: this.stackStates.Stacked },
                    onClick: () => this.props.setStackState(this.stackStates.Stacked)
                },
                { 
                    key: 'shared', 
                    text: this.props.t('commanding.shared'), 
                    iconProps: { iconName: this.stackStates.Shared },
                    onClick: () => this.props.setStackState(this.stackStates.Shared)
                },
                { 
                    key: 'overlap', 
                    text: this.props.t('commanding.overlap'), 
                    iconProps: { iconName: this.stackStates.Overlap },
                    onClick: () => this.props.setStackState(this.stackStates.Overlap)
                },
            ],
        },
    },
    { 
        key: 'marker', 
        text: this.props.t('commanding.marker'), 
        disabled: (this.props.chartType !== ChartTypes.Linechart),
        onClick: () => this.props.activeVisualizationReference.addMarker(), 
        iconProps: { iconName: 'marker' },
        className: CommandBarUtilities.style('commandItem', this.props.chartType !== ChartTypes.Linechart ? 'iconDisabled' : ''),
    },
    { 
        key: 'settings', 
        text: this.props.t('commanding.settings'), 
        onClick: (e) => {this.focusSettings(e)}, 
        iconProps: { iconName: this.props.theme === 'light' ? 'gearLight' : 'gearDark'},
        className: CommandBarUtilities.style('commandItem'),
    },
    ];

    getIsUndoAvailable = () => (this.props.unzoomStack.length > 0 && this.props.queries.length > 0);

    // Items to render on right of command bar -- will not collapse into overflow menu
    getCommandBarRightItems: () => ICommandBarItemProps[] = () => {
        let rightItems = [
            {
                key: 'undo',
                disabled: !this.getIsUndoAvailable(),
                tooltipHostProps: {
                    className: CommandBarUtilities.style('iconOnlyTooltip'),
                    calloutProps: {
                        styles: CommandBarUtilities.getCalloutStyles(this.props.theme, false),
                    },
                    onTooltipToggle: () => CommandBarUtilities.themifyFloatingLayer(this.props.theme),
                },
                text: this.props.t('commanding.undo'),
                iconProps: { iconName: 'Undo' },
                onClick: () => {this.zoomAndSetMaintainInterval(null, null, 'unzoom');},
                iconOnly: true,
                ariaLabel: this.props.t('commanding.undo'),
                className: CommandBarUtilities.style('commandItem',`${!this.getIsUndoAvailable() ? 'iconDisabled' : ''}`),
            },
            {
                key: 'intervalSize',
                tooltipHostProps: {
                    className: CommandBarUtilities.style('iconOnlyTooltip'),
                    calloutProps: {
                        styles: CommandBarUtilities.getCalloutStyles(this.props.theme, false),
                    },
                    onTooltipToggle: () => {CommandBarUtilities.themifyFloatingLayer(this.props.theme)},
                },
                text: this.props.t('commanding.changeInterval'),
                onClick: () => this.props.setChartSettingsToBasics(),
                iconOnly: true,
                ariaLabel: this.props.t('commanding.changeInterval'),
                className: 'commandBarButtonAs',
                commandBarButtonAs: (item) => {
                    return <button onClick={() => this.props.setChartSettingsToBasics()} className={`${CommandBarUtilities.style('commandItem')} ms-Button ms-Button--commandBar is-disabled ms-CommandBarItem-link ${CommandBarUtilities.style('intervalSizeButton')}`}>
                        <span className={CommandBarUtilities.style('bracket')}>[</span>{this.getBucketSizeText()}<span className={CommandBarUtilities.style('bracket')}>]</span>
                    </button>;
                },

            },
            {
                key: 'jumbotron',
                tooltipHostProps: {
                    className: CommandBarUtilities.style('iconOnlyTooltip'),
                    calloutProps: {
                        styles: CommandBarUtilities.getCalloutStyles(this.props.theme, false),
                    },
                    onTooltipToggle: () => {CommandBarUtilities.themifyFloatingLayer(this.props.theme)},
                },
                text: this.props.isJumbotronActive ? this.props.t('commanding.collapseChart') : this.props.t('commanding.expandChart'),
                iconProps: { iconName: this.props.isJumbotronActive ? 'collapse' : 'expand' },
                onClick: () => this.props.toggleJumbo(),
                iconOnly: true,
                ariaLabel: this.props.isJumbotronActive ? this.props.t('commanding.collapseChart') : this.props.t('commanding.expandChart'),
                className: CommandBarUtilities.style('commandItem'),
            },
            {
                key: 'moreActions',
                text: this.props.t('commanding.moreActions'),
                iconProps: { iconName: 'Download' },
                componentRef: this.moreActionsContainerRef,
                className: CommandBarUtilities.style('commandItem'),
                subMenuProps: {
                    styles: CommandBarUtilities.getMenuStyles(this.props.theme),
                    className: CommandBarUtilities.style('dropdownMenu'),
                    onMenuOpened: () => CommandBarUtilities.themifyFloatingLayer(this.props.theme),
                    items: [
                        { 
                            key: 'download', 
                            text: this.props.t('commanding.download'), 
                            iconProps: { iconName: 'Download' }, 
                            onClick: () => {
                                this.props.chartType === ChartTypes.Scatterplot ? this.props.activeVisualizationReference.downloadAsCSV(true) :
                                this.props.activeVisualizationReference.downloadAsCSV()
                            }
                        },
                        { 
                            key: 'pbiConnect', 
                            text: this.props.t('commanding.pbiConnect'), 
                            iconProps: { iconName: this.props.theme === 'light' ? 'pbiLight' : 'pbiDark' },
                            onClick: () => {
                                this.props.setModalOptions(null, null, null, null, null, null, null);
                                this.setState({pbiModalVisible: true});
                            },  
                        },
                        { 
                            key: 'showGrid', 
                            text: this.props.t('commanding.showGrid'), 
                            iconProps: { iconName: 'gridThumbnail' },
                            onClick: () => {
                                this.showGrid()
                            },
                        },
                        { 
                            key: 'exploreRawEvents', 
                            text: this.props.t('commanding.exploreRawEvents'), 
                            iconProps: { iconName: 'rawEvents' },
                            onClick: () => {
                                if (this.props.environmentIsLts) {
                                    this.props.getEvents(Utils.getLtsExploreEventsPayload(this.props.queries, new Date(this.props.searchSpan.from).toISOString(), new Date(this.props.searchSpan.to).toISOString(), RawEventsTakeValues.Min));
                                } 
                                else {
                                    this.props.getEvents({predicateString: Utils.getSSkuExploreEventsPredicateString(this.props.queries, this.props.activeVisualizationReference.getVisibilityState())}, {}, (new Date(this.props.searchSpan.from)).valueOf(), (new Date(this.props.searchSpan.to)).valueOf());
                                }
                                TelemetryService.logUserAction('moreActionsExploreEvents');
                            }
                        },
                    ],
                },
            },
        ];
        return rightItems;
    } 
}

export default ChartCommanding;
import * as React from 'react';
import classNames from 'classnames/bind';
import Utils from '../../services/Utils';
import Icon from '../Icon/Icon';
import Placeholder from '../Placeholder/Placeholder';
import TelemetryService from '../../services/TelemetryService';
import TooltipableContainer from '../Tooltipable/Tooltipable.container';
import ModalContainer from '../Modal/Modal.container';
const cx = classNames.bind(require('./AvailabilityChart.module.scss'));

interface Props { t: any; availabilityDistribution: any; isLoading: boolean; theme: string; 
                  setSearchSpan: any; queries: Array<any>; searchSpan: any; compact: any; toggleCompact: any;
                  setTimezone: any; timezone: string; setMaintainedIntervalSize: any; availabilityRerenderTrigger: any; 
                  validBucketSizes: Array<string>; getQueryResults: any; environmentFqdn: string; warmStoreRange: Array<String>;
                  environmentHasWarmStore: boolean; stopAvailabilityLongPoll: any; startAvailabilityLongPoll: any; isSearchSpanRelative: boolean;
                  zoom: any; forceCold: any; toggleForceCold: any;}
interface State { hasSeenWarmStoreCoachmark: boolean; }


class AvailabilityChart extends React.Component<Props, State> {
    private availabilityChart;
    private environmentChanged = true;
    private availabilityChartWrapperId;
    private componentWrapperId;
    private defaultAvailabilityZoomRangeMillis = 30*24*60*60*1000;

    constructor(props: Props) {
        super(props);
        this.availabilityChartWrapperId = Utils.guid();
        this.componentWrapperId = Utils.guid();    
        this.state = {hasSeenWarmStoreCoachmark: !!localStorage.getItem('tsiCoachWarmStore')}; 
    }
    
    shouldComponentUpdate(nextProps, nextState) {
        if(nextProps.compact && (nextProps.compact !== this.props.compact))
            this.flashOpacity();
        this.environmentChanged = this.environmentChanged || (nextProps.environmentFqdn !== this.props.environmentFqdn);
        return nextState.hasSeenWarmStoreCoachmark !== this.state.hasSeenWarmStoreCoachmark 
        || nextProps.theme !== this.props.theme 
        || nextProps.compact !== this.props.compact 
        || nextProps.availabilityRerenderTrigger !== this.props.availabilityRerenderTrigger 
        || nextProps.isLoading !== this.props.isLoading 
        || nextProps.environmentHasWarmStore !== this.props.environmentHasWarmStore; 
    }

    flashOpacity = () => {
        if(!this.availabilityChart)
            return;
        let opacityToggleClasses = ['tsi-timePickerContainer', 'tsi-zoomButtonContainer', 'tsi-sparklineContainer'];
        let setOpacity = (opacity = '1') => opacityToggleClasses.forEach(otc => {
            let elt = document.getElementById(this.availabilityChartWrapperId).getElementsByClassName(otc)[0] as any;
            if(elt)
                elt.style.opacity = opacity;
        })
        setOpacity('0');
        setTimeout(setOpacity, 100);   
    }

    toggleCompact = (event = null) => {
        if(event === null) {
            this.props.toggleCompact();
        }
        else if(this.props.compact) {
            let availabilityChartSvg = document.getElementById(this.availabilityChartWrapperId).getElementsByTagName('svg')[0];
            let dateTimePicker = document.getElementById(this.availabilityChartWrapperId).getElementsByClassName('tsi-dateTimeContainer')[0];
            if(availabilityChartSvg && dateTimePicker){
                let brushElement = availabilityChartSvg.getElementsByClassName('brushElem')[0];
                let warmToggleElement = document.getElementById(this.componentWrapperId).getElementsByClassName(cx('toggleSliderWrapper'))[0];
                let wasntClicked = (buttonElt, event) => buttonElt && buttonElt !== event.target && !buttonElt.contains(event.target as HTMLInputElement); 
                if(wasntClicked(brushElement, event) && wasntClicked(dateTimePicker, event) && (!warmToggleElement || wasntClicked(warmToggleElement, event))) {
                    let staleRelativeTime = document.getElementsByClassName(cx('staleRelativeTime'))[0];
                    let snapRightButton = staleRelativeTime ? staleRelativeTime.getElementsByTagName('button')[0] : null;
                    if((snapRightButton && wasntClicked(snapRightButton, event)) || !snapRightButton) 
                        this.props.toggleCompact();
                }
            }
        }
    }

    acknowledgeWarmCoachmark = () => {
        localStorage.setItem('tsiCoachWarmStore', 'true');
        this.setState({hasSeenWarmStoreCoachmark: true});
    }

    render () {
        setTimeout(() => {
            if (!this.props.availabilityDistribution || this.props.isLoading) {
                return;
            } 
            this.availabilityChart = !this.availabilityChart ? new Utils.tsiClient.ux.AvailabilityChart(document.getElementsByClassName(cx('chart'))[0]) : this.availabilityChart;
            let warmStoreRange = (this.props.warmStoreRange && this.props.environmentHasWarmStore && !this.props.forceCold) ? this.props.warmStoreRange : null;
            let adjustZoom = this.availabilityChart.zoomedToMillis === this.availabilityChart.toMillis;
            if(adjustZoom){
                this.availabilityChart.zoomedToMillis = null;
            }
            this.availabilityChart.render(Utils.tsiClient.ux.transformAvailabilityForVisualization(this.props.availabilityDistribution), 
                {theme: this.props.theme, 
                grid: false, 
                tooltip: false, 
                legend: "hidden", 
                snapBrush: false, 
                preserveAvailabilityState: !this.environmentChanged,
                color: this.props.theme === 'light' ? '#136BFB' : '#60AAFF',
                isCompact: this.props.compact,
                offset: this.props.timezone,
                warmStoreRange: warmStoreRange,
                persistDateTimeButtonRange: true,
                brushMoveAction: this.props.stopAvailabilityLongPoll,
                defaultAvailabilityZoomRangeMillis: new Date(this.props.availabilityDistribution.range.to).valueOf() - this.props.searchSpan?.from?.valueOf() > this.defaultAvailabilityZoomRangeMillis ? null : 30*24*60*60*1000,
                brushMoveEndAction: (from: Date, to: Date, timezone: String) => {
                    if (timezone !== undefined && timezone !== this.props.timezone) {
                        this.props.setTimezone(timezone);
                    }
                    
                    let newValidBucketSizes = Utils.getValidBucketSizes(from.valueOf(), to.valueOf());
                    let newBucketSize = Utils.getNewBucketSize(this.props.validBucketSizes, newValidBucketSizes, this.props.searchSpan.bucketSize);
                    this.props.setMaintainedIntervalSize(this.props.searchSpan.bucketSize);
                    this.props.setSearchSpan(from, to, newBucketSize, newValidBucketSizes, to.valueOf() === (new Date(this.props.availabilityDistribution.range.to)).valueOf());
                    if (this.props.queries.length) {
                        this.props.getQueryResults();
                    }
                    if (Utils.checkElementPresentByClassname('tsi-dateTimePickerContainer')) {
                        TelemetryService.logUserAction('setDateTimeByCalendar');
                    }
                    else {
                        TelemetryService.logUserAction('dateTimeBrush');
                    }
                    this.props.startAvailabilityLongPoll();
                }, 
                maxBuckets: 500, 
                availabilityLeftMargin: 24,
                strings: this.props.t('sdkStrings', {returnObjects: true})
                }, this.props.availabilityDistribution);
            this.environmentChanged = false;
            this.availabilityChart.setBrush(new Date(this.props.searchSpan.from).valueOf(), new Date(this.props.searchSpan.to).valueOf());
        });
        let relativeTimeAndNewData = this.props.isSearchSpanRelative && (this.props.availabilityDistribution && (this.props.searchSpan.to.valueOf() !== new Date(this.props.availabilityDistribution.range.to).valueOf()));
        return (
            <div id={this.componentWrapperId} onClick={(e) => {this.toggleCompact(e);}} className={cx('theme-' + this.props.theme, 'wrapper', {compact: this.props.compact})}>
                {(this.props.environmentHasWarmStore && this.props.warmStoreRange !== null) &&
                <div className={cx('_base-toggleWrapper')}>
                    {this.props.t('warmStore')}
                    <button role="checkbox" aria-checked={!this.props.forceCold} aria-label={this.props.t('warmToggle')} 
                        onClick={() => {this.props.toggleForceCold(); this.props.getQueryResults();}} className={cx('toggleSliderWrapper', {off: this.props.forceCold})}>
                        <div className={cx('toggleButton')}></div>
                    </button>
                    {this.props.t(this.props.forceCold ? 'off' : 'on')}
                </div>
                }
                {relativeTimeAndNewData 
                    ? <TooltipableContainer position='left' className={cx('staleRelativeTime')} tooltip={this.props.t('staleRelativeTime')}>
                        <button onClick={() => this.props.zoom(null, null, 'snapRight')}>
                            <Icon id='info' className={cx('icon16')}/>
                        </button>
                      </TooltipableContainer> 
                    : ''
                }
                <div id={this.availabilityChartWrapperId} className={cx('chartWrapper', {hidden: this.props.availabilityDistribution === null || this.props.isLoading})}>
                    <div className={cx('chart', this.props.theme === 'dark' ? 'invertZoomButtons' : '')}></div>
                </div>
                <button aria-expanded={!this.props.compact} aria-label={this.props.t('toggleAvailabilityCompactView')} className={cx('toggle')} onClick={(e) => { this.toggleCompact(); e.stopPropagation();}}>
                    <Icon id='chevron' className={cx('chevronIcon', {up: this.props.compact})}/>
                </button>
                <Placeholder className={cx('placeholder')} visible={this.props.isLoading}><div>{this.props.t('loadingAvailability')}</div></Placeholder>
                <Placeholder className={cx('placeholder')} visible={this.props.availabilityDistribution === null && !this.props.isLoading}><div>{this.props.t('emptyEnvironment')}</div></Placeholder>
                {(this.props.environmentHasWarmStore && this.props.warmStoreRange !== null && !this.state.hasSeenWarmStoreCoachmark && !(this.props.availabilityDistribution === null) && !this.props.isLoading) && <WarmStoreCoachmark props={this.props} onClose={() => {this.acknowledgeWarmCoachmark()}}/>}
            </div>
        );
    }
}

const WarmStoreCoachmark = ({props, onClose}) => {
    return <ModalContainer className={cx('warmStoreCoachmarkWrapper')} onClose={() => {}} hasCloseButton={false} contentClassName={cx('warmStoreCoachmarkModal')}>
            <div className={cx('caret')}></div>
            <div className={cx('rest')}>
                <h2>{props.t('warmStoreCoachmark.title')}</h2>
                <div className={cx('img')}>
                    <Icon id="warmCoachThumbnail"></Icon>
                </div>
                <div>
                    {props.t('warmStoreCoachmark.content')}
                </div>
                <div>
                    <a target="_blank" rel="noopener noreferrer" href="https://docs.microsoft.com/azure/time-series-insights/time-series-insights-update-storage-ingress#data-storage">{props.t('learnMore')}</a>
                    <button onClick={onClose}>{props.t('gotIt')}</button>
                </div>
            </div>
    </ModalContainer>
}

export default AvailabilityChart;
import React from 'react';
import classNames from 'classnames/bind';
import DropdownMenuContainer from '../../DropdownMenu/DropdownMenu.container';
import Icon from '../../Icon/Icon';
import TelemetryService from '../../../services/TelemetryService';
import Utils from '../../../services/Utils';
import { ChartTypes, QueryTypes } from '../../../../constants/Enums';
const cx = classNames.bind(require('./BasicSettings.module.scss'));

interface Props { t: any; theme: string; validBucketSizes: Array<any>; searchSpan: any; 
    setMaintainedIntervalSize: any; setSearchSpan: any; getQueryResults: any; queries: Array<any>;
    linechartStackState: string; setStackState: any; isGetSeriesEnabled: boolean; setIsGetSeriesEnabled: any; 
    chartType: ChartTypes; queriesType: QueryTypes; setShouldSticky: any; shouldSticky: boolean; stickyTrigger: any; 
    isSeriesLabelsEnabled: boolean; setSeriesLabelsEnabled: any; isWellCollapsed: boolean;
}

export default function BasicSettings (props:Props) {
    const focusSeriesLabelId = Utils.customID('focusSeriesLabel');
    const rawDataLabelId = Utils.customID('rawDataLabel');

    const intervalSizeText = () => {
        return Utils.intervalSizeText(props.queries, props.queriesType, props.t('rawData'), props.searchSpan?.bucketSize);
    }
    const setIntervalSize = (newIntervalSize: string) => {
        props.setMaintainedIntervalSize(props.searchSpan.bucketSize);
        props.setSearchSpan(new Date(props.searchSpan.from), new Date(props.searchSpan.to), newIntervalSize, props.validBucketSizes);
        if (props.queries.length) {
            props.getQueryResults();
        }
        TelemetryService.logUserAction('intervalSlider', {interval: newIntervalSize});
    }

    const getActiveStackLabel = (stackState: string) => {
        let stackIcon;
        if(stackState === stackStates.Stacked)
            stackIcon = <Icon key={0} id={'stack'} className={cx('chartCommandIcon')}/>
        else if(stackState === stackStates.Shared)
            stackIcon = <Icon key={0} id={'shared'} className={cx('chartCommandIcon')}/>
        else 
            stackIcon = <Icon key={0} id={'overlap'} className={cx('chartCommandIcon')}/>
     
        return [stackIcon, <span key={1}>{props.t('commanding.' + props.linechartStackState)}</span>];
    }


    const stackStates = Utils.getStackStates();

    return (<div className={cx('settingsTab', 'basicsTab')}>
        <h4 className={cx('sectionHeader')}>{props.t('chartSettings.axisType')}</h4>
        <DropdownMenuContainer 
            dropdownMenuOptions={[
                [(<Icon id={'stack'} className={cx('chartCommandIcon')}/>), 
                    props.t('commanding.stacked'), 
                    () => props.setStackState(stackStates.Stacked)
                ],
                [(<Icon id={'shared'} className={cx('chartCommandIcon')}/>), 
                    props.t('commanding.shared'), 
                    () => props.setStackState(stackStates.Shared)
                ],
                [(<Icon id={'overlap'} className={cx('chartCommandIcon')}/>), 
                    props.t('commanding.overlap'), 
                    () => props.setStackState(stackStates.Overlap)
                ]
            ]} 
            dropdownMenuSide='right'
            containerClassName={cx('dropdownMenuWrapper')}
            menuClassName={cx("dropdownMenu")}
            buttonClassName={cx("dropdownButton")}
            menuItemClassName={cx("dropdownMenuOption")}
            menuButtonLabel={`${props.t('commanding.axisType')} ${props.t('commanding.' + props.linechartStackState)}`}
            selectedValue={props.t('commanding.' + props.linechartStackState)}
        >
            <div className={cx('buttonContent')}>
                {getActiveStackLabel(props.linechartStackState)}
                <div className={cx('buttonContentText')}></div>
                <Icon id='chevron' className={cx('chevron')}></Icon>
            </div>
        </DropdownMenuContainer>
        <h4 className={cx('sectionHeader')}>{props.t('chartSettings.intervalSize')}</h4>
        <DropdownMenuContainer
            dropdownMenuOptions={props.validBucketSizes.map(vbs => [null, vbs, () => {setIntervalSize(vbs)}])} 
            dropdownMenuSide='right'
            containerClassName={cx('dropdownMenuWrapper')}
            menuClassName={cx("dropdownMenu")}
            buttonClassName={cx("dropdownButton")}
            menuItemClassName={cx("dropdownMenuItem")}
            selectedValue={props.searchSpan.bucketSize}
            menuButtonLabel={`${props.searchSpan.bucketSize}`}
        >
            <div className={cx('buttonContent')}>
                <div className={cx('buttonContentText')}>
                    <span>[</span>
                    {intervalSizeText()}
                    <span>]</span> 
                    {props.t('intervalSize')}
                </div>
                <Icon id='chevron' className={`${cx('chevron')}`}></Icon>
            </div>
        </DropdownMenuContainer>
        {/* Raw data */}
        <h4 className={cx('sectionHeader')}>{props.t('rawData')}</h4>
        <div className={cx("settingsField")}>
            <div className={cx('settingsFieldLabel', 'rawDataText')} id={rawDataLabelId}>{props.t('commanding.showRawDataIfPossibleInIntervalSelection')}</div>
            <button className={cx('settingsFieldToggle')} role='checkbox' aria-checked={props.isGetSeriesEnabled} aria-labelledby={rawDataLabelId} onClick={() => { 
                props.setIsGetSeriesEnabled(!props.isGetSeriesEnabled);
            }}>
                <div className={cx('toggleSliderWrapper', {off: !props.isGetSeriesEnabled})}><div className={cx('toggleButton')}></div></div>
            </button>
        </div>
        {/* Focus series */}
        <h4 className={cx('sectionHeader')}>{props.t('chartSettings.focusSeries')}</h4>
        <div className={cx("settingsField")}>
            <div className={cx('settingsFieldLabel', 'focusSeriesText')} id={focusSeriesLabelId}>{props.t('chartSettings.focusSeriesDescription')}</div>
            <button className={cx('settingsFieldToggle')} role='checkbox' aria-checked={props.shouldSticky} aria-labelledby={focusSeriesLabelId} onClick={() => { 
                if (props.shouldSticky) {
                    props.stickyTrigger(null, '');
                }
                props.setShouldSticky(!props.shouldSticky);
            }}>
                <div className={cx('toggleSliderWrapper', {off: !props.shouldSticky})}><div className={cx('toggleButton')}></div></div>
            </button>
        </div>
        {/* Series labels */}
        <h4 className={cx('sectionHeader')}>{props.t('chartSettings.seriesLabels')}</h4>
        <div className={cx("settingsField")}>
            <div className={cx('settingsFieldLabel', 'seriesLabelText')} id={rawDataLabelId}>{props.t('chartSettings.seriesLabelsDescription')}</div>
            <button className={cx('settingsFieldToggle')} role='checkbox' aria-checked={props.isGetSeriesEnabled} aria-labelledby={rawDataLabelId} onClick={() => { 
                props.setSeriesLabelsEnabled(!props.isSeriesLabelsEnabled)
            }}>
                <div className={cx('toggleSliderWrapper', {off: !props.isSeriesLabelsEnabled})}><div className={cx('toggleButton')}></div></div>
            </button>
        </div>
        {/* Show well collapsed, series labels off info */}
        {props.isWellCollapsed && !props.isSeriesLabelsEnabled && 
            <div className={cx('infoBox', '_base-slideIn')}>
                <div className={cx('infoIconWrapper')}>
                    <Icon id={`iconInfo-${props.theme}`} className={cx('icon16')}/>
                </div>
                <div className={cx('content')}>{props.t('chartSettings.seriesLabelsWellCollapsedInfo')}</div>
            </div>
        }
    </div>
    );
}
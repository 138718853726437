import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import NewExplorerSplash from './NewExplorerSplash';
import { getTheme } from '../../../../state/reducers/AppSettingsReducer';
import { CHECK_MIGRATION_IS_NECESSARY } from '../../../../../constants/ActionTypes';

const mapStateToProps = state => {
    return { 
        theme: getTheme(state)
    };
};

const mapDispatchToProps = dispatch => ({
    checkMigrationIsNecessary: () => dispatch({type: CHECK_MIGRATION_IS_NECESSARY})
})

const NewExplorerSplashContainer = translate()(connect(mapStateToProps, mapDispatchToProps)(NewExplorerSplash));

export default NewExplorerSplashContainer;

import React from 'react';
import Icon from '../Icon/Icon';
import classNames from 'classnames/bind';
const cx = classNames.bind(require('./InfoBanner.module.scss'));

interface Props {
    onClick?: () => void;
    children: any;
}

const noOp = () => {};

export default function InfoBanner({ onClick = null, children }: Props) {
    return (<div className={cx('infoBanner', { clickable: onClick !== null })} 
        onClick={onClick || noOp}>
        <Icon id='blueInfo' className={cx('icon24')} />
        { children }
    </div>);
}
import React, { useState, useCallback } from 'react';
import classNames from 'classnames/bind';
import SidePane from '../Modal/SidePane/SidePane';
import BasicSettingsContainer from './BasicSettings/BasicSettings.container';
import SeriesSettingsContainer from './SeriesSettings/SeriesSettings.container';
import { ChartSettingsState } from '../../models/Interfaces';
import { ChartSettingsTypes } from '../../../constants/Enums';
import Icon from '../Icon/Icon';
import AutoRefreshSettingsContainer from './AutoRefreshSettings/AutoRefreshSettings.container';
import SwimlaneSettingsContainer from './SwimlaneSettings/SwimlaneSettings.container';
const cx = classNames.bind(require('./ChartSettings.module.scss'));

interface Props { t: any; theme: string; closeModal: any; chartSettingsState: ChartSettingsState; 
    setTab: (type: ChartSettingsTypes) => void; availabilityIsCompact: boolean;  isPaygEnvWithoutWarmStore: boolean;}
                  
export default function ChartSettings (props:Props) {
    const [selectedTab, setSelectedTab] = useState(props.chartSettingsState.type);
    
    const basicsButtonRef = useCallback(node => {
        if (node !== null && props.chartSettingsState.type === ChartSettingsTypes.Basics) {
            setTimeout(() => {
                node.focus();
            });
        }
    }, [props.chartSettingsState.type]);

    const lanesButtonRef = useCallback(node => {
        if (node !== null && props.chartSettingsState.type === ChartSettingsTypes.Swimlanes) {
            setTimeout(() => {
                node.focus();
            });
        }
    }, [props.chartSettingsState.type]);

    
    const seriesButtonRef = useCallback(node => {
        if (node !== null && props.chartSettingsState.type === ChartSettingsTypes.Series) {
            setTimeout(() => {
                node.focus();
            });
        }
    }, [props.chartSettingsState.type]);
    
    const autoRefreshButtonRef = useCallback(node => {
        if (node !== null && props.chartSettingsState.type === ChartSettingsTypes.AutoRefresh) {
            setTimeout(() => {
                node.focus();
            });
        }
    }, [props.chartSettingsState.type]);

    return (
        <SidePane 
                title={props.t('chartSettings.chartSettings')}  handleOnClose={() => {props.closeModal()}} className={cx('chartSettingsModal')}
                wrapperClassName={cx('chartSettingsWrapper', props.availabilityIsCompact ? 'availabilityIsCompact' : '')} titleIconId={'iconGear-' + props.theme} >
            <div className={cx('chartSettingsContainer')}>
                <div className={cx('selectTabContainer')}>
                    <button role='tab' aria-selected={selectedTab === ChartSettingsTypes.Basics} onClick={() => setSelectedTab(ChartSettingsTypes.Basics)}
                        className={cx('selectTab', (selectedTab === ChartSettingsTypes.Basics ? 'isSelected' : ''))} ref={basicsButtonRef}>
                        <Icon  id={'basicsTab-' + props.theme} className={cx('tabIcon')}></Icon>
                        <span className={cx('selectText')}>{props.t('chartSettings.basics')}</span>
                    </button>
                    <button role='tab' aria-selected={selectedTab === ChartSettingsTypes.Series} onClick={() => setSelectedTab(ChartSettingsTypes.Series)}
                        className={cx('selectTab', (selectedTab === ChartSettingsTypes.Series ? 'isSelected' : ''))} ref={seriesButtonRef} disabled={props.chartSettingsState.activeTSQI === null}>
                        <Icon id={'seriesTab-' + props.theme} className={cx('tabIcon')}></Icon>
                        <span className={cx('selectText')}>{props.t('chartSettings.series')}</span>
                    </button>
                    <button role='tab' aria-selected={selectedTab === ChartSettingsTypes.Swimlanes} onClick={() => setSelectedTab(ChartSettingsTypes.Swimlanes)}
                        className={cx('selectTab', (selectedTab === ChartSettingsTypes.Swimlanes ? 'isSelected' : ''))} ref={lanesButtonRef}>
                        <Icon id={'lanes'} className={cx('tabIcon', 'blackWhiteOverride', 'lanesIcon')}></Icon>
                        <span className={cx('selectText')}>{props.t('chartSettings.lanes')}</span>
                    </button>
                    <button role='tab' aria-selected={selectedTab === ChartSettingsTypes.AutoRefresh} onClick={() => setSelectedTab(ChartSettingsTypes.AutoRefresh)}
                        className={cx('selectTab', (selectedTab === ChartSettingsTypes.AutoRefresh ? 'isSelected' : ''))} ref={autoRefreshButtonRef} disabled={props.isPaygEnvWithoutWarmStore}>
                        <Icon id={'refresh-light'} className={cx('tabIcon', 'blackWhiteOverride')}></Icon>
                        <span className={cx('selectText')}>{props.t('autoRefresh.autoRefresh')}</span>
                    </button>
                </div>
                {selectedTab === ChartSettingsTypes.Basics && <BasicSettingsContainer></BasicSettingsContainer> }
                {selectedTab === ChartSettingsTypes.Series && <SeriesSettingsContainer></SeriesSettingsContainer> }
                {selectedTab === ChartSettingsTypes.AutoRefresh && <AutoRefreshSettingsContainer></AutoRefreshSettingsContainer> }
                {selectedTab === ChartSettingsTypes.Swimlanes && <SwimlaneSettingsContainer></SwimlaneSettingsContainer> }
            </div>
        </SidePane>
    );
}
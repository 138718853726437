import * as React from 'react';
import classNames from 'classnames/bind';
import Utils from '../../services/Utils';
const cx = classNames.bind(require('./ColorPicker.module.scss'));

interface Props { 
    t: any,
    theme: string,
    className?: string,
    numberOfColors?: number,
    colors?: Array<string>,
    defaultColor?: string, 
    isColorValueHidden?: boolean, 
    onSelect: any,
    onClick?: any,
} 

export default class ColorPicker extends React.Component<Props> {
    static defaultProps = {
        className: '', 
        numberOfColors: 15,
        isColorValueHidden: true,
        onSelect: () => {}
    };

    private targetRef;
    private renderColorPickerTimeout: number;
    private componentId: string;

    constructor(props: Props) {
        super(props);
        this.targetRef = React.createRef();
        this.componentId = Utils.guid();
    }

    componentWillUnmount() {
        clearTimeout(this.renderColorPickerTimeout);
    }

    renderColorPicker = () => {
        clearTimeout(this.renderColorPickerTimeout);
        this.renderColorPickerTimeout = window.setTimeout(() => {
            let targetElement = this.targetRef.current;
            targetElement.innerHTML = "";
            let colorPicker = new Utils.tsiClient.ux.ColorPicker(targetElement, this.componentId);
            let colorPickerOptions = {
                theme: this.props.theme,
                numberOfColors: this.props.numberOfColors,
                colors: this.props.colors,
                defaultColor: this.props.defaultColor,
                isColorValueHidden: this.props.isColorValueHidden,
                onSelect: colorValue => {
                    this.props.onSelect(colorValue);
                },
                onClick: (e) => {
                    if (this.props.onClick) {
                        this.props.onClick(e, colorPicker);
                    }
                }
            }
            colorPicker.render(colorPickerOptions);
        }) ;
    }

    render() {
        return <>
            <div className={cx('colorPicker', this.props.className)} ref={this.targetRef}></div>
            {this.renderColorPicker()}
        </>
    }
}
import React, { useReducer, useRef, useMemo, useCallback, useEffect } from "react"
import { stdChannel, runSaga, Action } from "redux-saga"

/**
 * The useReducerAndSaga hook is parameterized with two generic type StateType and ActionType.for Type Safety.
 * 
 * StateType: Represent the Type of State, allowing the hook to be used with different state shape.
 * 
 * ActionType : Represent the Type of Action.
 * 
 * this generic types ensure type safety and flexibility or the hook, making it resuable across different part of the application. 
 * 
 * readonly :  the 'as const' that make the retuen tuple read only ,as per line number 35 
 *  return [state, dispatch] as const;
 *  the retun type is marked as 'readonly' to refelect this.
 */
export function useReducerAndSaga<StateType, ActionType extends Action>(
    reducer:(state : StateType,action:ActionType) =>StateType,
    initialState: StateType,
    saga): readonly[StateType,React.Dispatch<ActionType>] {
    const [state, reactDispatch] = useReducer(reducer, initialState);
    const env = useRef(state);
    env.current = state;
    
    const channel = useMemo(() => stdChannel(), []);
    const dispatch = useCallback((a:ActionType) => {
      setImmediate(channel.put, a)
      reactDispatch(a);
    }, [channel.put]);
    const getState = useCallback(() => env.current, [])
  
    useEffect(() => {
      const task = runSaga({ channel, dispatch, getState }, saga)
      return () => task.cancel()
    }, [channel, dispatch, getState, saga]);
  
    return [state, dispatch] as const;
}
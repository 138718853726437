import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { getTheme } from '../../../state/reducers/AppSettingsReducer';
import AutoRefreshSettings from './AutoRefreshSettings';
import { getAutoRefreshCycleMillis, getAutoRefreshTimeSpanMillis, getIsAutoRefreshEnabled } from '../../../state/reducers/AnalyticsReducer';
import { SET_AUTO_REFRESH, SET_AUTO_REFRESH_CYCLE_MILLIS, SET_AUTO_REFRESH_TIME_SPAN_MILLIS } from '../../../../constants/ActionTypes';

const mapStateToProps = state => {
    return { 
        theme: getTheme(state),
        autoRefreshTimeSpanMillis: getAutoRefreshTimeSpanMillis(state),
        autoRefreshCycleMillis: getAutoRefreshCycleMillis(state),
        isAutoRefreshEnabled: getIsAutoRefreshEnabled(state),
    };
};

const mapDispatchToProps = dispatch => ({ 
    setAutoRefreshTimeSpan: (timeSpanMillis, isRefreshEnabled) => {
        dispatch({type: SET_AUTO_REFRESH_TIME_SPAN_MILLIS, payload: timeSpanMillis});
        if(isRefreshEnabled){
            // Cycle auto refresh off/on to update timeSpanMillis
            dispatch({type: SET_AUTO_REFRESH, payload: false});
            setTimeout(() => {
                dispatch({type: SET_AUTO_REFRESH, payload: true});
            })
        }
    },
    setAutoRefreshCycle: (cycleMillis, isRefreshEnabled) => {
        dispatch({type: SET_AUTO_REFRESH_CYCLE_MILLIS, payload: cycleMillis});
        if(isRefreshEnabled){
            // Cycle auto refresh off/on to update cycleMillis
            dispatch({type: SET_AUTO_REFRESH, payload: false});
            setTimeout(() => {
                dispatch({type: SET_AUTO_REFRESH, payload: true});
            })
        }
    }
});

const AutoRefreshSettingsContainer = translate()(connect(mapStateToProps, mapDispatchToProps)(AutoRefreshSettings));

export default AutoRefreshSettingsContainer;

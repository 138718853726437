import * as React from 'react';
import classNames from 'classnames/bind';
import Icon from '../Icon/Icon';
const cx = classNames.bind(require('./Demo.module.scss'));

interface Props { t: any; theme: string; } 
interface State { step: number; }

export default class Demo extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {step: 1};
    }

    render() {
        return (this.state.step !== 5 ? <div className={cx('wrapper')}>
            <div className={cx('step', `step${this.state.step}`)}>
                <div className={cx('caret')}></div>
                <Icon id="iconClose-dark" className={cx('close')} onClick={() => this.setState({step: 5})} />
                <div className={cx('title')}>{this.props.t(`demo.step${this.state.step}title`)}</div>
                <div className={cx('content')}>{this.props.t(`demo.step${this.state.step}content`)}</div>
                <button onClick={() => this.setState({step: this.state.step + 1})} className={cx('_base-secondary-button')}>{this.state.step === 4 ? this.props.t('gotIt') : this.props.t('next')}</button>
                <div className={cx('stepCountdown')}>
                    {`${this.state.step} ${this.props.t('of')} 4`}
                </div>
            </div>
    </div> : '');
    }
}
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import TsmModal from './TsmModal';
import {PUT_TYPE, PUT_HIERARCHY, PUT_INSTANCES, CLEAR_PROGRESS, CLOSE_TSM_MODAL, SET_MIGRATION_MODAL_VISIBILITY} from '../../../constants/ActionTypes.js';
import { getTheme, getSelectedEnvironment, getIsUserContributor } from '../../state/reducers/AppSettingsReducer';
import { getMetadata, getValidBucketSizes } from '../../state/reducers/AnalyticsReducer';
import { getSelectedEntityType, getSelectedEntity, getProgress, getTypes, getHierarchies, getAddType, getErrorMessage, getRequestMethod, 
        getErrorCode, isTsmModalVisible, getSelectedSubEntityIdx, getSearchText, getBulkUploadInstancesProgress, getTimeSeriesIdProperties } from '../../state/reducers/ModelReducer';
import TelemetryService from '../../services/TelemetryService';

const mapStateToProps = state => {
    return { 
        theme: getTheme(state),
        isTsmModalVisible: isTsmModalVisible(state),
        entityType: getSelectedEntityType(state),
        entityToEdit: getSelectedEntity(state),
        subEntityIdxToEdit: getSelectedSubEntityIdx(state),
        types: getTypes(state),
        hierarchies: getHierarchies(state),
        metadata: getMetadata(state),
        progress: getProgress(state),
        errorMessage: getErrorMessage(state),
        errorCode: getErrorCode(state),
        addType: getAddType(state),
        requestMethod: getRequestMethod(state),
        validBucketSizes: getValidBucketSizes(state),
        searchText: getSearchText(state),
        environment: getSelectedEnvironment(state),
        bulkUploadInstancesProgress: getBulkUploadInstancesProgress(state),
        timeSeriesIdProperties: getTimeSeriesIdProperties(state),
        isUserContributor: getIsUserContributor(state)
    };
};

const mapDispatchToProps = dispatch => ({ 
    closeTsmModal: () => dispatch({type: CLOSE_TSM_MODAL}),
    putType: (type, isEdit, isBulk) => {
        dispatch({type: PUT_TYPE, payload: type});
        TelemetryService.logUserAction('putType', {isEdit, isBulk});
    },
    putHierarchy: (hierarchy, isEdit, isBulk) => {
        dispatch({type: PUT_HIERARCHY, payload: hierarchy});
        TelemetryService.logUserAction('putHierarchy', {isEdit, isBulk});
    },
    putInstances: (instances, method, isEdit, isBulk, searchText) => {
        dispatch({type: PUT_INSTANCES, payload: {instances, method, searchText, isBulk}});
        TelemetryService.logUserAction('putInstances', {isEdit: isEdit, isBulk: isBulk});
    },
    clearProgress: () => dispatch({type: CLEAR_PROGRESS}),
    showMigrationModal: () => dispatch({type: SET_MIGRATION_MODAL_VISIBILITY, payload: { open: true }})
});
const TsmModalContainer = translate()(connect(mapStateToProps, mapDispatchToProps)(TsmModal));

export default TsmModalContainer;
import * as React from 'react';
import classNames from 'classnames/bind';
import ModalContainer from '../Modal/Modal.container';
import Icon from '../Icon/Icon';

const cx = classNames.bind(require('./WellColumnsSettings.module.scss'));

interface Props { t: any; theme: any; focusModalX: any; focusModalY: any;
                  tsqFields: any; envId: string; setFieldAsOnlyVisible: any; setAllFieldsVisibility: any;
                  setFieldVisibility: any; closeModal: any; modalOptions: any; }

interface State { isAllSelected: boolean}

class WellColumnsSettings extends React.Component<Props, State> {

    private readonly dimensions = {width: 320, height: 276}

    constructor(props: Props) {
        super(props);
        this.state = {isAllSelected: this.isAllSelected()};
    }

    componentDidUpdate(prevProps) {
        if (this.props.tsqFields !== prevProps.tsqFields) {
            this.setState({isAllSelected: this.isAllSelected()});
        }
    }

    private isAllSelected = () => {
        return Object.keys(this.props.tsqFields).filter(a => !this.props.tsqFields[a]).length === 0;
    }

    private closeModal = () => {
        this.props.closeModal();
    }

    private getSourceElement = () => {
        return (this.props.modalOptions && this.props.modalOptions.sourceElement) ? this.props.modalOptions.sourceElement : null;
    }

    private getFieldName = (rawFieldName) => {
        switch (rawFieldName) {
            case 'timeSeriesId': return this.props.t('timeSeriesId');
            case 'timeSeriesName': return this.props.t('timeSeriesName');
            case 'Variable': return this.props.t('tsm.type.variable.title');
            case 'Description': return this.props.t('tsm.description');
            default: return rawFieldName;
        }
    }

    render() {
        return <ModalContainer sourceElement={this.getSourceElement()} onClose={this.closeModal} 
                    title={this.props.t('well.showHideColumns')} titleIconId={'iconEditColumns-' + this.props.theme}
                    className={cx('focusModal')} titleClassName={cx('focusModalTitle')} wrapperClassName={cx('focusModalWrapper')} 
                    contentClassName={cx('focusModalContent')} pageX={this.props.focusModalX} pageY={this.props.focusModalY} 
                    caret={this.props.modalOptions.caretDirection} modalDimensions={this.dimensions}>
                    <div className={cx('editColumnsContent')}>
                        <div className={cx('fieldToggleContent')}> 
                            <div className={cx('fieldToggleRow', 'all')}>
                                <button className={cx('fieldToggleButton')} 
                                    onClick={() => {
                                        this.setState({isAllSelected: !this.state.isAllSelected});
                                        this.props.setAllFieldsVisibility(this.props.envId, !this.state.isAllSelected); 
                                    }} 
                                    aria-label={this.props.t('well.allColumns')}
                                    role="checkbox" 
                                    aria-checked={Object.keys(this.props.tsqFields).filter(a => !this.props.tsqFields[a]).length === 0}
                                >
                                    <Icon id='checkbox' className={cx('icon16') + ' tsiCb ' +  (this.state.isAllSelected ? 'tsiCbSelected' : Object.keys(this.props.tsqFields).filter(a => this.props.tsqFields[a]).length !== 0 ? cx('notSelected') : '')} />
                                    <div className={cx('columnText')}>{this.props.t('well.allColumns')}</div>
                                </button>
                            </div>           
                            {Object.keys(this.props.tsqFields).sort().map((fieldName, i) =>
                                <div key={i} className={cx('fieldToggleRow')}> 
                                    <button className={cx('fieldToggleButton')} 
                                        onClick={(e) => {
                                            this.props.setFieldVisibility(this.props.envId, fieldName, !this.props.tsqFields[fieldName]);
                                        }} 
                                        aria-label={`${this.props.tsqFields[fieldName] ? this.props.t('well.hideField') : this.props.t('well.showField')} ${this.getFieldName(fieldName)}`}
                                        role="checkbox" 
                                        aria-checked={this.props.tsqFields[fieldName]}
                                    >
                                        <Icon id='checkbox' className={cx('icon16') + ' tsiCb ' +  (this.props.tsqFields[fieldName] ? 'tsiCbSelected' : '')} />
                                        <div className={cx('columnText')} title={this.getFieldName(fieldName)}>{this.getFieldName(fieldName)}</div>
                                    </button>
                                    <button className={cx('fieldOnly')} tabIndex={0}
                                        onClick={() => {this.props.setFieldAsOnlyVisible(this.props.envId, fieldName)}} 
                                        aria-label={`${this.props.t('well.only')} ${this.props.t('well.showField')} ${this.getFieldName(fieldName)}`}>
                                        {this.props.t('well.only')}
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </ModalContainer>;

    }
}

export default WellColumnsSettings;
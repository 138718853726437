import * as React from 'react';
import classNames from 'classnames/bind';
import Icon from '../../Icon/Icon';
import { SortableTableColumn } from '../../SortableTable/SortableTable';
import SortableTableContainer from '../../SortableTable/SortableTable.container';
import Utils from '../../../services/Utils';
const cx = classNames.bind(require('../TsmEntities.module.scss'));

const HierarchiesColumns: Array<SortableTableColumn> =  [
    { key: 'name', i18nKey: 'tsm.name', isSortable: true},
    { key: 'id', i18nKey: 'tsm.hierarchy.id', isSortable: true, style: { minWidth: '340px' } },
    { key: 'levels', i18nKey: 'tsm.hierarchy.levels', isSortable: false },
    { key: 'actions', i18nKey: 'tsm.actions', isSortable: false, style: { width: '164px' } }
];

const LevelsColumns: Array<SortableTableColumn> =  [
    { key: 'level', i18nKey: 'tsm.hierarchy.level', isSortable: false},
    { key: 'actions', i18nKey: 'tsm.actions', isSortable: false}
];

/*
    'entity' refers to hierarchy
    'subEntity' refers to level in a hierarchy
*/
export const HierarchiesTable = ({entities, selectedEntityIndex, selectedSubEntityIndex, expandedEntityIndices, handlers: {onClickRow, onClickSort, onCopyId, onEditEntity, onShowEntityJSON, onConfirmDeletion, onEditSubEntity, onAddSubEntity, onConfirmDeletionSubEntity}, sort: {sOrder, sColumnKey}, t, theme, isUserContributor, progressMessageFieldID}) => {
    return <SortableTableContainer
            columns={HierarchiesColumns}  
            rows={entities} 
            handleOnSort={onClickSort} 
            canExpandRows={true}
            sortOrder={sOrder}
            sortColumnIndex={HierarchiesColumns.findIndex(column => column.key === sColumnKey)}
            selectedRowIndex={selectedEntityIndex}
            handleOnClickRow={onClickRow}
            expandedRowIndices={expandedEntityIndices}
            renderRow={(hierarchy, index, expanded, selected, onClick) => 
                <HierarchiesTableRow 
                    key={`hierarchiestablerow-${index}`} 
                    t={t}
                    theme={theme}
                    hierarchy={hierarchy}
                    rowIndex={index} 
                    expanded={expanded}
                    selected={selected}
                    handlers={{onClick, onCopyId}}
                    actionButton={createActionButtonsForHierarchy({t, theme, hierarchyId: hierarchy.id, index, handlers: {onEditEntity, onShowEntityJSON, onConfirmDeletion}, isUserContributor, progressMessageFieldID})} />
            }
            renderExpandedRowContents={(hierarchy) => <HierarchyDetails 
                                                            t={t} 
                                                            theme={theme} 
                                                            hierarchy={hierarchy} 
                                                            selectedLevelIndex={selectedSubEntityIndex} 
                                                            handlers={{onEditSubEntity, onAddSubEntity, onConfirmDeletionSubEntity}} 
                                                            isUserContributor={isUserContributor} 
                                                            progressMessageFieldID={progressMessageFieldID}/>}/> 
}

const HierarchiesTableRow = ({t, theme, hierarchy, rowIndex, expanded, selected, handlers: {onClick, onCopyId}, actionButton}) => 
    <tr tabIndex={0}
        className={cx('row', 'bodyRow', {bodyRowExpanded: expanded}, { bodyRowSelected: selected })} 
        onClick={() => onClick(rowIndex)} onKeyDown={(e) => {if (!Utils.isKeyDownAndNotEnter(e)) {onClick(rowIndex)}}}>
        <td className={cx('expand', 'table-cell')}>
            <div className={cx('cell-wrapper')}>
                <button className={cx('inline')}
                    title={t('expandCollapse')} 
                    aria-label={t('expandCollapse')} 
                    aria-controls={hierarchy.id + "_details"}
                    aria-expanded={expanded}> 
                    <Icon 
                        id={cx('caret-down')} 
                        className={cx('icon12', 'expandIcon', { rotated: expanded })} />
                </button>
            </div>
        </td>
        <HierarchiesTableCell sortable={true}>{hierarchy.name}</HierarchiesTableCell>
        <HierarchiesTableCell sortable={true}>
            <span className={cx('card-id')}>
                <span className={cx('copy-id')}>{hierarchy.id}</span>
                <input tabIndex={-1} id={"copyId-" + hierarchy.id} type="text" readOnly onClick={() => {return false;}} aria-label={t('tsm.hierarchy.copyId')}></input>
                <button className={cx('copy-id-button')} onClick={onCopyId(hierarchy.id)} onKeyDown={onCopyId(hierarchy.id)} 
                        title={t('copy')} aria-label={t('tsm.hierarchy.copyId')}>
                    <Icon id={'iconCopy-' + theme} className={cx('icon16')}/>
                </button>
                <span id={"copyIdMessage-" + hierarchy.id} className={cx('copy-id-message')}></span>
            </span>
        </HierarchiesTableCell>
        <HierarchiesTableCell sortable={false}>
            {hierarchy.source.instanceFieldNames ? 
                <span>
                    {hierarchy.source.instanceFieldNames.map((l, idx) => 
                        (idx > 0 ? ' > ' : '') + l
                    )}
                </span>
            : "-"
            }
        </HierarchiesTableCell>

        <td className={cx('table-cell', 'actions')}>
            <div className={cx('cell-wrapper')}>
                {actionButton}
            </div>
        </td>
    </tr>;

const HierarchiesTableCell = ({sortable, children}) => 
    <td className={cx('table-cell', {sortableCell: sortable})}>
        <div className={cx('cell-wrapper')}>
            <span>{children}</span>
        </div>
    </td>;

const createActionButtonsForHierarchy = ({t, theme, hierarchyId, index, handlers: {onEditEntity, onShowEntityJSON, onConfirmDeletion}, isUserContributor, progressMessageFieldID}) => 
    isUserContributor ? 
        [<button key={`edit-${index}`} className={cx('action-button', 'inline')} onClick={onEditEntity(hierarchyId)} onKeyDown={onEditEntity(hierarchyId)} 
                aria-label={t('tsm.hierarchy.edit')} aria-describedby={progressMessageFieldID} title={t('tsm.hierarchy.edit')}>
            <Icon id={'iconEdit'} className={cx('icon16', theme)}/>
        </button>,
        <button key={`show-${index}`} className={cx('action-button', 'inline')} onClick={onShowEntityJSON(index)} onKeyDown={onShowEntityJSON(index)} 
                aria-label={t('tsm.viewJson')} title={t('tsm.viewJson')}>
            <Icon id={"iconDocument"} className={cx('icon16')}/>
        </button>,
        <button key={`delete-${index}`} className={cx('action-button', 'inline', 'delete')} onClick={e => onConfirmDeletion(false, e, hierarchyId)} 
                onKeyDown={e => onConfirmDeletion(false, e, hierarchyId)} 
                aria-describedby={progressMessageFieldID}
                aria-label={t('tsm.hierarchy.delete')} title={t('tsm.hierarchy.delete')}>
            <Icon id={'iconDelete'} className={cx('icon16', theme)}/>
        </button>]
    : '';


const HierarchyDetails = ({t, theme, hierarchy, selectedLevelIndex, handlers: {onEditSubEntity, onAddSubEntity, onConfirmDeletionSubEntity}, isUserContributor, progressMessageFieldID}) => 
    <>
        {(hierarchy.source && hierarchy.source.instanceFieldNames.length !== 0) &&
        <div className={cx('expanded-row-table-container')}>
            <SortableTableContainer
                columns={LevelsColumns}  
                rows={hierarchy.source.instanceFieldNames}
                canExpandRows={false}
                ariaLabel={t('tsm.hierarchy.levels')}
                selectedRowIndex={selectedLevelIndex}
                renderRow={(level, index, expanded, selected) => 
                    <LevelsTableRow 
                        key={`levelstablerow-${index}`}
                        levelIdx={index}
                        level={level}
                        selected={selected}
                        actionButton={createActionButtonsForLevel({t, theme, level, hierarchyId: hierarchy.id, index, handlers: {onEditSubEntity, onConfirmDeletionSubEntity}, isUserContributor, progressMessageFieldID})} />
                }/> 
        </div>}
        {isUserContributor &&
        <div className={cx('sub-button')}>
            <button className={cx('action-button')} onClick={onAddSubEntity(hierarchy.id)} aria-label={t('tsm.hierarchy.addLevel')}>
                <Icon id={'iconAddBlue'} className={cx('icon16')}/>
                <span>{t('tsm.hierarchy.addLevel')}</span>
            </button>
        </div>}
    </>;

const LevelsTableRow = ({levelIdx, level, selected, actionButton}) =>
    <tr className={cx('row', {bodyRowSelected: selected})}>
        <HierarchiesTableCell sortable={false}>
            {Array.apply(null, Array(levelIdx)).map(() => "\u00A0 \u00A0")}
            <span>{level}</span>
        </HierarchiesTableCell>

        <td className={cx('table-cell', 'actions')}>
            <div className={cx('cell-wrapper')}>
                {actionButton}
            </div>
        </td>
    </tr>;

const createActionButtonsForLevel = ({t, theme, level, hierarchyId, index, handlers: {onEditSubEntity, onConfirmDeletionSubEntity}, isUserContributor, progressMessageFieldID}) => 
    isUserContributor ? 
        <>
            <button className={cx('action-button')} onClick={onEditSubEntity(hierarchyId, index)} 
                    aria-label={t('tsm.hierarchy.level') + (index + 1) + level + " " + t('tsm.edit')}
                    aria-describedby={progressMessageFieldID} 
                    title={t('tsm.hierarchy.editLevel')}><Icon id={'iconEdit'} className={cx('icon16', theme)}/>
            </button>
            <button className={cx('action-button', 'delete')} onClick={e => onConfirmDeletionSubEntity(false, e, hierarchyId, index)} 
                    aria-label={t('tsm.hierarchy.level') + (index + 1) + level + " " + t('tsm.delete')}
                    aria-describedby={progressMessageFieldID} 
                    title={t('tsm.hierarchy.deleteLevel')}><Icon id={'iconDelete'} className={cx('icon16', theme)}/>
            </button>
        </>
    : '';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import App from './App';
import { getTheme, getTenantsList, getSelectedTenantId, getIsDoneGettingTenants } from '../../state/reducers/AppSettingsReducer';
import { SET_TENANT_ID, SET_ACTIVE_PAGE, GET_TENANTS, OPEN_FEEDBACK_FORM } from '../../../constants/ActionTypes';
import { getMigrationModalVisible } from '../../state/reducers/AppReducer';

const mapStateToProps = state => {
    return { 
        theme: getTheme(state),
        tenants: getTenantsList(state),
        tenantId: getSelectedTenantId(state),
        isDoneGettingTenants: getIsDoneGettingTenants(state),
        isMigrationModalVisible: getMigrationModalVisible(state)
    };
};

const mapDispatchToProps = dispatch => ({ 
    setTenantId: (tid) => dispatch({type: SET_TENANT_ID, payload: tid}),
    setActivePage: (page) => dispatch({type: SET_ACTIVE_PAGE, payload: page}),
    getTenants: () => dispatch({type: GET_TENANTS}),
    openFeedbackForm: (issue: string, requestId: string, responseText: string): void => dispatch({ 
        type: OPEN_FEEDBACK_FORM,
        payload: { issue, requestId, responseText }
    })
});

const AppContainer = translate()(connect(mapStateToProps, mapDispatchToProps, null, {pure: false})(App));

export default AppContainer;

import * as React from 'react';
import IconService from '../../services/IconService';

interface PropsBase { id: string; className?: string; onClick?: any; title?: string; tabIndex?: any; onKeyDown?: any;}
interface PropsExtendedForA11y1 extends PropsBase { ariaLabel?: string; role?: null} 
interface PropsExtendedForA11y2 extends PropsBase {role: string; ariaLabel: string}
interface State { }

export default class Icon extends React.Component<PropsExtendedForA11y1 | PropsExtendedForA11y2, State> {

    private svg;

    render() {
        this.svg = IconService.getSvg(this.props.id);
        return <div role={this.props.role} onClick={this.props.onClick} onKeyDown={this.props.onKeyDown} className={this.props.className} 
                    dangerouslySetInnerHTML={{__html: this.svg}} title={this.props.title} tabIndex={this.props.tabIndex} 
                    aria-label={this.props.ariaLabel}></div>;
    }
}
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import Feedback from './Feedback';
import { getTheme } from '../../state/reducers/AppSettingsReducer';
import {
    getIsFormOpen,
    getFeedbackIssue,
    getFeedbackRequestId,
    getFeedbackResponseText,
} from '../../state/reducers/AppReducer';
import { OPEN_FEEDBACK_FORM, CLOSE_FEEDBACK_FORM, CLOSE_AND_CLEAR_FEEDBACK_FORM } from '../../../constants/ActionTypes';

const mapStateToProps = state => ({
    theme: getTheme(state),
    isFormOpen: getIsFormOpen(state),
    issue: getFeedbackIssue(state),
    requestId: getFeedbackRequestId(state),
    responseText: getFeedbackResponseText(state)
});

const mapDispatchToProps = dispatch => ({
    openForm: () => dispatch({ type: OPEN_FEEDBACK_FORM }),
    closeForm: () => dispatch({ type: CLOSE_FEEDBACK_FORM }),
    closeAndClearForm: () => dispatch({ type: CLOSE_AND_CLEAR_FEEDBACK_FORM })
});

const FeedbackContainer = translate()(connect(mapStateToProps, mapDispatchToProps)(Feedback));

export default FeedbackContainer;

import Utils from "../services/Utils";
import { ChartDataTypes } from "../../constants/Enums";

export default abstract class BaseQuery {

    public hidden = false;
    public data = null;
    public error;
    public isJustAdded: boolean = true;
    public queryID = Utils.guid();
    public dataType = 'numeric';

    public noData () {
        if (!this.data) {
            return false;
        }
        let aeName = Object.keys(this.data).filter(k => k !== 'aggKey')[0];
        let splitByObject = this.data[aeName];
        let firstSplitByValues = splitByObject[Object.keys(splitByObject)[0]];
        return !this.data || 
                Object.keys(splitByObject).length === 0 || 
                !firstSplitByValues || 
                this.isCategoricalAndHasNoData() ||
                Object.keys(firstSplitByValues).length === 0 ||
                Object.keys(firstSplitByValues).reduce((p: boolean, c) => {
                    return p && 
                    (firstSplitByValues[c][Object.keys(firstSplitByValues[c])[0]] === null
                    || typeof(firstSplitByValues[c][Object.keys(firstSplitByValues[c])[0]]) !== 'number')
                }, true);
    }

    public hasError () {
        return !!this.error;
    }

    private isCategoricalAndHasNoData () {
        if(this.dataType !== ChartDataTypes.Categorical){
            return false;
        }
        else{
            let aeName = Object.keys(this.data).filter(k => k !== 'aggKey')[0];
            let splitByObject = this.data[aeName];
            let aNonzeroValueExists = false;
            Object.keys(splitByObject).some(splitByKey => {
                let splitByTimestamps = splitByObject[splitByKey];
                Object.keys(splitByTimestamps).some(ts => {
                    let timestampValues = splitByTimestamps[ts];
                    Object.keys(timestampValues).some(measureKey => {
                        aNonzeroValueExists = aNonzeroValueExists || timestampValues[measureKey] !== 0;
                        return aNonzeroValueExists;
                    })
                    return aNonzeroValueExists;
                })
                return aNonzeroValueExists;
            });
            return !aNonzeroValueExists;
        }
    }
}
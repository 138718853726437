import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import Analytics from './Analytics';
import { GET_SHORTLINK, GET_MODEL } from '../../../constants/ActionTypes';
import { getTheme, getSelectedEnvironmentIsLts, getSelectedEnvironmentName } from '../../state/reducers/AppSettingsReducer';
import { 
    getAvailabilityIsCompact, 
    getIsModelLoading, 
    getIsModelEmpty, 
    getActiveQueries, 
    getIsLoadingAggregates } from '../../state/reducers/AnalyticsReducer';

const mapStateToProps = state => {
    return { 
        environmentName: getSelectedEnvironmentName(state),
        environmentIsLts: getSelectedEnvironmentIsLts(state),
        theme: getTheme(state),
        isModelLoading: getIsModelLoading(state),
        tallBottom: getAvailabilityIsCompact(state),
        isModelEmpty: getIsModelEmpty(state),
        isLoadingAggregates: getIsLoadingAggregates(state),
        queries: getActiveQueries(state)
    };
};

const mapDispatchToProps = dispatch => ({ 
    getShortlink: () => dispatch({type: GET_SHORTLINK}),
    getModel: () => dispatch({type: GET_MODEL})
});

const AnalyticsContainer = translate()(connect(mapStateToProps, mapDispatchToProps)(Analytics));

export default AnalyticsContainer;

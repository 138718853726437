import Utils from "../services/Utils";
import BaseQuery from "./BaseQuery";
import { ChartDataTypes, CurveStepInterpolationFunctions, InterpolationFunctions, YAxisState } from "../../constants/Enums";
import { t } from "i18next";
import { VariableAggregationOperations_Numeric_Interpolation } from "../../constants/Constants";

export default class TimeSeriesQuery extends BaseQuery {

    static serializableProperties: Array<string> = ['additionalFields', 'hidden', 'includeDots', 'includeEnvelope', 
    'interpolationFunction', 'timeShift', 'startAt', 'searchSpan', 'alias', 'swimLane', 'color', 'dataType', 'valueMapping', 'height', 
    'queryID', 'variableAlias', 'rollupCategoricalValues', 'yAxisExtentState', 'yExtent'];
     
    public additionalFields = {};
    public timeShift: string;
    public startAt: string;
    public color: string;
    public interpolationFunction: string;
    public includeEnvelope: boolean = true;
    public includeDots: boolean = false;
    public swimLane: number;
    public alias: string;
    public valueMapping: Object;
    private tsqExpression;
    public variableAlias: string = null;
    public rollupCategoricalValues: boolean = true;
    public yAxisExtentState: YAxisState = YAxisState.auto;
    public yExtent: [] = null;

    constructor(instanceObject: any, variableObject: any, searchSpan: any, color: string = null, alias: string = '', obj = {}) {
            super();

            // use variableAlias to show variable if min/max/avg numeric, or categorical
            let chartDataOptions = {color: color, alias: alias, includeEnvelope: true, ...obj};
            if (chartDataOptions['additionalFields'] && chartDataOptions['additionalFields']['Variable']) { 
                chartDataOptions['variableAlias'] = chartDataOptions['additionalFields']['Variable'];
            }
            if (!TimeSeriesQuery.isCategoricalOrMinMaxAvgNumericVariable(variableObject, obj['dataType'])) {
                chartDataOptions['isVariableAliasShownOnTooltip'] = false;
            }

            this.tsqExpression = new Utils.tsiClient.ux.TsqExpression(instanceObject, variableObject, searchSpan, chartDataOptions);
            if(this.tsqExpression.dataType === ChartDataTypes.Categorical){
                this.tsqExpression.measureTypes = null; // this is a known hack to support the accessible grid that should be addressed more generally by the SDK
            }
            for (let prop in this.tsqExpression) {
                this[prop] = this.tsqExpression[prop];
            }

            this.interpolationFunction = TimeSeriesQuery.getDefaultInterpolationFunctionForVariable(variableObject, this.tsqExpression.dataType);
    }

    static getDefaultInterpolationFunctionForVariable = (variableObject, dataType, isRawData = false, setByUser = false) => {
        if (TimeSeriesQuery.isNumericKindVariable(variableObject, dataType)) {
            const variable = Object.values(variableObject)?.[0] as any;
            const aggregationOperation = variable?.aggregation?.tsx;
            const hasStepInterpolationInVariableDefinition = VariableAggregationOperations_Numeric_Interpolation.includes(aggregationOperation) && variable?.interpolation?.kind === 'step'; // only left, right, twavg and twsum variables support interpolation

            if (hasStepInterpolationInVariableDefinition) {
                if (isRawData) {
                    return aggregationOperation === 'left($value)' ? CurveStepInterpolationFunctions.curveStepAfter 
                                : aggregationOperation === 'right($value)' ? CurveStepInterpolationFunctions.curveStepBefore 
                                    : CurveStepInterpolationFunctions.curveStepAfter;
                } else {
                    return InterpolationFunctions.curveStep;
                } 
            } else {
                if (isRawData && setByUser) {
                    return CurveStepInterpolationFunctions.curveStepAfter;
                } else {
                    return InterpolationFunctions.none;
                }
            }
        } else {
            return InterpolationFunctions.none;
        }
    }

    static constructFromInstanceTypeVariable = (type, instance, variableName, color: string, chartDataOptions: any = {}) => {
        let variableObject = {};
        if (type.inlineVariables) { // this way we know that this type is not a regular instance type it is custom one we created to aggregate avg with min and max as well
            variableObject = type.inlineVariables;
        } else {
            variableObject = {[variableName]: type.variables[variableName]};
        }
        if(variableName in variableObject && variableObject[variableName].kind === 'categorical'){
            chartDataOptions.valueMapping = Utils.getCategoricalValueMapping(variableObject[variableName], variableName);
            chartDataOptions.dataType = ChartDataTypes.Categorical;
            chartDataOptions.rollupCategoricalValues = true;
            chartDataOptions.height = 32;
        }  
        else{
            chartDataOptions.dataType = ChartDataTypes.Numeric;
        }    
        chartDataOptions['variableAlias'] = variableName;

        let tsq = new TimeSeriesQuery(instance, 
            variableObject, 
            {}, 
            color, 
            Utils.getInstanceAlias(instance),
            chartDataOptions);
        let additionalFields = {};
        if (instance.name) {
            additionalFields['timeSeriesName'] = instance.name;
        }
        additionalFields['Variable'] = variableName;
        if (instance.description) {
            additionalFields['Description'] = instance.description;
        }
        tsq.additionalFields = {...additionalFields, ...instance.instanceFields};
        return tsq;
    }

    static fromObject = (obj: any) => {
        let ts = new TimeSeriesQuery(obj.instanceObject, obj.variableObject, obj.searchSpan, obj.color, obj.alias, obj);

        TimeSeriesQuery.serializableProperties.forEach(prop => {
            // Here we skip 'variableAlias' because the TimeSeriesQuery constructor above 
            // already populates the value, and the obj argument may not have a value for it.
            if(obj[prop] !== undefined && prop !== 'variableAlias'){
                if (prop === 'alias') {
                    ts[prop] = Utils.getInstanceAlias(obj.instanceObject);
                } else {
                    ts[prop] = obj[prop];
                }
            }
        });

        return ts;
    }

    static toObject(tsq: any) {
        tsq.alias = Utils.stripNullGuid(tsq.alias);

        // delete properties not neccessary for roundtrip serialization, preserve instance object
        let tsqPropertiesToKeep = Utils.createHashMapFromStringArray(['instanceObject', 'variableObject'].concat(TimeSeriesQuery.serializableProperties));
        Object.keys(tsq).forEach(prop => {
            if(!tsqPropertiesToKeep[prop]){
                delete tsq[prop];
            }
        })

        // delete irrelevant elements of instanceObject
        let instanceObjectPropertiesToKeep = Utils.createHashMapFromStringArray(['timeSeriesId', 'name', 'type', 'hierarchyIds']);
        Object.keys(tsq.instanceObject).forEach(prop => {
            if(!instanceObjectPropertiesToKeep[prop]){
                delete tsq.instanceObject[prop];
            }
        })

        // delete irrelevant elements of instanceObject.type
        if(tsq.instanceObject.type){
            let instanceObjectTypePropertiesToKeep = Utils.createHashMapFromStringArray(['id']);
            Object.keys(tsq.instanceObject.type).forEach(prop => {
                if(!instanceObjectTypePropertiesToKeep[prop]){
                    delete tsq.instanceObject.type[prop];
                }
            })
        }

        return tsq;
    }

    static isMinMaxAvgNumericVariable = (variableObject, dataType) => {
        return dataType === ChartDataTypes.Numeric && (variableObject && 'avg' in variableObject && 'min' in variableObject && 'max' in variableObject);
    }

    static isCategoricalOrMinMaxAvgNumericVariable = (variableObject, dataType) => {
        return TimeSeriesQuery.isMinMaxAvgNumericVariable(variableObject, dataType) || (dataType === ChartDataTypes.Categorical);
    }

    static isNumericKindVariable = (variableObject, dataType) => {
        return dataType === ChartDataTypes.Numeric && Object.values(variableObject).filter((variable: any) => variable?.kind !== ChartDataTypes.Numeric).length === 0;
    }

    static getRawDataInlineVariable = (variableObject, dataType) => {
        let rawValueMeasureIdentifier = `(${t('rawData').toLowerCase()})`;
        if (TimeSeriesQuery.isMinMaxAvgNumericVariable(variableObject, dataType)) {
            return {key: `${t('tsm.type.variable.value').toLowerCase()} ${rawValueMeasureIdentifier}`, value: variableObject?.avg};
        } else {
            return {key: variableObject ? `${Object.keys(variableObject)?.[0]} ${rawValueMeasureIdentifier}` : rawValueMeasureIdentifier, value: Object.values(variableObject)?.[0]};
        }
    }

    static applyChangesForRawSeries = (ts) => {
        ts.isRawData = true;
        if (ts.interpolationFunction) {
            if (ts.interpolationFunction === InterpolationFunctions.curveStep) {
                ts.interpolationFunction = TimeSeriesQuery.getDefaultInterpolationFunctionForVariable(ts.variableObject, ts.dataType, true, false);
            }
        } else {
            ts.interpolationFunction = TimeSeriesQuery.getDefaultInterpolationFunctionForVariable(ts.variableObject, ts.dataType, true, false);
        }
        delete ts.searchSpan.bucketSize;
        let rawDataInlineVariable = TimeSeriesQuery.getRawDataInlineVariable(ts.variableObject, ts.dataType);
        ts.measureTypes = [rawDataInlineVariable.key]; // set the measure types to raw identifier to differentiate raw from original ones to show in tooltip
    }

    static applyChangesForAggregateSeries = (ts, bucketSize) => {
        ts.isRawData = false;
        if (ts.interpolationFunction) {
            if (ts.interpolationFunction === InterpolationFunctions.curveStepAfter || ts.interpolationFunction === InterpolationFunctions.curveStepBefore) {
                ts.interpolationFunction = InterpolationFunctions.curveStep;
            }
        } else {
            ts.interpolationFunction = TimeSeriesQuery.getDefaultInterpolationFunctionForVariable(ts.variableObject, ts.dataType, false, false);
        }
        ts.measureTypes = Object.keys(ts.variableObject); //set measure types back to its original
        ts.searchSpan.bucketSize = bucketSize;
    }
}
import Utils from '../services/Utils';

export enum NotificationType {
  Info = 'Info',
  Warning = 'Warning',
  Error = 'Error',
  Migration = 'Migration' // added to filter migration related notifications easily when clearing other environments' migration notification
}

export interface BypassLocaleOptions{
  isTitleBypassed: boolean,
  isMessageBypassed: boolean
}

export interface NotificationAction {
  buttonLabelKey: string,
  buttonOnClick: any
}

export class NotificationInfo {
  id: string;
  titleKey: string;
  messageKey: string;
  type: NotificationType;
  requestId: string;
  responseText: string;
  action?: NotificationAction;
  bypasseLocaleOptions?: BypassLocaleOptions;

  constructor(
    titleKey: string, 
    messageKey: string, 
    type: NotificationType = NotificationType.Info, 
    requestId: string = null,
    responseText: string = null,
    action: NotificationAction = null,
    bypasseLocaleOptions: BypassLocaleOptions = null) {

    this.id = Utils.guid();
    this.titleKey = titleKey || 'notifications.unknownTitle';
    this.messageKey = messageKey || 'notifications.unknownError';
    this.type = type;
    this.requestId = requestId;
    this.responseText = responseText;
    this.action = action;
    this.bypasseLocaleOptions = bypasseLocaleOptions;
  }
}